var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import styles from './TaskInfo.module.scss';
import { FormConfirmationInput, FormConfirmationMarkdown, FormDateBox, FormSelect, RowLabel } from '@app/Components/FormControls';
import { BaseFormModel } from '@app/Services/BaseFormModel';
import { hasMaxLength, isNumber, isRequired } from '@app/Services/Validation';
import { Api } from '@app/Api';
import { PersonPicker } from '@app/Components/Pickers/PersonPicker/PersonPicker';
import { Attachments } from '@app/Widgets/Meeting/Attachments/Attachments';
import { AppData, ManualTaskStatusEnum, ManualTaskStateEnum, SeparatedStrings, ProjectStatus } from '@app/AppConstants';
import { ApprovedChecklistItemDeliverableTablePart } from '@app/Widgets/ManualTaskModals/Components/Tables/ApprovedChecklistItemDeliverableTablePart';
import { ApprovedChecklistItemQuestionTablePart } from '@app/Widgets/ManualTaskModals/Components/Tables/ApprovedChecklistItemQuestionTablePart';
import DateTimeService from '@app/Services/DateTimeService';
import { Input } from 'reactstrap';
import { DenyComments } from '@app/Widgets/ManualTaskModals/Components/DenyComments/DenyComments';
import FileUploadArea from '@app/Components/FileUploadArea/FileUploadArea';
import { ApprovedChecklistItemMasterDeliverableTablePart } from './Components/Tables/ApprovedChecklistItemMasterDeliverableTablePart';
import { MilestoneChecklistItemMasterDeliverableTablePart } from './Components/Tables/MilestoneChecklistItemMasterDeliverableTablePart';
import { ApprovedChecklistItemMasterExecutiveQuestionTablePart } from './Components/Tables/ApprovedChecklistItemMasterExecutiveQuestionTablePart';
import { ApprovedMilestoneChecklistItemMasterExecutiveQuestionTablePart } from './Components/Tables/ApprovedMilestoneChecklistItemMasterExecutiveQuestionTablePart';
export var TaskInfoFormModel = /** @class */ (function (_super) {
    __extends(TaskInfoFormModel, _super);
    function TaskInfoFormModel() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.projectId = 0;
        _this.title = '';
        _this.description = '';
        _this.answer = '';
        _this.attachments = [];
        return _this;
    }
    __decorate([
        observable,
        isRequired(),
        isNumber()
    ], TaskInfoFormModel.prototype, "projectId", void 0);
    __decorate([
        observable,
        isNumber()
    ], TaskInfoFormModel.prototype, "gateId", void 0);
    __decorate([
        observable
    ], TaskInfoFormModel.prototype, "gate", void 0);
    __decorate([
        observable
    ], TaskInfoFormModel.prototype, "checkListId", void 0);
    __decorate([
        observable,
        isRequired()
    ], TaskInfoFormModel.prototype, "deadLine", void 0);
    __decorate([
        observable,
        isRequired(),
        hasMaxLength(200)
    ], TaskInfoFormModel.prototype, "title", void 0);
    __decorate([
        observable
    ], TaskInfoFormModel.prototype, "description", void 0);
    __decorate([
        observable
    ], TaskInfoFormModel.prototype, "answer", void 0);
    __decorate([
        observable,
        isRequired()
    ], TaskInfoFormModel.prototype, "assignee", void 0);
    __decorate([
        observable,
        isRequired()
    ], TaskInfoFormModel.prototype, "approver", void 0);
    __decorate([
        observable
    ], TaskInfoFormModel.prototype, "attachments", void 0);
    return TaskInfoFormModel;
}(BaseFormModel));
export var TaskInfo = /** @class */ (function (_super) {
    __extends(TaskInfo, _super);
    function TaskInfo(props) {
        var _this = _super.call(this, props) || this;
        _this._gates = [];
        _this._projects = [];
        _this.handleFormChange = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.props.onChange) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.props.onChange(this.form)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        _this.handleGateChange = function () { return __awaiter(_this, void 0, void 0, function () {
            var gate;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        gate = this._gates.find(function (x) { return x.id === _this.form.gateId; });
                        if (gate) {
                            this._deadLine = DateTimeService.addDays(gate.forecastOrActualDate, AppData.ManualTaskDeadlineDiff);
                            this.form.deadLine = this._deadLine;
                        }
                        if (!this.props.onChange) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.props.onChange(this.form)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        _this.handleProjectChange = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.props.onChange) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.props.onChange(this.form)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.form.gateId = undefined;
                        this.form.gate = undefined;
                        return [4 /*yield*/, this._loadGates()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.form = props.form;
        _this._deadLine = _this.form.deadLine;
        _this._description = _this.form.description;
        _this._answer = _this.form.answer;
        _this._title = _this.form.title;
        _this._withProject = !!props.form.projectId;
        return _this;
    }
    TaskInfo.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, gate;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!this._withProject) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, Api.secured.searchProjects()];
                    case 1:
                        _a._projects = _b.sent();
                        _b.label = 2;
                    case 2:
                        if (!this._withProject) return [3 /*break*/, 4];
                        return [4 /*yield*/, this._loadGates()];
                    case 3:
                        _b.sent();
                        if (this.props.form.gateId && !this.props.task) {
                            gate = this._gates.find(function (x) { return x.id === _this.props.form.gateId; });
                            if (gate) {
                                this._deadLine = DateTimeService.addDays(gate.forecastOrActualDate, AppData.ManualTaskDeadlineDiff);
                                this.form.deadLine = this._deadLine;
                            }
                        }
                        _b.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    TaskInfo.prototype._loadGates = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, Api.secured.getApprovedGatesList({ projectId: Number(this.props.form.projectId) })];
                    case 1:
                        _a._gates = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TaskInfo.prototype._handleDeadLineChange = function (date) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._deadLine = date;
                        return [4 /*yield*/, this.handleFormChange()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TaskInfo.prototype._handelApproverSelect = function (users) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(users && users[0])) return [3 /*break*/, 2];
                        this._approver = users[0];
                        this.form.approver = users[0];
                        return [4 /*yield*/, this.handleFormChange()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    TaskInfo.prototype._handelAssigneeSelect = function (users) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(users && users[0])) return [3 /*break*/, 2];
                        this._assignee = users[0];
                        this.form.assignee = users[0];
                        return [4 /*yield*/, this.handleFormChange()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    TaskInfo.prototype._handleDescriptionChange = function (newDescription) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(newDescription !== this._description)) return [3 /*break*/, 2];
                        this._description = newDescription;
                        return [4 /*yield*/, this.handleFormChange()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    TaskInfo.prototype._handleAnswerChange = function (newAnswer) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(newAnswer !== this._answer)) return [3 /*break*/, 2];
                        this._answer = newAnswer;
                        return [4 /*yield*/, this.handleFormChange()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    TaskInfo.prototype._handleTitleChange = function (newTitle) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(newTitle !== this._title)) return [3 /*break*/, 2];
                        this._title = newTitle;
                        return [4 /*yield*/, this.handleFormChange()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    TaskInfo.prototype._uploadAttachment = function (file) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.props.task)
                            return [2 /*return*/];
                        return [4 /*yield*/, Api.secured.uploadManualTaskAttachment(this.props.task.id, { file: file })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TaskInfo.prototype._removeAttachment = function (attachment) {
        return __awaiter(this, void 0, void 0, function () {
            var task, onFileChange;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        task = (_a = this.props, _a.task), onFileChange = _a.onFileChange;
                        if (!(task === null || task === void 0 ? void 0 : task.id)) return [3 /*break*/, 3];
                        return [4 /*yield*/, Api.secured.deleteManualTaskAttachment(task.id, { attachmentId: attachment.id })];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, (onFileChange === null || onFileChange === void 0 ? void 0 : onFileChange())];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(TaskInfo.prototype, "projectById", {
        get: function () {
            var id = this.props.form.projectId;
            if (!id)
                return null;
            return this._projects.find(function (x) { return x.id === id; });
        },
        enumerable: false,
        configurable: true
    });
    TaskInfo.prototype.renderManualTaskState = function (state) {
        if (state == ManualTaskStateEnum.CantDo) {
            return SeparatedStrings.ManualTaskCantDoState;
        }
        if (state == ManualTaskStateEnum.ClosedBySystem) {
            return SeparatedStrings.ManualTaskClosedBySystemState;
        }
        return state;
    };
    TaskInfo.prototype.render = function () {
        var _a, _b;
        var _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        var projectName = (_a = this.props, _a.projectName), config = _a.config, task = _a.task;
        var gate = (_b = this.form, _b.gate), assignee = _b.assignee, approver = _b.approver;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.meetingContainer },
                React.createElement("div", { className: styles.dateRow },
                    React.createElement("div", { className: styles.wholeRow },
                        React.createElement(RowLabel, { text: "Project", className: styles.label, mandatory: !this._withProject }),
                        this._withProject ? (React.createElement("span", null, projectName)) : (React.createElement(FormSelect, { formModel: this.form, name: "projectId", options: this._projects.map(function (g) { return ({
                                value: g.id,
                                label: g.codeName + ' - ' + g.displayName
                            }); }), placeholder: "Select a project", changeHandler: this.handleProjectChange })))),
                React.createElement("div", { className: styles.dateRow },
                    React.createElement("div", { className: config.isStateHidden ? styles.firstPartOfRow : styles.thirdOfRow },
                        React.createElement(RowLabel, { text: "Gate", className: styles.label, mandatory: !config.isGateDisabled }),
                        React.createElement(FormSelect, { formModel: this.form, name: "gateId", defaultValue: gate, options: this._gates.map(function (g) { return ({ value: g.id, label: g.displayName }); }), placeholder: "Select a gate", disabled: config.isGateDisabled, changeHandler: this.handleGateChange })),
                    React.createElement("div", { className: config.isStateHidden ? styles.secondPartOfRow : styles.thirdOfRow },
                        React.createElement(RowLabel, { text: "DeadLine", className: styles.label, mandatory: !config.isDeadLineDisabled }),
                        React.createElement(FormDateBox, { className: styles.wholeRow, formModel: this.form, name: 'deadLine', disabled: config.isDeadLineDisabled, value: this._deadLine, type: "date", onDateChange: this._handleDeadLineChange, acceptCustomValue: false })),
                    !config.isStateHidden && (task === null || task === void 0 ? void 0 : task.state) && (React.createElement("div", { className: styles.thirdOfRow },
                        React.createElement(RowLabel, { text: "State", className: styles.label }),
                        React.createElement(Input, { type: "text", value: this.renderManualTaskState(task === null || task === void 0 ? void 0 : task.state), disabled: true })))),
                React.createElement("div", { className: styles.dateRow },
                    React.createElement("div", { className: styles.firstPartOfRow },
                        React.createElement(RowLabel, { text: "Approver", className: styles.label, mandatory: !config.isApproverDisabled }),
                        React.createElement(PersonPicker, { defaultValue: approver, onUsersSelect: this._handelApproverSelect, isDisabled: config.isApproverDisabled })),
                    React.createElement("div", { className: styles.secondPartOfRow },
                        React.createElement(RowLabel, { text: "Assignee", className: styles.label, mandatory: !config.isAssigneeDisabled }),
                        React.createElement(PersonPicker, { defaultValue: assignee, onUsersSelect: this._handelAssigneeSelect, isDisabled: config.isAssigneeDisabled, isCreatable: true }))),
                React.createElement("div", { className: styles.dateRow },
                    React.createElement("div", { className: styles.wholeRow },
                        React.createElement(RowLabel, { text: "Task name", className: styles.label, mandatory: !config.isTitleDisabled }),
                        React.createElement(FormConfirmationInput, { className: styles.textArea, placeholder: "Task name", changeHandler: this._handleDescriptionChange, formModel: this.form, name: "title", disabled: config.isTitleDisabled }))),
                React.createElement("div", { className: styles.dateRow },
                    React.createElement("div", { className: styles.wholeRow },
                        React.createElement(RowLabel, { text: "Short task description", className: styles.label, mandatory: !config.isDescriptionDisabled }),
                        React.createElement(FormConfirmationMarkdown, { type: "textarea", className: styles.textArea, placeholder: "Short task description", changeHandler: this._handleDescriptionChange, formModel: this.form, name: "description", disabled: config.isDescriptionDisabled }))),
                task && task.denyComments && task.denyComments.length > 0 && (React.createElement("div", { className: styles.dateRow },
                    React.createElement("div", { className: styles.wholeRow },
                        React.createElement(RowLabel, { text: "Deny Comments", className: styles.label }),
                        React.createElement(DenyComments, { denyComments: task.denyComments })))),
                !config.isAnswerHidden && task && (React.createElement("div", { className: styles.dateRow },
                    React.createElement("div", { className: styles.wholeRow },
                        task.type === ManualTaskStatusEnum.ProjectManagement && (React.createElement(React.Fragment, null,
                            React.createElement(RowLabel, { text: "Answer", className: styles.label }),
                            React.createElement(FormConfirmationMarkdown, { type: "textarea", className: styles.textArea, placeholder: "Answer", changeHandler: this._handleAnswerChange, formModel: this.form, name: "answer", disabled: config.isAnswerDisabled }))),
                        task.type === ManualTaskStatusEnum.DelegateDeliverable && (React.createElement(ApprovedChecklistItemDeliverableTablePart, { checkListId: task.checklistId || 0, checkListItemId: ((_c = task.checkListItem) === null || _c === void 0 ? void 0 : _c.value) || 0, manualTaskId: task.id, canPrincipalUpdateWorkerPart: task.canPrincipalUpdateWorkerPart, isProjectTerminatedOrCompleted: task.projectStatus === ProjectStatus.Terminated ||
                                task.projectStatus === ProjectStatus.Completed })),
                        task.type === ManualTaskStatusEnum.DelegateAssessmentDeliverable && (React.createElement(ApprovedChecklistItemMasterDeliverableTablePart, { projectId: task.projectId, projectCodeName: task.projectCodeName || '', checkListId: task.checklistId || 0, checkListItemId: ((_d = task.checkListItem) === null || _d === void 0 ? void 0 : _d.value) || 0, manualTaskId: task.id, canPrincipalUpdateWorkerPart: task.canPrincipalUpdateWorkerPart, gateId: ((_e = task.gate) === null || _e === void 0 ? void 0 : _e.value) || 0, gateDisplayName: ((_f = task.gate) === null || _f === void 0 ? void 0 : _f.label) || '', isProjectTerminatedOrCompleted: task.projectStatus === ProjectStatus.Terminated ||
                                task.projectStatus === ProjectStatus.Completed })),
                        task.type === ManualTaskStatusEnum.DelegateMilestoneMasterCliDeliverable && (React.createElement(MilestoneChecklistItemMasterDeliverableTablePart, { checkListItemId: ((_g = task.checkListItem) === null || _g === void 0 ? void 0 : _g.value) || 0, manualTaskId: task.id, canPrincipalUpdateWorkerPart: task.canPrincipalUpdateWorkerPart, isProjectTerminatedOrCompleted: task.projectStatus === ProjectStatus.Terminated ||
                                task.projectStatus === ProjectStatus.Completed })),
                        task.type === ManualTaskStatusEnum.DelegateMasterExecutiveQuestion && (React.createElement(ApprovedChecklistItemMasterExecutiveQuestionTablePart, { projectId: task.projectId, projectCodeName: task.projectCodeName || '', checkListId: task.checklistId || 0, checkListItemId: ((_h = task.checkListItem) === null || _h === void 0 ? void 0 : _h.value) || 0, manualTaskId: task.id, canPrincipalUpdateWorkerPart: task.canPrincipalUpdateWorkerPart, gateId: ((_j = task.gate) === null || _j === void 0 ? void 0 : _j.value) || 0, gateDisplayName: ((_k = task.gate) === null || _k === void 0 ? void 0 : _k.label) || '' })),
                        task.type === ManualTaskStatusEnum.DelegateMilestoneMasterExecutiveQuestion && (React.createElement(ApprovedMilestoneChecklistItemMasterExecutiveQuestionTablePart, { projectId: task.projectId, projectCodeName: task.projectCodeName || '', checkListId: task.checklistId || 0, checkListItemId: ((_l = task.checkListItem) === null || _l === void 0 ? void 0 : _l.value) || 0, manualTaskId: task.id, canPrincipalUpdateWorkerPart: task.canPrincipalUpdateWorkerPart, gateId: ((_m = task.gate) === null || _m === void 0 ? void 0 : _m.value) || 0, gateDisplayName: ((_o = task.gate) === null || _o === void 0 ? void 0 : _o.label) || '' })),
                        task.type === ManualTaskStatusEnum.DelegateQuestion && (React.createElement(ApprovedChecklistItemQuestionTablePart, { checkListId: task.checklistId || 0, checkListItemId: ((_p = task.checkListItem) === null || _p === void 0 ? void 0 : _p.value) || 0, manualTaskId: task.id, canPrincipalUpdateWorkerPart: task.canPrincipalUpdateWorkerPart }))))),
                !config.isAttachmentsHidden && task && task.type === ManualTaskStatusEnum.ProjectManagement && (React.createElement("div", { className: styles.dateRow },
                    React.createElement("div", { className: styles.wholeRow },
                        React.createElement("div", { className: styles.label }, "Attachments"),
                        !config.isAttachmentsDisabled && (React.createElement(FileUploadArea, { upload: this._uploadAttachment, onUpload: this.props.onFileChange })),
                        React.createElement(Attachments, { disabled: config.isAttachmentsDisabled, onRemove: this._removeAttachment, attachments: (_q = task === null || task === void 0 ? void 0 : task.attachments) !== null && _q !== void 0 ? _q : [], hideAddButton: true })))))));
    };
    __decorate([
        observable
    ], TaskInfo.prototype, "_deadLine", void 0);
    __decorate([
        observable
    ], TaskInfo.prototype, "_approver", void 0);
    __decorate([
        observable
    ], TaskInfo.prototype, "_assignee", void 0);
    __decorate([
        observable
    ], TaskInfo.prototype, "_gates", void 0);
    __decorate([
        observable
    ], TaskInfo.prototype, "_projects", void 0);
    __decorate([
        observable
    ], TaskInfo.prototype, "_description", void 0);
    __decorate([
        observable
    ], TaskInfo.prototype, "_answer", void 0);
    __decorate([
        observable
    ], TaskInfo.prototype, "_title", void 0);
    __decorate([
        action.bound
    ], TaskInfo.prototype, "_loadGates", null);
    __decorate([
        action.bound
    ], TaskInfo.prototype, "_handleDeadLineChange", null);
    __decorate([
        action.bound
    ], TaskInfo.prototype, "_handelApproverSelect", null);
    __decorate([
        action.bound
    ], TaskInfo.prototype, "_handelAssigneeSelect", null);
    __decorate([
        action.bound
    ], TaskInfo.prototype, "_handleDescriptionChange", null);
    __decorate([
        action.bound
    ], TaskInfo.prototype, "_handleAnswerChange", null);
    __decorate([
        action.bound
    ], TaskInfo.prototype, "_handleTitleChange", null);
    __decorate([
        action.bound
    ], TaskInfo.prototype, "_uploadAttachment", null);
    __decorate([
        action.bound
    ], TaskInfo.prototype, "_removeAttachment", null);
    __decorate([
        computed
    ], TaskInfo.prototype, "projectById", null);
    __decorate([
        action
    ], TaskInfo.prototype, "handleGateChange", void 0);
    __decorate([
        action
    ], TaskInfo.prototype, "handleProjectChange", void 0);
    TaskInfo = __decorate([
        observer
    ], TaskInfo);
    return TaskInfo;
}(React.Component));
