var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import styles from '../ApprovedChecklistDeliversTable.module.scss';
import { RatingPicker } from '@app/Components/RatingPicker/RatingPicker';
import { observable, action, computed } from 'mobx';
import { observer } from 'mobx-react';
import { ConfirmationCellWrapper } from '@app/Components/Grid/ConfirmationCellWrapper/ConfirmationCellWrapper';
import { ConfirmButton } from '@app/Components/ConfirmButton/ConfirmButton';
import { ValidationHint } from '../../../../../../../../Components/ValidationHint/ValidationHint';
import { CheckListItemRatingEnum, ChecklistSections } from '../../../../../../../../AppConstants';
export var AssessorRatingCell = /** @class */ (function (_super) {
    __extends(AssessorRatingCell, _super);
    function AssessorRatingCell(props) {
        var _this = _super.call(this, props) || this;
        _this._rating = props.rating;
        _this._originalRating = props.rating;
        return _this;
    }
    Object.defineProperty(AssessorRatingCell.prototype, "validationErrors", {
        get: function () {
            var _a;
            var errors = [];
            var rating = (_a = this.props, _a.rating), text = _a.text, textTitle = _a.textTitle, section = _a.section;
            if (section === ChecklistSections.Assessor || section === ChecklistSections.ProjectManager) {
                if (rating === CheckListItemRatingEnum.NotApplicable && !text) {
                    errors.push("".concat(textTitle, " has to be filled"));
                }
                return errors;
            }
            if (!text) {
                errors.push("".concat(textTitle, " has to be filled"));
            }
            if (rating === CheckListItemRatingEnum.NotApplicable) {
                return errors;
            }
            if (rating === CheckListItemRatingEnum.Default) {
                errors.push('Rating has to be set');
            }
            return errors;
        },
        enumerable: false,
        configurable: true
    });
    AssessorRatingCell.prototype.render = function () {
        var _a;
        var _this = this;
        var objectUniqueId = (_a = this.props, _a.objectUniqueId), disabled = _a.disabled, section = _a.section, isOkButtonVisible = _a.isOkButtonVisible, showValidationErrors = _a.showValidationErrors, onSaveOk = _a.onSaveOk, columnIndex = _a.columnIndex, rowIndex = _a.rowIndex;
        var valueChanged = this._rating !== this._originalRating;
        return (React.createElement(React.Fragment, null,
            React.createElement("section", null,
                React.createElement("div", { style: { position: 'relative' } },
                    React.createElement(ConfirmationCellWrapper, { onSave: this._handleSave, onCancel: this._handleCancel, objectUniqueId: objectUniqueId, disabled: disabled, section: this.props.section, valueChanged: valueChanged, columnIndex: columnIndex, rowIndex: rowIndex }, function (isEditing) { return (React.createElement("div", null,
                        React.createElement("div", { className: styles.ratingStatusWrapper },
                            React.createElement("div", { className: styles.ratingPicker },
                                React.createElement(RatingPicker, { rating: _this._rating, disabled: !isEditing, onChange: _this._handleRatingChange, defaultOpenMenu: isEditing })),
                            isOkButtonVisible && (React.createElement("div", { style: { position: 'relative', zIndex: 2 } },
                                React.createElement(ConfirmButton, { id: 'tooltip' + objectUniqueId + section, onClick: function () { return onSaveOk(); }, disabled: disabled })))))); })),
                React.createElement("div", { style: { textAlign: 'center' } },
                    React.createElement(ValidationHint, { id: objectUniqueId, isValid: !showValidationErrors, errors: this.validationErrors })))));
    };
    AssessorRatingCell.prototype._handleRatingChange = function (rating) {
        this._rating = rating;
    };
    AssessorRatingCell.prototype._handleCancel = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._rating = this._originalRating;
                return [2 /*return*/];
            });
        });
    };
    AssessorRatingCell.prototype._handleSave = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this._rating === this._originalRating)
                            return [2 /*return*/, this._handleCancel()];
                        this.props.onChange(this._rating);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.props.onSave()];
                    case 2:
                        _a.sent();
                        this._originalRating = this._rating;
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        observable
    ], AssessorRatingCell.prototype, "_rating", void 0);
    __decorate([
        observable
    ], AssessorRatingCell.prototype, "_originalRating", void 0);
    __decorate([
        computed
    ], AssessorRatingCell.prototype, "validationErrors", null);
    __decorate([
        action.bound
    ], AssessorRatingCell.prototype, "_handleRatingChange", null);
    __decorate([
        action.bound
    ], AssessorRatingCell.prototype, "_handleCancel", null);
    __decorate([
        action.bound
    ], AssessorRatingCell.prototype, "_handleSave", null);
    AssessorRatingCell = __decorate([
        observer
    ], AssessorRatingCell);
    return AssessorRatingCell;
}(React.Component));
