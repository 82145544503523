var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { Label } from 'reactstrap';
import { Input } from '@app/Components/Input/Input';
import styles from './CommentModal.module.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Modal } from '@app/Components/Modal/Modal';
export var CommentModal = /** @class */ (function (_super) {
    __extends(CommentModal, _super);
    function CommentModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._comment = '';
        _this._save = function () {
            var _a;
            var onSave = (_a = _this.props, _a.onSave), onToggle = _a.onToggle;
            onSave(_this._comment);
            onToggle();
            _this._comment = '';
        };
        return _this;
    }
    CommentModal.prototype.render = function () {
        var _a;
        var _this = this;
        var isOpen = (_a = this.props, _a.isOpen), onToggle = _a.onToggle;
        return (React.createElement("div", { className: styles.container1 },
            React.createElement(Modal, { isOpen: isOpen, toggle: onToggle, headerTitle: "Add Comment", minWidth: "600px", footerbuttons: this._getFooterButtons() },
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.field },
                        React.createElement(Label, null, "Comment"),
                        React.createElement(Input, { placeholder: this._comment, type: "text", value: this._comment, onChange: function (e) { return (_this._comment = e.target.value); } }))))));
    };
    CommentModal.prototype._getFooterButtons = function () {
        return [
            {
                color: "success",
                onClick: this._save,
                disabled: !this._comment,
                title: 'Save'
            },
            {
                onClick: this.props.onToggle,
                title: 'Close'
            }
        ];
    };
    __decorate([
        observable
    ], CommentModal.prototype, "_comment", void 0);
    CommentModal = __decorate([
        observer
    ], CommentModal);
    return CommentModal;
}(React.PureComponent));
