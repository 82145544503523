var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observer } from 'mobx-react';
import styles from './AddActionItemModal.module.scss';
import { observable, action, computed } from 'mobx';
import { Api } from '@app/Api';
import { DeadlinePicker } from '@app/Components/DeadlinePicker/DeadlinePicker';
import { FunctionPicker } from '@app/Components/FunctionPicker/FunctionPicker';
import { PromiseCompletion, CompletionType } from '@app/Classes/PromiseCompletion';
import { HtmlEditor } from '@app/Components/HtmlEditor/HtmlEditor';
import { GateProcessTypeEnum, ActionItemDeadLineEnum } from '@app/AppConstants';
import { Select } from '@app/Components/Select/Select';
import { Modal } from '@app/Components/Modal/Modal';
import { RowLabel } from '@app/Components/FormControls';
import { Button } from '@app/Components/Button/Button';
import { FaTrash } from 'react-icons/fa';
import { Col, Row } from 'reactstrap';
import FileUploadArea from '@app/Components/FileUploadArea/FileUploadArea';
import { PersonPicker } from '@app/Components/Pickers/PersonPicker/PersonPicker';
import { Input } from '@app/Components/Input/Input';
export var AddActionItemModal = /** @class */ (function (_super) {
    __extends(AddActionItemModal, _super);
    function AddActionItemModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._fileInputRef = React.createRef();
        _this._actionItem = {
            gateId: _this.props.gateId,
            functionPrincipalId: 0,
            gateProcessType: GateProcessTypeEnum.GateAssessment,
            deadlineType: ActionItemDeadLineEnum.BeforeNextGate,
            actionText: _this.props.actionText
        };
        _this._savingInProgress = new PromiseCompletion(CompletionType.Pending);
        _this._checklists = [];
        _this._checklistsSelectDisabled = false;
        _this._checklistItems = [];
        _this._checklistItemsSelectDisabled = false;
        _this._supporterFunctions = [];
        _this._files = [];
        _this._function = undefined;
        _this._functionDisabled = false;
        _this._showDatePicker = false;
        _this._actionForLoadFunctions = function (exceptId) {
            return Api.secured.getGateFunctions({ gateId: _this.props.gateId, exceptId: exceptId, isEnabledOnly: true });
        };
        _this._loadRelatedFunctionUser = function (params) {
            return Api.secured.getGatesRelatedFunctionsByProject(params);
        };
        _this.getValue = function (val, options) {
            var valueOptions = [];
            if (!val || !options)
                return valueOptions;
            val.forEach(function (id) {
                var strId = '' + id;
                var option = options.find(function (o) { return o.value === strId; });
                if (option)
                    valueOptions.push(option);
            });
            return valueOptions;
        };
        return _this;
    }
    AddActionItemModal.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var projectId, gateId, gateProcessType, selectedChecklistId, selectedChecklistItemId, selectedFunction, supporterFunctions, checklists, _a, id, name_1, code, supporterFunctions_1, relatedFunction;
            var _b, _c, _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        projectId = (_b = this.props, _b.projectId), gateId = _b.gateId, gateProcessType = _b.gateProcessType;
                        selectedChecklistId = (_c = this.props, _c.selectedChecklistId), selectedChecklistItemId = _c.selectedChecklistItemId, selectedFunction = _c.selectedFunction;
                        return [4 /*yield*/, Promise.all([
                                this._actionForLoadFunctions(),
                                Api.secured.getChecklistsLookupInfo({ projectId: projectId, gateId: gateId })
                            ])];
                    case 1:
                        supporterFunctions = (_d = _f.sent(), _d[0]), checklists = _d[1];
                        this._supporterFunctions = supporterFunctions;
                        this._checklists = checklists;
                        this._actionItem.gateId = gateId;
                        this._actionItem.gateProcessType = gateProcessType;
                        if (!selectedChecklistId) return [3 /*break*/, 3];
                        this._checklistsSelectDisabled = true;
                        this._actionItem.relatedChecklistsIds = [selectedChecklistId];
                        _a = this;
                        return [4 /*yield*/, Api.secured.getChecklistItemsLookupInfo({
                                checkListIds: this._actionItem.relatedChecklistsIds
                            })];
                    case 2:
                        _a._checklistItems = _f.sent();
                        _f.label = 3;
                    case 3:
                        if (selectedChecklistItemId) {
                            this._checklistItemsSelectDisabled = true;
                            this._actionItem.relatedChecklistItemsIds = [selectedChecklistItemId];
                        }
                        if (!selectedFunction) return [3 /*break*/, 5];
                        id = selectedFunction.id, name_1 = selectedFunction.name, code = selectedFunction.code;
                        this._defaultSelectedFunction = { id: id, name: name_1, code: code };
                        this._function = id;
                        this._functionDisabled = true;
                        return [4 /*yield*/, Promise.all([
                                this._actionForLoadFunctions(this._function),
                                this._loadRelatedFunctionUser({ projectId: projectId, gateId: gateId, functionId: this._function })
                            ])];
                    case 4:
                        supporterFunctions_1 = (_e = _f.sent(), _e[0]), relatedFunction = _e[1];
                        this._supporterFunctions = supporterFunctions_1;
                        this._selectedResponsiblePerson = relatedFunction === null || relatedFunction === void 0 ? void 0 : relatedFunction[0].principal;
                        _f.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    AddActionItemModal.prototype._onDeadlineSelect = function (option, date) {
        return __awaiter(this, void 0, void 0, function () {
            var val;
            return __generator(this, function (_a) {
                val = option.value;
                this._actionItem.deadlineDate = date || undefined;
                this._actionItem.deadlineType = val;
                if (this._actionItem.deadlineType === ActionItemDeadLineEnum.ByDate) {
                    this._showDatePicker = true;
                }
                else {
                    this._showDatePicker = false;
                }
                return [2 /*return*/];
            });
        });
    };
    Object.defineProperty(AddActionItemModal.prototype, "_isDisabled", {
        get: function () {
            var hasMarkup = this._actionItem.actionText && this._actionItem.actionText.indexOf('<p>') !== -1 ? true : false;
            return !(this._selectedResponsiblePerson &&
                this._actionItem.actionText &&
                (hasMarkup ? this._actionItem.actionText.length > 8 : this._actionItem.actionText.length > 2) &&
                this._actionItem.gateId &&
                this._actionItem.gateProcessType &&
                (this._actionItem.deadlineDate || this._actionItem.deadlineGateId || this._actionItem.deadlineType) &&
                !this._savingInProgress.isPending &&
                (this._function || (this._selectedResponsiblePerson && this._selectedResponsiblePerson.isActive)) &&
                (!this._selectedSupporterPerson || this._supporterFunction || this._selectedSupporterPerson.isActive));
        },
        enumerable: false,
        configurable: true
    });
    AddActionItemModal.prototype._toggleModal = function () {
        this._cleanActionItem();
        this.props.onToggle();
    };
    AddActionItemModal.prototype._handleSaveClick = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _createActionItemPromise;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this._function) {
                            this._actionItem.functionId = this._function;
                        }
                        if (this._selectedResponsiblePerson) {
                            this._actionItem.functionPrincipalId = this._selectedResponsiblePerson.id;
                        }
                        if (this._supporterFunction) {
                            this._actionItem.supportedFunctionId = this._supporterFunction;
                        }
                        if (this._selectedSupporterPerson) {
                            this._actionItem.supportedFunctionPrincipalId = this._selectedSupporterPerson.id;
                        }
                        this._actionItem.relatedChecklistItemsIds = (_a = this._actionItem.relatedChecklistItemsIds) === null || _a === void 0 ? void 0 : _a.filter(function (item) {
                            return _this._checklistItems.some(function (i) { return i.id === item; });
                        });
                        _createActionItemPromise = this._createActionItemWithFiles();
                        this._savingInProgress.subscribe(_createActionItemPromise);
                        return [4 /*yield*/, _createActionItemPromise];
                    case 1:
                        _b.sent();
                        if (!this.props.onSave) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.props.onSave()];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3:
                        this._toggleModal();
                        return [2 /*return*/];
                }
            });
        });
    };
    AddActionItemModal.prototype._createActionItemWithFiles = function () {
        return __awaiter(this, void 0, void 0, function () {
            var body, i, file;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.createActionItem({ body: this._actionItem })];
                    case 1:
                        body = (_a.sent()).body;
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this._files.length)) return [3 /*break*/, 5];
                        file = this._files[i];
                        return [4 /*yield*/, Api.secured.uploadActionItemDocument(body, {
                                file: file,
                                version: i + 1
                            })];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        i++;
                        return [3 /*break*/, 2];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    AddActionItemModal.prototype._onFunctionChange = function (selectedFunction) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var projectId, gateId, supporterFunctions, relatedFunction;
            var _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        projectId = (_b = this.props, _b.projectId), gateId = _b.gateId;
                        this._function = selectedFunction === null || selectedFunction === void 0 ? void 0 : selectedFunction.id;
                        if (this._function === this._supporterFunction || !selectedFunction) {
                            this._supporterFunction = undefined;
                        }
                        return [4 /*yield*/, Promise.all([
                                this._actionForLoadFunctions(this._function),
                                this._loadRelatedFunctionUser({ projectId: projectId, gateId: gateId, functionId: this._function })
                            ])];
                    case 1:
                        supporterFunctions = (_c = _d.sent(), _c[0]), relatedFunction = _c[1];
                        this._supporterFunctions = supporterFunctions;
                        this._selectedResponsiblePerson = selectedFunction ? (_a = relatedFunction === null || relatedFunction === void 0 ? void 0 : relatedFunction[0]) === null || _a === void 0 ? void 0 : _a.principal : undefined;
                        return [2 /*return*/];
                }
            });
        });
    };
    AddActionItemModal.prototype._handleResponsiblePersonSelect = function (value) {
        this._selectedResponsiblePerson = value === null || value === void 0 ? void 0 : value[0];
        if (!this._selectedResponsiblePerson) {
            this._supporterFunction = undefined;
            this._selectedSupporterPerson = undefined;
            this._function = undefined;
        }
    };
    AddActionItemModal.prototype._handleSupporterPersonSelect = function (value) {
        this._selectedSupporterPerson = value === null || value === void 0 ? void 0 : value[0];
    };
    AddActionItemModal.prototype._onSupportFunctionChange = function (selectedFunction) {
        return __awaiter(this, void 0, void 0, function () {
            var projectId, gateId, relatedSupporterFunctionUser;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        projectId = (_a = this.props, _a.projectId), gateId = _a.gateId;
                        this._supporterFunction = selectedFunction === null || selectedFunction === void 0 ? void 0 : selectedFunction.id;
                        return [4 /*yield*/, this._loadRelatedFunctionUser({
                                projectId: projectId,
                                gateId: gateId,
                                functionId: this._supporterFunction
                            })];
                    case 1:
                        relatedSupporterFunctionUser = _b.sent();
                        this._selectedSupporterPerson = selectedFunction ? relatedSupporterFunctionUser === null || relatedSupporterFunctionUser === void 0 ? void 0 : relatedSupporterFunctionUser[0].principal : undefined;
                        return [2 /*return*/];
                }
            });
        });
    };
    AddActionItemModal.prototype._cleanActionItem = function () {
        this._actionItem = {
            functionPrincipalId: 0,
            deadlineType: ActionItemDeadLineEnum.BeforeNextGate,
            gateId: this.props.gateId,
            gateProcessType: this.props.gateProcessType
        };
        this._function = undefined;
        this._supporterFunction = undefined;
        this._selectedResponsiblePerson = undefined;
        this._selectedSupporterPerson = undefined;
        this._files = [];
    };
    Object.defineProperty(AddActionItemModal.prototype, "defaultSupporterFunction", {
        get: function () {
            var _a;
            var _this = this;
            var _b;
            if (!this._supporterFunction)
                return { label: '', value: '' };
            var supporterFunction = (_b = this._supporterFunctions) === null || _b === void 0 ? void 0 : _b.find(function (x) { return x.id === _this._supporterFunction; });
            var name = (_a = supporterFunction, _a.name), code = _a.code, id = _a.id;
            return {
                label: "".concat(name, " (").concat(code, ")"),
                value: id.toString()
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddActionItemModal.prototype, "_checkListOptions", {
        get: function () {
            return this._checklists.map(function (ev) { return ({ label: '' + ev.codeName, value: '' + ev.id }); });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddActionItemModal.prototype, "_checkListItemsOptions", {
        get: function () {
            return this._checklistItems.map(function (ev) { return ({ label: '' + ev.codeName, value: '' + ev.id }); });
        },
        enumerable: false,
        configurable: true
    });
    AddActionItemModal.prototype._handleChecklistSelect = function (selected) {
        return __awaiter(this, void 0, void 0, function () {
            var ids, option, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!selected) {
                            this._actionItem.relatedChecklistsIds = undefined;
                            this._actionItem.relatedChecklistItemsIds = undefined;
                            this._checklistItems = [];
                            return [2 /*return*/];
                        }
                        if (Array.isArray(selected)) {
                            ids = selected.map(function (op) { return +op.value; });
                            this._actionItem.relatedChecklistsIds = ids;
                        }
                        else {
                            option = selected;
                            this._actionItem.relatedChecklistsIds = [+option.value];
                        }
                        _a = this;
                        return [4 /*yield*/, Api.secured.getChecklistItemsLookupInfo({
                                checkListIds: this._actionItem.relatedChecklistsIds
                            })];
                    case 1:
                        _a._checklistItems = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AddActionItemModal.prototype._handleChecklistItemSelect = function (selected) {
        if (!selected)
            return (this._actionItem.relatedChecklistItemsIds = undefined);
        if (Array.isArray(selected)) {
            var ids = selected.map(function (op) { return +op.value; });
            this._actionItem.relatedChecklistItemsIds = ids;
        }
        else {
            var option = selected;
            this._actionItem.relatedChecklistItemsIds = [+option.value];
        }
    };
    AddActionItemModal.prototype._handleDelete = function (index) {
        this._files.splice(index, 1);
    };
    AddActionItemModal.prototype._addFile = function (file) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._files.push(file);
                return [2 /*return*/];
            });
        });
    };
    AddActionItemModal.prototype.render = function () {
        var _this = this;
        var _a, _b;
        var isOpen = this.props.isOpen;
        var checkListPickerValue = this.getValue(this._actionItem.relatedChecklistsIds, this._checkListOptions);
        var checkListItemPickerValue = this.getValue(this._actionItem.relatedChecklistItemsIds, this._checkListItemsOptions);
        return (React.createElement(Modal, { isOpen: isOpen, headerTitle: "Create New Action Item", toggle: this._toggleModal, minWidth: '540px', footerbuttons: this._getFooterButtons() },
            React.createElement("div", { className: styles.actionItemContainer },
                React.createElement("div", { className: styles.row },
                    React.createElement(RowLabel, { text: "Checklists", className: styles.rowLabel }),
                    React.createElement("div", { className: styles.rowPicker },
                        React.createElement(Select, { styles: { menuPortal: function (base) { return (__assign(__assign({}, base), { zIndex: 1055 })); } }, options: this._checkListOptions, onChange: this._handleChecklistSelect, value: checkListPickerValue, isDisabled: this._checklistsSelectDisabled, isMulti: true, closeMenuOnSelect: false }))),
                React.createElement("div", { className: styles.row },
                    React.createElement(RowLabel, { text: "Checklist Items", className: styles.rowLabel }),
                    React.createElement("div", { className: styles.rowPicker },
                        React.createElement(Select, { styles: { menuPortal: function (base) { return (__assign(__assign({}, base), { zIndex: 1055 })); } }, options: this._checkListItemsOptions, onChange: this._handleChecklistItemSelect, value: checkListItemPickerValue, isDisabled: this._checklistItemsSelectDisabled, isMulti: true, closeMenuOnSelect: false }))),
                React.createElement("div", { className: styles.row },
                    React.createElement(RowLabel, { text: "Responsible", className: styles.rowLabel }),
                    React.createElement("div", { className: styles.rowPicker },
                        React.createElement(FunctionPicker, { actionForLoading: this._actionForLoadFunctions, isDisabled: this._functionDisabled, defaultFunction: this._defaultSelectedFunction, isClearable: true, onFunctionSelect: this._onFunctionChange }))),
                React.createElement("div", { className: styles.row },
                    React.createElement(RowLabel, { text: "Responsible Person", className: styles.rowLabel, mandatory: true }),
                    React.createElement("div", { className: styles.rowPicker }, this._function ? (React.createElement(Input, { type: "text", value: (_a = this._selectedResponsiblePerson) === null || _a === void 0 ? void 0 : _a.displayName, disabled: true })) : (React.createElement(PersonPicker, { onUsersSelect: this._handleResponsiblePersonSelect, isClearable: true, isCreatable: true })))),
                React.createElement("div", { className: styles.row },
                    React.createElement(RowLabel, { text: "Supporter", className: styles.rowLabel }),
                    React.createElement("div", { className: styles.rowPicker },
                        React.createElement(FunctionPicker, { overrideValue: this.defaultSupporterFunction, externalFunctions: this._supporterFunctions, isDisabled: !this._selectedResponsiblePerson, isClearable: true, onFunctionSelect: this._onSupportFunctionChange }))),
                React.createElement("div", { className: styles.row },
                    React.createElement(RowLabel, { text: "Supporter Person", className: styles.rowLabel }),
                    React.createElement("div", { className: styles.rowPicker }, this._supporterFunction ? (React.createElement(Input, { type: "text", value: (_b = this._selectedSupporterPerson) === null || _b === void 0 ? void 0 : _b.displayName, disabled: true })) : (React.createElement(PersonPicker, { onUsersSelect: this._handleSupporterPersonSelect, isClearable: true, isDisabled: !this._selectedResponsiblePerson, isCreatable: true })))),
                React.createElement("div", { className: styles.row },
                    React.createElement(RowLabel, { text: "Deadline", className: styles.rowLabel, mandatory: true }),
                    React.createElement("div", { className: styles.rowPicker },
                        React.createElement(DeadlinePicker, { value: {
                                val: this._actionItem.deadlineType || ActionItemDeadLineEnum.BeforeNextGate,
                                date: this._actionItem.deadlineDate || null
                            }, onValueChanged: this._onDeadlineSelect, datePickerVisible: this._showDatePicker, deadlineTypePickerVisible: true }))),
                React.createElement("div", { className: styles.textAreaWrapper },
                    React.createElement(RowLabel, { text: "Action Text", className: styles.rowLabel, mandatory: true }),
                    React.createElement(HtmlEditor, { height: 120, value: this._actionItem.actionText, onValueChanged: function (e) { return (_this._actionItem.actionText = e.value); } })),
                React.createElement(FileUploadArea, { upload: this._addFile }),
                React.createElement("div", { className: styles.documents }, this._files.map(function (f, i) { return (React.createElement(Row, { className: styles.documentRow, key: i },
                    React.createElement(Col, { sm: "8" }, f.name),
                    React.createElement(Col, { sm: "2" },
                        "Version: ",
                        i + 1),
                    React.createElement(Col, null,
                        React.createElement(Button, { size: "xs", color: "danger", onClick: function () { return _this._handleDelete(i); } },
                            React.createElement(FaTrash, null))))); })))));
    };
    AddActionItemModal.prototype._getFooterButtons = function () {
        return [
            {
                color: 'success',
                disabled: this._isDisabled,
                onClick: this._handleSaveClick,
                title: 'Save',
                validationError: this._isDisabled
                    ? 'At least one mandatory field is empty or/and an inactive person is selected as Responsible/Supporter'
                    : undefined
            },
            {
                onClick: this._toggleModal,
                title: 'Close'
            }
        ];
    };
    __decorate([
        observable
    ], AddActionItemModal.prototype, "_fileInputRef", void 0);
    __decorate([
        observable.shallow
    ], AddActionItemModal.prototype, "_actionItem", void 0);
    __decorate([
        observable
    ], AddActionItemModal.prototype, "_savingInProgress", void 0);
    __decorate([
        observable
    ], AddActionItemModal.prototype, "_checklists", void 0);
    __decorate([
        observable
    ], AddActionItemModal.prototype, "_checklistsSelectDisabled", void 0);
    __decorate([
        observable
    ], AddActionItemModal.prototype, "_checklistItems", void 0);
    __decorate([
        observable
    ], AddActionItemModal.prototype, "_checklistItemsSelectDisabled", void 0);
    __decorate([
        observable
    ], AddActionItemModal.prototype, "_supporterFunctions", void 0);
    __decorate([
        observable
    ], AddActionItemModal.prototype, "_defaultSelectedFunction", void 0);
    __decorate([
        observable
    ], AddActionItemModal.prototype, "_files", void 0);
    __decorate([
        observable
    ], AddActionItemModal.prototype, "_function", void 0);
    __decorate([
        observable
    ], AddActionItemModal.prototype, "_functionDisabled", void 0);
    __decorate([
        observable
    ], AddActionItemModal.prototype, "_supporterFunction", void 0);
    __decorate([
        observable
    ], AddActionItemModal.prototype, "_showDatePicker", void 0);
    __decorate([
        observable
    ], AddActionItemModal.prototype, "_selectedResponsiblePerson", void 0);
    __decorate([
        observable
    ], AddActionItemModal.prototype, "_selectedSupporterPerson", void 0);
    __decorate([
        action.bound
    ], AddActionItemModal.prototype, "_onDeadlineSelect", null);
    __decorate([
        computed
    ], AddActionItemModal.prototype, "_isDisabled", null);
    __decorate([
        action.bound
    ], AddActionItemModal.prototype, "_toggleModal", null);
    __decorate([
        action.bound
    ], AddActionItemModal.prototype, "_handleSaveClick", null);
    __decorate([
        action.bound
    ], AddActionItemModal.prototype, "_createActionItemWithFiles", null);
    __decorate([
        action.bound
    ], AddActionItemModal.prototype, "_onFunctionChange", null);
    __decorate([
        action.bound
    ], AddActionItemModal.prototype, "_handleResponsiblePersonSelect", null);
    __decorate([
        action.bound
    ], AddActionItemModal.prototype, "_handleSupporterPersonSelect", null);
    __decorate([
        action.bound
    ], AddActionItemModal.prototype, "_onSupportFunctionChange", null);
    __decorate([
        action.bound
    ], AddActionItemModal.prototype, "_cleanActionItem", null);
    __decorate([
        action.bound
    ], AddActionItemModal.prototype, "_loadRelatedFunctionUser", void 0);
    __decorate([
        computed
    ], AddActionItemModal.prototype, "defaultSupporterFunction", null);
    __decorate([
        computed
    ], AddActionItemModal.prototype, "_checkListOptions", null);
    __decorate([
        computed
    ], AddActionItemModal.prototype, "_checkListItemsOptions", null);
    __decorate([
        action.bound
    ], AddActionItemModal.prototype, "_handleChecklistSelect", null);
    __decorate([
        action.bound
    ], AddActionItemModal.prototype, "_handleChecklistItemSelect", null);
    __decorate([
        action.bound
    ], AddActionItemModal.prototype, "_handleDelete", null);
    __decorate([
        action.bound
    ], AddActionItemModal.prototype, "_addFile", null);
    AddActionItemModal = __decorate([
        observer
    ], AddActionItemModal);
    return AddActionItemModal;
}(React.PureComponent));
