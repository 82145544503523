var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { observer } from 'mobx-react';
import { colorThemesStore } from '@app/Stores/ColorThemesStore';
import { Select } from '../Select/Select';
import styles from './WidgetWithSelect.module.scss';
export var WidgetWithSelect = /** @class */ (function (_super) {
    __extends(WidgetWithSelect, _super);
    function WidgetWithSelect() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    WidgetWithSelect.prototype.render = function () {
        var _a, _b;
        var title = (_a = this.props, _a.title), titleBackground = _a.titleBackground, children = _a.children, color = _a.color, height = _a.height, maxHeight = _a.maxHeight, options = _a.options, selectedOption = _a.selectedOption, isSelectDisabled = _a.isSelectDisabled, onOptionSelect = _a.onOptionSelect;
        var white = (_b = colorThemesStore.appColors, _b.white), gray = _b.gray;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.header, style: { background: titleBackground ? titleBackground : gray, color: color || white } },
                React.createElement("div", { className: styles.title }, title),
                React.createElement(Select, { className: styles.select, options: options, value: selectedOption, onChange: function (option) {
                        onOptionSelect(option);
                    }, isSearchable: false, isClearable: false, isDisabled: isSelectDisabled })),
            React.createElement("div", { style: { height: height || '100%', maxHeight: maxHeight || '100%' }, className: styles.body }, children)));
    };
    WidgetWithSelect = __decorate([
        observer
    ], WidgetWithSelect);
    return WidgetWithSelect;
}(React.PureComponent));
