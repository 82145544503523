var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observer } from 'mobx-react';
import { action, observable, computed } from 'mobx';
import { FunctionRow } from './FunctionRow';
import { Modal } from '@app/Components/Modal/Modal';
export var EditFunctionDialog = /** @class */ (function (_super) {
    __extends(EditFunctionDialog, _super);
    function EditFunctionDialog() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._existingFunctionRanges = [];
        _this._createRowsInfo = [];
        _this._originalFunctionRanges = [];
        return _this;
    }
    EditFunctionDialog.prototype.componentDidMount = function () {
        this._refreshFunctionRanges();
        this._addEmptyRow();
    };
    EditFunctionDialog.prototype.componentDidUpdate = function () {
        this._refreshFunctionRanges();
    };
    EditFunctionDialog.prototype._refreshFunctionRanges = function () {
        var ranges = this.props.ranges;
        if (JSON.stringify(ranges) !== JSON.stringify(this._originalFunctionRanges)) {
            this._originalFunctionRanges = ranges;
            this._existingFunctionRanges = Object.assign([], ranges);
        }
    };
    EditFunctionDialog.prototype._handleSaveClick = function () {
        return __awaiter(this, void 0, void 0, function () {
            var createCandidates, updateCandidates;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        createCandidates = this._createRowsInfo
                            .filter(function (ri) { var _a; return (_a = ri.currentFunction) === null || _a === void 0 ? void 0 : _a.id; })
                            .map(function (ri) {
                            var _a;
                            var fr = {
                                weekFrom: ri.weekFrom || _this.props.cellInfo.week,
                                weekTo: ri.weekTo || _this.props.cellInfo.week,
                                functionId: (_a = ri.currentFunction) === null || _a === void 0 ? void 0 : _a.id,
                                templateId: _this.props.cellInfo.itemId,
                                approvalType: ri.type
                            };
                            return fr;
                        });
                        return [4 /*yield*/, this.props.create(createCandidates)];
                    case 1:
                        _a.sent();
                        updateCandidates = this._existingFunctionRanges.filter(function (fr) { return fr.id && fr.functionCode; });
                        return [4 /*yield*/, this.props.update(updateCandidates)];
                    case 2:
                        _a.sent();
                        this.props.onSave();
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(EditFunctionDialog.prototype, "_hasChanges", {
        get: function () {
            var _a;
            if (this._createRowsInfo.length > 1)
                return true;
            if (this._createRowsInfo[0]) {
                var currentFunction = (_a = this._createRowsInfo[0], _a.currentFunction), weekFrom = _a.weekFrom, weekTo = _a.weekTo, type = _a.type;
                if (currentFunction && weekFrom && weekTo && type)
                    return true;
            }
            for (var i = 0; i < this._existingFunctionRanges.length; i++) {
                var oldFr = this._originalFunctionRanges[i];
                var newFr = this._existingFunctionRanges[i];
                if (oldFr && newFr) {
                    var sameFunction = oldFr.functionId === newFr.functionId;
                    var sameWeekFrom = oldFr.weekFrom === newFr.weekFrom;
                    var sameWeekTo = oldFr.weekTo === newFr.weekTo;
                    var sameType = oldFr.approvalType === newFr.approvalType;
                    if (!sameFunction || !sameWeekFrom || !sameWeekTo || !sameType)
                        return true;
                }
            }
            return false;
        },
        enumerable: false,
        configurable: true
    });
    EditFunctionDialog.prototype._handleRowChange = function (rowInfo, functionRange, ind) {
        var weekFrom = rowInfo.weekFrom, weekTo = rowInfo.weekTo;
        var defaultWeek = weekFrom || weekTo || this.props.cellInfo.week;
        var newFr = Object.assign({}, functionRange);
        newFr.weekFrom = defaultWeek;
        newFr.weekTo = weekTo || defaultWeek;
        if (ind > -1)
            this._existingFunctionRanges.splice(ind, 1, newFr);
    };
    EditFunctionDialog.prototype._handleCreateRowInfoChange = function (newInfo, oldInfo, index) {
        this._createRowsInfo.splice(index, 1, newInfo);
    };
    EditFunctionDialog.prototype._addEmptyRow = function () {
        var empty = {
            weekFrom: null,
            weekTo: null,
            currentFunction: null,
            type: null
        };
        this._createRowsInfo.push(empty);
    };
    EditFunctionDialog.prototype._getRowInfo = function (functionRange) {
        var functions = this.props.cellInfo.functions;
        var rowInfo = {
            currentFunction: functions.find(function (f) { return f.id === functionRange.functionId; }) || null,
            weekFrom: functionRange.weekFrom,
            weekTo: functionRange.weekTo,
            type: functionRange.approvalType
        };
        return rowInfo;
    };
    EditFunctionDialog.prototype._handleDeleteClick = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.props.delete(id)];
                    case 1:
                        _a.sent();
                        this.props.onSave();
                        return [2 /*return*/];
                }
            });
        });
    };
    EditFunctionDialog.prototype.render = function () {
        var _this = this;
        var functions = this.props.cellInfo.functions;
        var selectedFunction = this.props.selectedFunction;
        var hasFunction = !!selectedFunction;
        return (React.createElement(Modal, { isOpen: true, toggle: this.props.onCancel, headerTitle: React.createElement(React.Fragment, null,
                hasFunction && React.createElement("span", null, "Edit Function"),
                !hasFunction && React.createElement("span", null, "Add Function")), footerbuttons: this._getFooterButtons(), maxWidth: "95%" },
            this._existingFunctionRanges.map(function (fr, i) { return (React.createElement(FunctionRow, { key: "update ".concat(i), functions: functions, rowInfo: _this._getRowInfo(fr), onDelete: function () { return _this._handleDeleteClick(fr.id); }, onRowInfoChange: function (rowInfo) { return _this._handleRowChange(rowInfo, fr, i); }, type: _this.props.cellInfo.type })); }),
            React.createElement("hr", null),
            "Add new",
            this._createRowsInfo.map(function (rowInfo, i) { return (React.createElement(FunctionRow, { key: "create ".concat(i), functions: functions, isCreation: true, rowInfo: rowInfo, onRowInfoChange: function (newInfo) { return _this._handleCreateRowInfoChange(newInfo, rowInfo, i); }, type: _this.props.cellInfo.type })); })));
    };
    EditFunctionDialog.prototype._getFooterButtons = function () {
        return [
            {
                color: 'success',
                disabled: !this._hasChanges,
                onClick: this._handleSaveClick,
                title: 'Save'
            },
            {
                onClick: this.props.onCancel,
                title: 'Close'
            }
        ];
    };
    __decorate([
        observable
    ], EditFunctionDialog.prototype, "_existingFunctionRanges", void 0);
    __decorate([
        observable
    ], EditFunctionDialog.prototype, "_createRowsInfo", void 0);
    __decorate([
        action.bound
    ], EditFunctionDialog.prototype, "_refreshFunctionRanges", null);
    __decorate([
        action.bound
    ], EditFunctionDialog.prototype, "_handleSaveClick", null);
    __decorate([
        computed
    ], EditFunctionDialog.prototype, "_hasChanges", null);
    __decorate([
        action.bound
    ], EditFunctionDialog.prototype, "_handleRowChange", null);
    __decorate([
        action.bound
    ], EditFunctionDialog.prototype, "_handleCreateRowInfoChange", null);
    __decorate([
        action.bound
    ], EditFunctionDialog.prototype, "_addEmptyRow", null);
    EditFunctionDialog = __decorate([
        observer
    ], EditFunctionDialog);
    return EditFunctionDialog;
}(React.PureComponent));
