var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observer } from 'mobx-react';
import { HtmlEditor } from '@app/Components/HtmlEditor/HtmlEditor';
import { observable, action } from 'mobx';
import HTMLReactParser from 'html-react-parser';
import { WithCopy } from '@app/Components/LinkWithCopy/LinkWithCopy';
import { ConfirmationCellWrapper } from '../ConfirmationCellWrapper/ConfirmationCellWrapper';
export var EditCell = /** @class */ (function (_super) {
    __extends(EditCell, _super);
    function EditCell() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EditCell.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._value = this.props.value;
                this._originalValue = this.props.value;
                return [2 /*return*/];
            });
        });
    };
    EditCell.prototype._handleChange = function (e) {
        var _a;
        var parts = (_a = e.value) === null || _a === void 0 ? void 0 : _a.split('<p>');
        if (!parts)
            return (this._value = '');
        var isEmpty = true;
        for (var i = 0; i < parts.length; i++) {
            var p = parts[i];
            if (p.indexOf('</p>') !== -1) {
                p = p.replace('</p>', '');
            }
            if (p.length > 1) {
                // special chars in the end
                isEmpty = false;
                break;
            }
        }
        this._value = isEmpty ? '' : e.value;
    };
    EditCell.prototype._handleSaveClick = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this._value === '' && this._originalValue === null) {
                            return [2 /*return*/, this._handleCancelClick()];
                        }
                        this._value = (_a = this._value) === null || _a === void 0 ? void 0 : _a.replace(/(<a href=.*?) target="_self"/g, '$1 target="_blank"');
                        this.props.onChange(this._value);
                        return [4 /*yield*/, this.props.onSave()];
                    case 1:
                        _b.sent();
                        this._originalValue = this._value;
                        return [2 /*return*/];
                }
            });
        });
    };
    EditCell.prototype._handleCancelClick = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._value = this._originalValue;
                return [2 /*return*/];
            });
        });
    };
    EditCell.prototype.render = function () {
        var _a;
        var _this = this;
        var disabled = (_a = this.props, _a.disabled), section = _a.section, columnIndex = _a.columnIndex, rowIndex = _a.rowIndex;
        var valueChanged = this._value !== this._originalValue;
        return (React.createElement(ConfirmationCellWrapper, { onSave: this._handleSaveClick, onCancel: this._handleCancelClick, objectUniqueId: this.props.objectUniqueId, disabled: disabled, valueChanged: valueChanged, section: section, columnIndex: columnIndex, rowIndex: rowIndex }, function (isEditing) {
            return isEditing ? (React.createElement("div", null,
                _this.props.children,
                React.createElement(HtmlEditor, { height: 180, value: _this._value, isFocused: true, onValueChanged: _this._handleChange }))) : (React.createElement("div", { style: { wordBreak: 'break-word' } },
                _this.props.children,
                _this.props.withCopy ? (React.createElement(WithCopy, { text: _this._originalValue || '' }, HTMLReactParser(_this._originalValue || ''))) : (HTMLReactParser(_this._originalValue || ''))));
        }));
    };
    __decorate([
        observable
    ], EditCell.prototype, "_value", void 0);
    __decorate([
        observable
    ], EditCell.prototype, "_originalValue", void 0);
    __decorate([
        action.bound
    ], EditCell.prototype, "componentDidMount", null);
    __decorate([
        action.bound
    ], EditCell.prototype, "_handleChange", null);
    __decorate([
        action.bound
    ], EditCell.prototype, "_handleSaveClick", null);
    __decorate([
        action.bound
    ], EditCell.prototype, "_handleCancelClick", null);
    EditCell = __decorate([
        observer
    ], EditCell);
    return EditCell;
}(React.PureComponent));
