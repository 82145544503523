var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { Collapse } from 'reactstrap';
import { NavTab } from 'react-router-tabs';
import { Loader } from '@app/Components/Loader/Loader';
import styles from './ProjectView.module.scss';
import { ProjectViewDescription } from './ProjectViewDescription/ProjectViewDescription';
import { ProjectViewTitle } from './ProjectViewTitle/ProjectViewTitle';
import { ProjectSetup } from './ProjectViewTabs/ProjectSetup/ProjectSetup';
import { GatePreparations } from './ProjectViewTabs/GatePreparations/GatePreparations';
import { GateAssessments } from './ProjectViewTabs/GateAssessments/GateAssessments';
import GateDeliverables from './ProjectViewTabs/GateDeliverables/GateDeliverables';
import { GateMeetings } from './ProjectViewTabs/GateMeetings/GateMeetings';
import { MilestoneGateMeetings } from './ProjectViewTabs/MilestoneGateMeetings/MilestoneGateMeetings';
import projectCompleteStatusImg from 'assets/images/prj.c.png';
import projectApprovedStatusImg from 'assets/images/prj.a.png';
import projectTerminatedStatusImg from 'assets/images/prj.t.png';
import { Api } from '@app/Api';
import { AppData, ImportProjectFromNorrdByIdResultEnum, ProjectState, ProjectStatus, ProjectType } from '@app/AppConstants';
import { TgTabs } from '@app/Components/TgTabs/TgTabs';
import { TgTabsContent } from '@app/Components/TgTabs/TgTabsContent';
import { NotFound } from '../NotFound/NotFound';
import { Button } from '@app/Components/Button/Button';
import { Link } from '@app/Components/Link/Link';
import { ProjectStateContext } from '@app/Pages/ProjectView/Components/ProjectStateContext';
import { NotificationHandler } from '@app/Components/ToastNotification/ToastNotification';
import { ProjectStatuses } from '@app/Components/ProjectsOverview/ProjectsOverview';
import ProjectDocuments from './ProjectViewTabs/Documents/ProjectDocuments';
export var ProjectView = /** @class */ (function (_super) {
    __extends(ProjectView, _super);
    function ProjectView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._project = null;
        _this._isDescriptionOpen = false;
        _this._handleFavouriteClick = function (checked) { return __awaiter(_this, void 0, void 0, function () {
            var projectId;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        projectId = (_a = this._project) === null || _a === void 0 ? void 0 : _a.id;
                        if (!projectId)
                            return [2 /*return*/];
                        if (!checked) return [3 /*break*/, 2];
                        return [4 /*yield*/, Api.secured.addFavoriteProjectForCurrentPrincipal({ projectId: projectId })];
                    case 1:
                        _b.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, Api.secured.deleteFavoriteProjectForCurrentPrincipal({ projectId: projectId })];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4: return [4 /*yield*/, this._loadProject()];
                    case 5:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this._handleHideNAItemsClick = function (checked) { return __awaiter(_this, void 0, void 0, function () {
            var projectId;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        projectId = (_a = this._project) === null || _a === void 0 ? void 0 : _a.id;
                        if (!projectId)
                            return [2 /*return*/];
                        return [4 /*yield*/, Api.secured.updateHideNAItems({ projectId: projectId, isEnabled: checked })];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this._loadProject()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this._handleShowRedDotDeliverableClick = function (checked) { return __awaiter(_this, void 0, void 0, function () {
            var projectId;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        projectId = (_a = this._project) === null || _a === void 0 ? void 0 : _a.id;
                        if (!projectId)
                            return [2 /*return*/];
                        return [4 /*yield*/, Api.secured.updateShowRedDotDeliverable({ projectId: projectId, isEnabled: checked })];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this._loadProject()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.renderCustomButtons = function () {
            if (!_this._project)
                return undefined;
            var result = [];
            if (_this._project.norrdProjectLink) {
                result.push(React.createElement(Button, { size: "sm", color: "success", onClick: function () { return _this._synchronizeWithNorrd(); } }, "Sync with NORRD"));
            }
            result.push(React.createElement(Link, { to: "/tasks?".concat(AppData.ProjectCodeNameQueryParameter, "=").concat(_this._project.codeName) },
                React.createElement(Button, { size: "sm", color: "success" }, "Tasks")));
            return result;
        };
        _this._renderProjectTitle = function () {
            var _a;
            if (!_this._project)
                return null;
            var status = (_a = _this._project, _a.status), codeName = _a.codeName, displayName = _a.displayName;
            var projectStatusImg;
            if (status) {
                switch (status) {
                    case ProjectStatuses.APPROVED:
                        projectStatusImg = projectApprovedStatusImg;
                        break;
                    case ProjectStatuses.COMPLETED:
                        projectStatusImg = projectCompleteStatusImg;
                        break;
                    case ProjectStatuses.TERMINATED:
                        projectStatusImg = projectTerminatedStatusImg;
                        break;
                    default:
                        projectStatusImg = '';
                }
            }
            return (React.createElement(React.Fragment, null,
                !!status && React.createElement("img", { className: styles.statusImage, src: projectStatusImg, title: status, alt: status }),
                codeName,
                " / ",
                displayName));
        };
        return _this;
    }
    ProjectView.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadProject()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectView.prototype.componentDidUpdate = function (prevProps) {
        return __awaiter(this, void 0, void 0, function () {
            var match1, match2;
            return __generator(this, function (_a) {
                if (this.props.location !== prevProps.location) {
                    match1 = /(prj|ptg)-(\d+)\/([^/]*)(\/[^/]*)?(\/.*)?$/g.exec(prevProps.location.pathname);
                    match2 = /(prj|ptg)-(\d+)\/([^/]*)(\/[^/]*)?(\/.*)?$/g.exec(this.props.location.pathname);
                    if (match1 !== null && match2 !== null && match1[2] !== match2[2]) {
                        if (match1[2] !== 'setup') {
                            match1[3] && (this._lastGateId = parseInt(match1[3].replace(/\/g-/gi, '')));
                        }
                        if (match2[2] !== 'setup') {
                            this._lastGateId &&
                                this.props.location.pathname.search('/' + this._lastGateId) === -1 &&
                                this.props.history.push(this.props.location.pathname + '/g-' + this._lastGateId);
                        }
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    ProjectView.prototype._loadProject = function () {
        return __awaiter(this, void 0, void 0, function () {
            var codeName, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        codeName = this.props.match.params.codeName;
                        _a = this;
                        return [4 /*yield*/, Api.secured.getProject(codeName)];
                    case 1:
                        _a._project = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectView.prototype._handleTemplateChosen = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadProject()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ProjectView.prototype._handleUpdateAfterChanges = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this._project && this._project.projectState != ProjectState.IsEmpty)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._loadProject()];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ProjectView.prototype._synchronizeWithNorrd = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var projectId, result, resultMessage;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        projectId = (_a = this._project) === null || _a === void 0 ? void 0 : _a.id;
                        if (!projectId)
                            return [2 /*return*/];
                        return [4 /*yield*/, Api.secured.synchronizeWithNorrd({ projectId: projectId })];
                    case 1:
                        result = (_b = _c.sent(), _b.result), resultMessage = _b.resultMessage;
                        switch (result) {
                            case ImportProjectFromNorrdByIdResultEnum.NoChanges:
                                NotificationHandler.showInfo(resultMessage, { autoClose: 15000 });
                                break;
                            case ImportProjectFromNorrdByIdResultEnum.Success:
                                NotificationHandler.showSuccess(resultMessage, { autoClose: 15000 });
                                break;
                            case ImportProjectFromNorrdByIdResultEnum.Fail:
                                NotificationHandler.showWarning(resultMessage, { autoClose: 15000 });
                                break;
                        }
                        return [4 /*yield*/, this._loadProject()];
                    case 2:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectView.prototype.render = function () {
        var _this = this;
        var _a;
        var match = this.props.match;
        if (!this._project) {
            return React.createElement(Loader, { style: { width: 100, height: 100 } });
        }
        var isMilestoneProject = this._project.type === ProjectType.Milestone;
        var isProjectTerminatedOrCompleted = this._project.status === ProjectStatus.Terminated || this._project.status === ProjectStatus.Completed;
        return (React.createElement("div", { className: styles.container },
            React.createElement(ProjectViewTitle, { onFavouriteToggle: this._handleFavouriteClick, onHideNAItemsToggle: this._handleHideNAItemsClick, onShowRedDotDeliverableToggle: this._handleShowRedDotDeliverableClick, isFavourite: this._project.isProjectFavorite, isHideNAItemsEnabled: this._project.needToHideNAItems, isShowRedDotDeliverableEnabled: this._project.needToShowRedDotDeliverable || false, status: this._project.status },
                React.createElement("div", { className: this._isDescriptionOpen ? styles.close : styles.open, onClick: function () { return (_this._isDescriptionOpen = !_this._isDescriptionOpen); } }, this._renderProjectTitle())),
            React.createElement("div", { className: styles.description },
                React.createElement(Collapse, { isOpen: this._isDescriptionOpen },
                    React.createElement(ProjectViewDescription, { project: this._project, onChange: this._loadProject }))),
            React.createElement(TgTabs, { startPathWith: match.url, customButtons: this.renderCustomButtons() },
                React.createElement(NavTab, { to: "/setup" }, "Project Setup"),
                React.createElement(NavTab, { to: "/documents" }, "Documents"),
                React.createElement(NavTab, { to: "/deliverables" }, isMilestoneProject ? 'Milestone Deliverables' : 'Gate Deliverables'),
                ((_a = this._project) === null || _a === void 0 ? void 0 : _a.projectState) === ProjectState.Full && (React.createElement(NavTab, { to: "/preparations" }, "Gate Preparations")),
                !isMilestoneProject && React.createElement(NavTab, { to: "/assessments" }, "Gate Assessments"),
                !isMilestoneProject && React.createElement(NavTab, { to: "/gate-meeting" }, "Gate Meeting"),
                isMilestoneProject && React.createElement(NavTab, { to: "/milestone-meeting" }, "Milestone Meeting")),
            React.createElement(ProjectStateContext.Provider, { value: {
                    hideNAItems: this._project.needToHideNAItems,
                    showRedDotDeliverable: this._project.needToShowRedDotDeliverable || false,
                    isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted
                } },
                React.createElement(TgTabsContent, null,
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: "".concat(match.url, "/setup") },
                            React.createElement(ProjectSetup, { id: this._project.id, projectCodeName: this._project.codeName, isEmpty: this._project.projectState == ProjectState.IsEmpty, canPrincipalInitFromTemplate: this._project.canPrincipalInitFromTemplate, canPrincipalRemoveProject: this._project.canPrincipalRemoveProject, canPrincipalAddGate: this._project.canPrincipalAddGate, canPrincipalManipulateWithReader: this._project.canPrincipalManipulateWithReader, canPrincipalRedoGate: this._project.canPrincipalRedoGate, canPrincipalUpdateStatus: this._project.canPrincipalUpdateStatus, canPrincipalRollbackStatus: this._project.canPrincipalRollbackStatus, onChooseTemplateHandler: this._handleTemplateChosen, onUpdateProjectChangeHandler: this._handleUpdateAfterChanges, isNotififcationsEnabled: this._project.isNotificationsEnabled, projectType: ProjectType[this._project.type], isNorrdProject: !!this._project.norrdProjectLink, status: this._project.status, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })),
                        React.createElement(Route, { path: "".concat(match.url, "/documents") },
                            React.createElement(ProjectDocuments, { project: this._project })),
                        this._project.projectState === ProjectState.HasOnlyMasterCheckLists && (React.createElement(Route, { path: "".concat(match.url, "/preparations") },
                            React.createElement(Redirect, { to: "".concat(match.url, "/assessments") }))),
                        this._project.type === ProjectType.Milestone && (React.createElement(Route, { path: "".concat(match.url, "/preparations") },
                            React.createElement(Redirect, { to: "".concat(match.url, "/milestone-meeting") }))),
                        this._project.type === ProjectType.Milestone && (React.createElement(Route, { path: "".concat(match.url, "/assessments") },
                            React.createElement(Redirect, { to: "".concat(match.url, "/milestone-meeting") }))),
                        this._project.projectState === ProjectState.Full && (React.createElement(Route, { path: "".concat(match.url, "/preparations") },
                            React.createElement(GatePreparations, { projectId: this._project.id, projectCodeName: this._project.codeName, projectState: ProjectState[this._project.projectState], projectType: ProjectType[this._project.type] }))),
                        React.createElement(Route, { path: "".concat(match.url, "/deliverables") },
                            React.createElement(GateDeliverables, { projectId: this._project.id, projectState: ProjectState[this._project.projectState], projectType: ProjectType[this._project.type] })),
                        React.createElement(Route, { path: "".concat(match.url, "/assessments") },
                            React.createElement(GateAssessments, { projectId: this._project.id, projectCodeName: this._project.codeName, projectState: ProjectState[this._project.projectState], projectType: ProjectType[this._project.type], isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })),
                        React.createElement(Route, { path: "".concat(match.url, "/gate-meeting") },
                            React.createElement(GateMeetings, { projectId: this._project.id, projectState: ProjectState[this._project.projectState], projectType: ProjectType[this._project.type], isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })),
                        React.createElement(Route, { path: "".concat(match.url, "/milestone-meeting") },
                            React.createElement(MilestoneGateMeetings, { projectId: this._project.id, projectCodeName: this._project.codeName, projectState: ProjectState[this._project.projectState], projectType: ProjectType[this._project.type], isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })),
                        React.createElement(Route, { exact: true, path: "".concat(match.url) },
                            React.createElement(Redirect, { to: "".concat(match.url, "/setup") })),
                        React.createElement(Route, { path: "*", component: NotFound }))))));
    };
    __decorate([
        observable
    ], ProjectView.prototype, "_project", void 0);
    __decorate([
        observable
    ], ProjectView.prototype, "_isDescriptionOpen", void 0);
    __decorate([
        observable
    ], ProjectView.prototype, "_lastGateId", void 0);
    __decorate([
        action.bound
    ], ProjectView.prototype, "_loadProject", null);
    __decorate([
        action.bound
    ], ProjectView.prototype, "_handleTemplateChosen", null);
    __decorate([
        action.bound
    ], ProjectView.prototype, "_handleUpdateAfterChanges", null);
    __decorate([
        action.bound
    ], ProjectView.prototype, "_synchronizeWithNorrd", null);
    ProjectView = __decorate([
        observer
    ], ProjectView);
    return ProjectView;
}(React.PureComponent));
