var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { withRouter } from 'react-router-dom';
import { FaTrash, FaUndo } from 'react-icons/fa';
import styles from './ProjectDocumentFilesView.module.scss';
import columnSharedStyles from '@app/Components/Grid/SharedStyles/column-shared-styles.module.scss';
import { createUniqueId } from '@app/Helpers/UidHelper/UidHelper';
import { ComponentUrls, ObjectType } from '@app/AppConstants';
import { Button } from '@app/Components/Button/Button';
import { Grid } from '@app/Components/Grid/Grid';
import { Column } from 'devextreme-react/data-grid';
import { Api } from '@app/Api';
import { EditCell } from '@app/Components/Grid/EditCell/EditCell';
import { DownloadButton } from '@app/Components/DownloadButton/DownloadButton';
import { CopyToClipBoardButton } from '@app/Components/CopyToClipboardButton/CopyToClipboardButton';
import { ConfirmationDialog } from '@app/Widgets/ConfirmationDialog/ConfirmationDialog';
import { TextCell } from '@app/Components/Grid/TextCell/TextCell';
import { DateTimeBox } from '@app/Widgets/DateTimeBox/DateTimeBox';
var ProjectDocumentFilesView = /** @class */ (function (_super) {
    __extends(ProjectDocumentFilesView, _super);
    function ProjectDocumentFilesView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._gridRef = React.createRef();
        _this._isConfirmationDialogOpen = false;
        _this._currentFileInformationIdId = undefined;
        return _this;
    }
    ProjectDocumentFilesView.prototype._fileDeleteClickHandler = function (fileInformationId) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!(((_c = (_b = (_a = this.props.filesInfoModel) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b.filter(function (x) { return !x.documentFileIsDeleted; })) === null || _c === void 0 ? void 0 : _c.length) === 1)) return [3 /*break*/, 1];
                        this._currentFileInformationIdId = fileInformationId;
                        this._isConfirmationDialogOpen = true;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this._deleteFile(fileInformationId)];
                    case 2:
                        _d.sent();
                        _d.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocumentFilesView.prototype._deleteFile = function (fileInformationId, isLastFileDeleted) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.deleteProjectDocumentFile({
                            projectId: this.props.projectId,
                            documentId: this.props.documentId,
                            fileInformationId: fileInformationId
                        })];
                    case 1:
                        _a.sent();
                        this.props.onFilesChanged(isLastFileDeleted);
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocumentFilesView.prototype._deleteFileConfirmClickHandler = function (fileInformationId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!fileInformationId)
                            return [2 /*return*/];
                        return [4 /*yield*/, this._deleteFile(fileInformationId, true)];
                    case 1:
                        _a.sent();
                        this._toggleConfirmationDialogOpen();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocumentFilesView.prototype._saveFileComment = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            var updateModel;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        updateModel = {
                            projectDocumentId: this.props.documentId || 0,
                            fileInformationId: rowData.fileInformationId,
                            comment: rowData.comment
                        };
                        return [4 /*yield*/, Api.secured.updateProjectDocumentComment({ body: updateModel })];
                    case 1:
                        _a.sent();
                        this.props.onFilesChanged();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocumentFilesView.prototype._updateFileName = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            var updateModel;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        updateModel = {
                            projectDocumentId: this.props.documentId || 0,
                            fileInformationId: rowData.fileInformationId,
                            fileName: rowData.name || ''
                        };
                        return [4 /*yield*/, Api.secured.updateProjectDocumentFileName({ body: updateModel })];
                    case 1:
                        _a.sent();
                        this.props.onFilesChanged();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocumentFilesView.prototype._toggleConfirmationDialogOpen = function () {
        this._isConfirmationDialogOpen = !this._isConfirmationDialogOpen;
        if (!this._isConfirmationDialogOpen) {
            this._currentFileInformationIdId = undefined;
        }
    };
    ProjectDocumentFilesView.prototype._fileRestoreClickHandler = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            var restoreModel;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        restoreModel = {
                            projectDocumentId: this.props.documentId || 0,
                            fileInformationId: rowData.fileInformationId
                        };
                        return [4 /*yield*/, Api.secured.restoreProjectDocumentFile({ body: restoreModel })];
                    case 1:
                        _a.sent();
                        this.props.onFilesChanged();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocumentFilesView.prototype.render = function () {
        var _this = this;
        var _a = this.props, filesLoading = _a.filesLoading, filesInfoModel = _a.filesInfoModel, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted;
        var rows = (filesInfoModel === null || filesInfoModel === void 0 ? void 0 : filesInfoModel.files) || [];
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid, { ref: this._gridRef, dataSource: __spreadArray([], rows, true), rowsCount: rows.length, loading: filesLoading },
                React.createElement(Column, { cssClass: columnSharedStyles.commentColumn, caption: "Name", dataField: "name", minWidth: 200, cellRender: function (_a) {
                        var _b;
                        var data = _a.data;
                        return (React.createElement(TextCell, { disabled: !data.canPrincipalManageFile || isProjectTerminatedOrCompleted || filesLoading, value: (_b = data.name) !== null && _b !== void 0 ? _b : '', onChange: function (value) {
                                data.name = value || '';
                            }, onSave: function () { return _this._updateFileName(data); }, objectUniqueId: createUniqueId(ObjectType.FileInformation, data.fileInformationId) }));
                    } }),
                React.createElement(Column, { caption: "Original File Name", minWidth: 200, dataField: "originalFileName" }),
                React.createElement(Column, { cssClass: columnSharedStyles.commentColumn, caption: "Comment", width: 300, dataField: "FileComment", cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(EditCell, { disabled: !data.canPrincipalManageFile || isProjectTerminatedOrCompleted || filesLoading, value: data.comment, onChange: function (value) { return (data.comment = value); }, onSave: function () { return _this._saveFileComment(data); }, objectUniqueId: createUniqueId(ObjectType.FileInformation, data.fileInformationId) }));
                    } }),
                React.createElement(Column, { caption: "Uploaded Date", dataField: "uploadedDate", dataType: "date", width: 150, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(DateTimeBox, { dateUTC: data.uploadedDate }));
                    } }),
                React.createElement(Column, { caption: "Uploaded By", dataField: "uploadedBy", width: 150 }),
                React.createElement(Column, { cssClass: columnSharedStyles.commentColumn, caption: "Status", dataField: "documentFileIsDeleted", width: 80, cellRender: function (_a) {
                        var data = _a.data;
                        return data.documentFileIsDeleted ? 'Deleted' : 'Active';
                    } }),
                React.createElement(Column, { caption: "Version", dataField: "version", width: 100 }),
                React.createElement(Column, { caption: "Action", width: 300, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("div", { className: styles.actions },
                            !data.documentFileIsDeleted && (React.createElement(React.Fragment, null,
                                React.createElement(DownloadButton, { fileId: data.fileInformationId, className: styles.action }),
                                data.canPrincipalDeleteFile &&
                                    !isProjectTerminatedOrCompleted &&
                                    data.fileInformationId && (React.createElement(Button, { className: styles.action, color: "danger", size: "xs", onClick: function () { return _this._fileDeleteClickHandler(data.fileInformationId); } },
                                    React.createElement(FaTrash, { title: "Delete file" }))),
                                React.createElement(CopyToClipBoardButton, { text: location.origin + ComponentUrls.DocumentUrl + data.fileInformationId, copyText: "Copy file link to clipboard" }))),
                            data.canPrincipalRestoreFile && (React.createElement(Button, { className: styles.action, size: "xs", onClick: function () { return _this._fileRestoreClickHandler(data); } },
                                React.createElement(FaUndo, { title: "Restore project document" })))));
                    } })),
            React.createElement(ConfirmationDialog, { maxWidth: "500px", isOpen: this._isConfirmationDialogOpen, onToggle: this._toggleConfirmationDialogOpen, headerTitle: "Delete Project Document", onSave: function () { return _this._deleteFileConfirmClickHandler(_this._currentFileInformationIdId); }, body: 'Warning: Deletion of the last active File will cause deletion of the whole Project Document. Are you sure you want to proceed?' })));
    };
    __decorate([
        observable.ref
    ], ProjectDocumentFilesView.prototype, "_gridRef", void 0);
    __decorate([
        observable
    ], ProjectDocumentFilesView.prototype, "_isConfirmationDialogOpen", void 0);
    __decorate([
        observable
    ], ProjectDocumentFilesView.prototype, "_currentFileInformationIdId", void 0);
    __decorate([
        action.bound
    ], ProjectDocumentFilesView.prototype, "_fileDeleteClickHandler", null);
    __decorate([
        action.bound
    ], ProjectDocumentFilesView.prototype, "_deleteFile", null);
    __decorate([
        action.bound
    ], ProjectDocumentFilesView.prototype, "_deleteFileConfirmClickHandler", null);
    __decorate([
        action.bound
    ], ProjectDocumentFilesView.prototype, "_saveFileComment", null);
    __decorate([
        action.bound
    ], ProjectDocumentFilesView.prototype, "_updateFileName", null);
    __decorate([
        action.bound
    ], ProjectDocumentFilesView.prototype, "_toggleConfirmationDialogOpen", null);
    __decorate([
        action.bound
    ], ProjectDocumentFilesView.prototype, "_fileRestoreClickHandler", null);
    ProjectDocumentFilesView = __decorate([
        observer
    ], ProjectDocumentFilesView);
    return ProjectDocumentFilesView;
}(React.PureComponent));
export default withRouter(ProjectDocumentFilesView);
