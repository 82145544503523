var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Api } from '@app/Api';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Grid } from '@app/Components/Grid/Grid';
import { Column, Sorting } from 'devextreme-react/data-grid';
import { DateBox } from '@app/Widgets/DateBox/DateBox';
import DateTimeService from '@app/Services/DateTimeService';
import styles from './ChecklistApproversModal.module.scss';
import { CommentModal } from '@app/Widgets/CommentModal/CommentModal';
import { Modal } from '@app/Components/Modal/Modal';
import { ApproveDenyChecklistButtons } from './ApproveDenyChecklistButtons';
import { ApprovalTypeEnum, SeparatedStrings, Status } from '@app/AppConstants';
export var ChecklistApproversModal = /** @class */ (function (_super) {
    __extends(ChecklistApproversModal, _super);
    function ChecklistApproversModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._approvers = [];
        _this._isApprovesLoading = false;
        _this._isCommentModalOpen = false;
        _this._denyItem = null;
        _this._denyApprovalId = null;
        return _this;
    }
    ChecklistApproversModal.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadApprovers()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ChecklistApproversModal.prototype.componentDidUpdate = function (prevProps) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(((_a = prevProps.checklist) === null || _a === void 0 ? void 0 : _a.id) !== ((_b = this.props.checklist) === null || _b === void 0 ? void 0 : _b.id) ||
                            (prevProps.isOpen !== this.props.isOpen && this.props.isOpen))) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._loadApprovers()];
                    case 1:
                        _c.sent();
                        _c.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ChecklistApproversModal.prototype._loadApprovers = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, , 2, 3]);
                        this._isApprovesLoading = true;
                        _b = this;
                        return [4 /*yield*/, Api.secured.getApprovalsByUniqueId({
                                objectUniqueId: (_a = this.props.checklist) === null || _a === void 0 ? void 0 : _a.uniqueId
                            })];
                    case 1:
                        _b._approvers = _c.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this._isApprovesLoading = false;
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ChecklistApproversModal.prototype._handleApprove = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.props.onApprovalChanged()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadApprovers()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ChecklistApproversModal.prototype._handleDeny = function (comment) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this._denyItem && this._denyApprovalId)) return [3 /*break*/, 2];
                        return [4 /*yield*/, Api.secured.denyFunctionChecklist(this._denyItem.id, {
                                approvalId: this._denyApprovalId,
                                comment: comment
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this._denyItem = null;
                        return [4 /*yield*/, this.props.onApprovalChanged()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this._loadApprovers()];
                    case 4: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ChecklistApproversModal.prototype._toggleCommentModalOpen = function () {
        this._isCommentModalOpen = !this._isCommentModalOpen;
        if (!this._isCommentModalOpen)
            this._denyItem = null;
    };
    ChecklistApproversModal.prototype.renderApprovalType = function (approvalType) {
        switch (approvalType) {
            case ApprovalTypeEnum.ProvideDeliverableTask:
                return SeparatedStrings.ApprovalTypeProvideDeliverableTask;
            case ApprovalTypeEnum.DraftDeliverableTask:
                return SeparatedStrings.ApprovalTypeDraftDeliverableTask;
            case ApprovalTypeEnum.ApprovalDeliverableTask:
                return SeparatedStrings.ApprovalTypeApprovalDeliverableTask;
            case ApprovalTypeEnum.MoveDeliverableToInApprovalTask:
                return SeparatedStrings.ApprovalTypeMoveDeliverableToInApprovalTask;
            case ApprovalTypeEnum.MoveFunctionChecklistToInApprovalTask:
                return SeparatedStrings.ApprovalTypeMoveFunctionChecklistToInApprovalTask;
            case ApprovalTypeEnum.ApprovalFunctionChecklistTask:
                return SeparatedStrings.ApprovalTypeApprovalFunctionChecklistTask;
        }
    };
    ChecklistApproversModal.prototype.render = function () {
        var _a;
        var _this = this;
        var isOpen = (_a = this.props, _a.isOpen), toggle = _a.toggle, checklist = _a.checklist, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted;
        return (React.createElement(React.Fragment, null,
            React.createElement(Modal, { isOpen: isOpen, toggle: toggle, headerTitle: "Approvers", minWidth: "1250px", footerbuttons: this._getFooterButtons() },
                React.createElement(Grid, { dataSource: this._approvers, loading: this._isApprovesLoading },
                    React.createElement(Sorting, { mode: "multiple" }),
                    React.createElement(Column, { caption: "Type", dataField: "approvalType", minWidth: 150, cellRender: function (_a) {
                            var data = _a.data;
                            return (React.createElement("p", null, _this.renderApprovalType(data.approvalType)));
                        } }),
                    React.createElement(Column, { caption: "Function", dataField: "relatedFunctionFunctionCode", width: 100 }),
                    React.createElement(Column, { caption: "Related Principal", dataField: "relatedPrincipalDisplayName", width: 175 }),
                    React.createElement(Column, { caption: "Date From", dataField: "dateFrom", width: 100, defaultSortOrder: "asc", cellRender: function (_a) {
                            var data = _a.data;
                            return React.createElement(DateBox, { date: data.dateFrom });
                        } }),
                    React.createElement(Column, { caption: "Date To", dataField: "dateTo", width: 100, cellRender: function (_a) {
                            var data = _a.data;
                            return React.createElement(DateBox, { date: data.dateTo });
                        } }),
                    React.createElement(Column, { caption: "Approval Result", dataField: "approvalResult", width: 100, cellRender: function (_a) {
                            var data = _a.data;
                            if (data.approvalType === ApprovalTypeEnum.ApprovalFunctionChecklistTask) {
                                return data.approvalResult;
                            }
                            else if ((checklist === null || checklist === void 0 ? void 0 : checklist.status) === Status.Draft) {
                                return 'NotYetSet';
                            }
                            else {
                                return 'Moved';
                            }
                        } }),
                    React.createElement(Column, { caption: "Date Modified", dataField: "modified", width: 100, cellRender: function (_a) {
                            var data = _a.data;
                            return DateTimeService.toUiDateTime(data.modified);
                        } }),
                    React.createElement(Column, { caption: "Modified By", dataField: "modifiedBy", width: 175 }),
                    React.createElement(Column, { width: 200, cellRender: function (_a) {
                            var data = _a.data;
                            return (React.createElement("div", { className: styles.actions }, _this.props.checklist && (checklist === null || checklist === void 0 ? void 0 : checklist.canCheckListBeApproved) && (data.canBeApproved || data.canBeDeny) && (React.createElement(ApproveDenyChecklistButtons, { checklistId: _this.props.checklist.id, checklistApproverId: data.id, onDenyHandler: function () {
                                    _this._toggleCommentModalOpen();
                                    _this._denyItem = _this.props.checklist;
                                    _this._denyApprovalId = data.id;
                                }, onApproveHandler: _this._handleApprove, disabled: isProjectTerminatedOrCompleted, hideApproveButton: !data.canBeApproved, hideDenyButton: !data.canBeDeny }))));
                        } }))),
            React.createElement(CommentModal, { isOpen: this._isCommentModalOpen, onToggle: this._toggleCommentModalOpen, onSave: this._handleDeny })));
    };
    ChecklistApproversModal.prototype._getFooterButtons = function () {
        return [
            {
                onClick: this.props.toggle,
                title: 'Close'
            }
        ];
    };
    __decorate([
        observable
    ], ChecklistApproversModal.prototype, "_approvers", void 0);
    __decorate([
        observable
    ], ChecklistApproversModal.prototype, "_isApprovesLoading", void 0);
    __decorate([
        observable
    ], ChecklistApproversModal.prototype, "_isCommentModalOpen", void 0);
    __decorate([
        action
    ], ChecklistApproversModal.prototype, "_loadApprovers", null);
    __decorate([
        action.bound
    ], ChecklistApproversModal.prototype, "_handleApprove", null);
    __decorate([
        action.bound
    ], ChecklistApproversModal.prototype, "_handleDeny", null);
    __decorate([
        action.bound
    ], ChecklistApproversModal.prototype, "_toggleCommentModalOpen", null);
    ChecklistApproversModal = __decorate([
        observer
    ], ChecklistApproversModal);
    return ChecklistApproversModal;
}(React.PureComponent));
