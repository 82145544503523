var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable @typescript-eslint/no-explicit-any */
/* tslint:disable:no-any */
import React from 'react';
import DateTimeService from '@app/Services/DateTimeService';
import axios from 'axios';
import { NotificationHandler } from '../Components/ToastNotification/ToastNotification';
import { globalAjaxLoaderStore } from '@app/Stores/GlobalAjaxLoaderStore';
import { appStore } from '@app/AppStore';
import { emitter } from './EventEmitter';
import { AzureAuth } from '@app/Components/AzureAuth';
import { modalStore } from '@app/Stores/ModalStore';
import { ErrorDialog } from '@app/Components/Dialogs/ErrorDialog';
var ApiHeaders = /** @class */ (function () {
    function ApiHeaders() {
    }
    return ApiHeaders;
}());
var ApiService = /** @class */ (function () {
    function ApiService() {
    }
    ApiService.toQueryString = function (params) {
        if (typeof params !== 'object')
            return '';
        return "?".concat(Object.keys(params)
            .map(function (k) { return "".concat(k, "=").concat(params[k]); })
            .join('&'));
    };
    ApiService.getTypedData = function (url, getData, options) {
        return ApiService._callMethod(function (instance) { return instance.get; }, url, { params: getData }, options);
    };
    ApiService.postData = function (url, postData, options) {
        return ApiService._callMethod(function (instance) { return instance.post; }, url, postData, options);
    };
    ApiService.putData = function (url, putData, options) {
        return ApiService._callMethod(function (instance) { return instance.put; }, url, putData, options);
    };
    ApiService.putTypedData = function (url, putData, options) {
        return ApiService._callMethod(function (instance) { return instance.put; }, url, putData, options);
    };
    ApiService._callMethod = function (methodChooser, url, data, options) {
        return __awaiter(this, void 0, void 0, function () {
            var responseType, instance, result, requestTimestamp, isHandleError;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        responseType = (options && options.responseType) || 'json';
                        instance = ApiService._getInstance(responseType, options);
                        result = methodChooser(instance)(this._getAbsoluteUrl(url), data);
                        requestTimestamp = DateTimeService.today().getTime();
                        isHandleError = !(options && options.suppressErrorHandling);
                        globalAjaxLoaderStore.showAjaxSpinner(requestTimestamp);
                        options && options.completion && options.completion.subscribe(result);
                        result.catch(function (error) {
                            if (isHandleError)
                                ApiService.handleError(url, error);
                            globalAjaxLoaderStore.hideAjaxSpinner(requestTimestamp);
                        });
                        return [4 /*yield*/, result.then(function () {
                                globalAjaxLoaderStore.hideAjaxSpinner(requestTimestamp);
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, result];
                }
            });
        });
    };
    ApiService.handleError = function (url, error) {
        if (error && error.response && error.response.status === 409) {
            return;
        }
        errorHandleService.showError(url, error);
    };
    ApiService._getAbsoluteUrl = function (url) {
        var relariveUrl = url;
        if (relariveUrl && relariveUrl[0] !== '/')
            relariveUrl = '/' + url;
        if (!relariveUrl.startsWith('/api'))
            relariveUrl = '/api' + url;
        return location.origin + relariveUrl;
    };
    ApiService._getInstance = function (responseType, options) {
        if (responseType === void 0) { responseType = 'json'; }
        var headers = new ApiHeaders();
        headers['Cache-Control'] = 'no-cache';
        if (appStore.currentToken) {
            headers['Authorization'] = 'Bearer ' + appStore.currentToken;
        }
        if (options && options.operationName) {
            headers['App-Operation-Name'] = encodeURIComponent(options.operationName);
        }
        if (appStore.signalRConnectionId) {
            headers['App-SignalR-ConnectionId'] = appStore.signalRConnectionId;
        }
        if (appStore.impersonatedPrincipal) {
            headers['App-Impersonation-PrincipalId'] = appStore.impersonatedPrincipal.principalId || '';
        }
        var transformResponse = function (data) {
            if (options && options.noDateTransform)
                return data;
            return DateTimeService.typeCheck(data);
        };
        var axiosOptions = {
            responseType: responseType,
            headers: headers,
            transformResponse: transformResponse
        };
        // if (options && options.supressErrorHandling){
        //     axiosOptions.validateStatus = () => true;
        // }
        var axiosInstance = axios.create(axiosOptions);
        axiosInstance.interceptors.response.use(function (res) {
            var contentType = res.headers['content-type'];
            if (contentType && contentType.indexOf('text/html') > -1 && responseType === 'json') {
                throw new Error('API call to ' + res.config.url + ' returned not expected content type: ' + contentType);
            }
            return res;
        }, function (err) {
            if (err.response && err.response.status === 401) {
                return new Promise(function () {
                    AzureAuth.login();
                });
            }
            if (err.response && err.response.status === 403) {
                emitter.emit('notify-error-403', err);
                return Promise.reject(err);
            }
            throw err;
        });
        ApiService._responseInterceptors.forEach(function (x) { return axiosInstance.interceptors.response.use(x.response, x.error); });
        return axiosInstance;
    };
    ApiService._responseInterceptors = [];
    return ApiService;
}());
export default ApiService;
var ErrorHandleService = /** @class */ (function () {
    function ErrorHandleService() {
        this._lastError = { message: '', time: 0 };
    }
    ErrorHandleService.prototype.showError = function (url, error) {
        var currentTime = new Date().getTime();
        console.log('--error AJAX: ', error);
        if (!this._lastError ||
            this._lastError.message !== error.message ||
            currentTime - this._lastError.time > 10000) {
            if (error.response && error.response.status !== 403) {
                NotificationHandler.showError(React.createElement(React.Fragment, null,
                    React.createElement("h4", { style: { color: '#e74c3c' } }, '--error AJAX'),
                    React.createElement("p", null, error.message)));
                if (error && error.response && (error.response.status === 400 || error.response.status === 500)) {
                    var instance_1 = modalStore.show({
                        body: React.createElement(ErrorDialog, { error: error, getModalInstance: function () { return instance_1; } }),
                        title: "Request can't be completed"
                    });
                }
                this._lastError = { message: error.message, time: currentTime };
            }
        }
    };
    return ErrorHandleService;
}());
var errorHandleService = new ErrorHandleService();
