import React, { useState, useEffect } from 'react';
import styles from './confirmation-input.module.scss';
import { ClickDetector } from '@app/Components/ClickDetector/ClickDetector';
import { SaveCancelContainer } from '@app/Components/SaveCancelContainer/SaveCancelContainer';
export var ConfirmationInput = function (_a) {
    var value = _a.value, placeholder = _a.placeholder, invalid = _a.invalid, onChange = _a.onChange, disabled = _a.disabled, className = _a.className;
    var _b = useState(false), isFocused = _b[0], setIsFocused = _b[1];
    var _c = useState(''), inputValue = _c[0], setInputValue = _c[1];
    useEffect(function () {
        setInputValue(value);
    }, [value]);
    var cssClass = styles.confirmationInput;
    if (isFocused)
        cssClass += " ".concat(styles.focusedInput);
    if (invalid)
        cssClass += " ".concat(styles.invalidInput);
    if (className)
        cssClass += " ".concat(className);
    var wrapperStyles = styles.confirmationInputWrapper;
    if (invalid)
        wrapperStyles += " is-invalid";
    function handleOutsideClick() {
        setIsFocused(false);
        onChange(inputValue);
    }
    function handleSave() {
        setIsFocused(false);
        onChange(inputValue);
    }
    function handleCancel() {
        setIsFocused(false);
        setInputValue(value);
    }
    return (React.createElement("div", { className: wrapperStyles },
        React.createElement(SaveCancelContainer, { isActive: isFocused, onCancel: handleCancel, onSave: handleSave },
            React.createElement(ClickDetector, { disabled: !isFocused, onOutsideClick: handleOutsideClick },
                React.createElement("input", { disabled: disabled, placeholder: placeholder, value: inputValue, className: cssClass, onClick: function () { return setIsFocused(true); }, onChange: function (e) { return setInputValue(e.target.value); } })))));
};
