var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { MdCloudUpload } from 'react-icons/md';
import notify from 'devextreme/ui/notify';
import { devInfoStore } from '@app/Stores/DevInfoStore';
import styles from './FileUploadArea.module.scss';
var FileUploadArea = /** @class */ (function (_super) {
    __extends(FileUploadArea, _super);
    function FileUploadArea() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._fileInputRef = React.createRef();
        _this._dragging = false;
        _this._dragCounter = 0;
        return _this;
    }
    FileUploadArea.prototype._handleFileChange = function (e) {
        return __awaiter(this, void 0, void 0, function () {
            var files;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.persist();
                        files = e.target.files;
                        return [4 /*yield*/, this._handleFiles(files)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    FileUploadArea.prototype._handleFiles = function (files) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var i, file, sizeInMb;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(files && files.length)) return [3 /*break*/, 6];
                        i = 0;
                        _c.label = 1;
                    case 1:
                        if (!(i < files.length)) return [3 /*break*/, 4];
                        file = files[i];
                        if (file.size > devInfoStore.maxDocumentSize) {
                            sizeInMb = devInfoStore.maxDocumentSize / 1048576;
                            notify("File size must be less than ".concat(sizeInMb, "MB"), 'error', 1000);
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.props.upload(file)];
                    case 2:
                        _c.sent();
                        _c.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4:
                        this._fileInputRef.current && (this._fileInputRef.current.value = '');
                        return [4 /*yield*/, ((_b = (_a = this.props).onUpload) === null || _b === void 0 ? void 0 : _b.call(_a))];
                    case 5:
                        _c.sent();
                        _c.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    FileUploadArea.prototype._handleLoadClick = function () {
        var _a;
        (_a = this._fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    FileUploadArea.prototype._setDragging = function (dragging) {
        this._dragging = dragging;
    };
    FileUploadArea.prototype._dragEnterHandler = function (e) {
        e.preventDefault();
        e.stopPropagation();
        this._dragCounter++;
        this._setDragging(true);
    };
    FileUploadArea.prototype._dragLeaveHandler = function (e) {
        e.preventDefault();
        e.stopPropagation();
        this._dragCounter--;
        if (this._dragCounter > 0)
            return;
        this._setDragging(false);
    };
    FileUploadArea.prototype._dragOverHandler = function (e) {
        e.preventDefault();
        e.stopPropagation();
    };
    FileUploadArea.prototype._dropHandler = function (e) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.persist();
                        e.preventDefault();
                        e.stopPropagation();
                        this._setDragging(false);
                        if (!(e.dataTransfer.files && e.dataTransfer.files.length > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._handleFiles(e.dataTransfer.files)];
                    case 1:
                        _a.sent();
                        e.dataTransfer.clearData();
                        this._dragCounter = 0;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    FileUploadArea.prototype._renderIcon = function () {
        return React.createElement(MdCloudUpload, { className: styles.icon, size: "25px" });
    };
    FileUploadArea.prototype._renderText = function () {
        if (this._dragging)
            return React.createElement("div", { className: styles.text }, "Upload Files");
        return React.createElement("div", { className: styles.text }, "Drag files here or browse");
    };
    FileUploadArea.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: styles.area + (this._dragging ? " ".concat(styles.dragging) : ''), onClick: this._handleLoadClick, onDragEnter: function (e) { return _this._dragEnterHandler(e); }, onDragLeave: function (e) { return _this._dragLeaveHandler(e); }, onDragOver: function (e) { return _this._dragOverHandler(e); }, onDrop: function (e) { return _this._dropHandler(e); }, style: { maxWidth: this.props.maxWidth } },
            this._renderIcon(),
            this._renderText(),
            React.createElement("input", { style: { display: 'none' }, type: "file", ref: this._fileInputRef, onChange: this._handleFileChange })));
    };
    __decorate([
        observable
    ], FileUploadArea.prototype, "_fileInputRef", void 0);
    __decorate([
        observable
    ], FileUploadArea.prototype, "_dragging", void 0);
    __decorate([
        action.bound
    ], FileUploadArea.prototype, "_handleFileChange", null);
    __decorate([
        action.bound
    ], FileUploadArea.prototype, "_handleFiles", null);
    __decorate([
        action.bound
    ], FileUploadArea.prototype, "_handleLoadClick", null);
    __decorate([
        action.bound
    ], FileUploadArea.prototype, "_setDragging", null);
    FileUploadArea = __decorate([
        observer
    ], FileUploadArea);
    return FileUploadArea;
}(React.PureComponent));
export default FileUploadArea;
