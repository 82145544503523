var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { Button } from '@app/Components/Button/Button';
import { observer } from 'mobx-react';
import styles from './Deliverable.module.scss';
import { TemplateConfigurarion } from './TemplateConfiguration';
import { Link } from '@app/Components/Link/Link';
import { withRouter, Switch, Route } from 'react-router-dom';
import { Modal } from '@app/Components/Modal/Modal';
import { action, observable } from 'mobx';
var DeliverableC = /** @class */ (function (_super) {
    __extends(DeliverableC, _super);
    function DeliverableC() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isModalOpen = false;
        return _this;
    }
    DeliverableC.prototype._toggleModal = function () {
        var _a = this.props, history = _a.history, match = _a.match;
        this.isModalOpen = !this.isModalOpen;
        if (!this.isModalOpen) {
            this.props.onModalClose();
            history.push(match.url);
        }
    };
    DeliverableC.prototype.render = function () {
        var _a = this.props, match = _a.match, templateName = _a.templateName;
        return (React.createElement("div", { className: styles.container },
            React.createElement(Link, { to: "".concat(match.url, "/config/").concat(this.props.projectTemplateId), className: styles.button, onClick: this._toggleModal },
                React.createElement(Button, { size: "sm" }, "Configuration")),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "".concat(match.url, "/config/").concat(this.props.projectTemplateId) },
                    React.createElement(Modal, { isOpen: this.isModalOpen, toggle: this._toggleModal, headerTitle: 'Configuration ' + templateName, maxWidth: "95%", footerbuttons: this._getFooterButtons() },
                        React.createElement(TemplateConfigurarion, { projectTemplateId: this.props.projectTemplateId, projectTemplateType: this.props.projectTemplateType }))))));
    };
    DeliverableC.prototype._getFooterButtons = function () {
        return [
            {
                onClick: this._toggleModal,
                title: 'Close'
            }
        ];
    };
    __decorate([
        observable
    ], DeliverableC.prototype, "isModalOpen", void 0);
    __decorate([
        action.bound
    ], DeliverableC.prototype, "_toggleModal", null);
    DeliverableC = __decorate([
        observer
    ], DeliverableC);
    return DeliverableC;
}(React.PureComponent));
// TODO: rename
export var Deliverable = withRouter(DeliverableC);
