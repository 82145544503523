var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import styles from './DocumentButton.module.scss';
import globalStyles from '@app/Styles/GlobalStyles.module.scss';
import { IoMdDocument } from 'react-icons/io';
import { Button } from '@app/Components/Button/Button';
var DocumentButton = /** @class */ (function (_super) {
    __extends(DocumentButton, _super);
    function DocumentButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DocumentButton.prototype.render = function () {
        var _a = this.props, hasDocument = _a.hasDocument, onDocumentIconClick = _a.onDocumentIconClick, className = _a.className, isTiny = _a.isTiny, hasRedDot = _a.hasRedDot;
        var iconStyles = "".concat(hasDocument ? styles.documentIconUploaded : styles.documentIconNotUploaded);
        var tinyButtonStyles = isTiny ? styles.tinyButton : '';
        var cls = "".concat(styles.documentIcon, " ").concat(iconStyles, " ").concat(className, " ").concat(tinyButtonStyles);
        return (React.createElement(Button, { size: "xs", color: "primary", className: cls, onClick: onDocumentIconClick },
            React.createElement("div", { className: styles.block },
                React.createElement(IoMdDocument, null),
                hasRedDot && React.createElement("p", { className: globalStyles.redDot }, "."))));
    };
    return DocumentButton;
}(React.PureComponent));
export { DocumentButton };
