/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed } from 'mobx';
import DateTimeService from '@app/Services/DateTimeService';
import { DateTime } from '@app/AppConstants';
/*Validation Rules*/
export function isRequired(msg) {
    return function (target, name) {
        var validation = {
            fieldName: name,
            validateFunction: function (obj) {
                return obj[name] ? null : msg || 'Field ' + getDisplayName(obj, name) + ' cannot be empty';
            }
        };
        addValidation(target, name, validation);
    };
}
export function isDate(msg) {
    var regEx = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.(\d{4})\s*$/;
    return function (target, name) {
        var validation = {
            fieldName: name,
            validateFunction: function (obj) {
                var isValid = !obj[name] ||
                    (regEx.test(obj[name]) && DateTimeService.isValidDate(DateTimeService.parseUiDate(obj[name])));
                return isValid
                    ? undefined
                    : msg || 'Field ' + getDisplayName(obj, name) + ' should have format ' + DateTime.viewDateFormat;
            }
        };
        addValidation(target, name, validation);
    };
}
export function isDateAfter(compareName, format, msg) {
    return function (target, name) {
        var validation = {
            fieldName: name,
            validateFunction: function (obj) {
                var originDate = DateTimeService.parse(obj[name], format ? format : DateTime.viewDateFormat);
                var compareDate = DateTimeService.parse(obj[compareName], format ? format : DateTime.viewDateFormat);
                var isValid = !DateTimeService.isValidDate(originDate) ||
                    !DateTimeService.isValidDate(compareDate) ||
                    originDate >= compareDate;
                return isValid
                    ? undefined
                    : msg || getDisplayName(obj, name) + ' should be later than ' + getDisplayName(obj, compareName);
            }
        };
        addValidation(target, name, validation);
    };
}
export function isDateBefore(compareName, format, msg) {
    return function (target, name) {
        var validation = {
            fieldName: name,
            validateFunction: function (obj) {
                var originDate = DateTimeService.parse(obj[name], format ? format : DateTime.viewDateFormat);
                var compareDate = DateTimeService.parse(obj[compareName], format ? format : DateTime.viewDateFormat);
                var isValid = !DateTimeService.isValidDate(originDate) ||
                    !DateTimeService.isValidDate(compareDate) ||
                    originDate <= compareDate;
                return isValid
                    ? undefined
                    : msg || getDisplayName(obj, name) + ' should be earlier than ' + getDisplayName(obj, compareName);
            }
        };
        addValidation(target, name, validation);
    };
}
export function isSameAs(compareName, format, msg) {
    return function (target, name) {
        var validation = {
            fieldName: name,
            validateFunction: function (obj) {
                var isValid = obj[name] === obj[compareName];
                return isValid
                    ? undefined
                    : msg || getDisplayName(obj, name) + ' should be the same as ' + getDisplayName(obj, compareName);
            }
        };
        addValidation(target, name, validation);
    };
}
export function isNotSameAs(compareName, format, msg) {
    return function (target, name) {
        var validation = {
            fieldName: name,
            validateFunction: function (obj) {
                var isValid = obj[name] !== obj[compareName];
                return isValid
                    ? undefined
                    : msg ||
                        getDisplayName(obj, name) + ' should not be the same as ' + getDisplayName(obj, compareName);
            }
        };
        addValidation(target, name, validation);
    };
}
export function isTime(msg) {
    var regEx = /^(([0-1][0-9])|(2[0-3])):[0-5][0-9]$/;
    return function (target, name) {
        var validation = {
            fieldName: name,
            validateFunction: function (obj) {
                var isValid = !obj[name] || regEx.test(obj[name]);
                return isValid
                    ? undefined
                    : msg ||
                        'Field ' +
                            getDisplayName(obj, name) +
                            ' should have format ' +
                            DateTime.timeFormat.toUpperCase();
            }
        };
        addValidation(target, name, validation);
    };
}
export function isDateTime(msg) {
    var regEx = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})[ ](([0-1][0-9])|(2[0-3])):[0-5][0-9]$/;
    return function (target, name) {
        var validation = {
            fieldName: name,
            validateFunction: function (obj) {
                var isValid = !obj[name] ||
                    (regEx.test(obj[name]) && DateTimeService.isValidDate(DateTimeService.parseUiDateTime(obj[name])));
                return isValid
                    ? undefined
                    : msg ||
                        'Field ' +
                            getDisplayName(obj, name) +
                            ' should have format ' +
                            DateTime.viewFullFormat.toUpperCase();
            }
        };
        addValidation(target, name, validation);
    };
}
export function hasMaxLength(length, msg) {
    return function (target, name) {
        var validation = {
            fieldName: name,
            validateFunction: function (obj) {
                var isValid = !obj[name] || obj[name].toString().length <= length;
                return isValid
                    ? undefined
                    : msg ||
                        'Field ' +
                            getDisplayName(obj, name) +
                            ' should be less than or equal to ' +
                            length +
                            ' characters';
            }
        };
        addValidation(target, name, validation);
    };
}
export function notWhitespace(msg) {
    return function (target, name) {
        var validation = {
            fieldName: name,
            validateFunction: function (obj) {
                var isValid = obj[name].toString().length > 0 ? obj[name].toString().trim().length > 0 : true;
                return isValid
                    ? undefined
                    : msg || 'Field ' + getDisplayName(obj, name) + ' cannot consist only of white-space characters.';
            }
        };
        addValidation(target, name, validation);
    };
}
export function isRange(min, max, msg) {
    return function (target, name) {
        var validation = {
            fieldName: name,
            validateFunction: function (obj) {
                var number = Number(obj[name]);
                var isValid = !obj[name] || (!isNaN(number) && number >= min && number <= max);
                return isValid
                    ? undefined
                    : msg || 'Field ' + getDisplayName(obj, name) + ' is out of range from ' + min + ' to ' + max;
            }
        };
        addValidation(target, name, validation);
    };
}
export function isDateInRange(start, end, format, msg) {
    return function (target, name) {
        var validation = {
            fieldName: name,
            validateFunction: function (obj) {
                var originDate = DateTimeService.parse(obj[name], format ? format : DateTime.viewDateFormat);
                var startDate = DateTimeService.parse(obj[start], format ? format : DateTime.viewDateFormat);
                var endDate = DateTimeService.parse(obj[end], format ? format : DateTime.viewDateFormat);
                if (!DateTimeService.isValidDate(originDate))
                    return undefined;
                var isStartValid = start && DateTimeService.isValidDate(startDate)
                    ? DateTimeService.isSameDate(originDate, startDate) ||
                        DateTimeService.isAfterDate(originDate, startDate)
                    : true;
                var isEndValid = end && DateTimeService.isValidDate(endDate)
                    ? DateTimeService.isSameDate(originDate, endDate) ||
                        DateTimeService.isBeforeDate(originDate, endDate)
                    : true;
                if (!isStartValid)
                    return msg || getDisplayName(obj, name) + ' should be later or equal to ' + obj[start];
                if (!isEndValid)
                    return msg || getDisplayName(obj, name) + ' should be earlier or equal to ' + obj[end];
                return undefined;
            }
        };
        addValidation(target, name, validation);
    };
}
export function isRegEx(regEx, msg) {
    return function (target, name) {
        var validation = {
            fieldName: name,
            validateFunction: function (obj) {
                var isValid = !obj[name] || regEx.test(obj[name]);
                return isValid ? undefined : msg || 'Field ' + getDisplayName(obj, name) + ' is not valid.';
            }
        };
        addValidation(target, name, validation);
    };
}
export function isNumber(msg) {
    return function (target, name) {
        var validation = {
            fieldName: name,
            validateFunction: function (obj) {
                var isValid = !obj[name] || !isNaN(Number(obj[name]));
                var errorMEssage = 'Field ' + getDisplayName(obj, name) + ' is not a number';
                if (isValid && !Number.isInteger(Number(obj[name]))) {
                    isValid = false;
                    errorMEssage = 'Field ' + getDisplayName(obj, name) + ' should be integer value.';
                }
                return isValid ? undefined : msg || errorMEssage;
            }
        };
        addValidation(target, name, validation);
    };
}
export function isBiggerThanNull(msg) {
    return function (target, name) {
        var validation = {
            fieldName: name,
            validateFunction: function (obj) {
                var isValid = !obj[name] || Number(obj[name]) <= 0;
                var errorMEssage;
                if (isValid && obj[name].length && Number(obj[name]) <= 0) {
                    isValid = false;
                    errorMEssage = 'Field ' + getDisplayName(obj, name) + ' is less or equal than 0';
                }
                return isValid ? undefined : msg || errorMEssage;
            }
        };
        addValidation(target, name, validation);
    };
}
export function isDuration(msg) {
    var regEx = /\d*:[0-5][0-9]$/;
    return function (target, name) {
        var validation = {
            fieldName: name,
            validateFunction: function (obj) {
                var isValid = !obj[name] || regEx.test(obj[name]);
                return isValid ? undefined : msg || 'Field ' + getDisplayName(obj, name) + ' should have format HH:MM';
            }
        };
        addValidation(target, name, validation);
    };
}
export function isEmail(msg) {
    var regEx = /([\w.-]+@([\w-]+)\.+\w{2,})/;
    return function (target, name) {
        var validation = {
            fieldName: name,
            validateFunction: function (obj) {
                var isValid = !obj[name] || regEx.test(obj[name]);
                return isValid ? undefined : msg || 'Field ' + getDisplayName(obj, name) + ' is not a valid email';
            }
        };
        addValidation(target, name, validation);
    };
}
export function displayName(displayName) {
    return function (target, name) {
        var __displayName = camelCase('____displayName_', name);
        target[__displayName] = displayName;
    };
}
export function addDateTimeValidator(target, name, msg, title) {
    var regEx = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})[ ](([0-1][0-9])|(2[0-3])):[0-5][0-9]$/;
    var validation = {
        fieldName: name,
        validateFunction: function (obj) {
            var isValid = !obj[name] ||
                (regEx.test(obj[name]) && DateTimeService.isValidDate(DateTimeService.parseUiDateTime(obj[name])));
            return isValid
                ? undefined
                : msg || 'Field ' + (title ? title : camelCase('', name)) + ' should have format DD.MM.YYYY HH:MM';
        }
    };
    addValidation(target, name, validation);
}
export function addDateValidator(target, name, msg, title) {
    var regEx = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/;
    var validation = {
        fieldName: name,
        validateFunction: function (obj) {
            var isValid = !obj[name] ||
                (regEx.test(obj[name]) && DateTimeService.isValidDate(DateTimeService.parseUiDate(obj[name])));
            return isValid
                ? undefined
                : msg || 'Field ' + (title ? title : camelCase('', name)) + ' should have format DD.MM.YYYY';
        }
    };
    addValidation(target, name, validation);
}
export function addNumberValidator(target, name, msg, title) {
    var validation = {
        fieldName: name,
        validateFunction: function (obj) {
            var isValid = !obj[name] || !isNaN(Number(obj[name]));
            return isValid ? undefined : msg || 'Field ' + (title ? title : camelCase('', name)) + ' is not a number';
        }
    };
    addValidation(target, name, validation);
}
export function addRequiredValidator(target, name, msg, title) {
    var validation = {
        fieldName: name,
        validateFunction: function (obj) {
            return obj[name] ? null : msg || 'Field ' + (title ? title : camelCase('', name)) + ' cannot be empty';
        }
    };
    addValidation(target, name, validation);
}
export function addIsRangeValidator(target, name, min, max, msg) {
    var validation = {
        fieldName: name,
        validateFunction: function (obj) {
            var number = Number(obj[name]);
            var isValid = !obj[name] || (!isNaN(number) && number >= min && number <= max);
            return isValid
                ? undefined
                : msg || 'Field ' + getDisplayName(obj, name) + ' is out of range from ' + min + ' to ' + max;
        }
    };
    addValidation(target, name, validation);
}
/*Logic*/
function addValidation(target, name, validationRule) {
    var __validators = '__validators';
    var __validationErrors = '__validationErrors';
    var __isValidForm = '__isValidForm';
    var __validateError = camelCase('__validateError_', name);
    var __errorFields = '__errorFields';
    if (!target.hasOwnProperty(__validators)) {
        Object.defineProperty(target, __validators, {
            configurable: true,
            enumerable: false,
            value: []
        });
    }
    if (!target.hasOwnProperty(__validationErrors)) {
        var descriptor = {
            configurable: true,
            enumerable: false,
            get: function getter() {
                var _this = this;
                var errorList = [];
                var validators = this[__validators];
                validators.forEach(function (validator) {
                    var error = validator.validateFunction(_this);
                    if (error) {
                        errorList.push(error);
                    }
                });
                return errorList;
            }
        };
        defineComputedProperty(target, __validationErrors, descriptor);
    }
    if (!target.hasOwnProperty(__isValidForm)) {
        var descriptor = {
            configurable: true,
            enumerable: false,
            get: function getter() {
                var _this = this;
                var isValid = true;
                var validators = this[__validators];
                if (!validators.length)
                    return isValid;
                validators.forEach(function (validator) {
                    var error = validator.validateFunction(_this);
                    if (error) {
                        isValid = false;
                    }
                });
                return isValid;
            }
        };
        defineComputedProperty(target, __isValidForm, descriptor);
    }
    if (!target.hasOwnProperty(__validateError)) {
        var descriptor = {
            configurable: true,
            enumerable: false,
            get: function getter() {
                var _this = this;
                var validators = this[__validators];
                var errorList = [];
                validators.forEach(function (validator) {
                    if (validator.fieldName === name) {
                        var error = validator.validateFunction(_this);
                        if (error) {
                            errorList.push(error);
                        }
                    }
                });
                return errorList;
            }
        };
        defineComputedProperty(target, __validateError, descriptor);
    }
    if (!target.hasOwnProperty(__errorFields)) {
        var descriptor = {
            configurable: true,
            enumerable: false,
            get: function getter() {
                var _this = this;
                var validators = this[__validators];
                var errorNames = [];
                validators.forEach(function (validator) {
                    var error = validator.validateFunction(_this);
                    if (error) {
                        errorNames.push(validator.fieldName);
                    }
                });
                return errorNames;
            }
        };
        defineComputedProperty(target, __errorFields, descriptor);
    }
    target[__validators].push(validationRule);
}
function defineComputedProperty(target, name, descriptor) {
    Object.defineProperty(target, name, descriptor);
    computed(target, name, descriptor);
}
function camelCase(prefix, others) {
    return prefix + others[0].toUpperCase() + others.substr(1);
}
function getDisplayName(target, name) {
    var __displayName = camelCase('____displayName_', name);
    return target[__displayName] ? target[__displayName] : camelCase('', name);
}
export function setDisplayName(target, name, value) {
    var __displayName = camelCase('____displayName_', name);
    target[__displayName] = value;
}
