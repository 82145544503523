var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Api } from '@app/Api';
import { Column, FilterRow, Summary, TotalItem } from 'devextreme-react/data-grid';
import { FaTrash } from 'react-icons/fa';
import { Grid } from '@app/Components/Grid/Grid';
import { Modal } from '@app/Components/Modal/Modal';
import { Button } from '@app/Components/Button/Button';
import { PersonPicker } from '@app/Components/Pickers/PersonPicker/PersonPicker';
import { RowLabel } from '@app/Components/FormControls';
import styles from './RoleMembers.module.scss';
export var RoleMembers = /** @class */ (function (_super) {
    __extends(RoleMembers, _super);
    function RoleMembers() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RoleMembers.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadRoleMembers()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    RoleMembers.prototype._loadRoleMembers = function () {
        return __awaiter(this, void 0, void 0, function () {
            var objectUniqueId, objectRoleInfo;
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        objectUniqueId = (_a = this.props, _a.objectUniqueId), objectRoleInfo = _a.objectRoleInfo;
                        if (!(objectUniqueId && (objectRoleInfo === null || objectRoleInfo === void 0 ? void 0 : objectRoleInfo.roleId) && (objectRoleInfo === null || objectRoleInfo === void 0 ? void 0 : objectRoleInfo.originObjectUniqueId))) return [3 /*break*/, 2];
                        return [4 /*yield*/, Api.secured
                                .getRoleMembers({
                                objectUniqueId: objectUniqueId,
                                roleId: objectRoleInfo === null || objectRoleInfo === void 0 ? void 0 : objectRoleInfo.roleId,
                                inheritedFrom: objectRoleInfo === null || objectRoleInfo === void 0 ? void 0 : objectRoleInfo.originObjectUniqueId
                            })
                                .then(function (res) {
                                _this._roleMembers = res;
                            })];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    RoleMembers.prototype._handleUserSelect = function (users) {
        if (users === null || users === void 0 ? void 0 : users.length) {
            this._selectedPrincipal = users[0];
        }
    };
    RoleMembers.prototype._handleRoleMemberAdd = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var roleMember;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!this._selectedPrincipal) return [3 /*break*/, 3];
                        roleMember = {
                            objectUniqueId: this.props.objectUniqueId,
                            roleId: (_a = this.props.objectRoleInfo) === null || _a === void 0 ? void 0 : _a.roleId,
                            principalId: this._selectedPrincipal.id
                        };
                        return [4 /*yield*/, Api.secured.createRoleMember(roleMember)];
                    case 1:
                        _d.sent();
                        return [4 /*yield*/, this._loadRoleMembers()];
                    case 2:
                        _d.sent();
                        (_c = (_b = this.props).onRefresh) === null || _c === void 0 ? void 0 : _c.call(_b);
                        this.forceUpdate();
                        _d.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    RoleMembers.prototype._handleRoleMemberDelete = function (roleMember) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, Api.secured.deleteRoleMember(roleMember)];
                    case 1:
                        _c.sent();
                        return [4 /*yield*/, this._loadRoleMembers()];
                    case 2:
                        _c.sent();
                        (_b = (_a = this.props).onRefresh) === null || _b === void 0 ? void 0 : _b.call(_a);
                        return [2 /*return*/];
                }
            });
        });
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    RoleMembers.prototype._customizeTotalCount = function (data) {
        return "Total Members: ".concat(data.value);
    };
    RoleMembers.prototype.render = function () {
        var _a;
        var _this = this;
        var isOpen = (_a = this.props, _a.isOpen), onClose = _a.onClose, objectUniqueId = _a.objectUniqueId, objectRoleInfo = _a.objectRoleInfo;
        return (React.createElement(Modal, { isOpen: isOpen, minWidth: "75%", toggle: onClose, headerTitle: "".concat(objectRoleInfo === null || objectRoleInfo === void 0 ? void 0 : objectRoleInfo.roleDisplayName, " on ").concat(objectUniqueId), footerbuttons: this._getFooterButtons() },
            React.createElement(Grid, { dataSource: this._roleMembers, paging: { enabled: true, pageSize: 20 } },
                React.createElement(FilterRow, { visible: true }),
                React.createElement(Column, { dataField: "principalCodeName", caption: "Login" }),
                React.createElement(Column, { dataField: "principalEmail", caption: "Email" }),
                React.createElement(Column, { dataField: "principalDisplayName", caption: "Name" }),
                React.createElement(Column, { caption: "Action", width: 100, cellRender: function (_a) {
                        var data = _a.data;
                        return data.canDelete && (React.createElement("div", null,
                            React.createElement(Button, { color: "danger", size: "xs", onClick: function () { return _this._handleRoleMemberDelete(data); } },
                                React.createElement(FaTrash, null))));
                    } }),
                React.createElement(Summary, null,
                    React.createElement(TotalItem, { column: "principalCodeName", summaryType: "count", customizeText: this._customizeTotalCount }))),
            React.createElement("div", null,
                React.createElement(RowLabel, { text: "Add user to role", className: styles.rowLabel, mandatory: true }),
                React.createElement(PersonPicker, { onUsersSelect: this._handleUserSelect, isClearable: true }),
                React.createElement(Button, { className: styles.action, color: "primary", onClick: this._handleRoleMemberAdd }, "Add"))));
    };
    RoleMembers.prototype._getFooterButtons = function () {
        return [
            {
                onClick: this.props.onClose,
                title: 'Close'
            }
        ];
    };
    __decorate([
        observable
    ], RoleMembers.prototype, "_roleMembers", void 0);
    __decorate([
        observable
    ], RoleMembers.prototype, "_selectedPrincipal", void 0);
    __decorate([
        action.bound
    ], RoleMembers.prototype, "_loadRoleMembers", null);
    __decorate([
        action.bound
    ], RoleMembers.prototype, "_handleUserSelect", null);
    __decorate([
        action.bound
    ], RoleMembers.prototype, "_handleRoleMemberAdd", null);
    __decorate([
        action.bound
    ], RoleMembers.prototype, "_handleRoleMemberDelete", null);
    RoleMembers = __decorate([
        observer
    ], RoleMembers);
    return RoleMembers;
}(React.PureComponent));
