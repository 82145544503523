var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { observer } from 'mobx-react';
import { Select } from '@app/Components/Select/Select';
import { action, observable } from 'mobx';
import { GateMeetingRoleTypeEnum } from '@app/AppConstants';
import EnumService from '@app/Services/EnumService';
export var GateMeetingRolePicker = /** @class */ (function (_super) {
    __extends(GateMeetingRolePicker, _super);
    function GateMeetingRolePicker() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._value = null;
        _this.pickerOptions = [
            { value: GateMeetingRoleTypeEnum.ProjectManager, label: GateMeetingRoleTypeEnum.ProjectManager },
            { value: GateMeetingRoleTypeEnum.GateAssessor, label: GateMeetingRoleTypeEnum.GateAssessor }
        ];
        return _this;
    }
    GateMeetingRolePicker.prototype.componentDidMount = function () {
        var defaultRoleOption = this.props.defaultRoleOption;
        if (defaultRoleOption) {
            var index = this.pickerOptions.findIndex(function (x) { return x.value === defaultRoleOption; });
            if (index > -1) {
                this._value = this.pickerOptions[index];
            }
        }
    };
    GateMeetingRolePicker.prototype._handleSelectChange = function (value) {
        var _a, _b, _c;
        this._value = value;
        var item = ((_a = this._value) === null || _a === void 0 ? void 0 : _a.value)
            ? EnumService.getEnumKeyByEnumValue(GateMeetingRoleTypeEnum, this._value.value)
            : null;
        (_c = (_b = this.props).onRoleSelect) === null || _c === void 0 ? void 0 : _c.call(_b, item);
    };
    GateMeetingRolePicker.prototype.render = function () {
        return (React.createElement(Select, __assign({}, this.props, { options: this.pickerOptions, onChange: this._handleSelectChange, value: this._value })));
    };
    __decorate([
        observable
    ], GateMeetingRolePicker.prototype, "_value", void 0);
    __decorate([
        action.bound
    ], GateMeetingRolePicker.prototype, "_handleSelectChange", null);
    GateMeetingRolePicker = __decorate([
        observer
    ], GateMeetingRolePicker);
    return GateMeetingRolePicker;
}(React.Component));
