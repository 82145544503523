var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { IoMdCopy } from 'react-icons/io';
import styles from './CopyChecklistCommentsButton.module.scss';
export var CopyChecklistCommentsButton = /** @class */ (function (_super) {
    __extends(CopyChecklistCommentsButton, _super);
    function CopyChecklistCommentsButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isDropdownOpen = false;
        return _this;
    }
    CopyChecklistCommentsButton.prototype._togleDropdown = function () {
        this._isDropdownOpen = !this._isDropdownOpen;
    };
    CopyChecklistCommentsButton.prototype._renderDropdownItems = function () {
        var _a;
        var checklists = (_a = this.props, _a.checklists), onChecklistClick = _a.onChecklistClick;
        return checklists.map(function (checklist) { return (React.createElement(DropdownItem, { key: checklist.id, className: styles.dropdownItem, onClick: function () { return onChecklistClick(checklist); } },
            "Copy Comments from ",
            checklist.displayName)); });
    };
    CopyChecklistCommentsButton.prototype.render = function () {
        var className = this.props.className;
        var cls = "".concat(styles.copyButton, " ").concat(className);
        return (React.createElement(ButtonDropdown, { isOpen: this._isDropdownOpen, className: cls, toggle: this._togleDropdown },
            React.createElement(DropdownToggle, { color: "primary", className: styles.dropdownButton, disabled: !this.props.checklists.length },
                React.createElement(IoMdCopy, null)),
            React.createElement(DropdownMenu, { className: styles.dropdownMenu }, this._renderDropdownItems())));
    };
    __decorate([
        observable
    ], CopyChecklistCommentsButton.prototype, "_isDropdownOpen", void 0);
    __decorate([
        action.bound
    ], CopyChecklistCommentsButton.prototype, "_togleDropdown", null);
    CopyChecklistCommentsButton = __decorate([
        observer
    ], CopyChecklistCommentsButton);
    return CopyChecklistCommentsButton;
}(React.PureComponent));
