var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React, { createRef } from 'react';
import { EditorState, ContentState, convertToRaw, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import styles from './HtmlEditor.module.scss';
export var HtmlEditor = /** @class */ (function (_super) {
    __extends(HtmlEditor, _super);
    function HtmlEditor(props) {
        var _this = _super.call(this, props) || this;
        _this._editorRef = createRef();
        var blocksFromHtml = htmlToDraft(_this.props.value || '');
        var contentBlocks = blocksFromHtml.contentBlocks, entityMap = blocksFromHtml.entityMap;
        var contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        _this._state = EditorState.moveSelectionToEnd(EditorState.createWithContent(contentState));
        return _this;
    }
    HtmlEditor.prototype.componentDidMount = function () {
        var _a;
        if (this.props.isFocused)
            (_a = this._editorRef.current) === null || _a === void 0 ? void 0 : _a.focusEditor();
    };
    HtmlEditor.prototype._handleEditorStateChange = function (state) {
        var _a, _b;
        this._state = state;
        var html = draftToHtml(convertToRaw(state.getCurrentContent()));
        (_b = (_a = this.props).onValueChanged) === null || _b === void 0 ? void 0 : _b.call(_a, { value: html });
    };
    HtmlEditor.prototype._handlePastedText = function (text) {
        var blocksFromHtml = htmlToDraft(text);
        var contentBlocks = blocksFromHtml.contentBlocks, entityMap = blocksFromHtml.entityMap;
        var contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        var newContent = Modifier.replaceWithFragment(this._state.getCurrentContent(), this._state.getSelection(), contentState.getBlockMap());
        this._handleEditorStateChange(EditorState.push(this._state, newContent, 'insert-fragment'));
        return true;
    };
    HtmlEditor.prototype.reset = function (defaultValue) {
        var blocksFromHtml = htmlToDraft(defaultValue || '');
        var contentBlocks = blocksFromHtml.contentBlocks, entityMap = blocksFromHtml.entityMap;
        var contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        var state = EditorState.moveSelectionToEnd(EditorState.createWithContent(contentState));
        this._handleEditorStateChange(state);
    };
    HtmlEditor.prototype.render = function () {
        var _a, _b;
        var disabled = (_a = this.props, _a.disabled), height = (_b = _a.height, _b === void 0 ? 250 : _b), onFocus = _a.onFocus;
        return (React.createElement(Editor, { ref: this._editorRef, toolbar: {
                options: ['inline', 'link', 'blockType', 'fontSize'],
                blockType: {
                    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
                },
                link: {
                    popupClassName: styles.linkPopup
                }
            }, onFocus: onFocus, wrapperClassName: styles.wrapper, editorClassName: styles.editor, toolbarClassName: styles.toolbar, editorStyle: { minHeight: height, maxHeight: height }, readOnly: disabled, editorState: this._state, handlePastedText: this._handlePastedText, onEditorStateChange: this._handleEditorStateChange, spellCheck: true }));
    };
    __decorate([
        observable
    ], HtmlEditor.prototype, "_state", void 0);
    __decorate([
        observable
    ], HtmlEditor.prototype, "_editorRef", void 0);
    __decorate([
        action.bound
    ], HtmlEditor.prototype, "_handleEditorStateChange", null);
    __decorate([
        action.bound
    ], HtmlEditor.prototype, "_handlePastedText", null);
    __decorate([
        action.bound
    ], HtmlEditor.prototype, "reset", null);
    HtmlEditor = __decorate([
        observer
    ], HtmlEditor);
    return HtmlEditor;
}(React.PureComponent));
