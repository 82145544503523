var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { InApprovalWarning } from './InApprovalWarning';
var InApprovalLinkedDeliverableWarning = /** @class */ (function (_super) {
    __extends(InApprovalLinkedDeliverableWarning, _super);
    function InApprovalLinkedDeliverableWarning() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InApprovalLinkedDeliverableWarning.prototype.render = function () {
        var _a = this.props, onDismissWarning = _a.onDismissWarning, onToggleWarning = _a.onToggleWarning;
        return (React.createElement(InApprovalWarning, { onDismissWarning: onDismissWarning, onToggleWarning: onToggleWarning, text: "The deliverable linked should have been approved outside of TeamGate. It will auto approve here." }));
    };
    return InApprovalLinkedDeliverableWarning;
}(React.PureComponent));
export { InApprovalLinkedDeliverableWarning };
