var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import styles from './ExternalAppButton.module.scss';
import { Button } from '@app/Components/Button/Button';
var ExternalAppButton = /** @class */ (function (_super) {
    __extends(ExternalAppButton, _super);
    function ExternalAppButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ExternalAppButton.prototype.render = function () {
        var _a = this.props, onButtonClick = _a.onButtonClick, className = _a.className, isTiny = _a.isTiny, isWhite = _a.isWhite, disabled = _a.disabled;
        var iconStyles = 'styles.icon';
        var tinyButtonStyles = isTiny ? styles.tinyButton : '';
        var cls = "".concat(styles.documentIcon, " ").concat(iconStyles, " ").concat(className, " ").concat(tinyButtonStyles);
        var textColor = isWhite ? 'white' : 'gray';
        return (React.createElement(Button, { size: "xs", color: "primary", className: cls, disabled: disabled, onClick: onButtonClick, title: "Managed by external application" },
            React.createElement("span", { style: { color: textColor } }, "E")));
    };
    return ExternalAppButton;
}(React.PureComponent));
export { ExternalAppButton };
