var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import notify from 'devextreme/ui/notify';
import { Grid } from '@app/Components/Grid/Grid';
import { Column } from 'devextreme-react/data-grid';
import { Api } from '@app/Api';
import { DateBox } from '@app/Widgets/DateBox/DateBox';
import { Modal } from '@app/Components/Modal/Modal';
import { devInfoStore } from '@app/Stores/DevInfoStore';
import { DownloadButton } from '@app/Components/DownloadButton/DownloadButton';
export var AttachmentView = /** @class */ (function (_super) {
    __extends(AttachmentView, _super);
    function AttachmentView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._fileInputRef = React.createRef();
        _this._isSaving = false;
        _this._attachments = [];
        _this._loading = false;
        return _this;
    }
    AttachmentView.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadAttachments()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AttachmentView.prototype._loadAttachments = function () {
        return __awaiter(this, void 0, void 0, function () {
            var projectId, gateId, meetingId, manualTaskId, params, _a;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        projectId = (_b = this.props, _b.projectId), gateId = _b.gateId, meetingId = _b.meetingId, manualTaskId = _b.manualTaskId;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, , 3, 4]);
                        this._loading = true;
                        params = { projectId: projectId, gateId: gateId, meetingId: meetingId, taskId: manualTaskId };
                        _a = this;
                        return [4 /*yield*/, Api.secured.getAttachments(params)];
                    case 2:
                        _a._attachments = _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this._loading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AttachmentView.prototype._handleCancel = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.props.onToggle();
                return [2 /*return*/];
            });
        });
    };
    AttachmentView.prototype._handleLoadClick = function () {
        var _a;
        (_a = this._fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    AttachmentView.prototype._handleFileChange = function (e) {
        return __awaiter(this, void 0, void 0, function () {
            var files, file, sixeInMb;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.persist();
                        files = e.target.files;
                        if (!(files && files.length)) return [3 /*break*/, 4];
                        file = files[0];
                        if (file.size > devInfoStore.maxDocumentSize) {
                            this._fileInputRef.current && (this._fileInputRef.current.value = '');
                            sixeInMb = devInfoStore.maxDocumentSize / 1048576;
                            notify("File size must be less than ".concat(sixeInMb, "MB"), 'error', 1000);
                            return [2 /*return*/];
                        }
                        this._isSaving = true;
                        if (!this.props.meetingId) return [3 /*break*/, 2];
                        return [4 /*yield*/, Api.secured.uploadAttachment(this.props.meetingId, { file: file })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        // if (this.props.manualTaskId) {
                        //     await Api.secured.uploadManualTaskAttachment(this.props.manualTaskId, { file });
                        // }
                        this._isSaving = false;
                        this.props.onFileChange && this.props.onFileChange();
                        return [4 /*yield*/, this._loadAttachments()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AttachmentView.prototype.render = function () {
        var _a;
        var isOpen = (_a = this.props, _a.isOpen), onToggle = _a.onToggle;
        return (React.createElement(Modal, { isOpen: isOpen, toggle: onToggle, headerTitle: "Document View", maxWidth: "80%", footerbuttons: this._getFooterButtons() },
            this._attachments && (React.createElement(Grid, { dataSource: this._attachments, loading: this._loading },
                React.createElement(Column, { caption: "Name", dataField: "name", minWidth: 200 }),
                React.createElement(Column, { caption: "Uploaded Date", dataField: "uploadedDate", dataType: "date", width: 150, cellRender: function (_a) {
                        var data = _a.data;
                        return React.createElement(DateBox, { date: data.uploadedDate });
                    } }),
                React.createElement(Column, { caption: "Uploaded By", dataField: "uploadedBy", width: 150 }),
                React.createElement(Column, { caption: "Action", width: 300, cellRender: function (_a) {
                        var data = _a.data;
                        if (!data.id)
                            return null;
                        return React.createElement(DownloadButton, { fileId: data.id });
                    } }))),
            React.createElement("input", { style: { display: 'none' }, type: "file", ref: this._fileInputRef, onChange: this._handleFileChange })));
    };
    AttachmentView.prototype._getFooterButtons = function () {
        return [
            {
                color: 'primary',
                onClick: this._handleLoadClick,
                title: 'Upload'
            },
            {
                onClick: this._handleCancel,
                title: 'Close'
            }
        ];
    };
    __decorate([
        observable
    ], AttachmentView.prototype, "_fileInputRef", void 0);
    __decorate([
        observable
    ], AttachmentView.prototype, "_isSaving", void 0);
    __decorate([
        observable
    ], AttachmentView.prototype, "_attachments", void 0);
    __decorate([
        observable
    ], AttachmentView.prototype, "_loading", void 0);
    __decorate([
        action.bound
    ], AttachmentView.prototype, "_loadAttachments", null);
    __decorate([
        action.bound
    ], AttachmentView.prototype, "_handleCancel", null);
    __decorate([
        action.bound
    ], AttachmentView.prototype, "_handleLoadClick", null);
    __decorate([
        action.bound
    ], AttachmentView.prototype, "_handleFileChange", null);
    AttachmentView = __decorate([
        observer
    ], AttachmentView);
    return AttachmentView;
}(React.PureComponent));
