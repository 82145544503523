var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { observer } from 'mobx-react';
import styles from './add-participant.module.scss';
import { action } from 'mobx';
import { PersonPicker } from '@app/Components/Pickers/PersonPicker/PersonPicker';
export var AddParticipant = /** @class */ (function (_super) {
    __extends(AddParticipant, _super);
    function AddParticipant() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AddParticipant.prototype._handleUserSelect = function (users) {
        var onAdd = this.props.onAdd;
        if (users === null || users === void 0 ? void 0 : users.length)
            onAdd(users[0]);
    };
    AddParticipant.prototype.render = function () {
        var exceptIds = this.props.exceptIds;
        return (React.createElement("div", { className: styles.addParticipant },
            React.createElement("div", { style: { width: '70%' } },
                React.createElement(PersonPicker, { onUsersSelect: this._handleUserSelect, exceptIds: exceptIds, clearAfterSelect: true }))));
    };
    __decorate([
        action.bound
    ], AddParticipant.prototype, "_handleUserSelect", null);
    AddParticipant = __decorate([
        observer
    ], AddParticipant);
    return AddParticipant;
}(React.PureComponent));
