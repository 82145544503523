/* eslint-disable @typescript-eslint/no-explicit-any */
/* tslint:disable:no-any */
import { DateTime } from '@app/AppConstants';
Date.prototype.toJSON = function () {
    //use custom implementation for better performance
    var year = this.getUTCFullYear().toString();
    var month = (this.getUTCMonth() + 1).toString();
    var day = this.getUTCDate().toString();
    var hh = this.getUTCHours().toString();
    var mm = this.getUTCMinutes().toString();
    var ss = this.getUTCSeconds().toString();
    if (month.length === 1)
        month = '0' + month;
    if (day.length === 1)
        day = '0' + day;
    if (hh.length === 1)
        hh = '0' + hh;
    if (mm.length === 1)
        mm = '0' + mm;
    if (ss.length === 1)
        ss = '0' + ss;
    //'YYYY-MM-DDTHH:MM:SS.Z'
    return "".concat(year, "-").concat(month, "-").concat(day, "T").concat(hh, ":").concat(mm, ":").concat(ss, "Z");
};
var DateTimeService = /** @class */ (function () {
    function DateTimeService() {
    }
    //#region NEW API
    DateTimeService.min = function (value1, value2) {
        if (!value1)
            return value2;
        return value1 > value2 ? value2 : value1;
    };
    DateTimeService.max = function (value1, value2) {
        if (!value1)
            return value2;
        return value1 > value2 ? value1 : value2;
    };
    DateTimeService.parseUiDate = function (value) {
        return this.parse(value, DateTime.viewDateFormat);
    };
    DateTimeService.parseUiDateTime = function (value) {
        return this.parse(value, DateTime.viewFullFormat);
    };
    DateTimeService.parseUiSeconds = function (value) {
        if (!value)
            return NaN;
        var timeParts = value.split(':');
        if (timeParts.length > 2)
            return NaN;
        var hours = +timeParts[0];
        var minutes = +timeParts[1];
        var result = (hours * 60 + minutes) * 60;
        if (value.charAt(0) === '-')
            result = result * -1;
        return result;
    };
    DateTimeService.parseUiTime = function (date, value) {
        var timeParts = value.split(':');
        var hours = +timeParts[0];
        var minutes = +timeParts[1];
        var originDate = DateTimeService.date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
        originDate.setUTCMinutes(minutes);
        originDate.setUTCHours(hours);
        return originDate;
    };
    DateTimeService.toUiDate = function (value) {
        var d = value.getDate().toString();
        var m = (value.getUTCMonth() + 1).toString();
        d = d.length === 1 ? '0' + d : d;
        m = m.length === 1 ? '0' + m : m;
        return d + '.' + m + '.' + value.getUTCFullYear();
    };
    DateTimeService.toUiDateShortYear = function (value) {
        var d = value.getUTCDate().toString();
        var m = (value.getUTCMonth() + 1).toString();
        var y = value
            .getFullYear()
            .toString()
            .substr(-2);
        d = d.length === 1 ? '0' + d : d;
        m = m.length === 1 ? '0' + m : m;
        return d + '.' + m + '.' + y;
    };
    DateTimeService.toUiMonth = function (value) {
        var m = (value.getUTCMonth() + 1).toString();
        m = m.length === 1 ? '0' + m : m;
        return m + '.' + value.getUTCFullYear();
    };
    DateTimeService.toUiDateTime = function (value) {
        if (isNaN(value.getTime()))
            return '~';
        var d = value.getUTCDate().toString();
        var m = (value.getUTCMonth() + 1).toString();
        var h = value.getUTCHours().toString();
        var mm = value.getUTCMinutes().toString();
        d = d.length === 1 ? '0' + d : d;
        m = m.length === 1 ? '0' + m : m;
        h = h.length === 1 ? '0' + h : h;
        mm = mm.length === 1 ? '0' + mm : mm;
        return d + '.' + m + '.' + value.getUTCFullYear() + ' ' + h + ':' + mm;
    };
    DateTimeService.toUiClientShortDate = function (value) {
        var d = value.getDate().toString();
        var mIndex = value.getMonth();
        var m = this.monthNamesShort[mIndex];
        return m + "'" + d;
    };
    DateTimeService.toUiClientShortDateTime = function (value) {
        var d = value.getDate().toString();
        var mIndex = value.getMonth();
        var m = this.monthNamesShort[mIndex];
        var h = value.getHours().toString();
        var mm = value.getMinutes().toString();
        d = d.length === 1 ? '0' + d : d;
        m = m.length === 1 ? '0' + m : m;
        h = h.length === 1 ? '0' + h : h;
        mm = mm.length === 1 ? '0' + mm : mm;
        var isToday = new Date().toDateString() === value.toDateString();
        return (isToday ? '' : d + ' ' + m + ' ') + h + ':' + mm;
    };
    DateTimeService.toUiTime = function (value) {
        if (isNaN(value.getTime()))
            return '~';
        var h = value.getUTCHours().toString();
        var mm = value.getUTCMinutes().toString();
        h = h.length === 1 ? '0' + h : h;
        mm = mm.length === 1 ? '0' + mm : mm;
        return h + ':' + mm;
    };
    DateTimeService.toUiTimestamp = function (value) {
        var d = value.getUTCDate().toString();
        var m = (value.getUTCMonth() + 1).toString();
        var h = value.getUTCHours().toString();
        var mm = value.getUTCMinutes().toString();
        var s = value.getUTCSeconds().toString();
        d = d.length === 1 ? '0' + d : d;
        m = m.length === 1 ? '0' + m : m;
        h = h.length === 1 ? '0' + h : h;
        mm = mm.length === 1 ? '0' + mm : mm;
        s = s.length === 1 ? '0' + s : s;
        return d + '.' + m + '.' + value.getUTCFullYear() + ' ' + h + ':' + mm + ':' + s;
    };
    DateTimeService.toUiSeconds = function (seconds) {
        var sec = Math.trunc(seconds);
        var isNegative = sec < 0;
        var h = Math.abs(Math.trunc(sec / (60 * 60)));
        var m = Math.abs(Math.trunc(sec / 60) - h * 60);
        var mm = m.toString();
        var hh = h.toString();
        mm = mm.length === 1 ? '0' + mm : mm;
        hh = hh.length === 1 ? '0' + hh : hh;
        return (isNegative ? '-' : '') + hh + ':' + mm;
    };
    DateTimeService.toUiMiliseconds = function (miliseconds) {
        var sec = Math.trunc(miliseconds / 1000);
        var isNegative = sec < 0;
        var h = Math.abs(Math.trunc(sec / (60 * 60)));
        var m = Math.abs(Math.trunc(sec / 60) - h * 60);
        var s = Math.abs(Math.trunc(sec - h * 60 * 60 - m * 60));
        var mm = m.toString();
        var hh = h.toString();
        var ss = s.toString();
        mm = mm.length === 1 ? '0' + mm : mm;
        hh = hh.length === 1 ? '0' + hh : hh;
        ss = ss.length === 1 ? '0' + ss : ss;
        return (isNegative ? '-' : '') + hh + ':' + mm + ':' + ss;
    };
    DateTimeService.toCustomUiFormat = function (value, format) {
        return this.format(value, format);
    };
    DateTimeService.isSameDate = function (d1, d2) {
        if ((d1 || null) === (d2 || null))
            return true;
        if (!d1 || !d2)
            return false;
        return (d1.getUTCFullYear() === d2.getUTCFullYear() &&
            d1.getUTCMonth() === d2.getUTCMonth() &&
            d1.getUTCDate() === d2.getUTCDate());
    };
    DateTimeService.isSameMonth = function (d1, d2) {
        return d1.getUTCFullYear() === d2.getUTCFullYear() && d1.getUTCMonth() === d2.getUTCMonth();
    };
    DateTimeService.isSameYear = function (d1, d2) {
        return d1.getUTCFullYear() === d2.getUTCFullYear();
    };
    DateTimeService.isAfterDate = function (d1, d2) {
        if (d1.getUTCFullYear() > d2.getUTCFullYear())
            return true;
        if (d1.getUTCFullYear() < d2.getUTCFullYear())
            return false;
        if (d1.getUTCMonth() > d2.getUTCMonth())
            return true;
        if (d1.getUTCMonth() < d2.getUTCMonth())
            return false;
        return d1.getUTCDate() > d2.getUTCDate();
    };
    DateTimeService.isAfterDateOrEqual = function (d1, d2) {
        if (d1.getUTCFullYear() > d2.getUTCFullYear())
            return true;
        if (d1.getUTCFullYear() < d2.getUTCFullYear())
            return false;
        if (d1.getUTCMonth() > d2.getUTCMonth())
            return true;
        if (d1.getUTCMonth() < d2.getUTCMonth())
            return false;
        return d1.getUTCDate() >= d2.getUTCDate();
    };
    DateTimeService.isBeforeDate = function (d1, d2) {
        if (d1.getUTCFullYear() < d2.getUTCFullYear())
            return true;
        if (d1.getUTCFullYear() > d2.getUTCFullYear())
            return false;
        if (d1.getUTCMonth() < d2.getUTCMonth())
            return true;
        if (d1.getUTCMonth() > d2.getUTCMonth())
            return false;
        return d1.getUTCDate() < d2.getUTCDate();
    };
    DateTimeService.isBeforeDateOrEqual = function (d1, d2) {
        if (d1.getUTCFullYear() < d2.getUTCFullYear())
            return true;
        if (d1.getUTCFullYear() > d2.getUTCFullYear())
            return false;
        if (d1.getUTCMonth() < d2.getUTCMonth())
            return true;
        if (d1.getUTCMonth() > d2.getUTCMonth())
            return false;
        return d1.getUTCDate() <= d2.getUTCDate();
    };
    DateTimeService.compare = function (d1, d2) {
        return d1.getTime() - d2.getTime();
    };
    DateTimeService.isValidDate = function (d) {
        return !isNaN(d.getTime());
    };
    DateTimeService.diffMonths = function (dateFrom, dateTo) {
        var months = dateTo.getUTCFullYear() * 12 + dateTo.getUTCMonth() - dateFrom.getFullYear() * 12 - dateFrom.getUTCMonth();
        return months;
    };
    DateTimeService.diffDays = function (to, from) {
        var date1WithoutTime = this.withTime(to, 0, 0);
        var date2WithoutTime = this.withTime(from, 0, 0);
        var diff = date1WithoutTime.getTime() - date2WithoutTime.getTime();
        return Math.ceil(diff / (24 * 60 * 60 * 1000));
    };
    DateTimeService.diffSeconds = function (to, from) {
        var diff = to.getTime() - from.getTime();
        return Math.ceil(diff / 1000);
    };
    DateTimeService.diffMinutes = function (to, from) {
        var diff = to.getTime() - from.getTime();
        return Math.ceil(diff / (60 * 1000));
    };
    DateTimeService.diffHours = function (to, from) {
        var diff = to.getTime() - from.getTime();
        return diff / (60 * 60 * 1000);
    };
    DateTimeService.addDays = function (date, days) {
        return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
    };
    DateTimeService.addMonths = function (date, months) {
        return this.date(date.getUTCFullYear(), date.getUTCMonth() + months, date.getUTCDate());
    };
    DateTimeService.addYears = function (date, years) {
        return this.date(date.getUTCFullYear() + years, date.getUTCMonth(), date.getUTCDate());
    };
    DateTimeService.addTime = function (date, hours, minutes) {
        var offset = hours * 60 * 60 * 1000;
        if (minutes) {
            offset += minutes * 60 * 1000;
        }
        return new Date(date.getTime() + offset);
    };
    DateTimeService.addDiffToLt = function (date, sourceOffset) {
        var offset = sourceOffset.indexOf(':') ? sourceOffset.replace(':', '') : sourceOffset;
        var signChar = offset[0];
        var sign = signChar === '-' ? -1 : 1;
        var timeDiff = offset.substring(signChar === '-' || signChar === '+' ? 1 : 0);
        if (timeDiff.length !== 4) {
            throw new Error('Invalid time offset, expected format +HHMM, actual: ' + offset);
        }
        var hh = sign * +timeDiff.substring(0, 2);
        var mm = sign * +timeDiff.substring(2, 4);
        return DateTimeService.addTime(date, hh, mm);
    };
    DateTimeService.today = function () {
        var today = new Date();
        return DateTimeService.date(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate());
    };
    DateTimeService.range = function (dateFrom, dateTo) {
        var days = [];
        var date = dateFrom;
        days.push(date);
        while (date.getTime() < dateTo.getTime()) {
            date = new Date(date.getTime() + 24 * 60 * 60 * 1000);
            days.push(date);
        }
        return days;
    };
    DateTimeService.fromString = function (value) {
        return new Date(Date.parse(value));
    };
    DateTimeService.withTime = function (date, hours, minutes) {
        var d = DateTimeService.date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
        return DateTimeService.addTime(d, hours, minutes);
    };
    DateTimeService.date = function (year, month, day) {
        return new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
    };
    DateTimeService.now = function () {
        return new Date();
    };
    DateTimeService.startOfMonth = function () {
        var now = this.now();
        return this.date(now.getUTCFullYear(), now.getUTCMonth(), 1);
    };
    DateTimeService.toFirstDayOfMonth = function (date) {
        return this.date(date.getUTCFullYear(), date.getUTCMonth(), 1);
    };
    DateTimeService.toLastDayOfMonth = function (date) {
        return this.date(date.getUTCFullYear(), date.getUTCMonth() + 1, 0);
    };
    DateTimeService.startOfDate = function (date) {
        return this.date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    };
    DateTimeService.toFirstDayOfWeek = function (date) {
        var dayOfWeek = date.getUTCDay() - 1;
        if (dayOfWeek < 0) {
            dayOfWeek = 6;
        }
        return this.date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() - dayOfWeek);
    };
    DateTimeService.toLastDayOfWeek = function (date) {
        var dayOfWeek = date.getUTCDay() - 1;
        if (dayOfWeek < 0) {
            dayOfWeek = 6;
        }
        return this.date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() - dayOfWeek + 6);
    };
    DateTimeService.getDateIndexInRange = function (date, dateFrom, dateTo) {
        if (date < dateFrom)
            return -1;
        if (dateTo && date > dateTo)
            return -1;
        return this.diffDays(date, dateFrom);
    };
    DateTimeService.calculateAge = function (birthday, atDate) {
        var ageDifMs = atDate.getTime() - birthday.getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    };
    DateTimeService.isIntersects = function (r1, r2) {
        return r1.to >= r2.from && r1.from <= r2.to;
    };
    DateTimeService.asRestString = function (date) {
        return '/' + date.getUTCFullYear() + '/' + (date.getUTCMonth() + 1) + '/' + date.getUTCDate();
    };
    DateTimeService.parse = function (value, format) {
        if (typeof value !== 'string')
            return this.getNanDate();
        var year = 1;
        var month = 0;
        var day = 1;
        var hour = 0;
        var minute = 0;
        var second = 0;
        var milliseconds = 0;
        var pattern = DateTimeService.patternCache[format];
        var regexp = '';
        var patterns = [];
        if (!pattern) {
            regexp = format.replace(this.regExpDateMask, function (match, pattern) {
                patterns.push(pattern);
                return DateTimeService.patternsToRegex[pattern];
            });
            regexp = new RegExp(regexp);
            DateTimeService.patternCache[format] = pattern;
        }
        var match = value.match(regexp);
        if (match) {
            for (var i = 1; i < match.length; i++) {
                var foundValue = patterns[i - 1];
                var valuePart = match[i];
                switch (foundValue) {
                    case 'YYYY':
                        year = this._parseSafeInteger(valuePart, 9999);
                        break;
                    case 'M':
                    case 'MM':
                        month = this._parseSafeInteger(valuePart, 12) - 1;
                        break;
                    case 'MMMM':
                        month = this.monthNames.indexOf(valuePart);
                        if (month === -1)
                            throw new Error('Invalid month name');
                        break;
                    case 'D':
                    case 'DD':
                        day = this._parseSafeInteger(valuePart, 31);
                        break;
                    case 'H':
                    case 'HH':
                        hour = this._parseSafeInteger(valuePart, 23);
                        break;
                    case 'mm':
                        minute = this._parseSafeInteger(valuePart, 59);
                        break;
                    case 'ss':
                        second = this._parseSafeInteger(valuePart, 59);
                        break;
                    case 'SSS':
                        milliseconds = this._parseSafeInteger(valuePart, 999);
                        break;
                }
            }
        }
        else {
            return this.getNanDate();
        }
        var newDate = new Date(Date.UTC(year, month, day, hour, minute, second, milliseconds));
        var newDateDay = newDate.getUTCDate();
        if (newDateDay === day) {
            return newDate;
        }
        return this.getNanDate();
    };
    DateTimeService.format = function (date, format) {
        var _this = this;
        if (format === void 0) { format = DateTime.viewDateFormat; }
        var result = format;
        result = result.replace(this.regExpDateMask, function (match) {
            switch (match) {
                case 'DD':
                    return date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate().toString();
                case 'D':
                    return date.getUTCDate().toString();
                case 'dd': {
                    var dayOfWeek = date.getUTCDay() - 1;
                    if (dayOfWeek < 0) {
                        dayOfWeek = 6;
                    }
                    return _this.weekDayNames2Letters[dayOfWeek];
                }
                case 'MMMM':
                    return _this.monthNames[date.getUTCMonth()];
                case 'MM':
                    return date.getUTCMonth() + 1 < 10
                        ? '0' + (date.getUTCMonth() + 1).toString()
                        : (date.getUTCMonth() + 1).toString();
                case 'M':
                    return (date.getUTCMonth() + 1).toString();
                case 'YYYY':
                    return date.getUTCFullYear().toString();
                case 'YY':
                    return date
                        .getUTCFullYear()
                        .toString()
                        .substr(-2);
                case 'HH':
                    return date.getUTCHours() < 10 ? '0' + date.getUTCHours() : date.getUTCHours().toString();
                case 'H':
                    return date.getUTCHours().toString();
                case 'mm':
                    return date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes().toString();
                case 'ss':
                    return date.getUTCSeconds() < 10 ? '0' + date.getUTCSeconds() : date.getUTCSeconds().toString();
                default:
                    return match;
            }
        });
        return result;
    };
    DateTimeService.toQueryParam = function (value) {
        return value.toJSON();
    };
    DateTimeService.getRefYear = function () {
        var date = DateTimeService.now();
        return date.getUTCMonth() <= 6 ? DateTimeService.addYears(date, -1).getUTCFullYear() : date.getUTCFullYear();
    };
    DateTimeService.getZeroDate = function () {
        return new Date(0);
    };
    DateTimeService.getNanDate = function () {
        return new Date(NaN);
    };
    DateTimeService.getStartOfDay = function (year, month, day) {
        var result = this.getZeroDate();
        result.setUTCFullYear(year);
        result.setUTCMonth(month);
        result.setUTCDate(day);
        return result;
    };
    DateTimeService._parseSafeInteger = function (value, maxValue) {
        var parsedPart = parseInt(value, 10);
        if (isNaN(parsedPart) || parsedPart < 0 || !Number.isInteger(parsedPart)) {
            throw new Error('Failed parse ' + value + ' exception, expected format [0-9]');
        }
        if (parsedPart > maxValue) {
            return NaN;
        }
        return parsedPart;
    };
    DateTimeService.getAllowedChangesDate = function () {
        var dateNow = DateTimeService.today();
        var currentDay = dateNow.getUTCDate();
        var startOfMonth = DateTimeService.toFirstDayOfMonth(dateNow);
        var startOfPrevMonth = DateTimeService.addMonths(startOfMonth, -1);
        return currentDay >= 3 ? startOfMonth : startOfPrevMonth;
    };
    DateTimeService.ISO_8601 = /^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$/i;
    DateTimeService.ISO_8601_date = /^\d{4}-\d\d-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?$/i;
    DateTimeService.regExpDateMask = /(YYYY|YY|MMMM|MM|M|DD|D|dd|d|HH|H|mm|ss|SSS)/g;
    DateTimeService.monthNamesShort = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ];
    DateTimeService.monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];
    DateTimeService.weekDayNames2Letters = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
    //#endregion
    DateTimeService.patternCache = {};
    DateTimeService.patternsToRegex = {
        YYYY: '(\\d{4})',
        MMMM: '(\\S+)',
        MM: '(\\d{2})',
        M: '(\\d{1,2})',
        DD: '(\\d{2})',
        D: '(\\d{1,2})',
        HH: '(\\d{2})',
        H: '(\\d{1,2})',
        mm: '(\\d{2})',
        ss: '(\\d{2})',
        SSS: '(\\d{3})'
    };
    DateTimeService.typeCheck = function (el, isDate) {
        if (!el)
            return el;
        var typeEl = el;
        switch (typeof el) {
            case 'string':
                typeEl = DateTimeService.strCheck(el, isDate);
                break;
            case 'object':
                typeEl = Array.isArray(el) ? DateTimeService.arrCheck(el) : DateTimeService.objCheck(el);
                break;
        }
        return typeEl;
    };
    DateTimeService.strCheck = function (str, isDate) {
        if (isDate && DateTimeService.ISO_8601_date.test(str))
            return DateTimeService.fromString(str);
        return str;
    };
    DateTimeService.arrCheck = function (array) {
        return array.map(function (el) {
            return DateTimeService.typeCheck(el);
        });
    };
    DateTimeService.objCheck = function (obj) {
        Object.keys(obj).forEach(function (key) {
            obj[key] = DateTimeService.typeCheck(obj[key], key.indexOf('date') === 0 || key.indexOf('Date') !== -1);
        });
        return obj;
    };
    return DateTimeService;
}());
export default DateTimeService;
