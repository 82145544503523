var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import styles from './GlobalLoader.module.scss';
import { Loader } from '@app/Components/Loader/Loader';
var GlobalLoader = /** @class */ (function (_super) {
    __extends(GlobalLoader, _super);
    function GlobalLoader() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GlobalLoader.prototype.render = function () {
        var loading = this.props.loading;
        var visible = loading !== null && loading !== void 0 ? loading : true;
        return (React.createElement("div", { hidden: !visible, className: styles.globalLoader },
            React.createElement(Loader, { style: { width: 80, height: 80 } })));
    };
    return GlobalLoader;
}(React.PureComponent));
export { GlobalLoader };
