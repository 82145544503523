var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Grid } from '@app/Components/Grid/Grid';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Api } from '@app/Api';
import { Column } from 'devextreme-react/data-grid';
import { Button } from '@app/Components/Button/Button';
import { Route, Switch, withRouter } from 'react-router-dom';
import styles from './DeliversTable.module.scss';
import columnSharedStyles from '@app/Components/Grid/SharedStyles/column-shared-styles.module.scss';
import { ApprovalApplicationEnum, ChecklistItemType, ChecklistSections, ComponentUrls, ObjectType, ProjectState, ProjectType, SeparatedStrings, SignalREvents, StatusEnum } from '@app/AppConstants';
import { DateBox } from '@app/Widgets/DateBox/DateBox';
import { createUniqueId } from '@app/Helpers/UidHelper/UidHelper';
import { ApproversModal } from './ApproversModal';
import HTMLReactParser from 'html-react-parser';
import { signalRService } from '@app/Services/SignalRService';
import { ApproveDenyDeliverableButtons } from '@app/Pages/ProjectView/ProjectViewTabs/GateDeliverables/DeliversTable/ApproveDenyDeliverableButtons';
import { CommentModal } from '@app/Widgets/CommentModal/CommentModal';
import { DocumentButton } from '@app/Components/DocumentButton/DocumentButton';
import { ValidationHint } from '@app/Components/ValidationHint/ValidationHint';
import { DocumentStatusIcon } from '@app/Components/DocumentStatusIcon/DocumentStatusIcon';
import { ChangeLogDialog } from '@app/Components/Dialogs/ChangeLogDialog';
import { CodeCell } from '@app/Components/Grid/CodeCell/CodeCell';
import { DownloadButton } from '@app/Components/DownloadButton/DownloadButton';
import { DocumentStatusModal } from '@app/Components/DocumentStatusModal/DocumentStatusModal';
import { InApprovalLinkedDeliverableWarning } from '@app/Components/InApprovalWarning/InApprovalLinkedDeliverableWarning';
import { InApprovalRedDotDeliverableWarning } from '@app/Components/InApprovalWarning/InApprovalRedDotDeliverableWarning';
import { CopyToClipBoardButton } from '@app/Components/CopyToClipboardButton/CopyToClipboardButton';
import { ExternalAppButton } from '../../../../../Components/ExternalAppButton/ExternalAppButton';
import { renderApprovalApplication } from '../../ProjectSetup/ExternalApprovals/ExternalApprovals';
var DeliversTableC = /** @class */ (function (_super) {
    __extends(DeliversTableC, _super);
    function DeliversTableC() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._delivers = [];
        _this._isApproversModalOpen = false;
        _this._isDocumentStatusModalOpen = false;
        _this._isCommentModalOpen = false;
        _this._isUnapproveDeliverableModalOpen = false;
        _this._currentDeliverable = null;
        _this._currentWarningDeliverable = null;
        _this._currentMasterDeliverable = null;
        _this._denyItem = null;
        _this._denyApprovalId = undefined;
        _this._unapprovedDeliverable = null;
        _this._showInApprovalWarning = false;
        _this._showInApprovalRedDotWarning = false;
        _this._dismissWarning = false;
        _this._dismissRedDotWarning = false;
        _this._loading = false;
        _this._handleDeliverableRatingsChanged = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._load()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    DeliversTableC.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        signalRService.subscribe(SignalREvents.recommendationChanged, this._load);
                        signalRService.subscribe(SignalREvents.deliverableRatingsChanged, this._handleDeliverableRatingsChanged);
                        signalRService.subscribe(SignalREvents.checklistStatusChanged, this._handleDeliverableRatingsChanged);
                        return [4 /*yield*/, this._load()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DeliversTableC.prototype.componentWillUnmount = function () {
        signalRService.unsubscribe(SignalREvents.recommendationChanged, this._load);
        signalRService.unsubscribe(SignalREvents.deliverableRatingsChanged, this._handleDeliverableRatingsChanged);
        signalRService.unsubscribe(SignalREvents.checklistStatusChanged, this._handleDeliverableRatingsChanged);
    };
    DeliversTableC.prototype.componentDidUpdate = function (prevProps) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(prevProps.match.params.id !== this.props.match.params.id ||
                            prevProps.isFilesChanged !== this.props.isFilesChanged ||
                            prevProps.hideNAItems !== this.props.hideNAItems)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._load()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    DeliversTableC.prototype._load = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, gateId, hideNAItems, checklistItemId, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this.props, gateId = _a.gateId, hideNAItems = _a.hideNAItems, checklistItemId = _a.checklistItemId;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, , 3, 4]);
                        this._loading = true;
                        _b = this;
                        return [4 /*yield*/, Api.secured.getGateDeliverables({
                                gateId: gateId,
                                masterChecklistItemId: checklistItemId,
                                hideNAItems: hideNAItems
                            })];
                    case 2:
                        _b._delivers = _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this._loading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DeliversTableC.prototype._toggleApproversModal = function () {
        this._isApproversModalOpen = !this._isApproversModalOpen;
    };
    DeliversTableC.prototype._toggleCommentModalOpen = function () {
        this._isCommentModalOpen = !this._isCommentModalOpen;
        if (!this._isCommentModalOpen)
            this._denyItem = null;
    };
    DeliversTableC.prototype._toggleDocumentStatusModalOpen = function () {
        this._isDocumentStatusModalOpen = !this._isDocumentStatusModalOpen;
        if (!this._isDocumentStatusModalOpen)
            this._currentMasterDeliverable = null;
    };
    DeliversTableC.prototype._toggleUnapproveDeliverableModalOpen = function () {
        this._isUnapproveDeliverableModalOpen = !this._isUnapproveDeliverableModalOpen;
        if (!this._isUnapproveDeliverableModalOpen)
            this._unapprovedDeliverable = null;
    };
    DeliversTableC.prototype._toggleLogModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, history, match;
            return __generator(this, function (_b) {
                _a = this.props, history = _a.history, match = _a.match;
                history.push(match.url);
                this._currentDeliverable = null;
                return [2 /*return*/];
            });
        });
    };
    DeliversTableC.prototype._handleDeny = function (comment) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 3, 5]);
                        this._loading = true;
                        if (!(this._denyItem && this._denyApprovalId)) return [3 /*break*/, 2];
                        return [4 /*yield*/, Api.secured.denyDeliverable(this._denyItem.id, {
                                approvalId: this._denyApprovalId,
                                comment: comment
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this._denyItem = null;
                        return [3 /*break*/, 5];
                    case 3:
                        this._loading = false;
                        return [4 /*yield*/, this._load()];
                    case 4:
                        _a.sent();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    DeliversTableC.prototype._showApprovers = function (deliverable) {
        this._currentDeliverable = deliverable;
        this._toggleApproversModal();
    };
    DeliversTableC.prototype._showChildDeliverables = function (deliverable) {
        var projectState = this.props.projectState;
        if (projectState === ProjectState.Full &&
            deliverable.checkListItemType === ChecklistItemType.MasterDeliverable) {
            this._currentMasterDeliverable = deliverable;
            this._toggleDocumentStatusModalOpen();
        }
    };
    DeliversTableC.prototype._handlePrepare = function (deliverable) {
        return __awaiter(this, void 0, void 0, function () {
            var showRedDotDeliverable, isSuccessful;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 2, 3]);
                        showRedDotDeliverable = this.props.showRedDotDeliverable;
                        if (showRedDotDeliverable && deliverable.hasRedDot && !this._dismissRedDotWarning) {
                            this._currentWarningDeliverable = deliverable;
                            this._onToggleRedDotWarning();
                            return [2 /*return*/];
                        }
                        this._loading = true;
                        return [4 /*yield*/, Api.secured.prepareDeliverable(deliverable.id, { dismissWarning: this._dismissWarning })];
                    case 1:
                        isSuccessful = (_a.sent()).body;
                        if (isSuccessful) {
                            this._dismissWarning = false;
                            this._showInApprovalWarning = false;
                        }
                        else {
                            this._showInApprovalWarning = true;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        this._loading = false;
                        return [7 /*endfinally*/];
                    case 3: return [4 /*yield*/, this._load()];
                    case 4: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    DeliversTableC.prototype._onDismissWarning = function (deliverable) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._dismissWarning = true;
                        return [4 /*yield*/, this._handlePrepare(deliverable)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DeliversTableC.prototype._onDismissRedDotWarning = function (deliverable) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._dismissRedDotWarning = true;
                        this._onToggleRedDotWarning();
                        return [4 /*yield*/, this._handlePrepare(deliverable)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DeliversTableC.prototype._onToggleWarning = function () {
        this._showInApprovalWarning = !this._showInApprovalWarning;
    };
    DeliversTableC.prototype._onToggleRedDotWarning = function () {
        this._showInApprovalRedDotWarning = !this._showInApprovalRedDotWarning;
        if (!this._showInApprovalRedDotWarning)
            this._currentWarningDeliverable = null;
    };
    DeliversTableC.prototype._handleUndoApprovalClick = function (deliverable) {
        this._toggleUnapproveDeliverableModalOpen();
        this._unapprovedDeliverable = deliverable;
    };
    DeliversTableC.prototype._handleUndoApproval = function (comment) {
        return __awaiter(this, void 0, void 0, function () {
            var id, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this._unapprovedDeliverable)
                            return [2 /*return*/];
                        id = this._unapprovedDeliverable.id;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, , 9, 10]);
                        this._loading = true;
                        _a = this._unapprovedDeliverable.checkListItemType;
                        switch (_a) {
                            case ChecklistItemType.MasterDeliverableWithoutFunctionDeliverable: return [3 /*break*/, 2];
                            case ChecklistItemType.MasterDeliverable: return [3 /*break*/, 4];
                        }
                        return [3 /*break*/, 6];
                    case 2: return [4 /*yield*/, Api.secured.undoDeliverableApprovalWithoutInput(id, { comment: comment })];
                    case 3:
                        _b.sent();
                        return [3 /*break*/, 8];
                    case 4: return [4 /*yield*/, Api.secured.undoMasterDeliverable(id, { comment: comment })];
                    case 5:
                        _b.sent();
                        return [3 /*break*/, 8];
                    case 6: return [4 /*yield*/, Api.secured.undoApproval(id, { comment: comment })];
                    case 7:
                        _b.sent();
                        _b.label = 8;
                    case 8: return [3 /*break*/, 10];
                    case 9:
                        this._loading = false;
                        return [7 /*endfinally*/];
                    case 10: return [4 /*yield*/, this._load()];
                    case 11: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    DeliversTableC.prototype._onApprovalChangedHandler = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._isApproversModalOpen = !this._isApproversModalOpen;
                        return [4 /*yield*/, this._load()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    DeliversTableC.prototype.openExternalApprovalAppLink = function (data) {
        window.open(data.externalApprovalAppLink, '_blank');
    };
    DeliversTableC.prototype.openExternalTestPlanAppLink = function (data) {
        window.open(data.externalTestPlanAppLink, '_blank');
    };
    DeliversTableC.prototype.renderStatus = function (status) {
        if (status === StatusEnum.NotApplicable)
            return SeparatedStrings.StatusNotApplicable;
        return status;
    };
    DeliversTableC.prototype.renderInfoButtonsColumn = function (data) {
        var _this = this;
        var _a, _b, _c;
        var _d = this.props, onDocumentIconClick = _d.onDocumentIconClick, projectState = _d.projectState, projectType = _d.projectType, showRedDotDeliverable = _d.showRedDotDeliverable;
        var isExternalManagement = data.approvalApplication !== ApprovalApplicationEnum.TeamGate;
        return (data.isEnabled && (React.createElement("div", { className: styles.actions },
            React.createElement("div", { className: styles.action }, (projectState === ProjectState.HasOnlyMasterCheckLists ||
                projectType === ProjectType.Milestone ||
                data.checkListItemType === ChecklistItemType.FunctionDeliverable) && (React.createElement(Button, { color: "success", size: "xs", onClick: function () { return _this._showApprovers(data); } }, "A"))),
            React.createElement("div", { className: styles.action }, ((data.checkListItemType === ChecklistItemType.FunctionDeliverable ||
                data.checkListItemType === ChecklistItemType.MasterDeliverableWithoutFunctionDeliverable ||
                data.hasChildren ||
                projectState === ProjectState.HasOnlyMasterCheckLists) &&
                onDocumentIconClick &&
                !isExternalManagement && (React.createElement(DocumentButton, { hasDocument: data.hasFile, onDocumentIconClick: function () {
                    return onDocumentIconClick === null || onDocumentIconClick === void 0 ? void 0 : onDocumentIconClick(data.checkListItemId, ChecklistSections.Function);
                }, hasRedDot: data.hasRedDot && showRedDotDeliverable }))) ||
                (isExternalManagement && (React.createElement(ExternalAppButton, { isWhite: data.currentStatus === StatusEnum.InApproval ||
                        data.currentStatus === StatusEnum.Approved ||
                        (data.stateAtGateAssessment === StatusEnum.Draft &&
                            data.hasComment &&
                            data.hasRating), disabled: !data.isExternallyConfigured, onButtonClick: function () {
                        if (data.approvalApplication !== ApprovalApplicationEnum.TMS) {
                            _this.openExternalApprovalAppLink(data);
                        }
                        else {
                            _this.openExternalTestPlanAppLink(data);
                        }
                    } })))),
            React.createElement("div", { className: styles.action }, ((_a = data.fileInformation) === null || _a === void 0 ? void 0 : _a.fileId) && (React.createElement(DownloadButton, { fileId: data.fileInformation.fileId, hasRedDot: data.hasRedDot && showRedDotDeliverable }))),
            React.createElement("div", { className: styles.action }, ((_b = data.fileInformation) === null || _b === void 0 ? void 0 : _b.fileId) && (React.createElement(CopyToClipBoardButton, { text: location.origin + ComponentUrls.DocumentUrl + ((_c = data.fileInformation) === null || _c === void 0 ? void 0 : _c.fileId), copyText: "Copy file link to clipboard" }))))));
    };
    DeliversTableC.prototype.renderApproveDenyButtons = function (data) {
        var _this = this;
        var isProjectTerminatedOrCompleted = this.props.isProjectTerminatedOrCompleted;
        return (data.canPrincipalImmediateApproveDeliverable &&
            !data.isApprovalDisabled && (React.createElement(ApproveDenyDeliverableButtons, { deliverable: data, deliverableApproverId: data.canPrincipalImmediateApproveDeliverable ? null : data.approvalId || null, canApproveWithoutApprover: data.canPrincipalImmediateApproveDeliverable ||
                (data.canPrincipalApproveDeliverable &&
                    data.checkListItemType === ChecklistItemType.MasterDeliverableWithoutFunctionDeliverable), isApproveDisabled: this._isApproversModalOpen || this._loading, onDenyHandler: function () {
                _this._toggleCommentModalOpen();
                _this._denyItem = data;
                _this._denyApprovalId = data.approvalId;
            }, onApprovedHandler: function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this._load()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); }, disabled: isProjectTerminatedOrCompleted })));
    };
    DeliversTableC.prototype.renderApproveDenyExternalRedirectButtons = function (data) {
        var _this = this;
        var isProjectTerminatedOrCompleted = this.props.isProjectTerminatedOrCompleted;
        return (React.createElement("div", { className: "".concat(styles.actions) },
            React.createElement(Button, { className: "".concat(styles.action), size: "sm", color: "success", onClick: function () { return _this.openExternalApprovalAppLink(data); }, disabled: this._isApproversModalOpen || this._loading || isProjectTerminatedOrCompleted }, "Approve..."),
            React.createElement(Button, { className: "".concat(styles.action), size: "sm", onClick: function () { return _this.openExternalApprovalAppLink(data); }, isLoading: this._loading, disabled: isProjectTerminatedOrCompleted }, "Deny...")));
    };
    DeliversTableC.prototype.renderApprovalButtonsColumn = function (data) {
        var _this = this;
        var _a = this.props, projectState = _a.projectState, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted;
        var canSetToDraftDeliverable = data.canPrincipalSetToDraftDeliverable;
        var isExternalManagement = data.approvalApplication !== ApprovalApplicationEnum.TeamGate;
        return (!data.isApprovalDisabled &&
            data.canEdit && (React.createElement("div", { className: styles.actions },
            data.currentStatus == StatusEnum.Draft &&
                !data.doNotNeedApproval &&
                (projectState === ProjectState.HasOnlyMasterCheckLists
                    ? true
                    : data.checkListItemType === ChecklistItemType.FunctionDeliverable ||
                        data.checkListItemType ===
                            ChecklistItemType.MasterDeliverableWithoutFunctionDeliverable) && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.validationHint },
                    React.createElement(ValidationHint, { id: 'del' + data.id, isValid: data.canPrincipalSetInApprovalDeliverable, errors: data.validationErrors || [] })),
                isExternalManagement && (React.createElement(Button, { className: styles.action, size: "sm", color: "success", disabled: this._loading ||
                        !(isExternalManagement && data.isExternallyConfigured) ||
                        isProjectTerminatedOrCompleted, onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            return [2 /*return*/, this.openExternalApprovalAppLink(data)];
                        });
                    }); } },
                    data.approvalApplication === ApprovalApplicationEnum.MFiles
                        ? 'Processing in '
                        : 'Approval in ',
                    renderApprovalApplication(data.approvalApplication),
                    "...")),
                !isExternalManagement && (React.createElement(Button, { className: styles.action, size: "sm", color: "success", disabled: !data.canPrincipalSetInApprovalDeliverable ||
                        this._loading ||
                        isProjectTerminatedOrCompleted, onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            return [2 /*return*/, this._handlePrepare(data)];
                        });
                    }); } }, "Ready for Approval")),
                this._showInApprovalWarning && (React.createElement(InApprovalLinkedDeliverableWarning, { onDismissWarning: function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this._onDismissWarning(data)];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); }, onToggleWarning: this._onToggleWarning })))),
            canSetToDraftDeliverable && (React.createElement(Button, { className: styles.action, size: "sm", color: "success", onClick: function () {
                    isExternalManagement
                        ? _this.openExternalApprovalAppLink(data)
                        : _this._handleUndoApprovalClick(data);
                }, disabled: this._loading || isProjectTerminatedOrCompleted }, "Undo Approval".concat(isExternalManagement ? '...' : ''))),
            !this._isApproversModalOpen &&
                !this._loading &&
                !isExternalManagement &&
                this.renderApproveDenyButtons(data) &&
                isExternalManagement &&
                this.renderApproveDenyExternalRedirectButtons(data))));
    };
    DeliversTableC.prototype.render = function () {
        var _this = this;
        var _a = this.props, onDocumentIconClick = _a.onDocumentIconClick, projectState = _a.projectState, projectType = _a.projectType, match = _a.match, hideNAItems = _a.hideNAItems, showRedDotDeliverable = _a.showRedDotDeliverable, projectId = _a.projectId, gateId = _a.gateId, isFilesChanged = _a.isFilesChanged, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted;
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid, { dataSource: this._delivers, expandableRows: true, rowsCount: this._delivers.length, loading: this._loading },
                React.createElement(Column, { caption: "CLI Code", dataField: "checkListItemCode", width: 120, cssClass: columnSharedStyles.colWithHistory, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(CodeCell, { code: data.checkListItemCode, onHistoryClick: function () { return (_this._currentDeliverable = data); }, to: "".concat(match.url, "/log") }));
                    } }),
                React.createElement(Column, { caption: "Deliverable Name", dataField: "deliverableName", width: 200 }),
                React.createElement(Column, { width: 50, alignment: "center", cellRender: function (_a) {
                        var data = _a.data;
                        return data.stateAtGateAssessment &&
                            data.currentStatus && (React.createElement(DocumentStatusIcon, { targetStatus: data.stateAtGateAssessment, isFileUploaded: data.hasFile, isCommented: data.hasComment, isRated: data.hasRating, currentStatus: data.currentStatus, deliverableId: data.id, isExternalManagement: data.approvalApplication !== ApprovalApplicationEnum.TeamGate, isNotApplicable: data.isApprovalDisabled || data.isNotApplicableAfterApproval, rating: data.rating, onClick: function () { return _this._showChildDeliverables(data); } }));
                    } }),
                React.createElement(Column, { caption: "Current Status", dataField: "currentStatus", width: 110, alignment: "center", cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("p", null, _this.renderStatus(data.currentStatus)));
                    } }),
                React.createElement(Column, { caption: "Target Status", dataField: "stateAtGateAssessment", width: 110, alignment: "center" }),
                React.createElement(Column, { caption: "Target Date", dataField: "targetDeliverableDate", dataType: "date", width: 100, cellRender: function (_a) {
                        var data = _a.data;
                        return data.targetDeliverableDate && React.createElement(DateBox, { date: data.targetDeliverableDate });
                    } }),
                projectType !== ProjectType.Milestone && (React.createElement(Column, { caption: "Function Comment (Read only)", dataField: "functionalComment", minWidth: 210, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(React.Fragment, null,
                            data.approvalApplication !== ApprovalApplicationEnum.TeamGate && (React.createElement("div", { className: styles.isExternalManagementText },
                                "(Comment and Rating",
                                ' ',
                                data.approvalApplication === ApprovalApplicationEnum.MFiles
                                    ? 'are managed by '
                                    : 'editable in ',
                                renderApprovalApplication(data.approvalApplication),
                                ")")),
                            React.createElement("div", { style: { whiteSpace: 'pre-line' } }, HTMLReactParser(data.functionalComment || ''))));
                    } })),
                React.createElement(Column, { caption: projectType !== ProjectType.Milestone
                        ? 'GA/PjM Comment (Read only)'
                        : 'Comment (Read only)', dataField: "projectManagerComment", minWidth: 210, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("div", null, HTMLReactParser(data.projectManagerComment || '')));
                    } }),
                React.createElement(Column, { caption: "Comments on Deny (Read only)", dataField: "comment", minWidth: 210, cssClass: styles.approvalCommentCommentCell, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("div", null, HTMLReactParser(data.comment || '')));
                    } }),
                React.createElement(Column, { minWidth: 145, cellRender: function (_a) {
                        var data = _a.data;
                        return _this.renderInfoButtonsColumn(data);
                    } }),
                React.createElement(Column, { minWidth: 200, cellRender: function (_a) {
                        var data = _a.data;
                        return _this.renderApprovalButtonsColumn(data);
                    } })),
            React.createElement(ApproversModal, { isOpen: this._isApproversModalOpen, toggle: this._toggleApproversModal, onApprovalChanged: this._onApprovalChangedHandler, deliverable: this._currentDeliverable, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted }),
            React.createElement(CommentModal, { isOpen: this._isUnapproveDeliverableModalOpen, onToggle: this._toggleUnapproveDeliverableModalOpen, onSave: this._handleUndoApproval }),
            React.createElement(CommentModal, { isOpen: this._isCommentModalOpen, onToggle: this._toggleCommentModalOpen, onSave: this._handleDeny }),
            this._currentMasterDeliverable && (React.createElement(DocumentStatusModal, { isOpen: this._isDocumentStatusModalOpen, toggle: this._toggleDocumentStatusModalOpen, checklistItemId: this._currentMasterDeliverable.checkListItemId, checklistItemName: this._currentMasterDeliverable.checkListItemCode, projectId: projectId, gateId: gateId, projectState: projectState, projectType: projectType, hideNAItems: hideNAItems, showRedDotDeliverable: showRedDotDeliverable, onDocumentIconClick: onDocumentIconClick, isFilesChanged: isFilesChanged, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })),
            this._showInApprovalRedDotWarning && (React.createElement(InApprovalRedDotDeliverableWarning, { onDismissWarning: function () { return __awaiter(_this, void 0, void 0, function () {
                    var _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _a = this._currentWarningDeliverable;
                                if (!_a) return [3 /*break*/, 2];
                                return [4 /*yield*/, this._onDismissRedDotWarning(this._currentWarningDeliverable)];
                            case 1:
                                _a = (_b.sent());
                                _b.label = 2;
                            case 2:
                                _a;
                                return [2 /*return*/];
                        }
                    });
                }); }, onToggleWarning: this._onToggleRedDotWarning })),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "".concat(match.url, "/log") }, this._currentDeliverable && (React.createElement(ChangeLogDialog, { onToggle: this._toggleLogModal, uniqueId: createUniqueId(ObjectType.ChecklistItemDeliverable, this._currentDeliverable.id) }))))));
    };
    __decorate([
        observable
    ], DeliversTableC.prototype, "_delivers", void 0);
    __decorate([
        observable
    ], DeliversTableC.prototype, "_isApproversModalOpen", void 0);
    __decorate([
        observable
    ], DeliversTableC.prototype, "_isDocumentStatusModalOpen", void 0);
    __decorate([
        observable
    ], DeliversTableC.prototype, "_isCommentModalOpen", void 0);
    __decorate([
        observable
    ], DeliversTableC.prototype, "_isUnapproveDeliverableModalOpen", void 0);
    __decorate([
        observable
    ], DeliversTableC.prototype, "_currentDeliverable", void 0);
    __decorate([
        observable
    ], DeliversTableC.prototype, "_currentWarningDeliverable", void 0);
    __decorate([
        observable
    ], DeliversTableC.prototype, "_currentMasterDeliverable", void 0);
    __decorate([
        observable
    ], DeliversTableC.prototype, "_denyItem", void 0);
    __decorate([
        observable
    ], DeliversTableC.prototype, "_denyApprovalId", void 0);
    __decorate([
        observable
    ], DeliversTableC.prototype, "_unapprovedDeliverable", void 0);
    __decorate([
        observable
    ], DeliversTableC.prototype, "_showInApprovalWarning", void 0);
    __decorate([
        observable
    ], DeliversTableC.prototype, "_showInApprovalRedDotWarning", void 0);
    __decorate([
        observable
    ], DeliversTableC.prototype, "_dismissWarning", void 0);
    __decorate([
        observable
    ], DeliversTableC.prototype, "_dismissRedDotWarning", void 0);
    __decorate([
        observable
    ], DeliversTableC.prototype, "_loading", void 0);
    __decorate([
        action.bound
    ], DeliversTableC.prototype, "_load", null);
    __decorate([
        action.bound
    ], DeliversTableC.prototype, "_toggleApproversModal", null);
    __decorate([
        action.bound
    ], DeliversTableC.prototype, "_toggleCommentModalOpen", null);
    __decorate([
        action.bound
    ], DeliversTableC.prototype, "_toggleDocumentStatusModalOpen", null);
    __decorate([
        action.bound
    ], DeliversTableC.prototype, "_toggleUnapproveDeliverableModalOpen", null);
    __decorate([
        action.bound
    ], DeliversTableC.prototype, "_toggleLogModal", null);
    __decorate([
        action.bound
    ], DeliversTableC.prototype, "_handleDeny", null);
    __decorate([
        action.bound
    ], DeliversTableC.prototype, "_showApprovers", null);
    __decorate([
        action.bound
    ], DeliversTableC.prototype, "_showChildDeliverables", null);
    __decorate([
        action.bound
    ], DeliversTableC.prototype, "_handlePrepare", null);
    __decorate([
        action.bound
    ], DeliversTableC.prototype, "_onDismissWarning", null);
    __decorate([
        action.bound
    ], DeliversTableC.prototype, "_onDismissRedDotWarning", null);
    __decorate([
        action.bound
    ], DeliversTableC.prototype, "_onToggleWarning", null);
    __decorate([
        action.bound
    ], DeliversTableC.prototype, "_onToggleRedDotWarning", null);
    __decorate([
        action.bound
    ], DeliversTableC.prototype, "_handleUndoApprovalClick", null);
    __decorate([
        action.bound
    ], DeliversTableC.prototype, "_handleUndoApproval", null);
    __decorate([
        action.bound
    ], DeliversTableC.prototype, "_onApprovalChangedHandler", null);
    DeliversTableC = __decorate([
        observer
    ], DeliversTableC);
    return DeliversTableC;
}(React.PureComponent));
var DeliversTable = withRouter(DeliversTableC);
export default DeliversTable;
