import React from 'react';
import styles from './person.module.scss';
import Avatar from './Avatar/Avatar';
var Person = function (_a) {
    var name = _a.name, secondName = _a.secondName, functionName = _a.functionName;
    return (React.createElement("div", { className: styles.person },
        React.createElement(Avatar, { name: name, secondName: secondName }),
        React.createElement("span", { className: styles.personName },
            name,
            " ",
            secondName,
            " ",
            functionName ? '(' + functionName + ')' : '')));
};
export default Person;
