var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import styles from './ChecklistViewDescription.module.scss';
var ChecklistViewDescription = /** @class */ (function (_super) {
    __extends(ChecklistViewDescription, _super);
    function ChecklistViewDescription() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChecklistViewDescription.prototype.render = function () {
        var checklist = this.props.checklist;
        return (React.createElement("div", { className: styles.line },
            React.createElement("div", { className: styles.item },
                React.createElement("div", { className: styles.title }, "Checklist Code"),
                React.createElement("div", { className: styles.data }, checklist.code)),
            React.createElement("div", { className: styles.item },
                React.createElement("div", { className: styles.title }, "Checklist Description"),
                React.createElement("div", { className: styles.data }, checklist.name)),
            React.createElement("div", { className: styles.item },
                React.createElement("div", { className: styles.title }, "Checklist Project"),
                React.createElement("div", { className: styles.data },
                    checklist.projectCode,
                    "/ ",
                    checklist.projectName))));
    };
    return ChecklistViewDescription;
}(React.PureComponent));
export { ChecklistViewDescription };
