var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { AddGates } from './AddGates/AddGates';
import { JoinGates } from './JoinGates/JoinGates';
import { SplitGates } from './SplitGates/SplitGates';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { GateView } from './GateView/GateView';
import styles from './Gates.module.scss';
import columnSharedStyles from '@app/Components/Grid/SharedStyles/column-shared-styles.module.scss';
import { Api } from '@app/Api';
import { FaTrash } from 'react-icons/fa';
import { Grid } from '@app/Components/Grid/Grid';
import { Column } from 'devextreme-react/data-grid';
import { GateProcessStage, SeparatedStrings, SignalREvents, ObjectType, GateRecommendationDecisionEnum, ProjectType } from '@app/AppConstants';
import { CommentModal } from '@app/Widgets/CommentModal/CommentModal';
import { Link } from '@app/Components/Link/Link';
import { withRouter, Switch, Route } from 'react-router-dom';
import { Button } from '@app/Components/Button/Button';
import { RedoGate } from './RedoGate/RedoGate';
import { signalRService } from '@app/Services/SignalRService';
import { ErrorModal } from './ErrorModal';
import { Modal } from '@app/Components/Modal/Modal';
import { ValidationHint } from '@app/Components/ValidationHint/ValidationHint';
import { ConfirmationDialog } from '@app/Widgets/ConfirmationDialog/ConfirmationDialog';
import { ChangeLogDialog } from '@app/Components/Dialogs/ChangeLogDialog';
import { createUniqueId } from '@app/Helpers/UidHelper/UidHelper';
import { HistoryButton } from '@app/Components/HistoryButton.tsx/HistoryButton';
import HTMLReactParser from 'html-react-parser';
import { Tooltip } from 'devextreme-react';
var GatesC = /** @class */ (function (_super) {
    __extends(GatesC, _super);
    function GatesC() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isGateCommentModalOpen = false;
        _this._isGateRemoveModalOpen = false;
        _this._isGateSetOnHoldModalOpen = false;
        _this._isGateAddModalOpen = false;
        _this.loading = false;
        _this._canRedoGate = false;
        _this._isErrorModalOpen = false;
        _this._errorModalData = {};
        _this._currentGate = null;
        _this._currentGateId = null;
        _this._denyGate = null;
        _this._removeGateId = null;
        _this._removeGateHasMFilesDeliverables = null;
        _this._isOnHoldTooltipVisible = false;
        return _this;
    }
    GatesC.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        signalRService.subscribe(SignalREvents.decisionChanged, this._loadGates);
                        this._findCurrentGate();
                        return [4 /*yield*/, this._loadGates()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    GatesC.prototype.componentWillUnmount = function () {
        signalRService.unsubscribe(SignalREvents.decisionChanged, this._loadGates);
    };
    GatesC.prototype._findCurrentGate = function () {
        var match = /.*\/g-(\d{1,}).*/gi.exec(this.props.location.pathname);
        if (match && match[1]) {
            var parsed = parseInt(match[1]);
            if (this._currentGateId !== parsed) {
                this._currentGateId = parsed;
            }
        }
    };
    GatesC.prototype._toggle = function () {
        this._isErrorModalOpen = !this._isErrorModalOpen;
    };
    GatesC.prototype._loadGates = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var _c, gate;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.loading = true;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, , 3, 4]);
                        _c = this;
                        return [4 /*yield*/, Api.secured.getGatesList({ projectId: this.props.projectId })];
                    case 2:
                        _c._gateList = _d.sent();
                        this._canRedoGate = this.props.canPrincipalRedoGate;
                        this.props.onGatesNumberChanged((_b = (_a = this._gateList) === null || _a === void 0 ? void 0 : _a.gates) === null || _b === void 0 ? void 0 : _b.filter(function (g) { return !g.isRedone; }).length);
                        if (this._currentGateId) {
                            gate = this._gateList.gates.find(function (x) { return x.id === _this._currentGateId; });
                            if (gate) {
                                this._currentGate = gate;
                            }
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        this.loading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    GatesC.prototype._handleGateAdd = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var gateCreateModel;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        gateCreateModel = __assign(__assign({}, params), { projectId: this.props.projectId });
                        return [4 /*yield*/, Api.secured.createGate({ body: gateCreateModel }).then(function (res) {
                                if (res.errorMessages) {
                                    _this._errorModalData = res;
                                    _this._isErrorModalOpen = true;
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadGates()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    GatesC.prototype._handleSkipGate = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var gateCreateModel;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        gateCreateModel = __assign(__assign({}, params), { projectId: this.props.projectId });
                        return [4 /*yield*/, Api.secured.skipGate({ body: gateCreateModel }).then(function (res) {
                                if (res.errorMessages) {
                                    _this._errorModalData = res;
                                    _this._isErrorModalOpen = true;
                                }
                            })];
                    case 1:
                        _a.sent();
                        if (!this.props.onGateStatusChangeHandler) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.props.onGateStatusChangeHandler()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this._loadGates()];
                    case 4: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    GatesC.prototype._handleGateRedo = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var gateRedoModel;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        gateRedoModel = __assign(__assign({}, params), { projectId: this.props.projectId });
                        return [4 /*yield*/, Api.secured.redoGate({ body: gateRedoModel }).then(function (res) {
                                if (res.errorMessages) {
                                    _this._errorModalData = res;
                                    _this._isErrorModalOpen = true;
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadGates()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, this._canRedoGate];
                }
            });
        });
    };
    GatesC.prototype._handleGateSplit = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var gateSplitModel;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        gateSplitModel = __assign({}, params);
                        return [4 /*yield*/, Api.secured.splitGate({ body: gateSplitModel })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadGates()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    GatesC.prototype._handleGateJoin = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadGates()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    GatesC.prototype._handleGateDeleteClick = function (gateId) {
        return __awaiter(this, void 0, void 0, function () {
            var body;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._removeGateId = gateId;
                        return [4 /*yield*/, Api.secured.hasGateMFilesDeliverables({ gateId: gateId })];
                    case 1:
                        body = (_a.sent()).body;
                        this._removeGateHasMFilesDeliverables = body;
                        this._toggleGateRemoveModalOpen();
                        return [2 /*return*/];
                }
            });
        });
    };
    GatesC.prototype._handleGateDelete = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._removeGateId)
                            return [2 /*return*/];
                        return [4 /*yield*/, Api.secured.deleteGate({ gateId: this._removeGateId })];
                    case 1:
                        _a.sent();
                        this._removeGateId = null;
                        this._removeGateHasMFilesDeliverables = null;
                        this._toggleGateRemoveModalOpen();
                        return [4 /*yield*/, this._loadGates()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    GatesC.prototype._handleGateConfigure = function (gate) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, history, match, url;
            return __generator(this, function (_b) {
                _a = this.props, history = _a.history, match = _a.match;
                url = "".concat(match.url, "/g-").concat(gate.id);
                this._setCurrentGate(gate);
                history.push(url);
                return [2 /*return*/];
            });
        });
    };
    GatesC.prototype._handleGateSetOnHoldClick = function (gate) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._setCurrentGate(gate);
                this._toggleGateSetOnHoldModalOpen();
                return [2 /*return*/];
            });
        });
    };
    GatesC.prototype._handleGateSetOnHold = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._currentGate) return [3 /*break*/, 3];
                        return [4 /*yield*/, Api.secured.setGateOnHold({ gateId: this._currentGate.id })];
                    case 1:
                        _a.sent();
                        this._clearCurrentGate();
                        return [4 /*yield*/, this._loadGates()];
                    case 2:
                        _a.sent();
                        this._toggleGateSetOnHoldModalOpen();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    GatesC.prototype._handleGateUndoOnHold = function (gate) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.undoGateOnHold({ gateId: gate.id })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadGates()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    GatesC.prototype._changeStatusOnInApproval = function (gateId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.prepareGate({ gateId: gateId })];
                    case 1:
                        _a.sent();
                        if (!this.props.onGateStatusChangeHandler) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.props.onGateStatusChangeHandler()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this._loadGates()];
                    case 4:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    GatesC.prototype._handleGateApprove = function (gateId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.approveGate({ gateId: gateId })];
                    case 1:
                        _a.sent();
                        if (!this.props.onGateStatusChangeHandler) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.props.onGateStatusChangeHandler()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this._loadGates()];
                    case 4: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    GatesC.prototype._handleGateDeny = function (comment) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._denyGate)
                            return [2 /*return*/];
                        return [4 /*yield*/, Api.secured.denyGate({ gateId: this._denyGate.id, comment: comment })];
                    case 1:
                        _a.sent();
                        this._denyGate = null;
                        if (!this.props.onGateStatusChangeHandler) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.props.onGateStatusChangeHandler()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this._loadGates()];
                    case 4: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    GatesC.prototype._handleGateAddClick = function (data) {
        this._setCurrentGate(data);
        this._toggleGateAddModal();
    };
    GatesC.prototype._toggleGateCommentModalOpen = function () {
        this._isGateCommentModalOpen = !this._isGateCommentModalOpen;
        if (!this._isGateCommentModalOpen)
            this._denyGate = null;
    };
    GatesC.prototype._toggleGateRemoveModalOpen = function () {
        this._isGateRemoveModalOpen = !this._isGateRemoveModalOpen;
    };
    GatesC.prototype._toggleGateSetOnHoldModalOpen = function () {
        this._isGateSetOnHoldModalOpen = !this._isGateSetOnHoldModalOpen;
    };
    GatesC.prototype._handleDenyClick = function (gate) {
        this._toggleGateCommentModalOpen();
        this._denyGate = gate;
    };
    GatesC.prototype._setCurrentGate = function (gate) {
        this._currentGate = gate;
    };
    GatesC.prototype._clearCurrentGate = function () {
        this._currentGate = null;
    };
    GatesC.prototype._toggleModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, history, match;
            return __generator(this, function (_b) {
                _a = this.props, history = _a.history, match = _a.match;
                history.push(match.url);
                this._clearCurrentGate();
                return [2 /*return*/];
            });
        });
    };
    GatesC.prototype._toggleGateAddModal = function () {
        this._isGateAddModalOpen = !this._isGateAddModalOpen;
        if (!this._isGateAddModalOpen) {
            this._clearCurrentGate();
        }
    };
    GatesC.prototype._toggleGateViewModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, history, match;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, history = _a.history, match = _a.match;
                        return [4 /*yield*/, this._loadGates()];
                    case 1:
                        _b.sent();
                        history.push(match.url);
                        this._clearCurrentGate();
                        return [2 /*return*/];
                }
            });
        });
    };
    GatesC.prototype._showOnHoldTooltip = function () {
        this._isOnHoldTooltipVisible = true;
        this.forceUpdate();
    };
    GatesC.prototype._hideOnHoldTooltip = function () {
        this._isOnHoldTooltipVisible = false;
        this.forceUpdate();
    };
    GatesC.prototype.renderProcessStage = function (processStage, skipReason) {
        switch (processStage) {
            case GateProcessStage.PreparationAssessment:
                return SeparatedStrings.GateProcessStagePreparationAssessment;
            case GateProcessStage.OnHold:
                return SeparatedStrings.GateProcessStageOnHold;
        }
        if (skipReason)
            return "".concat(processStage, " ").concat(SeparatedStrings.GateProcessStageSkippedPostfix);
        return processStage;
    };
    GatesC.prototype.renderDecision = function (desision) {
        switch (desision) {
            case GateRecommendationDecisionEnum.Go:
                return desision;
            case GateRecommendationDecisionEnum.NotSpecified:
                return SeparatedStrings.NotSpecified;
            case GateRecommendationDecisionEnum.GoWithActionItem:
                return SeparatedStrings.GoWithActionItem;
            case GateRecommendationDecisionEnum.RedoGate:
                return SeparatedStrings.RedoGate;
            case GateRecommendationDecisionEnum.CloseTerminate:
                return SeparatedStrings.CloseTerminate;
        }
    };
    GatesC.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g;
        var _h = this.props, projectId = _h.projectId, projectCodeName = _h.projectCodeName, projectType = _h.projectType, isNorrdProject = _h.isNorrdProject, match = _h.match, isProjectTerminatedOrCompleted = _h.isProjectTerminatedOrCompleted;
        var isGateApprovePjmOnlyEnabled = (_a = this._gateList) === null || _a === void 0 ? void 0 : _a.isGateApprovePjmOnlyEnabled;
        var isMilestoneProject = projectType === ProjectType.Milestone;
        if (!((_b = this._gateList) === null || _b === void 0 ? void 0 : _b.gates))
            return null;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.wrapper },
                React.createElement("div", { className: styles.table },
                    React.createElement(Grid, { dataSource: this._gateList.gates.filter(function (g) { return g.isDeleted === false; }), expandableRows: true, rowsCount: this._gateList.gates.filter(function (g) { return g.isDeleted === false; }).length, loading: this.loading },
                        React.createElement(Column, { caption: isMilestoneProject ? 'Milestone' : 'Gate', dataField: "displayName", width: 60, cssClass: columnSharedStyles.colWithHistory, cellRender: function (_a) {
                                var data = _a.data;
                                return (React.createElement("div", null,
                                    React.createElement("div", null,
                                        React.createElement(Link, { onClick: function () { return _this._setCurrentGate(data); }, to: "".concat(match.url, "/g-").concat(data.id), className: styles.action }, data.displayName)),
                                    React.createElement(HistoryButton, { onClick: function () { return _this._setCurrentGate(data); }, to: "".concat(match.url, "/log") })));
                            } }),
                        React.createElement(Column, { caption: isMilestoneProject ? 'Next Milestone(s)' : 'Next Gate(s)', dataField: "nextGates", width: 100, alignment: "center" }),
                        React.createElement(Column, { caption: isMilestoneProject ? 'Milestone Description' : 'Gate Description', dataField: "gateDescription", width: 200, cellRender: function (_a) {
                                var data = _a.data;
                                return (React.createElement("div", { style: { wordBreak: 'break-word' } }, HTMLReactParser(data.gateDescription || '')));
                            } }),
                        React.createElement(Column, { caption: isMilestoneProject ? 'Milestone Definition' : 'Gate Definition', dataField: "gateDefinition", width: 300, cellRender: function (_a) {
                                var data = _a.data;
                                return (React.createElement("div", { style: { whiteSpace: 'pre-line' } }, data.gateDefinition));
                            } }),
                        React.createElement(Column, { caption: "Scope", dataField: "scopeLimitation", width: 200 }),
                        React.createElement(Column, { caption: "Setup Status", dataField: "setupStatus", width: 120, alignment: "center" }),
                        React.createElement(Column, { caption: isMilestoneProject ? 'Milestone Status' : 'Gate Status', width: 170, alignment: "center", cellRender: function (_a) {
                                var data = _a.data;
                                return (React.createElement("p", null, _this.renderProcessStage(data.processStage, data.skipReason)));
                            } }),
                        React.createElement(Column, { caption: isMilestoneProject ? 'Milestone Decision' : 'Gate Decision', width: 170, alignment: "center", dataField: "decision", cellRender: function (_a) {
                                var data = _a.data;
                                return (React.createElement("p", null, data.processStage === GateProcessStage.Closed &&
                                    data.decision &&
                                    _this.renderDecision(data.decision.decision)));
                            } }),
                        React.createElement(Column, { caption: isMilestoneProject ? 'Milestone Setup' : 'Gate Setup', minWidth: 700, cellRender: function (_a) {
                                var data = _a.data;
                                return (React.createElement(React.Fragment, null,
                                    React.createElement("div", { className: styles.actions },
                                        React.createElement("div", { className: styles.buttons },
                                            React.createElement("div", { className: styles.trashBtn }, data.canPrincipalRemoveGate && (React.createElement(Button, { className: styles.action, color: "danger", size: "xs", onClick: function () { return _this._handleGateDeleteClick(data.id); }, disabled: isProjectTerminatedOrCompleted },
                                                React.createElement(FaTrash, null)))),
                                            React.createElement("div", { className: styles.gateActionBtn },
                                                data.canPrincipalAddGate && (React.createElement(Button, { className: styles.action, size: "sm", color: "primary", onClick: function () { return _this._handleGateAddClick(data); }, disabled: isProjectTerminatedOrCompleted }, isMilestoneProject ? 'Add MS' : 'Add Gate')),
                                                _this._canRedoGate && data.canRedo && (React.createElement(Link, { to: "".concat(match.url, "/redo") },
                                                    React.createElement(Button, { className: styles.action, size: "sm", color: "primary", onClick: function () { return _this._setCurrentGate(data); }, disabled: isProjectTerminatedOrCompleted }, isMilestoneProject ? 'Redo MS' : 'Redo Gate')))),
                                            React.createElement("div", { className: styles.gateActionBtn }, data.canJoin && (React.createElement(Link, { to: "".concat(match.url, "/join") },
                                                React.createElement(Button, { className: styles.action, size: "sm", color: "primary", onClick: function () { return _this._setCurrentGate(data); }, disabled: isProjectTerminatedOrCompleted }, isMilestoneProject ? 'Join MS' : 'Join Gate')))),
                                            React.createElement("div", { className: styles.gateActionBtn }, data.canPrincipalSplitGate && (React.createElement(Link, { to: "".concat(match.url, "/split") },
                                                React.createElement(Button, { className: styles.action, size: "sm", color: "primary", onClick: function () { return _this._setCurrentGate(data); }, disabled: isProjectTerminatedOrCompleted }, isMilestoneProject ? 'Split MS' : 'Split Gate')))),
                                            React.createElement("div", { className: styles.gateActionBtn }, data.processStage !== GateProcessStage.Closed && (React.createElement(Button, { className: styles.action, size: "sm", color: "primary", onClick: function () { return _this._handleGateConfigure(data); } }, "Configure"))),
                                            (data.canPrincipalSetOnHoldGate ||
                                                data.canPrincipalUndoOnHoldGate) && (React.createElement("div", { className: styles.gateWideActionBtn, onMouseEnter: !data.canPrincipalClickOnHoldGate
                                                    ? _this._showOnHoldTooltip
                                                    : undefined, onMouseLeave: !data.canPrincipalClickOnHoldGate
                                                    ? _this._hideOnHoldTooltip
                                                    : undefined, id: 'onhold' + data.id },
                                                data.canPrincipalSetOnHoldGate && (React.createElement(React.Fragment, null,
                                                    React.createElement(Button, { className: styles.action, size: "sm", color: "primary", onClick: function () { return _this._handleGateSetOnHoldClick(data); }, disabled: isProjectTerminatedOrCompleted ||
                                                            !data.canPrincipalClickOnHoldGate }, "Set on hold"),
                                                    React.createElement(Tooltip, { visible: !data.canPrincipalClickOnHoldGate &&
                                                            _this._isOnHoldTooltipVisible, target: '#onhold' + data.id }, "Button is disabled until approval-related background processes are completed. Button state will be updated after the next refresh of the page."))),
                                                data.canPrincipalUndoOnHoldGate && (React.createElement(Button, { className: styles.action, size: "sm", color: "primary", onClick: function () { return _this._handleGateUndoOnHold(data); }, disabled: isProjectTerminatedOrCompleted }, "Reactivate")))),
                                            data.canPrincipalSetInApprovalGate && (React.createElement(React.Fragment, null,
                                                React.createElement("div", { className: isGateApprovePjmOnlyEnabled
                                                        ? styles.gateActionBtn
                                                        : styles.gateWideActionBtn },
                                                    React.createElement(Button, { className: styles.action, size: "sm", color: "success", disabled: !data.isReadyForApproval ||
                                                            isProjectTerminatedOrCompleted, onClick: function () { return _this._changeStatusOnInApproval(data.id); } }, isGateApprovePjmOnlyEnabled
                                                        ? 'Approve'
                                                        : 'Ready for Approval')))),
                                            data.canPrincipalApproveGate && (React.createElement(React.Fragment, null,
                                                React.createElement("div", { className: styles.gateActionBtn },
                                                    React.createElement(Button, { className: styles.action, size: "sm", onClick: function () { return _this._handleDenyClick(data); }, disabled: isProjectTerminatedOrCompleted }, "Deny")),
                                                React.createElement("div", { className: styles.gateActionBtn },
                                                    React.createElement(Button, { className: styles.action, size: "sm", color: "success", onClick: function () { return _this._handleGateApprove(data.id); }, disabled: isProjectTerminatedOrCompleted }, "Approve")))),
                                            React.createElement("div", null, data.canPrincipalSetInApprovalGate && (React.createElement(ValidationHint, { id: 'gate' + data.id, isValid: data.isReadyForApproval, errors: data.validationErrors })))))));
                            } }))),
                React.createElement(Switch, null,
                    React.createElement(Route, { path: "".concat(match.url, "/redo") },
                        React.createElement(RedoGate, { onToggle: this._toggleModal, onGateRedo: this._handleGateRedo, projectId: projectId, redoGateId: (_c = this._currentGate) === null || _c === void 0 ? void 0 : _c.id, previousGateId: (_d = this._currentGate) === null || _d === void 0 ? void 0 : _d.id, isMilestoneProject: isMilestoneProject })),
                    React.createElement(Route, { path: "".concat(match.url, "/join") },
                        React.createElement(JoinGates, { onGateJoin: this._handleGateJoin, onToggle: this._toggleModal, gateId: (_e = this._currentGate) === null || _e === void 0 ? void 0 : _e.id, isMilestoneProject: isMilestoneProject })),
                    React.createElement(Route, { path: "".concat(match.url, "/split") }, this._currentGate && (React.createElement(SplitGates, { onGateSplit: this._handleGateSplit, onToggle: this._toggleModal, gate: this._currentGate, isMilestoneProject: isMilestoneProject, isNorrdProject: isNorrdProject }))),
                    React.createElement(Route, { path: "".concat(match.url, "/log") }, this._currentGate && (React.createElement(ChangeLogDialog, { onToggle: this._toggleModal, uniqueId: createUniqueId(ObjectType.Gate, this._currentGate.id) })))),
                this._isGateAddModalOpen && (React.createElement(AddGates, { onGateAdd: this._handleGateAdd, onGateSkip: this._handleSkipGate, onToggle: this._toggleGateAddModal, projectId: projectId, gatesCount: this._gateList.gates.filter(function (g) { return !g.isRedone; }).length, previousGateId: (_f = this._currentGate) === null || _f === void 0 ? void 0 : _f.id, isOpen: this._isGateAddModalOpen, projectType: projectType })),
                React.createElement(CommentModal, { isOpen: this._isGateCommentModalOpen, onToggle: this._toggleGateCommentModalOpen, onSave: this._handleGateDeny }),
                React.createElement(ConfirmationDialog, { isOpen: this._isGateRemoveModalOpen, onToggle: this._toggleGateRemoveModalOpen, headerTitle: isMilestoneProject ? 'Remove Milestone' : 'Remove Gate', onSave: this._handleGateDelete, body: isMilestoneProject
                        ? 'Are you sure you want to delete the milestone? The milestone can not be restored after deletion.'
                        : this._removeGateHasMFilesDeliverables
                            ? 'The Gate contains Deliverable(s) managed by M-Files. If the Gate is deleted, integration with M-Files will be lost. Are you sure you want to continue?'
                            : 'Are you sure you want to delete the gate? The gate can not be restored after deletion.' }),
                React.createElement(ConfirmationDialog, { isOpen: this._isGateSetOnHoldModalOpen, onToggle: this._toggleGateSetOnHoldModalOpen, headerTitle: 'Set on hold', onSave: this._handleGateSetOnHold, body: 'Proceed with putting gate on hold?' })),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "".concat(match.url, "/g-:id") }, this._currentGate && (React.createElement(Modal, { isOpen: true, toggle: this._toggleGateViewModal, headerTitle: isMilestoneProject ? 'Milestone Info' : 'Gate Info', maxWidth: "1420px" },
                    React.createElement(GateView, { projectId: projectId, gate: this._currentGate, projectType: projectType, isNorrdProject: isNorrdProject, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })))),
                React.createElement(Route, { path: "".concat(match.url, "/add") },
                    React.createElement(AddGates, { onGateAdd: this._handleGateAdd, onGateSkip: this._handleSkipGate, onToggle: this._toggleModal, projectId: projectId, gatesCount: this._gateList.gates.filter(function (g) { return !g.isRedone; }).length, previousGateId: (_g = this._currentGate) === null || _g === void 0 ? void 0 : _g.id, isOpen: true, projectType: projectType }))),
            React.createElement(ErrorModal, { isOpen: this._isErrorModalOpen, toggle: this._toggle, errorData: this._errorModalData, projectId: projectId, projectCodeName: projectCodeName })));
    };
    __decorate([
        observable
    ], GatesC.prototype, "_gateList", void 0);
    __decorate([
        observable
    ], GatesC.prototype, "_isGateCommentModalOpen", void 0);
    __decorate([
        observable
    ], GatesC.prototype, "_isGateRemoveModalOpen", void 0);
    __decorate([
        observable
    ], GatesC.prototype, "_isGateSetOnHoldModalOpen", void 0);
    __decorate([
        observable
    ], GatesC.prototype, "_isGateAddModalOpen", void 0);
    __decorate([
        observable
    ], GatesC.prototype, "loading", void 0);
    __decorate([
        observable
    ], GatesC.prototype, "_canRedoGate", void 0);
    __decorate([
        observable
    ], GatesC.prototype, "_isErrorModalOpen", void 0);
    __decorate([
        observable
    ], GatesC.prototype, "_errorModalData", void 0);
    __decorate([
        observable
    ], GatesC.prototype, "_currentGate", void 0);
    __decorate([
        observable
    ], GatesC.prototype, "_currentGateId", void 0);
    __decorate([
        observable
    ], GatesC.prototype, "_denyGate", void 0);
    __decorate([
        observable
    ], GatesC.prototype, "_removeGateId", void 0);
    __decorate([
        observable
    ], GatesC.prototype, "_removeGateHasMFilesDeliverables", void 0);
    __decorate([
        observable
    ], GatesC.prototype, "_isOnHoldTooltipVisible", void 0);
    __decorate([
        action.bound
    ], GatesC.prototype, "_toggle", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_loadGates", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_handleGateAdd", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_handleSkipGate", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_handleGateRedo", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_handleGateSplit", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_handleGateJoin", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_handleGateDeleteClick", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_handleGateDelete", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_handleGateConfigure", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_handleGateSetOnHoldClick", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_handleGateSetOnHold", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_handleGateUndoOnHold", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_changeStatusOnInApproval", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_handleGateApprove", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_handleGateDeny", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_handleGateAddClick", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_toggleGateCommentModalOpen", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_toggleGateRemoveModalOpen", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_toggleGateSetOnHoldModalOpen", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_handleDenyClick", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_setCurrentGate", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_clearCurrentGate", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_toggleModal", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_toggleGateAddModal", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_toggleGateViewModal", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_showOnHoldTooltip", null);
    __decorate([
        action.bound
    ], GatesC.prototype, "_hideOnHoldTooltip", null);
    GatesC = __decorate([
        observer
    ], GatesC);
    return GatesC;
}(React.PureComponent));
export var Gates = withRouter(GatesC);
