var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { observer } from 'mobx-react';
import styles from './accordion-tab.module.scss';
import { observable, action } from 'mobx';
import { IoIosArrowForward, IoIosArrowDown } from 'react-icons/io';
export var AccordionTab = /** @class */ (function (_super) {
    __extends(AccordionTab, _super);
    function AccordionTab() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isOpen = _this.props.isOpenedByDefault || false;
        return _this;
    }
    AccordionTab.prototype.render = function () {
        var title = this.props.title;
        return (React.createElement("div", { className: styles.accordionTab },
            React.createElement("div", { className: styles.accordionTabTitle + (this._isOpen ? ' ' + styles.accordionTabOpen : ''), onClick: this._toggleTab },
                React.createElement("span", { style: { marginLeft: '5px', marginRight: '10px' } }, this._isOpen ? React.createElement(IoIosArrowDown, null) : React.createElement(IoIosArrowForward, null)),
                title),
            this._isOpen && React.createElement("div", { className: styles.accordionTabBody }, this.props.children)));
    };
    AccordionTab.prototype._toggleTab = function () {
        this._isOpen = !this._isOpen;
    };
    __decorate([
        observable
    ], AccordionTab.prototype, "_isOpen", void 0);
    __decorate([
        action.bound
    ], AccordionTab.prototype, "_toggleTab", null);
    AccordionTab = __decorate([
        observer
    ], AccordionTab);
    return AccordionTab;
}(React.PureComponent));
