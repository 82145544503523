var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import styles from './ProjectsOverviewGates.module.scss';
import { Link } from '@app/Components/Link/Link';
import { ProjectsOverviewGate } from '../ProjectsOverviewGate/ProjectsOverviewGate';
import { Tooltip } from 'devextreme-react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { DATE_OPTIONS } from '@app/Helpers/DateOptions';
import { GateProcessStage, GateRecommendationDecisionEnum, ProjectType, SeparatedStrings } from '@app/AppConstants';
import { colorThemesStore } from '@app/Stores/ColorThemesStore';
export var ProjectsOverviewGates = /** @class */ (function (_super) {
    __extends(ProjectsOverviewGates, _super);
    function ProjectsOverviewGates() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._totalLength = 1;
        _this._getGateWidth = function (num) {
            return (num / _this._totalLength) * 100 + '%';
        };
        _this._getGateColor = function (state) {
            var colors = colorThemesStore.appColors;
            var green = colors.green, lightBlue = colors.lightBlue, red = colors.red, gray = colors.gray, white = colors.white, lightGray = colors.lightGray;
            switch (state) {
                case 'NotApplicable':
                    return gray;
                case 'Delayed':
                    return red;
                case 'OnHold':
                    return white;
                case 'OnTime':
                    return green;
                case 'OnGoing':
                    return lightBlue;
                default:
                    return lightGray;
            }
        };
        return _this;
    }
    ProjectsOverviewGates.prototype.componentDidMount = function () {
        var _a;
        this._totalLength = ((_a = this.props.prj.gates) === null || _a === void 0 ? void 0 : _a.reduce(function (a, b) { return a + b.lengthInWeek; }, 0)) || 1;
    };
    ProjectsOverviewGates.prototype.componentDidUpdate = function (prevProps) {
        var _a;
        if (this.props.prj !== prevProps.prj) {
            this._totalLength = ((_a = this.props.prj.gates) === null || _a === void 0 ? void 0 : _a.reduce(function (a, b) { return a + b.lengthInWeek; }, 0)) || 1;
        }
    };
    ProjectsOverviewGates.prototype._generateLink = function (gate) {
        switch (gate.processStage) {
            case 'Setup':
                return "/projects/".concat(this.props.prj.codeName, "/setup/gates/g-").concat(gate.id);
            case 'PreparationAssessment':
            case 'OnHold':
                return "/projects/".concat(this.props.prj.codeName, "/preparations/g-").concat(gate.id);
            case 'Meeting':
            case 'Closed':
                return "/projects/".concat(this.props.prj.codeName, "/gate-meeting/g-").concat(gate.id);
        }
    };
    ProjectsOverviewGates.prototype.renderProcessStage = function (processStage, skipReason) {
        switch (processStage) {
            case GateProcessStage.PreparationAssessment:
                return SeparatedStrings.GateProcessStagePreparationAssessment;
            case GateProcessStage.OnHold:
                return SeparatedStrings.GateProcessStageOnHold;
        }
        if (skipReason)
            return "".concat(processStage, " ").concat(SeparatedStrings.GateProcessStageSkippedPostfix);
        return processStage;
    };
    ProjectsOverviewGates.prototype.render = function () {
        var _this = this;
        var _a;
        var prj = this.props.prj;
        var isMilestoneProject = prj.type === ProjectType.Milestone;
        return (React.createElement("div", { className: styles.gates }, (_a = prj.gates) === null || _a === void 0 ? void 0 : _a.map(function (g) {
            var _a, _b, _c, _d;
            return (React.createElement(Link, { key: g.id, style: { width: _this._getGateWidth(g.lengthInWeek) }, to: _this._generateLink(g), className: "".concat(styles.link, " ").concat(styles.gate) },
                React.createElement("div", { id: "gate".concat(g.id), onMouseEnter: function () { return (_this._currentGate = g.id); }, onMouseLeave: function () { return (_this._currentGate = undefined); } },
                    React.createElement(ProjectsOverviewGate, { width: '100%', color: _this._getGateColor(g.gateScheduleStatus) }, g.displayName)),
                React.createElement(Tooltip, { target: "#gate".concat(g.id), visible: _this._currentGate === g.id },
                    React.createElement("div", { className: styles.gateInfoWrapper },
                        React.createElement("div", { className: styles.tooltipTitle },
                            "Name: ",
                            g.displayName),
                        React.createElement("div", null,
                            isMilestoneProject ? 'Milestone Description:' : 'Gate Description:',
                            ' ',
                            g.gateShortDescription),
                        React.createElement("div", null,
                            "Setup Status: ",
                            g.setupStatus),
                        React.createElement("div", null,
                            isMilestoneProject ? 'Milestone Stage:' : 'Gate Stage:',
                            ' ',
                            _this.renderProcessStage(g.processStage, g.skipReason)),
                        React.createElement("div", null,
                            "Recommendation Status: ",
                            ((_a = g.recommendation) === null || _a === void 0 ? void 0 : _a.status) || 'N/A'),
                        g.recommendation && (React.createElement("div", null,
                            "Recommendation:",
                            ' ',
                            ((_b = g.recommendation) === null || _b === void 0 ? void 0 : _b.decision) || GateRecommendationDecisionEnum.NotSpecified)),
                        g.decision && (React.createElement(React.Fragment, null,
                            React.createElement("div", null,
                                "Decision Status: ",
                                ((_c = g.decision) === null || _c === void 0 ? void 0 : _c.status) || 'N/A'),
                            React.createElement("div", null,
                                "Decision:",
                                ' ',
                                ((_d = g.decision) === null || _d === void 0 ? void 0 : _d.decision) || GateRecommendationDecisionEnum.NotSpecified))),
                        React.createElement("div", null,
                            "Start Date:",
                            ' ',
                            g.startDate ? g.startDate.toLocaleDateString('en-GB', DATE_OPTIONS) : 'N/A'),
                        React.createElement("div", null,
                            "Completion Target Date:",
                            ' ',
                            g.completionTargetDate
                                ? g.completionTargetDate.toLocaleDateString('en-GB', DATE_OPTIONS)
                                : 'N/A'),
                        React.createElement("div", null,
                            isMilestoneProject ? 'Milestone Meeting Date:' : 'Gate Meeting Date:',
                            ' ',
                            g.gateMeetingDate
                                ? g.gateMeetingDate.toLocaleDateString('en-GB', DATE_OPTIONS)
                                : 'N/A'),
                        React.createElement("div", null,
                            "Duration In Weeks: ",
                            g.lengthInWeek)))));
        })));
    };
    __decorate([
        observable
    ], ProjectsOverviewGates.prototype, "_currentGate", void 0);
    __decorate([
        observable
    ], ProjectsOverviewGates.prototype, "_totalLength", void 0);
    __decorate([
        action.bound
    ], ProjectsOverviewGates.prototype, "_generateLink", null);
    ProjectsOverviewGates = __decorate([
        observer
    ], ProjectsOverviewGates);
    return ProjectsOverviewGates;
}(React.PureComponent));
