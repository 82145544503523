var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { TabPane, TabContent, Nav, NavItem, NavLink } from 'reactstrap';
import { Api } from '@app/Api';
import { observer } from 'mobx-react';
import styles from './Deliverable.module.scss';
import { observable, action } from 'mobx';
import { Grid } from '@app/Components/Grid/Grid';
import { Column, Sorting, FilterRow } from 'devextreme-react/data-grid';
import { StatusCell } from './Components/StatusCell';
import { FunctionCell } from './Components/FunctionCell';
import { DocumentCell } from './Components/DocumentCell';
import { ProjectTemplateTypeEnum, StatusEnum, ApprovalTaskTypeEnum } from '@app/AppConstants';
export var TemplateConfigurarion = /** @class */ (function (_super) {
    __extends(TemplateConfigurarion, _super);
    function TemplateConfigurarion() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._templateStatusConfigurations = [];
        _this._deliverableDocumentConfigurations = [];
        _this._approvalDocumentConfigurations = [];
        _this._approvalChecklistConfigurations = [];
        _this._functions = [];
        _this._allDocuments = [];
        _this._activeTab = 0;
        return _this;
    }
    TemplateConfigurarion.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._getDeliverableTemplateStatusConfiguration()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._getDeliverableTemplateApprovalConfiguration()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this._getChecklistTemplateApprovalConfiguration()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this._loadFunctions()];
                    case 4:
                        _a.sent();
                        return [4 /*yield*/, this._loadAllDocuments()];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, this._loadDocumentConfigurations()];
                    case 6:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TemplateConfigurarion.prototype._getDeliverableTemplateStatusConfiguration = function () {
        return __awaiter(this, void 0, void 0, function () {
            var projectTemplateId, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        projectTemplateId = this.props.projectTemplateId;
                        _a = this;
                        return [4 /*yield*/, Api.secured.getDeliverablesTemplateStatusConfiguration({
                                projectTemplateId: projectTemplateId
                            })];
                    case 1:
                        _a._templateStatusConfigurations = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TemplateConfigurarion.prototype._getDeliverableTemplateApprovalConfiguration = function () {
        return __awaiter(this, void 0, void 0, function () {
            var projectTemplateId, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        projectTemplateId = this.props.projectTemplateId;
                        _a = this;
                        return [4 /*yield*/, Api.secured.getDeliverablesTemplateApprovalConfiguration({
                                projectTemplateId: projectTemplateId
                            })];
                    case 1:
                        _a._approvalDocumentConfigurations = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TemplateConfigurarion.prototype._getChecklistTemplateApprovalConfiguration = function () {
        return __awaiter(this, void 0, void 0, function () {
            var projectTemplateId, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        projectTemplateId = this.props.projectTemplateId;
                        _a = this;
                        return [4 /*yield*/, Api.secured.getChecklistsTemplateApprovalConfiguration({
                                projectTemplateId: projectTemplateId
                            })];
                    case 1:
                        _a._approvalChecklistConfigurations = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TemplateConfigurarion.prototype._loadFunctions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var projectTemplateId, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        projectTemplateId = this.props.projectTemplateId;
                        _a = this;
                        return [4 /*yield*/, Api.secured.getProjectTemplateFunctions({ projectTemplateId: projectTemplateId })];
                    case 1:
                        _a._functions = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TemplateConfigurarion.prototype._loadAllDocuments = function () {
        return __awaiter(this, void 0, void 0, function () {
            var projectTemplateId, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        projectTemplateId = this.props.projectTemplateId;
                        _a = this;
                        return [4 /*yield*/, Api.secured.getDocumentDefinitions({
                                projectTemplateId: projectTemplateId
                            })];
                    case 1:
                        _a._allDocuments = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TemplateConfigurarion.prototype._loadDocumentConfigurations = function () {
        return __awaiter(this, void 0, void 0, function () {
            var projectTemplateId, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        projectTemplateId = this.props.projectTemplateId;
                        _a = this;
                        return [4 /*yield*/, Api.secured.getDeliverablesDocumentConfiguration({
                                projectTemplateId: projectTemplateId
                            })];
                    case 1:
                        _a._deliverableDocumentConfigurations = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TemplateConfigurarion.prototype._onTabClick = function (tabId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._activeTab = tabId;
                return [2 /*return*/];
            });
        });
    };
    TemplateConfigurarion.prototype.render = function () {
        var _this = this;
        return (React.createElement(React.Fragment, null,
            React.createElement(Nav, { tabs: true, style: { flexGrow: 1 } },
                React.createElement(NavItem, null,
                    React.createElement(NavLink, { className: this._activeTab === 0 ? 'active' : '', onClick: function () { return _this._onTabClick(0); } }, "Documents")),
                React.createElement(NavItem, null,
                    React.createElement(NavLink, { className: this._activeTab === 1 ? 'active' : '', onClick: function () { return _this._onTabClick(1); } }, "Statuses")),
                React.createElement(NavItem, null,
                    React.createElement(NavLink, { className: this._activeTab === 2 ? 'active' : '', onClick: function () { return _this._onTabClick(2); } }, "Deliverables Approval")),
                this.props.projectTemplateType == ProjectTemplateTypeEnum.Functional && (React.createElement(NavItem, null,
                    React.createElement(NavLink, { className: this._activeTab === 3 ? 'active' : '', onClick: function () { return _this._onTabClick(3); } }, "Checklists Approval")))),
            React.createElement(TabContent, { activeTab: this._activeTab },
                React.createElement(TabPane, { tabId: 0 },
                    React.createElement("div", { className: styles.content }, this._activeTab === 0 && (React.createElement(Grid, { dataSource: this._deliverableDocumentConfigurations, 
                        // loading={}
                        rowAlternationEnabled: true },
                        React.createElement(FilterRow, { visible: true }),
                        React.createElement(Column, { caption: "Document Identifier", dataField: "uniqueDocumentId", width: 200 }),
                        React.createElement(Column, { caption: "Project Document", dataField: "name", cellRender: function (_a) {
                                var data = _a.data;
                                return (React.createElement(DocumentCell, { allDocuments: _this._allDocuments, item: data, onSave: function () { return _this._loadDocumentConfigurations(); } }));
                            } }),
                        React.createElement(Column, { caption: "Deliverable IDs", dataField: "deliverableCodes", width: 300 }),
                        React.createElement(Column, { caption: "Function", dataField: "functionCode", width: 200 }))))),
                React.createElement(TabPane, { tabId: 1 },
                    React.createElement("div", { className: styles.content }, this._activeTab === 1 && (React.createElement(Grid, { dataSource: this._templateStatusConfigurations, rowAlternationEnabled: true },
                        React.createElement(Sorting, { mode: "multiple" }),
                        React.createElement(Column, { caption: "Document Identifier", dataField: "documentIdentifier", minWidth: 120 }),
                        React.createElement(Column, { caption: "Project Document", dataField: "deliverableName", minWidth: 200 }),
                        React.createElement(Column, { caption: "Function", dataField: "functionCode", width: 70 }),
                        React.createElement(Column, { caption: "G0 " },
                            React.createElement(Column, { caption: "Entry", minWidth: 50, cellRender: function (_a) {
                                    var data = _a.data;
                                    return _this._renderSVStatusCell(data, 0);
                                } }),
                            React.createElement(Column, { cssClass: styles.exitStatusColumn, caption: "Exit", minWidth: 50, cellRender: function (_a) {
                                    var data = _a.data;
                                    return _this._renderSVStatusCell(data, 0, false);
                                } })),
                        React.createElement(Column, { caption: "G1" },
                            React.createElement(Column, { caption: "Entry", minWidth: 50, cellRender: function (_a) {
                                    var data = _a.data;
                                    return _this._renderSVStatusCell(data, 1);
                                } }),
                            React.createElement(Column, { cssClass: styles.exitStatusColumn, caption: "Exit", minWidth: 50, cellRender: function (_a) {
                                    var data = _a.data;
                                    return _this._renderSVStatusCell(data, 1, false);
                                } })),
                        React.createElement(Column, { caption: "G2" },
                            React.createElement(Column, { caption: "Entry", minWidth: 50, cellRender: function (_a) {
                                    var data = _a.data;
                                    return _this._renderSVStatusCell(data, 2);
                                } }),
                            React.createElement(Column, { cssClass: styles.exitStatusColumn, caption: "Exit", minWidth: 50, cellRender: function (_a) {
                                    var data = _a.data;
                                    return _this._renderSVStatusCell(data, 2, false);
                                } })),
                        React.createElement(Column, { caption: "G3" },
                            React.createElement(Column, { caption: "Entry", minWidth: 50, cellRender: function (_a) {
                                    var data = _a.data;
                                    return _this._renderSVStatusCell(data, 3);
                                } }),
                            React.createElement(Column, { cssClass: styles.exitStatusColumn, caption: "Exit", minWidth: 50, cellRender: function (_a) {
                                    var data = _a.data;
                                    return _this._renderSVStatusCell(data, 3, false);
                                } })),
                        React.createElement(Column, { caption: "G4" },
                            React.createElement(Column, { caption: "Entry", minWidth: 50, cellRender: function (_a) {
                                    var data = _a.data;
                                    return _this._renderSVStatusCell(data, 4);
                                } }),
                            React.createElement(Column, { cssClass: styles.exitStatusColumn, caption: "Exit", minWidth: 50, cellRender: function (_a) {
                                    var data = _a.data;
                                    return _this._renderSVStatusCell(data, 4, false);
                                } })),
                        React.createElement(Column, { caption: "G5" },
                            React.createElement(Column, { caption: "Entry", minWidth: 50, cellRender: function (_a) {
                                    var data = _a.data;
                                    return _this._renderSVStatusCell(data, 5);
                                } }),
                            React.createElement(Column, { cssClass: styles.exitStatusColumn, caption: "Exit", minWidth: 50, cellRender: function (_a) {
                                    var data = _a.data;
                                    return _this._renderSVStatusCell(data, 5, false);
                                } })),
                        React.createElement(Column, { caption: "G6" },
                            React.createElement(Column, { caption: "Entry", minWidth: 50, cellRender: function (_a) {
                                    var data = _a.data;
                                    return _this._renderSVStatusCell(data, 6);
                                } }),
                            React.createElement(Column, { cssClass: styles.exitStatusColumn, caption: "Exit", minWidth: 50, cellRender: function (_a) {
                                    var data = _a.data;
                                    return _this._renderSVStatusCell(data, 6, false);
                                } })),
                        React.createElement(Column, { caption: "G7" },
                            React.createElement(Column, { caption: "Entry", minWidth: 50, cellRender: function (_a) {
                                    var data = _a.data;
                                    return _this._renderSVStatusCell(data, 7);
                                } }),
                            React.createElement(Column, { cssClass: styles.exitStatusColumn, caption: "Exit", minWidth: 50, cellRender: function (_a) {
                                    var data = _a.data;
                                    return _this._renderSVStatusCell(data, 7, false);
                                } })))))),
                React.createElement(TabPane, { tabId: 2 },
                    React.createElement("div", { className: styles.content }, this._activeTab === 2 && (React.createElement(Grid, { dataSource: this._approvalDocumentConfigurations, rowAlternationEnabled: true },
                        React.createElement(Sorting, { mode: "multiple" }),
                        React.createElement(FilterRow, { visible: true }),
                        React.createElement(Column, { caption: "Gate", dataField: "gateTemplateDisplayName", width: 70 }),
                        React.createElement(Column, { caption: "Function", dataField: "functionCode", width: 70 }),
                        React.createElement(Column, { caption: "ID", dataField: "code", width: 120 }),
                        React.createElement(Column, { caption: "Deliverable name", dataField: "deliverableName", width: 250 }),
                        React.createElement(Column, { caption: "-13", cssClass: styles.gridColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderDeliverableFunctionCell(data, -13);
                            } }),
                        React.createElement(Column, { caption: "-12", cssClass: styles.gridColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderDeliverableFunctionCell(data, -12);
                            } }),
                        React.createElement(Column, { caption: "-11", cssClass: styles.gridColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderDeliverableFunctionCell(data, -11);
                            } }),
                        React.createElement(Column, { caption: "-10", cssClass: styles.gridColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderDeliverableFunctionCell(data, -10);
                            } }),
                        React.createElement(Column, { caption: "-9", cssClass: styles.gridColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderDeliverableFunctionCell(data, -9);
                            } }),
                        React.createElement(Column, { caption: "-8", cssClass: styles.gridColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderDeliverableFunctionCell(data, -8);
                            } }),
                        React.createElement(Column, { caption: "-7", cssClass: styles.gridColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderDeliverableFunctionCell(data, -7);
                            } }),
                        React.createElement(Column, { caption: "-6", cssClass: styles.gridColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderDeliverableFunctionCell(data, -6);
                            } }),
                        React.createElement(Column, { caption: "-5", cssClass: styles.gridColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderDeliverableFunctionCell(data, -5);
                            } }),
                        React.createElement(Column, { caption: "-4", cssClass: styles.gridColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderDeliverableFunctionCell(data, -4);
                            } }),
                        React.createElement(Column, { caption: "-3", cssClass: styles.gridColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderDeliverableFunctionCell(data, -3);
                            } }),
                        React.createElement(Column, { caption: "-2", cssClass: styles.gridColumn + ' ' + styles.gatePreparationColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderDeliverableFunctionCell(data, -2);
                            } }),
                        React.createElement(Column, { caption: "-1", cssClass: styles.gridColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderDeliverableFunctionCell(data, -1);
                            } }),
                        React.createElement(Column, { cssClass: styles.gridColumn + ' ' + styles.gateMeetingColumn, caption: "0", minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderDeliverableFunctionCell(data, 0);
                            } }))))),
                this.props.projectTemplateType == ProjectTemplateTypeEnum.Functional && (React.createElement(TabPane, { tabId: 3 },
                    React.createElement("div", { className: styles.content }, this._activeTab === 3 && (React.createElement(Grid, { dataSource: this._approvalChecklistConfigurations, rowAlternationEnabled: true },
                        React.createElement(Sorting, { mode: "multiple" }),
                        React.createElement(Column, { caption: "Gate", dataField: "gateTemplateDisplayName", width: 70 }),
                        React.createElement(Column, { caption: "Function", dataField: "functionCode", width: 70 }),
                        React.createElement(Column, { caption: "ID", dataField: "code", width: 120 }),
                        React.createElement(Column, { caption: "-13", cssClass: styles.gridColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderChecklistFunctionCell(data, -13);
                            } }),
                        React.createElement(Column, { caption: "-12", cssClass: styles.gridColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderChecklistFunctionCell(data, -12);
                            } }),
                        React.createElement(Column, { caption: "-11", cssClass: styles.gridColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderChecklistFunctionCell(data, -11);
                            } }),
                        React.createElement(Column, { caption: "-10", cssClass: styles.gridColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderChecklistFunctionCell(data, -10);
                            } }),
                        React.createElement(Column, { caption: "-9", cssClass: styles.gridColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderChecklistFunctionCell(data, -9);
                            } }),
                        React.createElement(Column, { caption: "-8", cssClass: styles.gridColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderChecklistFunctionCell(data, -8);
                            } }),
                        React.createElement(Column, { caption: "-7", cssClass: styles.gridColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderChecklistFunctionCell(data, -7);
                            } }),
                        React.createElement(Column, { caption: "-6", cssClass: styles.gridColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderChecklistFunctionCell(data, -6);
                            } }),
                        React.createElement(Column, { caption: "-5", cssClass: styles.gridColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderChecklistFunctionCell(data, -5);
                            } }),
                        React.createElement(Column, { caption: "-4", cssClass: styles.gridColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderChecklistFunctionCell(data, -4);
                            } }),
                        React.createElement(Column, { caption: "-3", cssClass: styles.gridColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderChecklistFunctionCell(data, -3);
                            } }),
                        React.createElement(Column, { caption: "-2", cssClass: styles.gridColumn + ' ' + styles.gatePreparationColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderChecklistFunctionCell(data, -2);
                            } }),
                        React.createElement(Column, { caption: "-1", cssClass: styles.gridColumn, minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderChecklistFunctionCell(data, -1);
                            } }),
                        React.createElement(Column, { cssClass: styles.gridColumn + ' ' + styles.gateMeetingColumn, caption: "0", minWidth: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return _this._renderChecklistFunctionCell(data, 0);
                            } })))))))));
    };
    TemplateConfigurarion.prototype._renderDeliverableFunctionCell = function (item, week) {
        return (React.createElement(FunctionCell, { cellInfo: {
                itemId: item.id,
                templateFunctionRange: item.deliverableTemplateFunctionsRange,
                week: week,
                functions: this._functions,
                type: ApprovalTaskTypeEnum.Deliverable
            }, onSave: this._getDeliverableTemplateApprovalConfiguration, create: function (body) {
                return Api.secured.createDeliverableTemplateFunctionsRange({ body: body });
            }, update: function (body) {
                return Api.secured.updateDeliverableTemplateFunctionsRange({ body: body });
            }, delete: function (id) { return Api.secured.deleteDeliverableTemplateFunctionsRange({ body: [id] }); } }));
    };
    TemplateConfigurarion.prototype._renderChecklistFunctionCell = function (item, week) {
        return (React.createElement(FunctionCell, { cellInfo: {
                itemId: item.id,
                templateFunctionRange: item.checklistTemplateFunctionsRange,
                week: week,
                functions: this._functions,
                type: ApprovalTaskTypeEnum.FunctionChecklist
            }, onSave: this._getChecklistTemplateApprovalConfiguration, create: function (body) {
                return Api.secured.createChecklistTemplateFunctionsRange({ body: body });
            }, update: function (body) {
                return Api.secured.updateChecklistTemplateFunctionsRange({ body: body });
            }, delete: function (id) { return Api.secured.deleteChecklistTemplateFunctionsRange({ body: [id] }); } }));
    };
    TemplateConfigurarion.prototype._renderSVStatusCell = function (data, gateNumber, isEntry) {
        var _this = this;
        if (isEntry === void 0) { isEntry = true; }
        var itemIndex = data.statusConfigurations.findIndex(function (x) { return x.gateIndexNumber === gateNumber; });
        if (itemIndex === -1)
            return null;
        var item = data.statusConfigurations[itemIndex];
        var status = isEntry ? item.stateAtGateCreation : item.stateAtGateAssessment;
        var excludedStatuses = [];
        if (item.stateAtGateCreation === StatusEnum.Draft) {
            if (item.stateAtGateAssessment === StatusEnum.Draft && isEntry) {
                excludedStatuses = [StatusEnum.Approved];
            }
        }
        return (React.createElement(StatusCell, { status: status, itemId: item.checkListItemTemplateDeliverableId, itemCode: item.code, onSave: function () { return _this._getDeliverableTemplateStatusConfiguration(); }, onChangeStatusHandler: function (id, status) {
                return Api.secured.changeCLITemplateDeliverableGateAssessmentStatus({ id: id, status: status, isEntry: isEntry });
            }, excludedStatuses: excludedStatuses }));
    };
    __decorate([
        observable
    ], TemplateConfigurarion.prototype, "_templateStatusConfigurations", void 0);
    __decorate([
        observable
    ], TemplateConfigurarion.prototype, "_deliverableDocumentConfigurations", void 0);
    __decorate([
        observable
    ], TemplateConfigurarion.prototype, "_approvalDocumentConfigurations", void 0);
    __decorate([
        observable
    ], TemplateConfigurarion.prototype, "_approvalChecklistConfigurations", void 0);
    __decorate([
        observable
    ], TemplateConfigurarion.prototype, "_functions", void 0);
    __decorate([
        observable
    ], TemplateConfigurarion.prototype, "_allDocuments", void 0);
    __decorate([
        observable
    ], TemplateConfigurarion.prototype, "_activeTab", void 0);
    __decorate([
        action.bound
    ], TemplateConfigurarion.prototype, "_getDeliverableTemplateStatusConfiguration", null);
    __decorate([
        action.bound
    ], TemplateConfigurarion.prototype, "_getDeliverableTemplateApprovalConfiguration", null);
    __decorate([
        action.bound
    ], TemplateConfigurarion.prototype, "_getChecklistTemplateApprovalConfiguration", null);
    __decorate([
        action
    ], TemplateConfigurarion.prototype, "_loadFunctions", null);
    __decorate([
        action
    ], TemplateConfigurarion.prototype, "_loadAllDocuments", null);
    __decorate([
        action
    ], TemplateConfigurarion.prototype, "_loadDocumentConfigurations", null);
    TemplateConfigurarion = __decorate([
        observer
    ], TemplateConfigurarion);
    return TemplateConfigurarion;
}(React.PureComponent));
