var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action } from 'mobx';
var GlobalAjaxLoaderStore = /** @class */ (function () {
    function GlobalAjaxLoaderStore() {
        this._actionElement = null;
        this._actionElementMap = new Map();
        this.appLoaderElement = null;
        this.appLoaderMessageElement = null;
        document.addEventListener('click', this.appClickHandler);
    }
    GlobalAjaxLoaderStore.prototype.hideAppLoader = function () {
        var _a, _b;
        if (!((_a = this.appLoaderElement) === null || _a === void 0 ? void 0 : _a.classList.contains('hide'))) {
            (_b = this.appLoaderElement) === null || _b === void 0 ? void 0 : _b.classList.add('hide');
            if (this.appLoaderMessageElement)
                this.appLoaderMessageElement.innerText = '';
        }
    };
    GlobalAjaxLoaderStore.prototype.showAppLoader = function (message) {
        var _this = this;
        var _a;
        (_a = this.appLoaderElement) === null || _a === void 0 ? void 0 : _a.classList.remove('invisible');
        window.setTimeout(function () {
            var _a;
            (_a = _this.appLoaderElement) === null || _a === void 0 ? void 0 : _a.classList.remove('hide');
            if (message) {
                if (_this.appLoaderMessageElement)
                    _this.appLoaderMessageElement.innerText = message;
            }
        }, 0);
    };
    GlobalAjaxLoaderStore.prototype.showAjaxSpinner = function (timestamp) {
        var elem = this._actionElement;
        if (elem) {
            this._actionElementMap.set(timestamp, elem);
            elem.disabled = true;
            elem.classList.add('btn-loader');
        }
    };
    GlobalAjaxLoaderStore.prototype.hideAjaxSpinner = function (timestamp) {
        var elem = this._actionElementMap.get(timestamp);
        if (elem) {
            elem.disabled = false;
            elem.classList.remove('btn-loader');
            this._actionElementMap.delete(timestamp);
            this._actionElement = null;
        }
    };
    GlobalAjaxLoaderStore.prototype.appClickHandler = function (event) {
        this.setActiveAjaxElement(event.target);
    };
    GlobalAjaxLoaderStore.prototype.setActiveAjaxElement = function (target) {
        var element = target;
        var button = element && element.closest('button');
        if (button && !button.classList.contains('no-loader')) {
            this._actionElement = button;
        }
    };
    __decorate([
        action
    ], GlobalAjaxLoaderStore.prototype, "hideAppLoader", null);
    __decorate([
        action
    ], GlobalAjaxLoaderStore.prototype, "showAppLoader", null);
    __decorate([
        action
    ], GlobalAjaxLoaderStore.prototype, "showAjaxSpinner", null);
    __decorate([
        action
    ], GlobalAjaxLoaderStore.prototype, "hideAjaxSpinner", null);
    __decorate([
        action.bound
    ], GlobalAjaxLoaderStore.prototype, "appClickHandler", null);
    __decorate([
        action
    ], GlobalAjaxLoaderStore.prototype, "setActiveAjaxElement", null);
    return GlobalAjaxLoaderStore;
}());
export var globalAjaxLoaderStore = new GlobalAjaxLoaderStore();
