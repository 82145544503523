var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { observer } from 'mobx-react';
import styles from './attachments.module.scss';
import { observable, action } from 'mobx';
import { AddButton } from '../AddButton/AddButton';
import { MdClose } from 'react-icons/md';
import { DownloadButton } from '@app/Components/DownloadButton/DownloadButton';
export var Attachments = /** @class */ (function (_super) {
    __extends(Attachments, _super);
    function Attachments() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._fileInputRef = React.createRef();
        return _this;
    }
    Attachments.prototype.render = function () {
        var _a;
        var attachments = (_a = this.props, _a.attachments), onFilesChange = _a.onFilesChange, disabled = _a.disabled, hideAddButton = _a.hideAddButton, onRemove = _a.onRemove;
        return (React.createElement("div", { className: styles.attachmentsList },
            !disabled && !hideAddButton && React.createElement(AddButton, { title: "Add Attachment", onClick: this._handleAddClick }),
            !hideAddButton && (React.createElement("input", { style: { display: 'none' }, type: "file", multiple: true, ref: this._fileInputRef, onChange: onFilesChange })),
            attachments.map(function (attachment, i) { return (React.createElement("div", { key: i, className: styles.item },
                React.createElement(DownloadButton, { fileId: attachment.fileId }),
                React.createElement("div", { className: styles.name }, attachment.name),
                !disabled && (React.createElement("div", { className: styles.closeButton },
                    React.createElement(MdClose, { size: "20", onClick: function () { return onRemove === null || onRemove === void 0 ? void 0 : onRemove(attachment); } }))))); })));
    };
    Attachments.prototype._handleAddClick = function () {
        var refInput = this._fileInputRef.current;
        if (refInput) {
            refInput.value = '';
            refInput.click();
        }
    };
    __decorate([
        observable
    ], Attachments.prototype, "_fileInputRef", void 0);
    __decorate([
        action.bound
    ], Attachments.prototype, "_handleAddClick", null);
    Attachments = __decorate([
        observer
    ], Attachments);
    return Attachments;
}(React.PureComponent));
