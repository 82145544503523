var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import styles from './RatingFilterMeeting.module.scss';
import { GateMeetingRolePicker } from '@app/Components/GateMeetingRolePicker/GateMeetingRolePicker';
import { CheckListItemRatingEnum, GateMeetingRoleTypeEnum } from '@app/AppConstants';
import { RatingPicker } from '@app/Components/RatingPicker/RatingPicker';
export var RatingFilterMeeting = /** @class */ (function (_super) {
    __extends(RatingFilterMeeting, _super);
    function RatingFilterMeeting() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RatingFilterMeeting_1 = RatingFilterMeeting;
    RatingFilterMeeting.prototype._onFilterRoleValueChange = function (selectedRole) {
        var _a;
        if (selectedRole === null) {
            RatingFilterMeeting_1.filterValue = null;
            return;
        }
        RatingFilterMeeting_1.filterValue = {
            roleValue: selectedRole,
            ratingValue: ((_a = RatingFilterMeeting_1.filterValue) === null || _a === void 0 ? void 0 : _a.ratingValue) || CheckListItemRatingEnum.Default
        };
    };
    RatingFilterMeeting.prototype._onFilterRatingValueChange = function (selectedRating) {
        if (!RatingFilterMeeting_1.filterValue)
            return;
        RatingFilterMeeting_1.filterValue = {
            roleValue: RatingFilterMeeting_1.filterValue.roleValue,
            ratingValue: selectedRating
        };
    };
    RatingFilterMeeting.prototype.render = function () {
        var _a, _b;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.title }, "Rating Filter:"),
            React.createElement("div", { className: styles.selectContainer },
                React.createElement("div", { className: styles.rolePicker },
                    React.createElement(GateMeetingRolePicker, { defaultRoleOption: ((_a = RatingFilterMeeting_1.filterValue) === null || _a === void 0 ? void 0 : _a.roleValue) || undefined, isClearable: true, onRoleSelect: this._onFilterRoleValueChange })),
                React.createElement("div", { className: styles.ratingPicker },
                    React.createElement(RatingPicker, { rating: ((_b = RatingFilterMeeting_1.filterValue) === null || _b === void 0 ? void 0 : _b.ratingValue) || CheckListItemRatingEnum.Default, onChange: this._onFilterRatingValueChange, disabled: !RatingFilterMeeting_1.filterValue })))));
    };
    var RatingFilterMeeting_1;
    RatingFilterMeeting.filterValue = null;
    __decorate([
        action.bound
    ], RatingFilterMeeting.prototype, "_onFilterRoleValueChange", null);
    __decorate([
        action.bound
    ], RatingFilterMeeting.prototype, "_onFilterRatingValueChange", null);
    __decorate([
        observable
    ], RatingFilterMeeting, "filterValue", void 0);
    RatingFilterMeeting = RatingFilterMeeting_1 = __decorate([
        observer
    ], RatingFilterMeeting);
    return RatingFilterMeeting;
}(React.Component));
export function filterMeetingItemsTableRowsByRoleAndRating(tableRows, selectedFilterValue) {
    if (!selectedFilterValue)
        return tableRows;
    var newRows = tableRows.filter(function (row) {
        if (selectedFilterValue.roleValue === GateMeetingRoleTypeEnum.ProjectManager) {
            return row.projectManagerRating === selectedFilterValue.ratingValue;
        }
        else {
            return row.assessorRating === selectedFilterValue.ratingValue;
        }
    });
    return newRows;
}
