var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Button as RButton } from 'reactstrap';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { colorThemesStore } from '@app/Stores/ColorThemesStore';
import { Loader } from '../Loader/Loader';
export var Button = /** @class */ (function (_super) {
    __extends(Button, _super);
    function Button() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._hovered = false;
        _this._isLoading = _this.props.isLoading;
        return _this;
    }
    Button.prototype.componentDidUpdate = function (prevProps) {
        if (prevProps.isLoading !== this.props.isLoading) {
            this._isLoading = this.props.isLoading || false;
        }
    };
    Button.prototype._getColor = function () {
        var colors = colorThemesStore.appColors;
        switch (this.props.color) {
            case 'primary':
                return !this._hovered ? colors.buttonPrimary : colors.buttonPrimaryHover;
            case 'success':
                return !this._hovered ? colors.buttonSuccess : colors.buttonSuccessHover;
            case 'danger':
                return !this._hovered ? colors.buttonDanger : colors.buttonDangerHover;
            case 'white':
                return 'white';
            default:
                return !this._hovered ? colors.buttonDefault : colors.buttonDefaultHover;
        }
    };
    Button.prototype._getDefaultStyles = function () {
        var size = this.props.size;
        return {
            minWidth: size === 'xs' ? 20 : 90,
            border: '1px solid transparent',
            borderRadius: 2,
            boxShadow: 'none',
            fontSize: 14,
            lineHeight: '20px',
            padding: '5px 10px',
            cursor: this.props.disabled ? 'default' : 'pointer',
            backgroundColor: this._getColor()
        };
    };
    Button.prototype._onClickHandler = function (e) {
        return __awaiter(this, void 0, void 0, function () {
            var onClick;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        onClick = this.props.onClick;
                        this._isLoading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        return [4 /*yield*/, (onClick === null || onClick === void 0 ? void 0 : onClick(e))];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this._isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    Button.prototype.render = function () {
        var _this = this;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        var _a = this.props, isLoading = _a.isLoading, rest = __rest(_a, ["isLoading"]);
        var children = rest.children;
        var newProps = this._isLoading ? __assign(__assign({}, rest), { disabled: true }) : rest;
        return (React.createElement(RButton, __assign({}, newProps, { style: __assign(__assign({}, this._getDefaultStyles()), (this.props.style || {})), onMouseEnter: function () { return (_this._hovered = true); }, onMouseLeave: function () { return (_this._hovered = false); }, onClick: this._onClickHandler }), this._isLoading ? (React.createElement("div", { style: { display: 'flex', alignItems: 'center', justifyContent: 'center' } },
            React.createElement(Loader, { style: { width: 20, height: 20 } }))) : (children)));
    };
    __decorate([
        observable
    ], Button.prototype, "_hovered", void 0);
    __decorate([
        observable
    ], Button.prototype, "_isLoading", void 0);
    __decorate([
        action.bound
    ], Button.prototype, "_onClickHandler", null);
    Button = __decorate([
        observer
    ], Button);
    return Button;
}(React.PureComponent));
