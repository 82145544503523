var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import styles from './comment-summary.module.scss';
import { observer } from 'mobx-react';
import { Comment } from '@app/Components/Grid/CommentsSummary/Comment';
export var CommentsSummary = /** @class */ (function (_super) {
    __extends(CommentsSummary, _super);
    function CommentsSummary() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CommentsSummary.prototype.render = function () {
        var _a;
        var green = (_a = this.props, _a.green), yellow = _a.yellow, red = _a.red, notRated = _a.notRated, notRatedCodes = _a.notRatedCodes, notCommented = _a.notCommented, notCommentedCodes = _a.notCommentedCodes, isVisible = _a.isVisible, notApplicable = _a.notApplicable, cssStyle = _a.cssStyle, id = _a.id, link = _a.link;
        if (!isVisible)
            return null;
        return (React.createElement("div", { className: cssStyle ? cssStyle : styles.commentsSummary },
            React.createElement("div", null,
                green,
                " green,",
                yellow,
                " yellow,",
                red,
                " red,",
                notApplicable,
                " N/A."),
            !!notRated && (React.createElement(Comment, { id: "tooltip_notRatedCode_".concat(id), errorText: "".concat(notRated, " not rated."), codes: notRatedCodes, link: link })),
            !!notCommented && (React.createElement(Comment, { id: "tooltip_notCommentedCode_".concat(id), errorText: "".concat(notCommented, " not commented."), codes: notCommentedCodes, link: link }))));
    };
    CommentsSummary = __decorate([
        observer
    ], CommentsSummary);
    return CommentsSummary;
}(React.PureComponent));
