var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Events, Sizes } from '@app/AppConstants';
import { SimpleToggler } from '@app/Components/ShowNAItemsToggler/SimpleToggler';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './PassedGateYearCustomTitle.module.scss';
export var PassedGateYearCustomTitle = /** @class */ (function (_super) {
    __extends(PassedGateYearCustomTitle, _super);
    function PassedGateYearCustomTitle(props) {
        var _this = _super.call(this, props) || this;
        _this._isLastPassedGateOnlyChecked = false;
        _this._isIncludeSkippedChecked = false;
        _this._isLastPassedGateOnlyChecked = props.isLastPassedGateOnlyDefaultChecked;
        _this._isIncludeSkippedChecked = props.isIncludeSkippedDefaultChecked;
        return _this;
    }
    PassedGateYearCustomTitle.prototype.componentDidMount = function () {
        document.addEventListener(Events.setPassedGateYearTogglerTrue, this._setPassedGateYearTogglerTrue);
        document.addEventListener(Events.setIncludeSkippedTogglerTrue, this._setIncludeSkippedTogglerTrue);
    };
    PassedGateYearCustomTitle.prototype.componentWillUnmount = function () {
        document.removeEventListener(Events.setPassedGateYearTogglerTrue, this._setPassedGateYearTogglerTrue);
        document.removeEventListener(Events.setIncludeSkippedTogglerTrue, this._setIncludeSkippedTogglerTrue);
    };
    PassedGateYearCustomTitle.prototype._setPassedGateYearTogglerTrue = function () {
        this._isLastPassedGateOnlyChecked = true;
    };
    PassedGateYearCustomTitle.prototype._setIncludeSkippedTogglerTrue = function () {
        this._isIncludeSkippedChecked = true;
    };
    PassedGateYearCustomTitle.prototype._onIncludeSkippedToggle = function (checked) {
        this._isIncludeSkippedChecked = checked;
        this.props.onIncludeSkippedToggle(this._isIncludeSkippedChecked);
    };
    PassedGateYearCustomTitle.prototype._onLastPassedGateOnlyToggle = function (checked) {
        this._isLastPassedGateOnlyChecked = checked;
        this.props.onLastPassedGateOnlyToggle(this._isLastPassedGateOnlyChecked);
    };
    PassedGateYearCustomTitle.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: styles.titleWrapper },
            React.createElement("div", { className: styles.text }, "Passed Gate"),
            React.createElement("div", { className: styles.togglerWrapper },
                React.createElement("div", { className: styles.toggler },
                    React.createElement(SimpleToggler, { onToggle: function (checked) { return _this._onIncludeSkippedToggle(checked); }, checked: this._isIncludeSkippedChecked, iconSize: Sizes.IconSizeSmall })),
                React.createElement("div", { className: styles.textSkipped }, "incl. SKIPPED"),
                React.createElement("div", { className: styles.toggler },
                    React.createElement(SimpleToggler, { onToggle: function (checked) { return _this._onLastPassedGateOnlyToggle(checked); }, checked: this._isLastPassedGateOnlyChecked, iconSize: Sizes.IconSizeSmall })),
                React.createElement("div", { className: styles.text }, "Last Passed Gate Only"))));
    };
    __decorate([
        observable
    ], PassedGateYearCustomTitle.prototype, "_isLastPassedGateOnlyChecked", void 0);
    __decorate([
        observable
    ], PassedGateYearCustomTitle.prototype, "_isIncludeSkippedChecked", void 0);
    __decorate([
        action.bound
    ], PassedGateYearCustomTitle.prototype, "_setPassedGateYearTogglerTrue", null);
    __decorate([
        action.bound
    ], PassedGateYearCustomTitle.prototype, "_setIncludeSkippedTogglerTrue", null);
    __decorate([
        action.bound
    ], PassedGateYearCustomTitle.prototype, "_onIncludeSkippedToggle", null);
    __decorate([
        action.bound
    ], PassedGateYearCustomTitle.prototype, "_onLastPassedGateOnlyToggle", null);
    PassedGateYearCustomTitle = __decorate([
        observer
    ], PassedGateYearCustomTitle);
    return PassedGateYearCustomTitle;
}(React.PureComponent));
