var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { convertUTCDateToLocalDate } from '@app/Helpers/DateOptions';
import React from 'react';
var DateTimeBox = /** @class */ (function (_super) {
    __extends(DateTimeBox, _super);
    function DateTimeBox() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
        return _this;
    }
    DateTimeBox.prototype._getMonthName = function (date) {
        return this._monthNames[date.getMonth()];
    };
    DateTimeBox.prototype._getShortMonthName = function (date) {
        return this._getMonthName(date).substr(0, 3);
    };
    DateTimeBox.prototype._getTime = function (date) {
        var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours().toString();
        var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes().toString();
        var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds().toString();
        return "".concat(hours, ":").concat(minutes, ":").concat(seconds);
    };
    DateTimeBox.prototype.render = function () {
        var dateUTC = this.props.dateUTC;
        if (!dateUTC)
            return null;
        var dateLocal = convertUTCDateToLocalDate(dateUTC);
        var dateString = "".concat(dateLocal.getDate().toString(), " ").concat(this._getShortMonthName(dateLocal), " ").concat(dateLocal.getFullYear(), " ").concat(this._getTime(dateLocal));
        return React.createElement("span", null, dateString);
    };
    return DateTimeBox;
}(React.PureComponent));
export { DateTimeBox };
