var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { FaToggleOn, FaToggleOff } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import styles from './ShowNAItemsToggler.module.scss';
import { Sizes } from '@app/AppConstants';
var SimpleToggler = /** @class */ (function (_super) {
    __extends(SimpleToggler, _super);
    function SimpleToggler() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SimpleToggler.prototype.render = function () {
        var _a = this.props, checked = _a.checked, onToggle = _a.onToggle, iconSize = _a.iconSize;
        var size = iconSize !== null && iconSize !== void 0 ? iconSize : Sizes.IconSize;
        return (React.createElement("div", { onClick: function () { return onToggle(!checked); }, className: styles.container },
            React.createElement(IconContext.Provider, { value: { style: { verticalAlign: 'unset', cursor: 'pointer' } } }, checked ? React.createElement(FaToggleOn, { size: size }) : React.createElement(FaToggleOff, { size: size }))));
    };
    return SimpleToggler;
}(React.PureComponent));
export { SimpleToggler };
