var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import styles from './Checklists.module.scss';
import { ChecklistView } from '@app/Widgets/ChecklistView/ChecklistView';
import { Grid } from '@app/Components/Grid/Grid';
import { Column, Scrolling } from 'devextreme-react/data-grid';
import { Api } from '@app/Api';
import { FaPlus, FaTrash, FaEdit } from 'react-icons/fa';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Link } from '@app/Components/Link/Link';
import { Checkbox } from './CheckBox/Checkbox';
import { Modal } from '@app/Components/Modal/Modal';
import { Button } from '@app/Components/Button/Button';
import { SeparatedStrings, StatusEnum } from '@app/AppConstants';
import { CompletionType, PromiseCompletion } from '@app/Classes/PromiseCompletion';
import { ConfirmationDialog } from '@app/Widgets/ConfirmationDialog/ConfirmationDialog';
import { CopyChecklistModal } from './CopyChecklistModal/CopyChecklistModal';
import { EditChecklistModal } from './EditChecklistModal/EditChecklistModal';
var ChecklistsC = /** @class */ (function (_super) {
    __extends(ChecklistsC, _super);
    function ChecklistsC() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._checklists = [];
        _this._loading = false;
        _this._updating = new PromiseCompletion(CompletionType.Pending);
        _this._isDisableConfirmationModalOpen = false;
        _this._isDeleteConfirmationModalOpen = false;
        _this._isCopyChecklistModalOpen = false;
        _this._isEditChecklistModalOpen = false;
        _this._conflictingCheckList = null;
        _this._currentChecklist = null;
        return _this;
    }
    ChecklistsC.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadChecklists()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ChecklistsC.prototype._loadChecklists = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this._loading = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, , 3, 4]);
                        _a = this;
                        return [4 /*yield*/, Api.secured.getAllCheckLists({
                                projectId: this.props.projectId,
                                gateId: this.props.gateId
                            })];
                    case 2:
                        _a._checklists = _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this._loading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ChecklistsC.prototype.save = function (row) {
        return __awaiter(this, void 0, void 0, function () {
            var promise;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        promise = Api.secured.updateChecklist({ body: row });
                        this._updating.subscribe(promise);
                        return [4 /*yield*/, promise];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadChecklists()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ChecklistsC.prototype._handleChange = function (row, isChecked) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _error_1, error;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        row.isEnabled = isChecked;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.save(row)];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        _error_1 = _b.sent();
                        error = _error_1;
                        if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) == 409) {
                            this._conflictingCheckList = row;
                            this._toggleDisableConfirmationDialogModalOpen();
                        }
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ChecklistsC.prototype._onConflictResolve = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._conflictingCheckList) return [3 /*break*/, 2];
                        this._conflictingCheckList.overrideAllowed = true;
                        return [4 /*yield*/, this.save(this._conflictingCheckList)];
                    case 1:
                        _a.sent();
                        this._toggleDisableConfirmationDialogModalOpen();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ChecklistsC.prototype._toggleChecklistsModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, history, match;
            return __generator(this, function (_b) {
                _a = this.props, history = _a.history, match = _a.match;
                history.push(match.url);
                return [2 /*return*/];
            });
        });
    };
    ChecklistsC.prototype._toggleDisableConfirmationDialogModalOpen = function (isCancel) {
        var _this = this;
        this._isDisableConfirmationModalOpen = !this._isDisableConfirmationModalOpen;
        if (isCancel) {
            this._checklists = this._checklists.map(function (checklist) {
                var _a;
                if (checklist.id === ((_a = _this._conflictingCheckList) === null || _a === void 0 ? void 0 : _a.id)) {
                    return __assign(__assign({}, checklist), { isEnabled: !checklist.isEnabled });
                }
                return __assign({}, checklist);
            });
        }
    };
    ChecklistsC.prototype._toggleDeleteConfirmationDialogModalOpen = function () {
        this._isDeleteConfirmationModalOpen = !this._isDeleteConfirmationModalOpen;
    };
    ChecklistsC.prototype._handleCopyClick = function (checklist) {
        this._currentChecklist = checklist;
        this._isCopyChecklistModalOpen = true;
    };
    ChecklistsC.prototype._handleChecklistCopy = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._currentChecklist = null;
                        return [4 /*yield*/, this._loadChecklists()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ChecklistsC.prototype._toggleCopyChecklistModal = function () {
        this._isCopyChecklistModalOpen = !this._isCopyChecklistModalOpen;
    };
    ChecklistsC.prototype._handleDeleteClick = function (checklist) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._currentChecklist = checklist;
                this._isDeleteConfirmationModalOpen = true;
                return [2 /*return*/];
            });
        });
    };
    ChecklistsC.prototype._handleChecklistDelete = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._currentChecklist) return [3 /*break*/, 2];
                        return [4 /*yield*/, Api.secured.deleteChecklist({ checklistId: this._currentChecklist.id })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this._isDeleteConfirmationModalOpen = false;
                        this._currentChecklist = null;
                        return [4 /*yield*/, this._loadChecklists()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ChecklistsC.prototype._handleEditClick = function (checklist) {
        this._currentChecklist = checklist;
        this._isEditChecklistModalOpen = true;
    };
    ChecklistsC.prototype._handleChecklistEdit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._currentChecklist = null;
                        return [4 /*yield*/, this._loadChecklists()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ChecklistsC.prototype._toggleEditChecklistModal = function () {
        this._isEditChecklistModalOpen = !this._isEditChecklistModalOpen;
    };
    ChecklistsC.prototype.renderStatus = function (status) {
        if (status === StatusEnum.NotApplicable)
            return SeparatedStrings.StatusNotApplicable;
        return status;
    };
    ChecklistsC.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        var _g = this.props, match = _g.match, editable = _g.editable, gateId = _g.gateId;
        var isActionsColumnVisiable = !!gateId;
        return (React.createElement("div", null,
            React.createElement(Grid, { dataSource: this._checklists, loading: this._loading || this._updating.isPending },
                React.createElement(Scrolling, { mode: "infinite" }),
                isActionsColumnVisiable && (React.createElement(Column, { alignment: "center", caption: "Enabled", width: 70, cellRender: function (_a) {
                        var data = _a.data;
                        return data.functionCode && (React.createElement(Checkbox, { disabled: !editable, defaultChecked: data.isEnabled, onChange: function (isChecked) { return _this._handleChange(data, isChecked); } }));
                    } })),
                React.createElement(Column, { caption: "Code", dataField: "code", width: 140, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(Link, { to: "".concat(match.url, "/cl-").concat(data.id) }, data.code));
                    } }),
                React.createElement(Column, { caption: "Org Unit", dataField: "orgUnitCodeName", width: 100, alignment: "center" }),
                React.createElement(Column, { caption: "Gate", dataField: "gateDisplayName", width: 50, alignment: "center" }),
                React.createElement(Column, { caption: "Name", dataField: "name", width: 200 }),
                React.createElement(Column, { caption: "Function Short", dataField: "functionCode", width: 120, alignment: "center" }),
                React.createElement(Column, { caption: "Function Full", dataField: "functionName", width: 150, alignment: "center" }),
                React.createElement(Column, { caption: "Status", width: 150, alignment: "center", cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("p", null, _this.renderStatus(data.status)));
                    } }),
                React.createElement(Column, { caption: "From Template", dataField: "fromTemplate", width: 150, alignment: "center", calculateCellValue: function (value) { return (value ? 'Yes' : 'No'); } }),
                isActionsColumnVisiable && editable && (React.createElement(Column, { caption: "Actions", width: 150, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("div", { className: styles.actions },
                            data.canPrincipalCopyChecklist && (React.createElement("div", { className: styles.button },
                                React.createElement(Button, { color: "success", size: "xs", onClick: function () { return _this._handleCopyClick(data); } },
                                    React.createElement(FaPlus, null)))),
                            data.canPrincipalRemoveChecklist && (React.createElement("div", { className: styles.button },
                                React.createElement(Button, { color: "danger", size: "xs", onClick: function () { return _this._handleDeleteClick(data); } },
                                    React.createElement(FaTrash, null)))),
                            data.canPrincipalEditChecklist && (React.createElement("div", { className: styles.button },
                                React.createElement(Button, { color: "secondary", size: "xs", onClick: function () { return _this._handleEditClick(data); } },
                                    React.createElement(FaEdit, null))))));
                    } }))),
            React.createElement(CopyChecklistModal, { isOpen: this._isCopyChecklistModalOpen, onToggle: this._toggleCopyChecklistModal, onChecklistCopy: this._handleChecklistCopy, checklistId: (_a = this._currentChecklist) === null || _a === void 0 ? void 0 : _a.id, checklistCode: (_b = this._currentChecklist) === null || _b === void 0 ? void 0 : _b.code }),
            React.createElement(EditChecklistModal, { isOpen: this._isEditChecklistModalOpen, onToggle: this._toggleEditChecklistModal, onChecklistEdit: this._handleChecklistEdit, checklistId: (_c = this._currentChecklist) === null || _c === void 0 ? void 0 : _c.id, checklistCodeName: (_d = this._currentChecklist) === null || _d === void 0 ? void 0 : _d.code, postFix: (_e = this._currentChecklist) === null || _e === void 0 ? void 0 : _e.checkListPostFix }),
            React.createElement(ConfirmationDialog, { headerTitle: "Warning", body: "Remove Checklist ".concat((_f = this._currentChecklist) === null || _f === void 0 ? void 0 : _f.code, "?"), isOpen: this._isDeleteConfirmationModalOpen, onToggle: this._toggleDeleteConfirmationDialogModalOpen, onSave: this._handleChecklistDelete }),
            React.createElement(ConfirmationDialog, { headerTitle: "Warning", body: "Disabling of checklist will lead to irreversible consequences in approvals and will have to be fixed tediously on enabling again!\n                            If you have disabled the checklist with this warning and enabled it again you would be advised to delete the gate and start over again to prevent any unwanted consequences.\n                            Consequences:\n                            This checklist's function is a approver in a approval process other than for this checklist. Thus the approver on the other checklist or it's deliverables will be irreversibly replaced with the PJM.\n                            If you enable the checklist again you will manually have to re-assign the function in the affected approval process by deleting the PJM approval and then adding the approval again with the correct function.", isOpen: this._isDisableConfirmationModalOpen, onToggle: this._toggleDisableConfirmationDialogModalOpen, onSave: this._onConflictResolve }),
            React.createElement(Switch, null, this._checklists.map(function (c) { return (React.createElement(Route, { key: c.id, path: "".concat(match.url, "/cl-").concat(c.id) },
                React.createElement(Modal, { isOpen: true, toggle: _this._toggleChecklistsModal, headerTitle: "Checklist Info", maxWidth: "95%" },
                    React.createElement(ChecklistView, { checklist: c, gateId: gateId })))); }))));
    };
    __decorate([
        observable
    ], ChecklistsC.prototype, "_checklists", void 0);
    __decorate([
        observable
    ], ChecklistsC.prototype, "_loading", void 0);
    __decorate([
        observable
    ], ChecklistsC.prototype, "_updating", void 0);
    __decorate([
        observable
    ], ChecklistsC.prototype, "_isDisableConfirmationModalOpen", void 0);
    __decorate([
        observable
    ], ChecklistsC.prototype, "_isDeleteConfirmationModalOpen", void 0);
    __decorate([
        observable
    ], ChecklistsC.prototype, "_isCopyChecklistModalOpen", void 0);
    __decorate([
        observable
    ], ChecklistsC.prototype, "_isEditChecklistModalOpen", void 0);
    __decorate([
        observable
    ], ChecklistsC.prototype, "_conflictingCheckList", void 0);
    __decorate([
        observable
    ], ChecklistsC.prototype, "_currentChecklist", void 0);
    __decorate([
        action.bound
    ], ChecklistsC.prototype, "_loadChecklists", null);
    __decorate([
        action.bound
    ], ChecklistsC.prototype, "_handleChange", null);
    __decorate([
        action.bound
    ], ChecklistsC.prototype, "_onConflictResolve", null);
    __decorate([
        action.bound
    ], ChecklistsC.prototype, "_toggleChecklistsModal", null);
    __decorate([
        action.bound
    ], ChecklistsC.prototype, "_toggleDisableConfirmationDialogModalOpen", null);
    __decorate([
        action.bound
    ], ChecklistsC.prototype, "_toggleDeleteConfirmationDialogModalOpen", null);
    __decorate([
        action.bound
    ], ChecklistsC.prototype, "_handleCopyClick", null);
    __decorate([
        action.bound
    ], ChecklistsC.prototype, "_handleChecklistCopy", null);
    __decorate([
        action.bound
    ], ChecklistsC.prototype, "_toggleCopyChecklistModal", null);
    __decorate([
        action.bound
    ], ChecklistsC.prototype, "_handleDeleteClick", null);
    __decorate([
        action.bound
    ], ChecklistsC.prototype, "_handleChecklistDelete", null);
    __decorate([
        action.bound
    ], ChecklistsC.prototype, "_handleEditClick", null);
    __decorate([
        action.bound
    ], ChecklistsC.prototype, "_handleChecklistEdit", null);
    __decorate([
        action.bound
    ], ChecklistsC.prototype, "_toggleEditChecklistModal", null);
    __decorate([
        action.bound
    ], ChecklistsC.prototype, "renderStatus", null);
    ChecklistsC = __decorate([
        observer
    ], ChecklistsC);
    return ChecklistsC;
}(React.PureComponent));
export var Checklists = withRouter(ChecklistsC);
