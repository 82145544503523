var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { observer } from 'mobx-react';
import { ProjectsOverviewGate } from '../ProjectsOverviewGate/ProjectsOverviewGate';
import styles from './ProjectsOverviewInfoline.module.scss';
import { colorThemesStore } from '@app/Stores/ColorThemesStore';
export var ProjectsOverviewInfoline = /** @class */ (function (_super) {
    __extends(ProjectsOverviewInfoline, _super);
    function ProjectsOverviewInfoline() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProjectsOverviewInfoline.prototype.render = function () {
        var colors = colorThemesStore.appColors;
        return (React.createElement("div", { className: styles.infoline },
            React.createElement("div", { className: styles.infoblock },
                React.createElement("div", { className: styles.gatePreview },
                    React.createElement(ProjectsOverviewGate, { color: colors.gray })),
                React.createElement("div", { className: styles.gatePreviewInfo }, "N/A")),
            React.createElement("div", { className: styles.infoblock },
                React.createElement("div", { className: styles.gatePreview },
                    React.createElement(ProjectsOverviewGate, { color: 'lightblue' })),
                React.createElement("div", { className: styles.gatePreviewInfo }, "Ongoing & on time")),
            React.createElement("div", { className: styles.infoblock },
                React.createElement("div", { className: styles.gatePreview },
                    React.createElement(ProjectsOverviewGate, { color: colors.green })),
                React.createElement("div", { className: styles.gatePreviewInfo }, "Completed on time")),
            React.createElement("div", { className: styles.infoblock },
                React.createElement("div", { className: styles.gatePreview },
                    React.createElement(ProjectsOverviewGate, { color: colors.red })),
                React.createElement("div", { className: styles.gatePreviewInfo }, "Delayed"))));
    };
    ProjectsOverviewInfoline = __decorate([
        observer
    ], ProjectsOverviewInfoline);
    return ProjectsOverviewInfoline;
}(React.PureComponent));
