var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styles from './AssesmentGateExecutiveQuestionsTable.module.scss';
import columnSharedStyles from '@app/Components/Grid/SharedStyles/column-shared-styles.module.scss';
import { observer } from 'mobx-react';
import { Grid } from '@app/Components/Grid/Grid';
import { Column } from 'devextreme-react/data-grid';
import { observable, action } from 'mobx';
import { Api } from '@app/Api';
import { FunctionFilterContext } from '@app/Components/FunctonFilter/FunctionFilterContext';
import { filterQuestionsTableRowsByFunction } from '@app/Components/FunctonFilter/FunctionFilter';
import { RatingCell } from '@app/Components/Grid/RatingCell/RatingCell';
import { ChecklistSections, Events, GateProcessTypeEnum, ObjectType, ProjectState, SignalREvents } from '@app/AppConstants';
import { EditCell } from '@app/Components/Grid/EditCell/EditCell';
import { createUniqueId } from '@app/Helpers/UidHelper/UidHelper';
import HTMLReactParser from 'html-react-parser';
import { signalRService } from '@app/Services/SignalRService';
import { ChangeLogDialog } from '@app/Components/Dialogs/ChangeLogDialog';
import { AddActionItemWrapper } from '@app/Components/AddActionItemWrapper/AddActionItemWrapper';
import { ConfirmButton } from '@app/Components/ConfirmButton/ConfirmButton';
import { CommentsSummary } from '@app/Components/Grid/CommentsSummary/CommentsSummary';
import { CommentsList } from '@app/Components/Grid/CommentsList/CommentsList';
import { RatingPicker } from '@app/Components/RatingPicker/RatingPicker';
import { CodeCell } from '@app/Components/Grid/CodeCell/CodeCell';
import { FunctionList } from '@app/Components/Grid/FunctionList/FunctionList';
import { filterItemsTableRowsByRoleAndRating } from '@app/Components/RatingFilterAssessment/RatingFilterAssessment';
import { RatingFilterAssessmentContext } from '@app/Components/RatingFilterAssessment/RatingFilterAssessmentContext';
import { ExternalProviderButton } from '../../../GatePreparations/ApprovedGateView/ApprovedChecklistView/ExternalProviderButton/ExternalProviderButton';
export var AssesmentGateExecutiveQuestionsTable = /** @class */ (function (_super) {
    __extends(AssesmentGateExecutiveQuestionsTable, _super);
    function AssesmentGateExecutiveQuestionsTable() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._executiveQuestions = [];
        _this._currentChecklistitem = null;
        _this._loading = false;
        _this._gridRef = React.createRef();
        return _this;
    }
    AssesmentGateExecutiveQuestionsTable.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadQuestions()];
                    case 1:
                        _a.sent();
                        signalRService.subscribe(SignalREvents.recommendationChanged, this._loadQuestions);
                        document.addEventListener(Events.assessmentGateExecutiveQuestionsConfirm, this._loadQuestions);
                        return [2 /*return*/];
                }
            });
        });
    };
    AssesmentGateExecutiveQuestionsTable.prototype.componentWillUnmount = function () {
        signalRService.unsubscribe(SignalREvents.recommendationChanged, this._loadQuestions);
        document.removeEventListener(Events.assessmentGateExecutiveQuestionsConfirm, this._loadQuestions);
    };
    AssesmentGateExecutiveQuestionsTable.prototype.reloadData = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadQuestions()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AssesmentGateExecutiveQuestionsTable.prototype._savePMQuestion = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 6]);
                        return [4 /*yield*/, Api.secured.updateProjectManagerExecutiveQuestion({ body: rowData })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.props.onRefreshChecklistStatusOverview()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 3:
                        error_1 = _a.sent();
                        if (!error_1) return [3 /*break*/, 5];
                        console.error(error_1);
                        return [4 /*yield*/, this._loadQuestions()];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    AssesmentGateExecutiveQuestionsTable.prototype._saveAssessorQuestion = function (rowData, rowIndex, isRepaint) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var item_1, error_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 5, , 8]);
                        return [4 /*yield*/, Api.secured.updateAssessorExecutiveQuestion({ body: rowData })];
                    case 1:
                        _b.sent();
                        if (!isRepaint) return [3 /*break*/, 3];
                        return [4 /*yield*/, Api.secured.getGateAssessmentExecutiveQuestion({ checklistItemId: rowData.id })];
                    case 2:
                        item_1 = _b.sent();
                        (_a = Object.keys(rowData)) === null || _a === void 0 ? void 0 : _a.forEach(function (key) {
                            rowData[key] = item_1[key];
                        });
                        if (this._gridRef.current) {
                            this._gridRef.current.repaintRows([rowIndex]);
                        }
                        _b.label = 3;
                    case 3: return [4 /*yield*/, this.props.onRefreshChecklistStatusOverview()];
                    case 4:
                        _b.sent();
                        return [3 /*break*/, 8];
                    case 5:
                        error_2 = _b.sent();
                        if (!error_2) return [3 /*break*/, 7];
                        console.error(error_2);
                        return [4 /*yield*/, this._loadQuestions()];
                    case 6:
                        _b.sent();
                        _b.label = 7;
                    case 7: return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    AssesmentGateExecutiveQuestionsTable.prototype._saveOkAssessorPart = function (checkListItemId, rowData, rowIndex) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var row;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Api.secured.okExecutiveQuestionAssessorPart({ checkListItemId: checkListItemId })];
                    case 1:
                        row = _b.sent();
                        (_a = Object.keys(rowData)) === null || _a === void 0 ? void 0 : _a.forEach(function (key) {
                            rowData[key] = row[key];
                        });
                        if (this._gridRef.current) {
                            this._gridRef.current.repaintRows([rowIndex]);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AssesmentGateExecutiveQuestionsTable.prototype.componentDidUpdate = function (prevProps) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(prevProps.gateId !== this.props.gateId || prevProps.hideNAItems !== this.props.hideNAItems)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._loadQuestions()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    AssesmentGateExecutiveQuestionsTable.prototype._loadQuestions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var projectId, gateId, hideNAItems, _a;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        projectId = (_b = this.props, _b.projectId), gateId = _b.gateId, hideNAItems = _b.hideNAItems;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, , 3, 4]);
                        this._loading = true;
                        _a = this;
                        return [4 /*yield*/, Api.secured.getGateAssessmentExecutiveQuestions({
                                projectId: projectId,
                                gateId: gateId,
                                hideNAItems: hideNAItems
                            })];
                    case 2:
                        _a._executiveQuestions = _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this._loading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AssesmentGateExecutiveQuestionsTable.prototype._toggleLogModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._currentChecklistitem = null;
                return [2 /*return*/];
            });
        });
    };
    AssesmentGateExecutiveQuestionsTable.prototype.render = function () {
        var _a, _b;
        var _this = this;
        var projectId = (_a = this.props, _a.projectId), gateId = _a.gateId, projectCodeName = _a.projectCodeName, gateName = _a.gateName, projectState = _a.projectState, onRefreshActionItems = _a.onRefreshActionItems, openModalLink = _a.openModalLink, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted;
        var _executiveQuestions = (_b = this, _b._executiveQuestions), _loading = _b._loading;
        return (React.createElement(React.Fragment, null,
            React.createElement(RatingFilterAssessmentContext.Consumer, null, function (ratingFilterValue) {
                return (React.createElement(FunctionFilterContext.Consumer, null, function (functionFilterValue) {
                    var rows = filterItemsTableRowsByRoleAndRating(_executiveQuestions, ratingFilterValue);
                    rows = filterQuestionsTableRowsByFunction(rows, functionFilterValue);
                    var count = rows.length;
                    return (React.createElement(Grid, { ref: _this._gridRef, dataSource: __spreadArray([], rows, true), expandableRows: true, rowsCount: count, loading: _loading },
                        React.createElement(Column, { caption: "ID", dataField: "code", width: 100, allowEditing: false, cssClass: columnSharedStyles.colWithHistory, cellRender: function (_a) {
                                var data = _a.data;
                                return (React.createElement(CodeCell, { code: data.code, onHistoryClick: function () { return (_this._currentChecklistitem = data); } }, data.canPrincipalUpdateProjectManagerPart &&
                                    (projectState === ProjectState.HasOnlyMasterCheckLists ||
                                        !data.hasChildren) && (React.createElement(ExternalProviderButton, { projectName: projectCodeName, projectId: projectId, gateId: gateId, gateCodeName: gateName, checkListItemId: data.id, taskId: data.taskId || null, taskStatus: data.taskStatus, onRefresh: _this._loadQuestions, disable: isProjectTerminatedOrCompleted }))));
                            } }),
                        React.createElement(Column, { caption: "Functions", width: 90, cellRender: function (_a) {
                                var data = _a.data;
                                return (React.createElement(FunctionList, { functions: data.functions, rowId: '' + data.id }));
                            } }),
                        React.createElement(Column, { caption: "Question / Expectation", dataField: "question", width: 350, cellRender: function (_a) {
                                var data = _a.data;
                                return (React.createElement("div", null,
                                    data.questionTitle && (React.createElement("div", { className: styles.questionTitle }, data.questionTitle)),
                                    React.createElement("div", { style: { whiteSpace: 'pre-line' } }, HTMLReactParser(data.question || ''))));
                            } }),
                        React.createElement(Column, { caption: "Guideline", dataField: "guideline", minWidth: 300, cellRender: function (_a) {
                                var data = _a.data;
                                return (React.createElement("div", { style: { whiteSpace: 'pre-line' } }, HTMLReactParser(data.guideline || '')));
                            } }),
                        projectState !== ProjectState.HasOnlyMasterCheckLists && (React.createElement(Column, { caption: "Function Answer", width: 200, cellRender: function (_a) {
                                var data = _a.data;
                                return (React.createElement("div", null,
                                    !data.hasChildren ? null : (React.createElement(CommentsSummary, { id: 'f' + data.id, green: data.greenRatingCount, yellow: data.yellowRatingCount, red: data.redRatingCount, notApplicable: data.notApplicableRatingCount, notRated: data.notRatingCount, notRatedCodes: data.notRatingCheckListItems, notCommented: data.notCommentedCount, notCommentedCodes: data.notCommentedCheckListItems, link: openModalLink, isVisible: true })),
                                    React.createElement(CommentsList, { comments: data.functionalComments || [] })));
                            } })),
                        projectState !== ProjectState.HasOnlyMasterCheckLists && (React.createElement(Column, { headerCellRender: function () { return React.createElement("div", { title: "Function Rating" }, "Rating"); }, width: 60, cellRender: function (_a) {
                                var data = _a.data;
                                return React.createElement(RatingPicker, { rating: data.functionalRating, disabled: true });
                            } })),
                        React.createElement(Column, { caption: "Project Manager Answer", dataField: "projectManagerComment", width: 300, cssClass: columnSharedStyles.commentColumn, cellRender: function (_a) {
                                var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                                return (React.createElement(EditCell, { rowIndex: rowIndex, columnIndex: columnIndex, value: data.projectManagerOriginalComment, onChange: function (value) {
                                        data.projectManagerOriginalComment = value;
                                    }, onSave: function () { return _this._savePMQuestion(data); }, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), withCopy: true, disabled: !data.canPrincipalUpdateProjectManagerPart ||
                                        isProjectTerminatedOrCompleted, section: ChecklistSections.ProjectManager },
                                    React.createElement(CommentsSummary, { id: 'p' + data.id, green: data.projectManagerGreenRatingCount, yellow: data.projectManagerYellowRatingCount, red: data.projectManagerRedRatingCount, notApplicable: data.projectManagerNotApplicableRatingCount, notRated: data.projectManagerNotRatingCount, notRatedCodes: data.projectManagerNotRatingCheckListItems, notCommented: data.projectManagerNotCommentedCount, notCommentedCodes: data.projectManagerNotCommentedCheckListItems, link: openModalLink, isVisible: !(projectState ===
                                            ProjectState.HasOnlyMasterCheckLists ||
                                            !data.hasChildren) }),
                                    React.createElement(CommentsList, { comments: data.projectManagerComments })));
                            } }),
                        React.createElement(Column, { headerCellRender: function () { return (React.createElement("div", { title: "Project Manager Rating" }, "Rating")); }, width: 110, cssClass: columnSharedStyles.commentColumn, cellRender: function (_a) {
                                var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                                return (React.createElement(AddActionItemWrapper, { projectId: projectId, gateId: gateId, gateProcessType: GateProcessTypeEnum.GateAssessment, disabled: !data.canPrincipalAddActionItem ||
                                        isProjectTerminatedOrCompleted, data: data, isForProjectManager: true, onActionItemSave: onRefreshActionItems },
                                    React.createElement(RatingCell, { rowIndex: rowIndex, columnIndex: columnIndex, onSave: function () { return _this._savePMQuestion(data); }, onChange: function (value) {
                                            data.projectManagerOriginalRating = value;
                                            data.projectManagerRating = value;
                                            if (_this._gridRef.current) {
                                                _this._gridRef.current.repaintRows([rowIndex]);
                                            }
                                        }, rating: data.projectManagerRating, text: data.projectManagerOriginalComment, textTitle: "Answer", showValidationError: data.canPrincipalUpdateProjectManagerPart &&
                                            ((projectState !==
                                                ProjectState.HasOnlyMasterCheckLists &&
                                                !data.hasChildren) ||
                                                projectState ===
                                                    ProjectState.HasOnlyMasterCheckLists), objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), disabled: !data.canPrincipalUpdateProjectManagerPart ||
                                            isProjectTerminatedOrCompleted, section: ChecklistSections.ProjectManager, controlId: "pmRating".concat(data.id) })));
                            } }),
                        React.createElement(Column, { caption: "Gate Assessor Comment", dataField: "gateAssessorComment", width: 300, cssClass: columnSharedStyles.commentColumn, cellRender: function (_a) {
                                var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                                return (React.createElement(EditCell, { rowIndex: rowIndex, columnIndex: columnIndex, value: data.assessorOriginalComment, onChange: function (value) {
                                        data.assessorOriginalComment = value;
                                        if (_this._gridRef.current) {
                                            _this._gridRef.current.repaintRows([rowIndex]);
                                        }
                                    }, onSave: function () { return _this._saveAssessorQuestion(data, rowIndex); }, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), withCopy: true, disabled: !data.canPrincipalUpdateGateAssessorPart ||
                                        isProjectTerminatedOrCompleted, section: ChecklistSections.Assessor },
                                    React.createElement(CommentsSummary, { id: 'a' + data.id, green: data.assessorGreenRatingCount, yellow: data.assessorYellowRatingCount, red: data.assessorRedRatingCount, notApplicable: data.assessorNotApplicableRatingCount, notRated: data.assessorNotRatingCount, notRatedCodes: data.assessorNotRatingCheckListItems, notCommented: data.assessorNotCommentedCount, notCommentedCodes: data.assessorNotCommentedCheckListItems, link: openModalLink, cssStyle: styles.commentsSummary, isVisible: !(projectState ===
                                            ProjectState.HasOnlyMasterCheckLists ||
                                            !data.hasChildren) }),
                                    React.createElement(CommentsList, { comments: data.assessorComments })));
                            } }),
                        React.createElement(Column, { headerCellRender: function () { return React.createElement("div", { title: "Gate Assessor Rating" }, "Rating"); }, width: 110, cssClass: columnSharedStyles.commentColumn, cellRender: function (_a) {
                                var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                                return (React.createElement(AddActionItemWrapper, { projectId: projectId, gateId: gateId, gateProcessType: GateProcessTypeEnum.GateAssessment, disabled: !data.canPrincipalAddActionItem ||
                                        isProjectTerminatedOrCompleted, data: data, onActionItemSave: onRefreshActionItems },
                                    React.createElement(RatingCell, { rowIndex: rowIndex, columnIndex: columnIndex, onSave: function () {
                                            return _this._saveAssessorQuestion(data, rowIndex, true);
                                        }, onChange: function (value) {
                                            data.assessorOriginalRating = value;
                                            data.assessorRating = value;
                                            if (_this._gridRef.current) {
                                                _this._gridRef.current.repaintRows([rowIndex]);
                                            }
                                        }, rating: data.assessorRating, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), disabled: !data.canPrincipalUpdateGateAssessorPart ||
                                            isProjectTerminatedOrCompleted, section: ChecklistSections.Assessor, controlId: "gaRating".concat(data.id), confirmButton: data.canPrincipalUpdateGateAssessorPart ? (React.createElement("div", { className: styles.confirmButton },
                                            React.createElement(ConfirmButton, { id: 'tooltip' + data.id, onClick: function () {
                                                    return _this._saveOkAssessorPart(data.id, data, rowIndex);
                                                }, disabled: isProjectTerminatedOrCompleted }))) : (undefined) })));
                            } })));
                }));
            }),
            this._currentChecklistitem && (React.createElement(ChangeLogDialog, { onToggle: this._toggleLogModal, uniqueId: createUniqueId(ObjectType.ChecklistItem, this._currentChecklistitem.id) }))));
    };
    __decorate([
        observable
    ], AssesmentGateExecutiveQuestionsTable.prototype, "_executiveQuestions", void 0);
    __decorate([
        observable
    ], AssesmentGateExecutiveQuestionsTable.prototype, "_currentChecklistitem", void 0);
    __decorate([
        observable
    ], AssesmentGateExecutiveQuestionsTable.prototype, "_loading", void 0);
    __decorate([
        observable.ref
    ], AssesmentGateExecutiveQuestionsTable.prototype, "_gridRef", void 0);
    __decorate([
        action.bound
    ], AssesmentGateExecutiveQuestionsTable.prototype, "reloadData", null);
    __decorate([
        action.bound
    ], AssesmentGateExecutiveQuestionsTable.prototype, "_savePMQuestion", null);
    __decorate([
        action.bound
    ], AssesmentGateExecutiveQuestionsTable.prototype, "_saveAssessorQuestion", null);
    __decorate([
        action.bound
    ], AssesmentGateExecutiveQuestionsTable.prototype, "_saveOkAssessorPart", null);
    __decorate([
        action.bound
    ], AssesmentGateExecutiveQuestionsTable.prototype, "_loadQuestions", null);
    __decorate([
        action.bound
    ], AssesmentGateExecutiveQuestionsTable.prototype, "_toggleLogModal", null);
    AssesmentGateExecutiveQuestionsTable = __decorate([
        observer
    ], AssesmentGateExecutiveQuestionsTable);
    return AssesmentGateExecutiveQuestionsTable;
}(React.PureComponent));
