var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React, { createRef } from 'react';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
var HorizontalScrollingWrapper = /** @class */ (function (_super) {
    __extends(HorizontalScrollingWrapper, _super);
    // private _lastScrollDistance: number | null = null;
    function HorizontalScrollingWrapper(props) {
        var _this = _super.call(this, props) || this;
        _this._wrapperRef = createRef();
        _this._isWheelDown = false;
        _this._isMouseWheelScrollingModeOn = false;
        _this._startPos = null;
        _this._isScrolledBeforeMouseUp = false;
        _this._onMouseDown = _this._onMouseDown.bind(_this);
        _this._onMouseUp = _this._onMouseUp.bind(_this);
        _this._onMouseMove = _this._onMouseMove.bind(_this);
        return _this;
    }
    HorizontalScrollingWrapper.prototype.componentDidMount = function () {
        var _a, _b;
        (_a = this._wrapperRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('mousedown', this._onMouseDown);
        (_b = this._wrapperRef.current) === null || _b === void 0 ? void 0 : _b.addEventListener('mouseup', this._onMouseUp);
        document.addEventListener('mousemove', this._onMouseMove);
    };
    HorizontalScrollingWrapper.prototype.componentWillUnmount = function () {
        var _a, _b;
        (_a = this._wrapperRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('mousedown', this._onMouseDown);
        (_b = this._wrapperRef.current) === null || _b === void 0 ? void 0 : _b.removeEventListener('mouseup', this._onMouseUp);
        document.removeEventListener('mousemove', this._onMouseMove);
    };
    HorizontalScrollingWrapper.prototype._onMouseDown = function (event) {
        if (!this._isCustomScrollPossible)
            return;
        var isWheel = event.button === 1;
        if (isWheel) {
            event.preventDefault();
            this._isWheelDown = true;
            this._startPos = null;
            if (!this._isMouseWheelScrollingModeOn) {
                this._isMouseWheelScrollingModeOn = true;
                this._setCursor('e-resize');
                return;
            }
        }
        this._isMouseWheelScrollingModeOn = false;
        this._isScrolledBeforeMouseUp = false;
        this._setCursor('default');
    };
    HorizontalScrollingWrapper.prototype._onMouseUp = function (event) {
        var isWheel = event.button === 1;
        if (isWheel) {
            this._isWheelDown = false;
            if (this._isScrolledBeforeMouseUp) {
                this._isMouseWheelScrollingModeOn = false;
                this._isScrolledBeforeMouseUp = false;
                this._setCursor('default');
            }
        }
    };
    HorizontalScrollingWrapper.prototype._onMouseMove = function (event) {
        if (this._isMouseWheelScrollingModeOn) {
            if (!this._startPos)
                this._startPos = event.pageX;
            var scrollDistance = (event.pageX - this._startPos) / 2;
            this._scrollGrid(scrollDistance);
            // this._lastScrollDistance = scrollDistance;
            if (this._isWheelDown) {
                this._isScrolledBeforeMouseUp = true;
            }
            else {
                this._isScrolledBeforeMouseUp = false;
            }
        }
    };
    HorizontalScrollingWrapper.prototype._scrollGrid = function (distance) {
        var _a;
        (_a = this._dataGridScroll) === null || _a === void 0 ? void 0 : _a.scrollBy({ left: distance, top: 0 });
    };
    // private _scrollGridByDistancePerSecond(distancePerSecond: number) {
    //     if (this._isMouseWheelScrollingModeOn && distancePerSecond === this._lastScrollDistance) {
    //         this._scrollGrid(distancePerSecond / 5);
    //     }
    //     setTimeout(() => {
    //         this._scrollGridByDistancePerSecond(distancePerSecond);
    //     }, 200);
    // }
    HorizontalScrollingWrapper.prototype._setCursor = function (cursor) {
        document.body.style.cursor = cursor;
    };
    Object.defineProperty(HorizontalScrollingWrapper.prototype, "_dataGridScroll", {
        get: function () {
            var _a;
            return (_a = this.props.dataGridRef.current) === null || _a === void 0 ? void 0 : _a.instance.getScrollable();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HorizontalScrollingWrapper.prototype, "_isCustomScrollPossible", {
        get: function () {
            var _a, _b, _c, _d;
            var clientWidth = (_a = this._dataGridScroll) === null || _a === void 0 ? void 0 : _a.clientWidth();
            var scrollWidth = (_b = this._dataGridScroll) === null || _b === void 0 ? void 0 : _b.scrollWidth();
            var clientHeight = Math.floor(((_c = this._dataGridScroll) === null || _c === void 0 ? void 0 : _c.clientHeight()) || 0);
            var scrollHeight = Math.floor(((_d = this._dataGridScroll) === null || _d === void 0 ? void 0 : _d.scrollHeight()) || 0);
            if (clientWidth !== scrollWidth && clientHeight && scrollHeight && clientHeight === scrollHeight) {
                return true;
            }
            return false;
        },
        enumerable: false,
        configurable: true
    });
    HorizontalScrollingWrapper.prototype.render = function () {
        return React.createElement("div", { ref: this._wrapperRef }, this.props.children);
    };
    __decorate([
        observable
    ], HorizontalScrollingWrapper.prototype, "_wrapperRef", void 0);
    __decorate([
        computed
    ], HorizontalScrollingWrapper.prototype, "_dataGridScroll", null);
    __decorate([
        computed
    ], HorizontalScrollingWrapper.prototype, "_isCustomScrollPossible", null);
    HorizontalScrollingWrapper = __decorate([
        observer
    ], HorizontalScrollingWrapper);
    return HorizontalScrollingWrapper;
}(React.PureComponent));
export default HorizontalScrollingWrapper;
