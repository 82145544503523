var DevInfoStore = /** @class */ (function () {
    function DevInfoStore() {
    }
    Object.defineProperty(DevInfoStore.prototype, "envClassName", {
        get: function () {
            return window.environment.toLocaleLowerCase();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DevInfoStore.prototype, "appVersion", {
        get: function () {
            return window.appVersion;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DevInfoStore.prototype, "appEnvironment", {
        get: function () {
            return window.environment.toUpperCase();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DevInfoStore.prototype, "isDevelopment", {
        get: function () {
            return this.appEnvironment === 'DEVELOPMENT';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DevInfoStore.prototype, "isDemo", {
        get: function () {
            return this.appEnvironment === 'DEMO';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DevInfoStore.prototype, "isStaging", {
        get: function () {
            return this.appEnvironment === 'STAGING' || this.appEnvironment === 'STAGE';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DevInfoStore.prototype, "isProduction", {
        get: function () {
            return this.appEnvironment === 'PRODUCTION';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DevInfoStore.prototype, "maxDocumentSize", {
        get: function () {
            return window.maxDocumentSize;
        },
        enumerable: false,
        configurable: true
    });
    return DevInfoStore;
}());
export var devInfoStore = new DevInfoStore();
