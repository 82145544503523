var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import notify from 'devextreme/ui/notify';
import { Api } from '@app/Api';
import { withRouter } from 'react-router-dom';
import { Modal } from '@app/Components/Modal/Modal';
import { devInfoStore } from '@app/Stores/DevInfoStore';
import { ActionItemDocumentsGrid } from './ActionItemDocumentsGrid/ActionItemDocumentsGrid';
import DragWrapper from '@app/Components/DragWrapper/DragWrapper';
var ActionItemDocumentViewC = /** @class */ (function (_super) {
    __extends(ActionItemDocumentViewC, _super);
    function ActionItemDocumentViewC() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._fileInputRef = React.createRef();
        _this._isSaving = false;
        _this._actionItemDocumentsModel = {
            actionItemId: 0
        };
        return _this;
    }
    ActionItemDocumentViewC.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadDocumentVersions()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ActionItemDocumentViewC.prototype._loadDocumentVersions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, Api.secured.getActionItemDocuments({
                                actionItemId: parseInt(this.props.match.params.id)
                            })];
                    case 1:
                        _a._actionItemDocumentsModel = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ActionItemDocumentViewC.prototype._refreshView = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.props.onFileChange && this.props.onFileChange();
                        return [4 /*yield*/, this._loadDocumentVersions()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ActionItemDocumentViewC.prototype._handleLoadClick = function () {
        var _a;
        (_a = this._fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    ActionItemDocumentViewC.prototype._handleDocumentDelete = function (actionItemId, actionItemDocumentId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.deleteActionItemDocument({
                            actionItemId: actionItemId,
                            actionItemDocumentId: actionItemDocumentId
                        })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._refreshView()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ActionItemDocumentViewC.prototype._handleFileChange = function (e) {
        return __awaiter(this, void 0, void 0, function () {
            var files;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.persist();
                        files = e.target.files;
                        return [4 /*yield*/, this._handleFiles(files)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ActionItemDocumentViewC.prototype._handleDrop = function (files) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._handleFiles(files)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ActionItemDocumentViewC.prototype._handleFiles = function (files) {
        return __awaiter(this, void 0, void 0, function () {
            var i, file, sizeInMb;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(files && files.length)) return [3 /*break*/, 8];
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < files.length)) return [3 /*break*/, 6];
                        file = files[i];
                        if (file.size > devInfoStore.maxDocumentSize) {
                            this._fileInputRef.current && (this._fileInputRef.current.value = '');
                            sizeInMb = devInfoStore.maxDocumentSize / 1048576;
                            notify("File size must be less than ".concat(sizeInMb, "MB"), 'error', 1000);
                            return [2 /*return*/];
                        }
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, , 4, 5]);
                        this._isSaving = true;
                        return [4 /*yield*/, Api.secured.uploadActionItemDocument(parseInt(this.props.match.params.id), {
                                file: file
                            })];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        this._isSaving = false;
                        return [7 /*endfinally*/];
                    case 5:
                        i++;
                        return [3 /*break*/, 1];
                    case 6: return [4 /*yield*/, this._refreshView()];
                    case 7:
                        _a.sent();
                        _a.label = 8;
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    ActionItemDocumentViewC.prototype._delay = function (time) {
        return new Promise(function (resolve) { return setTimeout(resolve, time); });
    };
    ActionItemDocumentViewC.prototype._toggleModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, history, backUrl, onClose;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, history = _a.history, backUrl = _a.backUrl, onClose = _a.onClose;
                        history.push(backUrl);
                        if (!onClose) return [3 /*break*/, 2];
                        return [4 /*yield*/, onClose()];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ActionItemDocumentViewC.prototype.render = function () {
        var _a = this.props, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted, canPrincipalManageDocument = _a.canPrincipalManageDocument;
        return (React.createElement(Modal, { isOpen: true, toggle: this._toggleModal, headerTitle: React.createElement(React.Fragment, null,
                React.createElement("div", null, "Document View"),
                React.createElement("div", null,
                    this._actionItemDocumentsModel.projectDisplayName,
                    "/",
                    this._actionItemDocumentsModel.actionItemCode)), maxWidth: "95%", footerbuttons: this._getFooterButtons(canPrincipalManageDocument) },
            React.createElement(DragWrapper, { dropHandler: this._handleDrop, disabled: !canPrincipalManageDocument || isProjectTerminatedOrCompleted },
                React.createElement(ActionItemDocumentsGrid, { isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted, actionItemId: this._actionItemDocumentsModel.actionItemId, documents: this._actionItemDocumentsModel.documents, onDocumentDelete: this._handleDocumentDelete, canManageDocument: canPrincipalManageDocument, onFileCommentUpdate: this._refreshView }),
                React.createElement("input", { style: { display: 'none' }, type: "file", ref: this._fileInputRef, onChange: this._handleFileChange }))));
    };
    ActionItemDocumentViewC.prototype._getFooterButtons = function (canUpload) {
        var buttons = [];
        if (canUpload) {
            buttons.push({
                color: 'primary',
                disabled: this._isSaving || this.props.isProjectTerminatedOrCompleted,
                onClick: this._handleLoadClick,
                title: 'Upload'
            });
        }
        buttons.push({
            onClick: this._toggleModal,
            title: 'Close'
        });
        return buttons;
    };
    __decorate([
        observable
    ], ActionItemDocumentViewC.prototype, "_fileInputRef", void 0);
    __decorate([
        observable
    ], ActionItemDocumentViewC.prototype, "_isSaving", void 0);
    __decorate([
        observable
    ], ActionItemDocumentViewC.prototype, "_actionItemDocumentsModel", void 0);
    __decorate([
        action.bound
    ], ActionItemDocumentViewC.prototype, "_loadDocumentVersions", null);
    __decorate([
        action.bound
    ], ActionItemDocumentViewC.prototype, "_refreshView", null);
    __decorate([
        action.bound
    ], ActionItemDocumentViewC.prototype, "_handleLoadClick", null);
    __decorate([
        action.bound
    ], ActionItemDocumentViewC.prototype, "_handleDocumentDelete", null);
    __decorate([
        action.bound
    ], ActionItemDocumentViewC.prototype, "_handleFileChange", null);
    __decorate([
        action.bound
    ], ActionItemDocumentViewC.prototype, "_handleDrop", null);
    __decorate([
        action.bound
    ], ActionItemDocumentViewC.prototype, "_handleFiles", null);
    __decorate([
        action.bound
    ], ActionItemDocumentViewC.prototype, "_toggleModal", null);
    ActionItemDocumentViewC = __decorate([
        observer
    ], ActionItemDocumentViewC);
    return ActionItemDocumentViewC;
}(React.PureComponent));
export var ActionItemDocumentView = withRouter(ActionItemDocumentViewC);
