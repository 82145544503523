var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import styles from './GateViewDescription.module.scss';
import { DATE_OPTIONS } from '@app/Helpers/DateOptions';
import { observer } from 'mobx-react';
import { observable, action, computed } from 'mobx';
import { Api } from '@app/Api';
import { ErrorModal } from '../../ErrorModal';
import { InputCell } from './InputCell';
import { InputStringCell } from './InputStringCell';
import { createUniqueId } from '@app/Helpers/UidHelper/UidHelper';
import { ObjectType } from '@app/AppConstants';
import { DateCell } from './DateCell';
import { Tooltip } from 'devextreme-react';
import HTMLReactParser from 'html-react-parser';
export var GateViewDescription = /** @class */ (function (_super) {
    __extends(GateViewDescription, _super);
    function GateViewDescription(props) {
        var _this = _super.call(this, props) || this;
        _this._durInWeeks = 13;
        _this._displayName = '';
        _this._isSyncWithNorrdDisabled = false;
        _this._isErrorModalOpen = false;
        _this._errorModalData = {};
        _this._isTooltipVisible = false;
        _this._durInWeeks = _this.props.gate.lengthInWeek;
        _this._forecastOrActualDate = _this.props.gate.forecastOrActualDate;
        _this._displayName = _this.props.gate.displayName;
        _this._isSyncWithNorrdDisabled = _this.props.gate.isSyncWithNorrdDisabled;
        return _this;
    }
    GateViewDescription.prototype.componentDidMount = function () {
        this._durInWeeks = this.props.gate.lengthInWeek;
        this._forecastOrActualDate = this.props.gate.forecastOrActualDate;
        this._displayName = this.props.gate.displayName;
        this._isSyncWithNorrdDisabled = this.props.gate.isSyncWithNorrdDisabled;
    };
    GateViewDescription.prototype.componentDidUpdate = function (prevProps) {
        if (prevProps.gate.lengthInWeek !== this.props.gate.lengthInWeek) {
            this._durInWeeks = this.props.gate.lengthInWeek;
        }
        if (prevProps.gate.completionTargetDate !== this.props.gate.completionTargetDate) {
            this._forecastOrActualDate = this.props.gate.forecastOrActualDate;
        }
        if (prevProps.gate.displayName !== this.props.gate.displayName) {
            this._displayName = this.props.gate.displayName;
        }
        if (prevProps.gate.isSyncWithNorrdDisabled !== this.props.gate.isSyncWithNorrdDisabled) {
            this._isSyncWithNorrdDisabled = this.props.gate.isSyncWithNorrdDisabled;
        }
    };
    Object.defineProperty(GateViewDescription.prototype, "_minCompletionTargetDate", {
        get: function () {
            var gate = this.props.gate;
            if (!gate.prevGateForecastOrActualDate)
                return;
            var minDate = new Date(gate.prevGateForecastOrActualDate);
            minDate.setDate(gate.prevGateForecastOrActualDate.getDate() + this._durInWeeks * 7);
            return minDate;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(GateViewDescription.prototype, "_maxDuration", {
        get: function () {
            var gate = this.props.gate;
            if (!gate.prevGateForecastOrActualDate || !this._forecastOrActualDate)
                return;
            var diffDays = this._dateDiffInDays(gate.prevGateForecastOrActualDate, this._forecastOrActualDate);
            return diffDays / 7;
        },
        enumerable: false,
        configurable: true
    });
    GateViewDescription.prototype._dateDiffInDays = function (a, b) {
        var _MS_PER_DAY = 1000 * 60 * 60 * 24;
        var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    };
    GateViewDescription.prototype._toggle = function () {
        this._isErrorModalOpen = !this._isErrorModalOpen;
    };
    GateViewDescription.prototype._handleDurationChange = function (duration) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._durInWeeks = duration;
                return [2 /*return*/];
            });
        });
    };
    GateViewDescription.prototype._handleForecastOrActualDateChange = function (date) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._forecastOrActualDate = date;
                return [2 /*return*/];
            });
        });
    };
    GateViewDescription.prototype._handleDisplayNameChange = function (displayName) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._displayName = displayName;
                return [2 /*return*/];
            });
        });
    };
    GateViewDescription.prototype._handleIsSyncWithNorrdDisabledChange = function (e) {
        return __awaiter(this, void 0, void 0, function () {
            var objectUniqueId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        objectUniqueId = createUniqueId(ObjectType.Gate, this.props.gate.id);
                        this._isSyncWithNorrdDisabled = !e.currentTarget.checked;
                        return [4 /*yield*/, Api.secured.createLock({ body: { objectUniqueId: objectUniqueId } })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._updateGate(undefined, undefined, undefined, this._isSyncWithNorrdDisabled)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, Api.secured.unLock({ objectUniqueId: objectUniqueId })];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    GateViewDescription.prototype._handleSaveClick = function (date, duration, displayName, isSyncWithNorrdDisabled) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this._updateGate(date, duration, displayName, isSyncWithNorrdDisabled)];
                    case 1:
                        _c.sent();
                        return [4 /*yield*/, ((_b = (_a = this.props).onReloadGate) === null || _b === void 0 ? void 0 : _b.call(_a))];
                    case 2:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    GateViewDescription.prototype._updateGate = function (date, duration, displayName, isSyncWithNorrdDisabled) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!date && !duration && !displayName && isSyncWithNorrdDisabled === undefined)
                            return [2 /*return*/];
                        return [4 /*yield*/, Api.secured
                                .updateGate({
                                body: {
                                    gateId: this.props.gate.id,
                                    forecastOrActualDate: date,
                                    lengthInWeeks: duration,
                                    displayName: displayName,
                                    isSyncWithNorrdDisabled: isSyncWithNorrdDisabled
                                }
                            })
                                .then(function (res) {
                                if (res.errorMessages) {
                                    _this._errorModalData = res;
                                    _this._isErrorModalOpen = true;
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    GateViewDescription.prototype.render = function () {
        var _a;
        var _this = this;
        var gate = (_a = this.props, _a.gate), isMilestoneProject = _a.isMilestoneProject, isNorrdProject = _a.isNorrdProject, editable = _a.editable;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.item },
                        React.createElement("div", { className: styles.title }, isMilestoneProject ? 'Milestone Name' : 'Gate Name'),
                        React.createElement("div", { className: styles.inputWrap },
                            React.createElement(InputStringCell, { value: this._displayName, onChange: function (v) { return _this._handleDisplayNameChange(v); }, onSave: function (v) { return _this._handleSaveClick(undefined, undefined, v); }, objectUniqueId: createUniqueId(ObjectType.Gate, gate.id), disabled: !gate.canPrincipalEditGateDisplayName || !editable }))),
                    React.createElement("div", { className: styles.item },
                        React.createElement("div", { className: styles.title }, isMilestoneProject ? 'Milestone Duration In Weeks' : 'Gate Duration In Weeks'),
                        React.createElement("div", { className: styles.inputWrap },
                            React.createElement(InputCell, { maxDuration: this._maxDuration, value: this._durInWeeks, onChange: function (v) { return _this._handleDurationChange(v); }, onSave: function (v) { return _this._handleSaveClick(undefined, v); }, objectUniqueId: createUniqueId(ObjectType.Gate, gate.id), disabled: !gate.canPrincipalEditGateDuration || !editable }))),
                    React.createElement("div", { className: styles.item },
                        React.createElement("div", { className: styles.title }, isMilestoneProject ? 'Milestone Description' : 'Gate Description'),
                        React.createElement("div", { className: styles.data, id: "gateDescription_".concat(gate.id), onMouseEnter: function () { return (_this._isTooltipVisible = true); }, onMouseLeave: function () { return (_this._isTooltipVisible = false); } }, gate.gateShortDescription),
                        React.createElement(Tooltip, { visible: this._isTooltipVisible, target: "#gateDescription_".concat(gate.id) },
                            React.createElement("span", { className: styles.gateDescriptionTooltipText }, HTMLReactParser(gate.gateDescription || '')))),
                    React.createElement("div", { className: styles.item })),
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.item },
                        React.createElement("div", { className: styles.title }, "Start Date"),
                        React.createElement("div", { className: styles.data }, gate.startDate && gate.startDate.toLocaleDateString('en-GB', DATE_OPTIONS))),
                    React.createElement("div", { className: styles.item },
                        React.createElement("div", { className: styles.title }, "Completion Target Date"),
                        React.createElement("div", { className: styles.data }, gate.completionTargetDate &&
                            gate.completionTargetDate.toLocaleDateString('en-GB', DATE_OPTIONS))),
                    React.createElement("div", { className: styles.item },
                        React.createElement("div", { className: styles.title }, "Forecast Or Actual Date"),
                        React.createElement("div", { className: styles.inputWrap },
                            React.createElement(DateCell, { onSave: function (date) { return _this._handleSaveClick(date); }, objectUniqueId: createUniqueId(ObjectType.Gate, gate.id), value: this._forecastOrActualDate, onChange: function (date) { return _this._handleForecastOrActualDateChange(date); }, minDate: this._minCompletionTargetDate, disabled: !gate.canPrincipalEditForecastOrActualDate || !editable }))),
                    React.createElement("div", { className: styles.item })),
                isNorrdProject && gate.canPrincipalEditIsSyncWithNorrdDisabled && (React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.checkbox },
                        React.createElement("div", { className: styles.title }, "Sync Forecast Or Actual Date with NORRD"),
                        React.createElement("input", { disabled: !editable, type: "checkbox", checked: !this._isSyncWithNorrdDisabled, onChange: this._handleIsSyncWithNorrdDisabledChange })),
                    React.createElement("div", { className: styles.item }),
                    React.createElement("div", { className: styles.item }),
                    React.createElement("div", { className: styles.item })))),
            React.createElement(ErrorModal, { isOpen: this._isErrorModalOpen, toggle: this._toggle, errorData: this._errorModalData })));
    };
    __decorate([
        observable
    ], GateViewDescription.prototype, "_durInWeeks", void 0);
    __decorate([
        observable
    ], GateViewDescription.prototype, "_forecastOrActualDate", void 0);
    __decorate([
        observable
    ], GateViewDescription.prototype, "_displayName", void 0);
    __decorate([
        observable
    ], GateViewDescription.prototype, "_isSyncWithNorrdDisabled", void 0);
    __decorate([
        observable
    ], GateViewDescription.prototype, "_isErrorModalOpen", void 0);
    __decorate([
        observable
    ], GateViewDescription.prototype, "_errorModalData", void 0);
    __decorate([
        observable
    ], GateViewDescription.prototype, "_isTooltipVisible", void 0);
    __decorate([
        action.bound
    ], GateViewDescription.prototype, "componentDidMount", null);
    __decorate([
        action.bound
    ], GateViewDescription.prototype, "componentDidUpdate", null);
    __decorate([
        computed
    ], GateViewDescription.prototype, "_minCompletionTargetDate", null);
    __decorate([
        computed
    ], GateViewDescription.prototype, "_maxDuration", null);
    __decorate([
        action.bound
    ], GateViewDescription.prototype, "_toggle", null);
    __decorate([
        action.bound
    ], GateViewDescription.prototype, "_handleDurationChange", null);
    __decorate([
        action.bound
    ], GateViewDescription.prototype, "_handleForecastOrActualDateChange", null);
    __decorate([
        action.bound
    ], GateViewDescription.prototype, "_handleDisplayNameChange", null);
    __decorate([
        action.bound
    ], GateViewDescription.prototype, "_handleIsSyncWithNorrdDisabledChange", null);
    __decorate([
        action.bound
    ], GateViewDescription.prototype, "_handleSaveClick", null);
    __decorate([
        action.bound
    ], GateViewDescription.prototype, "_updateGate", null);
    GateViewDescription = __decorate([
        observer
    ], GateViewDescription);
    return GateViewDescription;
}(React.PureComponent));
