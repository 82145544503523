var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import styles from './DocumentStatusIcon.module.scss';
import { Status, CheckListItemRatingEnum, StatusEnum } from '@app/AppConstants';
import { observable } from 'mobx';
import { Tooltip } from 'devextreme-react';
import { observer } from 'mobx-react';
import { colorThemesStore } from '@app/Stores/ColorThemesStore';
export var DocumentStatusIcon = /** @class */ (function (_super) {
    __extends(DocumentStatusIcon, _super);
    function DocumentStatusIcon() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isTooltipVisible = false;
        return _this;
    }
    DocumentStatusIcon.prototype._getDefaultStyles = function () {
        return {
            color: this._getColor()
        };
    };
    DocumentStatusIcon.prototype._getColor = function () {
        var colors = colorThemesStore.appColors;
        if (this.props.isNotApplicable) {
            return colors.gray;
        }
        if (this.props.targetStatus == Status.Draft) {
            if (((this.props.isFileUploaded || this.props.isExternalManagement) &&
                this.props.isRated &&
                this.props.isCommented) ||
                this.props.rating === CheckListItemRatingEnum.NotApplicable) {
                return colors.green;
            }
            else {
                return colors.red;
            }
        }
        switch (this.props.currentStatus) {
            case Status.Draft:
                return colors.red;
            case Status.InApproval:
                return colors.yellow;
            case Status.Approved:
                return colors.green;
            default:
                return colors.red;
        }
    };
    DocumentStatusIcon.prototype._getLetter = function () {
        switch (this.props.targetStatus) {
            case Status.Draft:
                return 'D';
            case Status.Approved:
                return 'A';
            default:
                return '';
        }
    };
    DocumentStatusIcon.prototype.render = function () {
        var _a;
        var _this = this;
        var className = (_a = this.props, _a.className), deliverableId = _a.deliverableId, isExternalManagement = _a.isExternalManagement, targetStatus = _a.targetStatus, currentStatus = _a.currentStatus, isFileUploaded = _a.isFileUploaded, isCommented = _a.isCommented, isRated = _a.isRated, onClick = _a.onClick;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { style: __assign({}, this._getDefaultStyles()), className: className + ' ' + styles.icon, onMouseEnter: function () { return (_this._isTooltipVisible = true); }, onMouseLeave: function () { return (_this._isTooltipVisible = false); }, id: "deliverable".concat(deliverableId), onClick: onClick }, this._getLetter()),
            React.createElement(Tooltip, { visible: this._isTooltipVisible, target: "#deliverable".concat(deliverableId) },
                React.createElement("div", { className: styles.deliverableInfoWrapper },
                    React.createElement("div", null,
                        "Target Status: ",
                        targetStatus),
                    React.createElement("div", null,
                        "Current Status: ",
                        currentStatus),
                    React.createElement("div", null,
                        "File uploaded:",
                        ' ',
                        isExternalManagement &&
                            (currentStatus == StatusEnum.InApproval ||
                                currentStatus === StatusEnum.Approved ||
                                (targetStatus === StatusEnum.Draft && isCommented && isRated))
                            ? 'external'
                            : isFileUploaded
                                ? 'yes'
                                : 'no'),
                    React.createElement("div", null,
                        "Rating set: ",
                        isRated ? 'yes' : 'no'),
                    React.createElement("div", null,
                        "Comment put: ",
                        isCommented ? 'yes' : 'no')))));
    };
    __decorate([
        observable
    ], DocumentStatusIcon.prototype, "_isTooltipVisible", void 0);
    DocumentStatusIcon = __decorate([
        observer
    ], DocumentStatusIcon);
    return DocumentStatusIcon;
}(React.PureComponent));
