var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { Api } from '@app/Api';
import styles from './ApprovedChecklistStatusOverview.module.scss';
import { Collapse } from 'reactstrap';
import { Grid } from '@app/Components/Grid/Grid';
import { Column } from 'devextreme-react/data-grid';
import { AppColors } from '@app/AppConstants';
export var CheckListStatusOverviewColumnName;
(function (CheckListStatusOverviewColumnName) {
    CheckListStatusOverviewColumnName["FunctionRating"] = "FunctionRating";
    CheckListStatusOverviewColumnName["PMRating"] = "PMRating";
    CheckListStatusOverviewColumnName["AssessorRating"] = "AssessorRating";
})(CheckListStatusOverviewColumnName || (CheckListStatusOverviewColumnName = {}));
export var ApprovedChecklistStatusOverview = /** @class */ (function (_super) {
    __extends(ApprovedChecklistStatusOverview, _super);
    function ApprovedChecklistStatusOverview() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._data = null;
        _this._isDescriptionOpen = false;
        _this._loading = false;
        _this.refreshChecklistStatusOverview = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this._getGridData = function (data) {
            var items = [];
            if (!data) {
                return items;
            }
            items.push(_this._getItem('Green', AppColors.LightGreen, 'Ok, Gate can be passed with this deliverable/question', data.functionOverview.greenRatingCount, data.projectManagerOverview.greenRatingCount, data.assessorOverview.greenRatingCount));
            items.push(_this._getItem('Yellow', AppColors.Yellow, 'On-going / Low risk', data.functionOverview.yellowRatingCount, data.projectManagerOverview.yellowRatingCount, data.assessorOverview.yellowRatingCount));
            items.push(_this._getItem('Red', AppColors.Red, 'Not ok, Gate cannot be passed with this deliverable/question', data.functionOverview.redRatingCount, data.projectManagerOverview.redRatingCount, data.assessorOverview.redRatingCount));
            items.push(_this._getItem('N/A', AppColors.Gray, 'Current deliverable/question is not applicable to this project', data.functionOverview.notApplicableRatingCount, data.projectManagerOverview.notApplicableRatingCount, data.assessorOverview.notApplicableRatingCount));
            items.push(_this._getItem('Default', AppColors.White, 'Default status, answer / deliverable to be provided', data.functionOverview.defaultRatingCount, data.projectManagerOverview.defaultRatingCount, data.assessorOverview.defaultRatingCount));
            return items;
        };
        _this._getItem = function (colorDescription, color, description, functionCount, prjManagerCount, assessorCount) {
            var item = {
                color: color,
                colorDescription: colorDescription,
                description: description,
                functionCount: functionCount,
                prjManagerCount: prjManagerCount,
                assessorCount: assessorCount
            };
            return item;
        };
        return _this;
    }
    ApprovedChecklistStatusOverview.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistStatusOverview.prototype._loadData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var checklistId, isForGateMeeting, _a;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        checklistId = (_b = this.props, _b.checklistId), isForGateMeeting = _b.isForGateMeeting;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, , 3, 4]);
                        this._loading = true;
                        _a = this;
                        return [4 /*yield*/, Api.secured.getChecklistStatusOverview({ id: checklistId, isForGateMeeting: isForGateMeeting })];
                    case 2:
                        _a._data = _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this._loading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistStatusOverview.prototype.render = function () {
        var _this = this;
        var hideColumns = this.props.hideColumns;
        var hideFunction = hideColumns === null || hideColumns === void 0 ? void 0 : hideColumns.includes(CheckListStatusOverviewColumnName.FunctionRating);
        var hidePM = hideColumns === null || hideColumns === void 0 ? void 0 : hideColumns.includes(CheckListStatusOverviewColumnName.PMRating);
        var hideGA = hideColumns === null || hideColumns === void 0 ? void 0 : hideColumns.includes(CheckListStatusOverviewColumnName.AssessorRating);
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.blockTitle },
                React.createElement("div", { className: styles.blockTitleText },
                    React.createElement("div", { className: this._isDescriptionOpen ? styles.close : styles.open, onClick: function () { return (_this._isDescriptionOpen = !_this._isDescriptionOpen); } }, "Ratings Overview")),
                React.createElement("div", { className: styles.blockTitleLine })),
            React.createElement("div", { className: styles.description },
                React.createElement(Collapse, { isOpen: this._isDescriptionOpen },
                    React.createElement("div", { style: { maxWidth: 1000 } },
                        React.createElement(Grid, { dataSource: this._getGridData(this._data), rowsCount: 5, loading: this._loading },
                            React.createElement(Column, { dataField: "colorDescription", caption: "", width: 100, alignment: "center", cssClass: styles.statusColumn, cellRender: function (_a) {
                                    var data = _a.data;
                                    return (React.createElement("div", { style: { backgroundColor: data.color }, className: styles.status },
                                        React.createElement("span", null, data.colorDescription)));
                                } }),
                            React.createElement(Column, { dataField: "description", width: 400, caption: "", alignment: "center" }),
                            !hideFunction && (React.createElement(Column, { dataField: "functionCount", width: 150, caption: "Function Ratings", alignment: "center" })),
                            !hidePM && (React.createElement(Column, { dataField: "prjManagerCount", width: 200, caption: "Project Manager Ratings", alignment: "center" })),
                            !hideGA && (React.createElement(Column, { dataField: "assessorCount", width: 150, caption: "Gate Assessor Ratings", alignment: "center" }))))))));
    };
    __decorate([
        observable
    ], ApprovedChecklistStatusOverview.prototype, "_data", void 0);
    __decorate([
        observable
    ], ApprovedChecklistStatusOverview.prototype, "_isDescriptionOpen", void 0);
    __decorate([
        observable
    ], ApprovedChecklistStatusOverview.prototype, "_loading", void 0);
    __decorate([
        action.bound
    ], ApprovedChecklistStatusOverview.prototype, "_loadData", null);
    ApprovedChecklistStatusOverview = __decorate([
        observer
    ], ApprovedChecklistStatusOverview);
    return ApprovedChecklistStatusOverview;
}(React.PureComponent));
