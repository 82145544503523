import { appStore } from '@app/AppStore';
export var AzureAuth = /** @class */ (function () {
    function AzureAuth() {
    }
    Object.defineProperty(AzureAuth, "isUserHasAdminRole", {
        get: function () {
            return true;
        },
        enumerable: false,
        configurable: true
    });
    AzureAuth.login = function () {
        appStore.clearUserData();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        var appWindow = window;
        appWindow.adal && appWindow.adal.redirectToLogin();
    };
    return AzureAuth;
}());
