var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observer } from 'mobx-react';
import styles from './MyTasks.module.scss';
import { Grid } from '@app/Components/Grid/Grid';
import { Column, Sorting, FilterRow, Scrolling } from 'devextreme-react/data-grid';
import { DateBox } from '@app/Widgets/DateBox/DateBox';
import { observable, action } from 'mobx';
import { Api } from '@app/Api';
import { withRouter } from 'react-router-dom';
import { SignalREvents } from '@app/AppConstants';
import { signalRService } from '@app/Services/SignalRService';
import { generateLink } from '../UrlHelper';
import DateTimeService from '@app/Services/DateTimeService';
import { CompletionType, PromiseCompletion } from '@app/Classes/PromiseCompletion';
var MyTasksS = /** @class */ (function (_super) {
    __extends(MyTasksS, _super);
    function MyTasksS() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._tasks = [];
        _this._promiseLoaded = new PromiseCompletion(CompletionType.Completed);
        _this._navigate = function (assignment) { return __awaiter(_this, void 0, void 0, function () {
            var link;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, generateLink(assignment)];
                    case 1:
                        link = _a.sent();
                        if (link) {
                            this.props.history.push(link);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    MyTasksS.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadTasks()];
                    case 1:
                        _a.sent();
                        signalRService.subscribe(SignalREvents.refreshAssignments, function () { return _this._loadTasks(); });
                        return [2 /*return*/];
                }
            });
        });
    };
    MyTasksS.prototype._loadTasks = function () {
        return __awaiter(this, void 0, void 0, function () {
            var tasksRequest, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        tasksRequest = Api.secured.getAssignmentsForCurrentPrincipal({ page: 0, size: 1000 });
                        this._promiseLoaded.subscribe(tasksRequest);
                        _a = this;
                        return [4 /*yield*/, tasksRequest];
                    case 1:
                        _a._tasks = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MyTasksS.prototype.render = function () {
        var _this = this;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.table },
                React.createElement(Grid, { dataSource: this._tasks, loading: this._promiseLoaded.isPending, style: { maxHeight: '300px' } },
                    React.createElement(Scrolling, { mode: "virtual" }),
                    React.createElement(Sorting, { mode: "multiple" }),
                    React.createElement(FilterRow, { visible: true }),
                    React.createElement(Column, { caption: "Message", dataField: "description", minWidth: 200, cellRender: function (_a) {
                            var data = _a.data;
                            return (React.createElement("div", { className: styles.link, onClick: function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this._navigate(data)];
                                        case 1: return [2 /*return*/, _a.sent()];
                                    }
                                }); }); } }, data.description));
                        } }),
                    React.createElement(Column, { caption: "Project ID", dataField: "project.value", width: 95, alignment: "left" }),
                    React.createElement(Column, { caption: "Project", dataField: "project.label", width: 170 }),
                    React.createElement(Column, { caption: "Creation Date", dataField: "createdDate", width: 120, cellRender: function (_a) {
                            var data = _a.data;
                            return React.createElement(DateBox, { date: data.createdDate });
                        } }),
                    React.createElement(Column, { caption: "Due Date", dataField: "dueDate", width: 120, defaultSortOrder: "asc", dataType: "date", cellRender: function (_a) {
                            var data = _a.data;
                            return (React.createElement("div", { className: data.dueDate < DateTimeService.today() ? styles.overdue : '' },
                                React.createElement(DateBox, { date: data.dueDate })));
                        } })))));
    };
    __decorate([
        observable
    ], MyTasksS.prototype, "_tasks", void 0);
    __decorate([
        observable
    ], MyTasksS.prototype, "_promiseLoaded", void 0);
    __decorate([
        action.bound
    ], MyTasksS.prototype, "_loadTasks", null);
    MyTasksS = __decorate([
        observer
    ], MyTasksS);
    return MyTasksS;
}(React.PureComponent));
export var MyTasks = withRouter(MyTasksS);
