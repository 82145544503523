var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { Button } from '@app/Components/Button/Button';
import { Grid } from '@app/Components/Grid/Grid';
import { Column } from 'devextreme-react/data-grid';
import { FaTrash } from 'react-icons/fa';
import { DateBox } from '@app/Widgets/DateBox/DateBox';
import styles from './ActionItemDocumentsGrid.module.scss';
import { DownloadButton } from '@app/Components/DownloadButton/DownloadButton';
import { CopyToClipBoardButton } from '@app/Components/CopyToClipboardButton/CopyToClipboardButton';
import { ComponentUrls } from '@app/AppConstants';
import { observer } from 'mobx-react';
export var ActionItemDocumentsGrid = /** @class */ (function (_super) {
    __extends(ActionItemDocumentsGrid, _super);
    function ActionItemDocumentsGrid() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ActionItemDocumentsGrid.prototype.render = function () {
        var _a;
        var actionItemId = (_a = this.props, _a.actionItemId), documents = _a.documents, canManageDocument = _a.canManageDocument, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted, onDocumentDelete = _a.onDocumentDelete;
        return (React.createElement(Grid, { dataSource: documents },
            React.createElement(Column, { caption: "Name", minWidth: 200, dataField: "name" }),
            React.createElement(Column, { caption: "Original File Name", minWidth: 200, dataField: "originalFileName" }),
            React.createElement(Column, { caption: "Uploaded Date", dataField: "uploadedDate", dataType: "date", width: 150, cellRender: function (_a) {
                    var data = _a.data;
                    return React.createElement(DateBox, { date: data.uploadedDate });
                } }),
            React.createElement(Column, { caption: "Uploaded By", dataField: "uploadedBy", width: 150 }),
            React.createElement(Column, { caption: "Version", dataField: "version", width: 100 }),
            React.createElement(Column, { caption: "Action", width: 300, cellRender: function (_a) {
                    var data = _a.data;
                    return (React.createElement("div", { className: styles.actions },
                        React.createElement(DownloadButton, { fileId: data.fileInformationId, className: styles.action }),
                        canManageDocument && (React.createElement(Button, { disabled: isProjectTerminatedOrCompleted, className: styles.action, color: "danger", size: "xs", onClick: function () { return onDocumentDelete(actionItemId, data.id); } },
                            React.createElement(FaTrash, null))),
                        React.createElement(CopyToClipBoardButton, { text: location.origin + ComponentUrls.DocumentUrl + data.fileInformationId, copyText: "Copy file link to clipboard" })));
                } })));
    };
    ActionItemDocumentsGrid = __decorate([
        observer
    ], ActionItemDocumentsGrid);
    return ActionItemDocumentsGrid;
}(React.PureComponent));
