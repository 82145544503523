var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { IoMdArrowDropright, IoMdArrowDropdown } from 'react-icons/io';
import styles from './Grid.module.scss';
import loaderStyles from './GridLoader/grid-loader.module.scss';
import { action, computed } from 'mobx';
import { GridLoader } from './GridLoader/GridLoader';
import HorizontalScrollingWrapper from './HorizontalScrollingWrapper/HorizontalScrollingWrapper';
export var Grid = /** @class */ (function (_super) {
    __extends(Grid, _super);
    function Grid() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._rowsStatus = [];
        _this._refO = React.createRef();
        _this._toggle = function (index) { return function () {
            var _a;
            _this._rowsStatus[index] = !_this._rowsStatus[index];
            (_a = _this._refO.current) === null || _a === void 0 ? void 0 : _a.instance.repaintRows([index]);
        }; };
        _this.repaintRows = function (rows) {
            var _a;
            (_a = _this._refO.current) === null || _a === void 0 ? void 0 : _a.instance.repaintRows(rows);
        };
        return _this;
    }
    Grid.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.rowsCount !== prevProps.rowsCount && this.props.rowsCount) {
            this._rowsStatus = [];
            for (var i = 0; i < this.props.rowsCount; i++)
                this._rowsStatus[i] = false;
        }
    };
    Object.defineProperty(Grid.prototype, "_allExpanded", {
        get: function () {
            return this._rowsStatus.some(function (el) { return el; });
        },
        enumerable: false,
        configurable: true
    });
    Grid.prototype._toggleAll = function () {
        if (this._allExpanded) {
            this._rowsStatus = this._rowsStatus.map(function () { return false; });
        }
        else {
            this._rowsStatus = this._rowsStatus.map(function () { return true; });
        }
        this.forceUpdate();
    };
    Grid.prototype.render = function () {
        var _this = this;
        var _a = this.props, style = _a.style, loading = _a.loading, expandableRows = _a.expandableRows, children = _a.children, rest = __rest(_a, ["style", "loading", "expandableRows", "children"]);
        return (React.createElement(HorizontalScrollingWrapper, { dataGridRef: this._refO },
            React.createElement("div", { className: loaderStyles.loaderContainer },
                React.createElement(GridLoader, { loading: !!loading }),
                React.createElement(DataGrid, __assign({ ref: this._refO, paging: { enabled: false }, showRowLines: true, allowColumnResizing: true, columnResizingMode: 'widget', allowColumnReordering: true, wordWrapEnabled: true, onKeyDown: function (e) { return (e.handled = true); }, onFocusedCellChanging: function (e) { return (e.cancel = true); }, onRowPrepared: function (e) {
                        var _a;
                        if (expandableRows && e.rowIndex !== undefined) {
                            if (!_this._rowsStatus[e.rowIndex])
                                (_a = e.rowElement) === null || _a === void 0 ? void 0 : _a.classList.add(styles.expandable);
                        }
                    } }, rest, { style: __assign({ fontFamily: 'Droid Sans' }, (style || {})), className: styles.grid }),
                    expandableRows && (React.createElement(Column, { headerCellRender: function () {
                            return _this._allExpanded ? (React.createElement(IoMdArrowDropdown, { className: styles.arrow, onClick: _this._toggleAll })) : (React.createElement(IoMdArrowDropright, { className: styles.arrow, onClick: _this._toggleAll }));
                        }, width: 25, cellRender: function (e) {
                            return _this._rowsStatus[e.rowIndex] ? (React.createElement(IoMdArrowDropdown, { className: styles.arrow, onClick: _this._toggle(e.rowIndex) })) : (React.createElement(IoMdArrowDropright, { className: styles.arrow, onClick: _this._toggle(e.rowIndex) }));
                        } })),
                    children))));
    };
    __decorate([
        computed
    ], Grid.prototype, "_allExpanded", null);
    __decorate([
        action.bound
    ], Grid.prototype, "_toggleAll", null);
    __decorate([
        action.bound
    ], Grid.prototype, "_toggle", void 0);
    return Grid;
}(React.PureComponent));
