var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { observable, action } from 'mobx';
export var ModalStore = /** @class */ (function () {
    function ModalStore() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.modalList = [];
    }
    ModalStore.prototype.show = function (state) {
        var _this = this;
        var resolvePromise;
        var instance = {
            close: function (data) { return __awaiter(_this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, ((_a = correctedState.onClose) === null || _a === void 0 ? void 0 : _a.call(correctedState, data))];
                        case 1:
                            _b.sent();
                            resolvePromise(data);
                            this.modalList = this.modalList.filter(function (modal) { return modal !== correctedState; });
                            return [2 /*return*/];
                    }
                });
            }); },
            result: new Promise(function (resolve) { return (resolvePromise = resolve); })
        };
        this.modalList.push(__assign(__assign({}, state), { width: state.width || 700, id: state.body.props.modalId || '', instance: instance }));
        var correctedState = this.modalList[this.modalList.length - 1];
        return instance;
    };
    __decorate([
        observable
    ], ModalStore.prototype, "modalList", void 0);
    __decorate([
        action
    ], ModalStore.prototype, "show", null);
    return ModalStore;
}());
export var modalStore = new ModalStore();
export var DialogActionType;
(function (DialogActionType) {
    DialogActionType[DialogActionType["cancel"] = 0] = "cancel";
    DialogActionType[DialogActionType["submit"] = 1] = "submit";
    DialogActionType[DialogActionType["yes"] = 2] = "yes";
    DialogActionType[DialogActionType["no"] = 3] = "no";
    DialogActionType[DialogActionType["save"] = 4] = "save";
    DialogActionType[DialogActionType["runNow"] = 5] = "runNow";
    DialogActionType[DialogActionType["saveAndRunNow"] = 6] = "saveAndRunNow";
    DialogActionType[DialogActionType["createNew"] = 7] = "createNew";
    DialogActionType[DialogActionType["delete"] = 8] = "delete";
})(DialogActionType || (DialogActionType = {}));
