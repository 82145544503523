var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import styles from './RolesOnObject.module.scss';
import { Api } from '@app/Api';
import { Button } from '@app/Components/Button/Button';
import { Grid } from '@app/Components/Grid/Grid';
import { Column, Summary, TotalItem } from 'devextreme-react/data-grid';
import { RoleMembers } from './RoleMembers';
import { UniqueIdFilter } from '../../Components/UniqueIdFilter';
export var RolesOnObject = /** @class */ (function (_super) {
    __extends(RolesOnObject, _super);
    function RolesOnObject() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isModalOpen = false;
        return _this;
    }
    RolesOnObject.prototype._loadRoles = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.getRolesOnObject({ uniqueId: this._selectedUniqueId }).then(function (res) {
                            _this._roles = res;
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    RolesOnObject.prototype._updateUniqueId = function (uniqueId) {
        this._selectedUniqueId = uniqueId;
    };
    RolesOnObject.prototype._openModal = function (role) {
        if (role) {
            this._selectedRole = role;
            this._isModalOpen = true;
        }
    };
    RolesOnObject.prototype._closeModal = function () {
        this._isModalOpen = false;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    RolesOnObject.prototype._customizeTotalCount = function (data) {
        return "Total Roles: ".concat(data.value);
    };
    RolesOnObject.prototype._refreshGrid = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadRoles()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    RolesOnObject.prototype.render = function () {
        var _this = this;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null, "Shows the roles on particular object identified by its unique ID."),
            React.createElement("div", { className: styles.line },
                React.createElement("div", { className: styles.thirdField },
                    React.createElement(UniqueIdFilter, { onUniqueIdChange: this._updateUniqueId }))),
            React.createElement("div", null,
                React.createElement(Button, { color: "primary", onClick: this._loadRoles }, "Show Roles")),
            this._roles && (React.createElement(Grid, { dataSource: this._roles },
                React.createElement(Column, { dataField: "originObjectTypeGroupName", groupIndex: 0, caption: "Origin Object", autoExpandGroup: false }),
                React.createElement(Column, { dataField: "roleDisplayName", caption: "Role", cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("a", { href: "#", target: "_self" },
                            React.createElement("span", { onClick: function () { return _this._openModal(data); } }, data.roleDisplayName)));
                    } }),
                React.createElement(Column, { dataField: "originObjectUniqueId", caption: "From" }),
                React.createElement(Column, { dataField: "roleMembersCount", caption: "Members", alignment: "left" }),
                React.createElement(Summary, null,
                    React.createElement(TotalItem, { column: "roleDisplayName", summaryType: "count", customizeText: this._customizeTotalCount })))),
            this._isModalOpen && (React.createElement(RoleMembers, { isOpen: this._isModalOpen, onClose: this._closeModal, objectUniqueId: this._selectedUniqueId, objectRoleInfo: this._selectedRole, onRefresh: this._refreshGrid }))));
    };
    __decorate([
        observable
    ], RolesOnObject.prototype, "_selectedUniqueId", void 0);
    __decorate([
        observable
    ], RolesOnObject.prototype, "_roles", void 0);
    __decorate([
        observable
    ], RolesOnObject.prototype, "_selectedRole", void 0);
    __decorate([
        observable
    ], RolesOnObject.prototype, "_isModalOpen", void 0);
    __decorate([
        action.bound
    ], RolesOnObject.prototype, "_loadRoles", null);
    __decorate([
        action.bound
    ], RolesOnObject.prototype, "_updateUniqueId", null);
    __decorate([
        action.bound
    ], RolesOnObject.prototype, "_openModal", null);
    __decorate([
        action.bound
    ], RolesOnObject.prototype, "_closeModal", null);
    __decorate([
        action.bound
    ], RolesOnObject.prototype, "_refreshGrid", null);
    RolesOnObject = __decorate([
        observer
    ], RolesOnObject);
    return RolesOnObject;
}(React.PureComponent));
