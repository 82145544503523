var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { FormFeedback } from 'reactstrap';
import DateTimeField from '@1stquad/react-bootstrap-datetimepicker';
import { DateTime } from '@app/AppConstants';
import DateTimeService from '@app/Services/DateTimeService';
export var DatePickerControl = /** @class */ (function (_super) {
    __extends(DatePickerControl, _super);
    function DatePickerControl() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DatePickerControl.prototype.render = function () {
        var _a;
        var value = (_a = this.props, _a.value), disabled = _a.disabled, onChange = _a.onChange, minDate = _a.minDate, maxDate = _a.maxDate, onBlur = _a.onBlur, onEnter = _a.onEnter, pickerMode = _a.pickerMode, pickerSize = _a.pickerSize, daysOfWeekDisabled = _a.daysOfWeekDisabled;
        var minimalDate = minDate && DateTimeService.isValidDate(minDate)
            ? minDate
            : DateTimeService.parse(DateTime.minDate, DateTime.viewDateFormat);
        var maximalDate = maxDate && DateTimeService.isValidDate(maxDate) ? maxDate : undefined;
        return (React.createElement(DateTimeField, { dateTime: value, hideInputIcon: true, inputFormat: this._getPickerFormat(pickerMode), format: this._getPickerFormat(pickerMode), onChange: onChange, onBlur: onBlur ? onBlur : undefined, onEnterKeyDown: onEnter ? onEnter : undefined, mode: pickerMode ? pickerMode : 'date', size: pickerSize ? pickerSize : 'sm', minDate: minimalDate, maxDate: maximalDate, disabled: disabled, daysOfWeekDisabled: daysOfWeekDisabled ? daysOfWeekDisabled : [] }));
    };
    DatePickerControl.prototype._getPickerFormat = function (mode) {
        if (mode === 'datetime') {
            return DateTime.viewFullFormat;
        }
        if (mode === 'time') {
            return DateTime.timeFormat;
        }
        return DateTime.viewDateFormat;
    };
    DatePickerControl = __decorate([
        observer
    ], DatePickerControl);
    return DatePickerControl;
}(React.Component));
export var DatePickerValidatedControl = /** @class */ (function (_super) {
    __extends(DatePickerValidatedControl, _super);
    function DatePickerValidatedControl() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DatePickerValidatedControl.prototype.render = function () {
        var _a, _b, _c;
        var disabled = (_a = this.props, _a.disabled), minDate = _a.minDate, maxDate = _a.maxDate, onBlur = _a.onBlur, onEnter = _a.onEnter, pickerMode = _a.pickerMode, pickerSize = _a.pickerSize, daysOfWeekDisabled = _a.daysOfWeekDisabled, formModel = _a.formModel, name = _a.name, viewMode = _a.viewMode, availableDates = _a.availableDates;
        var minimalDate = minDate && DateTimeService.isValidDate(minDate)
            ? minDate
            : DateTimeService.parse(DateTime.minDate, DateTime.viewDateFormat);
        var maximalDate = maxDate && DateTimeService.isValidDate(maxDate) ? maxDate : undefined;
        var fieldValue = (_b = formModel, _c = name, _b[_c]);
        var dateTimeValue = DateTimeService.parse(fieldValue, this._getPickerFormat(pickerMode));
        var dateTime = DateTimeService.isValidDate(dateTimeValue) ? dateTimeValue : fieldValue;
        var validationClass = formModel.invalidFields.includes(name) || !DateTimeService.isValidDate(dateTimeValue) ? 'is-invalid' : '';
        return (React.createElement("div", { className: formModel.formValidatedClass },
            React.createElement(DateTimeField, { dateTime: dateTime, hideInputIcon: true, inputFormat: this._getPickerFormat(pickerMode), format: this._getPickerFormat(pickerMode), onChange: this._onDateChanged, onBlur: onBlur ? onBlur : undefined, onEnterKeyDown: onEnter ? onEnter : undefined, mode: pickerMode ? pickerMode : 'date', viewMode: viewMode ? viewMode : 'date', size: pickerSize ? pickerSize : 'sm', minDate: minimalDate, maxDate: maximalDate, disabled: disabled, daysOfWeekDisabled: daysOfWeekDisabled ? daysOfWeekDisabled : [], validationClass: validationClass, availableDates: availableDates }),
            React.createElement(FormFeedback, null, formModel.errorFor(name).map(function (error) { return (React.createElement("div", { key: name + error }, error)); }))));
    };
    DatePickerValidatedControl.prototype._onDateChanged = function (dateTime, value) {
        var _a;
        var pickerMode = (_a = this.props, _a.pickerMode), formModel = _a.formModel, name = _a.name, onChange = _a.onChange;
        formModel.setValue(name, value && DateTimeService.isValidDate(dateTime)
            ? DateTimeService.toCustomUiFormat(dateTime, this._getPickerFormat(pickerMode))
            : value);
        if (onChange && (formModel.isValid(name) || DateTimeService.isValidDate(dateTime))) {
            onChange(dateTime);
        }
    };
    DatePickerValidatedControl.prototype._getPickerFormat = function (mode) {
        if (mode === 'datetime') {
            return DateTime.viewFullFormat;
        }
        if (mode === 'time') {
            return DateTime.timeFormat;
        }
        return DateTime.viewDateFormat;
    };
    __decorate([
        action.bound
    ], DatePickerValidatedControl.prototype, "_onDateChanged", null);
    DatePickerValidatedControl = __decorate([
        observer
    ], DatePickerValidatedControl);
    return DatePickerValidatedControl;
}(React.Component));
