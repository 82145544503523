var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { SimpleToggler } from '@app/Components/ShowNAItemsToggler/SimpleToggler';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './DeletedItemsFilter.module.scss';
export var DeletedItemsFilter = /** @class */ (function (_super) {
    __extends(DeletedItemsFilter, _super);
    function DeletedItemsFilter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DeletedItemsFilter.prototype._onToggle = function (checked) {
        this.props.onToggle(checked);
    };
    DeletedItemsFilter.prototype.render = function () {
        var _a;
        var _this = this;
        var title = (_a = this.props, _a.title), isChecked = _a.isChecked;
        return (React.createElement("div", { className: styles.deletedItemFilterWrapper },
            React.createElement("div", { className: styles.togglerWrapper },
                React.createElement(SimpleToggler, { onToggle: function (checked) { return _this._onToggle(checked); }, checked: isChecked })),
            React.createElement("div", { className: styles.titleWrapper }, title)));
    };
    __decorate([
        action.bound
    ], DeletedItemsFilter.prototype, "_onToggle", null);
    DeletedItemsFilter = __decorate([
        observer
    ], DeletedItemsFilter);
    return DeletedItemsFilter;
}(React.PureComponent));
