var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { observable, action } from 'mobx';
// import styles from './AssessmentGateView.module.scss';
import { observer } from 'mobx-react';
import { FunctionPicker } from '@app/Components/FunctionPicker/FunctionPicker';
export var FunctionFilter = /** @class */ (function (_super) {
    __extends(FunctionFilter, _super);
    function FunctionFilter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FunctionFilter_1 = FunctionFilter;
    FunctionFilter.prototype._onFilterValueChange = function (selectedFunction) {
        FunctionFilter_1.filterValue = selectedFunction;
    };
    FunctionFilter.prototype.render = function () {
        var def = FunctionFilter_1.filterValue ? FunctionFilter_1.filterValue : undefined;
        return (React.createElement("div", { style: { display: 'flex', alignItems: 'center' } },
            React.createElement("div", { style: { marginRight: 10 } }, "Filter Items:"),
            React.createElement("div", { style: { width: '200px' } },
                React.createElement(FunctionPicker, { defaultFunction: def, isClearable: true, onFunctionSelect: this._onFilterValueChange, actionForLoading: this.props.actionForLoading }))));
    };
    var FunctionFilter_1;
    FunctionFilter.filterValue = null;
    __decorate([
        action.bound
    ], FunctionFilter.prototype, "_onFilterValueChange", null);
    __decorate([
        observable
    ], FunctionFilter, "filterValue", void 0);
    FunctionFilter = FunctionFilter_1 = __decorate([
        observer
    ], FunctionFilter);
    return FunctionFilter;
}(React.PureComponent));
export function filterDeliverableTableRowsByFunction(tableRows, selectedFunction) {
    if (!selectedFunction)
        return tableRows;
    var sfName = selectedFunction.name;
    var newRows = tableRows
        .filter(function (row) { return row.functions.some(function (f) { return f.name === sfName; }); })
        .map(function (row) {
        var newFunctionNames = row.functions.filter(function (f) { return f.name === sfName; });
        var newRow = Object.assign({}, row);
        newRow.functions = newFunctionNames;
        return newRow;
    });
    return newRows;
}
export function filterFunctionSummariesTableRowsByFunction(tableRows, selectedFunction) {
    if (!selectedFunction)
        return tableRows;
    var sfName = selectedFunction.name;
    var newRows = tableRows
        .filter(function (row) { return row.functions.some(function (x) { return x.name === sfName; }); })
        .map(function (row) {
        var newFunctionNames = row.functions.filter(function (x) { return x.name === sfName; });
        var newRow = Object.assign({}, row);
        newRow.functions = newFunctionNames;
        return newRow;
    });
    return newRows;
}
export function filterQuestionsTableRowsByFunction(tableRows, selectedFunction) {
    if (!selectedFunction)
        return tableRows;
    var sfName = selectedFunction.name;
    var newRows = tableRows
        .filter(function (row) { return row.functions.some(function (f) { return f.name === sfName; }); })
        .map(function (row) {
        var newFunctionNames = row.functions.filter(function (f) { return f.name === sfName; });
        var newRow = Object.assign({}, row);
        newRow.functions = newFunctionNames;
        return newRow;
    });
    return newRows;
}
export function filterActionItemsTableRowsByFunction(tableRows, selectedFunction) {
    if (!selectedFunction)
        return tableRows;
    return tableRows.filter(function (row) {
        var id = selectedFunction.id;
        return row.functionId === id || row.supportedFunctionId === id;
    });
}
