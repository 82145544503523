var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import styles from './Approvals.module.scss';
import { Api } from '@app/Api';
import { Grid } from '@app/Components/Grid/Grid';
import { Column, Sorting, FilterRow } from 'devextreme-react/data-grid';
import { withRouter, Switch, Route } from 'react-router-dom';
import { AddApprovalModal } from '../Gates/GateView/AddApprovalModal';
import { FunctionCell } from './FunctionCell';
import { createUniqueId } from '@app/Helpers/UidHelper/UidHelper';
import { ApprovalResultEnum, ApprovalTypeEnum, ObjectType, SeparatedStrings } from '@app/AppConstants';
import { DateCell } from './DateCell';
import { Button } from '@app/Components/Button/Button';
import { FaTrash } from 'react-icons/fa';
var ApprovalsC = /** @class */ (function (_super) {
    __extends(ApprovalsC, _super);
    function ApprovalsC() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loadingUpd = false;
        _this.loadingApprovals = false;
        _this._approvals = [];
        // TODO: LOADING ISSUE?
        _this._actionForLoadFunctions = function () {
            var projectId = _this.props.projectId;
            return Api.secured.getProjectFunctions({ projectId: projectId }).then(function (res) {
                return res;
            });
        };
        return _this;
    }
    ApprovalsC.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadApprovals()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovalsC.prototype._loadApprovals = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, projectId, gateId, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this.props, projectId = _a.projectId, gateId = _a.gateId;
                        this.loadingApprovals = true;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, , 3, 4]);
                        _b = this;
                        return [4 /*yield*/, Api.secured.getApprovalsForGate({ projectId: projectId, gateId: gateId })];
                    case 2:
                        _b._approvals = _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this.loadingApprovals = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ApprovalsC.prototype._updateApproval = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            var approval, approvalIndex;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loadingUpd = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        return [4 /*yield*/, Api.secured.updateApproval({ body: rowData })];
                    case 2:
                        approval = _a.sent();
                        approvalIndex = this._approvals.findIndex(function (x) { return x.id == rowData.id; });
                        if (approvalIndex) {
                            this._approvals.splice(approvalIndex, 1);
                            this._approvals = __spreadArray(__spreadArray([], this._approvals, true), [approval], false);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        this.loadingUpd = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ApprovalsC.prototype._handleApprovalDelete = function (approvalId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.deleteApproval({ id: approvalId })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadApprovals()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ApprovalsC.prototype._onFunctionChange = function (data, functionModel) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!functionModel)
                    return [2 /*return*/];
                data.relatedFunctionFunctionId = functionModel.id;
                data.relatedFunctionFunctionCode = functionModel.code;
                data.relatedFunctionFunctionName = functionModel.name;
                return [2 /*return*/];
            });
        });
    };
    ApprovalsC.prototype.renderApprovalResult = function (approvalResult) {
        if (approvalResult === ApprovalResultEnum.NotYetSet)
            return SeparatedStrings.ApprovalResultNotYetSet;
        return approvalResult;
    };
    ApprovalsC.prototype.renderApprovalType = function (approvalType) {
        switch (approvalType) {
            case ApprovalTypeEnum.ProvideDeliverableTask:
                return SeparatedStrings.ApprovalTypeProvideDeliverableTask;
            case ApprovalTypeEnum.DraftDeliverableTask:
                return SeparatedStrings.ApprovalTypeDraftDeliverableTask;
            case ApprovalTypeEnum.ApprovalDeliverableTask:
                return SeparatedStrings.ApprovalTypeApprovalDeliverableTask;
            case ApprovalTypeEnum.MoveDeliverableToInApprovalTask:
                return SeparatedStrings.ApprovalTypeMoveDeliverableToInApprovalTask;
            case ApprovalTypeEnum.MoveFunctionChecklistToInApprovalTask:
                return SeparatedStrings.ApprovalTypeMoveFunctionChecklistToInApprovalTask;
            case ApprovalTypeEnum.ApprovalFunctionChecklistTask:
                return SeparatedStrings.ApprovalTypeApprovalFunctionChecklistTask;
        }
    };
    ApprovalsC.prototype.render = function () {
        var _this = this;
        var _a = this.props, match = _a.match, projectId = _a.projectId, gateId = _a.gateId, editable = _a.editable;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.wrapper },
                React.createElement("div", { className: styles.table },
                    React.createElement(Grid, { dataSource: this._approvals, loading: this.loadingApprovals },
                        React.createElement(Sorting, { mode: "multiple" }),
                        React.createElement(FilterRow, { visible: true }),
                        React.createElement(Column, { caption: "Id", dataField: "relatedObjectCodeName", width: 120, defaultSortOrder: "asc" }),
                        React.createElement(Column, { caption: "Deliverable Name", dataField: "deliverableName", width: 250 }),
                        React.createElement(Column, { caption: "Type", dataField: "approvalType", minWidth: 200, cellRender: function (_a) {
                                var data = _a.data;
                                return (React.createElement("p", null, _this.renderApprovalType(data.approvalType)));
                            } }),
                        React.createElement(Column, { caption: "Approving Function", dataField: "relatedFunctionFunctionName", width: 200, cssClass: styles.editCell, allowFiltering: true, cellRender: function (_a) {
                                var data = _a.data;
                                return (React.createElement(FunctionCell, { value: {
                                        code: data.relatedFunctionFunctionCode || '',
                                        name: data.relatedFunctionFunctionName || '',
                                        id: data.relatedFunctionFunctionId || 0
                                    }, onChange: function (f) { return _this._onFunctionChange(data, f); }, onSave: function () { return _this._updateApproval(data); }, objectUniqueId: createUniqueId(ObjectType.Approval, data.id), cellRender: "".concat(data.relatedFunctionFunctionName, " (").concat(data.relatedFunctionFunctionCode, ")"), actionForLoadingFunctions: _this._actionForLoadFunctions, disabled: !editable ||
                                        (data.approvalType !== ApprovalTypeEnum.ApprovalFunctionChecklistTask &&
                                            data.approvalType !== ApprovalTypeEnum.ApprovalDeliverableTask) }));
                            } }),
                        React.createElement(Column, { caption: "Related Principal / Approver", dataField: "relatedPrincipalDisplayName", width: 200, allowFiltering: true, cellRender: function (_a) {
                                var data = _a.data;
                                return (React.createElement("p", null, data.relatedPrincipalDisplayName));
                            } }),
                        React.createElement(Column, { caption: "Date From", dataField: "dateFrom", width: 150, cssClass: styles.editCell, allowFiltering: false, alignment: "center", defaultSortOrder: "asc", cellRender: function (_a) {
                                var data = _a.data;
                                return (React.createElement(DateCell, { onSave: function () { return _this._updateApproval(data); }, objectUniqueId: createUniqueId(ObjectType.Approval, data.id), value: data.dateFrom, max: data.dateTo, onChange: function (date) { return (data.dateFrom = date); }, disabled: !editable }));
                            } }),
                        React.createElement(Column, { caption: "Date To", dataField: "dateTo", width: 150, cssClass: styles.editCell, allowFiltering: false, alignment: "center", cellRender: function (_a) {
                                var data = _a.data;
                                return (React.createElement(DateCell, { onSave: function () { return _this._updateApproval(data); }, objectUniqueId: createUniqueId(ObjectType.Approval, data.id), value: data.dateTo, onChange: function (date) { return (data.dateTo = date); }, disabled: !editable, min: data.dateFrom }));
                            } }),
                        React.createElement(Column, { width: 50, cellRender: function (_a) {
                                var data = _a.data;
                                return (React.createElement("div", { className: styles.actions }, _this.props.editable && data.canPrincipalRemoveApproval && (React.createElement(Button, { className: styles.action, color: "danger", size: "xs", onClick: function () { return _this._handleApprovalDelete(data.id); } },
                                    React.createElement(FaTrash, null)))));
                            } })))),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "".concat(match.url, "/add") },
                    React.createElement(AddApprovalModal, { backUrl: "".concat(match.url), projectId: projectId, gateId: gateId || 0, onClose: this._loadApprovals })))));
    };
    __decorate([
        observable
    ], ApprovalsC.prototype, "loadingUpd", void 0);
    __decorate([
        observable
    ], ApprovalsC.prototype, "loadingApprovals", void 0);
    __decorate([
        observable.deep
    ], ApprovalsC.prototype, "_approvals", void 0);
    __decorate([
        action.bound
    ], ApprovalsC.prototype, "_loadApprovals", null);
    __decorate([
        action.bound
    ], ApprovalsC.prototype, "_updateApproval", null);
    __decorate([
        action.bound
    ], ApprovalsC.prototype, "_handleApprovalDelete", null);
    __decorate([
        action
    ], ApprovalsC.prototype, "_onFunctionChange", null);
    ApprovalsC = __decorate([
        observer
    ], ApprovalsC);
    return ApprovalsC;
}(React.PureComponent));
export var Approvals = withRouter(ApprovalsC);
