var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
export var ClickDetector = /** @class */ (function (_super) {
    __extends(ClickDetector, _super);
    function ClickDetector(props) {
        var _this = _super.call(this, props) || this;
        _this._wrapperRef = React.createRef();
        _this.ignoreDetecton = false;
        _this.lastDetectionTimeStamp = null;
        _this.handleClickOutside = _this.handleClickOutside.bind(_this);
        return _this;
    }
    ClickDetector.prototype.componentDidMount = function () {
        document.addEventListener('click', this.handleClickOutside);
    };
    ClickDetector.prototype.componentWillUnmount = function () {
        document.removeEventListener('click', this.handleClickOutside);
    };
    ClickDetector.prototype.toggleDetection = function (isIgnored) {
        this.ignoreDetecton = isIgnored;
    };
    ClickDetector.prototype.handleClickOutside = function (event) {
        var _a;
        // skip fast double click
        if (this.lastDetectionTimeStamp != null &&
            event.timeStamp > this.lastDetectionTimeStamp - 10 &&
            event.timeStamp < this.lastDetectionTimeStamp + 10)
            return;
        var disabled = (_a = this.props, _a.disabled), onOutsideClick = _a.onOutsideClick;
        if (disabled ||
            !this._wrapperRef.current ||
            (this._wrapperRef.current && this._wrapperRef.current.contains(event.target)))
            return;
        var rect = this._wrapperRef.current.getBoundingClientRect();
        var xOutSide = event.x > rect.right || event.x < rect.left;
        var yOutSide = event.y < rect.top || event.y > rect.bottom;
        if (xOutSide || yOutSide) {
            this.lastDetectionTimeStamp = event.timeStamp;
            onOutsideClick();
        }
    };
    ClickDetector.prototype.render = function () {
        return (React.createElement("div", { ref: this._wrapperRef, className: this.props.className }, this.props.children));
    };
    __decorate([
        observable
    ], ClickDetector.prototype, "ignoreDetecton", void 0);
    __decorate([
        observable
    ], ClickDetector.prototype, "lastDetectionTimeStamp", void 0);
    __decorate([
        action.bound
    ], ClickDetector.prototype, "toggleDetection", null);
    ClickDetector = __decorate([
        observer
    ], ClickDetector);
    return ClickDetector;
}(React.Component));
