var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Widget } from '@app/Components/Widget/Widget';
import { ProjectsOverview } from '@app/Components/ProjectsOverview/ProjectsOverview';
import styles from './Projects.module.scss';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import ErrorBoundary from '@app/Components/ErrorBoundary/ErrorBoundary';
import { Api } from '@app/Api';
import { ProjectsOverviewFilter } from '@app/Components/ProjectsOverview/ProjectsOverviewFilter/ProjectsOverviewFilter';
import { Button } from '@app/Components/Button/Button';
import { FaRegFileExcel } from 'react-icons/fa';
import DownloadService from '@app/Services/DownloadService';
var gridName = 'ProjectsGrid';
export var Projects = /** @class */ (function (_super) {
    __extends(Projects, _super);
    function Projects() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._filters = null;
        _this._currentFilterParams = undefined;
        return _this;
    }
    Projects.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var filters;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.getPrincipalFilterConfiguration({ name: gridName })];
                    case 1:
                        filters = _a.sent();
                        this._filters = filters || {};
                        return [2 /*return*/];
                }
            });
        });
    };
    Projects.prototype._saveFilters = function (filters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.addOrUpdatePrincipalFilterConfiguration({
                            name: gridName,
                            filterModel: filters ? JSON.stringify(filters) : ''
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Projects.prototype._resetFilters = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Api.secured.deletePrincipalFilterConfiguration({
                            name: gridName
                        })];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, ((_a = this._projectsRef) === null || _a === void 0 ? void 0 : _a.reloadProjects())];
                    case 2:
                        _b.sent();
                        this._currentFilterParams = undefined;
                        return [2 /*return*/];
                }
            });
        });
    };
    Projects.prototype._clearFilters = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, ((_a = this._projectsRef) === null || _a === void 0 ? void 0 : _a.reloadProjects())];
                    case 1:
                        _b.sent();
                        this._currentFilterParams = undefined;
                        return [2 /*return*/];
                }
            });
        });
    };
    Projects.prototype._filterProjects = function (params) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, ((_a = this._projectsRef) === null || _a === void 0 ? void 0 : _a.reloadProjects(params))];
                    case 1:
                        _b.sent();
                        this._currentFilterParams = params;
                        return [2 /*return*/];
                }
            });
        });
    };
    Projects.prototype._handleExcelButtonClick = function () {
        return __awaiter(this, void 0, void 0, function () {
            var getLogsResponse, data, fileName;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.downloadProjects(__assign({}, this._currentFilterParams))];
                    case 1:
                        getLogsResponse = _a.sent();
                        return [4 /*yield*/, getLogsResponse.blobBody];
                    case 2:
                        data = _a.sent();
                        fileName = "Projects.xlsx";
                        DownloadService.downloadByData(data, fileName);
                        return [2 /*return*/];
                }
            });
        });
    };
    Projects.prototype.render = function () {
        var _this = this;
        var _a, _b;
        return (React.createElement(ErrorBoundary, null,
            this._filters && (React.createElement(ProjectsOverviewFilter, { defaultFilters: this._filters, onFiltersSave: this._saveFilters, onFilterReset: this._resetFilters, onFilterClear: this._clearFilters, onFilterProjects: this._filterProjects })),
            React.createElement("div", { className: styles.widgets },
                React.createElement("div", { className: styles.widgetWrapper },
                    React.createElement("div", { className: styles.widget },
                        React.createElement(Widget, { title: React.createElement("div", { className: styles.title },
                                React.createElement("div", null, "Projects Overview"),
                                React.createElement("div", { className: styles.titleRight },
                                    React.createElement("div", null,
                                        React.createElement(Button, { className: styles.excelButton, size: "xs", onClick: function () { return _this._handleExcelButtonClick(); }, disabled: (_a = this._projectsRef) === null || _a === void 0 ? void 0 : _a.isDataLoading },
                                            React.createElement(FaRegFileExcel, { title: "Export all data" }),
                                            "XLSX")),
                                    React.createElement("div", null,
                                        "Count: ", (_b = this._projectsRef) === null || _b === void 0 ? void 0 :
                                        _b.projectsLength))) },
                            React.createElement("div", { className: styles.body },
                                React.createElement(ProjectsOverview, { ref: function (r) { return r && (_this._projectsRef = r); } }))))))));
    };
    __decorate([
        observable
    ], Projects.prototype, "_projectsRef", void 0);
    __decorate([
        observable
    ], Projects.prototype, "_filters", void 0);
    __decorate([
        action.bound
    ], Projects.prototype, "_saveFilters", null);
    __decorate([
        action.bound
    ], Projects.prototype, "_resetFilters", null);
    __decorate([
        action.bound
    ], Projects.prototype, "_clearFilters", null);
    __decorate([
        action.bound
    ], Projects.prototype, "_filterProjects", null);
    __decorate([
        action.bound
    ], Projects.prototype, "_handleExcelButtonClick", null);
    Projects = __decorate([
        observer
    ], Projects);
    return Projects;
}(React.PureComponent));
