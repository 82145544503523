var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { ConfirmationDialog } from '@app/Widgets/ConfirmationDialog/ConfirmationDialog';
import { Select } from '@app/Components/Select/Select';
import { ConfirmationCellWrapper } from '@app/Components/Grid/ConfirmationCellWrapper/ConfirmationCellWrapper';
import { Modal } from '@app/Components/Modal/Modal';
import { ApprovalApplicationEnum, SeparatedStrings, StatusEnum } from '@app/AppConstants';
import { appStore } from '@app/AppStore';
import { renderApprovalApplication } from './ExternalApprovals';
export var ApprovalApplicationCell = /** @class */ (function (_super) {
    __extends(ApprovalApplicationCell, _super);
    function ApprovalApplicationCell() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._originalValue = _this.props.value.toString();
        _this._value = _this.props.value.toString();
        _this._isChecklistStatusErrorModalOpen = false;
        _this._isDeliverableStatusErrorModalOpen = false;
        _this._isNoAdminAccessErrorModalOpen = false;
        _this._isFromTGToTMSConfirmationModalOpen = false;
        _this._isFromTMSToTGConfirmationModalOpen = false;
        _this._options = [
            {
                label: SeparatedStrings.ApprovalApplicationTeamGate,
                value: ApprovalApplicationEnum.TeamGate
            },
            {
                label: ApprovalApplicationEnum.TMS,
                value: ApprovalApplicationEnum.TMS
            }
        ];
        _this._handleSelectChange = function (value) {
            if (!value)
                return;
            var data = value;
            _this._value = data.value;
        };
        _this._handleSave = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.props.onSave(this.props.deliverable.id, this._value)];
                    case 1:
                        _a.sent();
                        this._originalValue = this._value;
                        this._isFromTGToTMSConfirmationModalOpen = false;
                        this._isFromTMSToTGConfirmationModalOpen = false;
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    ApprovalApplicationCell.prototype._toggleChecklistStatusErrorModalOpen = function () {
        this._isChecklistStatusErrorModalOpen = !this._isChecklistStatusErrorModalOpen;
    };
    ApprovalApplicationCell.prototype._toggleDeliverableStatusErrorModalOpen = function () {
        this._isDeliverableStatusErrorModalOpen = !this._isDeliverableStatusErrorModalOpen;
    };
    ApprovalApplicationCell.prototype._toggleNoAdminAccessErrorModalOpen = function () {
        this._isNoAdminAccessErrorModalOpen = !this._isNoAdminAccessErrorModalOpen;
    };
    ApprovalApplicationCell.prototype._toggleFromTGToTMSConfirmationModalOpen = function () {
        this._value = this._originalValue;
        this._isFromTGToTMSConfirmationModalOpen = !this._isFromTGToTMSConfirmationModalOpen;
    };
    ApprovalApplicationCell.prototype._toggleFromTMSToTGConfirmationModalOpen = function () {
        this._value = this._originalValue;
        this._isFromTMSToTGConfirmationModalOpen = !this._isFromTMSToTGConfirmationModalOpen;
    };
    ApprovalApplicationCell.prototype.render = function () {
        var _a;
        var _this = this;
        var disabled = (_a = this.props, _a.disabled), value = _a.value, deliverable = _a.deliverable;
        var valueChanged = this._value !== this._originalValue;
        var hasPrincipalAdminAccess = appStore.appSecurityModel.hasPrincipalAdminAccess;
        return (React.createElement(React.Fragment, null,
            React.createElement(ConfirmationCellWrapper, { onSave: function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!valueChanged)
                                    return [2 /*return*/];
                                if (!(this._value == ApprovalApplicationEnum.TMS)) return [3 /*break*/, 4];
                                if (!!deliverable.hasData) return [3 /*break*/, 2];
                                return [4 /*yield*/, this._handleSave()];
                            case 1:
                                _a.sent();
                                return [3 /*break*/, 3];
                            case 2:
                                if (deliverable.checklistStatus === StatusEnum.InApproval ||
                                    deliverable.checklistStatus === StatusEnum.Approved) {
                                    this._isChecklistStatusErrorModalOpen = true;
                                    this._value = this._originalValue;
                                    return [2 /*return*/];
                                }
                                else if (deliverable.deliverableStatus === StatusEnum.InApproval ||
                                    deliverable.deliverableStatus === StatusEnum.Approved) {
                                    this._isDeliverableStatusErrorModalOpen = true;
                                    this._value = this._originalValue;
                                    return [2 /*return*/];
                                }
                                else if (deliverable.deliverableStatus === StatusEnum.Draft) {
                                    this._isFromTGToTMSConfirmationModalOpen = true;
                                    return [2 /*return*/];
                                }
                                _a.label = 3;
                            case 3: return [3 /*break*/, 7];
                            case 4:
                                if (!!deliverable.isExternallyConfigured) return [3 /*break*/, 6];
                                return [4 /*yield*/, this._handleSave()];
                            case 5:
                                _a.sent();
                                return [3 /*break*/, 7];
                            case 6:
                                if (!hasPrincipalAdminAccess) {
                                    this._isNoAdminAccessErrorModalOpen = true;
                                    return [2 /*return*/];
                                }
                                else if (deliverable.checklistStatus === StatusEnum.InApproval ||
                                    deliverable.checklistStatus === StatusEnum.Approved) {
                                    this._isChecklistStatusErrorModalOpen = true;
                                    this._value = this._originalValue;
                                    return [2 /*return*/];
                                }
                                else if (deliverable.deliverableStatus === StatusEnum.InApproval ||
                                    deliverable.deliverableStatus === StatusEnum.Approved) {
                                    this._isDeliverableStatusErrorModalOpen = true;
                                    this._value = this._originalValue;
                                    return [2 /*return*/];
                                }
                                else if (deliverable.deliverableStatus === StatusEnum.Draft) {
                                    this._isFromTMSToTGConfirmationModalOpen = true;
                                    return [2 /*return*/];
                                }
                                _a.label = 7;
                            case 7: return [2 /*return*/];
                        }
                    });
                }); }, onCancel: function () {
                    _this._value = _this._originalValue;
                    return;
                }, objectUniqueId: deliverable.uniqueId, disabled: disabled, valueChanged: valueChanged }, function (isEditable, toggleIgnoreDetection) { return (React.createElement("div", { style: { overflow: 'visible' } }, isEditable ? (React.createElement(Select, { options: _this._options, onChange: _this._handleSelectChange, value: _this._options.find(function (x) { var _a; return x.value === ((_a = _this._value) === null || _a === void 0 ? void 0 : _a.toString()); }), onMenuOpen: function () { return toggleIgnoreDetection && toggleIgnoreDetection(true); }, onMenuClose: function () { return toggleIgnoreDetection && toggleIgnoreDetection(false); }, menuPortalTarget: document.body, menuPosition: 'absolute', styles: {
                    menuPortal: function (base) { return (__assign(__assign({}, base), { zIndex: 1050 })); },
                    input: function (base) { return (__assign(__assign({}, base), { lineHeight: '20px' })); }
                } })) : (renderApprovalApplication(value)))); }),
            React.createElement(Modal, { headerTitle: "Error", isOpen: this._isChecklistStatusErrorModalOpen, toggle: this._toggleChecklistStatusErrorModalOpen, footerbuttons: [
                    {
                        onClick: this._toggleChecklistStatusErrorModalOpen,
                        title: 'OK'
                    }
                ] },
                "Changing Approval Application is not possible, as the related Function Checklist is already in status \"",
                deliverable.checklistStatus,
                "\".If changes should be still performed, the Checklist status should be first set back to \"Draft\".",
                !hasPrincipalAdminAccess ? ' Please, contact Administrator.' : ''),
            React.createElement(Modal, { headerTitle: "Error", isOpen: this._isDeliverableStatusErrorModalOpen, toggle: this._toggleDeliverableStatusErrorModalOpen, footerbuttons: [
                    {
                        onClick: this._toggleDeliverableStatusErrorModalOpen,
                        title: 'OK'
                    }
                ] },
                "Changing Approval Application is not possible, as the related Deliverable is already in status \"",
                deliverable.deliverableStatus,
                "\".If changes should be still performed, the Deliverable status should be first set back to \"Draft\".",
                !hasPrincipalAdminAccess ? ' Please, contact Administrator.' : ''),
            React.createElement(Modal, { headerTitle: "Error", isOpen: this._isNoAdminAccessErrorModalOpen, toggle: this._toggleNoAdminAccessErrorModalOpen, footerbuttons: [
                    {
                        onClick: this._toggleNoAdminAccessErrorModalOpen,
                        title: 'OK'
                    }
                ] }, "Changing Approval Application is not possible, as the the link between TMS and TeamGate applications is already established. If changes should be still performed, please, contact Administrator to perform them."),
            React.createElement(ConfirmationDialog, { isOpen: this._isFromTGToTMSConfirmationModalOpen, onToggle: this._toggleFromTGToTMSConfirmationModalOpen, headerTitle: 'Confirmation', onSave: this._handleSave, body: 'Changing Approval Application to "TMS" will cause deletion of all the input provided to the Deliverable in the TeamGate application. Do you want to continue?' }),
            React.createElement(ConfirmationDialog, { isOpen: this._isFromTMSToTGConfirmationModalOpen, onToggle: this._toggleFromTMSToTGConfirmationModalOpen, headerTitle: 'Confirmation', onSave: this._handleSave, body: 'Changing Approval Application will break the link to the TMS application, which was already established, all corresponding data will be lost within the TeamGate application. Do you really want to continue?' })));
    };
    __decorate([
        observable
    ], ApprovalApplicationCell.prototype, "_originalValue", void 0);
    __decorate([
        observable
    ], ApprovalApplicationCell.prototype, "_value", void 0);
    __decorate([
        observable
    ], ApprovalApplicationCell.prototype, "_isChecklistStatusErrorModalOpen", void 0);
    __decorate([
        observable
    ], ApprovalApplicationCell.prototype, "_isDeliverableStatusErrorModalOpen", void 0);
    __decorate([
        observable
    ], ApprovalApplicationCell.prototype, "_isNoAdminAccessErrorModalOpen", void 0);
    __decorate([
        observable
    ], ApprovalApplicationCell.prototype, "_isFromTGToTMSConfirmationModalOpen", void 0);
    __decorate([
        observable
    ], ApprovalApplicationCell.prototype, "_isFromTMSToTGConfirmationModalOpen", void 0);
    __decorate([
        action.bound
    ], ApprovalApplicationCell.prototype, "_toggleChecklistStatusErrorModalOpen", null);
    __decorate([
        action.bound
    ], ApprovalApplicationCell.prototype, "_toggleDeliverableStatusErrorModalOpen", null);
    __decorate([
        action.bound
    ], ApprovalApplicationCell.prototype, "_toggleNoAdminAccessErrorModalOpen", null);
    __decorate([
        action.bound
    ], ApprovalApplicationCell.prototype, "_toggleFromTGToTMSConfirmationModalOpen", null);
    __decorate([
        action.bound
    ], ApprovalApplicationCell.prototype, "_toggleFromTMSToTGConfirmationModalOpen", null);
    ApprovalApplicationCell = __decorate([
        observer
    ], ApprovalApplicationCell);
    return ApprovalApplicationCell;
}(React.PureComponent));
