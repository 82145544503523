var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { Logo } from '@app/Components/Logo/Logo';
import { Userbar } from '@app/Components/Userbar/Userbar';
import { Menu } from '@app/Components/Menu/Menu';
import styles from './Header.module.scss';
import { authStore } from '@app/Stores/AuthStore';
var Header = /** @class */ (function (_super) {
    __extends(Header, _super);
    function Header() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Header.prototype.render = function () {
        var _a;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.line },
                React.createElement(Logo, null),
                React.createElement("div", { className: styles.userbar },
                    React.createElement(Userbar, { user: { name: ((_a = authStore.currentUser) === null || _a === void 0 ? void 0 : _a.name) || '', logout: authStore.logout } }))),
            React.createElement("div", { className: styles.line },
                React.createElement(Menu, null))));
    };
    return Header;
}(React.PureComponent));
export { Header };
