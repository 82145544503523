var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import styles from './ApprovedGateViewDescription.module.scss';
import { DATE_OPTIONS } from '@app/Helpers/DateOptions';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Tooltip } from 'devextreme-react';
import HTMLReactParser from 'html-react-parser';
export var ApprovedGateViewDescription = /** @class */ (function (_super) {
    __extends(ApprovedGateViewDescription, _super);
    function ApprovedGateViewDescription() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isTooltipVisible = false;
        return _this;
    }
    ApprovedGateViewDescription.prototype.render = function () {
        var _this = this;
        var gate = this.props.gate;
        return (React.createElement("div", null,
            React.createElement("div", { className: styles.line },
                React.createElement("div", { className: styles.item },
                    React.createElement("div", { className: styles.title }, "Gate Name"),
                    React.createElement("div", { className: styles.data }, gate.displayName)),
                React.createElement("div", { className: styles.item },
                    React.createElement("div", { className: styles.title }, "Gate Length In Weeks"),
                    React.createElement("div", { className: styles.data }, gate.lengthInWeek)),
                React.createElement("div", { className: styles.item },
                    React.createElement("div", { className: styles.title }, "Gate Description"),
                    React.createElement("div", { className: styles.data, id: "gateDescription_".concat(gate.id), onMouseEnter: function () { return (_this._isTooltipVisible = true); }, onMouseLeave: function () { return (_this._isTooltipVisible = false); } }, gate.gateShortDescription),
                    React.createElement(Tooltip, { visible: this._isTooltipVisible, target: "#gateDescription_".concat(gate.id) },
                        React.createElement("span", { className: styles.gateDescriptionTooltipText }, HTMLReactParser(gate.gateDescription || ''))))),
            React.createElement("div", { className: styles.line },
                React.createElement("div", { className: styles.item },
                    React.createElement("div", { className: styles.title }, "Start Date"),
                    React.createElement("div", { className: styles.data }, gate.startDate && gate.startDate.toLocaleDateString('en-GB', DATE_OPTIONS))),
                React.createElement("div", { className: styles.item },
                    React.createElement("div", { className: styles.title }, "Forecast Or Actual Date"),
                    React.createElement("div", { className: styles.data }, gate.forecastOrActualDate.toLocaleDateString('en-GB', DATE_OPTIONS))),
                React.createElement("div", { className: styles.item },
                    React.createElement("div", { className: styles.title }, "Completion Target Date"),
                    React.createElement("div", { className: styles.data }, gate.completionTargetDate &&
                        gate.completionTargetDate.toLocaleDateString('en-GB', DATE_OPTIONS))))));
    };
    __decorate([
        observable
    ], ApprovedGateViewDescription.prototype, "_isTooltipVisible", void 0);
    ApprovedGateViewDescription = __decorate([
        observer
    ], ApprovedGateViewDescription);
    return ApprovedGateViewDescription;
}(React.PureComponent));
