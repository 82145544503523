var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import styles from './JobList.module.scss';
import JobListStore from './Stores/JobListStore';
import { PageTitle } from '@app/Components/PageTitle/PageTitle';
import { Column } from 'devextreme-react/data-grid';
import { Grid } from '@app/Components/Grid/Grid';
import { RiCloseLine, RiRestartLine } from 'react-icons/ri';
import { FiCheck } from 'react-icons/fi';
import { AiOutlineSync } from 'react-icons/ai';
import { JobParametersDialog } from './Components/JobParametersDialog';
import { observable, action } from 'mobx';
import { convertUTCDateToLocalDate, DATE_WITH_TIME_OPTIONS } from '@app/Helpers/DateOptions';
import ErrorBoundary from '@app/Components/ErrorBoundary/ErrorBoundary';
var JobListC = /** @class */ (function (_super) {
    __extends(JobListC, _super);
    function JobListC() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._store = new JobListStore();
        _this.runningJobs = [];
        return _this;
    }
    JobListC.prototype.componentWillUnmount = function () {
        this._store.unload();
    };
    JobListC.prototype.onRunJob = function (job) {
        return __awaiter(this, void 0, void 0, function () {
            var runTriggerInternal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        runTriggerInternal = this._store.runTriggerInternal;
                        this.runJob(job);
                        return [4 /*yield*/, runTriggerInternal(job)];
                    case 1:
                        _a.sent();
                        this.removeRunningJob(job);
                        return [2 /*return*/];
                }
            });
        });
    };
    JobListC.prototype.runJob = function (job) {
        this.runningJobs.push(job);
        this.forceUpdate();
    };
    JobListC.prototype.removeRunningJob = function (job) {
        var ind = this.runningJobs.findIndex(function (j) { return j.id === job.id; });
        if (ind > -1) {
            this.runningJobs.splice(ind, 1);
            this.forceUpdate();
        }
    };
    JobListC.prototype.render = function () {
        var _this = this;
        var _a = this._store, openDialog = _a.openDialog, closeDialog = _a.closeDialog, curentJobTrigger = _a.curentJobTriggerInModal, curentJobName = _a.curentJobName, isModalOpen = _a.isModalOpen, handleSaveClick = _a.handleSaveClick;
        return (React.createElement(ErrorBoundary, null,
            React.createElement("div", { className: styles.container },
                React.createElement(PageTitle, { title: 'JOBS' }),
                React.createElement(Grid, { dataSource: this._store.jobItems },
                    React.createElement(Column, { dataField: "name", caption: "Job Name", width: 350 }),
                    React.createElement(Column, { caption: "Schedule", width: 150, cellRender: function (_a) {
                            var data = _a.data;
                            var schedules = [];
                            var _loop_1 = function (i) {
                                var trigger = data.triggers[i];
                                schedules.push(React.createElement("span", { key: trigger.id, onClick: function () { return openDialog(trigger, data.name); } },
                                    trigger.cronSchedule,
                                    React.createElement("br", null)));
                            };
                            for (var i = 0; i < data.triggers.length; i++) {
                                _loop_1(i);
                            }
                            return React.createElement("div", null, schedules);
                        } }),
                    React.createElement(Column, { caption: "Parameters", dataField: "parameters", width: 330, cellRender: function (_a) {
                            var data = _a.data;
                            var parameters = data.parameters;
                            var params = parameters.map(function (p) { return p.name + ':' + p.value; }).join(';');
                            return params;
                        } }),
                    React.createElement(Column, { width: 50, caption: "Active", alignment: "center", cellRender: function (_a) {
                            var data = _a.data;
                            var schedules = [];
                            for (var i = 0; i < data.triggers.length; i++) {
                                schedules.push(data.triggers[i].isActive ? (React.createElement("div", { key: data.triggers[i].id },
                                    React.createElement(FiCheck, { color: "green" }))) : (React.createElement("div", { key: data.triggers[i].id },
                                    React.createElement(RiCloseLine, { color: "red" }))));
                            }
                            return React.createElement("div", null, schedules);
                        } }),
                    React.createElement(Column, { width: 130, caption: "Last Status", dataField: "lastStatus" }),
                    React.createElement(Column, { width: 130, caption: "Previous Start", dataField: "lastRunDateTimeUtc", alignment: "center", cellRender: function (_a) {
                            var data = _a.data;
                            var lastRunDateTimeUtc = data.lastRunDateTimeUtc;
                            return lastRunDateTimeUtc
                                ? convertUTCDateToLocalDate(lastRunDateTimeUtc).toLocaleDateString('en-GB', DATE_WITH_TIME_OPTIONS)
                                : 'never';
                        } }),
                    React.createElement(Column, { width: 130, caption: "Next Start", dataField: "nextRunDateTimeUtc", alignment: "center", cellRender: function (_a) {
                            var data = _a.data;
                            var nextRunDateTimeUtc = data.nextRunDateTimeUtc;
                            return nextRunDateTimeUtc
                                ? convertUTCDateToLocalDate(nextRunDateTimeUtc).toLocaleDateString('en-GB', DATE_WITH_TIME_OPTIONS)
                                : 'never';
                        } }),
                    React.createElement(Column, { minWidth: 150, caption: "Actions", 
                        // alignment="center"
                        cellRender: function (_a) {
                            var data = _a.data;
                            var isRunning = _this.runningJobs.findIndex(function (j) { return j.id === data.id; }) > -1;
                            if (isRunning) {
                                return (React.createElement(React.Fragment, null,
                                    React.createElement(AiOutlineSync, { color: "blue" }),
                                    ' ',
                                    React.createElement("span", { style: { marginLeft: 10 } }, "Running...")));
                            }
                            else {
                                return (React.createElement(React.Fragment, null,
                                    React.createElement(RiRestartLine, { color: "green" }),
                                    React.createElement("span", { style: { marginLeft: 10, cursor: 'pointer' }, onClick: function () { return _this.onRunJob(data); } }, "Run")));
                            }
                        } })),
                isModalOpen && curentJobTrigger && (React.createElement(JobParametersDialog, { showTriggerState: true, jobTrigger: curentJobTrigger, jobName: curentJobName, onSave: handleSaveClick, onClose: closeDialog, isOpen: isModalOpen })))));
    };
    __decorate([
        observable
    ], JobListC.prototype, "runningJobs", void 0);
    __decorate([
        action.bound
    ], JobListC.prototype, "runJob", null);
    __decorate([
        action.bound
    ], JobListC.prototype, "removeRunningJob", null);
    JobListC = __decorate([
        observer
    ], JobListC);
    return JobListC;
}(React.Component));
export var JobList = withRouter(JobListC);
