var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import styles from './MeetingMilestoneGateView.module.scss';
import { withRouter, Switch, Route } from 'react-router-dom';
import { Button } from '@app/Components/Button/Button';
import { Api } from '@app/Api';
import { DecisionTable } from '../GateMeetings/DecisionTable/DecisionTable';
import ActionItemsTable from '../Components/ActionItemsTable/ActionItemsTable';
import { ChecklistSections, GateProcessTypeEnum, ProjectType } from '@app/AppConstants';
import { MilestoneExecutiveQuestionsTable } from './MilestoneExecutiveQuestionsTable/MilestoneExecutiveQuestionsTable';
import { MilestoneDeliversTable } from './MilestoneDeliversTable/MilestoneDeliversTable';
import { Meeting } from '@app/Widgets/Meeting/Meeting';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { DocumentView } from '@app/Widgets/DocumentView/DocumentView';
import { ProjectStateContext } from '../../Components/ProjectStateContext';
var MeetingMilestoneGateViewC = /** @class */ (function (_super) {
    __extends(MeetingMilestoneGateViewC, _super);
    function MeetingMilestoneGateViewC() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isFilesChanged = false;
        _this._milestoneDeliversTable = React.createRef();
        _this._documentViewSection = ChecklistSections.ProjectManager;
        _this.refreshActionItems = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                document.dispatchEvent(new Event('refreshActionItems'));
                return [2 /*return*/];
            });
        }); };
        _this._handleRefresh = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.props.onRefreshChecklistStatusOverview(),
                            (_a = this._milestoneDeliversTable.current) === null || _a === void 0 ? void 0 : _a.reload()
                        ])];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    MeetingMilestoneGateViewC.prototype._closeMeetingModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, history, match;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, history = _a.history, match = _a.match;
                        return [4 /*yield*/, this.props.onRefresh()];
                    case 1:
                        _b.sent();
                        history.push("".concat(match.url));
                        return [2 /*return*/];
                }
            });
        });
    };
    MeetingMilestoneGateViewC.prototype._handleDocumentClick = function (cliId, section) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._documentViewSection = section;
                this.props.history.push("".concat(this.props.match.url, "/upload/cl-").concat(cliId));
                return [2 /*return*/];
            });
        });
    };
    MeetingMilestoneGateViewC.prototype.render = function () {
        var _this = this;
        var _a = this.props, gate = _a.gate, projectId = _a.projectId, match = _a.match, projectState = _a.projectState, projectType = _a.projectType, projectCodeName = _a.projectCodeName, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted, onRefreshChecklistStatusOverview = _a.onRefreshChecklistStatusOverview;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.wrapper },
                React.createElement("div", { className: styles.table },
                    gate.canMakeDecision && (React.createElement("div", { className: styles.decisionWrapper },
                        React.createElement("div", { className: styles.tableTitle }, "Decision"),
                        !gate.hasMeeting && (React.createElement(Button, { size: "sm", className: styles.decisionButtn, onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                                var res;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, Api.secured.createGateMeeting(gate.id)];
                                        case 1:
                                            res = _a.sent();
                                            if (!res.error) {
                                                gate.hasMeeting = true;
                                            }
                                            return [2 /*return*/];
                                    }
                                });
                            }); }, disabled: isProjectTerminatedOrCompleted }, "Add Decision")))),
                    gate.hasMeeting && (React.createElement(DecisionTable, { gateId: gate.id, isMilestoneProject: projectType === ProjectType.Milestone, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted }))),
                React.createElement("div", { className: styles.table },
                    React.createElement(ActionItemsTable, { projectId: projectId, gateId: gate.id, gateProcessType: GateProcessTypeEnum.GateMeeting, gateProcessTypeForCreation: GateProcessTypeEnum.GateMeeting, canAdd: gate.canCreateGateAssessmentActionItems || gate.canCreateGateMeetingActionItems, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })),
                React.createElement(ProjectStateContext.Consumer, null, function (projectStateContext) {
                    return (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: styles.table },
                            React.createElement("div", { className: styles.tableTitle }, "Deliverables"),
                            React.createElement(MilestoneDeliversTable, { ref: _this._milestoneDeliversTable, projectId: projectId, gateId: gate.id, gateName: gate.displayName, projectCodeName: projectCodeName, onDocumentIconClick: _this._handleDocumentClick, projectState: projectState, hideNAItems: projectStateContext.hideNAItems, showRedDotDeliverable: projectStateContext.showRedDotDeliverable, showDocuments: gate.hasApprovedRecommendation, onRefreshActionItems: _this.refreshActionItems, onRefreshChecklistStatusOverview: onRefreshChecklistStatusOverview, isFilesChanged: _this._isFilesChanged, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })),
                        React.createElement("div", { className: styles.table },
                            React.createElement("div", { className: styles.tableTitle }, "Questions / Expectations"),
                            React.createElement(MilestoneExecutiveQuestionsTable, { projectId: projectId, gateId: gate.id, gateName: gate.displayName, projectCodeName: projectCodeName, hideNAItems: projectStateContext.hideNAItems, onRefreshActionItems: _this.refreshActionItems, onRefreshChecklistStatusOverview: onRefreshChecklistStatusOverview, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted }))));
                })),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "".concat(match.url, "/meeting") },
                    React.createElement(Meeting, { onToggle: this._closeMeetingModal, projectId: projectId, gateId: gate.id, gateForecastOrActualDate: gate.forecastOrActualDate, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })),
                React.createElement(Route, { path: "".concat(match.url, "/upload/cl-:id") },
                    React.createElement(DocumentView, { backUrl: "".concat(match.url), section: this._documentViewSection, onFileChange: function () { return (_this._isFilesChanged = !_this._isFilesChanged); }, onClose: this._handleRefresh, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })))));
    };
    __decorate([
        observable
    ], MeetingMilestoneGateViewC.prototype, "_isFilesChanged", void 0);
    __decorate([
        action.bound
    ], MeetingMilestoneGateViewC.prototype, "_closeMeetingModal", null);
    __decorate([
        action.bound
    ], MeetingMilestoneGateViewC.prototype, "_handleDocumentClick", null);
    MeetingMilestoneGateViewC = __decorate([
        observer
    ], MeetingMilestoneGateViewC);
    return MeetingMilestoneGateViewC;
}(React.Component));
export var MeetingMilestoneGateView = withRouter(MeetingMilestoneGateViewC);
