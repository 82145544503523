var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { DateBox } from 'devextreme-react';
var DatePicker = /** @class */ (function (_super) {
    __extends(DatePicker, _super);
    function DatePicker() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DatePicker.prototype.render = function () {
        var _this = this;
        var _a;
        var _b = this.props, onDateChanged = _b.onDateChanged, className = _b.className, customData = _b.customData, dateBoxProps = __rest(_b, ["onDateChanged", "className", "customData"]);
        return (React.createElement(DateBox, __assign({ displayFormat: 'dd MMM yyyy' }, dateBoxProps, { style: __assign({ maxWidth: 265 }, this.props.style), className: className, onValueChanged: function (data) {
                var _a, _b;
                onDateChanged === null || onDateChanged === void 0 ? void 0 : onDateChanged(data.value, customData);
                (_b = (_a = _this.props).onValueChanged) === null || _b === void 0 ? void 0 : _b.call(_a, data);
            }, disabledDates: (_a = this.props.disabledDates) !== null && _a !== void 0 ? _a : this._getDisabledDates })));
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    DatePicker.prototype._getDisabledDates = function (args) {
        var dayOfWeek = args.date.getDay();
        if (args.view !== 'month')
            return false;
        var isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
        return isWeekend;
    };
    return DatePicker;
}(React.PureComponent));
export { DatePicker };
