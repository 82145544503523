import { ApiService } from "@app/Api";
var RequestTimelineService = /** @class */ (function () {
    function RequestTimelineService() {
        var _this = this;
        this._interceptor = {
            response: function (res) {
                _this.addTimeline(res.headers['x-request-timeline']);
                return res;
            },
            error: function (err) {
                _this._addErrorTimeline(err);
                throw err;
            }
        };
        if (RequestTimelineService._instance) {
            throw new Error("You can't initialize several instances. Use getInstance instead");
        }
    }
    RequestTimelineService.getInstanse = function () {
        if (!RequestTimelineService._instance) {
            RequestTimelineService._instance = new RequestTimelineService();
        }
        return RequestTimelineService._instance;
    };
    RequestTimelineService.prototype.init = function () {
        ApiService.addResponseInterceptor(this._interceptor);
    };
    RequestTimelineService.prototype.addTimeline = function (rawData) {
        var requestLog = localStorage.getItem('DevToolRequestLog');
        if (requestLog && rawData) {
            var maxLogSize = 1 * 1024 * 1024;
            var newArray = [];
            var existingArray = JSON.parse(requestLog);
            var minIndexToKeep = existingArray.length - 1;
            var size = rawData.length;
            for (; minIndexToKeep >= 0; minIndexToKeep--) {
                if (!existingArray[minIndexToKeep])
                    continue;
                if (size + existingArray[minIndexToKeep].length > maxLogSize)
                    break;
                size += existingArray[minIndexToKeep].length;
            }
            for (var i = minIndexToKeep; i < existingArray.length; i++) {
                if (!existingArray[i])
                    continue;
                newArray.push(existingArray[i]);
            }
            if (newArray.indexOf(rawData) === -1) {
                newArray.push(rawData);
            }
            localStorage.setItem('DevToolRequestLog', JSON.stringify(newArray));
        }
    };
    RequestTimelineService.prototype.readTimeline = function (lastRawLength) {
        var savedRequestsRaw = localStorage.getItem('DevToolRequestLog') || '';
        if (savedRequestsRaw.length === lastRawLength)
            return null;
        var rawRequests = JSON.parse(savedRequestsRaw);
        return rawRequests;
    };
    RequestTimelineService.prototype.clear = function () {
        localStorage.setItem('DevToolRequestLog', JSON.stringify([]));
    };
    RequestTimelineService.prototype.setValue = function (value) {
        localStorage.setItem('DevToolRequestLog', value);
    };
    RequestTimelineService.prototype._addErrorTimeline = function (err) {
        if (err.response && err.response.status !== 401 && err.response.status !== 403) {
            this.addTimeline(err.response.headers['x-request-timeline']);
        }
    };
    return RequestTimelineService;
}());
export var requestTimelineService = RequestTimelineService.getInstanse();
