var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, computed } from 'mobx';
export var CompletionType;
(function (CompletionType) {
    //to track that at least one of operations is still pending
    CompletionType[CompletionType["Pending"] = 0] = "Pending";
    //that operation was completed at least once
    CompletionType[CompletionType["Completed"] = 1] = "Completed";
})(CompletionType || (CompletionType = {}));
export var PromiseCompletion = /** @class */ (function () {
    function PromiseCompletion(type) {
        if (type === void 0) { type = CompletionType.Pending; }
        this._promiseList = [];
        this._executedOnce = false;
        this._waiters = [];
        this._type = type;
    }
    Object.defineProperty(PromiseCompletion.prototype, "isCompleted", {
        get: function () {
            var result = !this._promiseList.length;
            if (this._type === CompletionType.Completed) {
                result = result || this._executedOnce;
            }
            return result;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PromiseCompletion.prototype, "isPending", {
        get: function () {
            return !!this._promiseList.length;
        },
        enumerable: false,
        configurable: true
    });
    PromiseCompletion.prototype.add = function (callback) {
        var promise = callback();
        void this.subscribe(promise);
        return promise;
    };
    PromiseCompletion.prototype.subscribe = function (promise) {
        var _this = this;
        if (this._promiseList.indexOf(promise) !== -1) {
            throw new Error('Promise is already registered!');
        }
        this._promiseList.push(promise);
        promise.then(function () { return _this._complete(promise); }, function () { return _this._complete(promise); });
    };
    PromiseCompletion.prototype._complete = function (promise) {
        var _this = this;
        window.setTimeout(function () {
            var index = _this._promiseList.indexOf(promise);
            if (index === -1)
                throw new Error('Promise is not registered!');
            _this._promiseList.splice(index, 1);
            _this._executedOnce = true;
            if (!_this._promiseList.length) {
                var waiters = _this._waiters.slice(0);
                _this._waiters.length = 0;
                waiters.forEach(function (w) { return w(); });
            }
        }, 0);
    };
    PromiseCompletion.prototype.wait = function () {
        var _this = this;
        if (!this._promiseList.length && this._executedOnce) {
            return Promise.resolve();
        }
        return new Promise(function (resolve) {
            _this._waiters.push(resolve);
        });
    };
    __decorate([
        observable
    ], PromiseCompletion.prototype, "_promiseList", void 0);
    __decorate([
        observable
    ], PromiseCompletion.prototype, "_executedOnce", void 0);
    __decorate([
        computed
    ], PromiseCompletion.prototype, "isCompleted", null);
    __decorate([
        computed
    ], PromiseCompletion.prototype, "isPending", null);
    return PromiseCompletion;
}());
