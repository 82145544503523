var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { FaTrash, FaUndo } from 'react-icons/fa';
import { IoMdDocument } from 'react-icons/io';
import styles from './ProjectDocumentsGrid.module.scss';
import columnSharedStyles from '@app/Components/Grid/SharedStyles/column-shared-styles.module.scss';
import { createUniqueId } from '@app/Helpers/UidHelper/UidHelper';
import { ComponentUrls, ObjectType } from '@app/AppConstants';
import { Button } from '@app/Components/Button/Button';
import { Grid } from '@app/Components/Grid/Grid';
import { Column } from 'devextreme-react/data-grid';
import { EditCell } from '@app/Components/Grid/EditCell/EditCell';
import { DownloadButton } from '@app/Components/DownloadButton/DownloadButton';
import { CopyToClipBoardButton } from '@app/Components/CopyToClipboardButton/CopyToClipboardButton';
import { TextCell } from '@app/Components/Grid/TextCell/TextCell';
import { DocumentCategoryCell } from '@app/Components/Grid/DocumentCategoryCell/DocumentCategoryCell';
import { GateCell } from '@app/Components/Grid/GateCell/GateCell';
import { Route, Switch, withRouter } from 'react-router-dom';
import { ChangeLogDialog } from '@app/Components/Dialogs/ChangeLogDialog';
import { HistoryButton } from '@app/Components/HistoryButton.tsx/HistoryButton';
import { DateTimeBox } from '@app/Widgets/DateTimeBox/DateTimeBox';
var ProjectDocumentsGrid = /** @class */ (function (_super) {
    __extends(ProjectDocumentsGrid, _super);
    function ProjectDocumentsGrid() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._currentProjectDocument = null;
        return _this;
    }
    ProjectDocumentsGrid.prototype._saveFileComment = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.props.onFileCommentSave(rowData)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocumentsGrid.prototype._updateFileName = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.props.onFileNameUpdate(rowData)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocumentsGrid.prototype._updateFileCategory = function (documentId, categoryId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.props.onFileCategoryUpdate(documentId, categoryId)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocumentsGrid.prototype._updateFileGate = function (documentId, gateId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.props.onFileGateUpdate(documentId, gateId)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocumentsGrid.prototype._documentDeleteClickHandler = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.props.onDelete(id)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocumentsGrid.prototype._documentRestoreClickHandler = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.props.onRestore(id)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocumentsGrid.prototype._toggleLogModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, history, match;
            return __generator(this, function (_b) {
                _a = this.props, history = _a.history, match = _a.match;
                history.push(match.url);
                this._currentProjectDocument = null;
                return [2 /*return*/];
            });
        });
    };
    ProjectDocumentsGrid.prototype.render = function () {
        var _this = this;
        var _a = this.props, loadingDocuments = _a.loadingDocuments, documents = _a.documents, projectId = _a.projectId, gateOptions = _a.gateOptions, documentCategoryOptions = _a.documentCategoryOptions, match = _a.match, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted;
        var rows = documents || [];
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid, { dataSource: __spreadArray([], rows, true), expandableRows: true, rowsCount: rows.length, loading: loadingDocuments },
                React.createElement(Column, { caption: "", dataField: "id", width: 30, alignment: "center", cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(HistoryButton, { onClick: function () { return (_this._currentProjectDocument = data); }, to: "".concat(match.url, "/log") }));
                    } }),
                React.createElement(Column, { cssClass: columnSharedStyles.commentColumn, caption: "Name", dataField: "name", minWidth: 200, cellRender: function (_a) {
                        var _b;
                        var data = _a.data;
                        return (data.fileInformationId && (React.createElement(TextCell, { value: (_b = data.name) !== null && _b !== void 0 ? _b : '', onChange: function (value) {
                                data.name = value || '';
                            }, onSave: function () { return _this._updateFileName(data); }, objectUniqueId: createUniqueId(ObjectType.FileInformation, data.fileInformationId), disabled: !data.canPrincipalUpdateProjectDocument || isProjectTerminatedOrCompleted })));
                    } }),
                React.createElement(Column, { cssClass: columnSharedStyles.commentColumn, caption: "Gate", dataField: "gateDisplayName", minWidth: 200, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(GateCell, { options: gateOptions, defaultValue: data.gateId ? '' + data.gateId : '', defaultLabel: data.gateDisplayName, projectId: projectId, onSave: function (option) { return _this._updateFileGate(data.id, option === null || option === void 0 ? void 0 : option.value); }, objectUniqueId: createUniqueId(ObjectType.ProjectDocument, data.id), disabled: !data.canPrincipalUpdateProjectDocument || isProjectTerminatedOrCompleted }));
                    } }),
                React.createElement(Column, { cssClass: columnSharedStyles.commentColumn, caption: "Category", dataField: "documentCategoryName", minWidth: 200, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(DocumentCategoryCell, { options: documentCategoryOptions, defaultValue: data.documentCategoryId ? '' + data.documentCategoryId : '', defaultLabel: data.documentCategoryName, projectId: projectId, onSave: function (option) { return _this._updateFileCategory(data.id, option === null || option === void 0 ? void 0 : option.value); }, objectUniqueId: createUniqueId(ObjectType.ProjectDocument, data.id), disabled: !data.canPrincipalUpdateProjectDocument || isProjectTerminatedOrCompleted }));
                    } }),
                React.createElement(Column, { caption: "Original File Name", minWidth: 200, dataField: "originalFileName" }),
                React.createElement(Column, { cssClass: columnSharedStyles.commentColumn, caption: "Comment", width: 300, dataField: "FileComment", cellRender: function (_a) {
                        var data = _a.data;
                        return (data.fileInformationId && (React.createElement(EditCell, { value: data.comment, onChange: function (value) { return (data.comment = value); }, onSave: function () { return _this._saveFileComment(data); }, objectUniqueId: createUniqueId(ObjectType.FileInformation, data.fileInformationId), disabled: !data.canPrincipalUpdateProjectDocument || isProjectTerminatedOrCompleted })));
                    } }),
                React.createElement(Column, { caption: "Uploaded Date", dataField: "uploadedDate", dataType: "date", width: 150, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(DateTimeBox, { dateUTC: data.uploadedDate }));
                    } }),
                React.createElement(Column, { caption: "Uploaded By", dataField: "uploadedBy", width: 150 }),
                React.createElement(Column, { cssClass: columnSharedStyles.commentColumn, caption: "Status", dataField: "documentIsDeleted", width: 80, cellRender: function (_a) {
                        var data = _a.data;
                        return data.documentIsDeleted ? 'Deleted' : 'Active';
                    } }),
                React.createElement(Column, { caption: "Version", dataField: "version", width: 100 }),
                React.createElement(Column, { caption: "Action", width: 300, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("div", { className: styles.actions },
                            !data.documentIsDeleted && (React.createElement(React.Fragment, null,
                                React.createElement(Button, { className: styles.action, color: "primary", size: "xs", onClick: function () { return _this.props.onDocumentClick(data); } },
                                    React.createElement(IoMdDocument, { title: "Manage project document" })),
                                data.fileInformationId && (React.createElement(DownloadButton, { fileId: data.fileInformationId, className: styles.action })),
                                data.canPrincipalUpdateProjectDocument && !isProjectTerminatedOrCompleted && (React.createElement(Button, { className: styles.action, color: "danger", size: "xs", onClick: function () { return _this._documentDeleteClickHandler(data.id); } },
                                    React.createElement(FaTrash, { title: "Delete project document with all files" }))),
                                data.fileInformationId && (React.createElement(CopyToClipBoardButton, { text: location.origin + ComponentUrls.DocumentUrl + data.fileInformationId, copyText: "Copy file link to clipboard" })))),
                            data.canPrincipalRestoreProjectDocument && (React.createElement(Button, { className: styles.action, size: "xs", onClick: function () { return _this._documentRestoreClickHandler(data.id); } },
                                React.createElement(FaUndo, { title: "Restore project document" })))));
                    } })),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "".concat(match.url, "/log") }, this._currentProjectDocument && (React.createElement(ChangeLogDialog, { onToggle: this._toggleLogModal, uniqueId: createUniqueId(ObjectType.ProjectDocument, this._currentProjectDocument.id) }))))));
    };
    __decorate([
        observable
    ], ProjectDocumentsGrid.prototype, "_currentProjectDocument", void 0);
    __decorate([
        action.bound
    ], ProjectDocumentsGrid.prototype, "_saveFileComment", null);
    __decorate([
        action.bound
    ], ProjectDocumentsGrid.prototype, "_updateFileName", null);
    __decorate([
        action.bound
    ], ProjectDocumentsGrid.prototype, "_updateFileCategory", null);
    __decorate([
        action.bound
    ], ProjectDocumentsGrid.prototype, "_updateFileGate", null);
    __decorate([
        action.bound
    ], ProjectDocumentsGrid.prototype, "_documentDeleteClickHandler", null);
    __decorate([
        action.bound
    ], ProjectDocumentsGrid.prototype, "_documentRestoreClickHandler", null);
    __decorate([
        action.bound
    ], ProjectDocumentsGrid.prototype, "_toggleLogModal", null);
    ProjectDocumentsGrid = __decorate([
        observer
    ], ProjectDocumentsGrid);
    return ProjectDocumentsGrid;
}(React.PureComponent));
export default withRouter(ProjectDocumentsGrid);
