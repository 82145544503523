var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ApiUrls, SignalREvents } from '@app/AppConstants';
import ApiService from '@app/Services/ApiService';
import { devInfoStore } from '@app/Stores/DevInfoStore';
import { signalRService } from '@app/Services/SignalRService';
import styles from './ToastNotification.module.scss';
var NotificationContainer = /** @class */ (function (_super) {
    __extends(NotificationContainer, _super);
    function NotificationContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NotificationContainer.prototype.render = function () {
        return (React.createElement(ToastContainer, { position: "bottom-right", className: styles.container, bodyClassName: styles.content, closeButton: true }));
    };
    return NotificationContainer;
}(React.Component));
export { NotificationContainer };
var NotificationHandler = /** @class */ (function () {
    function NotificationHandler() {
    }
    NotificationHandler.subscribeToNotifications = function () {
        signalRService.subscribe(SignalREvents.success, NotificationHandler.onOperationSuccessHandler);
        signalRService.subscribe(SignalREvents.warning, NotificationHandler.onOperationWarningHandler);
        signalRService.subscribe(SignalREvents.error, NotificationHandler.onOperationErrorHandler);
        signalRService.subscribe(SignalREvents.info, NotificationHandler.onOperationInfoHandler);
    };
    NotificationHandler.onOperationSuccessHandler = function (eventName, message) {
        NotificationHandler.showSuccess(message);
    };
    NotificationHandler.onOperationWarningHandler = function (eventName, message) {
        NotificationHandler.showWarning(message);
    };
    NotificationHandler.onOperationErrorHandler = function (eventName, message) {
        NotificationHandler.showError(message);
    };
    NotificationHandler.onOperationInfoHandler = function (eventName, message) {
        NotificationHandler.showInfo(message);
    };
    NotificationHandler.showConsoleError = function (errorParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var msg, url, lineNo, columnNo, error, string, message, params;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        msg = errorParameters[0];
                        url = errorParameters[1];
                        lineNo = errorParameters[2];
                        columnNo = errorParameters[3];
                        error = errorParameters[4];
                        string = (msg + '').toLowerCase();
                        message = 'script error';
                        if (string.indexOf(message) > -1) {
                            message = 'Script Error: See Browser Console for Detail';
                        }
                        else {
                            message = [
                                'Message: ' + msg,
                                'URL: ' + (url || ''),
                                'Line: ' + (lineNo || ''),
                                'Column: ' + (columnNo || ''),
                                'Error object: ' + JSON.stringify(error)
                            ].join(' - ');
                        }
                        if (devInfoStore.isDevelopment) {
                            NotificationHandler.showError(message);
                        }
                        params = {
                            message: msg,
                            url: window.location.href,
                            source: url || '' + ' Line: ' + lineNo + ' Column: ' + columnNo
                        };
                        return [4 /*yield*/, ApiService.postData(ApiUrls.ErrorUrl, params)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    NotificationHandler.showError = function (message, options) {
        toast.error(message, options);
    };
    NotificationHandler.showInfo = function (message, options) {
        toast.info(message, options);
    };
    NotificationHandler.showWarning = function (message, options) {
        toast.warning(message, options);
    };
    NotificationHandler.showSuccess = function (message, options) {
        toast.success(message, options);
    };
    NotificationHandler.clear = function () {
        toast.clearWaitingQueue();
    };
    return NotificationHandler;
}());
export { NotificationHandler };
