var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { FaStar, FaRegStar } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import styles from './FavoriteToggler.module.scss';
import { Sizes } from '@app/AppConstants';
var FavouriteToggler = /** @class */ (function (_super) {
    __extends(FavouriteToggler, _super);
    function FavouriteToggler() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FavouriteToggler.prototype.render = function () {
        var _a = this.props, checked = _a.checked, onToggle = _a.onToggle;
        return (React.createElement("div", { onClick: function () { return onToggle(!checked); }, className: styles.container },
            React.createElement(IconContext.Provider, { value: { style: { verticalAlign: 'unset', cursor: 'pointer' } } }, checked ? (React.createElement(FaStar, { color: "gold", size: Sizes.IconSize })) : (React.createElement(FaRegStar, { color: "gold", size: Sizes.IconSize })))));
    };
    return FavouriteToggler;
}(React.PureComponent));
export { FavouriteToggler };
