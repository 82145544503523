import React from 'react';
import ReactDOM from 'react-dom';
import { AppWrapper } from '@app/AppWrapper';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
// import './fonts/fonts.css';
import './reset.css';
import './dxoverwrite.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
//import 'devextreme/dist/css/dx.common.css';
//import 'devextreme/dist/css/dx.light.compact.css';
ReactDOM.render(React.createElement(AppWrapper, null), document.getElementById('root'));
