/* eslint-disable prefer-rest-params */
/* eslint-disable no-var */

export const initAdal = () => {
    window.adal = function() {
        var windowStop = function() {
            try {
                window.stop();
            } catch (exception) {
                document.execCommand('Stop');
            }
        };

        var b64DecodeUnicode = function(str) {
            return decodeURIComponent(
                Array.prototype.map
                    .call(atob(str), function(c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join('')
            );
        };

        var parseJwtBody = function(tokenBody) {
            return JSON.parse(b64DecodeUnicode(tokenBody.replace('-', '+').replace('_', '/')));
        };

        function guid() {
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            }

            return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
        }

        var getTokenPayload = function(token) {
            if (!token) return null;

            var tokenParts = token.split('.');
            if (tokenParts.length === 3) {
                var payload = parseJwtBody(tokenParts[1]);
                if (payload && payload.roles && payload.roles.length) {
                    const roles = [];
                    payload.roles.forEach(function(item) {
                        const splittedRoles = item.split(',');
                        splittedRoles.forEach(function(role) {
                            if (roles.indexOf(role) === -1) {
                                roles.push(role);
                            }
                        });
                    });
                    payload.roles = roles;
                }
                return payload;
            }
            return null;
        };

        var isTokenValid = function(token) {
            var payload = getTokenPayload(token);
            if (!payload) return false;
            var nowUtc = Date.now();
            var expireAt = new Date(payload.exp * 1000);
            if (expireAt <= nowUtc) {
                return false;
            }

            return true;
        };

        var redirectToLogin = function(email) {
            if (window.navigator.onLine) {
                window.removeEventListener('online', redirectToLogin);
                var config = window.adalConfig;
                var nonce = guid();

                if (
                    window.location.href !== config.postLogoutRedirectUri &&
                    window.location.href.indexOf('error') < 0
                ) {
                    localStorage.setItem('redirectUri', window.location.href);
                }

                var url =
                    config.aadInstance +
                    '/oauth2/authorize?response_type=id_token' +
                    '&client_id=' +
                    config.clientId +
                    '&redirect_uri=' +
                    config.postLoginRedirectUri +
                    '&nonce=' +
                    nonce +
                    (email ? '&login_hint=' + email : '');
                window.stop();
                window.location.href = url;
            } else {
                window.addEventListener('online', redirectToLogin);
            }
        };

        var refreshToken = function(email) {
            var config = window.adalConfig;
            var nonce = guid();
            var url =
                config.aadInstance +
                '/oauth2/authorize?response_type=id_token' +
                '&client_id=' +
                config.clientId +
                '&redirect_uri=' +
                config.postLoginRedirectUri +
                '&prompt=none&nonce=' +
                nonce +
                (email ? '&login_hint=' + email : '');

            var existingIframe = document.getElementById('refreshTokenIframe');
            if (existingIframe) {
                existingIframe.outerHTML = '';
            }
            var iframe = document.createElement('iframe');
            iframe.src = url;
            iframe.style.display = 'none';
            iframe.name = 'refreshTokenIframe';
            iframe.id = 'refreshTokenIframe';
            document.body.appendChild(iframe);
        };

        var redirectToLogout = function() {
            var config = window.adalConfig;
            var url =
                config.aadInstance + '/oauth2/logout?' + 'post_logout_redirect_uri=' + config.postLogoutRedirectUri;
            window.location.href = url;
        };

        if (navigator.userAgent.indexOf('Trident') !== -1) {
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = '/js/unsupported.js';
            document.head.appendChild(script);
            return;
        }

        var tokenParameter = 'id_token=';
        var token = null;
        var tokenHash = window.location.hash;
        if (tokenHash.indexOf('#') === 0) {
            tokenHash = tokenHash.substr(1);
        }
        if (tokenHash && tokenHash.indexOf(tokenParameter) === 0) {
            var nextParamPos = tokenHash.indexOf('&');
            token = tokenHash.substring(tokenParameter.length, nextParamPos === -1 ? void 0 : nextParamPos);
            history.replaceState({}, document.title, '.');
        } else {
            var tokenJson = localStorage.getItem('currentToken');
            if (tokenJson) {
                token = JSON.parse(tokenJson);
            }
        }

        var isIframe = !!window.frameElement;

        if (!isTokenValid(token)) {
            const currentUser = getTokenPayload(token);
            redirectToLogin(
                currentUser && (currentUser.email || currentUser.unique_name)
                    ? currentUser.email || currentUser.unique_name
                    : undefined
            );
        } else {
            var currentUser = getTokenPayload(token);
            localStorage.setItem('currentToken', JSON.stringify(token));
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
            var impersonated = JSON.parse(localStorage.getItem('impersonatedUser'));
            window.ReactNativeWebView &&
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({ userId: impersonated ? impersonated.email : currentUser.email })
                );
            if (isIframe) {
                windowStop();
            }
            var redirectUri = localStorage.getItem('redirectUri');
            if (redirectUri) {
                window.location.href = redirectUri;
                localStorage.removeItem('redirectUri');
            }
        }

        if (!isIframe) {
            var minute = 60 * 1000;
            var getTimeFromUnixTime = function(time) {
                return new Date(time * 1000).getTime();
            };
            setInterval(function() {
                var userJson = localStorage.getItem('currentUser');
                var user = userJson ? JSON.parse(userJson) : null;
                if (!user) {
                    redirectToLogin(
                        currentUser && (currentUser.email || currentUser.unique_name)
                            ? currentUser.email || currentUser.unique_name
                            : undefined
                    );
                    return;
                }
                var exp = Math.round((getTimeFromUnixTime(user.exp) - getTimeFromUnixTime(user.iat)) / minute);
                var now = Math.round((Date.now() - getTimeFromUnixTime(user.iat)) / minute);
                if (
                    Math.round(exp / 2) === now ||
                    now === exp - Math.round(exp / 4) ||
                    (now > exp - Math.round(exp / 4) && now % 5 === 0)
                ) {
                    refreshToken(user && (user.email || user.unique_name) ? user.email || user.unique_name : undefined);
                }
            }, minute);
        }

        this.redirectToLogin = redirectToLogin;
        this.redirectToLogout = redirectToLogout;

        return this;
    }.apply({});

    window.onerror = function() {
        var pendingErrors = (window.__pendingErrors = window.__pendingErrors || []);
        pendingErrors.push(arguments);
    };
};
