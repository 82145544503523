var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observer } from 'mobx-react';
import styles from './edit-cell-wrapper.module.scss';
import { Loader } from '@app/Components/Loader/Loader';
import { AiFillLock } from 'react-icons/ai';
import { SaveCancel } from '@app/Components/SaveCancel/SaveCancel';
import { signalRService } from '@app/Services/SignalRService';
import { SignalREvents } from '@app/AppConstants';
import { Api } from '@app/Api';
import ConfirmationCellWrapperStore, { configCellStore } from './ConfirmationCellWrapperStore';
import { CoordinatedLockStrategy } from './LockStrategies/CoordinatedLockStrategy';
import { ClickDetector } from '@app/Components/ClickDetector/ClickDetector';
export var ConfirmationCellWrapper = /** @class */ (function (_super) {
    __extends(ConfirmationCellWrapper, _super);
    function ConfirmationCellWrapper(props) {
        var _this = _super.call(this, props) || this;
        _this._handleUnLockNotification = function (eventName, data) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.props.objectUniqueId === data.objectUniqueId) {
                    this._toReadMode();
                }
                return [2 /*return*/];
            });
        }); };
        var objectUniqueId = props.objectUniqueId, section = props.section, rowIndex = props.rowIndex, columnIndex = props.columnIndex;
        if (_this.props.rowIndex !== undefined && _this.props.columnIndex !== undefined) {
            configCellStore.setCell(rowIndex, columnIndex);
        }
        _this._lockCell = _this._lockCell.bind(_this);
        _this._toEditMode = _this._toEditMode.bind(_this);
        _this._toReadMode = _this._toReadMode.bind(_this);
        _this._handleSaveClick = _this._handleSaveClick.bind(_this);
        _this._handleCancelClick = _this._handleCancelClick.bind(_this);
        _this._handleClickOutside = _this._handleClickOutside.bind(_this);
        _this._store = new ConfirmationCellWrapperStore(objectUniqueId, section);
        _this._lockStrategy = new CoordinatedLockStrategy(_this._store);
        return _this;
    }
    ConfirmationCellWrapper.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var isCurrentCellInEditing, objectUniqueId, section, lock;
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        signalRService.subscribe(SignalREvents.notifyReleasedObject, this._handleUnLockNotification);
                        isCurrentCellInEditing = configCellStore.cells.find(function (item) { return item.rowIndex === _this.props.rowIndex && item.columnIndex === _this.props.columnIndex && item.editing; });
                        if (!(isCurrentCellInEditing && !this._store.isEditMode)) return [3 /*break*/, 3];
                        objectUniqueId = (_a = this.props, _a.objectUniqueId), section = _a.section;
                        lock = { objectUniqueId: objectUniqueId, section: section };
                        return [4 /*yield*/, Api.secured.unLock(lock)];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this._toEditMode()];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ConfirmationCellWrapper.prototype.componentWillUnmount = function () {
        signalRService.unsubscribe(SignalREvents.notifyReleasedObject, this._handleUnLockNotification);
    };
    ConfirmationCellWrapper.prototype._handleClickOutside = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.props.ignoreDetection)
                            return [2 /*return*/];
                        if (this._store.isDetectionIgnored)
                            return [2 /*return*/];
                        configCellStore.setIsOutsideClick(true);
                        configCellStore.setEditing(this.props.rowIndex, this.props.columnIndex, false);
                        return [4 /*yield*/, this._handleSaveClick(undefined, true)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ConfirmationCellWrapper.prototype._handleSaveClick = function (e, isReseted) {
        return __awaiter(this, void 0, void 0, function () {
            var objectUniqueId, section, lock;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        configCellStore.setIsOutsideClick(false);
                        if (!!this.props.valueChanged) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._handleCancelClick()];
                    case 1: return [2 /*return*/, _b.sent()];
                    case 2:
                        if (!isReseted) {
                            configCellStore.setEditing(this.props.rowIndex, this.props.columnIndex, false);
                        }
                        objectUniqueId = (_a = this.props, _a.objectUniqueId), section = _a.section;
                        lock = { objectUniqueId: objectUniqueId, section: section };
                        return [4 /*yield*/, Api.secured.unLock(lock)];
                    case 3:
                        _b.sent();
                        this._toReadMode();
                        return [4 /*yield*/, this.props.onSave()];
                    case 4:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ConfirmationCellWrapper.prototype._handleCancelClick = function () {
        return __awaiter(this, void 0, void 0, function () {
            var objectUniqueId, section, lock, e_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        objectUniqueId = (_a = this.props, _a.objectUniqueId), section = _a.section;
                        lock = { objectUniqueId: objectUniqueId, section: section };
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, Api.secured.unLock(lock)];
                    case 2:
                        _b.sent();
                        this._toReadMode();
                        this.props.onCancel();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        console.error(e_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ConfirmationCellWrapper.prototype._toEditMode = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (this.props.disabled) {
                            return [2 /*return*/];
                        }
                        if (this._store.isEditMode) {
                            return [2 /*return*/];
                        }
                        // if (this._store.isEditMode || this._store.isLoading || this.props.disabled) return;
                        return [4 /*yield*/, this._lockCell()];
                    case 1:
                        // if (this._store.isEditMode || this._store.isLoading || this.props.disabled) return;
                        _c.sent();
                        this._store.setIsActive(true);
                        return [4 /*yield*/, ((_b = (_a = this.props).onSwitchToEditMode) === null || _b === void 0 ? void 0 : _b.call(_a))];
                    case 2:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ConfirmationCellWrapper.prototype._toReadMode = function () {
        this._store.setLockModel(null);
        // this._store.setIsActive(false);
    };
    ConfirmationCellWrapper.prototype._lockCell = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._lockStrategy.lock()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ConfirmationCellWrapper.prototype.render = function () {
        var _a, _b;
        var _this = this;
        var buttonSidePosition = (_a = this.props, _a.buttonSidePosition), buttonsOverlay = _a.buttonsOverlay;
        if (this._store.isLoading) {
            return (React.createElement("div", { className: styles.loaderWrapper },
                React.createElement(Loader, { style: { width: 45, height: 45 } })));
        }
        if (this._store._showLockScreen) {
            var editorName = (_b = this._store, _b.editorName), sinceDate = _b.sinceDate;
            return (React.createElement("div", { style: { height: '100%' } },
                React.createElement("div", null,
                    React.createElement("div", null,
                        React.createElement(AiFillLock, { size: "30" })),
                    React.createElement("span", null,
                        "This cell is locked for editing by ",
                        editorName,
                        " since ", " ".concat(sinceDate)))));
        }
        if (!this._store.isEditMode) {
            return (React.createElement("div", { className: styles.confirmationCellWrapper },
                this.props.children(this._store.isEditMode, this._store.toggleIgnoreDetection),
                React.createElement("div", { className: styles.overlay, onClick: function () {
                        setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (configCellStore.isOutsideClick) {
                                            return [2 /*return*/];
                                        }
                                        if (this.props.rowIndex !== undefined && this.props.columnIndex !== undefined) {
                                            configCellStore.setEditing(this.props.rowIndex, this.props.columnIndex, true);
                                        }
                                        return [4 /*yield*/, this._toEditMode()];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); }, 0);
                    } })));
        }
        var wrapperStyles = buttonSidePosition ? styles.editModeWrapperPos : styles.editModeWrapper;
        var buttonsWraperClassName = buttonsOverlay ? styles.buttonsOverlay : '';
        return (React.createElement("section", null,
            React.createElement(ClickDetector, { onOutsideClick: this._handleClickOutside, className: wrapperStyles, disabled: this._store.isDetectionIgnored },
                React.createElement("div", { className: styles.contentWrapepr }, this.props.children(this._store.isEditMode, this._store.toggleIgnoreDetection)),
                this._store.isEditMode && (React.createElement("div", { className: buttonsWraperClassName },
                    React.createElement(SaveCancel, { onSave: this._handleSaveClick, onCancel: this._handleCancelClick, noBorder: this.props.noBorderForButtons, disabled: this.props.disabledConfirm }))))));
    };
    ConfirmationCellWrapper = __decorate([
        observer
    ], ConfirmationCellWrapper);
    return ConfirmationCellWrapper;
}(React.PureComponent));
