var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observer } from 'mobx-react';
import { observable, action, computed } from 'mobx';
import { ConfirmationCellWrapper } from '../ConfirmationCellWrapper/ConfirmationCellWrapper';
import { Select } from '@app/Components/Select/Select';
export var GateCell = /** @class */ (function (_super) {
    __extends(GateCell, _super);
    function GateCell() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._selectedValue = undefined;
        _this._originalValue = undefined;
        _this._gates = [];
        _this._handleSelectChange = function (selectedValue) { return __awaiter(_this, void 0, void 0, function () {
            var option;
            return __generator(this, function (_a) {
                if (!selectedValue) {
                    this._selectedValue = null;
                    return [2 /*return*/];
                }
                if (!Array.isArray(selectedValue)) {
                    option = selectedValue;
                    this._selectedValue = option;
                }
                return [2 /*return*/];
            });
        }); };
        return _this;
    }
    GateCell.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._gates = this.props.options;
                this._selectedValue = this._getDefaultOption(this.props.defaultValue);
                this._originalValue = this._selectedValue ? __assign({}, this._selectedValue) : undefined;
                return [2 /*return*/];
            });
        });
    };
    GateCell.prototype._handleCancel = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._selectedValue = this._originalValue ? __assign({}, this._originalValue) : undefined;
                return [2 /*return*/];
            });
        });
    };
    Object.defineProperty(GateCell.prototype, "_dateOptions", {
        get: function () {
            return this._gates.map(function (x) { return ({ label: '' + x.codeName, value: '' + x.id }); });
        },
        enumerable: false,
        configurable: true
    });
    GateCell.prototype._getDefaultOption = function (defaultValue) {
        if (!defaultValue || !this._dateOptions.length)
            return undefined;
        var ou = this._dateOptions.find(function (ou) { return ou.value === defaultValue; });
        return ou ? { value: '' + ou.value, label: '' + ou.label } : undefined;
    };
    GateCell.prototype._handleSave = function () {
        return __awaiter(this, void 0, void 0, function () {
            var option;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this._selectedValue) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.props.onSave()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                    case 2:
                        if (!!Array.isArray(this._selectedValue)) return [3 /*break*/, 4];
                        option = this._selectedValue;
                        return [4 /*yield*/, this.props.onSave(option)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(GateCell.prototype, "_isValueChanged", {
        get: function () {
            var selectedValue = this._selectedValue ? JSON.stringify(this._selectedValue) : null;
            var originalValue = this._originalValue ? JSON.stringify(this._originalValue) : null;
            return selectedValue !== originalValue;
        },
        enumerable: false,
        configurable: true
    });
    GateCell.prototype.render = function () {
        var _a;
        var _this = this;
        var objectUniqueId = (_a = this.props, _a.objectUniqueId), disabled = _a.disabled, defaultLabel = _a.defaultLabel, section = _a.section;
        var changed = this._isValueChanged;
        return (React.createElement(ConfirmationCellWrapper, { onSave: this._handleSave, onCancel: this._handleCancel, objectUniqueId: objectUniqueId, disabled: disabled, valueChanged: changed, section: section }, function (isEditable) {
            return isEditable ? (React.createElement(Select, { menuPosition: 'absolute', menuPortalTarget: document.body, isClearable: true, options: _this._dateOptions, onChange: _this._handleSelectChange, value: _this._selectedValue })) : (React.createElement("div", null, defaultLabel));
        }));
    };
    __decorate([
        observable
    ], GateCell.prototype, "_selectedValue", void 0);
    __decorate([
        observable
    ], GateCell.prototype, "_originalValue", void 0);
    __decorate([
        observable
    ], GateCell.prototype, "_gates", void 0);
    __decorate([
        action.bound
    ], GateCell.prototype, "componentDidMount", null);
    __decorate([
        action.bound
    ], GateCell.prototype, "_handleCancel", null);
    __decorate([
        computed
    ], GateCell.prototype, "_dateOptions", null);
    __decorate([
        action.bound
    ], GateCell.prototype, "_handleSave", null);
    __decorate([
        computed
    ], GateCell.prototype, "_isValueChanged", null);
    GateCell = __decorate([
        observer
    ], GateCell);
    return GateCell;
}(React.PureComponent));
