/* eslint-disable @typescript-eslint/no-explicit-any */
/* tslint:disable:no-any */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action, computed } from 'mobx';
export var BaseFormModel = /** @class */ (function () {
    function BaseFormModel() {
        this.validated = false;
    }
    BaseFormModel.prototype.errorFor = function (name) {
        return this.validationErrorsName(name);
    };
    BaseFormModel.prototype.isValid = function (name) {
        return !this.validationErrorsName(name).length;
    };
    BaseFormModel.prototype.setValue = function (name, value) {
        this[name] = value;
    };
    BaseFormModel.prototype.validationErrorsName = function (name) {
        var errors = this['__validateError_' + name[0].toUpperCase() + name.substr(1)];
        return errors ? errors : [];
    };
    Object.defineProperty(BaseFormModel.prototype, "isFormValid", {
        get: function () {
            return this.__validators ? this.__isValidForm : true;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseFormModel.prototype, "invalidFields", {
        get: function () {
            return this.__errorFields || [];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseFormModel.prototype, "formValidatedClass", {
        get: function () {
            return this.validated ? 'validated' : 'not-validated';
        },
        enumerable: false,
        configurable: true
    });
    BaseFormModel.prototype.validate = function () {
        this.validated = true;
        return this.isFormValid;
    };
    __decorate([
        observable
    ], BaseFormModel.prototype, "validated", void 0);
    __decorate([
        action
    ], BaseFormModel.prototype, "errorFor", null);
    __decorate([
        action
    ], BaseFormModel.prototype, "isValid", null);
    __decorate([
        action
    ], BaseFormModel.prototype, "setValue", null);
    __decorate([
        action
    ], BaseFormModel.prototype, "validationErrorsName", null);
    __decorate([
        computed
    ], BaseFormModel.prototype, "isFormValid", null);
    __decorate([
        computed
    ], BaseFormModel.prototype, "invalidFields", null);
    __decorate([
        computed
    ], BaseFormModel.prototype, "formValidatedClass", null);
    __decorate([
        action
    ], BaseFormModel.prototype, "validate", null);
    return BaseFormModel;
}());
