var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { appStore } from './../AppStore';
import * as signalR from '@microsoft/signalr';
import { ComponentUrls, SignalREvents } from '@app/AppConstants';
import { HttpTransportType } from '@microsoft/signalr';
import DateTimeService from '@app/Services/DateTimeService';
import { authStore } from '@app/Stores/AuthStore';
var SignalRService = /** @class */ (function () {
    function SignalRService() {
        var _this = this;
        this._hubConnection = null;
        this._signalRObservers = [];
        this._initObservers = [];
        this._initialized = false;
        this.initialize = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!appStore.isAuthorized()) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.setUpHub()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        document.addEventListener('DOMContentLoaded', this.initialize);
    }
    SignalRService.prototype.setUpHub = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var connectionUrl, transportType, hubConnection, reconnectMessageTimer, reconnect, response, e_1;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this._initialized = true;
                        connectionUrl = location.origin + ComponentUrls.HubUrl;
                        if ((_a = appStore.impersonatedPrincipal) === null || _a === void 0 ? void 0 : _a.principalId) {
                            connectionUrl +=
                                ComponentUrls.HubImpersonatedPersonIdQueryString + appStore.impersonatedPrincipal.principalId;
                        }
                        transportType = HttpTransportType.LongPolling | HttpTransportType.ServerSentEvents | HttpTransportType.WebSockets;
                        hubConnection = new signalR.HubConnectionBuilder()
                            .withUrl(connectionUrl, {
                            transport: transportType,
                            accessTokenFactory: function () {
                                var token = authStore.currentToken;
                                return token;
                            }
                        })
                            .build();
                        reconnectMessageTimer = 0;
                        reconnect = function () {
                            window.setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.setUpHub()];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); }, 5000); // Restart connection after 5 seconds.
                        };
                        hubConnection.onclose(function () {
                            //globalAjaxLoaderStore.showAppLoader('Connection to server was lost, reconnecting...');
                            reconnect();
                        });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, hubConnection.start()];
                    case 2:
                        _b.sent();
                        if (reconnectMessageTimer) {
                            clearTimeout(reconnectMessageTimer);
                            reconnectMessageTimer = 0;
                        }
                        //globalAjaxLoaderStore.hideAppLoader();
                        console.log('SingalR connected');
                        this._initObservers.forEach(function (cb) { return cb(); });
                        this._hubConnection = hubConnection;
                        return [4 /*yield*/, this._hubConnection.invoke(SignalREvents.getConnectionId)];
                    case 3:
                        response = _b.sent();
                        appStore.signalRConnectionId = response;
                        //this.setupHeartbeat();
                        this._signalRObservers.forEach(function (item) {
                            hubConnection.on(item.eventName, item.internalObserver);
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _b.sent();
                        reconnect();
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    SignalRService.prototype.onInitialized = function (cb) {
        var _this = this;
        this._initObservers.push(cb);
        return function () { return (_this._initObservers = _this._initObservers.filter(function (sub) { return sub !== cb; })); };
    };
    SignalRService.prototype.isInitialized = function () {
        return this._initialized;
    };
    //setupHeartbeat() {
    //    const timeoutInSec = 90 + 30 * Math.random();
    //    window.setTimeout(this.updateHeartbeat, timeoutInSec * 1000);
    //}
    //updateHeartbeat = async () => {
    //    if (this._hubConnection && this._hubConnection.state === signalR.HubConnectionState.Connected) {
    //        this._hubConnection.invoke('UpdateHeartbeat').catch(err => console.error(err));
    //    }
    //    this.setupHeartbeat();
    //};
    SignalRService.prototype.sendNotification = function (methodName, notification) {
        if (!this._hubConnection)
            return;
        this._hubConnection.invoke(methodName, notification).catch(function (err) { return console.error(err); });
    };
    SignalRService.prototype.subscribe = function (eventName, observer) {
        var observerItem = {
            eventName: eventName,
            observer: observer,
            internalObserver: function (payload) { return observer(eventName, DateTimeService.typeCheck(payload)); }
        };
        var connection = this._hubConnection;
        if (connection) {
            connection.on(eventName, observerItem.internalObserver);
        }
        this._signalRObservers.push(observerItem);
    };
    SignalRService.prototype.unsubscribe = function (eventName, observer) {
        var observerRegistration = this._signalRObservers.find(function (item) { return item.eventName === eventName && item.observer === observer; });
        if (observerRegistration) {
            var index = this._signalRObservers.indexOf(observerRegistration);
            this._signalRObservers.splice(index, 1);
            var connection = this._hubConnection;
            if (connection) {
                connection.off(eventName, observerRegistration.internalObserver);
            }
        }
        // else {
        //     throw new Error(`Registration for ${eventName} is missing`);
        // }
    };
    return SignalRService;
}());
export var signalRService = new SignalRService();
