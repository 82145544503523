var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import styles from './ProjectsOverviewGate.module.scss';
import { colorThemesStore } from '@app/Stores/ColorThemesStore';
var ProjectsOverviewGate = /** @class */ (function (_super) {
    __extends(ProjectsOverviewGate, _super);
    function ProjectsOverviewGate() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProjectsOverviewGate.prototype.render = function () {
        var _a = this.props, _b = _a.color, color = _b === void 0 ? colorThemesStore.appColors.lightGray : _b, _c = _a.width, width = _c === void 0 ? 20 : _c, children = _a.children;
        return (React.createElement("div", { className: styles.container, style: { width: width } },
            React.createElement("div", { className: styles.pre }),
            React.createElement("div", { className: styles.body, style: { backgroundColor: color } }),
            React.createElement("div", { className: styles.text }, children),
            React.createElement("div", { className: styles.post, style: { backgroundColor: color } })));
    };
    return ProjectsOverviewGate;
}(React.PureComponent));
export { ProjectsOverviewGate };
