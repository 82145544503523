var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
var DateBox = /** @class */ (function (_super) {
    __extends(DateBox, _super);
    function DateBox() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._monthNames = [
            "January", "February", "March",
            "April", "May", "June",
            "July", "August", "September",
            "October", "November", "December"
        ];
        return _this;
    }
    DateBox.prototype._getMonthName = function (date) {
        return this._monthNames[date.getMonth()];
    };
    DateBox.prototype._getShortMonthName = function (date) {
        return this._getMonthName(date).substr(0, 3);
    };
    ;
    DateBox.prototype.render = function () {
        var date = this.props.date;
        if (!date)
            return null;
        var dateString = "".concat(date.getDate().toString(), " ").concat(this._getShortMonthName(date), " ").concat(date.getFullYear());
        return React.createElement("span", null, dateString);
    };
    return DateBox;
}(React.PureComponent));
export { DateBox };
