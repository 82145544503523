var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { ModalBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import styles from './ErrorModal.module.scss';
import { Modal } from '@app/Components/Modal/Modal';
var ErrorModalC = /** @class */ (function (_super) {
    __extends(ErrorModalC, _super);
    function ErrorModalC() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ErrorModalC.prototype.render = function () {
        var _a = this.props, isOpen = _a.isOpen, toggle = _a.toggle, errorData = _a.errorData;
        return (React.createElement(Modal, { isOpen: isOpen, toggle: toggle, headerTitle: "Error", footerbuttons: this._getFooterButtons() },
            React.createElement(ModalBody, null, errorData.errorMessages)));
    };
    ErrorModalC.prototype._getFooterButtons = function () {
        var _a = this.props, errorData = _a.errorData, projectId = _a.projectId, projectCodeName = _a.projectCodeName, history = _a.history;
        var buttons = [];
        if (errorData.nextGateId && projectId) {
            var goToBtn = {
                className: styles.button,
                color: 'success',
                onClick: function () {
                    return history.push("/projects/".concat(projectCodeName, "/setup/gates/g-").concat(errorData.nextGateId, "/functions"));
                },
                title: 'Go To Gate'
            };
            buttons.push(goToBtn);
        }
        buttons.push({
            onClick: this.props.toggle,
            title: 'Cancel'
        });
        return buttons;
    };
    return ErrorModalC;
}(React.PureComponent));
export var ErrorModal = withRouter(ErrorModalC);
