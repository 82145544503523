var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import styles from './related-cell.module.scss';
import { observable, action, computed } from 'mobx';
import { observer } from 'mobx-react';
import { Select } from '@app/Components/Select/Select';
import { ConfirmationCellWrapper } from '@app/Components/Grid/ConfirmationCellWrapper/ConfirmationCellWrapper';
import { Api } from '@app/Api';
import { PromiseCompletion } from '@app/Classes/PromiseCompletion';
export var RelatedIdCell = /** @class */ (function (_super) {
    __extends(RelatedIdCell, _super);
    function RelatedIdCell() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._checklistItems = [];
        _this._checklistItemsLoader = new PromiseCompletion();
        return _this;
    }
    RelatedIdCell.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var relatedChecklists, checklistsOptions;
            var _a;
            return __generator(this, function (_b) {
                relatedChecklists = (_a = this.props, _a.relatedChecklists), checklistsOptions = _a.checklistsOptions;
                this._relatedChecklistsValue =
                    relatedChecklists && checklistsOptions && this.findOptions(checklistsOptions, relatedChecklists);
                this._relatedChecklistsValueOriginal = this._relatedChecklistsValue;
                return [2 /*return*/];
            });
        });
    };
    Object.defineProperty(RelatedIdCell.prototype, "_checklistItemsOptions", {
        get: function () {
            return this._checklistItems.map(function (ev) { return ({ label: '' + ev.codeName, value: '' + ev.id }); });
        },
        enumerable: false,
        configurable: true
    });
    RelatedIdCell.prototype.findOptions = function (allOptions, ids) {
        var opts = [];
        var _loop_1 = function (i) {
            var option = allOptions.find(function (op) { return +op.value === ids[i].value; });
            if (option) {
                opts.push(option);
            }
            else {
                var opt = { value: '' + ids[i].value, label: '' + ids[i].label };
                opts.push(opt);
            }
        };
        for (var i = 0; i < ids.length; i++) {
            _loop_1(i);
        }
        return opts;
    };
    Object.defineProperty(RelatedIdCell.prototype, "_displayValue", {
        get: function () {
            var value = [];
            if (this.props.relatedChecklistItems) {
                value = this.props.relatedChecklistItems.map(function (relatedChecklistItem) { return relatedChecklistItem.label; });
            }
            return value;
        },
        enumerable: false,
        configurable: true
    });
    RelatedIdCell.prototype._loadChecklistItemsLookupInfo = function () {
        return __awaiter(this, void 0, void 0, function () {
            var relatedChecklists, relatedChecklistItems, ids, _a;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        relatedChecklists = (_b = this.props, _b.relatedChecklists), relatedChecklistItems = _b.relatedChecklistItems;
                        ids = relatedChecklists === null || relatedChecklists === void 0 ? void 0 : relatedChecklists.map(function (x) { return x.value; });
                        _a = this;
                        return [4 /*yield*/, this._checklistItemsLoader.add(function () {
                                return Api.secured.getChecklistItemsLookupInfo({ checkListIds: ids });
                            })];
                    case 1:
                        _a._checklistItems = _c.sent();
                        this._relatedChecklistItemsValue =
                            relatedChecklistItems &&
                                this._checklistItems &&
                                this.findOptions(this._checklistItems.map(function (cli) { return ({ value: '' + cli.id, label: '' + cli.codeName }); }), relatedChecklistItems);
                        this._relatedChecklistItemsValueOriginal = this._relatedChecklistItemsValue;
                        return [2 /*return*/];
                }
            });
        });
    };
    RelatedIdCell.prototype._onSwitchToEditMode = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadChecklistItemsLookupInfo()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    RelatedIdCell.prototype.render = function () {
        var _a;
        var _this = this;
        var checklistsOptions = (_a = this.props, _a.checklistsOptions), objectUniqueId = _a.objectUniqueId, disabled = _a.disabled, columnIndex = _a.columnIndex, rowIndex = _a.rowIndex;
        var itemsValueChanged = this._relatedChecklistItemsValue !== this._relatedChecklistItemsValueOriginal;
        var clValueChanged = this._relatedChecklistsValue !== this._relatedChecklistsValueOriginal;
        return (React.createElement(ConfirmationCellWrapper, { rowIndex: rowIndex, columnIndex: columnIndex, onSave: this._handleSave, onCancel: this._handleCancel, objectUniqueId: objectUniqueId, disabled: disabled, valueChanged: itemsValueChanged || clValueChanged, onSwitchToEditMode: this._onSwitchToEditMode }, function (isEditing, toggleIgnoreDetection) {
            if (isEditing) {
                return (React.createElement("div", { className: styles.relatedEditWrapper },
                    React.createElement("div", { className: styles.relatedEditItemWrapper },
                        React.createElement("div", { className: styles.relatedEditItemTitle }, "Checklists"),
                        React.createElement("div", { className: styles.relatedEditItem },
                            React.createElement(Select, { options: checklistsOptions, onMenuOpen: function () { return toggleIgnoreDetection && toggleIgnoreDetection(true); }, onMenuClose: function () { return toggleIgnoreDetection && toggleIgnoreDetection(false); }, value: _this._relatedChecklistsValue, onChange: _this._handleRelatedChecklistsIdsChange, menuPortalTarget: document.body, menuPosition: 'absolute', isDisabled: !isEditing, isClearable: false, isMulti: true }))),
                    React.createElement("div", { className: styles.relatedEditItemWrapper },
                        React.createElement("div", { className: styles.relatedEditItemTitle }, "Checklist Items"),
                        React.createElement("div", { className: styles.relatedEditItem },
                            React.createElement(Select, { options: _this._checklistItemsOptions, onMenuOpen: function () { return toggleIgnoreDetection && toggleIgnoreDetection(true); }, onMenuClose: function () { return toggleIgnoreDetection && toggleIgnoreDetection(false); }, value: _this._relatedChecklistItemsValue, onChange: _this._handleRelatedChecklistItemsIdsChange, menuPortalTarget: document.body, isLoading: _this._checklistItemsLoader.isPending, isDisabled: !isEditing || _this._checklistItemsLoader.isPending, isClearable: false, isMulti: true })))));
            }
            else {
                return (React.createElement("div", { className: styles.valueWrapper }, _this._displayValue.map(function (v, i) { return v && React.createElement("div", { key: v + i }, v); })));
            }
        }));
    };
    RelatedIdCell.prototype._handleRelatedChecklistsIdsChange = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            var ids_1, _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this._relatedChecklistsValue = value ? value : [];
                        if (!(this._relatedChecklistsValue && Array.isArray(this._relatedChecklistsValue))) return [3 /*break*/, 2];
                        ids_1 = this._relatedChecklistsValue.map(function (el) { return +el.value; });
                        _a = this;
                        return [4 /*yield*/, this._checklistItemsLoader.add(function () {
                                return Api.secured.getChecklistItemsLookupInfo({ checkListIds: ids_1 });
                            })];
                    case 1:
                        _a._checklistItems = _b.sent();
                        if (Array.isArray(this._relatedChecklistItemsValue)) {
                            this._relatedChecklistItemsValue = this._relatedChecklistItemsValue.filter(function (x) {
                                return _this._checklistItems.some(function (y) { return +x.value === y.id; });
                            });
                        }
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    RelatedIdCell.prototype._handleRelatedChecklistItemsIdsChange = function (value) {
        this._relatedChecklistItemsValue = value ? value : [];
    };
    RelatedIdCell.prototype._handleSave = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this._relatedChecklistsValue && Array.isArray(this._relatedChecklistsValue)) {
                            this.props.onRelatedChecklistsIdsChange(this._relatedChecklistsValue.map(function (el) { return ({ value: +el.value, label: el.label }); }));
                        }
                        if (this._relatedChecklistItemsValue && Array.isArray(this._relatedChecklistItemsValue)) {
                            this.props.onRelatedChecklistItemsIdsChange(this._relatedChecklistItemsValue.map(function (el) { return ({ value: +el.value, label: el.label }); }));
                        }
                        this._relatedChecklistItemsValueOriginal = this._relatedChecklistItemsValue;
                        this._relatedChecklistsValueOriginal = this._relatedChecklistsValue;
                        return [4 /*yield*/, this.props.onSave()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    RelatedIdCell.prototype._handleCancel = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._relatedChecklistsValue = this._relatedChecklistsValueOriginal;
                this._relatedChecklistItemsValue = this._relatedChecklistItemsValueOriginal;
                return [2 /*return*/];
            });
        });
    };
    __decorate([
        observable
    ], RelatedIdCell.prototype, "_relatedChecklistsValue", void 0);
    __decorate([
        observable
    ], RelatedIdCell.prototype, "_relatedChecklistsValueOriginal", void 0);
    __decorate([
        observable
    ], RelatedIdCell.prototype, "_relatedChecklistItemsValue", void 0);
    __decorate([
        observable
    ], RelatedIdCell.prototype, "_relatedChecklistItemsValueOriginal", void 0);
    __decorate([
        observable
    ], RelatedIdCell.prototype, "_checklistItems", void 0);
    __decorate([
        observable
    ], RelatedIdCell.prototype, "_checklistItemsLoader", void 0);
    __decorate([
        computed
    ], RelatedIdCell.prototype, "_checklistItemsOptions", null);
    __decorate([
        computed
    ], RelatedIdCell.prototype, "_displayValue", null);
    __decorate([
        action.bound
    ], RelatedIdCell.prototype, "_loadChecklistItemsLookupInfo", null);
    __decorate([
        action.bound
    ], RelatedIdCell.prototype, "_onSwitchToEditMode", null);
    __decorate([
        action.bound
    ], RelatedIdCell.prototype, "_handleRelatedChecklistsIdsChange", null);
    __decorate([
        action.bound
    ], RelatedIdCell.prototype, "_handleRelatedChecklistItemsIdsChange", null);
    __decorate([
        action.bound
    ], RelatedIdCell.prototype, "_handleSave", null);
    __decorate([
        action.bound
    ], RelatedIdCell.prototype, "_handleCancel", null);
    RelatedIdCell = __decorate([
        observer
    ], RelatedIdCell);
    return RelatedIdCell;
}(React.PureComponent));
