var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import styles from './ChecklistStatusIcon.module.scss';
import { StatusEnum } from '@app/AppConstants';
import { observable } from 'mobx';
import { Tooltip } from 'devextreme-react';
import { observer } from 'mobx-react';
import { colorThemesStore } from '@app/Stores/ColorThemesStore';
export var ChecklistStatusIcon = /** @class */ (function (_super) {
    __extends(ChecklistStatusIcon, _super);
    function ChecklistStatusIcon() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isTooltipVisible = false;
        return _this;
    }
    ChecklistStatusIcon.prototype._getDefaultStyles = function () {
        return {
            color: this._getColor()
        };
    };
    ChecklistStatusIcon.prototype._getColor = function () {
        var colors = colorThemesStore.appColors;
        switch (this.props.currentStatus) {
            case StatusEnum.Draft:
                return colors.red;
            case StatusEnum.InApproval:
                return colors.yellow;
            case StatusEnum.Approved:
                return colors.green;
            case StatusEnum.NotApplicable:
                return colors.gray;
            default:
                return colors.red;
        }
    };
    ChecklistStatusIcon.prototype._getLetter = function () {
        switch (this.props.targetStatus) {
            case StatusEnum.Draft:
                return 'D';
            case StatusEnum.Approved:
                return 'A';
            default:
                return '';
        }
    };
    ChecklistStatusIcon.prototype.render = function () {
        var _a;
        var _this = this;
        var checklistId = (_a = this.props, _a.checklistId), currentStatus = _a.currentStatus, targetStatus = _a.targetStatus, className = _a.className, onClick = _a.onClick;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { style: __assign({}, this._getDefaultStyles()), className: className + ' ' + styles.icon, onMouseEnter: function () { return (_this._isTooltipVisible = true); }, onMouseLeave: function () { return (_this._isTooltipVisible = false); }, id: "checklist".concat(checklistId), onClick: onClick }, this._getLetter()),
            React.createElement(Tooltip, { visible: this._isTooltipVisible, target: "#checklist".concat(checklistId) },
                React.createElement("div", { className: styles.infoWrapper },
                    React.createElement("div", null,
                        "Target Status: ",
                        targetStatus),
                    React.createElement("div", null,
                        "Current Status: ",
                        currentStatus)))));
    };
    __decorate([
        observable
    ], ChecklistStatusIcon.prototype, "_isTooltipVisible", void 0);
    ChecklistStatusIcon = __decorate([
        observer
    ], ChecklistStatusIcon);
    return ChecklistStatusIcon;
}(React.PureComponent));
