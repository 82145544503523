import React, { useState, useEffect } from 'react';
import styles from './confirmation-markdown.module.scss';
import { HtmlEditor } from '@app/Components/HtmlEditor/HtmlEditor';
import HTMLReactParser from 'html-react-parser';
import { ClickDetector } from '@app/Components/ClickDetector/ClickDetector';
import { SaveCancelContainer } from '@app/Components/SaveCancelContainer/SaveCancelContainer';
export var ConfirmationMarkdown = function (_a) {
    var value = _a.value, invalid = _a.invalid, onChange = _a.onChange, disabled = _a.disabled, className = _a.className;
    var _b = useState(false), isFocused = _b[0], setIsFocused = _b[1];
    var _c = useState(value), inputValue = _c[0], setInputValue = _c[1];
    useEffect(function () {
        setInputValue(value);
    }, [value]);
    var cssClass = styles.confirmationMarkdown;
    if (isFocused)
        cssClass += " ".concat(styles.focusedInput);
    if (invalid)
        cssClass += " ".concat(styles.invalidInput);
    var wrapperStyles = styles.confirmationMarkdownWrapper;
    if (invalid)
        wrapperStyles += " is-invalid";
    if (className) {
        wrapperStyles += ' ' + className;
    }
    function handleOutsideClick() {
        setIsFocused(false);
        onChange(inputValue);
    }
    function handleSave() {
        setIsFocused(false);
        onChange(inputValue);
    }
    function handleCancel() {
        setIsFocused(false);
        setInputValue(value);
    }
    function toEditMode() {
        !isFocused && !disabled && setIsFocused(true);
    }
    function _handleChange(e) {
        var _a;
        var parts = (_a = e.value) === null || _a === void 0 ? void 0 : _a.split('<p>');
        if (!parts) {
            setInputValue('');
            return;
        }
        var isEmpty = true;
        for (var i = 0; i < parts.length; i++) {
            var p = parts[i];
            if (p.indexOf('</p>') !== -1) {
                p = p.replace('</p>', '');
            }
            if (p.length > 1) {
                // special chars in the end
                isEmpty = false;
                break;
            }
        }
        var value = isEmpty ? '' : e.value;
        setInputValue(value || '');
    }
    return (React.createElement("div", { className: wrapperStyles, onClick: toEditMode },
        React.createElement(SaveCancelContainer, { isActive: isFocused, onCancel: handleCancel, onSave: handleSave },
            React.createElement(ClickDetector, { disabled: !isFocused, onOutsideClick: handleOutsideClick },
                React.createElement("div", { className: cssClass }, disabled || !isFocused ? (React.createElement("div", null, HTMLReactParser(inputValue))) : (React.createElement(HtmlEditor, { value: inputValue, onValueChanged: _handleChange })))))));
};
