var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Label } from 'reactstrap';
import { Input } from '@app/Components/Input/Input';
import styles from './SplitGates.module.scss';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Loader } from '@app/Components/Loader/Loader';
import { Modal } from '@app/Components/Modal/Modal';
import { Select } from '@app/Components/Select/Select';
import { RowLabel } from '@app/Components/FormControls';
import { DatePicker } from '@app/Components/DatePicker';
import { CompletionType, PromiseCompletion } from '@app/Classes/PromiseCompletion';
export var SplitGates = /** @class */ (function (_super) {
    __extends(SplitGates, _super);
    function SplitGates(props) {
        var _this = _super.call(this, props) || this;
        _this._initGateName = '';
        _this._isSaved = new PromiseCompletion(CompletionType.Completed);
        _this._initData();
        return _this;
    }
    SplitGates.prototype._initData = function () {
        var _a;
        var id = (_a = this.props.gate, _a.id), displayName = _a.displayName, scopeLimitation = _a.scopeLimitation, forecastOrActualDate = _a.forecastOrActualDate, lengthInWeek = _a.lengthInWeek;
        this._initGateName = displayName;
        this._initGateForecastOrActualDate = forecastOrActualDate;
        this._initGateLengthInWeeks = lengthInWeek;
        this._data = {
            gateId: id,
            splitCount: 2,
            splitParts: [
                {
                    name: displayName + '.1',
                    scope: scopeLimitation,
                    forecastOrActualDate: forecastOrActualDate,
                    lengthInWeeks: lengthInWeek,
                    isSyncWithNorrdDisabled: true
                },
                {
                    name: this._initGateName + '.2',
                    scope: '',
                    forecastOrActualDate: forecastOrActualDate,
                    lengthInWeeks: lengthInWeek,
                    isSyncWithNorrdDisabled: true
                }
            ]
        };
        var gateSplitOptions = [
            { value: '2', label: 'Split in two' },
            { value: '3', label: 'Split in three' },
            { value: '4', label: 'Split in four' }
        ];
        this._gateSplitOptions = gateSplitOptions.map(function (x) { return ({ value: x.value, label: x.label }); });
        this._initGateSplitOption = this._gateSplitOptions[0];
    };
    SplitGates.prototype._onToggle = function () {
        this.props.onToggle();
    };
    SplitGates.prototype._onSave = function () {
        return __awaiter(this, void 0, void 0, function () {
            var promise;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this._data && this.props.onGateSplit)) return [3 /*break*/, 2];
                        promise = this.props.onGateSplit(this._data);
                        this._isSaved.subscribe(promise);
                        return [4 /*yield*/, promise];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this._onToggle();
                        return [2 /*return*/];
                }
            });
        });
    };
    SplitGates.prototype._handleNameChange = function (e) {
        var inputValue = e.target.value;
        var result = this._initGateName + inputValue.substring(this._initGateName.length, inputValue.length);
        if (e.target.dataset.key) {
            var index = parseInt(e.target.dataset.key);
            this._data.splitParts[index].name = result;
            this.forceUpdate();
        }
    };
    SplitGates.prototype._handleScopeChange = function (e) {
        if (e.target.dataset.key) {
            var index = parseInt(e.target.dataset.key);
            this._data.splitParts[index].scope = e.target.value;
            this.forceUpdate();
        }
    };
    SplitGates.prototype._handleForecastOrActualDateChange = function (date, customData) {
        if (customData) {
            var index = customData;
            this._data.splitParts[index].forecastOrActualDate = date;
        }
    };
    SplitGates.prototype._handleLengthInWeeksChange = function (e) {
        if (e.target.dataset.key) {
            var index = parseInt(e.target.dataset.key);
            this._data.splitParts[index].lengthInWeeks = parseInt(e.target.value);
            this.forceUpdate();
        }
    };
    SplitGates.prototype._handleIsSyncWithNorrdDisabledChange = function (e) {
        if (e.target.dataset.key) {
            var index = parseInt(e.target.dataset.key);
            this._data.splitParts[index].isSyncWithNorrdDisabled = !e.target.checked;
            this.forceUpdate();
        }
    };
    SplitGates.prototype._handleGateSplitOptionChange = function (selectedOption) {
        return __awaiter(this, void 0, void 0, function () {
            var value, i;
            return __generator(this, function (_a) {
                if (Array.isArray(selectedOption) || !this._data)
                    return [2 /*return*/];
                if (selectedOption && this._initGateForecastOrActualDate && this._initGateLengthInWeeks) {
                    value = parseInt(selectedOption.value);
                    this._data.splitCount = value;
                    this._data.splitParts = this._data.splitParts.slice(0, 1);
                    for (i = 2; i <= this._data.splitCount; i++) {
                        this._data.splitParts.push({
                            name: "".concat(this._initGateName, ".").concat(i),
                            scope: '',
                            forecastOrActualDate: this._initGateForecastOrActualDate,
                            lengthInWeeks: this._initGateLengthInWeeks,
                            isSyncWithNorrdDisabled: true
                        });
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    SplitGates.prototype._renderSplitParts = function (splitCount, isNorrdSyncEnable) {
        var _a, _b, _c, _d, _e;
        var rows = [];
        for (var i = 0; i < splitCount; i++) {
            rows.push(React.createElement("div", { key: i },
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(RowLabel, { text: "Name", className: styles.rowLabel, mandatory: true }),
                        React.createElement(Input, { placeholder: "Name", type: "text", "data-key": i, maxLength: this._initGateName.length + 5, value: (_a = this._data) === null || _a === void 0 ? void 0 : _a.splitParts[i].name, onChange: this._handleNameChange })),
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(RowLabel, { text: "Scope Limitation", className: styles.rowLabel, mandatory: true }),
                        React.createElement(Input, { type: "text", "data-key": i, value: (_b = this._data) === null || _b === void 0 ? void 0 : _b.splitParts[i].scope, onChange: this._handleScopeChange }))),
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(RowLabel, { text: "Forecast Or Actual Date", className: styles.rowLabel, mandatory: true }),
                        React.createElement(DatePicker, { disabled: i == 0, onDateChanged: this._handleForecastOrActualDateChange, customData: i, defaultValue: (_c = this._data) === null || _c === void 0 ? void 0 : _c.splitParts[i].forecastOrActualDate, acceptCustomValue: false, type: "date" })),
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(RowLabel, { text: "Duration In Weeks", className: styles.rowLabel, mandatory: true }),
                        React.createElement(Input, { disabled: i == 0, placeholder: "Duration in weeks", type: "number", "data-key": i, min: 1, value: (_d = this._data) === null || _d === void 0 ? void 0 : _d.splitParts[i].lengthInWeeks, onChange: this._handleLengthInWeeksChange }))),
                isNorrdSyncEnable && (React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.checkbox },
                        React.createElement(RowLabel, { text: "Sync Forecast Or Actual Date with NORRD", className: styles.rowLabel }),
                        React.createElement("input", { type: "checkbox", "data-key": i, checked: !((_e = this._data) === null || _e === void 0 ? void 0 : _e.splitParts[i].isSyncWithNorrdDisabled), onChange: this._handleIsSyncWithNorrdDisabledChange })))),
                React.createElement("br", null)));
        }
        return rows;
    };
    SplitGates.prototype.render = function () {
        var _a, _b;
        var _c, _d, _e;
        var displayName = (_a = this.props.gate, _a.displayName), canPrincipalEditIsSyncWithNorrdDisabled = _a.canPrincipalEditIsSyncWithNorrdDisabled;
        var isMilestoneProject = (_b = this.props, _b.isMilestoneProject), isNorrdProject = _b.isNorrdProject;
        var isNorrdSyncEnable = isNorrdProject && canPrincipalEditIsSyncWithNorrdDisabled;
        var isSaveDisabled = !this._data ||
            ((_c = this._data) === null || _c === void 0 ? void 0 : _c.splitParts.filter(function (x) { return !x.name || !x.scope || !x.forecastOrActualDate || !x.lengthInWeeks; }).length) > 0;
        return (React.createElement("div", { className: styles.container },
            React.createElement(Modal, { isOpen: true, toggle: this._onToggle, headerTitle: "Split ".concat(isMilestoneProject ? 'Milestone' : 'Gate', " ").concat(displayName), minWidth: "50%", footerbuttons: this._getFooterButtons(isSaveDisabled) }, this._data ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.thirdField },
                        React.createElement(Label, null, "Split Option"),
                        React.createElement(Select, { defaultValue: this._initGateSplitOption, options: this._gateSplitOptions, onChange: this._handleGateSplitOptionChange }))),
                ((_d = this._data) === null || _d === void 0 ? void 0 : _d.splitCount) &&
                    this._renderSplitParts((_e = this._data) === null || _e === void 0 ? void 0 : _e.splitCount, isNorrdSyncEnable))) : (React.createElement(Loader, { style: { width: 100, height: 100 } })))));
    };
    SplitGates.prototype._getFooterButtons = function (isSaveDisabled) {
        return [
            {
                color: 'success',
                onClick: this._onSave,
                disabled: isSaveDisabled,
                isLoading: this._isSaved.isPending,
                title: 'Save'
            },
            {
                onClick: this._onToggle,
                title: 'Close'
            }
        ];
    };
    __decorate([
        observable
    ], SplitGates.prototype, "_data", void 0);
    __decorate([
        observable
    ], SplitGates.prototype, "_initGateName", void 0);
    __decorate([
        observable
    ], SplitGates.prototype, "_initGateForecastOrActualDate", void 0);
    __decorate([
        observable
    ], SplitGates.prototype, "_initGateLengthInWeeks", void 0);
    __decorate([
        observable
    ], SplitGates.prototype, "_initGateSplitOption", void 0);
    __decorate([
        observable
    ], SplitGates.prototype, "_gateSplitOptions", void 0);
    __decorate([
        observable
    ], SplitGates.prototype, "_isSaved", void 0);
    __decorate([
        action.bound
    ], SplitGates.prototype, "_initData", null);
    __decorate([
        action.bound
    ], SplitGates.prototype, "_onToggle", null);
    __decorate([
        action.bound
    ], SplitGates.prototype, "_onSave", null);
    __decorate([
        action.bound
    ], SplitGates.prototype, "_handleNameChange", null);
    __decorate([
        action.bound
    ], SplitGates.prototype, "_handleScopeChange", null);
    __decorate([
        action.bound
    ], SplitGates.prototype, "_handleForecastOrActualDateChange", null);
    __decorate([
        action.bound
    ], SplitGates.prototype, "_handleLengthInWeeksChange", null);
    __decorate([
        action.bound
    ], SplitGates.prototype, "_handleIsSyncWithNorrdDisabledChange", null);
    __decorate([
        action.bound
    ], SplitGates.prototype, "_handleGateSplitOptionChange", null);
    __decorate([
        action.bound
    ], SplitGates.prototype, "_renderSplitParts", null);
    SplitGates = __decorate([
        observer
    ], SplitGates);
    return SplitGates;
}(React.Component));
