var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { Grid } from '@app/Components/Grid/Grid';
import { Column, Sorting, FilterRow } from 'devextreme-react/data-grid';
import { withRouter } from 'react-router-dom';
import { Link } from '@app/Components/Link/Link';
import { action, observable } from 'mobx';
import { AttachmentView } from '@app/Widgets/AttachmentView/AttachmentView';
import { observer } from 'mobx-react';
import { SeparatedStrings, ManualTaskStateEnum, AppData, ObjectType } from '@app/AppConstants';
import { ManualTaskActionButtons } from '@app/Components/ManualTaskActionButtons/ManualTaskActionButtons';
import styles from './TasksTable.module.scss';
import { WithCopy } from '@app/Components/LinkWithCopy/LinkWithCopy';
import { DateBox } from '@app/Widgets/DateBox/DateBox';
import { ChangeLogDialog } from '@app/Components/Dialogs/ChangeLogDialog';
import { createUniqueId } from '@app/Helpers/UidHelper/UidHelper';
import { HistoryButton } from '@app/Components/HistoryButton.tsx/HistoryButton';
var TasksTableC = /** @class */ (function (_super) {
    __extends(TasksTableC, _super);
    function TasksTableC() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._selectedTask = null;
        _this._isAttachmentViewOpen = false;
        _this._historyOpen = false;
        return _this;
    }
    TasksTableC.prototype._handleHistoryClick = function (task) {
        this._selectedTask = task;
        this._historyOpen = true;
    };
    TasksTableC.prototype.renderManualTaskState = function (state) {
        if (state == ManualTaskStateEnum.CantDo) {
            return SeparatedStrings.ManualTaskCantDoState;
        }
        if (state == ManualTaskStateEnum.ClosedBySystem) {
            return SeparatedStrings.ManualTaskClosedBySystemState;
        }
        return state;
    };
    TasksTableC.prototype.render = function () {
        var _this = this;
        var _a;
        var _b = this.props, tasks = _b.tasks, match = _b.match, search = _b.location.search, onUpdate = _b.onUpdate;
        var assignee = new URLSearchParams(search).get(AppData.AssigneeQueryParameter);
        var defaultTaskStatus = assignee ? ManualTaskStateEnum.Deleted : null;
        var defaultTaskStatusOperator = assignee ? '<>' : null;
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid, { dataSource: tasks },
                React.createElement(Sorting, { mode: "multiple" }),
                React.createElement(FilterRow, { visible: true }),
                React.createElement(Column, { dataField: "title", caption: "Task name", width: 300, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(WithCopy, { text: "".concat(match.url, "/tsk-").concat(data.id).concat(search) },
                            React.createElement("div", { className: styles.codeColumn },
                                React.createElement(Link, { to: "".concat(match.url, "/tsk-").concat(data.id).concat(search) }, data.title),
                                React.createElement(HistoryButton, { onClick: function () { return _this._handleHistoryClick(data); }, to: match.url + search }))));
                    } }),
                React.createElement(Column, { dataField: "projectCodeName", caption: "Project", width: 100, alignment: "center", cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(Link, { to: "projects/".concat(data.projectCodeName) }, data.projectCodeName));
                    } }),
                React.createElement(Column, { dataField: "project", caption: "Name", width: 350, calculateCellValue: function (val) { return val.project.label; } }),
                React.createElement(Column, { dataField: "gate", caption: "Gate", width: 75, calculateCellValue: function (val) { var _a; return (_a = val.gate) === null || _a === void 0 ? void 0 : _a.label; } }),
                React.createElement(Column, { dataField: "assignee", caption: "Assignee", width: 200, defaultFilterValue: assignee, calculateCellValue: function (val) { var _a; return (_a = val.assignee) === null || _a === void 0 ? void 0 : _a.displayName; } }),
                React.createElement(Column, { dataField: "dueDate", caption: "Deadline", width: 125, cellRender: function (_a) {
                        var data = _a.data;
                        return React.createElement(DateBox, { date: data.dueDate });
                    } }),
                React.createElement(Column, { dataField: "approver", caption: "Approver", width: 200, calculateCellValue: function (val) { var _a; return (_a = val.approver) === null || _a === void 0 ? void 0 : _a.displayName; } }),
                React.createElement(Column, { dataField: "state", caption: "State", width: 100, defaultFilterValue: defaultTaskStatus, defaultSelectedFilterOperation: defaultTaskStatusOperator, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("p", null, _this.renderManualTaskState(data.state)));
                    } }),
                React.createElement(Column, { dataField: "status", caption: "Status", width: 100 }),
                React.createElement(Column, { caption: "Actions", minWidth: 250, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(React.Fragment, null, data.state !== ManualTaskStateEnum.Deleted && (React.createElement("div", { className: styles.buttons },
                            React.createElement(ManualTaskActionButtons, { task: data, updateTaskStatus: onUpdate })))));
                    } })),
            this._isAttachmentViewOpen && !!this._selectedTask && (React.createElement(AttachmentView, { isOpen: this._isAttachmentViewOpen, onToggle: function () { return (_this._isAttachmentViewOpen = !_this._isAttachmentViewOpen); }, projectId: this._selectedTask.project.value, manualTaskId: this._selectedTask.id, gateId: (_a = this._selectedTask.gate) === null || _a === void 0 ? void 0 : _a.value })),
            this._historyOpen && !!this._selectedTask && (React.createElement(ChangeLogDialog, { onToggle: function () { return (_this._historyOpen = !_this._historyOpen); }, uniqueId: createUniqueId(ObjectType.ManualTask, this._selectedTask.id) }))));
    };
    __decorate([
        observable
    ], TasksTableC.prototype, "_selectedTask", void 0);
    __decorate([
        observable
    ], TasksTableC.prototype, "_isAttachmentViewOpen", void 0);
    __decorate([
        observable
    ], TasksTableC.prototype, "_historyOpen", void 0);
    __decorate([
        action.bound
    ], TasksTableC.prototype, "_handleHistoryClick", null);
    TasksTableC = __decorate([
        observer
    ], TasksTableC);
    return TasksTableC;
}(React.PureComponent));
export var TasksTable = withRouter(TasksTableC);
