var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Label, Row, Col } from 'reactstrap';
import styles from './EditOrgUnitTemplateDialog.module.scss';
import { Modal } from '@app/Components/Modal/Modal';
import { ProjectTemplatePicker } from '@app/Components/Pickers/ProjectTemplatePicker/ProjectTemplatePicker';
import { ConfigurationKey } from '../OrgUnitConfiguration';
export var EditOrgUnitTemplateDialog = /** @class */ (function (_super) {
    __extends(EditOrgUnitTemplateDialog, _super);
    function EditOrgUnitTemplateDialog(props) {
        var _this = this;
        var _a;
        _this = _super.call(this, props) || this;
        _this._value = undefined;
        _this._autoProjectCreationConfigurationModel = undefined;
        _this._autoProjectCreationTemplateConfigurationModel = undefined;
        _this._handleSaveClick = function () {
            var onSave = _this.props.onSave;
            onSave(_this._autoProjectCreationTemplateConfigurationModel);
        };
        _this._handleChangeClick = function (value) {
            if (_this._autoProjectCreationTemplateConfigurationModel) {
                _this._autoProjectCreationTemplateConfigurationModel.value = value;
                _this._value = value;
            }
        };
        var configurations = props.configurations, orgUnitConfigurationModel = props.orgUnitConfigurationModel;
        _this._autoProjectCreationConfigurationModel = configurations.find(function (x) {
            return x.orgUnitId === orgUnitConfigurationModel.orgUnitId &&
                x.group === orgUnitConfigurationModel.group &&
                x.key === ConfigurationKey.AutoProjectCreation;
        });
        _this._autoProjectCreationTemplateConfigurationModel = configurations.find(function (x) {
            return x.orgUnitId === orgUnitConfigurationModel.orgUnitId &&
                x.group === orgUnitConfigurationModel.group &&
                x.key === ConfigurationKey.AutoProjectCreationTemplate;
        });
        _this._value = (_a = _this._autoProjectCreationTemplateConfigurationModel) === null || _a === void 0 ? void 0 : _a.value;
        return _this;
    }
    EditOrgUnitTemplateDialog.prototype.render = function () {
        var _a;
        var _this = this;
        var _b, _c;
        var isOpen = (_a = this.props, _a.isOpen), onClose = _a.onClose, orgUnitConfigurationModel = _a.orgUnitConfigurationModel;
        var isFormValid = !(((_b = this._autoProjectCreationConfigurationModel) === null || _b === void 0 ? void 0 : _b.value) === 'true' && this._value === undefined);
        return (React.createElement(Modal, { isOpen: isOpen, minWidth: "1100px", toggle: onClose, headerTitle: orgUnitConfigurationModel.orgUnitDisplayName, footerbuttons: this._getFooterButtons(isFormValid) },
            React.createElement(React.Fragment, null,
                React.createElement(Row, { className: styles.row },
                    React.createElement(Col, { sm: "3" },
                        React.createElement(Label, null, "Template")),
                    React.createElement(Col, null,
                        React.createElement(ProjectTemplatePicker, { isClearable: ((_c = this._autoProjectCreationConfigurationModel) === null || _c === void 0 ? void 0 : _c.value) !== 'true', defaultValue: this._value, onChangeHandler: function (ou) { var _a; return _this._handleChangeClick((_a = ou === null || ou === void 0 ? void 0 : ou.value) !== null && _a !== void 0 ? _a : ''); } }))))));
    };
    EditOrgUnitTemplateDialog.prototype._getFooterButtons = function (isFormValid) {
        return [
            {
                color: 'success',
                disabled: !isFormValid,
                onClick: this._handleSaveClick,
                title: 'Save'
            },
            {
                onClick: this.props.onClose,
                title: 'Close'
            }
        ];
    };
    __decorate([
        observable
    ], EditOrgUnitTemplateDialog.prototype, "_value", void 0);
    __decorate([
        observable.deep
    ], EditOrgUnitTemplateDialog.prototype, "_autoProjectCreationConfigurationModel", void 0);
    __decorate([
        observable.deep
    ], EditOrgUnitTemplateDialog.prototype, "_autoProjectCreationTemplateConfigurationModel", void 0);
    __decorate([
        action.bound
    ], EditOrgUnitTemplateDialog.prototype, "_handleSaveClick", void 0);
    __decorate([
        action.bound
    ], EditOrgUnitTemplateDialog.prototype, "_handleChangeClick", void 0);
    EditOrgUnitTemplateDialog = __decorate([
        observer
    ], EditOrgUnitTemplateDialog);
    return EditOrgUnitTemplateDialog;
}(React.Component));
