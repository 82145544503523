var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observer } from 'mobx-react';
import { observable, action, computed } from 'mobx';
import { Api } from '@app/Api';
import styles from './NorrdProjects.module.scss';
import { Redirect } from 'react-router-dom';
import { Grid } from '@app/Components/Grid/Grid';
import { Scrolling, Column, FilterRow, Sorting } from 'devextreme-react/data-grid';
import { Button } from '@app/Components/Button/Button';
import { DateBox } from '@app/Widgets/DateBox/DateBox';
import { setFilterDateFieldPlaceholder } from '@app/Helpers/DataGridHelpers/EditorHelper';
import { ProjectGateModelEnum, SeparatedStrings } from '@app/AppConstants';
import { colorThemesStore } from '@app/Stores/ColorThemesStore';
export var NorrdProjects = /** @class */ (function (_super) {
    __extends(NorrdProjects, _super);
    function NorrdProjects() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._projects = [];
        _this._loading = false;
        _this._maxGridHeight = window.innerHeight - 240;
        return _this;
    }
    Object.defineProperty(NorrdProjects.prototype, "projectsLength", {
        get: function () {
            return this._projects.length;
        },
        enumerable: false,
        configurable: true
    });
    NorrdProjects.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadProjects()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    NorrdProjects.prototype._loadProjects = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, , 2, 3]);
                        this._loading = true;
                        _a = this;
                        return [4 /*yield*/, Api.secured.getProjectsCreationDataFromNorrd({ size: 1000 })];
                    case 1:
                        _a._projects = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this._loading = false;
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    NorrdProjects.prototype._setupProject = function (pid) {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.createProjectWithDataFromNorrd({ norrdProjectId: pid })];
                    case 1:
                        res = _a.sent();
                        this._redirectId = res.body;
                        return [2 /*return*/];
                }
            });
        });
    };
    NorrdProjects.prototype._setProjectIgnore = function (pid, isIgnored) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("pid:".concat(pid, ", isIgnored:").concat(isIgnored, ", step 1"));
                        return [4 /*yield*/, Api.secured.updateNorrdProjectIgnoreStatus(pid, { isIgnored: isIgnored })];
                    case 1:
                        _a.sent();
                        console.log("pid:".concat(pid, ", isIgnored:").concat(isIgnored, ", step 2"));
                        return [4 /*yield*/, this._loadProjects()];
                    case 2:
                        _a.sent();
                        console.log("pid:".concat(pid, ", isIgnored:").concat(isIgnored, ", step 3"));
                        return [2 /*return*/];
                }
            });
        });
    };
    NorrdProjects.prototype.renderGateModel = function (processStage) {
        if (processStage === ProjectGateModelEnum.NotRequired)
            return SeparatedStrings.NorrdProjectGateModelNotRequired;
        return SeparatedStrings.NorrdProjectGateModelOnProjectLevel;
    };
    NorrdProjects.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: styles.container },
            React.createElement(Grid, { dataSource: this._projects, style: { maxHeight: this._maxGridHeight }, onEditorPreparing: function (e) { return setFilterDateFieldPlaceholder(e, ['plannedStartDate', 'plannedEndDate']); }, loading: this._loading, columnAutoWidth: true },
                React.createElement(Scrolling, { mode: "infinite" }),
                React.createElement(Sorting, { mode: "multiple" }),
                React.createElement(FilterRow, { visible: true }),
                React.createElement(Column, { caption: "Action", minWidth: 210, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("div", { className: styles.actions }, data.ignored ? (React.createElement(Button, { className: styles.button, size: "sm", onClick: function () { return _this._setProjectIgnore(data.id, false); } }, "Unignore")) : (React.createElement(React.Fragment, null,
                            React.createElement(Button, { className: styles.button, size: "sm", color: "success", onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, this._setupProject(data.id)];
                                            case 1:
                                                _a.sent();
                                                return [2 /*return*/];
                                        }
                                    });
                                }); } }, "Setup Project"),
                            React.createElement(Button, { className: styles.button, size: "sm", color: "primary", onClick: function () { return _this._setProjectIgnore(data.id, true); } }, "Ignore")))));
                    } }),
                React.createElement(Column, { caption: "Number", width: 85, dataField: "projectNumber", cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("a", { href: data.norrdProjectLink, target: "_blank", rel: "noopener noreferrer", style: { color: colorThemesStore.appColors.buttonPrimary } }, data.projectNumber));
                    } }),
                React.createElement(Column, { caption: "Name", dataField: "projectName", width: 250 }),
                React.createElement(Column, { caption: "Lead Org Unit", dataField: "leadResponsibleOrgUnitDisplayText", width: 300, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("div", null, data.leadResponsibleOrgUnitDisplayText));
                    } }),
                React.createElement(Column, { caption: "Leader", dataField: "projectLeader", width: 150, allowSorting: false }),
                React.createElement(Column, { caption: "Gate Model", dataField: "gateModel", width: 150, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("p", null, _this.renderGateModel(data.gateModel)));
                    } }),
                React.createElement(Column, { caption: "Planned Start Date", 
                    // width={150}
                    dataField: "plannedStartDate", alignment: "center", allowSorting: true, allowFiltering: true, dataType: "date", cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(DateBox, { date: data.plannedStartDate }));
                    } }),
                React.createElement(Column, { caption: "Planned End Date", 
                    // width={150}
                    dataField: "plannedEndDate", alignment: "center", dataType: "date", allowSorting: true, allowFiltering: true, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(DateBox, { date: data.plannedEndDate }));
                    } }),
                React.createElement(Column, { caption: "Total Budget", dataField: "totalBudget", calculateDisplayValue: function (data) { var _a; return (_a = data.totalBudget) === null || _a === void 0 ? void 0 : _a.toLocaleString('de-CH'); }, 
                    // width={100}
                    allowSorting: false }),
                React.createElement(Column, { caption: "Total Budget Forecast", dataField: "totalBudgetForecast", calculateDisplayValue: function (data) { var _a; return (_a = data.totalBudgetForecast) === null || _a === void 0 ? void 0 : _a.toLocaleString('de-CH'); }, 
                    // width={150}
                    allowSorting: false }),
                React.createElement(Column, { caption: "Security Level", dataField: "securityLevel", 
                    // alignment="center"
                    // width={150}
                    allowSorting: false })),
            this._redirectId && React.createElement(Redirect, { to: "projects/prj-".concat(this._redirectId) })));
    };
    __decorate([
        observable
    ], NorrdProjects.prototype, "_redirectId", void 0);
    __decorate([
        observable
    ], NorrdProjects.prototype, "_projects", void 0);
    __decorate([
        observable
    ], NorrdProjects.prototype, "_loading", void 0);
    __decorate([
        computed
    ], NorrdProjects.prototype, "projectsLength", null);
    __decorate([
        action
    ], NorrdProjects.prototype, "_loadProjects", null);
    __decorate([
        action.bound
    ], NorrdProjects.prototype, "_setupProject", null);
    __decorate([
        action.bound
    ], NorrdProjects.prototype, "_setProjectIgnore", null);
    NorrdProjects = __decorate([
        observer
    ], NorrdProjects);
    return NorrdProjects;
}(React.PureComponent));
