var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Row, Col } from 'reactstrap';
import { Select } from '@app/Components/Select/Select';
import { observer } from 'mobx-react';
import { observable, action, computed, runInAction } from 'mobx';
import { Api } from '@app/Api';
import { Modal } from '@app/Components/Modal/Modal';
import { withRouter } from 'react-router-dom';
var AddApprovalModalC = /** @class */ (function (_super) {
    __extends(AddApprovalModalC, _super);
    function AddApprovalModalC() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._functions = [];
        _this._weekOptions = [];
        _this._model = {};
        _this._type = 'cida';
        _this._lookupObjects = [];
        _this._loading = false;
        return _this;
    }
    Object.defineProperty(AddApprovalModalC.prototype, "_isDisabled", {
        get: function () {
            return !(!!this._model.functionId &&
                !!this._model.relatedObjectId &&
                !!this._model.weekFrom &&
                !!this._model.weekTo &&
                !!this._model.approvalType);
        },
        enumerable: false,
        configurable: true
    });
    AddApprovalModalC.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var i;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadObjects()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadFunctions()];
                    case 2:
                        _a.sent();
                        for (i = 0; i > -14; i--) {
                            this._weekOptions.push({ value: i + '', label: i + '' });
                        }
                        this._model.projectId = this.props.projectId;
                        this._model.gateId = this.props.gateId;
                        return [2 /*return*/];
                }
            });
        });
    };
    AddApprovalModalC.prototype._loadFunctions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var projectId, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        projectId = this.props.projectId;
                        _a = this;
                        return [4 /*yield*/, Api.secured.getProjectFunctions({ projectId: projectId })];
                    case 1:
                        _a._functions = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AddApprovalModalC.prototype._loadObjects = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, projectId, gateId, req, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this.props, projectId = _a.projectId, gateId = _a.gateId;
                        req = this._type === 'ca'
                            ? Api.secured.getFunctionalChecklistsLookupInfo.bind(Api.secured)
                            : Api.secured.getGateDeliverablesLookupInfo.bind(Api.secured);
                        _b = this;
                        return [4 /*yield*/, req({ projectId: projectId, gateId: gateId }).then(function (res) {
                                return res.map(function (cl) { return ({
                                    label: cl.codeName || '',
                                    value: cl.id + ''
                                }); });
                            })];
                    case 1:
                        _b._lookupObjects = _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AddApprovalModalC.prototype._handleTypeChange = function (type) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._type = type;
                        return [4 /*yield*/, this._loadObjects()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AddApprovalModalC.prototype._handleSave = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._loading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 6, 7]);
                        if (!(this._type === 'ca')) return [3 /*break*/, 3];
                        return [4 /*yield*/, Api.secured.createChecklistApproval({ body: this._model })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, Api.secured.createCheckListItemDeliverableApproval({ body: this._model })];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        this._toggleApprovalsModal();
                        return [3 /*break*/, 7];
                    case 6:
                        runInAction(function () {
                            _this._loading = false;
                        });
                        return [7 /*endfinally*/];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    AddApprovalModalC.prototype._handleApprovalTypeChange = function (value) {
        var option = value;
        var updatedValue = option ? option.value : null;
        if (updatedValue) {
            this._model.approvalType = updatedValue;
        }
    };
    AddApprovalModalC.prototype._toggleApprovalsModal = function () {
        var _a = this.props, history = _a.history, backUrl = _a.backUrl;
        history.push(backUrl);
        if (this.props.onClose)
            this.props.onClose();
    };
    Object.defineProperty(AddApprovalModalC.prototype, "_typeOptions", {
        get: function () {
            var types = this._type === 'cida'
                ? [
                    'ProvideDeliverableTask',
                    'DraftDeliverableTask',
                    'MoveDeliverableToInApprovalTask',
                    'ApprovalDeliverableTask'
                ]
                : ['MoveFunctionChecklistToInApprovalTask', 'ApprovalFunctionChecklistTask'];
            return types.map(function (t) { return ({ value: '' + t, label: '' + t }); });
        },
        enumerable: false,
        configurable: true
    });
    AddApprovalModalC.prototype.render = function () {
        var _this = this;
        return (React.createElement(Modal, { isOpen: true, toggle: this._toggleApprovalsModal, headerTitle: "Add Approval", footerbuttons: this._getFooterButtons() },
            React.createElement(Row, { style: { margin: '10px 0' } },
                React.createElement(Col, null,
                    React.createElement(Select, { placeholder: "Type", options: [
                            { label: 'Checklist Approval', value: 'ca' },
                            { label: 'Checklist Item Deliverable Approval', value: 'cida' }
                        ], defaultValue: { label: 'Checklist Item Deliverable Approval', value: 'cida' }, onChange: function (op) { return _this._handleTypeChange(op.value); } }))),
            React.createElement(Row, { style: { margin: '10px 0' } },
                React.createElement(Col, null,
                    React.createElement(Select, { placeholder: this._type === 'ca' ? 'Select Checklist' : 'Select Checklist Item', options: this._lookupObjects, onChange: function (op) { return (_this._model.relatedObjectId = parseInt(op.value)); } }))),
            React.createElement(Row, { style: { margin: '10px 0' } },
                React.createElement(Col, null,
                    React.createElement(Select, { placeholder: "Approving Function", options: this._functions.map(function (f) { return ({
                            label: "".concat(f.name, " (").concat(f.code, ")"),
                            value: f.id.toString()
                        }); }), onChange: function (op) { return (_this._model.functionId = parseInt(op.value)); } }))),
            React.createElement(Row, { style: { margin: '10px 0' } },
                React.createElement(Col, null,
                    React.createElement(Select, { placeholder: "Type", options: this._typeOptions, onChange: function (value) { return _this._handleApprovalTypeChange(value); } }))),
            React.createElement(Row, { style: { margin: '10px 0' } },
                React.createElement(Col, null,
                    React.createElement(Select, { placeholder: "Week from", options: this._weekOptions, onChange: function (op) { return (_this._model.weekFrom = parseInt(op.value)); } })),
                React.createElement(Col, null,
                    React.createElement(Select, { placeholder: "Week to", options: this._weekOptions, onChange: function (op) { return (_this._model.weekTo = parseInt(op.value)); } })))));
    };
    AddApprovalModalC.prototype._getFooterButtons = function () {
        return [
            {
                color: 'success',
                onClick: this._handleSave,
                disabled: this._isDisabled,
                isLoading: this._loading,
                title: 'Save'
            },
            {
                onClick: this._toggleApprovalsModal,
                disabled: this._loading,
                title: 'Close'
            }
        ];
    };
    __decorate([
        observable
    ], AddApprovalModalC.prototype, "_functions", void 0);
    __decorate([
        observable
    ], AddApprovalModalC.prototype, "_weekOptions", void 0);
    __decorate([
        observable
    ], AddApprovalModalC.prototype, "_model", void 0);
    __decorate([
        observable
    ], AddApprovalModalC.prototype, "_type", void 0);
    __decorate([
        observable
    ], AddApprovalModalC.prototype, "_lookupObjects", void 0);
    __decorate([
        observable
    ], AddApprovalModalC.prototype, "_loading", void 0);
    __decorate([
        computed
    ], AddApprovalModalC.prototype, "_isDisabled", null);
    __decorate([
        action.bound
    ], AddApprovalModalC.prototype, "_loadFunctions", null);
    __decorate([
        action.bound
    ], AddApprovalModalC.prototype, "_loadObjects", null);
    __decorate([
        action.bound
    ], AddApprovalModalC.prototype, "_handleTypeChange", null);
    __decorate([
        action.bound
    ], AddApprovalModalC.prototype, "_handleSave", null);
    __decorate([
        action.bound
    ], AddApprovalModalC.prototype, "_handleApprovalTypeChange", null);
    __decorate([
        action.bound
    ], AddApprovalModalC.prototype, "_toggleApprovalsModal", null);
    __decorate([
        computed
    ], AddApprovalModalC.prototype, "_typeOptions", null);
    AddApprovalModalC = __decorate([
        observer
    ], AddApprovalModalC);
    return AddApprovalModalC;
}(React.PureComponent));
export var AddApprovalModal = withRouter(AddApprovalModalC);
