var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getUid, splitUid } from '@app/Helpers/UidHelper/UidHelper';
import { ObjectType } from '@app/AppConstants';
export var getUrl = function (messageType, project, gate, checklist, manualTask) {
    switch (messageType) {
        case 'GateSetupStatusIsInApproval':
        case 'GateProcessStageIsOnHold':
        case 'GateProcessStageOnHoldUndone': {
            return "/".concat(project, "/setup/gates");
        }
        case 'GateSetupStatusIsDraft': {
            return "/".concat(project, "/setup/gates/").concat(gate, "/functions");
        }
        case 'ChecklistItemDeliverableStatusIsDraft':
        case 'ChecklistItemDeliverableStatusIsDraftExternal':
        case 'ChecklistItemDeliverableStatusIsInApproval':
        case 'ChecklistItemDeliverableStatusIsInApprovalConfigurable':
        case 'ChecklistFCLStatusIsDraft':
        case 'ChecklistFCLStatusIsApproved':
        case 'ChecklistFCLStatusIsInApproval':
        case 'ChecklistFCLStatusIsInApprovalConfigurable':
        case 'ChecklistFCLPredecessorDueDateExpired':
        case 'ChecklistItemDeliverableIsExternal':
        case 'ChecklistItemDeliverableIsNotExternal': {
            return "/".concat(project, "/preparations/").concat(gate, "/").concat(checklist);
        }
        case 'ActionItemStatusIsDraft':
        case 'ActionItemStatusIsInApproval':
        case 'ActionItemStatusIsApproved':
        case 'ChecklistItemDeliverableMCLIDStatusIsDraft':
        case 'ChecklistItemDeliverableStatusIsDraftAfterDeny':
        case 'ChecklistItemDeliverableStatusIsApproved':
        case 'ChecklistItemDeliverableStatusIsApprovedConfigurable':
        case 'ChecklistUnapproveFunctionChecklist':
        case 'ChecklistItemDeliverableUnapproved': {
            return "/".concat(project, "/deliverables/").concat(gate);
        }
        case 'GateRecommendationTimeToMakeGateRecommendation':
        case 'ChecklistMCLStatusIsDraftGateStagePreparationAssessment':
        case 'ChecklistFCLStatusIsApprovedGA': {
            return "/".concat(project, "/assessments/").concat(gate);
        }
        case 'GateMeetingDecisionStatusIsApproved':
        case 'GateRecommendationMade':
        case 'GateMeetingDecisionStatusInApproval': {
            return "/".concat(project, "/gate-meeting/").concat(gate);
        }
        case 'ManualTaskStatusIsDraft':
        case 'ManualTaskStatusIsInApproval': {
            return "/tasks/".concat(manualTask);
        }
        default:
            return '';
    }
};
export var getProject = function (projectCodeName) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, "projects/".concat(projectCodeName)];
    });
}); };
export var getIdFromUid = function (objectType, objectHierarchy) {
    return getUid(objectHierarchy || '', objectType);
};
export var getGateIdFromUid = function (gateId) {
    return 'g-' + gateId;
};
export var getCheckList = function (checkListId) {
    return 'cl-' + checkListId;
};
export var getManualTask = function (manualTaskId) {
    return 'tsk-' + manualTaskId;
};
var getGateIdFromObject = function (obj) {
    var typeWithId = splitUid(obj.objectId);
    var isGate = typeWithId[0] === ObjectType.Gate;
    var gateId = null;
    if (isGate) {
        gateId = typeWithId[1];
    }
    else {
        gateId = getIdFromUid(ObjectType.Gate, obj.objectHierarchy) || null;
    }
    return gateId;
};
var getChecklistIdFromObject = function (obj) {
    var typeWithId = splitUid(obj.objectId);
    var isCheckList = typeWithId[0] === ObjectType.Checklist;
    var clId = null;
    if (isCheckList) {
        clId = typeWithId[1];
    }
    else {
        clId = getIdFromUid(ObjectType.Checklist, obj.objectHierarchy) || null;
    }
    return clId;
};
var getManualTaskIdFromObject = function (obj) {
    var typeWithId = splitUid(obj.objectId);
    var isManualTask = typeWithId[0] === ObjectType.ManualTask;
    var manualTaskId = null;
    if (isManualTask) {
        manualTaskId = typeWithId[1];
    }
    else {
        manualTaskId = getIdFromUid(ObjectType.ManualTask, obj.objectHierarchy) || null;
    }
    return manualTaskId;
};
export var generateLink = function (obj) { return __awaiter(void 0, void 0, void 0, function () {
    var projectId, gateId, checklistId, manualTaskId, project, gate, checklist, manualTask;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                projectId = (_a = obj.project) === null || _a === void 0 ? void 0 : _a.value;
                if (!projectId)
                    return [2 /*return*/, ''];
                gateId = getGateIdFromObject(obj);
                checklistId = getChecklistIdFromObject(obj);
                manualTaskId = getManualTaskIdFromObject(obj);
                return [4 /*yield*/, getProject(projectId)];
            case 1:
                project = _b.sent();
                gate = (gateId !== null && getGateIdFromUid(gateId)) || '';
                checklist = (checklistId !== null && getCheckList(checklistId)) || '';
                manualTask = (manualTaskId !== null && getManualTask(manualTaskId)) || '';
                return [2 /*return*/, getUrl(obj.messageType, project, gate, checklist, manualTask)];
        }
    });
}); };
