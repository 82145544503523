var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { observable, action, computed } from 'mobx';
import { appStore } from '@app/AppStore';
import { DATE_WITH_TIME_OPTIONS, convertUTCDateToLocalDate } from '@app/Helpers/DateOptions';
var ConfirmationCellWrapperStore = /** @class */ (function () {
    function ConfirmationCellWrapperStore(objectUniqueId, section) {
        this._lockModel = null;
        this._ignoreDetection = false;
        this.isActive = false;
        this.loading = false;
        this._objectUniqueId = objectUniqueId;
        this._section = section;
    }
    ConfirmationCellWrapperStore.prototype.getObjectUniqueId = function () {
        return this._objectUniqueId;
    };
    ConfirmationCellWrapperStore.prototype.getSection = function () {
        return this._section;
    };
    ConfirmationCellWrapperStore.prototype.setLockModel = function (lockModel) {
        this._lockModel = lockModel;
    };
    ConfirmationCellWrapperStore.prototype.showLoading = function () {
        this.loading = true;
    };
    ConfirmationCellWrapperStore.prototype.hideLoading = function () {
        this.loading = false;
    };
    ConfirmationCellWrapperStore.prototype.toggleIgnoreDetection = function (isIgnored) {
        var _this = this;
        setTimeout(function () {
            _this._ignoreDetection = isIgnored;
        }, 0);
    };
    ConfirmationCellWrapperStore.prototype.setIsActive = function (isActive) {
        this.isActive = isActive;
    };
    Object.defineProperty(ConfirmationCellWrapperStore.prototype, "isLoading", {
        get: function () {
            return this.loading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ConfirmationCellWrapperStore.prototype, "_showLockScreen", {
        get: function () {
            if (!this._lockModel)
                return false;
            var _a = this._lockModel, currentEditor = _a.currentEditor, lockStatus = _a.lockStatus;
            var editorId = (currentEditor === null || currentEditor === void 0 ? void 0 : currentEditor.objectId) || null;
            var isLocked = lockStatus === 'Locked' || lockStatus === 'AlreadyLocked';
            var sameEditor = editorId === appStore.currentObjectId;
            var isLockedByMe = isLocked && sameEditor;
            return !isLockedByMe;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ConfirmationCellWrapperStore.prototype, "editorName", {
        get: function () {
            var _a, _b;
            return ((_b = (_a = this._lockModel) === null || _a === void 0 ? void 0 : _a.currentEditor) === null || _b === void 0 ? void 0 : _b.displayName) || 'Unknown Editor';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ConfirmationCellWrapperStore.prototype, "sinceDate", {
        get: function () {
            var _a;
            var date = (_a = this._lockModel) === null || _a === void 0 ? void 0 : _a.lockedSinceDateTimeUtc;
            var sinceDate = date
                ? convertUTCDateToLocalDate(date).toLocaleDateString('en-GB', DATE_WITH_TIME_OPTIONS)
                : '';
            return sinceDate;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ConfirmationCellWrapperStore.prototype, "isEditMode", {
        get: function () {
            if (!this._lockModel)
                return false;
            var _a = this._lockModel, lockStatus = _a.lockStatus, currentEditor = _a.currentEditor;
            if (lockStatus !== 'FailedToLock' && currentEditor) {
                var sameEditor = currentEditor.objectId === appStore.currentObjectId;
                return sameEditor;
            }
            if (this.isActive)
                return true;
            return false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ConfirmationCellWrapperStore.prototype, "isDetectionIgnored", {
        get: function () {
            return this._ignoreDetection;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], ConfirmationCellWrapperStore.prototype, "_lockModel", void 0);
    __decorate([
        observable
    ], ConfirmationCellWrapperStore.prototype, "_objectUniqueId", void 0);
    __decorate([
        observable
    ], ConfirmationCellWrapperStore.prototype, "_section", void 0);
    __decorate([
        observable
    ], ConfirmationCellWrapperStore.prototype, "_ignoreDetection", void 0);
    __decorate([
        observable
    ], ConfirmationCellWrapperStore.prototype, "isActive", void 0);
    __decorate([
        observable
    ], ConfirmationCellWrapperStore.prototype, "loading", void 0);
    __decorate([
        action
    ], ConfirmationCellWrapperStore.prototype, "setLockModel", null);
    __decorate([
        action
    ], ConfirmationCellWrapperStore.prototype, "showLoading", null);
    __decorate([
        action
    ], ConfirmationCellWrapperStore.prototype, "hideLoading", null);
    __decorate([
        action.bound
    ], ConfirmationCellWrapperStore.prototype, "toggleIgnoreDetection", null);
    __decorate([
        action.bound
    ], ConfirmationCellWrapperStore.prototype, "setIsActive", null);
    __decorate([
        computed
    ], ConfirmationCellWrapperStore.prototype, "isLoading", null);
    __decorate([
        computed
    ], ConfirmationCellWrapperStore.prototype, "_showLockScreen", null);
    __decorate([
        computed
    ], ConfirmationCellWrapperStore.prototype, "editorName", null);
    __decorate([
        computed
    ], ConfirmationCellWrapperStore.prototype, "sinceDate", null);
    __decorate([
        computed
    ], ConfirmationCellWrapperStore.prototype, "isEditMode", null);
    __decorate([
        computed
    ], ConfirmationCellWrapperStore.prototype, "isDetectionIgnored", null);
    return ConfirmationCellWrapperStore;
}());
export default ConfirmationCellWrapperStore;
var ConfigCellStore = /** @class */ (function () {
    function ConfigCellStore() {
        this._cells = [];
        this._isOutsideClick = false;
    }
    ConfigCellStore.prototype.setCell = function (rowIndex, columnIndex) {
        var isExist = this._cells.find(function (item) { return item.rowIndex === rowIndex && item.columnIndex === columnIndex; });
        if (!isExist) {
            this._cells = __spreadArray(__spreadArray([], this._cells, true), [{ rowIndex: rowIndex, columnIndex: columnIndex, editing: false }], false);
        }
    };
    ConfigCellStore.prototype.setEditing = function (row, column, isEditing) {
        this._cells = this._cells.map(function (cell) {
            if (cell.rowIndex === row && cell.columnIndex === column) {
                cell.editing = isEditing;
                return cell;
            }
            return cell;
        });
    };
    ConfigCellStore.prototype.setIsOutsideClick = function (isOutsideClick) {
        this._isOutsideClick = isOutsideClick;
    };
    Object.defineProperty(ConfigCellStore.prototype, "isOutsideClick", {
        get: function () {
            return this._isOutsideClick;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ConfigCellStore.prototype, "cells", {
        get: function () {
            return this._cells;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], ConfigCellStore.prototype, "_cells", void 0);
    __decorate([
        observable
    ], ConfigCellStore.prototype, "_isOutsideClick", void 0);
    __decorate([
        action.bound
    ], ConfigCellStore.prototype, "setCell", null);
    __decorate([
        action.bound
    ], ConfigCellStore.prototype, "setEditing", null);
    __decorate([
        action.bound
    ], ConfigCellStore.prototype, "setIsOutsideClick", null);
    __decorate([
        computed
    ], ConfigCellStore.prototype, "isOutsideClick", null);
    __decorate([
        computed
    ], ConfigCellStore.prototype, "cells", null);
    return ConfigCellStore;
}());
export var configCellStore = new ConfigCellStore();
