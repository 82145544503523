var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import styles from './AddActionItemWrapper.module.scss';
import { Button } from '@app/Components/Button/Button';
import { AddActionItemModal } from '@app/Widgets/AddActionItemModal/AddActionItemModal';
import { GateProcessTypeEnum } from '@app/AppConstants';
export var AddActionItemWrapper = /** @class */ (function (_super) {
    __extends(AddActionItemWrapper, _super);
    function AddActionItemWrapper() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isAddActionItemOpen = false;
        return _this;
    }
    AddActionItemWrapper.prototype._toggleAddActionItemModal = function () {
        this._isAddActionItemOpen = !this._isAddActionItemOpen;
    };
    AddActionItemWrapper.prototype.render = function () {
        var _a;
        var children = (_a = this.props, _a.children), disabled = _a.disabled, projectId = _a.projectId, gateId = _a.gateId, data = _a.data, gateProcessType = _a.gateProcessType, onActionItemSave = _a.onActionItemSave, isForProjectManager = _a.isForProjectManager;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.wrapper }, children),
            !disabled && (React.createElement("div", { className: styles.button },
                React.createElement(Button, { onClick: this._toggleAddActionItemModal }, "Action Item"))),
            this._isAddActionItemOpen && (React.createElement(AddActionItemModal, { projectId: projectId, gateId: gateId, selectedChecklistId: data.checkListId, selectedChecklistItemId: data.id, gateProcessType: gateProcessType, isOpen: this._isAddActionItemOpen, onToggle: this._toggleAddActionItemModal, onSave: onActionItemSave, actionText: gateProcessType === GateProcessTypeEnum.GateMeeting
                    ? data.assessorComment
                    : isForProjectManager
                        ? data.projectManagerOriginalComment
                        : data.assessorOriginalComment }))));
    };
    __decorate([
        observable
    ], AddActionItemWrapper.prototype, "_isAddActionItemOpen", void 0);
    __decorate([
        action.bound
    ], AddActionItemWrapper.prototype, "_toggleAddActionItemModal", null);
    AddActionItemWrapper = __decorate([
        observer
    ], AddActionItemWrapper);
    return AddActionItemWrapper;
}(React.Component));
