var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { AiOutlineHistory } from 'react-icons/ai';
import { Link } from 'react-router-dom';
var HistoryButton = /** @class */ (function (_super) {
    __extends(HistoryButton, _super);
    function HistoryButton(props) {
        var _this = _super.call(this, props) || this;
        _this._handleClick = _this._handleClick.bind(_this);
        return _this;
    }
    HistoryButton.prototype._handleClick = function () {
        var onClick = this.props.onClick;
        onClick === null || onClick === void 0 ? void 0 : onClick();
    };
    HistoryButton.prototype.render = function () {
        var to = this.props.to;
        var linkTo = to ? to : '#';
        return (React.createElement(Link, { onClick: this._handleClick, to: linkTo },
            React.createElement(AiOutlineHistory, null)));
    };
    return HistoryButton;
}(React.PureComponent));
export { HistoryButton };
