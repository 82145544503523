var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { LoadingContext } from './LoadingContext';
var LoadingContextProvider = /** @class */ (function (_super) {
    __extends(LoadingContextProvider, _super);
    function LoadingContextProvider() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            loading: false,
            setLoading: null
        };
        return _this;
    }
    LoadingContextProvider.prototype.render = function () {
        var _this = this;
        return (React.createElement(LoadingContext.Provider, { value: {
                loading: this.state.loading,
                setLoading: function (val) {
                    _this.setState({
                        loading: val
                    });
                }
            } }, this.props.children));
    };
    return LoadingContextProvider;
}(Component));
export { LoadingContextProvider };
