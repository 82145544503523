var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import styles from './TemplatesManagementTable.module.scss';
import { Button } from '@app/Components/Button/Button';
import { Deliverable } from '@app/Widgets/Deliverable/Deliverable';
import { Grid } from '@app/Components/Grid/Grid';
import { Column } from 'devextreme-react/data-grid';
var TemplatesManagementTable = /** @class */ (function (_super) {
    __extends(TemplatesManagementTable, _super);
    function TemplatesManagementTable() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TemplatesManagementTable.prototype.render = function () {
        var _a = this.props, templates = _a.templates, onTemplateExport = _a.onTemplateExport, onTemplateEnabled = _a.onTemplateEnabled, onRefreshTemplates = _a.onRefreshTemplates, onTemplateRemove = _a.onTemplateRemove, loading = _a.loading;
        return (React.createElement(Grid, { dataSource: templates, loading: loading },
            React.createElement(Column, { caption: "Template Name", dataField: "displayName", width: 350 }),
            React.createElement(Column, { caption: "Is Valid", dataField: "isValid", alignment: "center", width: 70, calculateCellValue: function (value) { return (value.isValid ? 'Yes' : 'No'); } }),
            React.createElement(Column, { caption: "Code", dataField: "code", width: 100 }),
            React.createElement(Column, { caption: "Version", dataField: "version", width: 100 }),
            React.createElement(Column, { caption: "Checklist Type", dataField: "templateType", width: 100 }),
            React.createElement(Column, { caption: "Org Unit", dataField: "orgUnitName", width: 200 }),
            React.createElement(Column, { caption: "Project Type", dataField: "projectType", width: 200 }),
            React.createElement(Column, { caption: "Description", dataField: "description", width: 400 }),
            React.createElement(Column, { caption: "Actions", minWidth: 420, cellRender: function (_a) {
                    var data = _a.data;
                    return (React.createElement("div", { className: styles.actions },
                        React.createElement(Button, { className: styles.action, size: "sm", color: data.isEnabled ? undefined : 'success', onClick: function () { return onTemplateEnabled === null || onTemplateEnabled === void 0 ? void 0 : onTemplateEnabled(data.id, !data.isEnabled); } }, data.isEnabled ? 'Disable' : 'Enable'),
                        React.createElement(Button, { className: styles.action, size: "sm", color: "primary", onClick: function () { return onTemplateExport === null || onTemplateExport === void 0 ? void 0 : onTemplateExport(data.id); } }, "Export"),
                        React.createElement(Deliverable, { projectTemplateId: data.id, projectTemplateType: data.projectTemplateType, templateName: data.displayName || '', onModalClose: onRefreshTemplates }),
                        React.createElement(Button, { className: styles.action, size: "sm", color: "danger", onClick: function () { return onTemplateRemove === null || onTemplateRemove === void 0 ? void 0 : onTemplateRemove(data.id); } }, "Remove")));
                } })));
    };
    return TemplatesManagementTable;
}(React.PureComponent));
export { TemplatesManagementTable };
