var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { withRouter } from 'react-router-dom';
import notify from 'devextreme/ui/notify';
import { devInfoStore } from '@app/Stores/DevInfoStore';
import { Api } from '@app/Api';
import { Modal } from '@app/Components/Modal/Modal';
import ProjectDocumentFilesView from './ProjectDocumentFilesView/ProjectDocumentFilesView';
import styles from './ProjectDocumentViewDialog.module.scss';
import { DeletedItemsFilter } from '../Components/DeletedItemsFilter/DeletedItemsFilter';
var ProjectDocumentViewDialog = /** @class */ (function (_super) {
    __extends(ProjectDocumentViewDialog, _super);
    function ProjectDocumentViewDialog() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._fileInputRef = React.createRef();
        _this._filesInfoModel = undefined;
        _this._fileInfosModel = undefined;
        _this._documentId = undefined;
        _this._filesLoading = false;
        _this._showDeletedFiles = false;
        return _this;
    }
    ProjectDocumentViewDialog.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.props.match.params.id) {
                            this._documentId = +this.props.match.params.id;
                        }
                        return [4 /*yield*/, this._reloadGrid()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocumentViewDialog.prototype._loadData = function (documentId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 2, 3]);
                        this._filesLoading = true;
                        return [4 /*yield*/, Promise.all([this._loadFiles(documentId), this._loadFileInfos(documentId)])];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this._filesLoading = false;
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocumentViewDialog.prototype._loadFiles = function (documentId) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!documentId)
                            return [2 /*return*/, (this._filesInfoModel = undefined)];
                        _a = this;
                        return [4 /*yield*/, Api.secured.getProjectDocumentFiles({
                                documentId: documentId,
                                showDeletedFiles: this._showDeletedFiles
                            })];
                    case 1:
                        _a._filesInfoModel = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocumentViewDialog.prototype._loadFileInfos = function (documentId) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, Api.secured.getProjectDocumentFileInfos({
                                projectId: this.props.projectId,
                                documentId: documentId
                            })];
                    case 1:
                        _a._fileInfosModel = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocumentViewDialog.prototype._uploadFileClickHandler = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                (_a = this._fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click();
                return [2 /*return*/];
            });
        });
    };
    ProjectDocumentViewDialog.prototype._handleFileChange = function (e) {
        return __awaiter(this, void 0, void 0, function () {
            var files;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.persist();
                        files = e.target.files;
                        return [4 /*yield*/, this._handleFiles(files)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocumentViewDialog.prototype._handleFiles = function (files) {
        return __awaiter(this, void 0, void 0, function () {
            var i, file, sizeInMb, body, body;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(files && files.length)) return [3 /*break*/, 8];
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < files.length)) return [3 /*break*/, 8];
                        file = files[i];
                        if (file.size > devInfoStore.maxDocumentSize) {
                            this._fileInputRef.current && (this._fileInputRef.current.value = '');
                            sizeInMb = devInfoStore.maxDocumentSize / 1048576;
                            notify("File size must be less than ".concat(sizeInMb, "MB"), 'error', 1000);
                            return [2 /*return*/];
                        }
                        if (!this._documentId) return [3 /*break*/, 3];
                        return [4 /*yield*/, Api.secured.uploadProjectDocumentFile({
                                documentId: this._documentId,
                                file: file
                            })];
                    case 2:
                        body = (_a.sent()).body;
                        this._documentId = body;
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, Api.secured.createProjectDocument({
                            projectId: this.props.projectId,
                            file: file
                        })];
                    case 4:
                        body = (_a.sent()).body;
                        this._documentId = body;
                        _a.label = 5;
                    case 5: return [4 /*yield*/, this._reloadGrid()];
                    case 6:
                        _a.sent();
                        _a.label = 7;
                    case 7:
                        i++;
                        return [3 /*break*/, 1];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocumentViewDialog.prototype._onFilesChangedHandler = function (isLastFileDeleted) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!isLastFileDeleted) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._toggleModal()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this._reloadGrid()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocumentViewDialog.prototype._reloadGrid = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadData(this._documentId)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocumentViewDialog.prototype._delay = function (time) {
        return new Promise(function (resolve) { return setTimeout(resolve, time); });
    };
    ProjectDocumentViewDialog.prototype._toggleModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, history, backUrl, onClose;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, history = _a.history, backUrl = _a.backUrl, onClose = _a.onClose;
                        return [4 /*yield*/, this._delay(500)];
                    case 1:
                        _b.sent(); // Timeout for ClickOutside event in ClickDetector before component will unmount (bug with locking comment column)
                        history.push(backUrl);
                        if (!onClose) return [3 /*break*/, 3];
                        return [4 /*yield*/, onClose()];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocumentViewDialog.prototype._onFilterChangeHandler = function (checked) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._showDeletedFiles = checked;
                        return [4 /*yield*/, this._reloadGrid()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocumentViewDialog.prototype.render = function () {
        var _a, _b;
        var _c = this.props, projectId = _c.projectId, title = _c.title, isProjectTerminatedOrCompleted = _c.isProjectTerminatedOrCompleted, isCreate = _c.isCreate;
        var canPrincipalManageDocument = !!((_a = this._fileInfosModel) === null || _a === void 0 ? void 0 : _a.canPrincipalManageDocument);
        var canPrincipalCreateDocument = !!((_b = this._fileInfosModel) === null || _b === void 0 ? void 0 : _b.canPrincipalCreateDocument);
        return (React.createElement(React.Fragment, null,
            React.createElement(Modal, { isOpen: true, toggle: this._toggleModal, headerTitle: React.createElement(React.Fragment, null,
                    React.createElement("div", null, "Project Document View"),
                    React.createElement("div", null, title),
                    React.createElement("div", { className: styles.warningTextWrapper }, "Warning: Uploading multiple files generates multiple versions of the same Document")), maxWidth: "80%", footerbuttons: this._getFooterButtons(canPrincipalManageDocument, canPrincipalCreateDocument, isProjectTerminatedOrCompleted, this._documentId) },
                !isCreate && (React.createElement("div", { className: styles.deletedItemsFilter },
                    React.createElement(DeletedItemsFilter, { isChecked: this._showDeletedFiles, title: "Show Deleted Files", onToggle: this._onFilterChangeHandler }))),
                React.createElement(ProjectDocumentFilesView, { isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted, filesInfoModel: this._filesInfoModel, filesLoading: this._filesLoading, projectId: projectId, documentId: this._documentId, onFilesChanged: this._onFilesChangedHandler })),
            React.createElement("input", { style: { display: 'none' }, type: "file", ref: this._fileInputRef, onChange: this._handleFileChange })));
    };
    ProjectDocumentViewDialog.prototype._getFooterButtons = function (canPrincipalManageDocument, canPrincipalCreateDocument, isProjectTerminated, documentId) {
        var buttons = [];
        if (!isProjectTerminated) {
            if (documentId ? canPrincipalManageDocument : canPrincipalCreateDocument) {
                var uploadBtn = {
                    color: 'primary',
                    onClick: this._uploadFileClickHandler,
                    title: 'Upload'
                };
                buttons.push(uploadBtn);
            }
        }
        buttons.push({
            onClick: this._toggleModal,
            title: 'Close'
        });
        return buttons;
    };
    __decorate([
        observable
    ], ProjectDocumentViewDialog.prototype, "_fileInputRef", void 0);
    __decorate([
        observable
    ], ProjectDocumentViewDialog.prototype, "_filesInfoModel", void 0);
    __decorate([
        observable
    ], ProjectDocumentViewDialog.prototype, "_fileInfosModel", void 0);
    __decorate([
        observable
    ], ProjectDocumentViewDialog.prototype, "_documentId", void 0);
    __decorate([
        observable
    ], ProjectDocumentViewDialog.prototype, "_filesLoading", void 0);
    __decorate([
        observable
    ], ProjectDocumentViewDialog.prototype, "_showDeletedFiles", void 0);
    __decorate([
        action.bound
    ], ProjectDocumentViewDialog.prototype, "_loadData", null);
    __decorate([
        action.bound
    ], ProjectDocumentViewDialog.prototype, "_loadFiles", null);
    __decorate([
        action.bound
    ], ProjectDocumentViewDialog.prototype, "_loadFileInfos", null);
    __decorate([
        action.bound
    ], ProjectDocumentViewDialog.prototype, "_uploadFileClickHandler", null);
    __decorate([
        action.bound
    ], ProjectDocumentViewDialog.prototype, "_handleFileChange", null);
    __decorate([
        action.bound
    ], ProjectDocumentViewDialog.prototype, "_handleFiles", null);
    __decorate([
        action.bound
    ], ProjectDocumentViewDialog.prototype, "_onFilesChangedHandler", null);
    __decorate([
        action.bound
    ], ProjectDocumentViewDialog.prototype, "_reloadGrid", null);
    __decorate([
        action.bound
    ], ProjectDocumentViewDialog.prototype, "_toggleModal", null);
    __decorate([
        action.bound
    ], ProjectDocumentViewDialog.prototype, "_onFilterChangeHandler", null);
    ProjectDocumentViewDialog = __decorate([
        observer
    ], ProjectDocumentViewDialog);
    return ProjectDocumentViewDialog;
}(React.PureComponent));
export default withRouter(ProjectDocumentViewDialog);
