var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import styles from '../ActionItemsTable.module.scss';
import { observable, action, computed, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { Select } from '@app/Components/Select/Select';
import { ConfirmationCellWrapper } from '@app/Components/Grid/ConfirmationCellWrapper/ConfirmationCellWrapper';
import { PersonPicker } from '@app/Components/Pickers/PersonPicker/PersonPicker';
import { Api } from '@app/Api';
export var ResponsibleCell = /** @class */ (function (_super) {
    __extends(ResponsibleCell, _super);
    function ResponsibleCell(props) {
        var _a;
        var _this = _super.call(this, props) || this;
        _this._loadRelatedFunctionUser = function (params) {
            return Api.secured.getGatesRelatedFunctionsByProject(params);
        };
        var functions = (_a = _this.props, _a.functions), actionItemData = _a.actionItemData;
        var functionPrincipal = actionItemData.functionPrincipal, supportedFunctionPrincipal = actionItemData.supportedFunctionPrincipal, functionId = actionItemData.functionId, supportedFunctionId = actionItemData.supportedFunctionId, functionPrincipalId = actionItemData.functionPrincipalId, supportedFunctionPrincipalId = actionItemData.supportedFunctionPrincipalId;
        _this._selectedResponsiblePerson = functionPrincipal;
        _this._selectedSupporterPerson = supportedFunctionPrincipal;
        if (functionId && !isNaN(functionId)) {
            _this._functionValue = functions === null || functions === void 0 ? void 0 : functions.find(function (x) { return +x.value === functionId; });
            _this._functionValueOriginal = _this._functionValue;
        }
        if (supportedFunctionId !== undefined) {
            _this._supportedFunctionValue = functions === null || functions === void 0 ? void 0 : functions.find(function (x) { return +x.value === supportedFunctionId; });
            _this._supportedFunctionValueOriginal = _this._supportedFunctionValue;
        }
        if (functionPrincipalId !== undefined) {
            _this._responsiblePrincipalOriginalId = functionPrincipalId;
        }
        if (supportedFunctionPrincipalId !== undefined) {
            _this._supporterPrincipalOriginalId = supportedFunctionPrincipalId;
        }
        reaction(function () { return _this._supportedFunctionValue; }, function () {
            if (!_this._supportedFunctionValue) {
                _this._selectedSupporterPerson = undefined;
            }
        });
        reaction(function () { return _this._selectedResponsiblePerson; }, function () {
            if (!_this._selectedResponsiblePerson) {
                _this._clearFields();
            }
        });
        reaction(function () { return _this._selectedSupporterPerson; }, function () {
            if (!_this._selectedSupporterPerson) {
                _this._supportedFunctionValue = null;
            }
        });
        return _this;
    }
    Object.defineProperty(ResponsibleCell.prototype, "_functionName", {
        get: function () {
            if (!this._functionValue)
                return '';
            var option = this._functionValue;
            return option.label;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ResponsibleCell.prototype, "_supportedFunctionName", {
        get: function () {
            if (!this._supportedFunctionValue)
                return '';
            var option = this._supportedFunctionValue;
            return option.label;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ResponsibleCell.prototype, "_supportedFunctions", {
        get: function () {
            var _this = this;
            var _a;
            if (!this._functionValue) {
                return this.props.functions;
            }
            return (_a = this.props.functions) === null || _a === void 0 ? void 0 : _a.filter(function (x) { return x !== _this._functionValue; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ResponsibleCell.prototype, "_isValid", {
        get: function () {
            return (this._selectedResponsiblePerson &&
                (this._functionValue || (this._selectedResponsiblePerson && this._selectedResponsiblePerson.isActive)) &&
                (!this._selectedSupporterPerson || this._supportedFunctionValue || this._selectedSupporterPerson.isActive));
        },
        enumerable: false,
        configurable: true
    });
    ResponsibleCell.prototype.render = function () {
        var _a;
        var _this = this;
        var _b, _c;
        var functions = (_a = this.props, _a.functions), objectUniqueId = _a.objectUniqueId, disabled = _a.disabled, actionItemData = _a.actionItemData, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
        var responsibleDisplayText = actionItemData.responsibleDisplayText;
        var supportedChanged = this._supportedFunctionValueOriginal !== this._supportedFunctionValue;
        var functionChanged = this._functionValueOriginal !== this._functionValue;
        var supportedPrincipalChanged = this._supporterPrincipalOriginalId !== ((_b = this._selectedSupporterPerson) === null || _b === void 0 ? void 0 : _b.id);
        var functionPrincipalChanged = this._responsiblePrincipalOriginalId !== ((_c = this._selectedResponsiblePerson) === null || _c === void 0 ? void 0 : _c.id);
        return (React.createElement(ConfirmationCellWrapper, { rowIndex: rowIndex, columnIndex: columnIndex, onSave: this._handleSave, onCancel: this._handleCancel, objectUniqueId: objectUniqueId, disabled: disabled, disabledConfirm: !this._isValid, valueChanged: supportedChanged || functionChanged || supportedPrincipalChanged || functionPrincipalChanged }, function (isEditing, toggleIgnoreDetection) {
            return isEditing ? (React.createElement("div", { className: styles.relatedEditWrapper },
                React.createElement("div", { className: styles.relatedEditItemWrapper },
                    React.createElement("div", { className: styles.relatedEditItemTitle }, "Responsible"),
                    React.createElement("div", { className: styles.relatedEditItem },
                        React.createElement(Select, { options: functions, onMenuOpen: function () { return toggleIgnoreDetection && toggleIgnoreDetection(true); }, value: _this._functionValue, onChange: function (val) { return _this._handleFunctionChange(val, toggleIgnoreDetection); }, menuPortalTarget: document.body, isDisabled: !isEditing, isMulti: false, isClearable: true }))),
                React.createElement("div", { className: styles.relatedEditItemWrapper },
                    React.createElement("div", { className: styles.relatedEditItemTitle }, "Responsible Person"),
                    React.createElement("div", { className: styles.relatedEditItem },
                        React.createElement(PersonPicker, { defaultValue: _this._selectedResponsiblePerson, onUsersSelect: function (val) {
                                return _this._handleResponsiblePersonSelect(val, toggleIgnoreDetection);
                            }, onMenuOpen: function () { return toggleIgnoreDetection && toggleIgnoreDetection(true); }, isDisabled: !!_this._functionValue, isClearable: true, isCreatable: true }))),
                React.createElement("div", { className: styles.relatedEditItemWrapper },
                    React.createElement("div", { className: styles.relatedEditItemTitle }, "Supporter"),
                    React.createElement("div", { className: styles.relatedEditItem },
                        React.createElement(Select, { options: _this._supportedFunctions, onMenuOpen: function () { return toggleIgnoreDetection && toggleIgnoreDetection(true); }, value: _this._supportedFunctionValue, onChange: function (val) {
                                return _this._handleSupportedFunctionChange(val, toggleIgnoreDetection);
                            }, menuPortalTarget: document.body, isDisabled: !isEditing || !_this._selectedResponsiblePerson, isMulti: false, isClearable: true }))),
                React.createElement("div", { className: styles.relatedEditItemWrapper },
                    React.createElement("div", { className: styles.relatedEditItemTitle }, "Supporter Person"),
                    React.createElement("div", { className: styles.relatedEditItem },
                        React.createElement(PersonPicker, { defaultValue: _this._selectedSupporterPerson, onUsersSelect: function (val) {
                                return _this._handleSupporterPersonSelect(val, toggleIgnoreDetection);
                            }, onMenuOpen: function () { return toggleIgnoreDetection && toggleIgnoreDetection(true); }, isClearable: true, isCreatable: true, isDisabled: !_this._selectedResponsiblePerson || !!_this._supportedFunctionValue }))))) : (React.createElement(React.Fragment, null, responsibleDisplayText));
        }));
    };
    ResponsibleCell.prototype._handleResponsiblePersonSelect = function (value, toggleIgnoreDetection) {
        this._selectedResponsiblePerson = value === null || value === void 0 ? void 0 : value[0];
        setTimeout(function () { return toggleIgnoreDetection === null || toggleIgnoreDetection === void 0 ? void 0 : toggleIgnoreDetection(false); }, 0);
    };
    ResponsibleCell.prototype._handleSupporterPersonSelect = function (value, toggleIgnoreDetection) {
        this._selectedSupporterPerson = value === null || value === void 0 ? void 0 : value[0];
        setTimeout(function () { return toggleIgnoreDetection === null || toggleIgnoreDetection === void 0 ? void 0 : toggleIgnoreDetection(false); }, 0);
    };
    ResponsibleCell.prototype._clearFields = function () {
        this._supportedFunctionValue = null;
        this._selectedSupporterPerson = undefined;
        this._functionValue = null;
    };
    ResponsibleCell.prototype._handleFunctionChange = function (value, toggleIgnoreDetection) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var projectId, gateId, relatedFunction;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        projectId = (_c = this.props, _c.projectId), gateId = _c.gateId;
                        this._functionValue = value;
                        return [4 /*yield*/, this._loadRelatedFunctionUser({
                                projectId: projectId,
                                gateId: gateId,
                                functionId: value ? +((_a = this._functionValue) === null || _a === void 0 ? void 0 : _a.value) : undefined
                            })];
                    case 1:
                        relatedFunction = _d.sent();
                        this._selectedResponsiblePerson = this._functionValue ? (_b = relatedFunction === null || relatedFunction === void 0 ? void 0 : relatedFunction[0]) === null || _b === void 0 ? void 0 : _b.principal : undefined;
                        if (this._supportedFunctionValue === this._functionValue) {
                            this._supportedFunctionValue = null;
                        }
                        toggleIgnoreDetection === null || toggleIgnoreDetection === void 0 ? void 0 : toggleIgnoreDetection(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    ResponsibleCell.prototype._handleSupportedFunctionChange = function (value, toggleIgnoreDetection) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var projectId, gateId, relatedSupporterFunctionUser;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        projectId = (_b = this.props, _b.projectId), gateId = _b.gateId;
                        this._supportedFunctionValue = value;
                        if (!value) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._loadRelatedFunctionUser({
                                projectId: projectId,
                                gateId: gateId,
                                functionId: this._supportedFunctionValue ? +((_a = this._supportedFunctionValue) === null || _a === void 0 ? void 0 : _a.value) : undefined
                            })];
                    case 1:
                        relatedSupporterFunctionUser = _c.sent();
                        this._selectedSupporterPerson = relatedSupporterFunctionUser === null || relatedSupporterFunctionUser === void 0 ? void 0 : relatedSupporterFunctionUser[0].principal;
                        _c.label = 2;
                    case 2:
                        toggleIgnoreDetection === null || toggleIgnoreDetection === void 0 ? void 0 : toggleIgnoreDetection(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    ResponsibleCell.prototype._handleSave = function () {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var params;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (!!this._isValid) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._handleCancel()];
                    case 1:
                        _e.sent();
                        return [2 /*return*/];
                    case 2:
                        params = __assign({}, this.props.actionItemData);
                        params.functionId = this._functionValue ? +((_a = this._functionValue) === null || _a === void 0 ? void 0 : _a.value) : undefined;
                        params.supportedFunctionId = this._supportedFunctionValue
                            ? +((_b = this._supportedFunctionValue) === null || _b === void 0 ? void 0 : _b.value)
                            : undefined;
                        params.functionPrincipalId = (_c = this._selectedResponsiblePerson) === null || _c === void 0 ? void 0 : _c.id;
                        params.supportedFunctionPrincipalId = (_d = this._selectedSupporterPerson) === null || _d === void 0 ? void 0 : _d.id;
                        params.functionPrincipal = undefined;
                        params.supportedFunctionPrincipal = undefined;
                        this._supportedFunctionValueOriginal = this._supportedFunctionValue;
                        this._functionValueOriginal = this._functionValue;
                        return [4 /*yield*/, this.props.onSave(params)];
                    case 3:
                        _e.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ResponsibleCell.prototype._handleCancel = function () {
        return __awaiter(this, void 0, void 0, function () {
            var functionPrincipal, supportedFunctionPrincipal;
            var _a;
            return __generator(this, function (_b) {
                functionPrincipal = (_a = this.props.actionItemData, _a.functionPrincipal), supportedFunctionPrincipal = _a.supportedFunctionPrincipal;
                this._functionValue = this._functionValueOriginal;
                this._supportedFunctionValue = this._supportedFunctionValueOriginal;
                this._selectedResponsiblePerson = functionPrincipal;
                this._selectedSupporterPerson = supportedFunctionPrincipal;
                return [2 /*return*/];
            });
        });
    };
    __decorate([
        observable
    ], ResponsibleCell.prototype, "_functionValue", void 0);
    __decorate([
        observable
    ], ResponsibleCell.prototype, "_functionValueOriginal", void 0);
    __decorate([
        observable
    ], ResponsibleCell.prototype, "_supportedFunctionValue", void 0);
    __decorate([
        observable
    ], ResponsibleCell.prototype, "_supportedFunctionValueOriginal", void 0);
    __decorate([
        observable
    ], ResponsibleCell.prototype, "_selectedResponsiblePerson", void 0);
    __decorate([
        observable
    ], ResponsibleCell.prototype, "_responsiblePrincipalOriginalId", void 0);
    __decorate([
        observable
    ], ResponsibleCell.prototype, "_selectedSupporterPerson", void 0);
    __decorate([
        observable
    ], ResponsibleCell.prototype, "_supporterPrincipalOriginalId", void 0);
    __decorate([
        computed
    ], ResponsibleCell.prototype, "_functionName", null);
    __decorate([
        computed
    ], ResponsibleCell.prototype, "_supportedFunctionName", null);
    __decorate([
        computed
    ], ResponsibleCell.prototype, "_supportedFunctions", null);
    __decorate([
        computed
    ], ResponsibleCell.prototype, "_isValid", null);
    __decorate([
        action.bound
    ], ResponsibleCell.prototype, "_handleResponsiblePersonSelect", null);
    __decorate([
        action.bound
    ], ResponsibleCell.prototype, "_handleSupporterPersonSelect", null);
    __decorate([
        action.bound
    ], ResponsibleCell.prototype, "_clearFields", null);
    __decorate([
        action.bound
    ], ResponsibleCell.prototype, "_handleFunctionChange", null);
    __decorate([
        action.bound
    ], ResponsibleCell.prototype, "_handleSupportedFunctionChange", null);
    __decorate([
        action.bound
    ], ResponsibleCell.prototype, "_loadRelatedFunctionUser", void 0);
    __decorate([
        action.bound
    ], ResponsibleCell.prototype, "_handleSave", null);
    __decorate([
        action.bound
    ], ResponsibleCell.prototype, "_handleCancel", null);
    ResponsibleCell = __decorate([
        observer
    ], ResponsibleCell);
    return ResponsibleCell;
}(React.PureComponent));
