var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observer } from 'mobx-react';
import styles from './meeting-content.module.scss';
import { observable, action } from 'mobx';
import { Accordion } from '@app/Components/Accordion/Accordion';
import { AccordionTab } from '@app/Components/Accordion/AccordionTab';
import { Particpants } from '../Participants/Participants';
import { Attachments } from '../Attachments/Attachments';
import { Api } from '@app/Api';
import { FormSelect, FormConfirmationInput, FormDateBox, FormConfirmationMarkdown } from '@app/Components/FormControls';
import { BaseFormModel } from '@app/Services/BaseFormModel';
import { isRequired, isNumber } from '@app/Services/Validation';
import FileUploadArea from '@app/Components/FileUploadArea/FileUploadArea';
var FormModel = /** @class */ (function (_super) {
    __extends(FormModel, _super);
    function FormModel() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.subject = '';
        _this.gateId = null;
        _this.agenda = '';
        _this.minutesOfMeeting = '';
        _this.participants = [];
        _this.attachments = [];
        return _this;
    }
    __decorate([
        observable,
        isRequired()
    ], FormModel.prototype, "subject", void 0);
    __decorate([
        observable,
        isRequired()
    ], FormModel.prototype, "startDateTime", void 0);
    __decorate([
        observable,
        isRequired(),
        isNumber()
    ], FormModel.prototype, "gateId", void 0);
    __decorate([
        observable,
        isRequired()
    ], FormModel.prototype, "meetingType", void 0);
    __decorate([
        observable
    ], FormModel.prototype, "agenda", void 0);
    __decorate([
        observable
    ], FormModel.prototype, "minutesOfMeeting", void 0);
    __decorate([
        observable
    ], FormModel.prototype, "participants", void 0);
    __decorate([
        observable
    ], FormModel.prototype, "attachments", void 0);
    return FormModel;
}(BaseFormModel));
export var MeetingContent = /** @class */ (function (_super) {
    __extends(MeetingContent, _super);
    function MeetingContent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._participants = [];
        _this._attachments = [];
        _this.form = new FormModel();
        return _this;
    }
    MeetingContent.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadMeeting()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MeetingContent.prototype._loadMeeting = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, Api.secured.getMeetingByGate({ gateId: this.props.gateId })];
                    case 1:
                        _a._meeting = _b.sent();
                        this._participants = this._meeting.participants.map(function (p) { return (__assign({}, p)); });
                        this._attachments = this._meeting.attachments.map(function (a) { return (__assign({}, a)); });
                        this._startDate = this._meeting.startDateTime;
                        this._endDate = this._meeting.endDateTime;
                        this.form.subject = this._meeting.subject;
                        this.form.agenda = this._meeting.agenda || '';
                        this.form.minutesOfMeeting = this._meeting.minutesOfMeeting || '';
                        return [2 /*return*/];
                }
            });
        });
    };
    MeetingContent.prototype._updateMeeting = function (fields) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this._meeting;
                        if (!_a) return [3 /*break*/, 2];
                        return [4 /*yield*/, Api.secured.updateMeeting({ body: __assign({ id: this._meeting.id }, fields) })];
                    case 1:
                        _a = (_b.sent());
                        _b.label = 2;
                    case 2:
                        _a;
                        return [2 /*return*/];
                }
            });
        });
    };
    MeetingContent.prototype._handleStartDateChange = function (date) {
        return __awaiter(this, void 0, void 0, function () {
            var endTime;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._startDate = date;
                        if (!(this._meeting && this._meeting.endDateTime && date.getTime() > this._meeting.endDateTime.getTime())) return [3 /*break*/, 2];
                        endTime = new Date(date);
                        endTime.setHours(date.getHours() + 1);
                        this._endDate = endTime;
                        return [4 /*yield*/, this._handleEndDateChange(endTime)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this._updateMeeting({ startDateTime: date })];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MeetingContent.prototype._handleEndDateChange = function (date) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._endDate = date;
                        return [4 /*yield*/, this._updateMeeting({ endDateTime: date })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MeetingContent.prototype._handleObjectChange = function (subject) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._updateMeeting({ subject: subject })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MeetingContent.prototype._handleAgendaChange = function (agenda) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._updateMeeting({ agenda: agenda })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MeetingContent.prototype._handleMinutesChange = function (minutesOfMeeting) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._updateMeeting({ minutesOfMeeting: minutesOfMeeting })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MeetingContent.prototype._uploadAttachment = function (file) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._meeting)
                            return [2 /*return*/];
                        return [4 /*yield*/, Api.secured.uploadAttachment(this._meeting.id, { file: file })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MeetingContent.prototype._removeAttachment = function (at) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._meeting) return [3 /*break*/, 3];
                        return [4 /*yield*/, Api.secured.removeAttachment(this._meeting.id, { attachmentId: at.id })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadMeeting()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MeetingContent.prototype._handleAddParticipant = function (user) {
        return __awaiter(this, void 0, void 0, function () {
            var existing, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        existing = this._participants.find(function (p) { return p.principalId === user.id; });
                        if (!existing)
                            this._participants.push({
                                principalId: user.id,
                                principalDisplayName: user.displayName,
                                isMandatory: true
                            });
                        _a = this._meeting;
                        if (!_a) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._updateMeeting({ participantIds: this._participants.map(function (p) { return p.principalId; }) })];
                    case 1:
                        _a = (_b.sent());
                        _b.label = 2;
                    case 2:
                        _a;
                        return [2 /*return*/];
                }
            });
        });
    };
    MeetingContent.prototype._handleRemoveParticipant = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var participantId, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        participantId = this._participants.findIndex(function (p) { return p.principalId === id; });
                        if (participantId > -1)
                            this._participants.splice(participantId, 1);
                        _a = this._meeting;
                        if (!_a) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._updateMeeting({ participantIds: this._participants.map(function (p) { return p.principalId; }) })];
                    case 1:
                        _a = (_b.sent());
                        _b.label = 2;
                    case 2:
                        _a;
                        return [2 /*return*/];
                }
            });
        });
    };
    MeetingContent.prototype._handleUpdateParticipant = function (participant) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        participant.isMandatory = !participant.isMandatory;
                        return [4 /*yield*/, Api.secured.updateParticipant({
                                meetingId: (_a = this._meeting) === null || _a === void 0 ? void 0 : _a.id,
                                principalId: participant.principalId,
                                isMandatory: participant.isMandatory
                            })];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this._loadMeeting()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MeetingContent.prototype.render = function () {
        var _a;
        var _b;
        var gateForecastOrActualDate = (_a = this.props, _a.gateForecastOrActualDate), isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted;
        var allowEdit = ((_b = this._meeting) === null || _b === void 0 ? void 0 : _b.canUpdateMeeting) && !isProjectTerminatedOrCompleted;
        if (!this._meeting)
            return false;
        var gateSelectDefaultValue = {
            value: this._meeting.gateId.toString(),
            label: this._meeting.gateName
        };
        var minEndDate = this._startDate
            ? new Date(this._startDate).setHours(this._startDate.getHours() - 1)
            : undefined;
        return (React.createElement("div", { className: styles.meetingContainer },
            React.createElement("div", { className: styles.dateRow },
                React.createElement("div", { style: { width: '31%', marginRight: '10px' } },
                    React.createElement("div", { className: styles.label }, "Gate Id"),
                    React.createElement(FormSelect, { formModel: this.form, name: "gateId", defaultValue: gateSelectDefaultValue, disabled: true, options: [gateSelectDefaultValue], placeholder: "Select a gate" })),
                React.createElement("div", { style: { width: '23%', marginRight: '10px', marginLeft: '10px' } },
                    React.createElement("div", { className: styles.label }, "Meeting start date"),
                    React.createElement(FormDateBox, { formModel: this.form, name: 'startDateTime', disabled: !allowEdit, value: this._startDate, displayFormat: 'dd MMM yyyy HH:mm:ss', onDateChange: this._handleStartDateChange, acceptCustomValue: false })),
                React.createElement("div", { style: { width: '23%', marginLeft: '10px' } },
                    React.createElement("div", { className: styles.label }, "Meeting end date"),
                    React.createElement(FormDateBox, { formModel: this.form, name: 'endDateTime', disabled: !allowEdit, min: minEndDate, value: this._endDate, displayFormat: 'dd MMM yyyy HH:mm:ss', onDateChange: this._handleEndDateChange, acceptCustomValue: false })),
                React.createElement("div", { style: { width: '23%', marginRight: '10px', marginLeft: '10px' } },
                    React.createElement("div", { className: styles.label }, "Completion target date"),
                    React.createElement(FormDateBox, { formModel: this.form, name: 'targetDate', disabled: true, value: gateForecastOrActualDate, displayFormat: 'dd MMM yyyy HH:mm:ss', onDateChange: this._handleStartDateChange, acceptCustomValue: false }))),
            React.createElement("div", { className: styles.titleWrapper },
                React.createElement(FormConfirmationInput, { placeholder: "Meeting subject", disabled: true, changeHandler: this._handleObjectChange, formModel: this.form, name: "subject" })),
            React.createElement(Accordion, null,
                React.createElement(AccordionTab, { title: 'Participants' },
                    React.createElement(Particpants, { participants: this._participants, disabled: !allowEdit, onAdd: this._handleAddParticipant, onRemove: this._handleRemoveParticipant, onChange: this._handleUpdateParticipant, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })),
                React.createElement(AccordionTab, { title: 'Agenda' },
                    React.createElement(FormConfirmationMarkdown, { className: styles.textArea, disabled: !allowEdit, type: "textarea", changeHandler: this._handleAgendaChange, name: "agenda", formModel: this.form })),
                React.createElement(AccordionTab, { title: 'Minutes of Meeting', isOpenedByDefault: !!this._meeting.minutesOfMeeting },
                    React.createElement(FormConfirmationMarkdown, { type: "textarea", disabled: !allowEdit, changeHandler: this._handleMinutesChange, className: styles.textArea, name: "minutesOfMeeting", formModel: this.form })),
                React.createElement(AccordionTab, { title: 'Attachments', isOpenedByDefault: this._meeting.attachments.length > 0 },
                    allowEdit && (React.createElement(FileUploadArea, { upload: this._uploadAttachment, onUpload: this._loadMeeting, maxWidth: "70%" })),
                    React.createElement(Attachments, { disabled: !allowEdit, onRemove: this._removeAttachment, attachments: this._attachments, hideAddButton: true })))));
    };
    __decorate([
        observable
    ], MeetingContent.prototype, "_meeting", void 0);
    __decorate([
        observable
    ], MeetingContent.prototype, "_participants", void 0);
    __decorate([
        observable
    ], MeetingContent.prototype, "_attachments", void 0);
    __decorate([
        observable
    ], MeetingContent.prototype, "_startDate", void 0);
    __decorate([
        observable
    ], MeetingContent.prototype, "_endDate", void 0);
    __decorate([
        action.bound
    ], MeetingContent.prototype, "_loadMeeting", null);
    __decorate([
        action.bound
    ], MeetingContent.prototype, "_handleStartDateChange", null);
    __decorate([
        action.bound
    ], MeetingContent.prototype, "_handleEndDateChange", null);
    __decorate([
        action.bound
    ], MeetingContent.prototype, "_handleObjectChange", null);
    __decorate([
        action.bound
    ], MeetingContent.prototype, "_handleAgendaChange", null);
    __decorate([
        action.bound
    ], MeetingContent.prototype, "_handleMinutesChange", null);
    __decorate([
        action.bound
    ], MeetingContent.prototype, "_uploadAttachment", null);
    __decorate([
        action.bound
    ], MeetingContent.prototype, "_removeAttachment", null);
    __decorate([
        action.bound
    ], MeetingContent.prototype, "_handleAddParticipant", null);
    __decorate([
        action.bound
    ], MeetingContent.prototype, "_handleRemoveParticipant", null);
    __decorate([
        action.bound
    ], MeetingContent.prototype, "_handleUpdateParticipant", null);
    MeetingContent = __decorate([
        observer
    ], MeetingContent);
    return MeetingContent;
}(React.Component));
