/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */
import * as msRest from "@azure/ms-rest-js";
export var actionItemDocumentId = {
    parameterPath: [
        "options",
        "actionItemDocumentId"
    ],
    mapper: {
        serializedName: "actionItemDocumentId",
        type: {
            name: "Number"
        }
    }
};
export var actionItemId0 = {
    parameterPath: [
        "options",
        "actionItemId"
    ],
    mapper: {
        serializedName: "actionItemId",
        type: {
            name: "Number"
        }
    }
};
export var actionItemId1 = {
    parameterPath: "actionItemId",
    mapper: {
        required: true,
        serializedName: "actionItemId",
        type: {
            name: "Number"
        }
    }
};
export var approvalApplication = {
    parameterPath: [
        "options",
        "approvalApplication"
    ],
    mapper: {
        serializedName: "approvalApplication",
        type: {
            name: "String"
        }
    }
};
export var approvalId = {
    parameterPath: [
        "options",
        "approvalId"
    ],
    mapper: {
        serializedName: "approvalId",
        type: {
            name: "Number"
        }
    }
};
export var approvalResult = {
    parameterPath: [
        "options",
        "approvalResult"
    ],
    mapper: {
        serializedName: "ApprovalResult",
        type: {
            name: "String"
        }
    }
};
export var approvedDateFrom = {
    parameterPath: [
        "options",
        "approvedDateFrom"
    ],
    mapper: {
        serializedName: "ApprovedDateFrom",
        type: {
            name: "DateTime"
        }
    }
};
export var approvedDateTo = {
    parameterPath: [
        "options",
        "approvedDateTo"
    ],
    mapper: {
        serializedName: "ApprovedDateTo",
        type: {
            name: "DateTime"
        }
    }
};
export var assessorRating = {
    parameterPath: [
        "options",
        "assessorRating"
    ],
    mapper: {
        serializedName: "AssessorRating",
        type: {
            name: "String"
        }
    }
};
export var assignmentState = {
    parameterPath: [
        "options",
        "assignmentState"
    ],
    mapper: {
        serializedName: "assignmentState",
        type: {
            name: "String"
        }
    }
};
export var attachmentId = {
    parameterPath: [
        "options",
        "attachmentId"
    ],
    mapper: {
        serializedName: "attachmentId",
        type: {
            name: "Number"
        }
    }
};
export var canDelete = {
    parameterPath: [
        "options",
        "canDelete"
    ],
    mapper: {
        serializedName: "CanDelete",
        type: {
            name: "Boolean"
        }
    }
};
export var categories = {
    parameterPath: [
        "options",
        "categories"
    ],
    mapper: {
        serializedName: "Categories",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "String"
                }
            }
        }
    },
    collectionFormat: msRest.QueryCollectionFormat.Csv
};
export var categoryId = {
    parameterPath: [
        "options",
        "categoryId"
    ],
    mapper: {
        serializedName: "categoryId",
        type: {
            name: "Number"
        }
    }
};
export var checklistCodes = {
    parameterPath: [
        "options",
        "checklistCodes"
    ],
    mapper: {
        serializedName: "ChecklistCodes",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "String"
                }
            }
        }
    },
    collectionFormat: msRest.QueryCollectionFormat.Csv
};
export var checklistId = {
    parameterPath: [
        "options",
        "checklistId"
    ],
    mapper: {
        serializedName: "checklistId",
        type: {
            name: "Number"
        }
    }
};
export var checkListId = {
    parameterPath: [
        "options",
        "checkListId"
    ],
    mapper: {
        serializedName: "checkListId",
        type: {
            name: "Number"
        }
    }
};
export var checkListIds = {
    parameterPath: [
        "options",
        "checkListIds"
    ],
    mapper: {
        serializedName: "checkListIds",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "Number"
                }
            }
        }
    },
    collectionFormat: msRest.QueryCollectionFormat.Csv
};
export var checklistItemId = {
    parameterPath: [
        "options",
        "checklistItemId"
    ],
    mapper: {
        serializedName: "checklistItemId",
        type: {
            name: "Number"
        }
    }
};
export var checkListItemId0 = {
    parameterPath: [
        "options",
        "checkListItemId"
    ],
    mapper: {
        serializedName: "checkListItemId",
        type: {
            name: "Number"
        }
    }
};
export var checkListItemId1 = {
    parameterPath: "checkListItemId",
    mapper: {
        required: true,
        serializedName: "checkListItemId",
        type: {
            name: "Number"
        }
    }
};
export var checklistType = {
    parameterPath: [
        "options",
        "checklistType"
    ],
    mapper: {
        serializedName: "ChecklistType",
        type: {
            name: "String"
        }
    }
};
export var code = {
    parameterPath: [
        "options",
        "code"
    ],
    mapper: {
        serializedName: "Code",
        type: {
            name: "String"
        }
    }
};
export var codeName = {
    parameterPath: [
        "options",
        "codeName"
    ],
    mapper: {
        serializedName: "CodeName",
        type: {
            name: "String"
        }
    }
};
export var colorsGray = {
    parameterPath: [
        "options",
        "colorsGray"
    ],
    mapper: {
        serializedName: "Colors.Gray",
        type: {
            name: "String"
        }
    }
};
export var colorsGreen = {
    parameterPath: [
        "options",
        "colorsGreen"
    ],
    mapper: {
        serializedName: "Colors.Green",
        type: {
            name: "String"
        }
    }
};
export var colorsOrange = {
    parameterPath: [
        "options",
        "colorsOrange"
    ],
    mapper: {
        serializedName: "Colors.Orange",
        type: {
            name: "String"
        }
    }
};
export var colorsRed = {
    parameterPath: [
        "options",
        "colorsRed"
    ],
    mapper: {
        serializedName: "Colors.Red",
        type: {
            name: "String"
        }
    }
};
export var colorsYellow = {
    parameterPath: [
        "options",
        "colorsYellow"
    ],
    mapper: {
        serializedName: "Colors.Yellow",
        type: {
            name: "String"
        }
    }
};
export var combineGateContainerNumber = {
    parameterPath: [
        "options",
        "combineGateContainerNumber"
    ],
    mapper: {
        serializedName: "combineGateContainerNumber",
        type: {
            name: "Number"
        }
    }
};
export var comment = {
    parameterPath: [
        "options",
        "comment"
    ],
    mapper: {
        serializedName: "comment",
        type: {
            name: "String"
        }
    }
};
export var completionTargetDateFrom = {
    parameterPath: [
        "options",
        "completionTargetDateFrom"
    ],
    mapper: {
        serializedName: "CompletionTargetDateFrom",
        type: {
            name: "DateTime"
        }
    }
};
export var completionTargetDateTo = {
    parameterPath: [
        "options",
        "completionTargetDateTo"
    ],
    mapper: {
        serializedName: "CompletionTargetDateTo",
        type: {
            name: "DateTime"
        }
    }
};
export var creationDateFrom = {
    parameterPath: [
        "options",
        "creationDateFrom"
    ],
    mapper: {
        serializedName: "CreationDateFrom",
        type: {
            name: "DateTime"
        }
    }
};
export var creationDateTo = {
    parameterPath: [
        "options",
        "creationDateTo"
    ],
    mapper: {
        serializedName: "CreationDateTo",
        type: {
            name: "DateTime"
        }
    }
};
export var currentStatus = {
    parameterPath: [
        "options",
        "currentStatus"
    ],
    mapper: {
        serializedName: "CurrentStatus",
        type: {
            name: "String"
        }
    }
};
export var dateFrom = {
    parameterPath: [
        "options",
        "dateFrom"
    ],
    mapper: {
        serializedName: "dateFrom",
        type: {
            name: "DateTime"
        }
    }
};
export var dateFromFrom = {
    parameterPath: [
        "options",
        "dateFromFrom"
    ],
    mapper: {
        serializedName: "DateFromFrom",
        type: {
            name: "DateTime"
        }
    }
};
export var dateFromTo = {
    parameterPath: [
        "options",
        "dateFromTo"
    ],
    mapper: {
        serializedName: "DateFromTo",
        type: {
            name: "DateTime"
        }
    }
};
export var dateTimeFrom = {
    parameterPath: [
        "options",
        "dateTimeFrom"
    ],
    mapper: {
        serializedName: "dateTimeFrom",
        type: {
            name: "DateTime"
        }
    }
};
export var dateTimeTo = {
    parameterPath: [
        "options",
        "dateTimeTo"
    ],
    mapper: {
        serializedName: "dateTimeTo",
        type: {
            name: "DateTime"
        }
    }
};
export var dateTo = {
    parameterPath: [
        "options",
        "dateTo"
    ],
    mapper: {
        serializedName: "dateTo",
        type: {
            name: "DateTime"
        }
    }
};
export var dateToFrom = {
    parameterPath: [
        "options",
        "dateToFrom"
    ],
    mapper: {
        serializedName: "DateToFrom",
        type: {
            name: "DateTime"
        }
    }
};
export var dateToTo = {
    parameterPath: [
        "options",
        "dateToTo"
    ],
    mapper: {
        serializedName: "DateToTo",
        type: {
            name: "DateTime"
        }
    }
};
export var deadlineDateFrom = {
    parameterPath: [
        "options",
        "deadlineDateFrom"
    ],
    mapper: {
        serializedName: "DeadlineDateFrom",
        type: {
            name: "DateTime"
        }
    }
};
export var deadlineDateTo = {
    parameterPath: [
        "options",
        "deadlineDateTo"
    ],
    mapper: {
        serializedName: "DeadlineDateTo",
        type: {
            name: "DateTime"
        }
    }
};
export var decision = {
    parameterPath: [
        "options",
        "decision"
    ],
    mapper: {
        serializedName: "decision",
        type: {
            name: "String"
        }
    }
};
export var description = {
    parameterPath: [
        "options",
        "description"
    ],
    mapper: {
        serializedName: "Description",
        type: {
            name: "String"
        }
    }
};
export var dismissWarning = {
    parameterPath: [
        "options",
        "dismissWarning"
    ],
    mapper: {
        serializedName: "dismissWarning",
        type: {
            name: "Boolean"
        }
    }
};
export var displayName = {
    parameterPath: [
        "options",
        "displayName"
    ],
    mapper: {
        serializedName: "DisplayName",
        type: {
            name: "String"
        }
    }
};
export var documentCategoryId = {
    parameterPath: [
        "options",
        "documentCategoryId"
    ],
    mapper: {
        serializedName: "documentCategoryId",
        type: {
            name: "Number"
        }
    }
};
export var documentDefinitionId = {
    parameterPath: [
        "options",
        "documentDefinitionId"
    ],
    mapper: {
        serializedName: "documentDefinitionId",
        type: {
            name: "Number"
        }
    }
};
export var documentId = {
    parameterPath: [
        "options",
        "documentId"
    ],
    mapper: {
        serializedName: "documentId",
        type: {
            name: "Number"
        }
    }
};
export var email0 = {
    parameterPath: [
        "options",
        "email"
    ],
    mapper: {
        serializedName: "email",
        type: {
            name: "String"
        }
    }
};
export var email1 = {
    parameterPath: [
        "options",
        "email"
    ],
    mapper: {
        serializedName: "Email",
        type: {
            name: "String"
        }
    }
};
export var exceptId = {
    parameterPath: [
        "options",
        "exceptId"
    ],
    mapper: {
        serializedName: "exceptId",
        type: {
            name: "Number"
        }
    }
};
export var exceptPrincipalIds = {
    parameterPath: [
        "options",
        "exceptPrincipalIds"
    ],
    mapper: {
        serializedName: "exceptPrincipalIds",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "Number"
                }
            }
        }
    },
    collectionFormat: msRest.QueryCollectionFormat.Csv
};
export var favoritesOnly = {
    parameterPath: [
        "options",
        "favoritesOnly"
    ],
    mapper: {
        serializedName: "FavoritesOnly",
        type: {
            name: "Boolean"
        }
    }
};
export var file = {
    parameterPath: [
        "options",
        "file"
    ],
    mapper: {
        serializedName: "file",
        type: {
            name: "Stream"
        }
    }
};
export var fileInformationId = {
    parameterPath: [
        "options",
        "fileInformationId"
    ],
    mapper: {
        serializedName: "fileInformationId",
        type: {
            name: "Number"
        }
    }
};
export var filterModel = {
    parameterPath: [
        "options",
        "filterModel"
    ],
    mapper: {
        serializedName: "filterModel",
        type: {
            name: "String"
        }
    }
};
export var forecastOrActualDateFrom = {
    parameterPath: [
        "options",
        "forecastOrActualDateFrom"
    ],
    mapper: {
        serializedName: "ForecastOrActualDateFrom",
        type: {
            name: "DateTime"
        }
    }
};
export var forecastOrActualDateTo = {
    parameterPath: [
        "options",
        "forecastOrActualDateTo"
    ],
    mapper: {
        serializedName: "ForecastOrActualDateTo",
        type: {
            name: "DateTime"
        }
    }
};
export var fromChecklistId = {
    parameterPath: [
        "options",
        "fromChecklistId"
    ],
    mapper: {
        serializedName: "fromChecklistId",
        type: {
            name: "Number"
        }
    }
};
export var fullCode = {
    parameterPath: [
        "options",
        "fullCode"
    ],
    mapper: {
        serializedName: "FullCode",
        type: {
            name: "String"
        }
    }
};
export var functionId = {
    parameterPath: [
        "options",
        "functionId"
    ],
    mapper: {
        serializedName: "functionId",
        type: {
            name: "Number"
        }
    }
};
export var functionPrincipalEmail = {
    parameterPath: [
        "options",
        "functionPrincipalEmail"
    ],
    mapper: {
        serializedName: "FunctionPrincipalEmail",
        type: {
            name: "String"
        }
    }
};
export var gAPrincipalEmail = {
    parameterPath: [
        "options",
        "gAPrincipalEmail"
    ],
    mapper: {
        serializedName: "GAPrincipalEmail",
        type: {
            name: "String"
        }
    }
};
export var gateAssessorIds = {
    parameterPath: [
        "options",
        "gateAssessorIds"
    ],
    mapper: {
        serializedName: "GateAssessorIds",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "Number"
                }
            }
        }
    },
    collectionFormat: msRest.QueryCollectionFormat.Csv
};
export var gateAssessorMeetingRating = {
    parameterPath: [
        "options",
        "gateAssessorMeetingRating"
    ],
    mapper: {
        serializedName: "GateAssessorMeetingRating",
        type: {
            name: "String"
        }
    }
};
export var gateContainerNumber = {
    parameterPath: [
        "options",
        "gateContainerNumber"
    ],
    mapper: {
        serializedName: "gateContainerNumber",
        type: {
            name: "Number"
        }
    }
};
export var gateId0 = {
    parameterPath: [
        "options",
        "gateId"
    ],
    mapper: {
        serializedName: "gateId",
        type: {
            name: "Number"
        }
    }
};
export var gateId1 = {
    parameterPath: [
        "options",
        "gateId"
    ],
    mapper: {
        serializedName: "GateId",
        type: {
            name: "Number"
        }
    }
};
export var gateId2 = {
    parameterPath: "gateId",
    mapper: {
        required: true,
        serializedName: "gateId",
        type: {
            name: "Number"
        }
    }
};
export var gateMeetingApprovedAtDateTimeFrom = {
    parameterPath: [
        "options",
        "gateMeetingApprovedAtDateTimeFrom"
    ],
    mapper: {
        serializedName: "GateMeetingApprovedAtDateTimeFrom",
        type: {
            name: "DateTime"
        }
    }
};
export var gateMeetingApprovedAtDateTimeTo = {
    parameterPath: [
        "options",
        "gateMeetingApprovedAtDateTimeTo"
    ],
    mapper: {
        serializedName: "GateMeetingApprovedAtDateTimeTo",
        type: {
            name: "DateTime"
        }
    }
};
export var gateMeetingDecision = {
    parameterPath: [
        "options",
        "gateMeetingDecision"
    ],
    mapper: {
        serializedName: "GateMeetingDecision",
        type: {
            name: "String"
        }
    }
};
export var gateMeetingStatus = {
    parameterPath: [
        "options",
        "gateMeetingStatus"
    ],
    mapper: {
        serializedName: "GateMeetingStatus",
        type: {
            name: "String"
        }
    }
};
export var gateProcessType0 = {
    parameterPath: [
        "options",
        "gateProcessType"
    ],
    mapper: {
        serializedName: "gateProcessType",
        type: {
            name: "String"
        }
    }
};
export var gateProcessType1 = {
    parameterPath: [
        "options",
        "gateProcessType"
    ],
    mapper: {
        serializedName: "GateProcessType",
        type: {
            name: "String"
        }
    }
};
export var gateStartContainerNumber = {
    parameterPath: [
        "options",
        "gateStartContainerNumber"
    ],
    mapper: {
        serializedName: "GateStartContainerNumber",
        type: {
            name: "Number"
        }
    }
};
export var gOPrincipalEmail = {
    parameterPath: [
        "options",
        "gOPrincipalEmail"
    ],
    mapper: {
        serializedName: "GOPrincipalEmail",
        type: {
            name: "String"
        }
    }
};
export var group0 = {
    parameterPath: [
        "options",
        "group"
    ],
    mapper: {
        serializedName: "group",
        type: {
            name: "String"
        }
    }
};
export var group1 = {
    parameterPath: [
        "options",
        "group"
    ],
    mapper: {
        serializedName: "Group",
        type: {
            name: "String"
        }
    }
};
export var hasActionItem = {
    parameterPath: [
        "options",
        "hasActionItem"
    ],
    mapper: {
        serializedName: "HasActionItem",
        type: {
            name: "Boolean"
        }
    }
};
export var hideNAItems = {
    parameterPath: [
        "options",
        "hideNAItems"
    ],
    mapper: {
        serializedName: "hideNAItems",
        defaultValue: false,
        type: {
            name: "Boolean"
        }
    }
};
export var id0 = {
    parameterPath: [
        "options",
        "id"
    ],
    mapper: {
        serializedName: "Id",
        type: {
            name: "Number"
        }
    }
};
export var id1 = {
    parameterPath: [
        "options",
        "id"
    ],
    mapper: {
        serializedName: "id",
        type: {
            name: "Number"
        }
    }
};
export var id2 = {
    parameterPath: "id",
    mapper: {
        required: true,
        serializedName: "id",
        type: {
            name: "Number"
        }
    }
};
export var includeChildren = {
    parameterPath: [
        "options",
        "includeChildren"
    ],
    mapper: {
        serializedName: "IncludeChildren",
        type: {
            name: "Boolean"
        }
    }
};
export var includeOrgUnitChildren = {
    parameterPath: [
        "options",
        "includeOrgUnitChildren"
    ],
    mapper: {
        serializedName: "IncludeOrgUnitChildren",
        type: {
            name: "Boolean"
        }
    }
};
export var inheritedFrom = {
    parameterPath: [
        "options",
        "inheritedFrom"
    ],
    mapper: {
        serializedName: "inheritedFrom",
        type: {
            name: "String"
        }
    }
};
export var isActive = {
    parameterPath: [
        "options",
        "isActive"
    ],
    mapper: {
        serializedName: "isActive",
        type: {
            name: "Boolean"
        }
    }
};
export var isActiveOnly = {
    parameterPath: [
        "options",
        "isActiveOnly"
    ],
    mapper: {
        serializedName: "isActiveOnly",
        type: {
            name: "Boolean"
        }
    }
};
export var isActiveOrWithProjectsOnly = {
    parameterPath: [
        "options",
        "isActiveOrWithProjectsOnly"
    ],
    mapper: {
        serializedName: "isActiveOrWithProjectsOnly",
        type: {
            name: "Boolean"
        }
    }
};
export var isApplicableForTemplate = {
    parameterPath: [
        "options",
        "isApplicableForTemplate"
    ],
    mapper: {
        serializedName: "isApplicableForTemplate",
        type: {
            name: "Boolean"
        }
    }
};
export var isEnabled0 = {
    parameterPath: [
        "options",
        "isEnabled"
    ],
    mapper: {
        serializedName: "IsEnabled",
        type: {
            name: "Boolean"
        }
    }
};
export var isEnabled1 = {
    parameterPath: [
        "options",
        "isEnabled"
    ],
    mapper: {
        serializedName: "isEnabled",
        type: {
            name: "Boolean"
        }
    }
};
export var isEnabledOnly = {
    parameterPath: [
        "options",
        "isEnabledOnly"
    ],
    mapper: {
        serializedName: "isEnabledOnly",
        defaultValue: false,
        type: {
            name: "Boolean"
        }
    }
};
export var isEntry = {
    parameterPath: [
        "options",
        "isEntry"
    ],
    mapper: {
        serializedName: "isEntry",
        type: {
            name: "Boolean"
        }
    }
};
export var isErrorsOnly = {
    parameterPath: [
        "options",
        "isErrorsOnly"
    ],
    mapper: {
        serializedName: "isErrorsOnly",
        type: {
            name: "Boolean"
        }
    }
};
export var isForCurrentPrincipal = {
    parameterPath: [
        "options",
        "isForCurrentPrincipal"
    ],
    mapper: {
        serializedName: "isForCurrentPrincipal",
        type: {
            name: "Boolean"
        }
    }
};
export var isForGateMeeting = {
    parameterPath: [
        "options",
        "isForGateMeeting"
    ],
    mapper: {
        serializedName: "isForGateMeeting",
        defaultValue: false,
        type: {
            name: "Boolean"
        }
    }
};
export var isForProject = {
    parameterPath: [
        "options",
        "isForProject"
    ],
    mapper: {
        serializedName: "isForProject",
        type: {
            name: "Boolean"
        }
    }
};
export var isIgnored = {
    parameterPath: [
        "options",
        "isIgnored"
    ],
    mapper: {
        serializedName: "isIgnored",
        type: {
            name: "Boolean"
        }
    }
};
export var isIncludeSkipped = {
    parameterPath: [
        "options",
        "isIncludeSkipped"
    ],
    mapper: {
        serializedName: "IsIncludeSkipped",
        type: {
            name: "Boolean"
        }
    }
};
export var isLastPassedGateOnly = {
    parameterPath: [
        "options",
        "isLastPassedGateOnly"
    ],
    mapper: {
        serializedName: "IsLastPassedGateOnly",
        type: {
            name: "Boolean"
        }
    }
};
export var isMandatory = {
    parameterPath: [
        "options",
        "isMandatory"
    ],
    mapper: {
        serializedName: "isMandatory",
        type: {
            name: "Boolean"
        }
    }
};
export var isOverdueGateOnly = {
    parameterPath: [
        "options",
        "isOverdueGateOnly"
    ],
    mapper: {
        serializedName: "IsOverdueGateOnly",
        type: {
            name: "Boolean"
        }
    }
};
export var jobName0 = {
    parameterPath: "jobName",
    mapper: {
        required: true,
        serializedName: "jobName",
        type: {
            name: "String"
        }
    }
};
export var jobName1 = {
    parameterPath: [
        "options",
        "jobName"
    ],
    mapper: {
        serializedName: "jobName",
        type: {
            name: "String"
        }
    }
};
export var key0 = {
    parameterPath: [
        "options",
        "key"
    ],
    mapper: {
        serializedName: "key",
        type: {
            name: "String"
        }
    }
};
export var key1 = {
    parameterPath: [
        "options",
        "key"
    ],
    mapper: {
        serializedName: "Key",
        type: {
            name: "String"
        }
    }
};
export var leaderPrincipalEmail = {
    parameterPath: [
        "options",
        "leaderPrincipalEmail"
    ],
    mapper: {
        serializedName: "LeaderPrincipalEmail",
        type: {
            name: "String"
        }
    }
};
export var leadOrgUnit = {
    parameterPath: [
        "options",
        "leadOrgUnit"
    ],
    mapper: {
        serializedName: "leadOrgUnit",
        type: {
            name: "String"
        }
    }
};
export var link = {
    parameterPath: [
        "options",
        "link"
    ],
    mapper: {
        serializedName: "link",
        type: {
            name: "String"
        }
    }
};
export var manualTaskId0 = {
    parameterPath: [
        "options",
        "manualTaskId"
    ],
    mapper: {
        serializedName: "manualTaskId",
        type: {
            name: "Number"
        }
    }
};
export var manualTaskId1 = {
    parameterPath: "manualTaskId",
    mapper: {
        required: true,
        serializedName: "manualTaskId",
        type: {
            name: "Number"
        }
    }
};
export var masterChecklistItemId = {
    parameterPath: [
        "options",
        "masterChecklistItemId"
    ],
    mapper: {
        serializedName: "masterChecklistItemId",
        type: {
            name: "Number"
        }
    }
};
export var masterFileMode = {
    parameterPath: [
        "options",
        "masterFileMode"
    ],
    mapper: {
        serializedName: "masterFileMode",
        type: {
            name: "Boolean"
        }
    }
};
export var meetingEndDateTimeFrom = {
    parameterPath: [
        "options",
        "meetingEndDateTimeFrom"
    ],
    mapper: {
        serializedName: "MeetingEndDateTimeFrom",
        type: {
            name: "DateTime"
        }
    }
};
export var meetingEndDateTimeTo = {
    parameterPath: [
        "options",
        "meetingEndDateTimeTo"
    ],
    mapper: {
        serializedName: "MeetingEndDateTimeTo",
        type: {
            name: "DateTime"
        }
    }
};
export var meetingId0 = {
    parameterPath: [
        "options",
        "meetingId"
    ],
    mapper: {
        serializedName: "meetingId",
        type: {
            name: "Number"
        }
    }
};
export var meetingId1 = {
    parameterPath: "meetingId",
    mapper: {
        required: true,
        serializedName: "meetingId",
        type: {
            name: "Number"
        }
    }
};
export var meetingStartDateTimeFrom = {
    parameterPath: [
        "options",
        "meetingStartDateTimeFrom"
    ],
    mapper: {
        serializedName: "MeetingStartDateTimeFrom",
        type: {
            name: "DateTime"
        }
    }
};
export var meetingStartDateTimeTo = {
    parameterPath: [
        "options",
        "meetingStartDateTimeTo"
    ],
    mapper: {
        serializedName: "MeetingStartDateTimeTo",
        type: {
            name: "DateTime"
        }
    }
};
export var messageType = {
    parameterPath: [
        "options",
        "messageType"
    ],
    mapper: {
        serializedName: "messageType",
        type: {
            name: "String"
        }
    }
};
export var milestoneNumbers = {
    parameterPath: [
        "options",
        "milestoneNumbers"
    ],
    mapper: {
        serializedName: "MilestoneNumbers",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "Number"
                }
            }
        }
    },
    collectionFormat: msRest.QueryCollectionFormat.Csv
};
export var name = {
    parameterPath: [
        "options",
        "name"
    ],
    mapper: {
        serializedName: "name",
        type: {
            name: "String"
        }
    }
};
export var needAttention = {
    parameterPath: [
        "options",
        "needAttention"
    ],
    mapper: {
        serializedName: "needAttention",
        type: {
            name: "Boolean"
        }
    }
};
export var nextGateId = {
    parameterPath: [
        "options",
        "nextGateId"
    ],
    mapper: {
        serializedName: "nextGateId",
        type: {
            name: "Number"
        }
    }
};
export var norrdProjectId0 = {
    parameterPath: [
        "options",
        "norrdProjectId"
    ],
    mapper: {
        serializedName: "norrdProjectId",
        type: {
            name: "Number"
        }
    }
};
export var norrdProjectId1 = {
    parameterPath: "norrdProjectId",
    mapper: {
        required: true,
        serializedName: "norrdProjectId",
        type: {
            name: "Number"
        }
    }
};
export var objectUniqueId0 = {
    parameterPath: [
        "options",
        "objectUniqueId"
    ],
    mapper: {
        serializedName: "objectUniqueId",
        type: {
            name: "String"
        }
    }
};
export var objectUniqueId1 = {
    parameterPath: [
        "options",
        "objectUniqueId"
    ],
    mapper: {
        serializedName: "ObjectUniqueId",
        type: {
            name: "String"
        }
    }
};
export var orderBy = {
    parameterPath: [
        "options",
        "orderBy"
    ],
    mapper: {
        serializedName: "orderBy",
        type: {
            name: "String"
        }
    }
};
export var orgUnitDisplayName = {
    parameterPath: [
        "options",
        "orgUnitDisplayName"
    ],
    mapper: {
        serializedName: "OrgUnitDisplayName",
        type: {
            name: "String"
        }
    }
};
export var orgUnitId0 = {
    parameterPath: [
        "options",
        "orgUnitId"
    ],
    mapper: {
        serializedName: "OrgUnitId",
        type: {
            name: "Number"
        }
    }
};
export var orgUnitId1 = {
    parameterPath: [
        "options",
        "orgUnitId"
    ],
    mapper: {
        serializedName: "orgUnitId",
        type: {
            name: "Number"
        }
    }
};
export var page = {
    parameterPath: [
        "options",
        "page"
    ],
    mapper: {
        serializedName: "page",
        type: {
            name: "Number"
        }
    }
};
export var passedGateNumbers = {
    parameterPath: [
        "options",
        "passedGateNumbers"
    ],
    mapper: {
        serializedName: "PassedGateNumbers",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "Number"
                }
            }
        }
    },
    collectionFormat: msRest.QueryCollectionFormat.Csv
};
export var passedGateYear = {
    parameterPath: [
        "options",
        "passedGateYear"
    ],
    mapper: {
        serializedName: "PassedGateYear",
        type: {
            name: "Number"
        }
    }
};
export var permissionIds = {
    parameterPath: [
        "options",
        "permissionIds"
    ],
    mapper: {
        serializedName: "permissionIds",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "Number"
                }
            }
        }
    },
    collectionFormat: msRest.QueryCollectionFormat.Csv
};
export var pjMPrincipalEmail = {
    parameterPath: [
        "options",
        "pjMPrincipalEmail"
    ],
    mapper: {
        serializedName: "PjMPrincipalEmail",
        type: {
            name: "String"
        }
    }
};
export var plannedEndDateFrom = {
    parameterPath: [
        "options",
        "plannedEndDateFrom"
    ],
    mapper: {
        serializedName: "PlannedEndDateFrom",
        type: {
            name: "DateTime"
        }
    }
};
export var plannedEndDateTo = {
    parameterPath: [
        "options",
        "plannedEndDateTo"
    ],
    mapper: {
        serializedName: "PlannedEndDateTo",
        type: {
            name: "DateTime"
        }
    }
};
export var plannedStartDateFrom = {
    parameterPath: [
        "options",
        "plannedStartDateFrom"
    ],
    mapper: {
        serializedName: "PlannedStartDateFrom",
        type: {
            name: "DateTime"
        }
    }
};
export var plannedStartDateTo = {
    parameterPath: [
        "options",
        "plannedStartDateTo"
    ],
    mapper: {
        serializedName: "PlannedStartDateTo",
        type: {
            name: "DateTime"
        }
    }
};
export var postFix = {
    parameterPath: [
        "options",
        "postFix"
    ],
    mapper: {
        serializedName: "postFix",
        type: {
            name: "String"
        }
    }
};
export var previousGateId = {
    parameterPath: [
        "options",
        "previousGateId"
    ],
    mapper: {
        serializedName: "previousGateId",
        type: {
            name: "Number"
        }
    }
};
export var principalCodeName = {
    parameterPath: [
        "options",
        "principalCodeName"
    ],
    mapper: {
        serializedName: "PrincipalCodeName",
        type: {
            name: "String"
        }
    }
};
export var principalDisplayName = {
    parameterPath: [
        "options",
        "principalDisplayName"
    ],
    mapper: {
        serializedName: "PrincipalDisplayName",
        type: {
            name: "String"
        }
    }
};
export var principalEmail = {
    parameterPath: [
        "options",
        "principalEmail"
    ],
    mapper: {
        serializedName: "PrincipalEmail",
        type: {
            name: "String"
        }
    }
};
export var principalId0 = {
    parameterPath: [
        "options",
        "principalId"
    ],
    mapper: {
        serializedName: "principalId",
        type: {
            name: "Number"
        }
    }
};
export var principalId1 = {
    parameterPath: [
        "options",
        "principalId"
    ],
    mapper: {
        serializedName: "PrincipalId",
        type: {
            name: "Number"
        }
    }
};
export var projectCodeName0 = {
    parameterPath: [
        "options",
        "projectCodeName"
    ],
    mapper: {
        serializedName: "ProjectCodeName",
        type: {
            name: "String"
        }
    }
};
export var projectCodeName1 = {
    parameterPath: [
        "options",
        "projectCodeName"
    ],
    mapper: {
        serializedName: "projectCodeName",
        type: {
            name: "String"
        }
    }
};
export var projectCodeName2 = {
    parameterPath: "projectCodeName",
    mapper: {
        required: true,
        serializedName: "projectCodeName",
        type: {
            name: "String"
        }
    }
};
export var projectDisplayNameAndNumber = {
    parameterPath: [
        "options",
        "projectDisplayNameAndNumber"
    ],
    mapper: {
        serializedName: "ProjectDisplayNameAndNumber",
        type: {
            name: "String"
        }
    }
};
export var projectId0 = {
    parameterPath: [
        "options",
        "projectId"
    ],
    mapper: {
        serializedName: "projectId",
        type: {
            name: "Number"
        }
    }
};
export var projectId1 = {
    parameterPath: [
        "options",
        "projectId"
    ],
    mapper: {
        serializedName: "ProjectId",
        type: {
            name: "Number"
        }
    }
};
export var projectId2 = {
    parameterPath: "projectId",
    mapper: {
        required: true,
        serializedName: "projectId",
        type: {
            name: "Number"
        }
    }
};
export var projectLeaderIds = {
    parameterPath: [
        "options",
        "projectLeaderIds"
    ],
    mapper: {
        serializedName: "ProjectLeaderIds",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "Number"
                }
            }
        }
    },
    collectionFormat: msRest.QueryCollectionFormat.Csv
};
export var projectManagerRating = {
    parameterPath: [
        "options",
        "projectManagerRating"
    ],
    mapper: {
        serializedName: "ProjectManagerRating",
        type: {
            name: "String"
        }
    }
};
export var projectName = {
    parameterPath: [
        "options",
        "projectName"
    ],
    mapper: {
        serializedName: "projectName",
        type: {
            name: "String"
        }
    }
};
export var projectNumber = {
    parameterPath: [
        "options",
        "projectNumber"
    ],
    mapper: {
        serializedName: "projectNumber",
        type: {
            name: "String"
        }
    }
};
export var projectState = {
    parameterPath: [
        "options",
        "projectState"
    ],
    mapper: {
        serializedName: "projectState",
        type: {
            name: "String"
        }
    }
};
export var projectTemplateId0 = {
    parameterPath: [
        "options",
        "projectTemplateId"
    ],
    mapper: {
        serializedName: "projectTemplateId",
        type: {
            name: "Number"
        }
    }
};
export var projectTemplateId1 = {
    parameterPath: "projectTemplateId",
    mapper: {
        required: true,
        serializedName: "projectTemplateId",
        type: {
            name: "Number"
        }
    }
};
export var rating = {
    parameterPath: [
        "options",
        "rating"
    ],
    mapper: {
        serializedName: "Rating",
        type: {
            name: "String"
        }
    }
};
export var redoGateId = {
    parameterPath: [
        "options",
        "redoGateId"
    ],
    mapper: {
        serializedName: "redoGateId",
        type: {
            name: "Number"
        }
    }
};
export var relatedFunctionEmail = {
    parameterPath: [
        "options",
        "relatedFunctionEmail"
    ],
    mapper: {
        serializedName: "RelatedFunctionEmail",
        type: {
            name: "String"
        }
    }
};
export var relatedFunctionId = {
    parameterPath: [
        "options",
        "relatedFunctionId"
    ],
    mapper: {
        serializedName: "relatedFunctionId",
        type: {
            name: "Number"
        }
    }
};
export var relatedFunctionPrincipalEmail = {
    parameterPath: [
        "options",
        "relatedFunctionPrincipalEmail"
    ],
    mapper: {
        serializedName: "RelatedFunctionPrincipalEmail",
        type: {
            name: "String"
        }
    }
};
export var relatedObjectCodeName = {
    parameterPath: [
        "options",
        "relatedObjectCodeName"
    ],
    mapper: {
        serializedName: "RelatedObjectCodeName",
        type: {
            name: "String"
        }
    }
};
export var responsibleOrgUnitCodeName = {
    parameterPath: [
        "options",
        "responsibleOrgUnitCodeName"
    ],
    mapper: {
        serializedName: "ResponsibleOrgUnitCodeName",
        type: {
            name: "String"
        }
    }
};
export var responsibleOrgUnitId = {
    parameterPath: [
        "options",
        "responsibleOrgUnitId"
    ],
    mapper: {
        serializedName: "ResponsibleOrgUnitId",
        type: {
            name: "Number"
        }
    }
};
export var roleId0 = {
    parameterPath: [
        "options",
        "roleId"
    ],
    mapper: {
        serializedName: "roleId",
        type: {
            name: "Number"
        }
    }
};
export var roleId1 = {
    parameterPath: [
        "options",
        "roleId"
    ],
    mapper: {
        serializedName: "RoleId",
        type: {
            name: "Number"
        }
    }
};
export var section = {
    parameterPath: [
        "options",
        "section"
    ],
    mapper: {
        serializedName: "section",
        type: {
            name: "String"
        }
    }
};
export var showDeletedDocuments = {
    parameterPath: [
        "options",
        "showDeletedDocuments"
    ],
    mapper: {
        serializedName: "showDeletedDocuments",
        type: {
            name: "Boolean"
        }
    }
};
export var showDeletedFiles = {
    parameterPath: [
        "options",
        "showDeletedFiles"
    ],
    mapper: {
        serializedName: "showDeletedFiles",
        type: {
            name: "Boolean"
        }
    }
};
export var showDisplayName = {
    parameterPath: [
        "options",
        "showDisplayName"
    ],
    mapper: {
        serializedName: "showDisplayName",
        type: {
            name: "Boolean"
        }
    }
};
export var size0 = {
    parameterPath: [
        "options",
        "size"
    ],
    mapper: {
        serializedName: "size",
        defaultValue: 15,
        type: {
            name: "Number"
        }
    }
};
export var size1 = {
    parameterPath: [
        "options",
        "size"
    ],
    mapper: {
        serializedName: "size",
        type: {
            name: "Number"
        }
    }
};
export var sortByName = {
    parameterPath: [
        "options",
        "sortByName"
    ],
    mapper: {
        serializedName: "sortByName",
        type: {
            name: "String"
        }
    }
};
export var startContainerNumber = {
    parameterPath: [
        "options",
        "startContainerNumber"
    ],
    mapper: {
        serializedName: "StartContainerNumber",
        type: {
            name: "Number"
        }
    }
};
export var state = {
    parameterPath: [
        "options",
        "state"
    ],
    mapper: {
        serializedName: "state",
        type: {
            name: "String"
        }
    }
};
export var status0 = {
    parameterPath: [
        "options",
        "status"
    ],
    mapper: {
        serializedName: "status",
        type: {
            name: "String"
        }
    }
};
export var status1 = {
    parameterPath: [
        "options",
        "status"
    ],
    mapper: {
        serializedName: "Status",
        type: {
            name: "String"
        }
    }
};
export var statuses = {
    parameterPath: [
        "options",
        "statuses"
    ],
    mapper: {
        serializedName: "Statuses",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "String"
                }
            }
        }
    },
    collectionFormat: msRest.QueryCollectionFormat.Csv
};
export var taskId = {
    parameterPath: [
        "options",
        "taskId"
    ],
    mapper: {
        serializedName: "taskId",
        type: {
            name: "Number"
        }
    }
};
export var teamMemberIds = {
    parameterPath: [
        "options",
        "teamMemberIds"
    ],
    mapper: {
        serializedName: "TeamMemberIds",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "Number"
                }
            }
        }
    },
    collectionFormat: msRest.QueryCollectionFormat.Csv
};
export var term = {
    parameterPath: [
        "options",
        "term"
    ],
    mapper: {
        serializedName: "term",
        type: {
            name: "String"
        }
    }
};
export var toChecklistId = {
    parameterPath: [
        "options",
        "toChecklistId"
    ],
    mapper: {
        serializedName: "toChecklistId",
        type: {
            name: "Number"
        }
    }
};
export var triggerId = {
    parameterPath: [
        "options",
        "triggerId"
    ],
    mapper: {
        serializedName: "triggerId",
        type: {
            name: "String"
        }
    }
};
export var triggerName0 = {
    parameterPath: [
        "options",
        "triggerName"
    ],
    mapper: {
        serializedName: "triggerName",
        type: {
            name: "String"
        }
    }
};
export var triggerName1 = {
    parameterPath: "triggerName",
    mapper: {
        required: true,
        serializedName: "triggerName",
        type: {
            name: "String"
        }
    }
};
export var type = {
    parameterPath: [
        "options",
        "type"
    ],
    mapper: {
        serializedName: "Type",
        type: {
            name: "String"
        }
    }
};
export var uniqueId = {
    parameterPath: [
        "options",
        "uniqueId"
    ],
    mapper: {
        serializedName: "uniqueId",
        type: {
            name: "String"
        }
    }
};
export var value0 = {
    parameterPath: [
        "options",
        "value"
    ],
    mapper: {
        serializedName: "Value",
        type: {
            name: "String"
        }
    }
};
export var value1 = {
    parameterPath: [
        "options",
        "value"
    ],
    mapper: {
        serializedName: "value",
        type: {
            name: "Boolean"
        }
    }
};
export var version = {
    parameterPath: [
        "options",
        "version"
    ],
    mapper: {
        serializedName: "version",
        type: {
            name: "Number"
        }
    }
};
export var versionNumber = {
    parameterPath: [
        "options",
        "versionNumber"
    ],
    mapper: {
        serializedName: "versionNumber",
        type: {
            name: "Number"
        }
    }
};
export var withVersion = {
    parameterPath: [
        "options",
        "withVersion"
    ],
    mapper: {
        serializedName: "withVersion",
        defaultValue: false,
        type: {
            name: "Boolean"
        }
    }
};
export var xScaleUnit = {
    parameterPath: [
        "options",
        "xScaleUnit"
    ],
    mapper: {
        serializedName: "xScaleUnit",
        type: {
            name: "String"
        }
    }
};
export var years = {
    parameterPath: [
        "options",
        "years"
    ],
    mapper: {
        serializedName: "Years",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "Number"
                }
            }
        }
    },
    collectionFormat: msRest.QueryCollectionFormat.Csv
};
