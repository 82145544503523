var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { observer } from 'mobx-react';
import { Grid } from '@app/Components/Grid/Grid';
import { Column, FilterRow, Scrolling, SearchPanel, HeaderFilter } from 'devextreme-react/data-grid';
export var JobCountersTable = /** @class */ (function (_super) {
    __extends(JobCountersTable, _super);
    function JobCountersTable() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    JobCountersTable.prototype.render = function () {
        var data = this.props.data;
        return (React.createElement(Grid, { dataSource: data },
            React.createElement(Column, { dataField: "serverName", groupIndex: 0, caption: "Server Name", autoExpandGroup: true }),
            React.createElement(Column, { dataField: "jobType", caption: "Job", width: 300 }),
            React.createElement(Column, { dataField: "bestDuration", caption: "Best Duration (ms)", headerFilter: { groupInterval: 50 } }),
            React.createElement(Column, { dataField: "worstDuration", caption: "Worst Duration (ms)", headerFilter: { groupInterval: 50 } }),
            React.createElement(Column, { dataField: "averageDuration", caption: "Average Duration (ms)", dataType: 'number', headerFilter: { groupInterval: 50 }, calculateCellValue: function (data) {
                    return data.averageDuration.toFixed(2);
                } }),
            React.createElement(Column, { dataField: "totalCount", caption: "Total Count", headerFilter: { groupInterval: 25 } }),
            React.createElement(Column, { dataField: "totalRunningTime", caption: "Total Running Time (ms)", headerFilter: { groupInterval: 50 } }),
            React.createElement(HeaderFilter, { visible: true }),
            React.createElement(SearchPanel, { visible: true }),
            React.createElement(FilterRow, { visible: true }),
            React.createElement(Scrolling, { mode: "virtual" })));
    };
    JobCountersTable = __decorate([
        observer
    ], JobCountersTable);
    return JobCountersTable;
}(React.PureComponent));
