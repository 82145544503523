var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { extendObservable, observable, action, computed } from 'mobx';
import { Form, FormGroup, Label, Col, Input } from 'reactstrap';
import { observer } from 'mobx-react';
import { DateTime, ParameterType } from '@app/AppConstants';
import { Api } from '@app/Api';
import { BaseFormModel } from '@app/Services/BaseFormModel';
import DateTimeService from '@app/Services/DateTimeService';
import { addDateValidator, addDateTimeValidator, addNumberValidator, addRequiredValidator, isDateTime } from '@app/Services/Validation';
import { FormCheckbox, FormInput, FormSelect, FormSwitcher } from '@app/Components/FormControls';
import { DatePickerValidatedControl } from '@app/Components/DateTimeControls';
import { DatePicker } from '@app/Components/DatePicker';
import { Modal } from '@app/Components/Modal/Modal';
var FormModel = /** @class */ (function (_super) {
    __extends(FormModel, _super);
    function FormModel(parameters) {
        var _this = _super.call(this) || this;
        _this.triggerName = '';
        _this.isActive = true;
        _this.cronSchedule = '';
        _this.dateTimeSchedule = '';
        _this.needUseCron = false;
        parameters.forEach(function (jobParameter) {
            var _a;
            extendObservable(_this, (_a = {},
                _a[jobParameter.name] = '',
                _a));
            if (jobParameter.isRequired)
                addRequiredValidator(_this, jobParameter.name, '', jobParameter.displayName);
            switch (jobParameter.type) {
                case ParameterType.DateTime:
                    addDateTimeValidator(_this, jobParameter.name, '', jobParameter.displayName);
                    break;
                case ParameterType.Date:
                    addDateValidator(_this, jobParameter.name, '', jobParameter.displayName);
                    break;
                case ParameterType.Integer:
                    addNumberValidator(_this, jobParameter.name, '', jobParameter.displayName);
                    break;
            }
        });
        return _this;
    }
    __decorate([
        observable
    ], FormModel.prototype, "triggerName", void 0);
    __decorate([
        observable
    ], FormModel.prototype, "isActive", void 0);
    __decorate([
        observable
    ], FormModel.prototype, "cronSchedule", void 0);
    __decorate([
        observable,
        isDateTime()
    ], FormModel.prototype, "dateTimeSchedule", void 0);
    __decorate([
        observable
    ], FormModel.prototype, "needUseCron", void 0);
    return FormModel;
}(BaseFormModel));
export var JobParametersDialog = /** @class */ (function (_super) {
    __extends(JobParametersDialog, _super);
    function JobParametersDialog(props) {
        var _this = _super.call(this, props) || this;
        _this._jobNameSpan = React.createRef();
        _this._jobNameInput = React.createRef();
        _this._controlRenderer = function (jobParameter) {
            var control;
            if (!_this._store.form)
                return;
            switch (jobParameter.type) {
                case ParameterType.DateTime:
                    control = (React.createElement(DatePickerValidatedControl, { pickerMode: "datetime", formModel: _this._store.form, name: jobParameter.name }));
                    break;
                case ParameterType.Date:
                    control = (React.createElement(DatePickerValidatedControl, { pickerMode: "date", formModel: _this._store.form, name: jobParameter.name }));
                    break;
                case ParameterType.Boolean:
                    control = (React.createElement(FormCheckbox, { label: jobParameter.displayName, formModel: _this._store.form, name: jobParameter.name, checked: false }));
                    break;
                case ParameterType.MultiLine:
                    control = React.createElement(FormInput, { formModel: _this._store.form, name: jobParameter.name, type: "textarea" });
                    break;
                case ParameterType.Enum: {
                    control = (React.createElement(FormSelect, { formModel: _this._store.form, name: jobParameter.name, options: jobParameter.enumValues }));
                    break;
                }
                default:
                    control = React.createElement(FormInput, { formModel: _this._store.form, name: jobParameter.name });
                    break;
            }
            return (React.createElement(FormGroup, { row: true, key: 'field_' + jobParameter.name },
                React.createElement(Label, { sm: 4 }, jobParameter.type === ParameterType.Boolean ? '' : jobParameter.displayName),
                React.createElement(Col, { sm: 8 }, control)));
        };
        _this._onChange = function (event) {
            var id = event.target.id;
            var idParts = id.split('_');
            if (idParts.length !== 2) {
                throw new Error('Invalid element id=' + id);
            }
            var radioNumber = +id.split('_')[1];
            if (_this._store.form) {
                _this._store.form.needUseCron = !radioNumber;
            }
        };
        _this._store = new JobParametersDialogStore(props.jobTrigger, props.jobName, props.onSave);
        return _this;
    }
    JobParametersDialog.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var span, input;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._store._getJobParams()];
                    case 1:
                        _a.sent();
                        span = this._jobNameSpan.current;
                        input = this._jobNameInput.current;
                        if (input) {
                            input.style.paddingLeft = span.clientWidth.toString() + 'px';
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    JobParametersDialog.prototype.render = function () {
        var _a, _b;
        var isOpen = (_a = this.props, _a.isOpen), onClose = _a.onClose;
        var form = (_b = this._store, _b.form), hasFormData = _b.hasFormData, jobName = _b.jobName;
        return (React.createElement(Modal, { isOpen: isOpen, toggle: onClose, headerTitle: 'Job Parameters:' + jobName, footerbuttons: this._getFooterButtons() }, form && hasFormData ? (React.createElement(Form, null,
            React.createElement(FormGroup, { row: true },
                React.createElement(Label, { sm: 4 }, "Active"),
                React.createElement(Col, { sm: 8 },
                    React.createElement(FormSwitcher, { formModel: form, name: "isActive", checked: false }))),
            React.createElement(FormGroup, { row: true },
                React.createElement(Label, { sm: 3 }, "Use Cron"),
                React.createElement(Col, { sm: 1 },
                    React.createElement(Input, { checked: form.needUseCron, type: "radio", name: "radioChooseAlternative", id: "needUseCron_0", onChange: this._onChange }),
                    ' '),
                React.createElement(Col, { sm: 7 },
                    React.createElement(FormInput, { disabled: !form.needUseCron, formModel: form, name: "cronSchedule" }))),
            React.createElement(FormGroup, { row: true },
                React.createElement(Label, { sm: 3 }, "Start Once"),
                React.createElement(Col, { sm: 1 },
                    React.createElement(Input, { checked: !form.needUseCron, type: "radio", name: "radioChooseAlternative", id: "needUseCron_1", onChange: this._onChange }),
                    ' '),
                React.createElement(Col, { sm: 7 },
                    React.createElement(DatePicker, { disabled: form.needUseCron, onDateChanged: this._onDateChange, type: "datetime", displayFormat: "dd MMM yyyy" }))),
            this._store.parameters.map(this._controlRenderer))) : null));
    };
    JobParametersDialog.prototype._getFooterButtons = function () {
        return [
            {
                color: 'success',
                onClick: this._store.saveParams,
                className: this._store._isSaving ? 'btn-loader' : '',
                title: 'Save'
            },
            {
                onClick: this.props.onClose,
                title: 'Close'
            }
        ];
    };
    JobParametersDialog.prototype._renderTriggerInfo = function () {
        var _a;
        if (!this._store.trigger)
            return '';
        var lastRunDateTime = (_a = this._store.trigger, _a.lastRunDateTime), nextRunDateTime = _a.nextRunDateTime;
        var lastRun = (lastRunDateTime && DateTimeService.toUiDateTime(lastRunDateTime)) || 'never';
        var nextRun = (nextRunDateTime && DateTimeService.toUiDateTime(nextRunDateTime)) || 'never';
        return (React.createElement("div", { className: "job-info-details" },
            "Last run: ",
            lastRun,
            " | Next run: ",
            nextRun));
    };
    JobParametersDialog.prototype._onDateChange = function (date) {
        if (this._store.form && date) {
            this._store.form.dateTimeSchedule = DateTimeService.toUiDateTime(date);
        }
    };
    __decorate([
        action.bound
    ], JobParametersDialog.prototype, "_onDateChange", null);
    JobParametersDialog = __decorate([
        observer
    ], JobParametersDialog);
    return JobParametersDialog;
}(React.Component));
var JobParametersDialogStore = /** @class */ (function () {
    function JobParametersDialogStore(jobTrigger, jobName, onSave) {
        var _this = this;
        this._isSaving = false;
        this._isDeleting = false;
        this.save = false;
        this.validityMessage = '';
        this.hasFormData = false;
        this.jobTriggerPrefix = '';
        this.data = null;
        this.parameters = [];
        this.form = null;
        this.onSave = null;
        this.updateValidityMessage = function (message) {
            if (message === void 0) { message = ''; }
            _this.validityMessage = message;
        };
        this.jobTrigger = jobTrigger;
        this.jobName = jobName;
        this.onSave = onSave ? onSave : null;
    }
    JobParametersDialogStore.prototype._getJobParams = function () {
        return __awaiter(this, void 0, void 0, function () {
            var trigger, response, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        trigger = this.trigger;
                        if (!trigger) return [3 /*break*/, 2];
                        return [4 /*yield*/, Api.secured.jobGetParameters(this.jobName, { triggerName: trigger.id })];
                    case 1:
                        response = _a.sent();
                        if (response) {
                            data = response;
                            this.data = data;
                            this.parameters = data.parameters;
                            this.form = new FormModel(this.parameters);
                            this._initFormValues();
                            this._initMainParameters();
                            this.hasFormData = true;
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    JobParametersDialogStore.prototype._initFormValues = function () {
        var _this = this;
        this.parameters.map(function (jobParameter) {
            if (jobParameter.value) {
                var parameterValues = JSON.parse(jobParameter.value);
                for (var prop in parameterValues) {
                    var date = DateTimeService.parse(parameterValues[prop], DateTime.jsonDateFormat);
                    if (!isNaN(date.getTime())) {
                        parameterValues[prop] = DateTimeService.toUiDate(date);
                    }
                    if (_this.form) {
                        _this.form[prop] = parameterValues[prop];
                    }
                }
            }
        });
    };
    JobParametersDialogStore.prototype._initMainParameters = function () {
        if (!this.form || !this.data)
            return;
        var date = '';
        if (this.trigger) {
            date = this.trigger.nextRunDateTime ? DateTimeService.toUiDateTime(this.trigger.nextRunDateTime) : '';
            this.form.isActive = this.trigger.isActive;
            this.form.cronSchedule = this.trigger.cronSchedule || '';
        }
        else {
            date = this.data.dateTimeSchedule ? DateTimeService.toUiDateTime(this.data.dateTimeSchedule) : '';
            this.form.cronSchedule = this.data.cronSchedule || '';
        }
        this.form.needUseCron = !!this.form.cronSchedule;
        this.form.dateTimeSchedule = this.form.needUseCron ? '' : date;
    };
    JobParametersDialogStore.prototype.saveParams = function () {
        return __awaiter(this, void 0, void 0, function () {
            var id, jobParams, dateTimeSchedule, jobDetails;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.trigger || !this.data)
                            return [2 /*return*/];
                        if (!this.form || !this.form.validate())
                            return [2 /*return*/];
                        id = this.trigger.id;
                        jobParams = [];
                        this.parameters.forEach(function (jobParameter) {
                            var name = jobParameter.name;
                            if (_this.form) {
                                var value = _this.form[name];
                                switch (jobParameter.type) {
                                    case ParameterType.DateTime: {
                                        value = value =
                                            value &&
                                                DateTimeService.format(DateTimeService.parseUiDateTime(value), DateTime.jsonFullFormat);
                                        break;
                                    }
                                    case ParameterType.Date: {
                                        value =
                                            value &&
                                                DateTimeService.format(DateTimeService.parseUiDate(value), DateTime.jsonDateFormat);
                                        break;
                                    }
                                    case ParameterType.Boolean: {
                                        value = value.toString();
                                        break;
                                    }
                                }
                                if (value)
                                    jobParams.push({ name: name, value: value });
                            }
                        });
                        dateTimeSchedule = (this.form.dateTimeSchedule && DateTimeService.parseUiDateTime(this.form.dateTimeSchedule)) || null;
                        jobDetails = {
                            jobName: this.data ? this.data.name : '',
                            parameters: jobParams,
                            triggerName: id || this.jobTriggerPrefix + this.form.triggerName,
                            isActive: this.form.isActive,
                            cronSchedule: this.form.needUseCron ? this.form.cronSchedule : '',
                            dateTimeSchedule: (!this.form.needUseCron && dateTimeSchedule) || undefined
                        };
                        return [4 /*yield*/, Api.secured.jobSaveParameters(id, { body: jobDetails })];
                    case 1:
                        _a.sent();
                        this.onSave && this.onSave();
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(JobParametersDialogStore.prototype, "trigger", {
        get: function () {
            var _a;
            return (_a = this.jobTrigger) !== null && _a !== void 0 ? _a : null;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], JobParametersDialogStore.prototype, "_isSaving", void 0);
    __decorate([
        observable
    ], JobParametersDialogStore.prototype, "_isDeleting", void 0);
    __decorate([
        observable
    ], JobParametersDialogStore.prototype, "save", void 0);
    __decorate([
        observable
    ], JobParametersDialogStore.prototype, "validityMessage", void 0);
    __decorate([
        observable
    ], JobParametersDialogStore.prototype, "hasFormData", void 0);
    __decorate([
        action
    ], JobParametersDialogStore.prototype, "_getJobParams", null);
    __decorate([
        action.bound
    ], JobParametersDialogStore.prototype, "saveParams", null);
    __decorate([
        computed
    ], JobParametersDialogStore.prototype, "trigger", null);
    __decorate([
        action
    ], JobParametersDialogStore.prototype, "updateValidityMessage", void 0);
    return JobParametersDialogStore;
}());
