var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { observer } from 'mobx-react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { computed } from 'mobx';
import DateTimeService from '../../../Services/DateTimeService';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
export var LineGraph = /** @class */ (function (_super) {
    __extends(LineGraph, _super);
    function LineGraph() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LineGraph.prototype.render = function () {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { style: { height: '300px' } },
                React.createElement(Line, { data: this.graphData, options: this.chartOptions, height: 50 })),
            !((_a = this.props.data) === null || _a === void 0 ? void 0 : _a.length) && React.createElement("span", null, "No data for the period")));
    };
    Object.defineProperty(LineGraph.prototype, "graphData", {
        get: function () {
            return !this.props.data.length ? this.emptyData : this.serverGraphData;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LineGraph.prototype, "serverGraphData", {
        get: function () {
            var _this = this;
            var timePoints = this.props.data[0].points.map(function (x) { return x.key; });
            var dataset = this.props.data.map(function (x) {
                var _a;
                var stat = new Array(timePoints.length);
                stat = stat.fill(0);
                for (var i = 0; i < x.points.length; i++) {
                    if (x.points[i].value === 0)
                        continue;
                    var index = timePoints.indexOf(x.points[i].key);
                    if (index === -1)
                        continue;
                    stat[index] = x.points[i].value;
                }
                var colors = _this.randomColors();
                return {
                    label: x.label,
                    fill: false,
                    borderColor: colors[0],
                    backgroundColor: colors[1],
                    data: stat,
                    yAxisID: (_a = x.yAxisId) !== null && _a !== void 0 ? _a : 'y'
                };
            });
            return {
                labels: timePoints,
                datasets: dataset
            };
        },
        enumerable: false,
        configurable: true
    });
    LineGraph.prototype.randomColors = function () {
        var r = Math.round(Math.random() * 255);
        var g = Math.round(Math.random() * 255);
        var b = Math.round(Math.random() * 255);
        var colors = ["rgb(".concat(r, ", ").concat(g, ", ").concat(b, ")"), "rgba(".concat(r, ", ").concat(g, ", ").concat(b, ", 0.5)")];
        return colors;
    };
    Object.defineProperty(LineGraph.prototype, "chartOptions", {
        get: function () {
            var _this = this;
            var _a, _b;
            var secondAxisKey = this.props.data.find(function (x) { return !!x.yAxisId; });
            var hasY1Axis = !!secondAxisKey;
            var yMinValue = this.props.yAxisMinValue || 0;
            var chartOptions = {
                legend: {
                    display: true
                },
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                    y: {
                        ticks: {
                            callback: function (value) {
                                return _this.props.yAxisUnitTitle ? "".concat(value, " ").concat(_this.props.yAxisUnitTitle, ".") : value;
                            }
                        },
                        title: {
                            display: !!this.props.yAxisTitle,
                            text: this.props.yAxisTitle || ''
                        },
                        min: yMinValue
                    },
                    y1: {
                        display: hasY1Axis,
                        title: {
                            display: !!this.props.y1AxisTitle,
                            text: this.props.y1AxisTitle
                        },
                        position: 'right',
                        ticks: {
                            stepSize: this.props.y1AxisStepSize
                        }
                    }
                }
            };
            chartOptions.legend.display = !((_b = (_a = this.graphData) === null || _a === void 0 ? void 0 : _a.datasets) === null || _b === void 0 ? void 0 : _b.length);
            return chartOptions;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LineGraph.prototype, "emptyData", {
        get: function () {
            var _a;
            var dateFrom = (_a = this.props, _a.dateFrom), dateTo = _a.dateTo;
            var timeLineCount = 0;
            var timeLineIncrementFunc;
            switch (this.props.xAxisScale) {
                case 'Day':
                    timeLineCount = Math.ceil(DateTimeService.diffDays(dateTo, dateFrom));
                    timeLineIncrementFunc = function (date) {
                        return DateTimeService.addDays(date, -1);
                    };
                    break;
                case 'Hour':
                    timeLineCount = Math.ceil(DateTimeService.diffHours(dateTo, dateFrom));
                    timeLineIncrementFunc = function (date) {
                        return DateTimeService.addTime(date, -1);
                    };
                    break;
                default:
                    timeLineIncrementFunc = function (date) {
                        return DateTimeService.addTime(date, -1);
                    };
            }
            var labels = [];
            var data = new Array(timeLineCount);
            var time = new Date(dateTo.getTime());
            for (var i = 0; i < timeLineCount; i++) {
                var label = DateTimeService.toUiTime(time);
                labels.push(label);
                time = timeLineIncrementFunc === null || timeLineIncrementFunc === void 0 ? void 0 : timeLineIncrementFunc(time);
            }
            return {
                labels: labels.reverse(),
                datasets: [
                    {
                        label: '',
                        fill: false,
                        backgroundColor: 'rgba(191, 175, 127, 0.4)',
                        borderColor: 'rgba(191,175,127,1)',
                        data: data
                    }
                ]
            };
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        computed
    ], LineGraph.prototype, "serverGraphData", null);
    __decorate([
        computed
    ], LineGraph.prototype, "chartOptions", null);
    __decorate([
        computed
    ], LineGraph.prototype, "emptyData", null);
    LineGraph = __decorate([
        observer
    ], LineGraph);
    return LineGraph;
}(React.PureComponent));
