var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ObjectType } from '@app/AppConstants';
import { Button } from '@app/Components/Button/Button';
import { Grid } from '@app/Components/Grid/Grid';
import { Input } from '@app/Components/Input/Input';
import { createUniqueId } from '@app/Helpers/UidHelper/UidHelper';
import { Column, FilterRow } from 'devextreme-react/data-grid';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { FaTrash } from 'react-icons/fa';
import styles from './DocumentCategoryDialogGrid.module.scss';
import { ConfirmationDialog } from '@app/Widgets/ConfirmationDialog/ConfirmationDialog';
import { TextCell } from '@app/Components/Grid/TextCell/TextCell';
export var DocumentCategoryDialogGrid = /** @class */ (function (_super) {
    __extends(DocumentCategoryDialogGrid, _super);
    function DocumentCategoryDialogGrid() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._gridRef = React.createRef();
        _this._isDocumentCategoryDeleteModalOpen = false;
        _this._isDocumentCategoryUpdateModalOpen = false;
        _this._currentDocumentCategoryToDelete = undefined;
        _this._currentDocumentCategoryToUpdate = undefined;
        return _this;
    }
    DocumentCategoryDialogGrid.prototype._toggleDocumentCategoryDeleteModalOpen = function () {
        this._isDocumentCategoryDeleteModalOpen = !this._isDocumentCategoryDeleteModalOpen;
        if (!this._isDocumentCategoryDeleteModalOpen) {
            this._currentDocumentCategoryToDelete = undefined;
        }
    };
    DocumentCategoryDialogGrid.prototype._handleDocumentCategoryDeleteClick = function (documentCategory) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._currentDocumentCategoryToDelete = documentCategory;
                this._toggleDocumentCategoryDeleteModalOpen();
                return [2 /*return*/];
            });
        });
    };
    DocumentCategoryDialogGrid.prototype._toggleDocumentCategoryUpdateModalOpen = function (isCancel) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._isDocumentCategoryUpdateModalOpen = !this._isDocumentCategoryUpdateModalOpen;
                        if (!isCancel) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.props.onReload()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!this._isDocumentCategoryUpdateModalOpen) {
                            this._currentDocumentCategoryToUpdate = undefined;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    DocumentCategoryDialogGrid.prototype._handleDocumentCategoryUpdateClick = function (documentCategory) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!documentCategory.hasRelatedDocuments) return [3 /*break*/, 2];
                        this._currentDocumentCategoryToUpdate = documentCategory;
                        return [4 /*yield*/, this._toggleDocumentCategoryUpdateModalOpen()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this._handleDocumentCategoryUpdate(documentCategory)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DocumentCategoryDialogGrid.prototype._handleDocumentCategoryUpdate = function (documentCategory) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!documentCategory) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.props.onUpdate(documentCategory)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!this._isDocumentCategoryUpdateModalOpen) return [3 /*break*/, 4];
                        return [4 /*yield*/, this._toggleDocumentCategoryUpdateModalOpen()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DocumentCategoryDialogGrid.prototype._handleDocumentCategoryDelete = function (documentCategory) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!documentCategory) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.props.onDelete(documentCategory.id)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this._toggleDocumentCategoryDeleteModalOpen();
                        return [2 /*return*/];
                }
            });
        });
    };
    DocumentCategoryDialogGrid.prototype.render = function () {
        var _a;
        var _this = this;
        var _b;
        var documentCategories = (_a = this.props, _a.documentCategories), isDataLoading = _a.isDataLoading, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted, onIsActiveInProjectChange = _a.onIsActiveInProjectChange;
        var rows = documentCategories || [];
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid, { dataSource: __spreadArray([], rows, true), rowsCount: rows.length, loading: isDataLoading, ref: this._gridRef },
                React.createElement(FilterRow, { visible: true }),
                React.createElement(Column, { caption: "Active in Project", width: 70, dataField: "isActiveInProject", alignment: "center", cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("div", { className: styles.checkbox },
                            React.createElement(Input, { disabled: isProjectTerminatedOrCompleted, type: "checkbox", checked: data.isActiveInProject, onChange: function () { return __awaiter(_this, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, onIsActiveInProjectChange(data.id, !data.isActiveInProject)];
                                            case 1:
                                                _a.sent();
                                                return [2 /*return*/];
                                        }
                                    });
                                }); } })));
                    } }),
                React.createElement(Column, { cssClass: styles.categoryNameCell, caption: "Category Name", dataField: "name", cellRender: function (_a) {
                        var _b;
                        var data = _a.data, rowIndex = _a.rowIndex;
                        return (React.createElement(TextCell, { disabled: isProjectTerminatedOrCompleted, value: (_b = data.name) !== null && _b !== void 0 ? _b : '', onChange: function (value) {
                                data.name = value || '';
                                if (_this._gridRef.current) {
                                    _this._gridRef.current.repaintRows([rowIndex]);
                                }
                            }, onSave: function () { return _this._handleDocumentCategoryUpdateClick(data); }, objectUniqueId: createUniqueId(ObjectType.DocumentCategory, data.id) }));
                    } }),
                !isProjectTerminatedOrCompleted && (React.createElement(Column, { caption: "Action", width: 100, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("div", { className: styles.actions }, true && (React.createElement(Button, { className: styles.action, color: "danger", size: "xs", onClick: function () { return _this._handleDocumentCategoryDeleteClick(data); }, disabled: isDataLoading },
                            React.createElement(FaTrash, { title: "Delete document category" })))));
                    } }))),
            React.createElement(ConfirmationDialog, { maxWidth: "500px", isOpen: this._isDocumentCategoryDeleteModalOpen, onToggle: this._toggleDocumentCategoryDeleteModalOpen, headerTitle: "Remove Category", onSave: function () { return _this._handleDocumentCategoryDelete(_this._currentDocumentCategoryToDelete); }, body: "Are you sure you want to delete the Category '".concat((_b = this._currentDocumentCategoryToDelete) === null || _b === void 0 ? void 0 : _b.name, "'?") }),
            React.createElement(ConfirmationDialog, { maxWidth: "500px", isOpen: this._isDocumentCategoryUpdateModalOpen, onToggle: this._toggleDocumentCategoryUpdateModalOpen, headerTitle: "Update Category", onSave: function () { return _this._handleDocumentCategoryUpdate(_this._currentDocumentCategoryToUpdate); }, body: 'The Category is used in some Project Document(s). Are you sure you want to proceed?' })));
    };
    __decorate([
        observable.ref
    ], DocumentCategoryDialogGrid.prototype, "_gridRef", void 0);
    __decorate([
        observable
    ], DocumentCategoryDialogGrid.prototype, "_isDocumentCategoryDeleteModalOpen", void 0);
    __decorate([
        observable
    ], DocumentCategoryDialogGrid.prototype, "_isDocumentCategoryUpdateModalOpen", void 0);
    __decorate([
        observable
    ], DocumentCategoryDialogGrid.prototype, "_currentDocumentCategoryToDelete", void 0);
    __decorate([
        observable
    ], DocumentCategoryDialogGrid.prototype, "_currentDocumentCategoryToUpdate", void 0);
    __decorate([
        action.bound
    ], DocumentCategoryDialogGrid.prototype, "_toggleDocumentCategoryDeleteModalOpen", null);
    __decorate([
        action.bound
    ], DocumentCategoryDialogGrid.prototype, "_handleDocumentCategoryDeleteClick", null);
    __decorate([
        action.bound
    ], DocumentCategoryDialogGrid.prototype, "_toggleDocumentCategoryUpdateModalOpen", null);
    __decorate([
        action.bound
    ], DocumentCategoryDialogGrid.prototype, "_handleDocumentCategoryUpdateClick", null);
    __decorate([
        action.bound
    ], DocumentCategoryDialogGrid.prototype, "_handleDocumentCategoryUpdate", null);
    __decorate([
        action.bound
    ], DocumentCategoryDialogGrid.prototype, "_handleDocumentCategoryDelete", null);
    DocumentCategoryDialogGrid = __decorate([
        observer
    ], DocumentCategoryDialogGrid);
    return DocumentCategoryDialogGrid;
}(React.PureComponent));
