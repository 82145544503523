var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { observer } from 'mobx-react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { TgTabsContent } from '../../Components/TgTabs/TgTabsContent';
import { TgTabs } from '../../Components/TgTabs/TgTabs';
import { NavTab } from 'react-router-tabs';
import { NotFound } from '../NotFound/NotFound';
import { Activities } from './StatisticTabs/Activities/Activities';
import { RequestCounters } from './StatisticTabs/RequestCounters/RequestCounters';
import { JobCounters } from './StatisticTabs/JobCounters/JobCounters';
import { UserCounters } from './StatisticTabs/UserCounters/UserCounters';
import RequestTimeline from './StatisticTabs/RequestTimeline/RequestTimeline';
import { appStore } from '../../AppStore';
export var Statistics = /** @class */ (function (_super) {
    __extends(Statistics, _super);
    function Statistics() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Statistics.prototype.render = function () {
        var _a;
        var match = (_a = this.props, _a.match), history = _a.history, location = _a.location;
        var canViewRequestTimeline = appStore.appSecurityModel.hasPrincipalAdminAccess;
        return (React.createElement(React.Fragment, null,
            React.createElement(TgTabs, { startPathWith: match.url },
                React.createElement(NavTab, { to: "/activities" }, "Activities"),
                React.createElement(NavTab, { to: "/usercounters" }, "User Counters"),
                React.createElement(NavTab, { to: "/requestcounters" }, "Request Counters"),
                React.createElement(NavTab, { to: "/jobactivities" }, "Job Activities"),
                canViewRequestTimeline && React.createElement(NavTab, { to: "/requesttimeline" }, "Request Timeline")),
            React.createElement(TgTabsContent, null,
                React.createElement(Switch, null,
                    React.createElement(Route, { path: "".concat(match.url, "/activities") },
                        React.createElement(Activities, null)),
                    React.createElement(Route, { path: "".concat(match.url, "/usercounters") },
                        React.createElement(UserCounters, null)),
                    React.createElement(Route, { path: "".concat(match.url, "/requestcounters") },
                        React.createElement(RequestCounters, null)),
                    React.createElement(Route, { path: "".concat(match.url, "/jobactivities") },
                        React.createElement(JobCounters, null)),
                    canViewRequestTimeline && (React.createElement(Route, { path: "".concat(match.url, "/requesttimeline") },
                        React.createElement(RequestTimeline, { history: history, location: location, match: match }))),
                    React.createElement(Route, { exact: true, path: "".concat(match.url) },
                        React.createElement(Redirect, { to: "".concat(match.url, "/activities") })),
                    React.createElement(Route, { path: "*", component: NotFound })))));
    };
    Statistics = __decorate([
        observer
    ], Statistics);
    return Statistics;
}(React.Component));
