var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import styles from './save-cancel.module.scss';
import { MdClose, MdCheck } from 'react-icons/md';
var SaveCancel = /** @class */ (function (_super) {
    __extends(SaveCancel, _super);
    function SaveCancel() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._onSaveClick = function (e) {
            e.stopPropagation();
            e.preventDefault();
            _this.props.onSave();
        };
        _this._onCancelClick = function (e) {
            e.stopPropagation();
            e.preventDefault();
            _this.props.onCancel();
        };
        return _this;
    }
    SaveCancel.prototype.render = function () {
        var _a = this.props, noBorder = _a.noBorder, disabled = _a.disabled;
        var cls = styles.actionButton;
        if (noBorder)
            cls = cls + ' ' + styles.actionButtonNoBorder;
        return (React.createElement("div", { className: styles.buttonsWrapper },
            React.createElement("button", { className: cls, disabled: disabled },
                React.createElement(MdCheck, { size: "20", onClick: this._onSaveClick })),
            React.createElement("button", { className: cls, onClick: this._onCancelClick },
                React.createElement(MdClose, { size: "20" }))));
    };
    return SaveCancel;
}(React.Component));
export { SaveCancel };
