var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import styles from './deny-comments-list.module.scss';
import HTMLReactParser from 'html-react-parser';
import { WithCopy } from '@app/Components/LinkWithCopy/LinkWithCopy';
import { DATE_WITH_TIME_OPTIONS, convertUTCDateToLocalDate } from '@app/Helpers/DateOptions';
var DenyCommentsList = /** @class */ (function (_super) {
    __extends(DenyCommentsList, _super);
    function DenyCommentsList() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DenyCommentsList.prototype.render = function () {
        var comments = this.props.comments;
        var text = comments
            .map(function (c) {
            return "".concat(convertUTCDateToLocalDate(c.created).toLocaleDateString('en-GB', DATE_WITH_TIME_OPTIONS), ": ").concat(c.comment || '');
        })
            .join(' ');
        return (React.createElement(WithCopy, { text: text }, comments.map(function (c, i) { return (React.createElement("div", { key: i, className: styles.comment },
            convertUTCDateToLocalDate(c.created).toLocaleDateString('en-GB', DATE_WITH_TIME_OPTIONS),
            ":",
            ' ',
            HTMLReactParser(c.comment || ''))); })));
    };
    return DenyCommentsList;
}(React.PureComponent));
export { DenyCommentsList };
