var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import styles from './Functions.module.scss';
import { Api } from '@app/Api';
import { Grid } from '@app/Components/Grid/Grid';
import { Column, Sorting } from 'devextreme-react/data-grid';
import { withRouter } from 'react-router-dom';
import { PersonCell } from './PersonCell';
import { createUniqueId } from '@app/Helpers/UidHelper/UidHelper';
import { ObjectType, FunctionCode, Status, GateProcessStage } from '@app/AppConstants';
import { IoIosCheckmark, IoIosClose } from 'react-icons/io';
import { FunctionList } from '@app/Components/Grid/FunctionList/FunctionList';
var FunctionsC = /** @class */ (function (_super) {
    __extends(FunctionsC, _super);
    function FunctionsC() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._functions = [];
        _this._loading = false;
        return _this;
    }
    FunctionsC.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadFunctions()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    FunctionsC.prototype._loadFunctions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, projectId, gate, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this.props, projectId = _a.projectId, gate = _a.gate;
                        this._loading = true;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, , 3, 4]);
                        _b = this;
                        return [4 /*yield*/, Api.secured.getGatesRelatedFunctionsByProject({ projectId: projectId, gateId: gate === null || gate === void 0 ? void 0 : gate.id })];
                    case 2:
                        _b._functions = _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this._loading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    FunctionsC.prototype._handleSaveClick = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.updateRelatedFunction({ body: rowData })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    FunctionsC.prototype._onFunctionEnableDisable = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.enableDisableRelatedFunction({
                            relatedFunctionId: rowData.id,
                            isEnabled: !rowData.isEnabled
                        })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadFunctions()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    FunctionsC.prototype.render = function () {
        var _this = this;
        var _a = this.props, editable = _a.editable, projectId = _a.projectId, isEnableDisableAllowed = _a.isEnableDisableAllowed, isNorrdProject = _a.isNorrdProject, gate = _a.gate;
        var canDeleteFunctionRepresentative = gate
            ? gate.setupStatus !== Status.Approved || gate.processStage !== GateProcessStage.PreparationAssessment
            : true;
        return (React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className: styles.table },
                React.createElement(Grid, { dataSource: this._functions.filter(function (f) { return f.isDeleted === false; }), loading: this._loading },
                    React.createElement(Sorting, { mode: "multiple" }),
                    isEnableDisableAllowed && (React.createElement(Column, { caption: "Enabled", width: 100, dataField: "isEnabled", cellRender: function (_a) {
                            var data = _a.data;
                            return (React.createElement("input", { type: "checkbox", checked: data.isEnabled, disabled: data.functionProperty.code === FunctionCode.ProjectManagement, onChange: function () { return __awaiter(_this, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, this._onFunctionEnableDisable(data)];
                                            case 1:
                                                _a.sent();
                                                return [2 /*return*/];
                                        }
                                    });
                                }); } }));
                        } })),
                    React.createElement(Column, { allowSorting: true, sortIndex: 1, sortOrder: "asc", caption: "Code", width: 100, dataField: "functionProperty.code", cellRender: function (_a) {
                            var data = _a.data;
                            return (React.createElement(FunctionList, { functions: [data.functionProperty], rowId: '' + data.id }));
                        } }),
                    React.createElement(Column, { caption: "Name", width: 320, calculateCellValue: function (value) { return value.functionProperty.name; } }),
                    React.createElement(Column, { caption: "Assigned To", dataField: "assignToName", width: 100, alignment: "center", allowSorting: false }),
                    React.createElement(Column, { caption: "Editable", alignment: "center", width: 120, dataField: "isEditable", allowSorting: true, sortIndex: 0, sortOrder: "desc", cellRender: function (_a) {
                            var data = _a.data;
                            return (React.createElement("div", { style: { textAlign: 'center' } }, data.isEditable ? (React.createElement(IoIosCheckmark, { color: "green", size: "26" })) : (React.createElement(IoIosClose, { color: "red", size: "26" }))));
                        } }),
                    React.createElement(Column, { caption: "Function Representative", minWidth: 400, cssClass: styles.editColumn, allowSorting: false, cellRender: function (_a) {
                            var data = _a.data;
                            return (React.createElement(PersonCell, { onChange: function (user) {
                                    data.principal = user;
                                }, onSave: function () { return _this._handleSaveClick(data); }, objectUniqueId: createUniqueId(ObjectType.RelatedFunction, data.id), disabled: !data.isEditable || !editable, value: data.principal, projectId: data.functionProperty.code === FunctionCode.ProjectManagement
                                    ? projectId
                                    : undefined, isClearable: data.functionProperty.code !== FunctionCode.ProjectManagement &&
                                    canDeleteFunctionRepresentative, isCreatable: data.functionProperty.code !== FunctionCode.ProjectManagement || !isNorrdProject }));
                        } })))));
    };
    __decorate([
        observable
    ], FunctionsC.prototype, "_functions", void 0);
    __decorate([
        observable
    ], FunctionsC.prototype, "_loading", void 0);
    __decorate([
        action.bound
    ], FunctionsC.prototype, "_loadFunctions", null);
    __decorate([
        action.bound
    ], FunctionsC.prototype, "_handleSaveClick", null);
    __decorate([
        action.bound
    ], FunctionsC.prototype, "_onFunctionEnableDisable", null);
    FunctionsC = __decorate([
        observer
    ], FunctionsC);
    return FunctionsC;
}(React.PureComponent));
export var Functions = withRouter(FunctionsC);
