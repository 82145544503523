var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Widget } from '@app/Components/Widget/Widget';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { Api } from '@app/Api';
import styles from './OrgUnitConfiguration.module.scss';
import { Column } from 'devextreme-react/data-grid';
import { Grid } from '@app/Components/Grid/Grid';
import { Input } from '@app/Components/Input/Input';
import { Button } from '@app/Components/Button/Button';
import { AddOrgUnitConfigDialog } from '../AddOrgUnitConfigDialog/AddOrgUnitConfigDialog';
import { EditOrgUnitTemplateDialog } from './EditOrgUnitTemplateDialog/EditOrgUnitTemplateDialog';
import { IoIosMore } from 'react-icons/io';
import { FaTrash } from 'react-icons/fa';
import { ConfirmationDialog } from '@app/Widgets/ConfirmationDialog/ConfirmationDialog';
export var ConfigurationKey;
(function (ConfigurationKey) {
    ConfigurationKey["AutoProjectCreation"] = "AutoProjectCreation";
    ConfigurationKey["AutoProjectCreationTemplate"] = "AutoProjectCreationTemplate";
})(ConfigurationKey || (ConfigurationKey = {}));
export var OrgUnitConfiguration = /** @class */ (function (_super) {
    __extends(OrgUnitConfiguration, _super);
    function OrgUnitConfiguration() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._configurations = [];
        _this._isAddModalOpen = false;
        _this._isEditOrgUnitTemplateModalOpen = false;
        _this._isDeleteModalOpen = false;
        _this._currentOrgUnitConfigGroup = null;
        _this._handleSaveClick = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._closeAddDialog();
                        return [4 /*yield*/, this._getConfigurations()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this._handleDeleteClick = function (group) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._currentOrgUnitConfigGroup = group;
                this._openDeleteModal();
                return [2 /*return*/];
            });
        }); };
        _this._handleConfigValueChange = function (config) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config.value = config.value == 'true' ? 'false' : 'true';
                        if (config.key === ConfigurationKey.AutoProjectCreation && config.value == 'true') {
                            this._openEditOrgUnitTemplateModal(config);
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, Api.secured.updateOrgUnitConfiguration(config)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._getConfigurations()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this._closeEditOrgUnitTemplateModal = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._currentOrgUnitConfiguration = undefined;
                        this._isEditOrgUnitTemplateModalOpen = false;
                        return [4 /*yield*/, this._getConfigurations()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this._handleEditOrgUnitTemplateSaveClick = function (config) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!config) return [3 /*break*/, 3];
                        return [4 /*yield*/, Api.secured.updateOrgUnitConfiguration(config)];
                    case 1:
                        _b.sent();
                        if (!(((_a = this._currentOrgUnitConfiguration) === null || _a === void 0 ? void 0 : _a.key) === ConfigurationKey.AutoProjectCreation)) return [3 /*break*/, 3];
                        return [4 /*yield*/, Api.secured.updateOrgUnitConfiguration(this._currentOrgUnitConfiguration)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3: return [4 /*yield*/, this._closeEditOrgUnitTemplateModal()];
                    case 4:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this._handleOrgUnitConfigDelete = function () { return __awaiter(_this, void 0, void 0, function () {
            var orgUnitIds, _i, orgUnitIds_1, orgUnitId;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        orgUnitIds = this._configurations
                            .filter(function (config) { return config.orgUnitDisplayName === _this._currentOrgUnitConfigGroup; })
                            .map(function (config) { return config.orgUnitId; });
                        _i = 0, orgUnitIds_1 = orgUnitIds;
                        _a.label = 1;
                    case 1:
                        if (!(_i < orgUnitIds_1.length)) return [3 /*break*/, 4];
                        orgUnitId = orgUnitIds_1[_i];
                        return [4 /*yield*/, Api.secured.deleteOrgUnitConfiguration({ orgUnitId: orgUnitId })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        this._closeDeleteModal();
                        return [4 /*yield*/, this._getConfigurations()];
                    case 5:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this._groupCellRender = function (event) {
            var value = event.value;
            return (React.createElement("div", { className: styles.groupCell },
                value,
                React.createElement(FaTrash, { className: styles.icon, onClick: function () { return _this._handleDeleteClick(value); } })));
        };
        return _this;
    }
    OrgUnitConfiguration.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._getConfigurations()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    OrgUnitConfiguration.prototype._getConfigurations = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, Api.secured.getOrgUnitConfigurations()];
                    case 1:
                        _a._configurations = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    OrgUnitConfiguration.prototype._toggleAddModal = function () {
        this._isAddModalOpen = !this._isAddModalOpen;
    };
    OrgUnitConfiguration.prototype._closeAddDialog = function () {
        this._isAddModalOpen = false;
    };
    OrgUnitConfiguration.prototype._toggleDeleteModal = function (isCancel) {
        if (isCancel) {
            this._closeDeleteModal();
            return;
        }
        this._openDeleteModal();
    };
    OrgUnitConfiguration.prototype._openDeleteModal = function () {
        this._isDeleteModalOpen = true;
    };
    OrgUnitConfiguration.prototype._closeDeleteModal = function () {
        this._currentOrgUnitConfigGroup = null;
        this._isDeleteModalOpen = false;
    };
    OrgUnitConfiguration.prototype._openEditOrgUnitTemplateModal = function (orgUnitTemplate) {
        if (orgUnitTemplate) {
            this._currentOrgUnitConfiguration = orgUnitTemplate;
            this._isEditOrgUnitTemplateModalOpen = true;
        }
    };
    OrgUnitConfiguration.prototype.render = function () {
        var _this = this;
        return (React.createElement(React.Fragment, null,
            React.createElement(Widget, { title: "OrgUnit Configuration" },
                true && (React.createElement("div", { className: styles.actions },
                    React.createElement(Button, { color: "primary", onClick: this._toggleAddModal }, "Add OrgUnit Config"))),
                React.createElement(Grid, { dataSource: this._configurations },
                    React.createElement(Column, { dataField: "orgUnitDisplayName", groupIndex: 0, caption: "OrgUnit", autoExpandGroup: false, groupCellRender: this._groupCellRender }),
                    React.createElement(Column, { dataField: "group", groupIndex: 1, caption: "Group" }),
                    React.createElement(Column, { dataField: "key", caption: "Key", width: 300, cellRender: function (_a) {
                            var data = _a.data;
                            if (data.key === ConfigurationKey.AutoProjectCreationTemplate) {
                                return (React.createElement("a", { href: "#", target: "_self" },
                                    React.createElement("span", { onClick: function () { return _this._openEditOrgUnitTemplateModal(data); } }, data.key)));
                            }
                            return data.key;
                        } }),
                    React.createElement(Column, { dataField: "value", caption: "Enabled", width: 85, alignment: "center", cellRender: function (_a) {
                            var data = _a.data;
                            if (data.value == 'true' || data.value == 'false') {
                                return (React.createElement("div", { className: styles.checkbox },
                                    React.createElement(Input, { type: "checkbox", checked: data.value == 'true', onChange: function () { return __awaiter(_this, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0: return [4 /*yield*/, this._handleConfigValueChange(data)];
                                                    case 1:
                                                        _a.sent();
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); } })));
                            }
                            if (data.key === ConfigurationKey.AutoProjectCreationTemplate) {
                                return React.createElement(IoIosMore, { onClick: function () { return _this._openEditOrgUnitTemplateModal(data); } });
                            }
                        } }),
                    React.createElement(Column, { dataField: "description", caption: "Description", minWidth: 500 })),
                this._isAddModalOpen && (React.createElement(AddOrgUnitConfigDialog, { isOpen: this._isAddModalOpen, onClose: this._closeAddDialog, onSave: this._handleSaveClick })),
                this._isEditOrgUnitTemplateModalOpen && this._currentOrgUnitConfiguration && (React.createElement(EditOrgUnitTemplateDialog, { configurations: this._configurations, isOpen: this._isEditOrgUnitTemplateModalOpen, onClose: this._closeEditOrgUnitTemplateModal, onSave: this._handleEditOrgUnitTemplateSaveClick, orgUnitConfigurationModel: this._currentOrgUnitConfiguration })),
                React.createElement(ConfirmationDialog, { isOpen: this._isDeleteModalOpen, onToggle: this._toggleDeleteModal, headerTitle: "Remove OrgUnit Configuration", onSave: this._handleOrgUnitConfigDelete, body: "Are you sure you want to delete the OrgUnit Configuration? The action will have impact on all the running Projects configured for the related OrgUnits." }))));
    };
    __decorate([
        observable
    ], OrgUnitConfiguration.prototype, "_configurations", void 0);
    __decorate([
        observable
    ], OrgUnitConfiguration.prototype, "_isAddModalOpen", void 0);
    __decorate([
        observable
    ], OrgUnitConfiguration.prototype, "_isEditOrgUnitTemplateModalOpen", void 0);
    __decorate([
        observable
    ], OrgUnitConfiguration.prototype, "_isDeleteModalOpen", void 0);
    __decorate([
        observable
    ], OrgUnitConfiguration.prototype, "_currentOrgUnitConfiguration", void 0);
    __decorate([
        observable
    ], OrgUnitConfiguration.prototype, "_currentOrgUnitConfigGroup", void 0);
    __decorate([
        action.bound
    ], OrgUnitConfiguration.prototype, "_getConfigurations", null);
    __decorate([
        action.bound
    ], OrgUnitConfiguration.prototype, "_toggleAddModal", null);
    __decorate([
        action.bound
    ], OrgUnitConfiguration.prototype, "_closeAddDialog", null);
    __decorate([
        action.bound
    ], OrgUnitConfiguration.prototype, "_toggleDeleteModal", null);
    __decorate([
        action.bound
    ], OrgUnitConfiguration.prototype, "_openDeleteModal", null);
    __decorate([
        action.bound
    ], OrgUnitConfiguration.prototype, "_closeDeleteModal", null);
    __decorate([
        action.bound
    ], OrgUnitConfiguration.prototype, "_handleSaveClick", void 0);
    __decorate([
        action.bound
    ], OrgUnitConfiguration.prototype, "_handleDeleteClick", void 0);
    __decorate([
        action.bound
    ], OrgUnitConfiguration.prototype, "_handleConfigValueChange", void 0);
    __decorate([
        action.bound
    ], OrgUnitConfiguration.prototype, "_openEditOrgUnitTemplateModal", null);
    __decorate([
        action.bound
    ], OrgUnitConfiguration.prototype, "_closeEditOrgUnitTemplateModal", void 0);
    __decorate([
        action.bound
    ], OrgUnitConfiguration.prototype, "_handleEditOrgUnitTemplateSaveClick", void 0);
    __decorate([
        action.bound
    ], OrgUnitConfiguration.prototype, "_handleOrgUnitConfigDelete", void 0);
    OrgUnitConfiguration = __decorate([
        observer
    ], OrgUnitConfiguration);
    return OrgUnitConfiguration;
}(React.PureComponent));
