var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { MdCloudUpload } from 'react-icons/md';
import styles from './DragWrapper.module.scss';
var DragWrapper = /** @class */ (function (_super) {
    __extends(DragWrapper, _super);
    function DragWrapper() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._dragging = false;
        _this._dragCounter = 0;
        return _this;
    }
    DragWrapper.prototype._setDragging = function (dragging) {
        this._dragging = dragging;
    };
    DragWrapper.prototype._dragEnterHandler = function (e) {
        e.preventDefault();
        e.stopPropagation();
        this._dragCounter++;
        this._setDragging(true);
    };
    DragWrapper.prototype._dragLeaveHandler = function (e) {
        e.preventDefault();
        e.stopPropagation();
        this._dragCounter--;
        if (this._dragCounter > 0)
            return;
        this._setDragging(false);
    };
    DragWrapper.prototype._dragOverHandler = function (e) {
        e.preventDefault();
        e.stopPropagation();
    };
    DragWrapper.prototype._dropHandler = function (e) {
        e.preventDefault();
        e.stopPropagation();
        this._setDragging(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.props.dropHandler(e.dataTransfer.files);
            e.dataTransfer.clearData();
            this._dragCounter = 0;
        }
    };
    DragWrapper.prototype.render = function () {
        var _this = this;
        if (this.props.disabled) {
            return this.props.children;
        }
        return (React.createElement("div", { className: styles.dragWrapper, onDragEnter: function (e) { return _this._dragEnterHandler(e); }, onDragLeave: function (e) { return _this._dragLeaveHandler(e); }, onDragOver: function (e) { return _this._dragOverHandler(e); }, onDrop: function (e) { return _this._dropHandler(e); } },
            this._dragging && (React.createElement("div", { className: styles.dragContent },
                !this.props.disableIcon && React.createElement(MdCloudUpload, { className: styles.dragIcon, size: "25px" }),
                React.createElement("div", { className: styles.dragText }, "Upload Files"))),
            this.props.children));
    };
    __decorate([
        observable
    ], DragWrapper.prototype, "_dragging", void 0);
    __decorate([
        action.bound
    ], DragWrapper.prototype, "_setDragging", null);
    DragWrapper = __decorate([
        observer
    ], DragWrapper);
    return DragWrapper;
}(React.PureComponent));
export default DragWrapper;
