var AuthStore = /** @class */ (function () {
    function AuthStore() {
        var _this = this;
        this.logout = function () {
            _this._clearUserData();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            var appWindow = window;
            appWindow.adal && appWindow.adal.redirectToLogout();
        };
        this.login = function () {
            _this._clearUserData();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            var appWindow = window;
            appWindow.adal && appWindow.adal.redirectToLogin();
        };
    }
    AuthStore.prototype._clearUserData = function () {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentToken');
        localStorage.removeItem('impersonatedUser');
    };
    Object.defineProperty(AuthStore.prototype, "currentToken", {
        get: function () {
            return (this._getValue('currentToken') || '').replace(/"/gi, '');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AuthStore.prototype, "currentUser", {
        get: function () {
            return this._getValue('currentUser');
        },
        enumerable: false,
        configurable: true
    });
    AuthStore.prototype._getValue = function (key) {
        var value = localStorage.getItem(key);
        return value ? JSON.parse(value) : null;
    };
    return AuthStore;
}());
export { AuthStore };
export var authStore = new AuthStore();
