var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { EditFunctionDialog } from './EditFunctionDialog';
import { ApprovalTypeEnum, SeparatedStrings } from '@app/AppConstants';
export var FunctionCell = /** @class */ (function (_super) {
    __extends(FunctionCell, _super);
    function FunctionCell() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isEditMode = false;
        _this._selectedFunction = null;
        return _this;
    }
    FunctionCell_1 = FunctionCell;
    FunctionCell.prototype._handleEditClick = function () {
        this._isEditMode = true;
    };
    FunctionCell.prototype._handleCancelClick = function () {
        this._selectedFunction = null;
        this._isEditMode = false;
    };
    FunctionCell.prototype._handleFunctionSelect = function (functionRange) {
        var func = {
            id: functionRange.functionId,
            name: functionRange.functionCode,
            code: functionRange.functionCode
        };
        this._selectedFunction = func;
    };
    FunctionCell.renderApprovalType = function (approvalType) {
        switch (approvalType) {
            case ApprovalTypeEnum.ProvideDeliverableTask:
                return SeparatedStrings.ApprovalTypeProvideDeliverableTask;
            case ApprovalTypeEnum.DraftDeliverableTask:
                return SeparatedStrings.ApprovalTypeDraftDeliverableTask;
            case ApprovalTypeEnum.ApprovalDeliverableTask:
                return SeparatedStrings.ApprovalTypeApprovalDeliverableTask;
            case ApprovalTypeEnum.MoveDeliverableToInApprovalTask:
                return SeparatedStrings.ApprovalTypeMoveDeliverableToInApprovalTask;
            case ApprovalTypeEnum.MoveFunctionChecklistToInApprovalTask:
                return SeparatedStrings.ApprovalTypeMoveFunctionChecklistToInApprovalTask;
            case ApprovalTypeEnum.ApprovalFunctionChecklistTask:
                return SeparatedStrings.ApprovalTypeApprovalFunctionChecklistTask;
            default:
                return '';
        }
    };
    FunctionCell.prototype.getWeightOfType = function (approvalType) {
        var weight = 0;
        switch (approvalType) {
            case ApprovalTypeEnum.ProvideDeliverableTask:
                weight = 3;
                break;
            case ApprovalTypeEnum.DraftDeliverableTask:
                weight = 2;
                break;
            case ApprovalTypeEnum.ApprovalDeliverableTask:
                weight = 0;
                break;
            case ApprovalTypeEnum.MoveDeliverableToInApprovalTask:
                weight = 1;
                break;
            case ApprovalTypeEnum.MoveFunctionChecklistToInApprovalTask:
                weight = 1;
                break;
            case ApprovalTypeEnum.ApprovalFunctionChecklistTask:
                weight = 0;
                break;
        }
        var result = [approvalType, weight];
        return result;
    };
    FunctionCell.prototype.getColor = function (ranges) {
        var _this = this;
        if (ranges.length === 0) {
            return '';
        }
        var types = ranges.map(function (r) { return _this.getWeightOfType(r.approvalType); });
        var mostValuableType = types[0];
        types.forEach(function (i) {
            if (i[1] > mostValuableType[1]) {
                mostValuableType = i;
            }
        });
        switch (mostValuableType[0]) {
            case ApprovalTypeEnum.ProvideDeliverableTask:
                return '#a9d08e';
            case ApprovalTypeEnum.DraftDeliverableTask:
                return '#c6e0b4';
            case ApprovalTypeEnum.ApprovalDeliverableTask:
            case ApprovalTypeEnum.ApprovalFunctionChecklistTask:
                return '#ffffff';
            case ApprovalTypeEnum.MoveDeliverableToInApprovalTask:
            case ApprovalTypeEnum.MoveFunctionChecklistToInApprovalTask:
                return '#e2efda';
        }
    };
    FunctionCell.prototype.render = function () {
        var _a;
        var _this = this;
        var onSave = (_a = this.props, _a.onSave), cellInfo = _a.cellInfo, create = _a.create, update = _a.update, dlt = _a.delete;
        var week = cellInfo.week;
        var ranges = cellInfo.templateFunctionRange.filter(function (x) { return week >= x.weekFrom && week <= x.weekTo; });
        return (React.createElement("div", { onDoubleClick: this._handleEditClick, style: { display: 'list-item' } },
            React.createElement("div", { style: {
                    backgroundColor: this.getColor(ranges),
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0
                } }),
            React.createElement("div", { style: { position: 'relative' } },
                ranges.map(function (x) { return (React.createElement("div", { key: x.id, onClick: function () { return _this._handleFunctionSelect(x); }, title: FunctionCell_1.renderApprovalType(x.approvalType) }, x.functionCode)); }),
                this._isEditMode && (React.createElement(EditFunctionDialog, { cellInfo: cellInfo, onSave: onSave, ranges: ranges, onCancel: this._handleCancelClick, selectedFunction: this._selectedFunction, create: create, update: update, delete: dlt })))));
    };
    var FunctionCell_1;
    __decorate([
        observable
    ], FunctionCell.prototype, "_isEditMode", void 0);
    __decorate([
        observable
    ], FunctionCell.prototype, "_selectedFunction", void 0);
    __decorate([
        action.bound
    ], FunctionCell.prototype, "_handleEditClick", null);
    __decorate([
        action.bound
    ], FunctionCell.prototype, "_handleCancelClick", null);
    __decorate([
        action.bound
    ], FunctionCell.prototype, "_handleFunctionSelect", null);
    FunctionCell = FunctionCell_1 = __decorate([
        observer
    ], FunctionCell);
    return FunctionCell;
}(React.PureComponent));
