var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { action, observable, computed } from 'mobx';
import { SignalREvents } from '@app/AppConstants';
import DateTimeService from '@app/Services/DateTimeService';
import { signalRService } from '@app/Services/SignalRService';
import { Api } from '@app/Api';
var JobListStore = /** @class */ (function () {
    function JobListStore() {
        var _this = this;
        this.dataLoaded = false;
        this.jobs = [];
        this.exceptionMessage = '';
        this.jobsFilter = '';
        this.isModalOpen = false;
        this.curentJobTriggerInModal = null;
        this.curentJobName = '';
        this.handleSaveClick = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._initJobs()];
                    case 1:
                        _a.sent();
                        this.closeDialog();
                        return [2 /*return*/];
                }
            });
        }); };
        signalRService.subscribe(SignalREvents.jobStatusChanged, this._onJobStatusChangedHandler);
        signalRService.subscribe(SignalREvents.jobProgressMaxValueChanged, this._onJobProgressMaxValueChangedHandler);
        signalRService.subscribe(SignalREvents.jobProgressValueChanged, this._onJobProgressValueChangedHandler);
        signalRService.subscribe(SignalREvents.jobFinished, this._onJobFinishedHandler);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this._initJobs();
        this.jobsFilter = localStorage.getItem('JobsListFilter') || '';
    }
    JobListStore.prototype.openDialog = function (jobTrigger, jobName) {
        this.curentJobTriggerInModal = jobTrigger;
        this.curentJobName = jobName;
        this.isModalOpen = true;
    };
    JobListStore.prototype.closeDialog = function () {
        this.curentJobTriggerInModal = null;
        this.curentJobName = '';
        this.isModalOpen = false;
    };
    Object.defineProperty(JobListStore.prototype, "jobItems", {
        get: function () {
            var _this = this;
            return this.jobs.filter(function (jobItem) { return _this.filterJob(jobItem); });
        },
        enumerable: false,
        configurable: true
    });
    JobListStore.prototype.unload = function () {
        signalRService.unsubscribe(SignalREvents.jobStatusChanged, this._onJobStatusChangedHandler);
        signalRService.unsubscribe(SignalREvents.jobProgressMaxValueChanged, this._onJobProgressMaxValueChangedHandler);
        signalRService.unsubscribe(SignalREvents.jobProgressValueChanged, this._onJobProgressValueChangedHandler);
        signalRService.unsubscribe(SignalREvents.jobFinished, this._onJobFinishedHandler);
    };
    JobListStore.prototype.runTriggerInternal = function (job) {
        return __awaiter(this, void 0, void 0, function () {
            var name, parameters, params;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        name = job.name, parameters = job.parameters;
                        params = [];
                        parameters.forEach(function (el) {
                            var paramValue = el.value && JSON.parse(el.value);
                            if (paramValue) {
                                var value = paramValue[el.name];
                                if (value) {
                                    var param = { name: el.name, value: value };
                                    params.push(param);
                                }
                            }
                        });
                        return [4 /*yield*/, Api.secured.jobStart(name, { body: { parameters: params.length > 0 ? params : parameters } })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    JobListStore.prototype.setFilter = function (value) {
        this.jobsFilter = value;
        localStorage.setItem('JobsListFilter', value);
    };
    JobListStore.prototype.filterJob = function (job) {
        var result = true;
        if (this.jobsFilter) {
            var filters = this.jobsFilter.toLowerCase().split(';');
            result = false;
            for (var i = 0; i < filters.length; i++) {
                var filter = filters[i];
                var matchByFilters = true;
                var filterParts = filter.split(' ');
                for (var j = 0; j < filterParts.length; j++) {
                    var filterPart = filterParts[j];
                    var isNegative = filterPart.startsWith('-');
                    if (isNegative) {
                        filterPart = filterPart.substring(1);
                        if (job.name.toLowerCase().indexOf(filterPart) !== -1 ||
                            (job.description && job.description.toLowerCase().indexOf(filterPart) !== -1)) {
                            matchByFilters = false;
                        }
                    }
                    else {
                        if (job.name.toLowerCase().indexOf(filterPart) === -1 &&
                            (!job.description ||
                                (job.description && job.description.toLowerCase().indexOf(filterPart) === -1))) {
                            matchByFilters = false;
                        }
                    }
                }
                if (matchByFilters) {
                    result = true;
                }
            }
        }
        return result;
    };
    JobListStore.prototype.updateJob = function (job) {
        var index = this.jobs.findIndex(function (x) { return x.id === job.id; });
        if (index !== -1 &&
            DateTimeService.compare(this.jobs[index].timestampDateTimeUtc, job.timestampDateTimeUtc) < 0) {
            this.jobs[index] = job;
        }
        else if (index !== -1) {
            this.jobs[index].triggers = job.triggers;
        }
    };
    JobListStore.prototype._initJobs = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.dataLoaded = false;
                        this.exceptionMessage = '';
                        return [4 /*yield*/, Api.secured.jobGetList()];
                    case 1:
                        response = _a.sent();
                        if (response.status === 'Started') {
                            this.jobs = response.jobs || [];
                            this.dataLoaded = true;
                        }
                        else if (response.status !== 'FailedToStart') {
                            window.setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this._initJobs()];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); }, 5000);
                        }
                        else if (response.status === 'FailedToStart') {
                            this.exceptionMessage = response.startupException;
                            this.dataLoaded = true;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    JobListStore.prototype._findTrigger = function (triggerId) {
        for (var i = 0; i < this.jobs.length; i++) {
            var job = this.jobs[i];
            for (var j = 0; j < job.triggers.length; j++) {
                var trigger = job.triggers[j];
                if (trigger.id === triggerId)
                    return trigger;
            }
        }
        return null;
    };
    JobListStore.prototype._onJobStatusChangedHandler = function (eventName, param) {
        var jobStatus = DateTimeService.typeCheck(param);
        var trigger = this._findTrigger(jobStatus.triggerId);
        if (trigger) {
            trigger.lastErrorMessage = jobStatus.lastErrorMessage;
            trigger.status = jobStatus.status;
            trigger.progressMessage = jobStatus.progressMessage;
        }
        var job = this.jobs.find(function (j) { return j.name === jobStatus.name; });
        if (job && DateTimeService.compare(job.timestampDateTimeUtc, jobStatus.timestampDateTimeUtc) < 0) {
            job.lastErrorMessage = jobStatus.lastErrorMessage;
            job.lastStatus = jobStatus.status;
            if (jobStatus.jobExecutionInfo) {
                var jobExecutionInfo = jobStatus.jobExecutionInfo;
                job.lastRunDateTimeUtc = jobExecutionInfo.lastRunDateTimeUtc;
                job.nextRunDateTimeUtc = jobExecutionInfo.nextRunDateTimeUtc;
                job.lastRunTriggerName = jobExecutionInfo.lastRunTriggerName || '';
                job.nextRunTriggerName = jobExecutionInfo.nextRunTriggerName || '';
            }
            job.timestampDateTimeUtc = jobStatus.timestampDateTimeUtc;
            this.jobs = this.jobs.map(function (x) { return x; });
            if (this.onJobStatusChangedHandler)
                this.onJobStatusChangedHandler(job);
        }
    };
    JobListStore.prototype._onJobProgressMaxValueChangedHandler = function (eventName, data) {
        var trigger = this._findTrigger(data.triggerId);
        if (trigger) {
            trigger.progressMaxValue = data.maxValue;
        }
    };
    JobListStore.prototype._onJobProgressValueChangedHandler = function (eventName, data) {
        var trigger = this._findTrigger(data.triggerId);
        if (trigger) {
            trigger.progressMaxValue = data.maxValue;
            trigger.progressCurrentValue = data.value;
        }
    };
    JobListStore.prototype._onJobFinishedHandler = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._initJobs()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        observable
    ], JobListStore.prototype, "dataLoaded", void 0);
    __decorate([
        observable
    ], JobListStore.prototype, "jobs", void 0);
    __decorate([
        observable
    ], JobListStore.prototype, "exceptionMessage", void 0);
    __decorate([
        observable
    ], JobListStore.prototype, "jobsFilter", void 0);
    __decorate([
        observable
    ], JobListStore.prototype, "isModalOpen", void 0);
    __decorate([
        observable
    ], JobListStore.prototype, "curentJobTriggerInModal", void 0);
    __decorate([
        observable
    ], JobListStore.prototype, "curentJobName", void 0);
    __decorate([
        action.bound
    ], JobListStore.prototype, "openDialog", null);
    __decorate([
        action.bound
    ], JobListStore.prototype, "closeDialog", null);
    __decorate([
        computed
    ], JobListStore.prototype, "jobItems", null);
    __decorate([
        action.bound
    ], JobListStore.prototype, "unload", null);
    __decorate([
        action.bound
    ], JobListStore.prototype, "runTriggerInternal", null);
    __decorate([
        action
    ], JobListStore.prototype, "setFilter", null);
    __decorate([
        action
    ], JobListStore.prototype, "updateJob", null);
    __decorate([
        action
    ], JobListStore.prototype, "_initJobs", null);
    __decorate([
        action
    ], JobListStore.prototype, "_findTrigger", null);
    __decorate([
        action.bound
    ], JobListStore.prototype, "_onJobStatusChangedHandler", null);
    __decorate([
        action.bound
    ], JobListStore.prototype, "_onJobProgressMaxValueChangedHandler", null);
    __decorate([
        action.bound
    ], JobListStore.prototype, "_onJobProgressValueChangedHandler", null);
    __decorate([
        action.bound
    ], JobListStore.prototype, "_onJobFinishedHandler", null);
    return JobListStore;
}());
export default JobListStore;
