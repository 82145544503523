var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import styles from './Loader.module.scss';
import loadingGif from 'assets/images/loading.gif';
var Loader = /** @class */ (function (_super) {
    __extends(Loader, _super);
    function Loader() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Loader.prototype.render = function () {
        var _a = this.props, style = _a.style, loading = _a.loading, className = _a.className;
        var visible = loading !== null && loading !== void 0 ? loading : true;
        var classNames = styles.wrapper + ' ' + className;
        return (React.createElement("div", { hidden: !visible, className: classNames },
            React.createElement("div", { style: style },
                React.createElement("img", { className: styles.image, src: loadingGif, alt: "" }))));
    };
    return Loader;
}(React.PureComponent));
export { Loader };
