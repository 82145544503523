var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { observer } from 'mobx-react';
import styles from './ExternalDeliverableInfo.module.scss';
import { ApprovalApplicationEnum, StatusEnum } from '@app/AppConstants';
import { colorThemesStore } from '@app/Stores/ColorThemesStore';
export var ExternalDeliverableInfo = /** @class */ (function (_super) {
    __extends(ExternalDeliverableInfo, _super);
    function ExternalDeliverableInfo() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getBGColor = function () {
            var _a;
            var currentStatus = (_a = _this.props.deliverable, _a.currentStatus), isExternallyConfigured = _a.isExternallyConfigured, isExternalPrepared = _a.isExternalPrepared, stateAtGateAssessment = _a.stateAtGateAssessment, hasComment = _a.hasComment, hasRating = _a.hasRating;
            var colors = colorThemesStore.appColors;
            if (currentStatus === StatusEnum.Draft) {
                if (stateAtGateAssessment === StatusEnum.Draft && hasComment && hasRating) {
                    return colors.green;
                }
                if (isExternalPrepared) {
                    return colors.orange;
                }
                if (isExternallyConfigured) {
                    return colors.yellow;
                }
                else {
                    return colors.gray;
                }
            }
            else if (currentStatus === StatusEnum.InApproval || currentStatus === StatusEnum.Approved) {
                return colors.green;
            }
        };
        _this.getTitle = function () {
            var _a;
            var currentStatus = (_a = _this.props.deliverable, _a.currentStatus), isExternallyConfigured = _a.isExternallyConfigured, isExternalPrepared = _a.isExternalPrepared, approvalApplication = _a.approvalApplication;
            if (approvalApplication !== ApprovalApplicationEnum.TMS) {
                return 'Delegation is disabled due to External management of the Deliverable';
            }
            if (currentStatus === StatusEnum.Draft) {
                if (isExternalPrepared) {
                    return 'TTP prepared in TMS by LTE';
                }
                if (isExternallyConfigured) {
                    return 'TTP to be prepared in TMS by LTE';
                }
                else {
                    return 'External Delegation';
                }
            }
            else if (currentStatus === StatusEnum.InApproval) {
                return 'TTP prepared in TMS by LTE and approved by DP';
            }
        };
        return _this;
    }
    ExternalDeliverableInfo.prototype.render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.button, style: { backgroundColor: this.getBGColor() }, title: this.getTitle() }, "E")));
    };
    ExternalDeliverableInfo = __decorate([
        observer
    ], ExternalDeliverableInfo);
    return ExternalDeliverableInfo;
}(React.Component));
