export var setDefaultFilterProp = function (filters, defaultFiltersProp) {
    if (defaultFiltersProp === void 0) { defaultFiltersProp = []; }
    defaultFiltersProp === null || defaultFiltersProp === void 0 ? void 0 : defaultFiltersProp.forEach(function (option) {
        var _a;
        (_a = filters.options) === null || _a === void 0 ? void 0 : _a.forEach(function (item) {
            if (option.value === item.value) {
                filters.value.push(item);
            }
        });
    });
};
