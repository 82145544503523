var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Grid } from '@app/Components/Grid/Grid';
import { Column } from 'devextreme-react/data-grid';
import columnSharedStyles from '@app/Components/Grid/SharedStyles/column-shared-styles.module.scss';
import styles from './ActionItemsTable.module.scss';
import { Api } from '@app/Api';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { filterActionItemsTableRowsByFunction } from '@app/Components/FunctonFilter/FunctionFilter';
import { FunctionFilterContext } from '@app/Components/FunctonFilter/FunctionFilterContext';
import { AddActionItemModal } from '@app/Widgets/AddActionItemModal/AddActionItemModal';
import { GateProcessTypeEnum, ObjectType, CheckListItemRatingEnum, ActionItemDeadLineEnum, SignalREvents, ComponentUrls } from '@app/AppConstants';
import { Button } from '@app/Components/Button/Button';
import { ActionItemAddButton } from '../ActionItemAddButton/ActionItemAddButton';
import { RelatedIdCell } from './RelatedIdCell.tsx/RelatedIdCell';
import { createUniqueId } from '@app/Helpers/UidHelper/UidHelper';
import { EditCell } from '@app/Components/Grid/EditCell/EditCell';
import { ResponsibleCell } from './ResponsibleCell/ResponsibleCell';
import { RatingCell } from '@app/Components/Grid/RatingCell/RatingCell';
import { CommentModal } from '@app/Widgets/CommentModal/CommentModal';
import { DeadlineEditCell } from './DeadlineCell/DeadlineEditCell';
import { DenyCommentsList } from '@app/Components/Grid/DenyCommentsList/DenyCommentsList';
import { signalRService } from '@app/Services/SignalRService';
import { ChangeLogDialog } from '@app/Components/Dialogs/ChangeLogDialog';
import { CodeCell } from '@app/Components/Grid/CodeCell/CodeCell';
import { FaTrash } from 'react-icons/fa';
import { ConfirmationDialog } from '@app/Widgets/ConfirmationDialog/ConfirmationDialog';
import { DocumentButton } from '@app/Components/DocumentButton/DocumentButton';
import { DownloadButton } from '@app/Components/DownloadButton/DownloadButton';
import { CopyToClipBoardButton } from '@app/Components/CopyToClipboardButton/CopyToClipboardButton';
import { Route, Switch, withRouter } from 'react-router-dom';
import { ActionItemDocumentView } from '@app/Widgets/ActionItemDocumentView/ActionItemDocumentView';
var ActionItemsTable = /** @class */ (function (_super) {
    __extends(ActionItemsTable, _super);
    function ActionItemsTable() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._items = [];
        _this._openAddActionItemModal = false;
        _this._isCommentModalOpen = false;
        _this._isActionItemDeleteModalOpen = false;
        _this._denyItem = null;
        _this._currentActionItem = null;
        _this._currentActionItemId = null;
        _this._currentActionItemCanPrincipalManageDocument = null;
        _this._loading = false;
        _this._gridRef = React.createRef();
        _this.refreshActionItems = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._load()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    ActionItemsTable.prototype.componentDidUpdate = function (prevProps) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.props.gateId !== prevProps.gateId)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._load()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ActionItemsTable.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        signalRService.subscribe(SignalREvents.recommendationChanged, this._loadActionItems);
                        signalRService.subscribe(SignalREvents.decisionChanged, this._loadActionItems);
                        document.addEventListener('refreshActionItems', this.refreshActionItems);
                        return [4 /*yield*/, this._load()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ActionItemsTable.prototype.componentWillUnmount = function () {
        signalRService.unsubscribe(SignalREvents.recommendationChanged, this._loadActionItems);
        signalRService.unsubscribe(SignalREvents.decisionChanged, this._loadActionItems);
        document.removeEventListener('refreshActionItems', this.refreshActionItems);
    };
    ActionItemsTable.prototype._load = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, functions, checklists;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            Api.secured.getGateFunctions({ gateId: this.props.gateId, isEnabledOnly: true }),
                            Api.secured.getChecklistsLookupInfo({
                                projectId: this.props.projectId,
                                gateId: this.props.gateId
                            }),
                            this._loadActionItems()
                        ])];
                    case 1:
                        _a = _b.sent(), functions = _a[0], checklists = _a[1];
                        this._functions = functions
                            .sort(function (a, b) { return (a.name < b.name ? -1 : 1); })
                            .map(function (f) { return ({ value: '' + f.id, label: f.name }); });
                        this._checklists = checklists.map(function (cl) { return ({ value: '' + cl.id, label: '' + cl.codeName }); });
                        return [2 /*return*/];
                }
            });
        });
    };
    ActionItemsTable.prototype._toggleLogModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._currentActionItem = null;
                return [2 /*return*/];
            });
        });
    };
    ActionItemsTable.prototype._loadActionItems = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, , 2, 3]);
                        this._loading = true;
                        _a = this;
                        return [4 /*yield*/, Api.secured.getActionItems({
                                gateId: this.props.gateId,
                                gateProcessType: this.props.gateProcessType
                            })];
                    case 1:
                        _a._items = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this._loading = false;
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ActionItemsTable.prototype._saveActionItem = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 4]);
                        return [4 /*yield*/, Api.secured.updateActionItemMainPart({ body: rowData })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        error_1 = _a.sent();
                        console.error(error_1);
                        return [4 /*yield*/, this.refreshActionItems()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ActionItemsTable.prototype._saveActionItemWorkerPart = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            var error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 4]);
                        return [4 /*yield*/, Api.secured.updateActionItemWorkerPart({ body: rowData })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        error_2 = _a.sent();
                        console.error(error_2);
                        return [4 /*yield*/, this.refreshActionItems()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ActionItemsTable.prototype._toggleCommentModalOpen = function () {
        this._isCommentModalOpen = !this._isCommentModalOpen;
        if (!this._isCommentModalOpen)
            this._denyItem = null;
    };
    ActionItemsTable.prototype._toggleSaveActionItemModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadActionItems()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ActionItemsTable.prototype._toggleAddActionItemModal = function () {
        this._openAddActionItemModal = !this._openAddActionItemModal;
    };
    ActionItemsTable.prototype._toggleActionItemDeleteModalOpen = function () {
        this._isActionItemDeleteModalOpen = !this._isActionItemDeleteModalOpen;
    };
    ActionItemsTable.prototype._handleActionItemDeleteClick = function (actionItemId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._currentActionItemId = actionItemId;
                this._toggleActionItemDeleteModalOpen();
                return [2 /*return*/];
            });
        });
    };
    ActionItemsTable.prototype._handleDeleteActionItem = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._currentActionItemId)
                            return [2 /*return*/];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        this._loading = true;
                        return [4 /*yield*/, Api.secured.deleteActionItem({ actionItemId: this._currentActionItemId })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this._loading = false;
                        return [7 /*endfinally*/];
                    case 4:
                        this._currentActionItemId = null;
                        this._toggleActionItemDeleteModalOpen();
                        return [4 /*yield*/, this._loadActionItems()];
                    case 5: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ActionItemsTable.prototype._handleActionItemPrepare = function (actionItemId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.prepareActionItem({ actionItemId: actionItemId })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadActionItems()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ActionItemsTable.prototype._handleActionItemDeny = function (comment) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._denyItem) return [3 /*break*/, 2];
                        return [4 /*yield*/, Api.secured.denyActionItem({ actionItemId: this._denyItem.id, comment: comment })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this._denyItem = null;
                        return [4 /*yield*/, this._loadActionItems()];
                    case 3: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ActionItemsTable.prototype._handleActionItemApprove = function (actionItemId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.approveActionItem({ actionItemId: actionItemId })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadActionItems()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ActionItemsTable.prototype._handleDocumentClick = function (model) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._currentActionItemCanPrincipalManageDocument = model.canPrincipalManageDocument;
                this.props.history.push("".concat(this.props.match.url, "/action-item-documents-upload/cl-").concat(model.id));
                return [2 /*return*/];
            });
        });
    };
    ActionItemsTable.prototype.renderInfoButtonsColumn = function (data) {
        var _this = this;
        return (React.createElement("div", { className: styles.actions },
            React.createElement("div", { className: styles.action },
                React.createElement(DocumentButton, { hasDocument: data.hasFile, onDocumentIconClick: function () { return _this._handleDocumentClick(data); } })),
            data.currentFileInformationId ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.action },
                    React.createElement(DownloadButton, { fileId: data.currentFileInformationId })),
                React.createElement("div", { className: styles.action },
                    React.createElement(CopyToClipBoardButton, { text: location.origin + ComponentUrls.DocumentUrl + data.currentFileInformationId, copyText: "Copy file link to clipboard" })))) : null));
    };
    ActionItemsTable.prototype.render = function () {
        var _this = this;
        var _a = this.props, projectId = _a.projectId, gateId = _a.gateId, canAdd = _a.canAdd, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted;
        var functionFilterValue = this.context;
        return (React.createElement(React.Fragment, null,
            canAdd && (React.createElement(ActionItemAddButton, { disabled: isProjectTerminatedOrCompleted, projectId: projectId, onClick: this._toggleAddActionItemModal })),
            React.createElement(Grid, { ref: this._gridRef, dataSource: filterActionItemsTableRowsByFunction(this._items, functionFilterValue), rowsCount: filterActionItemsTableRowsByFunction(this._items, functionFilterValue).length, loading: this._loading, expandableRows: true },
                React.createElement(Column, { caption: "Action ID", dataField: "code", width: 70, allowEditing: false, cssClass: columnSharedStyles.colWithHistory, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(CodeCell, { code: data.code, onHistoryClick: function () { return (_this._currentActionItem = data); } }));
                    } }),
                React.createElement(Column, { allowEditing: false, caption: "Related ID", dataField: "relatedChecklistItems", width: 190, cssClass: styles.relatedIdCell, cellRender: function (_a) {
                        var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                        return (React.createElement(RelatedIdCell, { rowIndex: rowIndex, columnIndex: columnIndex, checklistsOptions: _this._checklists, relatedChecklists: data.relatedChecklists, relatedChecklistItems: data.relatedChecklistItems, onRelatedChecklistsIdsChange: function (value) {
                                data.relatedChecklists = value;
                                if (_this._gridRef.current) {
                                    _this._gridRef.current.repaintRows([rowIndex]);
                                }
                            }, onRelatedChecklistItemsIdsChange: function (value) {
                                data.relatedChecklistItems = value;
                                if (_this._gridRef.current) {
                                    _this._gridRef.current.repaintRows([rowIndex]);
                                }
                            }, onSave: function () { return _this._saveActionItem(data); }, objectUniqueId: createUniqueId(ObjectType.ActionItem, data.id), disabled: !data.canPrincipalUpdateMainPart || isProjectTerminatedOrCompleted }));
                    } }),
                React.createElement(Column, { caption: "Action Text", dataField: "actionText", minWidth: 300, cssClass: columnSharedStyles.commentColumn, cellRender: function (_a) {
                        var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                        return (React.createElement(EditCell, { rowIndex: rowIndex, columnIndex: columnIndex, value: data.actionText, onChange: function (value) {
                                data.actionText = value || '';
                                if (_this._gridRef.current) {
                                    _this._gridRef.current.repaintRows([rowIndex]);
                                }
                            }, onSave: function () { return _this._saveActionItem(data); }, objectUniqueId: createUniqueId(ObjectType.ActionItem, data.id), disabled: !data.canPrincipalUpdateMainPart || isProjectTerminatedOrCompleted }));
                    } }),
                React.createElement(Column, { width: 190, cellRender: function (_a) {
                        var data = _a.data;
                        return _this.renderInfoButtonsColumn(data);
                    } }),
                React.createElement(Column, { caption: "Deadline", dataField: "deadlineDisplayText", width: 200, allowEditing: false, alignment: "center", cssClass: styles.deadlineCell, cellRender: function (_a) {
                        var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                        return (React.createElement(DeadlineEditCell, { rowIndex: rowIndex, columnIndex: columnIndex, onSave: function () { return _this._saveActionItem(data); }, value: data.deadlineType, dateValue: data.deadlineDate, onDeadlineTypeChange: function (value, dateValue) {
                                if (dateValue) {
                                    data.deadlineDate = dateValue;
                                }
                                else if (value !== ActionItemDeadLineEnum.ByDate) {
                                    data.deadlineDate = undefined;
                                }
                                data.deadlineType = value;
                                if (_this._gridRef.current) {
                                    _this._gridRef.current.repaintRows([rowIndex]);
                                }
                            }, objectUniqueId: createUniqueId(ObjectType.ActionItem, data.id), disabled: !data.canPrincipalUpdateMainPart ||
                                _this.props.gateProcessType === GateProcessTypeEnum.GateMeeting ||
                                isProjectTerminatedOrCompleted }));
                    } }),
                React.createElement(Column, { caption: "Responsible", width: 200, cssClass: styles.responibleTextCell, cellRender: function (_a) {
                        var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                        return (React.createElement(ResponsibleCell, { rowIndex: rowIndex, columnIndex: columnIndex, functions: _this._functions, actionItemData: data, projectId: projectId, gateId: gateId, onRepaint: function () {
                                if (_this._gridRef.current) {
                                    _this._gridRef.current.repaintRows([rowIndex]);
                                }
                            }, onSave: function (params) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this._saveActionItem(params)];
                                        case 1:
                                            _a.sent();
                                            return [4 /*yield*/, this._load()];
                                        case 2:
                                            _a.sent(); // We should to receive new generated comment after saving from BE
                                            return [2 /*return*/];
                                    }
                                });
                            }); }, objectUniqueId: createUniqueId(ObjectType.ActionItem, data.id), disabled: !data.canPrincipalUpdateMainPart || isProjectTerminatedOrCompleted }));
                    } }),
                React.createElement(Column, { caption: "Responsible Remark", dataField: "remarks", width: 260, cssClass: styles.remarksCell, cellRender: function (_a) {
                        var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                        return (React.createElement(EditCell, { rowIndex: rowIndex, columnIndex: columnIndex, value: data.remarks, onChange: function (value) {
                                data.remarks = value;
                                if (_this._gridRef.current) {
                                    _this._gridRef.current.repaintRows([rowIndex]);
                                }
                            }, onSave: function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this._saveActionItemWorkerPart(data)];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); }, objectUniqueId: createUniqueId(ObjectType.ActionItem, data.id), disabled: !data.canPrincipalUpdateWorkerPart || isProjectTerminatedOrCompleted }));
                    } }),
                React.createElement(Column, { headerCellRender: function () { return React.createElement("div", { title: "Function Rating" }, "Rating"); }, width: 100, alignment: "center", dataField: "functionRating", allowEditing: false, cssClass: styles.functionRatingCell, cellRender: function (_a) {
                        var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                        return data.functionRating && (React.createElement(RatingCell, { rowIndex: rowIndex, columnIndex: columnIndex, onSave: function () { return _this._saveActionItemWorkerPart(data); }, onChange: function (value) {
                                data.functionRating = value;
                                if (_this._gridRef.current) {
                                    _this._gridRef.current.repaintRows([rowIndex]);
                                }
                            }, rating: data.functionRating, objectUniqueId: createUniqueId(ObjectType.ActionItem, data.id), disabled: !data.canPrincipalUpdateWorkerPart ||
                                _this._loading ||
                                isProjectTerminatedOrCompleted, controlId: "fRating".concat(data.id) }));
                    } }),
                React.createElement(Column, { headerCellRender: function () { return React.createElement("div", { title: "Assessment Rating" }, "Assessment Rating"); }, width: 120, alignment: "center", dataField: "assessorRating", cssClass: styles.assessorRatingCell, allowEditing: false, cellRender: function (_a) {
                        var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                        return data.assessorRating && (React.createElement(RatingCell, { rowIndex: rowIndex, columnIndex: columnIndex, onSave: function () { return _this._saveActionItem(data); }, onChange: function (value) {
                                data.assessorRating = value;
                                if (_this._gridRef.current) {
                                    _this._gridRef.current.repaintRows([rowIndex]);
                                }
                            }, rating: data.assessorRating, objectUniqueId: createUniqueId(ObjectType.ActionItem, data.id), disabled: !data.canPrincipalApprove || _this._loading || isProjectTerminatedOrCompleted, controlId: "aRating".concat(data.id) }));
                    } }),
                React.createElement(Column, { caption: "Comments on Deny", dataField: "comment", minWidth: 250, cellRender: function (_a) {
                        var data = _a.data;
                        return data.denyComments && React.createElement(DenyCommentsList, { comments: data.denyComments });
                    } }),
                React.createElement(Column, { caption: "Status", dataField: "currentStatus", width: 100 }),
                React.createElement(Column, { width: 195, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: styles.actions },
                                data.canPrincipalDelete && (React.createElement(Button, { className: styles.action, color: "danger", size: "xs", disabled: isProjectTerminatedOrCompleted, onClick: function () { return _this._handleActionItemDeleteClick(data.id); } },
                                    React.createElement(FaTrash, null))),
                                data.canPrincipalSetToInApproval && (React.createElement(Button, { className: styles.action, size: "sm", color: "success", disabled: data.functionRating === CheckListItemRatingEnum.Default ||
                                        !data.remarks ||
                                        isProjectTerminatedOrCompleted, onClick: function () { return _this._handleActionItemPrepare(data.id); } }, "Send for Approval")),
                                data.canPrincipalApprove && (React.createElement(React.Fragment, null,
                                    React.createElement(Button, { className: styles.action, size: "sm", disabled: data.assessorRating === CheckListItemRatingEnum.Default ||
                                            isProjectTerminatedOrCompleted, onClick: function () {
                                            _this._toggleCommentModalOpen();
                                            _this._denyItem = data;
                                        } }, "Deny"),
                                    React.createElement(Button, { className: styles.action, size: "sm", color: "success", disabled: data.assessorRating === CheckListItemRatingEnum.Default ||
                                            isProjectTerminatedOrCompleted, onClick: function () { return _this._handleActionItemApprove(data.id); } }, "Approve"))))));
                    } })),
            React.createElement(AddActionItemModal, { projectId: projectId, gateId: gateId, gateProcessType: this.props.gateProcessTypeForCreation, isOpen: this._openAddActionItemModal, onToggle: this._toggleAddActionItemModal, onSave: this._toggleSaveActionItemModal }),
            React.createElement(CommentModal, { isOpen: this._isCommentModalOpen, onToggle: this._toggleCommentModalOpen, onSave: this._handleActionItemDeny }),
            this._currentActionItem && (React.createElement(ChangeLogDialog, { onToggle: this._toggleLogModal, uniqueId: createUniqueId(ObjectType.ActionItem, this._currentActionItem.id) })),
            React.createElement(ConfirmationDialog, { isOpen: this._isActionItemDeleteModalOpen, onToggle: this._toggleActionItemDeleteModalOpen, headerTitle: "Remove Action Item", onSave: this._handleDeleteActionItem, body: "Are you sure you want to delete the Action Item? The action item can not be restored after deletion." }),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "".concat(this.props.match.url, "/action-item-documents-upload/cl-:id") }, this._currentActionItemCanPrincipalManageDocument !== null && (React.createElement(ActionItemDocumentView, { backUrl: this.props.match.url, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted, canPrincipalManageDocument: this._currentActionItemCanPrincipalManageDocument, onClose: this.refreshActionItems }))))));
    };
    ActionItemsTable.contextType = FunctionFilterContext;
    __decorate([
        observable
    ], ActionItemsTable.prototype, "_functions", void 0);
    __decorate([
        observable
    ], ActionItemsTable.prototype, "_checklists", void 0);
    __decorate([
        observable
    ], ActionItemsTable.prototype, "_items", void 0);
    __decorate([
        observable
    ], ActionItemsTable.prototype, "_openAddActionItemModal", void 0);
    __decorate([
        observable
    ], ActionItemsTable.prototype, "_isCommentModalOpen", void 0);
    __decorate([
        observable
    ], ActionItemsTable.prototype, "_isActionItemDeleteModalOpen", void 0);
    __decorate([
        observable
    ], ActionItemsTable.prototype, "_currentActionItem", void 0);
    __decorate([
        observable
    ], ActionItemsTable.prototype, "_currentActionItemId", void 0);
    __decorate([
        observable
    ], ActionItemsTable.prototype, "_currentActionItemCanPrincipalManageDocument", void 0);
    __decorate([
        observable
    ], ActionItemsTable.prototype, "_loading", void 0);
    __decorate([
        observable.ref
    ], ActionItemsTable.prototype, "_gridRef", void 0);
    __decorate([
        action.bound
    ], ActionItemsTable.prototype, "_load", null);
    __decorate([
        action.bound
    ], ActionItemsTable.prototype, "_toggleLogModal", null);
    __decorate([
        action.bound
    ], ActionItemsTable.prototype, "_loadActionItems", null);
    __decorate([
        action.bound
    ], ActionItemsTable.prototype, "_saveActionItem", null);
    __decorate([
        action.bound
    ], ActionItemsTable.prototype, "_saveActionItemWorkerPart", null);
    __decorate([
        action.bound
    ], ActionItemsTable.prototype, "_toggleCommentModalOpen", null);
    __decorate([
        action.bound
    ], ActionItemsTable.prototype, "_toggleSaveActionItemModal", null);
    __decorate([
        action.bound
    ], ActionItemsTable.prototype, "_toggleAddActionItemModal", null);
    __decorate([
        action.bound
    ], ActionItemsTable.prototype, "_toggleActionItemDeleteModalOpen", null);
    __decorate([
        action.bound
    ], ActionItemsTable.prototype, "_handleActionItemDeleteClick", null);
    __decorate([
        action.bound
    ], ActionItemsTable.prototype, "_handleDeleteActionItem", null);
    __decorate([
        action.bound
    ], ActionItemsTable.prototype, "_handleActionItemPrepare", null);
    __decorate([
        action.bound
    ], ActionItemsTable.prototype, "_handleActionItemDeny", null);
    __decorate([
        action.bound
    ], ActionItemsTable.prototype, "_handleActionItemApprove", null);
    __decorate([
        action.bound
    ], ActionItemsTable.prototype, "_handleDocumentClick", null);
    ActionItemsTable = __decorate([
        observer
    ], ActionItemsTable);
    return ActionItemsTable;
}(React.PureComponent));
export default withRouter(ActionItemsTable);
