var AppPrefix = 'APP';
export var createUniqueId = function (objectType, id) {
    return objectType === AppPrefix ? AppPrefix : "".concat(objectType, "-").concat(id);
};
export var objectType = function (objectUniqueId) {
    var p = objectUniqueId.indexOf('-');
    return p > 0 ? objectUniqueId.substring(0, p) : '';
};
export var splitUid = function (objectUniqueId) {
    var objType;
    var id;
    if (objectUniqueId === AppPrefix) {
        objType = AppPrefix;
        id = 0;
    }
    else {
        objType = objectType(objectUniqueId);
        id = parseInt(objectUniqueId.substring(4), 10);
    }
    var result = [objType, id];
    return result;
};
export function getUid(objectHierarchy, objtype) {
    var arr = objectHierarchy.split(';');
    for (var _i = 0, arr_1 = arr; _i < arr_1.length; _i++) {
        var objid = arr_1[_i];
        var ind = objid.search(objtype);
        if (ind !== -1) {
            return parseInt(objid.substring(ind + objtype.length + 1), 10);
        }
    }
}
