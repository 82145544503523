var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import pako from 'pako';
import { Button } from 'reactstrap';
import { SignalREvents } from '@app/AppConstants';
import { signalRService } from '@app/Services/SignalRService';
import { requestTimelineService } from '@app/Services/RequestTimelineService';
import { appStore } from '@app/AppStore';
import requestStyles from './RequestTimeline.module.scss';
import { Grid } from '../../../../Components/Grid/Grid';
import { Column, Scrolling, Summary, TotalItem, Selection, MasterDetail, HeaderFilter, FilterRow } from 'devextreme-react/data-grid';
import { FaRegCopy } from 'react-icons/fa';
var RequestTimeline = /** @class */ (function (_super) {
    __extends(RequestTimeline, _super);
    function RequestTimeline(props) {
        var _a;
        var _this = _super.call(this, props) || this;
        _this._requestsData = null;
        _this._lastRawLength = 0;
        _this._checkTimer = 0;
        _this._requestFilter = '';
        _this._durationCssClass = (_a = {},
            _a[200] = 'badge-danger',
            _a[100] = 'badge-warning',
            _a[50] = 'badge-info',
            _a[0] = 'badge-success',
            _a);
        _this._getDurationCssClass = function (model) {
            var duration = model.duration, hasException = model.hasException;
            var cssClass = '';
            if (duration > 200) {
                cssClass = _this._durationCssClass[200];
            }
            else if (duration > 100) {
                cssClass = _this._durationCssClass[100];
            }
            else if (duration > 50) {
                cssClass = _this._durationCssClass[50];
            }
            else {
                cssClass = _this._durationCssClass[0];
            }
            if (hasException) {
                cssClass += 'requestItemWithException';
            }
            return cssClass;
        };
        _this._getDurationWidth = function (value, maxScale, maxWidth) {
            var result = maxWidth;
            if (value < maxScale) {
                result = Math.ceil(value / (maxScale / maxWidth));
            }
            if (result >= maxWidth - 10) {
                result -= 10;
            }
            return result.toString();
        };
        _this._requestFilter = localStorage.getItem('DevToolRequestFilter') || '';
        _this.onUnload = _this.onUnload.bind(_this);
        return _this;
    }
    RequestTimeline.prototype.onUnload = function () {
        requestTimelineService.setValue('');
    };
    RequestTimeline.prototype.componentDidMount = function () {
        var _this = this;
        requestTimelineService.clear();
        window.addEventListener('beforeunload', this.onUnload);
        this._checkTimer = window.setInterval(function () { return _this._checkRequests(); }, 1000);
        if (appStore.isUserHasAdminRole) {
            signalRService.subscribe(SignalREvents.sendJobTimeline, this.onJobTimelineChangedHandler);
            console.log('Subscribe to Job events');
        }
    };
    RequestTimeline.prototype.componentWillUnmount = function () {
        this.onUnload();
        window.clearInterval(this._checkTimer);
        window.removeEventListener('beforeunload', this.onUnload);
        if (appStore.isUserHasAdminRole) {
            signalRService.unsubscribe(SignalREvents.sendJobTimeline, this.onJobTimelineChangedHandler);
        }
    };
    RequestTimeline.prototype._base64ToArrayBuffer = function (base64) {
        var binaryString = window.atob(base64);
        var len = binaryString.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    };
    RequestTimeline.prototype._checkRequests = function () {
        var rawRequests = requestTimelineService.readTimeline(this._lastRawLength);
        if (rawRequests === null)
            return;
        var requests = [];
        for (var i = 0; i < rawRequests.length; i++) {
            var request = this._parseRawData(rawRequests[i]);
            request.Raw = rawRequests[i];
            requests.push(request);
        }
        var prevData = JSON.stringify(this._requestsData);
        var newData = JSON.stringify(requests);
        if (prevData !== newData) {
            this._requestsData = requests;
        }
    };
    RequestTimeline.prototype._parseRawData = function (rawBase64) {
        var raw = this._base64ToArrayBuffer(rawBase64);
        var jsonData = pako.inflate(raw, { to: 'string', raw: true });
        return JSON.parse(jsonData);
    };
    RequestTimeline.prototype.onJobTimelineChangedHandler = function (eventName, data) {
        console.log('Job timeline: ' + eventName);
        requestTimelineService.addTimeline(data.rawTimeline);
    };
    RequestTimeline.prototype._renderRequests = function () {
        var _this = this;
        var gridData = [];
        var filterGroups = [];
        var filterGroupParts = this._requestFilter.split(';');
        for (var j = 0; j < filterGroupParts.length; j++) {
            var filterParts = filterGroupParts[j].split(' ');
            var filters = [];
            for (var i = 0; i < filterParts.length; i++) {
                var value = filterParts[i].toLowerCase();
                var type = '+URL';
                if (value.startsWith('-')) {
                    type = '-URL';
                    value = value.substring(1);
                }
                if (value.startsWith('$')) {
                    type = '+SQL';
                    value = value.substring(1);
                }
                if (!value)
                    continue;
                filters.push({
                    value: value,
                    type: type
                });
            }
            filterGroups.push(filters);
        }
        if (this._requestsData) {
            for (var i = 0; i < this._requestsData.length; i++) {
                var request = this._requestsData[i];
                var matchFilters = !filterGroupParts.length;
                for (var k = 0; k < filterGroupParts.length; k++) {
                    var matchFilter = true;
                    var filters = filterGroups[k];
                    for (var j = 0; j < filters.length; j++) {
                        var filter = filters[j];
                        if (filter.type === '+URL') {
                            matchFilter =
                                matchFilter &&
                                    (request.TimelineParameters + ' ' + request.TimelineName)
                                        .toLowerCase()
                                        .includes(filter.value);
                        }
                        if (filter.type === '-URL') {
                            matchFilter =
                                matchFilter &&
                                    !(request.TimelineParameters + ' ' + request.TimelineName)
                                        .toLowerCase()
                                        .includes(filter.value);
                        }
                        if (filter.type === '+SQL') {
                            matchFilter =
                                matchFilter &&
                                    request.Items.map(function (x) { return x.OperationName || x.Text || ''; })
                                        .join(' ')
                                        .toLowerCase()
                                        .includes(filter.value);
                        }
                    }
                    if (matchFilter) {
                        matchFilters = true;
                    }
                }
                if (!matchFilters)
                    continue;
                gridData.push({
                    action: request.TimelineName.startsWith('Job') ? 'JOB' : request.TimelineParameters,
                    request: request.TimelineName,
                    duration: request.TotalTimeMs,
                    timestampUtc: request.StartUtc,
                    rawData: request.Raw || '',
                    hasException: request.Items.some(function (x) { return !!x.IsException; }),
                    items: request.Items
                });
            }
        }
        return (React.createElement(Grid, { dataSource: gridData },
            React.createElement(Column, { dataField: "action", caption: "Action", width: 100, allowFiltering: false }),
            React.createElement(Column, { dataField: "request", caption: "Name" }),
            React.createElement(Column, { dataField: "duration", caption: "Duration", width: 100, headerFilter: { groupInterval: 20 }, dataType: "number", calculateCellValue: function (model) { return model.duration.toFixed(2); }, cellRender: function (cellInfo) {
                    var calcWidth = _this._getDurationWidth(cellInfo.data.duration, 200, 100);
                    return (React.createElement(React.Fragment, null,
                        React.createElement("div", null,
                            cellInfo.text,
                            " ms."),
                        React.createElement("div", { style: { width: "".concat(calcWidth, "px"), height: '10px' }, className: _this._getDurationCssClass(cellInfo.data) })));
                } }),
            React.createElement(Column, { dataField: "timestampUtc", caption: "Timestamp UTC", width: 240, allowHeaderFiltering: false, allowFiltering: false }),
            React.createElement(Column, { dataField: "", caption: "" }),
            React.createElement(Summary, null,
                React.createElement(TotalItem, { column: "action", summaryType: "count", customizeText: function (e) {
                        return "Requests: ".concat(e.value);
                    } })),
            React.createElement(Scrolling, { mode: "infinite" }),
            React.createElement(Selection, { mode: "single" }),
            React.createElement(HeaderFilter, { visible: true }),
            React.createElement(FilterRow, { visible: true }),
            React.createElement(MasterDetail, { enabled: true, render: function (e) { return _this._renderRequestDetails(e); } })));
    };
    RequestTimeline.prototype._renderRequestDetails = function (props) {
        var _this = this;
        if (!props || !props.data)
            return;
        var model = props.data;
        return (React.createElement(Grid, { dataSource: model.items, wordWrapEnabled: false },
            React.createElement(Column, { dataField: "StartTimeMs", caption: "Duration", width: 100, calculateCellValue: function (model) {
                    return (model.EndTimeMs - model.StartTimeMs).toFixed(2);
                }, cellRender: function (cellInfo) {
                    var item = cellInfo.data;
                    var calcWidth = _this._getDurationWidth(cellInfo.value, 10, 100);
                    return (React.createElement(React.Fragment, null,
                        React.createElement("div", null,
                            cellInfo.text,
                            " ms."),
                        React.createElement("div", { style: { width: "".concat(calcWidth, "px"), height: '10px', backgroundColor: item.CssColor } })));
                } }),
            React.createElement(Column, { dataField: "OperationName", caption: "Operation" }),
            React.createElement(Column, { dataField: "", caption: "Copy", width: 60, cellRender: function (cellInfo) {
                    return (React.createElement("div", { style: { cursor: 'pointer' } },
                        React.createElement(FaRegCopy, { onClick: function () {
                                return navigator.clipboard.writeText("".concat(cellInfo.data.OperationName).concat('\n\r').concat(cellInfo.data.OperationParameters));
                            } })));
                } })));
    };
    RequestTimeline.prototype._clearRequests = function () {
        requestTimelineService.clear();
        this._requestsData = [];
    };
    RequestTimeline.prototype._setFilter = function (value) {
        this._requestFilter = value;
        localStorage.setItem('DevToolRequestFilter', value);
    };
    RequestTimeline.prototype.render = function () {
        var _this = this;
        return (React.createElement(React.Fragment, null,
            React.createElement(Button, { outline: true, size: "sm", onClick: this._clearRequests }, "Clear"),
            React.createElement("div", { className: requestStyles.requestsFilter, style: { display: 'flex' } },
                React.createElement("input", { className: "form-control", value: this._requestFilter, onChange: function (e) { return _this._setFilter(e.target.value); }, placeholder: "Filter" })),
            React.createElement("div", null, this._renderRequests())));
    };
    __decorate([
        observable
    ], RequestTimeline.prototype, "_requestsData", void 0);
    __decorate([
        observable
    ], RequestTimeline.prototype, "_requestFilter", void 0);
    __decorate([
        action.bound
    ], RequestTimeline.prototype, "onJobTimelineChangedHandler", null);
    __decorate([
        action
    ], RequestTimeline.prototype, "_renderRequestDetails", null);
    __decorate([
        action
    ], RequestTimeline.prototype, "_clearRequests", null);
    __decorate([
        action
    ], RequestTimeline.prototype, "_setFilter", null);
    RequestTimeline = __decorate([
        observer
    ], RequestTimeline);
    return RequestTimeline;
}(React.Component));
export default RequestTimeline;
