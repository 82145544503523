var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Label } from 'reactstrap';
import { Api } from '@app/Api';
import { UniqueIdPicker } from '@app/Components/Pickers/UniqueIdPicker/UniqueIdPicker';
import { OrgUnitPicker } from '@app/Components/Pickers/OrgUnitPicker/OrgUnitPicker';
import { ProjectPicker } from '@app/Components/Pickers/ProjectPicker/ProjectPicker';
export var UniqueIdFilter = /** @class */ (function (_super) {
    __extends(UniqueIdFilter, _super);
    function UniqueIdFilter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.projectPickerRef = React.createRef();
        _this.orgUnitPickerRef = React.createRef();
        return _this;
    }
    UniqueIdFilter.prototype._handleProjectChange = function (projectId) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.getProjectInfo({ id: parseInt(projectId) }).then(function (res) {
                            _this._selectedUniqueIdOption = { value: res.uniqueId, label: res.uniqueId };
                            _this.props.onUniqueIdChange(res.uniqueId);
                        })];
                    case 1:
                        _a.sent();
                        this.clearOrgUnitPicker();
                        return [2 /*return*/];
                }
            });
        });
    };
    UniqueIdFilter.prototype._handleOrgUnitChange = function (orgUnitId) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.getOrgUnitInfo({ id: parseInt(orgUnitId) }).then(function (res) {
                            _this._selectedUniqueIdOption = { value: res.uniqueId, label: res.uniqueId };
                            _this.props.onUniqueIdChange(res.uniqueId);
                        })];
                    case 1:
                        _a.sent();
                        this.clearProjectPicker();
                        return [2 /*return*/];
                }
            });
        });
    };
    UniqueIdFilter.prototype.handleUniqueIdChange = function (option) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.props.onUniqueIdChange(option === null || option === void 0 ? void 0 : option.value);
                this.clearProjectPicker();
                this.clearOrgUnitPicker();
                return [2 /*return*/];
            });
        });
    };
    UniqueIdFilter.prototype.clearProjectPicker = function () {
        if (this.projectPickerRef && this.projectPickerRef.current) {
            this.projectPickerRef.current._selectedValue = null;
        }
    };
    UniqueIdFilter.prototype.clearOrgUnitPicker = function () {
        if (this.orgUnitPickerRef && this.orgUnitPickerRef.current) {
            this.orgUnitPickerRef.current._selectedValue = null;
        }
    };
    UniqueIdFilter.prototype.render = function () {
        var _this = this;
        return (React.createElement(React.Fragment, null,
            React.createElement(Label, null, "Project Id:"),
            React.createElement(ProjectPicker, { ref: this.projectPickerRef, showDisplayName: false, onChangeHandler: function (x) { return _this._handleProjectChange(x.value); } }),
            React.createElement(Label, null, "Org Unit:"),
            React.createElement(OrgUnitPicker, { ref: this.orgUnitPickerRef, isForProject: false, isActiveOnly: false, isActiveOrWithProjectsOnly: false, onChangeHandler: function (x) { return _this._handleOrgUnitChange(x.value); } }),
            React.createElement(Label, null, "Unique Id of Object:"),
            React.createElement(UniqueIdPicker, { value: this._selectedUniqueIdOption, onChangeHandler: this.handleUniqueIdChange })));
    };
    __decorate([
        observable
    ], UniqueIdFilter.prototype, "_selectedUniqueIdOption", void 0);
    __decorate([
        action.bound
    ], UniqueIdFilter.prototype, "_handleProjectChange", null);
    __decorate([
        action.bound
    ], UniqueIdFilter.prototype, "_handleOrgUnitChange", null);
    __decorate([
        action.bound
    ], UniqueIdFilter.prototype, "handleUniqueIdChange", null);
    __decorate([
        action.bound
    ], UniqueIdFilter.prototype, "clearProjectPicker", null);
    __decorate([
        action.bound
    ], UniqueIdFilter.prototype, "clearOrgUnitPicker", null);
    UniqueIdFilter = __decorate([
        observer
    ], UniqueIdFilter);
    return UniqueIdFilter;
}(React.Component));
