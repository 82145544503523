var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { Input, Label, FormFeedback } from 'reactstrap';
import { observer } from 'mobx-react';
import { ConfirmationInput } from '@app/Widgets/Meeting/ConfirmationInput/ConfirmationInput';
import { Select } from './Select/Select';
import { ConfirmationMarkdown } from '@app/Widgets/Meeting/ConfirmationMarkdown/ConfirmationMarkdown';
import { DatePicker } from './DatePicker';
import { computed } from 'mobx';
export var FormCheckbox = /** @class */ (function (_super) {
    __extends(FormCheckbox, _super);
    function FormCheckbox() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FormCheckbox.prototype.render = function () {
        var _a = this.props, label = _a.label, name = _a.name, formModel = _a.formModel, checked = _a.checked, invalid = _a.invalid, changeHandler = _a.changeHandler, bsSize = _a.bsSize, className = _a.className, topAligned = _a.topAligned, smallValidationError = _a.smallValidationError, rest = __rest(_a, ["label", "name", "formModel", "checked", "invalid", "changeHandler", "bsSize", "className", "topAligned", "smallValidationError"]);
        var isChecked = checked;
        var isInvalid = invalid;
        var classList = ['checkbox'];
        if (formModel) {
            isChecked = formModel[name].toString() == 'true';
            isInvalid = formModel.validated ? !formModel.isValid(name) : formModel.validated;
        }
        if (bsSize)
            classList.push(bsSize);
        if (className)
            classList.push(className);
        if (topAligned)
            classList.push('top');
        return (React.createElement(React.Fragment, null,
            React.createElement(Label, { className: classList.join(' ') },
                React.createElement(Input, __assign({ checked: isChecked, invalid: isInvalid, type: "checkbox", onChange: function (event) {
                        if (formModel) {
                            formModel[name] = event.target.checked;
                        }
                        if (changeHandler)
                            changeHandler();
                    }, name: name }, rest)),
                React.createElement("span", null, label)),
            formModel && (React.createElement(FormFeedback, { className: smallValidationError ? 'label-small' : '' }, formModel.errorFor(name).map(function (error) { return (React.createElement("div", { key: name + error }, error)); })))));
    };
    FormCheckbox = __decorate([
        observer
    ], FormCheckbox);
    return FormCheckbox;
}(React.Component));
export var FormInput = /** @class */ (function (_super) {
    __extends(FormInput, _super);
    function FormInput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FormInput.prototype.render = function () {
        var _a, _b;
        var _c = this.props, formModel = _c.formModel, name = _c.name, changeHandler = _c.changeHandler, smallValidationError = _c.smallValidationError, rest = __rest(_c, ["formModel", "name", "changeHandler", "smallValidationError"]);
        var fieldValue = (_a = formModel, _b = name, _a[_b]);
        return (React.createElement(React.Fragment, null,
            React.createElement(Input, __assign({ name: name, value: fieldValue, autoComplete: "off", invalid: formModel.validated ? !formModel.isValid(name) : formModel.validated, onChange: function (ev) {
                    formModel.setValue(name, ev.target.value);
                    if (changeHandler)
                        changeHandler();
                } }, rest)),
            formModel.invalidFields.includes(name) && (React.createElement(FormFeedback, { className: smallValidationError ? 'label-small' : '' }, formModel.errorFor(name).map(function (error) { return (React.createElement("div", { key: name + error }, error)); })))));
    };
    FormInput = __decorate([
        observer
    ], FormInput);
    return FormInput;
}(React.Component));
export var FormConfirmationInput = /** @class */ (function (_super) {
    __extends(FormConfirmationInput, _super);
    function FormConfirmationInput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FormConfirmationInput.prototype.render = function () {
        var _a, _b, _c;
        var formModel = (_a = this.props, _a.formModel), placeholder = _a.placeholder, name = _a.name, changeHandler = _a.changeHandler, smallValidationError = _a.smallValidationError, disabled = _a.disabled, className = _a.className;
        var fieldValue = (_b = formModel, _c = name, _b[_c]);
        return (React.createElement(React.Fragment, null,
            React.createElement(ConfirmationInput, { value: fieldValue, invalid: formModel.validated ? !formModel.isValid(name) : formModel.validated, disabled: disabled, onChange: function (val) {
                    formModel.setValue(name, val);
                    if (changeHandler)
                        changeHandler(val);
                }, placeholder: placeholder, className: className }),
            formModel.invalidFields.includes(name) && (React.createElement(FormFeedback, { className: smallValidationError ? 'label-small' : '' }, formModel.errorFor(name).map(function (error) { return (React.createElement("div", { key: name + error }, error)); })))));
    };
    FormConfirmationInput = __decorate([
        observer
    ], FormConfirmationInput);
    return FormConfirmationInput;
}(React.Component));
export var FormConfirmationMarkdown = /** @class */ (function (_super) {
    __extends(FormConfirmationMarkdown, _super);
    function FormConfirmationMarkdown() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FormConfirmationMarkdown.prototype.render = function () {
        var _a, _b, _c;
        var formModel = (_a = this.props, _a.formModel), name = _a.name, changeHandler = _a.changeHandler, smallValidationError = _a.smallValidationError, disabled = _a.disabled, className = _a.className;
        var fieldValue = (_b = formModel, _c = name, _b[_c]);
        return (React.createElement(React.Fragment, null,
            React.createElement(ConfirmationMarkdown, { value: fieldValue, invalid: formModel.validated ? !formModel.isValid(name) : formModel.validated, disabled: disabled, onChange: function (val) {
                    formModel.setValue(name, val);
                    if (changeHandler)
                        changeHandler(val);
                }, className: className }),
            formModel.invalidFields.includes(name) && (React.createElement(FormFeedback, { className: smallValidationError ? 'label-small' : '' }, formModel.errorFor(name).map(function (error) { return (React.createElement("div", { key: name + error }, error)); })))));
    };
    FormConfirmationMarkdown = __decorate([
        observer
    ], FormConfirmationMarkdown);
    return FormConfirmationMarkdown;
}(React.Component));
export var FormSelect = /** @class */ (function (_super) {
    __extends(FormSelect, _super);
    function FormSelect(props) {
        var _this = _super.call(this, props) || this;
        var formModel = props.formModel, name = props.name, defaultValue = props.defaultValue;
        if (defaultValue)
            formModel.setValue(name, defaultValue.value);
        return _this;
    }
    Object.defineProperty(FormSelect.prototype, "formModelValue", {
        get: function () {
            var _a;
            var formModel = (_a = this.props, _a.formModel), name = _a.name, options = _a.options;
            var value = formModel[name];
            var opts = options;
            return opts.find(function (x) { return x.value === value; });
        },
        enumerable: false,
        configurable: true
    });
    FormSelect.prototype.render = function () {
        var _a;
        var _this = this;
        var formModel = (_a = this.props, _a.formModel), name = _a.name, placeholder = _a.placeholder, options = _a.options, smallValidationError = _a.smallValidationError, disabled = _a.disabled, changeHandler = _a.changeHandler;
        var isInvalid = formModel.validated ? !formModel.isValid(name) : formModel.validated;
        var cssClass = isInvalid ? 'is-invalid' : '';
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: cssClass },
                React.createElement(Select, { options: options, onChange: function (valueoption) {
                        if (!Array.isArray(valueoption) && valueoption) {
                            var option = valueoption;
                            formModel.setValue(name, option.value);
                            if (changeHandler) {
                                changeHandler();
                            }
                            _this.forceUpdate();
                        }
                    }, value: this.formModelValue || null, isDisabled: disabled, placeholder: placeholder, isInvalid: formModel.validated ? !formModel.isValid(name) : formModel.validated })),
            formModel.invalidFields.includes(name) && (React.createElement(FormFeedback, { className: smallValidationError ? 'label-small' : '' }, formModel.errorFor(name).map(function (error) { return (React.createElement("div", { key: name + error }, error)); })))));
    };
    __decorate([
        computed
    ], FormSelect.prototype, "formModelValue", null);
    FormSelect = __decorate([
        observer
    ], FormSelect);
    return FormSelect;
}(React.Component));
export var FormDateBox = /** @class */ (function (_super) {
    __extends(FormDateBox, _super);
    function FormDateBox() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FormDateBox.prototype.render = function () {
        var _a = this.props, formModel = _a.formModel, name = _a.name, smallValidationError = _a.smallValidationError, onDateChange = _a.onDateChange, defaultValue = _a.defaultValue, displayFormat = _a.displayFormat, type = _a.type, rest = __rest(_a, ["formModel", "name", "smallValidationError", "onDateChange", "defaultValue", "displayFormat", "type"]);
        var isInvalid = formModel.validated ? !formModel.isValid(name) : formModel.validated;
        var cssClass = isInvalid ? 'is-invalid' : '';
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: cssClass },
                React.createElement(DatePicker, __assign({ disabled: this.props.disabled, type: type || 'datetime', displayFormat: displayFormat || 'dd MMM yyyy', defaultValue: defaultValue, onValueChanged: function (e) {
                        formModel.setValue(name, e.value);
                        onDateChange === null || onDateChange === void 0 ? void 0 : onDateChange(e.value);
                    }, isValid: !isInvalid }, rest))),
            formModel.invalidFields.includes(name) && (React.createElement(FormFeedback, { className: smallValidationError ? 'label-small' : '' }, formModel.errorFor(name).map(function (error) { return (React.createElement("div", { key: name + error }, error)); })))));
    };
    FormDateBox = __decorate([
        observer
    ], FormDateBox);
    return FormDateBox;
}(React.Component));
export var FormSwitcher = /** @class */ (function (_super) {
    __extends(FormSwitcher, _super);
    function FormSwitcher() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FormSwitcher.prototype.render = function () {
        var _a;
        var name = (_a = this.props, _a.name), formModel = _a.formModel, changeHandler = _a.changeHandler;
        if (formModel)
            return (React.createElement(React.Fragment, null,
                React.createElement(Label, { className: "switcher" },
                    React.createElement(Input, { type: "checkbox", checked: formModel[name], name: name, invalid: formModel.validated ? !formModel.isValid(name) : formModel.validated, disabled: this.props.disabled, onChange: function (ev) {
                            formModel[name] = ev.target.checked;
                            if (changeHandler)
                                changeHandler();
                        } }),
                    React.createElement("span", null)),
                React.createElement(FormFeedback, null, formModel.errorFor(name))));
    };
    FormSwitcher = __decorate([
        observer
    ], FormSwitcher);
    return FormSwitcher;
}(React.Component));
export var RowLabel = /** @class */ (function (_super) {
    __extends(RowLabel, _super);
    function RowLabel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RowLabel.prototype.render = function () {
        var _a;
        var text = (_a = this.props, _a.text), mandatory = _a.mandatory, className = _a.className;
        return (React.createElement("div", { className: className },
            React.createElement(Label, null, text),
            mandatory && React.createElement("em", null, "*")));
    };
    RowLabel = __decorate([
        observer
    ], RowLabel);
    return RowLabel;
}(React.PureComponent));
