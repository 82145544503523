var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { ModalBody, ModalFooter, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { observable, action } from 'mobx';
import styles from './TemplatesImport.module.scss';
import { observer } from 'mobx-react';
import { FaTrash } from 'react-icons/fa';
import { Progress } from 'reactstrap';
import { signalRService } from '@app/Services/SignalRService';
import { SignalREvents } from '@app/AppConstants';
import { Button } from '@app/Components/Button/Button';
import { Modal } from '@app/Components/Modal/Modal';
export var TemplatesImport = /** @class */ (function (_super) {
    __extends(TemplatesImport, _super);
    function TemplatesImport(props) {
        var _this = _super.call(this, props) || this;
        _this._fileInputMasterRef = React.createRef();
        _this._fileInputFunctionRef = React.createRef();
        _this._files = [];
        _this._isLoading = false;
        _this._isLoadingFinished = false;
        _this._masterFileMode = _this.props.masterFileMode;
        _this._dropdownOpen = false;
        _this._finishedImportFilesNumber = 0;
        signalRService.subscribe(SignalREvents.excelImportProgressValueChanged, _this.onExcelImportProgressValueChanged);
        return _this;
    }
    TemplatesImport.prototype.unload = function () {
        signalRService.unsubscribe(SignalREvents.excelImportProgressValueChanged, this.onExcelImportProgressValueChanged);
    };
    TemplatesImport.prototype.onExcelImportProgressValueChanged = function () {
        this._finishedImportFilesNumber++;
    };
    TemplatesImport.prototype.componentWillUnmount = function () {
        this.unload();
    };
    TemplatesImport.prototype._handleFileChange = function (e, isMaster) {
        return __awaiter(this, void 0, void 0, function () {
            var files;
            return __generator(this, function (_a) {
                e.persist();
                files = e.target.files;
                if (files && files.length) {
                    if (isMaster) {
                        this._files = [files[0]];
                    }
                    else {
                        this._files = __spreadArray(__spreadArray([], this._files, true), files, true);
                    }
                }
                this._finishedImportFilesNumber = 0;
                return [2 /*return*/];
            });
        });
    };
    TemplatesImport.prototype._handleAddClick = function () {
        var refInput = this._masterFileMode ? this._fileInputMasterRef.current : this._fileInputFunctionRef.current;
        if (refInput) {
            refInput.value = '';
            refInput.click();
        }
    };
    TemplatesImport.prototype._handleDelete = function (index) {
        this._files.splice(index, 1);
    };
    TemplatesImport.prototype._handleSave = function () {
        return __awaiter(this, void 0, void 0, function () {
            var save;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        save = this.props.save;
                        this._isLoading = true;
                        this._finishedImportFilesNumber = 0;
                        return [4 /*yield*/, (save === null || save === void 0 ? void 0 : save(this._files))];
                    case 1:
                        _a.sent();
                        this._isLoading = false;
                        this._isLoadingFinished = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    TemplatesImport.prototype._handleCancel = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._files = [];
                this._finishedImportFilesNumber = 0;
                this._isLoadingFinished = false;
                this.props.toggle();
                return [2 /*return*/];
            });
        });
    };
    TemplatesImport.prototype._togleDropdown = function () {
        this._dropdownOpen = !this._dropdownOpen;
    };
    TemplatesImport.prototype._setFileMode = function (isMaster) {
        var oldMasterFileMode = this._masterFileMode;
        this._masterFileMode = !!isMaster;
        this._files = [];
        this._isLoadingFinished = false;
        if (this._masterFileMode !== oldMasterFileMode) {
            this.props.modeChange();
        }
    };
    TemplatesImport.prototype.render = function () {
        var _a;
        var _this = this;
        var isOpen = (_a = this.props, _a.isOpen), toggle = _a.toggle;
        var hasErrors = !!this.props.importErrors.length;
        return (React.createElement(Modal, { isOpen: isOpen, toggle: toggle, headerTitle: "Import template" },
            React.createElement(ModalBody, null,
                React.createElement("div", { className: styles.list }, this._files.map(function (f, i) { return (React.createElement("div", { key: i, className: styles.item },
                    React.createElement("div", { className: styles.itemTitle }, f.name),
                    React.createElement("div", { className: styles.itemAction },
                        React.createElement(Button, { size: "xs", color: "danger", onClick: function () { return _this._handleDelete(i); } },
                            React.createElement(FaTrash, null))))); })),
                React.createElement("div", { className: styles.buttonLine },
                    React.createElement(ButtonDropdown, { isOpen: this._dropdownOpen, toggle: this._togleDropdown },
                        React.createElement(DropdownToggle, { caret: true, className: styles.dropdownButton }, this._masterFileMode ? 'Master File' : 'Function Files'),
                        React.createElement(DropdownMenu, null,
                            React.createElement(DropdownItem, { onClick: function () { return _this._setFileMode(true); } }, "Master File"),
                            React.createElement(DropdownItem, { onClick: function () { return _this._setFileMode(); } }, "Function Files"))),
                    React.createElement("span", { style: { color: 'gray', cursor: 'pointer' }, onClick: this._handleAddClick },
                        React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", focusable: "false", role: "presentation" },
                            React.createElement("path", { d: "M13 11V3.993A.997.997 0 0 0 12 3c-.556 0-1 .445-1 .993V11H3.993A.997.997 0 0 0 3 12c0 .557.445 1 .993 1H11v7.007c0 .548.448.993 1 .993.556 0 1-.445 1-.993V13h7.007A.997.997 0 0 0 21 12c0-.556-.445-1-.993-1H13z", fill: "currentColor", fillRule: "evenodd" })))),
                React.createElement("input", { style: { display: 'none' }, type: "file", ref: this._fileInputMasterRef, onChange: function (e) { return _this._handleFileChange(e, true); } }),
                React.createElement("input", { style: { display: 'none' }, type: "file", multiple: true, ref: this._fileInputFunctionRef, onChange: this._handleFileChange }),
                !!this._files.length && this._isLoading && (React.createElement("div", { className: "progressSection" },
                    React.createElement(Progress, { className: styles.progress, animated: true, value: (this._finishedImportFilesNumber / this._files.length) * 100 }))),
                React.createElement("div", { className: styles.errorContainer }, hasErrors &&
                    this.props.importErrors.map(function (err, i) {
                        var _a;
                        return (React.createElement("div", { key: i, className: styles.errorItem },
                            React.createElement("p", null,
                                "Error(s) in file: ",
                                err.fileName), (_a = err.errorMessages) === null || _a === void 0 ? void 0 :
                            _a.map(function (x, index) { return (React.createElement("p", { key: "error".concat(index) }, x)); })));
                    }))),
            React.createElement(ModalFooter, null,
                React.createElement("div", { className: styles.footerRow },
                    React.createElement("div", { className: styles.criticalWarning }, hasErrors && 'File(s) with erros will not be saved'),
                    React.createElement("div", { className: styles.footerBtnWrapper },
                        !this._isLoadingFinished && (React.createElement(React.Fragment, null,
                            React.createElement(Button, { size: "sm", color: "primary", onClick: this._handleSave, disabled: this._files.length === 0, isLoading: this._isLoading }, "Upload"),
                            React.createElement(Button, { onClick: this._handleCancel }, "Close"))),
                        this._isLoadingFinished && React.createElement(Button, { onClick: this._handleCancel }, "Ok"))))));
    };
    __decorate([
        observable
    ], TemplatesImport.prototype, "_fileInputMasterRef", void 0);
    __decorate([
        observable
    ], TemplatesImport.prototype, "_fileInputFunctionRef", void 0);
    __decorate([
        observable
    ], TemplatesImport.prototype, "_files", void 0);
    __decorate([
        observable
    ], TemplatesImport.prototype, "_isLoading", void 0);
    __decorate([
        observable
    ], TemplatesImport.prototype, "_isLoadingFinished", void 0);
    __decorate([
        observable
    ], TemplatesImport.prototype, "_masterFileMode", void 0);
    __decorate([
        observable
    ], TemplatesImport.prototype, "_dropdownOpen", void 0);
    __decorate([
        observable
    ], TemplatesImport.prototype, "_finishedImportFilesNumber", void 0);
    __decorate([
        action.bound
    ], TemplatesImport.prototype, "unload", null);
    __decorate([
        action.bound
    ], TemplatesImport.prototype, "onExcelImportProgressValueChanged", null);
    __decorate([
        action.bound
    ], TemplatesImport.prototype, "_handleFileChange", null);
    __decorate([
        action.bound
    ], TemplatesImport.prototype, "_handleAddClick", null);
    __decorate([
        action.bound
    ], TemplatesImport.prototype, "_handleDelete", null);
    __decorate([
        action.bound
    ], TemplatesImport.prototype, "_handleSave", null);
    __decorate([
        action.bound
    ], TemplatesImport.prototype, "_handleCancel", null);
    __decorate([
        action.bound
    ], TemplatesImport.prototype, "_togleDropdown", null);
    __decorate([
        action.bound
    ], TemplatesImport.prototype, "_setFileMode", null);
    TemplatesImport = __decorate([
        observer
    ], TemplatesImport);
    return TemplatesImport;
}(React.PureComponent));
