var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Grid } from '@app/Components/Grid/Grid';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Api } from '@app/Api';
import { Column } from 'devextreme-react/data-grid';
import { withRouter, Switch, Route } from 'react-router-dom';
import { SignalREvents, ChecklistTypeEnum, StatusEnum, SeparatedStrings, ObjectType, Notifications } from '@app/AppConstants';
import { Button } from '@app/Components/Button/Button';
import styles from './ChecklistsTable.module.scss';
import columnSharedStyles from '@app/Components/Grid/SharedStyles/column-shared-styles.module.scss';
import { DateBox } from '@app/Widgets/DateBox/DateBox';
import { ChecklistApproversModal } from './ChecklistApproversModal';
import { signalRService } from '@app/Services/SignalRService';
import { createUniqueId } from '@app/Helpers/UidHelper/UidHelper';
import { ChangeLogDialog } from '@app/Components/Dialogs/ChangeLogDialog';
import { CommentModal } from '@app/Widgets/CommentModal/CommentModal';
import { CodeCell } from '@app/Components/Grid/CodeCell/CodeCell';
import { ValidationHint } from '@app/Components/ValidationHint/ValidationHint';
import { FunctionList } from '@app/Components/Grid/FunctionList/FunctionList';
import { ChecklistStatusIcon } from '@app/Components/ChecklistStatusIcon/ChecklistStatusIcon';
var ChecklistsTableC = /** @class */ (function (_super) {
    __extends(ChecklistsTableC, _super);
    function ChecklistsTableC() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._checklists = [];
        _this._isApproversModalOpen = false;
        _this._currentChecklist = null;
        _this._loading = false;
        _this._isUnapproveChecklistModalOpen = false;
        _this._unapprovedChecklist = null;
        return _this;
    }
    ChecklistsTableC.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._load()];
                    case 1:
                        _a.sent();
                        signalRService.subscribe(SignalREvents.recommendationChanged, this._load);
                        return [2 /*return*/];
                }
            });
        });
    };
    ChecklistsTableC.prototype.componentWillUnmount = function () {
        signalRService.unsubscribe(SignalREvents.recommendationChanged, this._load);
    };
    ChecklistsTableC.prototype.componentDidUpdate = function (prevProps) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(prevProps.match.params.id !== this.props.match.params.id ||
                            prevProps.hideNAItems !== this.props.hideNAItems)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._load()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ChecklistsTableC.prototype._load = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, projectId, gateId, hideNAItems, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this.props, projectId = _a.projectId, gateId = _a.gateId, hideNAItems = _a.hideNAItems;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, , 3, 4]);
                        this._loading = true;
                        _b = this;
                        return [4 /*yield*/, Api.secured.getChecklistsSmallModel({
                                projectId: projectId,
                                gateId: gateId,
                                hideNAItems: hideNAItems
                            })];
                    case 2:
                        _b._checklists = _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this._loading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ChecklistsTableC.prototype._showApprovers = function (checklist) {
        this._currentChecklist = checklist;
        this._toggleApproversModal();
    };
    ChecklistsTableC.prototype._handlePrepare = function (checkListId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.prepareFunctionChecklist(checkListId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._load()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ChecklistsTableC.prototype._toggleUnapproveChecklistModalOpen = function () {
        this._isUnapproveChecklistModalOpen = !this._isUnapproveChecklistModalOpen;
        if (!this._isUnapproveChecklistModalOpen)
            this._unapprovedChecklist = null;
    };
    ChecklistsTableC.prototype._handleUndoApprovalClick = function (checklist) {
        this._toggleUnapproveChecklistModalOpen();
        this._unapprovedChecklist = checklist;
    };
    ChecklistsTableC.prototype._handleUndoApproval = function (comment) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._unapprovedChecklist)
                            return [2 /*return*/];
                        return [4 /*yield*/, Api.secured.undoChecklistApproval(this._unapprovedChecklist.id, { comment: comment })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._load()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ChecklistsTableC.prototype._onApprovalChangedHandler = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._isApproversModalOpen = !this._isApproversModalOpen;
                        return [4 /*yield*/, this._load()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ChecklistsTableC.prototype._toggleLogModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, history, match;
            return __generator(this, function (_b) {
                _a = this.props, history = _a.history, match = _a.match;
                history.push(match.url);
                this._currentChecklist = null;
                return [2 /*return*/];
            });
        });
    };
    ChecklistsTableC.prototype._toggleApproversModal = function () {
        this._isApproversModalOpen = !this._isApproversModalOpen;
    };
    ChecklistsTableC.prototype.renderStatus = function (status) {
        if (status === StatusEnum.NotApplicable)
            return SeparatedStrings.StatusNotApplicable;
        return status;
    };
    ChecklistsTableC.prototype.render = function () {
        var _this = this;
        var _a = this.props, match = _a.match, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted;
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid, { dataSource: this._checklists, expandableRows: true, rowsCount: this._checklists.length, loading: this._loading },
                React.createElement(Column, { caption: "CLI Code", dataField: "code", width: 120, cssClass: columnSharedStyles.colWithHistory, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(CodeCell, { code: data.code, onHistoryClick: function () { return (_this._currentChecklist = data); }, to: "".concat(match.url, "/log") }));
                    } }),
                React.createElement(Column, { caption: "Functions", cellRender: function (_a) {
                        var data = _a.data;
                        if (!data.functions)
                            return null;
                        return React.createElement(FunctionList, { functions: data.functions, rowId: '' + data.id });
                    }, width: 90 }),
                React.createElement(Column, { width: 50, alignment: "center", cellRender: function (_a) {
                        var data = _a.data;
                        return data.status &&
                            data.targetStatus && (React.createElement(ChecklistStatusIcon, { checklistId: data.id, currentStatus: data.status, targetStatus: data.targetStatus }));
                    } }),
                React.createElement(Column, { caption: "Current Status", dataField: "status", width: 110, alignment: "center", calculateCellValue: function (data) { return _this.renderStatus(data.status); } }),
                React.createElement(Column, { caption: "Target Status", dataField: "targetStatus", width: 110, alignment: "center" }),
                React.createElement(Column, { caption: "Target Deliverable Date", dataField: "targetDeliverableDate", width: 170, alignment: "center", dataType: "date", cellRender: function (_a) {
                        var data = _a.data;
                        return data.targetDeliverableDate && React.createElement(DateBox, { date: data.targetDeliverableDate });
                    } }),
                React.createElement(Column, { caption: "Comment (Read only)", dataField: "comment", minWidth: 300, cellRender: function (_a) {
                        var data = _a.data;
                        return React.createElement("div", null, data.comment);
                    } }),
                this._checklists.every(function (x) { return x.type === ChecklistTypeEnum.Master; }) ? null : (React.createElement(Column, { width: 45, cellRender: function (_a) {
                        var data = _a.data;
                        return data.isEnabled &&
                            data.type === ChecklistTypeEnum.Function && (React.createElement("div", { className: styles.actions },
                            React.createElement(Button, { color: data.hasApprovalWarning ? 'danger' : 'success', size: "xs", className: styles.action, onClick: function () { return _this._showApprovers(data); } }, "A")));
                    } })),
                React.createElement(Column, { minWidth: 100, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("div", { className: styles.actions },
                            data.canPrincipalSetInApprovalChecklist && (React.createElement(React.Fragment, null,
                                React.createElement("div", { style: { marginRight: 5 } },
                                    React.createElement(ValidationHint, { id: 'del' + data.id, isValid: data.isValid, errors: [Notifications.ErrorForInvalidReadyForApprovalCheckListString] })),
                                React.createElement(Button, { className: styles.action, size: "sm", color: "success", onClick: function () { return _this._handlePrepare(data.id); }, disabled: !data.isValid || isProjectTerminatedOrCompleted }, "Ready for Approval"))),
                            data.canPrincipalSetToDraftChecklist && (React.createElement(Button, { className: styles.action, size: "sm", color: "success", disabled: isProjectTerminatedOrCompleted, onClick: function () { return _this._handleUndoApprovalClick(data); } }, "Undo Approval"))));
                    } })),
            React.createElement(ChecklistApproversModal, { isOpen: this._isApproversModalOpen, toggle: this._toggleApproversModal, onApprovalChanged: this._onApprovalChangedHandler, checklist: this._currentChecklist, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted }),
            React.createElement(CommentModal, { isOpen: this._isUnapproveChecklistModalOpen, onToggle: this._toggleUnapproveChecklistModalOpen, onSave: this._handleUndoApproval }),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "".concat(match.url, "/log") }, this._currentChecklist && (React.createElement(ChangeLogDialog, { onToggle: this._toggleLogModal, uniqueId: createUniqueId(ObjectType.Checklist, this._currentChecklist.id) }))))));
    };
    __decorate([
        observable
    ], ChecklistsTableC.prototype, "_checklists", void 0);
    __decorate([
        observable
    ], ChecklistsTableC.prototype, "_isApproversModalOpen", void 0);
    __decorate([
        observable
    ], ChecklistsTableC.prototype, "_currentChecklist", void 0);
    __decorate([
        observable
    ], ChecklistsTableC.prototype, "_loading", void 0);
    __decorate([
        observable
    ], ChecklistsTableC.prototype, "_isUnapproveChecklistModalOpen", void 0);
    __decorate([
        observable
    ], ChecklistsTableC.prototype, "_unapprovedChecklist", void 0);
    __decorate([
        action.bound
    ], ChecklistsTableC.prototype, "_load", null);
    __decorate([
        action.bound
    ], ChecklistsTableC.prototype, "_showApprovers", null);
    __decorate([
        action.bound
    ], ChecklistsTableC.prototype, "_handlePrepare", null);
    __decorate([
        action.bound
    ], ChecklistsTableC.prototype, "_toggleUnapproveChecklistModalOpen", null);
    __decorate([
        action.bound
    ], ChecklistsTableC.prototype, "_handleUndoApprovalClick", null);
    __decorate([
        action.bound
    ], ChecklistsTableC.prototype, "_handleUndoApproval", null);
    __decorate([
        action.bound
    ], ChecklistsTableC.prototype, "_onApprovalChangedHandler", null);
    __decorate([
        action.bound
    ], ChecklistsTableC.prototype, "_toggleLogModal", null);
    __decorate([
        action.bound
    ], ChecklistsTableC.prototype, "_toggleApproversModal", null);
    ChecklistsTableC = __decorate([
        observer
    ], ChecklistsTableC);
    return ChecklistsTableC;
}(React.PureComponent));
export var ChecklistsTable = withRouter(ChecklistsTableC);
