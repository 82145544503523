var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import styles from './function-summary-status.module.scss';
import { Status } from '@app/AppConstants';
import { DATE_OPTIONS, convertUTCDateToLocalDate } from '@app/Helpers/DateOptions';
var FunctionSummaryStatus = /** @class */ (function (_super) {
    __extends(FunctionSummaryStatus, _super);
    function FunctionSummaryStatus() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FunctionSummaryStatus.prototype.render = function () {
        var _a = this.props, status = _a.status, approved = _a.approved, isVisible = _a.isVisible;
        if (!isVisible) {
            return null;
        }
        return (React.createElement("div", { className: styles.functionSummaryStatus }, status == Status.Approved && !!approved ? (React.createElement("div", null,
            "FC aproved on ",
            convertUTCDateToLocalDate(approved).toLocaleDateString('en-GB', DATE_OPTIONS),
            '.')) : (React.createElement("div", null,
            "FC Status: ",
            status,
            '.'))));
    };
    return FunctionSummaryStatus;
}(React.PureComponent));
export { FunctionSummaryStatus };
