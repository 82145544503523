var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { action, computed, observable } from 'mobx';
import { Api } from '@app/Api';
import { AppColors as Colors, ColorBlindModeDefaultColors as DefaultColors } from '@app/AppConstants';
export var ColorThemes;
(function (ColorThemes) {
    ColorThemes["Default"] = "default";
    ColorThemes["Blind"] = "blind";
    ColorThemes["NotSet"] = "notSet";
})(ColorThemes || (ColorThemes = {}));
var ColorThemesStore = /** @class */ (function () {
    function ColorThemesStore() {
        this.defaultColors = {
            red: DefaultColors.Red,
            green: DefaultColors.Green,
            orange: DefaultColors.Orange,
            yellow: DefaultColors.Yellow,
            gray: DefaultColors.Gray
        };
        this._colorTheme = ColorThemes.Default;
        this.colors = this.defaultColors;
    }
    ColorThemesStore.prototype.loadColorTheme = function () {
        return __awaiter(this, void 0, void 0, function () {
            var colorThemeResponse, isEnabled, colors;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.getUseColorBlindSchemeValue()];
                    case 1:
                        colorThemeResponse = _a.sent();
                        isEnabled = colorThemeResponse.isEnabled, colors = colorThemeResponse.colors;
                        if (isEnabled !== undefined) {
                            this._initColorTheme = colorThemeResponse;
                            this.setColorTheme(isEnabled);
                            colors && this.setColors(colors);
                        }
                        else {
                            this._initColorTheme = null;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(ColorThemesStore.prototype, "isInitColorThemeNotSet", {
        get: function () {
            return this._initColorTheme === null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ColorThemesStore.prototype, "isBlindColorTheme", {
        get: function () {
            return this._colorTheme === ColorThemes.Blind;
        },
        enumerable: false,
        configurable: true
    });
    ColorThemesStore.prototype.setColorTheme = function (value) {
        this._colorTheme = value ? ColorThemes.Blind : ColorThemes.Default;
    };
    ColorThemesStore.prototype.setColors = function (colors) {
        if (colors === void 0) { colors = this.defaultColors; }
        this.colors = colors;
    };
    ColorThemesStore.prototype.saveColors = function (colors) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._updateColors(colors)];
                    case 1:
                        _a.sent();
                        this.setColors(colors);
                        return [2 /*return*/];
                }
            });
        });
    };
    ColorThemesStore.prototype.resetChanges = function () {
        var _a, _b;
        this.setColorTheme(this.isInitColorThemeNotSet ? false : (_a = this._initColorTheme) === null || _a === void 0 ? void 0 : _a.isEnabled);
        this.setColors(this.isInitColorThemeNotSet ? this.defaultColors : (_b = this._initColorTheme) === null || _b === void 0 ? void 0 : _b.colors);
    };
    ColorThemesStore.prototype._updateColors = function (colors) {
        return __awaiter(this, void 0, void 0, function () {
            var red, green, orange, yellow, gray;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        red = colors.red, green = colors.green, orange = colors.orange, yellow = colors.yellow, gray = colors.gray;
                        return [4 /*yield*/, Api.secured.addOrUpdateUseColorBlindSchemeValue({
                                isEnabled: this.isBlindColorTheme,
                                colorsRed: red,
                                colorsGreen: green,
                                colorsOrange: orange,
                                colorsYellow: yellow,
                                colorsGray: gray
                            })];
                    case 1:
                        _a.sent();
                        this._initColorTheme = { isEnabled: this.isBlindColorTheme, colors: colors };
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(ColorThemesStore.prototype, "appColors", {
        get: function () {
            if (this._colorTheme === ColorThemes.Blind) {
                var _a = this.colors, red = _a.red, green = _a.green, orange = _a.orange, yellow = _a.yellow, gray = _a.gray;
                return {
                    red: red || Colors.Red,
                    green: green || Colors.Green,
                    lightGreen: green || Colors.LightGreen,
                    orange: orange || Colors.Orange,
                    yellow: yellow || Colors.Yellow,
                    gray: gray || Colors.Gray,
                    lightGray: Colors.LightGray,
                    darkGray: Colors.DarkGray,
                    lightBlue: Colors.LightBlue,
                    white: Colors.White,
                    buttonPrimary: Colors.ButtonPrimary,
                    buttonPrimaryHover: Colors.ButtonPrimaryHover,
                    buttonSuccess: green || Colors.ButtonSuccess,
                    buttonSuccessHover: Colors.ButtonSuccessHover,
                    buttonDanger: red || Colors.ButtonDanger,
                    buttonDangerHover: Colors.ButtonDangerHover,
                    buttonDefault: gray || Colors.ButtonDefault,
                    buttonDefaultHover: Colors.ButtonDefaultHover
                };
            }
            return {
                red: Colors.Red,
                green: Colors.Green,
                lightGreen: Colors.LightGreen,
                orange: Colors.Orange,
                yellow: Colors.Yellow,
                gray: Colors.Gray,
                lightGray: Colors.LightGray,
                darkGray: Colors.DarkGray,
                lightBlue: Colors.LightBlue,
                white: Colors.White,
                buttonPrimary: Colors.ButtonPrimary,
                buttonPrimaryHover: Colors.ButtonPrimaryHover,
                buttonSuccess: Colors.ButtonSuccess,
                buttonSuccessHover: Colors.ButtonSuccessHover,
                buttonDanger: Colors.ButtonDanger,
                buttonDangerHover: Colors.ButtonDangerHover,
                buttonDefault: Colors.ButtonDefault,
                buttonDefaultHover: Colors.ButtonDefaultHover
            };
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], ColorThemesStore.prototype, "_initColorTheme", void 0);
    __decorate([
        observable
    ], ColorThemesStore.prototype, "_colorTheme", void 0);
    __decorate([
        observable
    ], ColorThemesStore.prototype, "colors", void 0);
    __decorate([
        computed
    ], ColorThemesStore.prototype, "isInitColorThemeNotSet", null);
    __decorate([
        computed
    ], ColorThemesStore.prototype, "isBlindColorTheme", null);
    __decorate([
        action.bound
    ], ColorThemesStore.prototype, "setColorTheme", null);
    __decorate([
        action.bound
    ], ColorThemesStore.prototype, "setColors", null);
    __decorate([
        action.bound
    ], ColorThemesStore.prototype, "saveColors", null);
    __decorate([
        action.bound
    ], ColorThemesStore.prototype, "resetChanges", null);
    __decorate([
        computed
    ], ColorThemesStore.prototype, "appColors", null);
    return ColorThemesStore;
}());
export var colorThemesStore = new ColorThemesStore();
