var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styles from './ApprovedChecklistDeliversTable.module.scss';
import columnSharedStyles from '@app/Components/Grid/SharedStyles/column-shared-styles.module.scss';
import { Grid } from '@app/Components/Grid/Grid';
import { Column } from 'devextreme-react/data-grid';
import { ApprovalApplicationEnum, ChecklistSections, Events, GateProcessTypeEnum, ObjectType, SignalREvents, StatusEnum } from '@app/AppConstants';
import { EditCell } from '@app/Components/Grid/EditCell/EditCell';
import { action, observable } from 'mobx';
import { createUniqueId } from '@app/Helpers/UidHelper/UidHelper';
import { Api } from '@app/Api';
import { AssessorRatingCell } from './AssessorRaitingCell/AssessorRaitingCell';
import { observer } from 'mobx-react';
import HTMLReactParser from 'html-react-parser';
import { ApproveButton } from './ApproveButton/ApproveButton';
import { ApproversModal } from '@app/Pages/ProjectView/ProjectViewTabs/GateDeliverables/DeliversTable/ApproversModal';
import { Button } from '@app/Components/Button/Button';
import { signalRService } from '@app/Services/SignalRService';
import { DocumentButton } from '@app/Components/DocumentButton/DocumentButton';
import { ChangeLogDialog } from '@app/Components/Dialogs/ChangeLogDialog';
import { CodeCell } from '@app/Components/Grid/CodeCell/CodeCell';
import { FunctionList } from '@app/Components/Grid/FunctionList/FunctionList';
import { ImportantCommentWrapepr } from '@app/Components/ImportantCommentWrapper/ImportantCommentWrapper';
import { AddActionItemModal } from '@app/Widgets/AddActionItemModal/AddActionItemModal';
import { DocumentStatusIcon } from '@app/Components/DocumentStatusIcon/DocumentStatusIcon';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { ApproveDenyDeliverableButtons } from '@app/Pages/ProjectView/ProjectViewTabs/GateDeliverables/DeliversTable/ApproveDenyDeliverableButtons';
import { CommentModal } from '@app/Widgets/CommentModal/CommentModal';
import { ExternalProviderButton } from '@app/Pages/ProjectView/ProjectViewTabs/GatePreparations/ApprovedGateView/ApprovedChecklistView/ExternalProviderButton/ExternalProviderButton';
import { ExternalAppButton } from '@app/Components/ExternalAppButton/ExternalAppButton';
import { ExternalDeliverableInfo } from '../ExternalDeliverableInfo/ExternalDeliverableInfo';
import { renderApprovalApplication } from '../../../../ProjectSetup/ExternalApprovals/ExternalApprovals';
import { Comment } from '@app/Components/Grid/CommentsSummary/Comment';
export var ApprovedChecklistDeliversTable = /** @class */ (function (_super) {
    __extends(ApprovedChecklistDeliversTable, _super);
    function ApprovedChecklistDeliversTable() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isApproversModalOpen = false;
        _this._isCommentModalOpen = false;
        _this._currentDeliverable = null;
        _this._currentChecklistitem = null;
        _this._delivers = [];
        _this._denyItem = null;
        _this._denyApprovalId = undefined;
        _this._isFunctionOkButtonVisiable = false;
        _this._loading = false;
        _this._gridRef = React.createRef();
        _this._delivarableApproveButtonRef = React.createRef();
        _this._documentStatusIconRef = React.createRef();
        _this._isAddActionItemOpen = false;
        _this._selectedActionItemModalData = null;
        _this._handleChecklistStatusChanged = function (eventName, data) { return __awaiter(_this, void 0, void 0, function () {
            var checklist;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        checklist = this.props.checklist;
                        if (!(data === checklist.id)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._loadDelivers()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    ApprovedChecklistDeliversTable.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        signalRService.subscribe(SignalREvents.recommendationChanged, this._loadDelivers);
                        signalRService.subscribe(SignalREvents.checklistStatusChanged, this._handleChecklistStatusChanged);
                        document.addEventListener(Events.deliverablesConfirm, this._loadDelivers);
                        return [4 /*yield*/, this._loadDelivers()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadConfigs()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistDeliversTable.prototype.componentWillUnmount = function () {
        signalRService.unsubscribe(SignalREvents.recommendationChanged, this._loadDelivers);
        signalRService.unsubscribe(SignalREvents.checklistStatusChanged, this._handleChecklistStatusChanged);
        document.removeEventListener(Events.deliverablesConfirm, this._loadDelivers);
    };
    ApprovedChecklistDeliversTable.prototype.componentDidUpdate = function (prevProps) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(prevProps.isFilesChanged !== this.props.isFilesChanged)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._loadDelivers()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistDeliversTable.prototype._loadDelivers = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, , 2, 3]);
                        this._loading = true;
                        _a = this;
                        return [4 /*yield*/, Api.secured.getDeliverableApprovedChecklistItems({
                                checkListId: this.props.checklist.id
                            })];
                    case 1:
                        _a._delivers = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this._loading = false;
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistDeliversTable.prototype._loadConfigs = function () {
        return __awaiter(this, void 0, void 0, function () {
            var body;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 2, 3]);
                        this._loading = true;
                        return [4 /*yield*/, Api.secured.isFunctionOkButtonVisiable({
                                checklistId: this.props.checklist.id
                            })];
                    case 1:
                        body = (_a.sent()).body;
                        this._isFunctionOkButtonVisiable = body;
                        return [3 /*break*/, 3];
                    case 2:
                        this._loading = false;
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistDeliversTable.prototype.reload = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadDelivers()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistDeliversTable.prototype._saveChecklistFunction = function (rowData) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var item, isValidationChanged, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, , 7]);
                        return [4 /*yield*/, Api.secured.updateFunctionListGatePreparationItem({ body: rowData })];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, Api.secured.getApprovedChecklistItem({ checkListItemId: rowData.id })];
                    case 2:
                        item = _b.sent();
                        isValidationChanged = rowData.canPrincipalSetToInApproveFunctionalDeliverable !==
                            item.canPrincipalSetToInApproveFunctionalDeliverable;
                        if (isValidationChanged && this._gridRef.current) {
                            rowData.canPrincipalSetToInApproveFunctionalDeliverable =
                                item.canPrincipalSetToInApproveFunctionalDeliverable;
                            (_a = this._delivarableApproveButtonRef.current) === null || _a === void 0 ? void 0 : _a.changeDisabled(!item.canPrincipalSetToInApproveFunctionalDeliverable || this.props.isProjectTerminatedOrCompleted);
                        }
                        return [4 /*yield*/, this.props.onRefreshCheckListInfo()];
                    case 3:
                        _b.sent();
                        return [3 /*break*/, 7];
                    case 4:
                        error_1 = _b.sent();
                        if (!error_1) return [3 /*break*/, 6];
                        console.error(error_1);
                        return [4 /*yield*/, this._loadDelivers()];
                    case 5:
                        _b.sent();
                        _b.label = 6;
                    case 6: return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistDeliversTable.prototype._saveChecklistFunctionRating = function (rowData, rowIndex) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var item_1, error_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, , 7]);
                        return [4 /*yield*/, Api.secured.updateFunctionListGatePreparationItem({ body: rowData })];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, Api.secured.getApprovedChecklistItem({ checkListItemId: rowData.id })];
                    case 2:
                        item_1 = _b.sent();
                        (_a = Object.keys(rowData)) === null || _a === void 0 ? void 0 : _a.forEach(function (key) {
                            rowData[key] = item_1[key];
                        });
                        if (this._gridRef.current) {
                            this._gridRef.current.repaintRows([rowIndex]);
                        }
                        return [4 /*yield*/, Promise.all([this.props.onRefreshChecklistStatusOverview(), this.props.onRefreshCheckListInfo()])];
                    case 3:
                        _b.sent();
                        return [3 /*break*/, 7];
                    case 4:
                        error_2 = _b.sent();
                        if (!error_2) return [3 /*break*/, 6];
                        console.error(error_2);
                        return [4 /*yield*/, this._loadDelivers()];
                    case 5:
                        _b.sent();
                        _b.label = 6;
                    case 6: return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistDeliversTable.prototype._saveChecklistAssessor = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            var error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 6]);
                        return [4 /*yield*/, Api.secured.updateAssessorGatePreparationItem({ body: rowData })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.props.onRefreshCheckListInfo()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 3:
                        error_3 = _a.sent();
                        if (!error_3) return [3 /*break*/, 5];
                        console.error(error_3);
                        return [4 /*yield*/, this._loadDelivers()];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistDeliversTable.prototype._saveChecklistAssessorRating = function (rowData, rowIndex) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var row_1, error_4;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, , 7]);
                        return [4 /*yield*/, Api.secured.updateAssessorGatePreparationItem({ body: rowData })];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, Api.secured.getApprovedChecklistItem({ checkListItemId: rowData.id })];
                    case 2:
                        row_1 = _b.sent();
                        (_a = Object.keys(rowData)) === null || _a === void 0 ? void 0 : _a.forEach(function (key) {
                            rowData[key] = row_1[key];
                        });
                        if (this._gridRef.current) {
                            this._gridRef.current.repaintRows([rowIndex]);
                        }
                        return [4 /*yield*/, Promise.all([this.props.onRefreshChecklistStatusOverview(), this.props.onRefreshCheckListInfo()])];
                    case 3:
                        _b.sent();
                        return [3 /*break*/, 7];
                    case 4:
                        error_4 = _b.sent();
                        if (!error_4) return [3 /*break*/, 6];
                        console.error(error_4);
                        return [4 /*yield*/, this._loadDelivers()];
                    case 5:
                        _b.sent();
                        _b.label = 6;
                    case 6: return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistDeliversTable.prototype._saveChecklistPMPart = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            var error_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 6]);
                        return [4 /*yield*/, Api.secured.updatePrjManagerPartGatePreparationItem({ body: rowData })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.props.onRefreshCheckListInfo()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 3:
                        error_5 = _a.sent();
                        if (!error_5) return [3 /*break*/, 5];
                        console.error(error_5);
                        return [4 /*yield*/, this._loadDelivers()];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistDeliversTable.prototype._saveChecklistPMRating = function (rowData, rowIndex) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var row_2, error_6;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, , 7]);
                        return [4 /*yield*/, Api.secured.updatePrjManagerPartGatePreparationItem({ body: rowData })];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, Api.secured.getApprovedChecklistItem({ checkListItemId: rowData.id })];
                    case 2:
                        row_2 = _b.sent();
                        (_a = Object.keys(rowData)) === null || _a === void 0 ? void 0 : _a.forEach(function (key) {
                            rowData[key] = row_2[key];
                        });
                        if (this._gridRef.current) {
                            this._gridRef.current.repaintRows([rowIndex]);
                        }
                        return [4 /*yield*/, Promise.all([this.props.onRefreshChecklistStatusOverview(), this.props.onRefreshCheckListInfo()])];
                    case 3:
                        _b.sent();
                        return [3 /*break*/, 7];
                    case 4:
                        error_6 = _b.sent();
                        if (!error_6) return [3 /*break*/, 6];
                        console.error(error_6);
                        return [4 /*yield*/, this._loadDelivers()];
                    case 5:
                        _b.sent();
                        _b.label = 6;
                    case 6: return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistDeliversTable.prototype._saveNeedAttentionPMPart = function (checkListItemId, value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.updatePrjManagerNeedAttention({ checkListItemId: checkListItemId, needAttention: value })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.props.onRefreshCheckListInfo()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this._loadDelivers()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistDeliversTable.prototype._saveNeedAttentionAssessorPart = function (checkListItemId, value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.updateAssessorNeedAttention({ checkListItemId: checkListItemId, needAttention: value })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.props.onRefreshCheckListInfo()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this._loadDelivers()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistDeliversTable.prototype._saveOkAssessorPart = function (checkListItemId, rowData, rowIndex) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var row;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Api.secured.okAssessorPartGatePreparationItem({ checkListItemId: checkListItemId })];
                    case 1:
                        row = _b.sent();
                        (_a = Object.keys(rowData)) === null || _a === void 0 ? void 0 : _a.forEach(function (key) {
                            rowData[key] = row[key];
                        });
                        if (this._gridRef.current) {
                            this._gridRef.current.repaintRows([rowIndex]);
                        }
                        return [4 /*yield*/, Promise.all([this.props.onRefreshChecklistStatusOverview(), this.props.onRefreshCheckListInfo()])];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistDeliversTable.prototype._saveOkPMPart = function (checkListItemId, rowData, rowIndex) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var row;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Api.secured.okPrjManagerPartGatePreparationItem({ checkListItemId: checkListItemId })];
                    case 1:
                        row = _b.sent();
                        (_a = Object.keys(rowData)) === null || _a === void 0 ? void 0 : _a.forEach(function (key) {
                            rowData[key] = row[key];
                        });
                        if (this._gridRef.current) {
                            this._gridRef.current.repaintRows([rowIndex]);
                        }
                        return [4 /*yield*/, Promise.all([this.props.onRefreshChecklistStatusOverview(), this.props.onRefreshCheckListInfo()])];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistDeliversTable.prototype._saveOkFunctionPart = function (checkListItemId, rowData, rowIndex) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var row;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Api.secured.okFunctionPartGatePreparationItem({ checkListItemId: checkListItemId })];
                    case 1:
                        row = _b.sent();
                        (_a = Object.keys(rowData)) === null || _a === void 0 ? void 0 : _a.forEach(function (key) {
                            rowData[key] = row[key];
                        });
                        if (this._gridRef.current) {
                            this._gridRef.current.repaintRows([rowIndex]);
                        }
                        return [4 /*yield*/, Promise.all([this.props.onRefreshChecklistStatusOverview(), this.props.onRefreshCheckListInfo()])];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistDeliversTable.prototype._handleAssessorRatingCellChange = function (rowData, rating) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (rowData.assessorRating !== rating) {
                    rowData.assessorRating = rating;
                }
                return [2 /*return*/];
            });
        });
    };
    ApprovedChecklistDeliversTable.prototype._handlePrjManagerRatingCellChange = function (rowData, rating) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (rowData.projectManagerRating !== rating) {
                    rowData.projectManagerRating = rating;
                }
                return [2 /*return*/];
            });
        });
    };
    ApprovedChecklistDeliversTable.prototype._handleFunctionRatingCellChange = function (rowData, rating) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (rowData.rating !== rating) {
                    rowData.rating = rating;
                }
                return [2 /*return*/];
            });
        });
    };
    ApprovedChecklistDeliversTable.prototype._handlePrepare = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.props.onRefreshCheckListInfo()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadDelivers()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistDeliversTable.prototype._showApprovers = function (checkListItem) {
        if (checkListItem.deliverable) {
            this._currentDeliverable = checkListItem.deliverable;
            this._toggleApproversModal();
        }
    };
    ApprovedChecklistDeliversTable.prototype._toggleApproversModal = function () {
        this._isApproversModalOpen = !this._isApproversModalOpen;
    };
    ApprovedChecklistDeliversTable.prototype._toggleLogModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._currentChecklistitem = null;
                return [2 /*return*/];
            });
        });
    };
    ApprovedChecklistDeliversTable.prototype._toggleCommentModalOpen = function () {
        this._isCommentModalOpen = !this._isCommentModalOpen;
        if (!this._isCommentModalOpen)
            this._denyItem = null;
    };
    ApprovedChecklistDeliversTable.prototype._handleDeny = function (comment) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 3, 5]);
                        this._loading = true;
                        if (!(this._denyItem && this._denyApprovalId)) return [3 /*break*/, 2];
                        return [4 /*yield*/, Api.secured.denyDeliverable(this._denyItem.id, {
                                approvalId: this._denyApprovalId,
                                comment: comment
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this._denyItem = null;
                        return [3 /*break*/, 5];
                    case 3:
                        this._loading = false;
                        return [4 /*yield*/, this._loadDelivers()];
                    case 4:
                        _a.sent();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistDeliversTable.prototype._onApprovalChangedHandler = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._isApproversModalOpen = !this._isApproversModalOpen;
                        return [4 /*yield*/, this.props.onRefreshCheckListInfo()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadDelivers()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistDeliversTable.prototype._toggleAddActionItemModal = function (isAssessor, rowData) {
        if (rowData) {
            var assessorComment = rowData.assessorComment, projectManagerComment = rowData.projectManagerComment;
            var actionText = isAssessor ? assessorComment : projectManagerComment;
            this._selectedActionItemModalData = this._isAddActionItemOpen
                ? null
                : { row: rowData, isAssessor: isAssessor, actionText: actionText };
        }
        this._isAddActionItemOpen = !this._isAddActionItemOpen;
    };
    ApprovedChecklistDeliversTable.prototype._handleNeedAttentionToggle = function (id, needAttention, isAssessor) {
        return __awaiter(this, void 0, void 0, function () {
            var row, e_1, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        row = this._delivers.find(function (r) { return r.id === id; });
                        if (!isAssessor) return [3 /*break*/, 5];
                        if (row)
                            row.needAttention = needAttention;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this._saveNeedAttentionAssessorPart(id, needAttention)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        if (row)
                            row.needAttention = !needAttention;
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 9];
                    case 5:
                        if (row)
                            row.projectManagerNeedAttention = needAttention;
                        _a.label = 6;
                    case 6:
                        _a.trys.push([6, 8, , 9]);
                        return [4 /*yield*/, this._saveNeedAttentionPMPart(id, needAttention)];
                    case 7:
                        _a.sent();
                        return [3 /*break*/, 9];
                    case 8:
                        e_2 = _a.sent();
                        if (row)
                            row.projectManagerNeedAttention = !needAttention;
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistDeliversTable.prototype.openExternalAppLink = function (data) {
        window.open(data.externalApprovalAppLink, '_blank');
    };
    ApprovedChecklistDeliversTable.prototype.openExternalTestPlanAppLink = function (data) {
        window.open(data.externalTestPlanAppLink, '_blank');
    };
    ApprovedChecklistDeliversTable.prototype.renderApproveDenyButtons = function (data) {
        var _this = this;
        var isProjectTerminatedOrCompleted = this.props.isProjectTerminatedOrCompleted;
        return (data.canPrincipalImmediateApproveDeliverable &&
            !data.isApprovalDisabled && (React.createElement(ApproveDenyDeliverableButtons, { deliverable: data, deliverableApproverId: data.canPrincipalImmediateApproveDeliverable ? null : data.approvalId || null, canApproveWithoutApprover: data.canPrincipalImmediateApproveDeliverable, isApproveDisabled: this._isApproversModalOpen || this._loading, onDenyHandler: function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (data) {
                                this._toggleCommentModalOpen();
                                this._denyItem = data;
                                this._denyApprovalId = data.approvalId;
                            }
                            return [4 /*yield*/, this.props.onRefreshCheckListInfo()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); }, onApprovedHandler: function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.props.onRefreshCheckListInfo()];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, this._loadDelivers()];
                        case 2:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); }, parentClassName: styles.approveDenyBlock, childClassName: styles.approveDenyButton, disabled: isProjectTerminatedOrCompleted })));
    };
    ApprovedChecklistDeliversTable.prototype.renderApproveDenyExternalRedirectButtons = function (data) {
        var _this = this;
        var isProjectTerminatedOrCompleted = this.props.isProjectTerminatedOrCompleted;
        return (data.canPrincipalImmediateApproveDeliverable &&
            !data.isApprovalDisabled && (React.createElement("div", { className: "".concat(styles.actions) },
            React.createElement(Button, { className: "".concat(styles.action), size: "sm", color: "success", onClick: function () { return _this.openExternalAppLink(data); }, disabled: this._isApproversModalOpen || this._loading || isProjectTerminatedOrCompleted }, "Approve..."),
            React.createElement(Button, { className: "".concat(styles.action), size: "sm", onClick: function () { return _this.openExternalAppLink(data); }, isLoading: this._loading || isProjectTerminatedOrCompleted }, "Deny..."))));
    };
    ApprovedChecklistDeliversTable.prototype.render = function () {
        var _a;
        var _this = this;
        var onDocumentIconClick = (_a = this.props, _a.onDocumentIconClick), projectId = _a.projectId, projectCodeName = _a.projectCodeName, gateId = _a.gateId, gateCodeName = _a.gateCodeName, checklist = _a.checklist, showRedDotDeliverable = _a.showRedDotDeliverable, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted;
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid, { ref: this._gridRef, dataSource: __spreadArray([], this._delivers, true), rowsCount: this._delivers.length, expandableRows: true, loading: this._loading },
                React.createElement(Column, { caption: "ID", dataField: "code", width: 150, allowEditing: false, cssClass: columnSharedStyles.colWithHistory, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(CodeCell, { code: data.code, onHistoryClick: function () { return (_this._currentChecklistitem = data); } },
                            data.canPrincipalDelegateTask &&
                                (!data.deliverable ||
                                    data.deliverable.approvalApplication === ApprovalApplicationEnum.TeamGate) && (React.createElement(ExternalProviderButton, { disable: isProjectTerminatedOrCompleted, projectName: projectCodeName, projectId: projectId, gateId: gateId, gateCodeName: gateCodeName, checkListItemId: data.id, taskId: data.taskId || null, taskStatus: data.taskStatus, onRefresh: _this._loadDelivers })),
                            data.deliverable &&
                                data.deliverable.approvalApplication !== ApprovalApplicationEnum.TeamGate && (React.createElement(ExternalDeliverableInfo, { deliverable: data.deliverable }))));
                    } }),
                React.createElement(Column, { caption: "Functions", dataField: "functionNames", width: 90, allowEditing: false, cellRender: function (_a) {
                        var data = _a.data;
                        if (!data.functionNames)
                            return null;
                        return React.createElement(FunctionList, { functions: data.functionNames, rowId: '' + data.id });
                    } }),
                React.createElement(Column, { caption: "Deliverable Text", dataField: "deliverableText", width: 300, allowEditing: false, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("div", { style: { whiteSpace: 'pre-line' } }, HTMLReactParser(data.deliverableText || '')));
                    } }),
                React.createElement(Column, { width: 40, alignment: "center", cellRender: function (_a) {
                        var _b, _c;
                        var data = _a.data;
                        return ((_b = data.deliverable) === null || _b === void 0 ? void 0 : _b.stateAtGateAssessment) &&
                            ((_c = data.deliverable) === null || _c === void 0 ? void 0 : _c.currentStatus) && (React.createElement(DocumentStatusIcon, { ref: _this._documentStatusIconRef, targetStatus: data.deliverable.stateAtGateAssessment, isFileUploaded: data.deliverable.hasFile, isCommented: data.deliverable.hasComment, isRated: data.deliverable.hasRating, currentStatus: data.deliverable.currentStatus, deliverableId: data.id, isExternalManagement: data.deliverable.approvalApplication !== ApprovalApplicationEnum.TeamGate, isNotApplicable: data.deliverable.isApprovalDisabled ||
                                data.deliverable.isNotApplicableAfterApproval, rating: data.deliverable.rating }));
                    } }),
                React.createElement(Column, { caption: "Guideline", dataField: "guideline", minWidth: 250, allowEditing: false, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("div", { style: { whiteSpace: 'pre-line' } }, HTMLReactParser(data.guideline || '')));
                    } }),
                React.createElement(Column, { cssClass: columnSharedStyles.commentColumn, caption: "Function Comment", width: 250, dataField: "comment", cellRender: function (_a) {
                        var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                        return (React.createElement(React.Fragment, null,
                            data.deliverable &&
                                data.deliverable.approvalApplication !== ApprovalApplicationEnum.TeamGate && (React.createElement("div", { className: styles.isExternalManagementText },
                                "(Comment and Rating",
                                ' ',
                                data.deliverable.approvalApplication === ApprovalApplicationEnum.MFiles
                                    ? 'are managed by '
                                    : 'editable in ',
                                renderApprovalApplication(data.deliverable.approvalApplication),
                                ")")),
                            React.createElement(EditCell, { value: data.comment, onChange: function (value) {
                                    data.comment = value;
                                    if (_this._gridRef.current) {
                                        _this._gridRef.current.repaintRows([rowIndex]);
                                    }
                                }, rowIndex: rowIndex, columnIndex: columnIndex, onSave: function () { return _this._saveChecklistFunction(data); }, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), section: ChecklistSections.Function, disabled: !data.canPrincipalUpdateFunctionalPart ||
                                    (data.deliverable &&
                                        data.deliverable.approvalApplication !==
                                            ApprovalApplicationEnum.TeamGate) ||
                                    isProjectTerminatedOrCompleted })));
                    } }),
                React.createElement(Column, { headerCellRender: function () { return React.createElement("div", { title: "Function Rating" }, "Rating"); }, width: 100, dataField: "rating", alignment: "center", cssClass: styles.ratingColumn, cellRender: function (_a) {
                        var _b;
                        var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                        return (React.createElement("section", null,
                            React.createElement("div", { className: styles.ratingCellWrapper },
                                React.createElement("div", { className: styles.ratingWrapper },
                                    React.createElement(AssessorRatingCell, { onSave: function () { return _this._saveChecklistFunctionRating(data, rowIndex); }, onSaveOk: function () { return __awaiter(_this, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0: return [4 /*yield*/, this._saveOkFunctionPart(data.id, data, rowIndex)];
                                                    case 1:
                                                        _a.sent();
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); }, onChange: function (changedData) {
                                            return _this._handleFunctionRatingCellChange(data, changedData);
                                        }, rowIndex: rowIndex, columnIndex: columnIndex, showValidationErrors: false, rating: data.rating, text: data.comment, textTitle: "Comment", objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), section: ChecklistSections.Function, isOkButtonVisible: _this._isFunctionOkButtonVisiable, disabled: !data.canPrincipalUpdateFunctionalPart ||
                                            (data.deliverable &&
                                                data.deliverable.approvalApplication !==
                                                    ApprovalApplicationEnum.TeamGate) ||
                                            isProjectTerminatedOrCompleted }))),
                            React.createElement("div", { className: styles.statusButton },
                                React.createElement(React.Fragment, null, data.deliverable &&
                                    data.deliverable.canEdit &&
                                    (data.deliverable.currentStatus === StatusEnum.Draft &&
                                        !(data.deliverable.stateAtGateAssessment === StatusEnum.Draft &&
                                            data.deliverable.hasComment &&
                                            data.deliverable.hasRating) &&
                                        !data.deliverable.isApprovalDisabled &&
                                        data.deliverable.approvalApplication !==
                                            ApprovalApplicationEnum.TeamGate ? (React.createElement(Button, { size: "sm", color: "success", onClick: function () {
                                            data.deliverable && _this.openExternalAppLink(data.deliverable);
                                        }, disabled: _this._loading ||
                                            !data.deliverable.isExternallyConfigured ||
                                            isProjectTerminatedOrCompleted },
                                        data.deliverable.approvalApplication ===
                                            ApprovalApplicationEnum.MFiles
                                            ? 'Processing in '
                                            : 'Approval in ',
                                        renderApprovalApplication(data.deliverable.approvalApplication),
                                        "...")) : (React.createElement(ApproveButton, { ref: _this._delivarableApproveButtonRef, deliverable: data.deliverable, rating: data.rating, comment: data.comment, hasDocument: data.deliverable.hasFile, showValidationError: true, isDisabled: !(data === null || data === void 0 ? void 0 : data.canPrincipalSetToInApproveFunctionalDeliverable) ||
                                            isProjectTerminatedOrCompleted, onPrepare: _this._handlePrepare, hasRedDot: ((_b = data.deliverable) === null || _b === void 0 ? void 0 : _b.hasRedDot) && showRedDotDeliverable, isApprovalDisabled: data.deliverable.currentStatus !== StatusEnum.Draft ||
                                            (data.deliverable.stateAtGateAssessment === StatusEnum.Draft &&
                                                data.deliverable.hasComment &&
                                                data.deliverable.hasRating) ||
                                            data.deliverable.isApprovalDisabled })))),
                                React.createElement(React.Fragment, null, !_this._isApproversModalOpen &&
                                    !_this._loading &&
                                    data.deliverable &&
                                    (data.deliverable.approvalApplication !== ApprovalApplicationEnum.TeamGate
                                        ? _this.renderApproveDenyExternalRedirectButtons(data.deliverable)
                                        : _this.renderApproveDenyButtons(data.deliverable)))),
                            React.createElement("div", { className: styles.commentActions },
                                React.createElement("div", { className: "".concat(styles.commentAction, " ").concat(styles.documentIconWrapper) },
                                    data.deliverable &&
                                        data.deliverable.approvalApplication === ApprovalApplicationEnum.TeamGate &&
                                        onDocumentIconClick && (React.createElement(DocumentButton, { hasDocument: !!data.deliverable.fileInformation, onDocumentIconClick: function () {
                                            return onDocumentIconClick === null || onDocumentIconClick === void 0 ? void 0 : onDocumentIconClick(data.id, ChecklistSections.Function);
                                        }, className: styles.documentIconButton, hasRedDot: data.deliverable.hasRedDot && showRedDotDeliverable })),
                                    data.deliverable &&
                                        data.deliverable.approvalApplication !==
                                            ApprovalApplicationEnum.TeamGate && (React.createElement(ExternalAppButton, { isWhite: data.deliverable.currentStatus === StatusEnum.InApproval ||
                                            data.deliverable.currentStatus === StatusEnum.Approved ||
                                            (data.deliverable.stateAtGateAssessment === StatusEnum.Draft &&
                                                data.deliverable.hasComment &&
                                                data.deliverable.hasRating), disabled: !data.deliverable.isExternallyConfigured, className: styles.approversButton, onButtonClick: function () {
                                            if (data.deliverable) {
                                                if (data.deliverable.approvalApplication !==
                                                    ApprovalApplicationEnum.TMS) {
                                                    _this.openExternalAppLink(data.deliverable);
                                                }
                                                else {
                                                    _this.openExternalTestPlanAppLink(data.deliverable);
                                                }
                                            }
                                        } })),
                                    data.deliverable && (React.createElement(Button, { color: "success", size: "xs", className: styles.approversButton, onClick: function () { return _this._showApprovers(data); } }, "A"))))));
                    } }),
                React.createElement(Column, { caption: "Project Manager Comment", width: 250, allowEditing: true, cssClass: columnSharedStyles.commentColumn, cellRender: function (_a) {
                        var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                        return (React.createElement(ImportantCommentWrapepr, { isImportant: data.projectManagerNeedAttention },
                            React.createElement(EditCell, { value: data.projectManagerComment, rowIndex: rowIndex, columnIndex: columnIndex, onChange: function (value) {
                                    data.projectManagerComment = value;
                                    if (_this._gridRef.current) {
                                        _this._gridRef.current.repaintRows([rowIndex]);
                                    }
                                }, onSave: function () { return _this._saveChecklistPMPart(data); }, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), section: ChecklistSections.ProjectManager, disabled: !data.canPrincipalUpdatePrjManagerPart || isProjectTerminatedOrCompleted })));
                    } }),
                React.createElement(Column, { headerCellRender: function () { return React.createElement("div", { title: "Project Manager Rating" }, "Rating"); }, width: 70, dataField: "projectManagerRating", allowEditing: false, cssClass: styles.projectManagerRatingCell, cellRender: function (_a) {
                        var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                        return (React.createElement(AssessorRatingCell, { rowIndex: rowIndex, columnIndex: columnIndex, onSave: function () { return _this._saveChecklistPMRating(data, rowIndex); }, onSaveOk: function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this._saveOkPMPart(data.id, data, rowIndex)];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); }, onChange: function (changedData) { return _this._handlePrjManagerRatingCellChange(data, changedData); }, showValidationErrors: checklist.status === StatusEnum.Draft, rating: data.projectManagerRating, text: data.projectManagerComment, textTitle: "Comment", objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), section: ChecklistSections.ProjectManager, isOkButtonVisible: true, disabled: !data.canPrincipalUpdatePrjManagerPart ||
                                _this._loading ||
                                isProjectTerminatedOrCompleted }));
                    } }),
                React.createElement(Column, { headerCellRender: function () { return (React.createElement("div", { title: "Need Attention" },
                        React.createElement(BsFillExclamationTriangleFill, { color: "orange" }))); }, width: 30, dataField: "projectManagerNeedAttention", alignment: "center", cssClass: styles.projectManagerRatingCell, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("input", { type: "checkbox", title: "Need Attention", checked: data.projectManagerNeedAttention, disabled: !data.canPrincipalUpdatePrjManagerPart || isProjectTerminatedOrCompleted, onChange: function () {
                                return _this._handleNeedAttentionToggle(data.id, !data.projectManagerNeedAttention, false);
                            } }));
                    } }),
                React.createElement(Column, { width: 110, caption: "PM Actions", cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(React.Fragment, null,
                            data.canAddActionItem && (React.createElement(Button, { disabled: isProjectTerminatedOrCompleted, onClick: function () { return _this._toggleAddActionItemModal(false, data); } }, "Action Item")),
                            !!(data.actionItemsCount && data.actionItemsCount > 0) && (React.createElement("div", null,
                                React.createElement(Link, { to: "/projects/".concat(projectCodeName, "/").concat(data.actionItemsProcessType === GateProcessTypeEnum.GateAssessment ? 'assessments' : 'gate-meeting', "/g-").concat(gateId) },
                                    data.actionItemsCount,
                                    " Action Item (s)"))),
                            !!data.actionItemsDraftCount && (React.createElement(Comment, { id: "actionItemsDraft_".concat(data.id), errorText: "".concat(data.actionItemsDraftCount, " Draft.") })),
                            !!data.actionItemsInApprovalCount && (React.createElement(Comment, { id: "actionItemsInApproval_".concat(data.id), errorText: "".concat(data.actionItemsInApprovalCount, " In Approval.") })),
                            !!data.actionItemsApprovedCount && (React.createElement(Comment, { id: "actionItemsApproved_".concat(data.id), errorText: "".concat(data.actionItemsApprovedCount, " Approved.") })),
                            !!data.actionItemsNotApplicableCount && (React.createElement(Comment, { id: "actionItemsNotApplicable_".concat(data.id), errorText: "".concat(data.actionItemsNotApplicableCount, " Not Applicable.") }))));
                    } }),
                React.createElement(Column, { caption: "Gate Assessor Comment", width: 300, dataField: "assessorComment", allowEditing: true, cssClass: columnSharedStyles.commentColumn, cellRender: function (_a) {
                        var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                        return (React.createElement(ImportantCommentWrapepr, { isImportant: data.needAttention },
                            React.createElement(EditCell, { rowIndex: rowIndex, columnIndex: columnIndex, value: data.assessorComment, onChange: function (value) {
                                    data.assessorComment = value;
                                    if (_this._gridRef.current) {
                                        _this._gridRef.current.repaintRows([rowIndex]);
                                    }
                                }, onSave: function () { return _this._saveChecklistAssessor(data); }, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), section: ChecklistSections.Assessor, disabled: !data.canPrincipalUpdateAssessorPart || isProjectTerminatedOrCompleted })));
                    } }),
                React.createElement(Column, { headerCellRender: function () { return React.createElement("div", { title: "Gate Assessor Rating" }, "Rating"); }, width: 70, dataField: "assessorRating", alignment: "center", allowEditing: false, cssClass: styles.projectManagerRatingCell, cellRender: function (_a) {
                        var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                        return (React.createElement(AssessorRatingCell, { rowIndex: rowIndex, columnIndex: columnIndex, onSave: function () { return _this._saveChecklistAssessorRating(data, rowIndex); }, onChange: function (changedData) { return _this._handleAssessorRatingCellChange(data, changedData); }, onSaveOk: function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this._saveOkAssessorPart(data.id, data, rowIndex)];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); }, showValidationErrors: checklist.status === StatusEnum.Draft, rating: data.assessorRating, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), section: ChecklistSections.Assessor, isOkButtonVisible: true, disabled: !data.canPrincipalUpdateAssessorPart ||
                                _this._loading ||
                                isProjectTerminatedOrCompleted, text: data.assessorComment, textTitle: "Comment" }));
                    } }),
                React.createElement(Column, { headerCellRender: function () { return (React.createElement("div", { title: "Need Attention" },
                        React.createElement(BsFillExclamationTriangleFill, { color: "orange" }))); }, width: 30, dataField: "needAttention", alignment: "center", cssClass: styles.projectManagerRatingCell, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("input", { type: "checkbox", title: "Need Attention", checked: data.needAttention, disabled: !data.canPrincipalUpdateAssessorPart || isProjectTerminatedOrCompleted, onChange: function () { return _this._handleNeedAttentionToggle(data.id, !data.needAttention, true); } }));
                    } }),
                React.createElement(Column, { width: 110, caption: "GA Actions", cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(React.Fragment, null,
                            data.canAddActionItem && (React.createElement(Button, { disabled: isProjectTerminatedOrCompleted, onClick: function () { return _this._toggleAddActionItemModal(true, data); } }, "Action Item")),
                            !!(data.actionItemsCount && data.actionItemsCount > 0) && (React.createElement("div", null,
                                React.createElement(Link, { to: "/projects/".concat(projectCodeName, "/").concat(data.actionItemsProcessType === GateProcessTypeEnum.GateAssessment ? 'assessments' : 'gate-meeting', "/g-").concat(gateId) },
                                    data.actionItemsCount,
                                    " Action Item (s)")))));
                    } })),
            this._isAddActionItemOpen && this._selectedActionItemModalData && (React.createElement(AddActionItemModal, { projectId: projectId, gateId: gateId, selectedChecklistId: checklist.id, selectedChecklistItemId: this._selectedActionItemModalData.row.id, gateProcessType: GateProcessTypeEnum.GateAssessment, isOpen: this._isAddActionItemOpen, onToggle: function () { var _a; return _this._toggleAddActionItemModal(!!((_a = _this._selectedActionItemModalData) === null || _a === void 0 ? void 0 : _a.isAssessor)); }, onSave: this._loadDelivers, actionText: this._selectedActionItemModalData.actionText, selectedFunction: this._selectedActionItemModalData.row.functionNames &&
                    this._selectedActionItemModalData.row.functionNames.length === 1
                    ? this._selectedActionItemModalData.row.functionNames[0]
                    : undefined })),
            React.createElement(ApproversModal, { isOpen: this._isApproversModalOpen, toggle: this._toggleApproversModal, onApprovalChanged: this._onApprovalChangedHandler, deliverable: this._currentDeliverable, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted }),
            React.createElement(CommentModal, { isOpen: this._isCommentModalOpen, onToggle: this._toggleCommentModalOpen, onSave: this._handleDeny }),
            this._currentChecklistitem && (React.createElement(ChangeLogDialog, { onToggle: this._toggleLogModal, uniqueId: createUniqueId(ObjectType.ChecklistItem, this._currentChecklistitem.id) }))));
    };
    __decorate([
        observable
    ], ApprovedChecklistDeliversTable.prototype, "_isApproversModalOpen", void 0);
    __decorate([
        observable
    ], ApprovedChecklistDeliversTable.prototype, "_isCommentModalOpen", void 0);
    __decorate([
        observable
    ], ApprovedChecklistDeliversTable.prototype, "_currentDeliverable", void 0);
    __decorate([
        observable
    ], ApprovedChecklistDeliversTable.prototype, "_currentChecklistitem", void 0);
    __decorate([
        observable.shallow
    ], ApprovedChecklistDeliversTable.prototype, "_delivers", void 0);
    __decorate([
        observable
    ], ApprovedChecklistDeliversTable.prototype, "_denyItem", void 0);
    __decorate([
        observable
    ], ApprovedChecklistDeliversTable.prototype, "_denyApprovalId", void 0);
    __decorate([
        observable
    ], ApprovedChecklistDeliversTable.prototype, "_isFunctionOkButtonVisiable", void 0);
    __decorate([
        observable
    ], ApprovedChecklistDeliversTable.prototype, "_loading", void 0);
    __decorate([
        observable.ref
    ], ApprovedChecklistDeliversTable.prototype, "_gridRef", void 0);
    __decorate([
        observable.ref
    ], ApprovedChecklistDeliversTable.prototype, "_delivarableApproveButtonRef", void 0);
    __decorate([
        observable.ref
    ], ApprovedChecklistDeliversTable.prototype, "_documentStatusIconRef", void 0);
    __decorate([
        observable
    ], ApprovedChecklistDeliversTable.prototype, "_isAddActionItemOpen", void 0);
    __decorate([
        observable
    ], ApprovedChecklistDeliversTable.prototype, "_selectedActionItemModalData", void 0);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_loadDelivers", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_loadConfigs", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "reload", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_saveChecklistFunction", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_saveChecklistFunctionRating", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_saveChecklistAssessor", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_saveChecklistAssessorRating", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_saveChecklistPMPart", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_saveChecklistPMRating", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_saveNeedAttentionPMPart", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_saveNeedAttentionAssessorPart", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_saveOkAssessorPart", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_saveOkPMPart", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_saveOkFunctionPart", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_handleAssessorRatingCellChange", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_handlePrjManagerRatingCellChange", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_handleFunctionRatingCellChange", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_handlePrepare", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_showApprovers", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_toggleApproversModal", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_toggleLogModal", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_toggleCommentModalOpen", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_handleDeny", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_onApprovalChangedHandler", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_toggleAddActionItemModal", null);
    __decorate([
        action.bound
    ], ApprovedChecklistDeliversTable.prototype, "_handleNeedAttentionToggle", null);
    ApprovedChecklistDeliversTable = __decorate([
        observer
    ], ApprovedChecklistDeliversTable);
    return ApprovedChecklistDeliversTable;
}(React.Component));
