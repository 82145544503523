var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { TokenCredentials, HttpHeaders } from '@azure/ms-rest-js';
import { TeamGateApiUnsecured } from './Api/Unsecure/teamGateApiUnsecured';
import { TeamGateApiSecured } from './Api/Secure/teamGateApiSecured';
import notify from 'devextreme/ui/notify';
import { appStore } from './AppStore';
import Axios from 'axios';
import { browserHistory } from './History';
import { AzureAuth } from './Components/AzureAuth';
import { authStore } from './Stores/AuthStore';
var MAX_COUNT_OF_CONFLICT_REQUESTS = 2;
var axios = Axios.create();
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.request.responseType === 'blob' &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf('json') != -1) {
        return new Promise(function (resolve, reject) {
            var reader = new FileReader();
            reader.onload = function () {
                error.response.data = JSON.parse(reader.result);
                resolve(Promise.reject(error));
            };
            reader.onerror = function () {
                reject(error);
            };
            reader.readAsText(error.response.data);
        });
    }
    return Promise.reject(error);
});
var TGResponse = /** @class */ (function () {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function TGResponse(req, headers, status, parsedBody) {
        this.request = req;
        this.headers = headers;
        this.status = status;
        this.parsedBody = parsedBody;
        this.blobBody = Promise.resolve(new Blob([parsedBody]));
    }
    return TGResponse;
}());
var TGHttpClient = /** @class */ (function () {
    function TGHttpClient(withErrorHandler) {
        this._withErrorHandler = false;
        this.conflictMapCounter = new Map();
        this._withErrorHandler = withErrorHandler;
    }
    TGHttpClient.prototype.sendRequest = function (httpRequest) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var token, rawHeaders, data, dd, key;
            var _this = this;
            return __generator(this, function (_c) {
                token = authStore.currentToken;
                rawHeaders = {
                    authorization: 'Bearer ' + token,
                    'App-SignalR-ConnectionId': appStore.signalRConnectionId || '',
                    'App-Impersonation-PrincipalId': ((_b = (_a = appStore.impersonatedPrincipal) === null || _a === void 0 ? void 0 : _a.principalId) === null || _b === void 0 ? void 0 : _b.toString()) || ''
                };
                data = httpRequest.body ? JSON.parse(httpRequest.body) : {};
                if (httpRequest.formData) {
                    rawHeaders['Content-Type'] = 'multipart/form-data';
                    dd = new FormData();
                    for (key in httpRequest.formData) {
                        dd.append(key, httpRequest.formData[key]);
                    }
                    data = dd;
                }
                ApiService.responseInterceptors.forEach(function (x) { return axios.interceptors.response.use(x.response, x.error); });
                return [2 /*return*/, axios
                        .request({
                        url: httpRequest.url,
                        headers: rawHeaders,
                        method: httpRequest.method == 'TRACE' ? 'GET' : httpRequest.method,
                        responseType: httpRequest.streamResponseBody ? 'blob' : 'json',
                        data: data
                    })
                        .then(function (res) {
                        return new TGResponse(httpRequest, new HttpHeaders(res.headers), res.status, res.data);
                    })
                        .catch(function (err) { return __awaiter(_this, void 0, void 0, function () {
                        var resoponseData, value;
                        var _a, _b, _c, _d, _e, _f;
                        return __generator(this, function (_g) {
                            switch (_g.label) {
                                case 0:
                                    if (!this._withErrorHandler) return [3 /*break*/, 11];
                                    if (!(((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) === 404 && !((_c = (_b = err.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.error))) return [3 /*break*/, 1];
                                    browserHistory.push('/notfound');
                                    return [3 /*break*/, 11];
                                case 1:
                                    if (!(((_d = err.response) === null || _d === void 0 ? void 0 : _d.status) === 401)) return [3 /*break*/, 2];
                                    AzureAuth.login();
                                    return [3 /*break*/, 11];
                                case 2:
                                    if (!(((_e = err.response) === null || _e === void 0 ? void 0 : _e.status) === 403)) return [3 /*break*/, 3];
                                    browserHistory.push('/notallow');
                                    return [3 /*break*/, 11];
                                case 3:
                                    if (!(((_f = err.response) === null || _f === void 0 ? void 0 : _f.status) === 409)) return [3 /*break*/, 10];
                                    resoponseData = err.response.data;
                                    if (!(!resoponseData || !resoponseData.needResponseOnConflict)) return [3 /*break*/, 9];
                                    if (!this.conflictMapCounter.has(httpRequest)) return [3 /*break*/, 7];
                                    value = this.conflictMapCounter.get(httpRequest);
                                    if (!(value == MAX_COUNT_OF_CONFLICT_REQUESTS)) return [3 /*break*/, 4];
                                    this._showError(err);
                                    this.conflictMapCounter.delete(httpRequest);
                                    return [3 /*break*/, 6];
                                case 4:
                                    this.conflictMapCounter.set(httpRequest, value + 1);
                                    //eslint-disable-next-line @typescript-eslint/no-floating-promises
                                    return [4 /*yield*/, this.sendRequest(httpRequest)];
                                case 5:
                                    //eslint-disable-next-line @typescript-eslint/no-floating-promises
                                    _g.sent();
                                    _g.label = 6;
                                case 6: return [3 /*break*/, 9];
                                case 7:
                                    this.conflictMapCounter.set(httpRequest, 1);
                                    //eslint-disable-next-line @typescript-eslint/no-floating-promises
                                    return [4 /*yield*/, this.sendRequest(httpRequest)];
                                case 8:
                                    //eslint-disable-next-line @typescript-eslint/no-floating-promises
                                    _g.sent();
                                    _g.label = 9;
                                case 9: return [3 /*break*/, 11];
                                case 10:
                                    this._showError(err);
                                    _g.label = 11;
                                case 11: throw err;
                            }
                        });
                    }); })];
            });
        });
    };
    TGHttpClient.prototype._showError = function (error) {
        var _a, _b;
        var message = ((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) || error.message;
        notify({
            message: message,
            closeOnClick: false,
            closeOnSwipe: false
        }, 'error', 10000);
    };
    return TGHttpClient;
}());
export var ApiService = /** @class */ (function () {
    function ApiService() {
        this._undecuredInstance = new TeamGateApiUnsecured({ baseUri: window.location.origin });
        this._securedInstance = new TeamGateApiSecured(new TokenCredentials('undefined'), {
            baseUri: window.location.origin,
            httpClient: new TGHttpClient(true)
        });
        this._securedInstanceWithoutErrorHandler = new TeamGateApiSecured(new TokenCredentials('undefined'), {
            baseUri: window.location.origin,
            httpClient: new TGHttpClient(false)
        });
    }
    Object.defineProperty(ApiService.prototype, "secured", {
        get: function () {
            return this._securedInstance;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ApiService.prototype, "securedWithoutErrorHandler", {
        get: function () {
            return this._securedInstanceWithoutErrorHandler;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ApiService.prototype, "Unsecured", {
        get: function () {
            return this._undecuredInstance;
        },
        enumerable: false,
        configurable: true
    });
    ApiService.prototype.setCredentials = function (creds) {
        this._securedInstance = new TeamGateApiSecured(creds, {
            baseUri: window.location.origin,
            httpClient: new TGHttpClient(true)
        });
        this._securedInstanceWithoutErrorHandler = new TeamGateApiSecured(creds, {
            baseUri: window.location.origin,
            httpClient: new TGHttpClient(false)
        });
    };
    ApiService.addResponseInterceptor = function (interceptor) {
        ApiService.responseInterceptors.push(interceptor);
    };
    ApiService.responseInterceptors = [];
    return ApiService;
}());
export var Api = new ApiService();
