var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Label } from 'reactstrap';
import { Input } from '@app/Components/Input/Input';
import { observer } from 'mobx-react';
import styles from './CreateProjectModal.module.scss';
import { observable, action } from 'mobx';
import { withRouter } from 'react-router-dom';
import { Api } from '@app/Api';
import { Modal } from '@app/Components/Modal/Modal';
import { DatePicker } from '@app/Components/DatePicker';
import { Select } from '@app/Components/Select/Select';
import { PromiseCompletion, CompletionType } from '@app/Classes/PromiseCompletion';
import { OrgUnitPicker } from '@app/Components/Pickers/OrgUnitPicker/OrgUnitPicker';
import { PersonPicker } from '@app/Components/Pickers/PersonPicker/PersonPicker';
import { ProjectType as ProjectTypeEnum } from '@app/AppConstants';
var BooleanOptions = [
    { value: '0', label: 'No' },
    { value: '1', label: 'Yes' }
];
var CreateProjectModalC = /** @class */ (function (_super) {
    __extends(CreateProjectModalC, _super);
    function CreateProjectModalC() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._projectCreateModel = { type: ProjectTypeEnum.Gate };
        //eslint-disable-next-line @typescript-eslint/no-inferrable-types
        _this._totalBudgetNumber = '0';
        _this._savingInProgress = new PromiseCompletion(CompletionType.Pending);
        _this._isProjectTypeSelectionAllowed = false;
        _this._handleTotalBudgetChange = function (e) {
            var re = /^[0-9]+(.[0-9]*)?$/;
            if (re.test(e.target.value)) {
                var newValue = e.target.value;
                if (newValue.indexOf('.') !== newValue.length - 1) {
                    newValue = parseFloat(newValue).toString();
                }
                _this._totalBudgetNumber = newValue;
            }
            if (e.target.value === '') {
                _this._totalBudgetNumber = '0';
            }
        };
        return _this;
    }
    CreateProjectModalC.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, securityLevels, categories, types;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            Api.secured.getProjectSecurityLevelEnum(),
                            Api.secured.getProjectCategoryEnum(),
                            Api.secured.getProjectTypeEnum()
                        ])];
                    case 1:
                        _a = _b.sent(), securityLevels = _a[0], categories = _a[1], types = _a[2];
                        this._securityLevels = securityLevels.map(function (x) { return ({ value: '' + x.value, label: '' + x.label }); });
                        this._categories = categories.map(function (x) { return ({ value: '' + x.value, label: '' + x.label }); });
                        this._types = types.map(function (x) { return ({ value: '' + x.value, label: '' + x.label }); });
                        this._projectCreateModel.intellectualPropertyKeyProject = false;
                        this._projectCreateModel.isDigital = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateProjectModalC.prototype._handleSaveClick = function () {
        return __awaiter(this, void 0, void 0, function () {
            var promise, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._projectCreateModel.totalBudget = parseFloat(this._totalBudgetNumber);
                        promise = Api.secured.createProject({ body: this._projectCreateModel });
                        this._savingInProgress.subscribe(promise);
                        return [4 /*yield*/, promise];
                    case 1:
                        res = _a.sent();
                        if (!this.props.onSave) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.props.onSave()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        this._navigateToProject(res.codeName);
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateProjectModalC.prototype._navigateToProject = function (codeName) {
        this.props.history.push('/');
        if (codeName)
            this.props.history.push('/projects/' + codeName);
    };
    CreateProjectModalC.prototype._handleUserSelect = function (value) {
        if (value && value[0]) {
            this._projectCreateModel.leaderPrincipalId = value[0].id;
        }
    };
    CreateProjectModalC.prototype._handleSecurityLevelChange = function (selectedOption) {
        if (!selectedOption || Array.isArray(selectedOption))
            return;
        var value = selectedOption.value;
        this._projectCreateModel.securityLevel = value;
    };
    CreateProjectModalC.prototype._handleIPKeyChange = function (selectedOption) {
        if (!selectedOption || Array.isArray(selectedOption))
            return;
        var value = selectedOption.value;
        this._projectCreateModel.intellectualPropertyKeyProject = value === '1';
    };
    CreateProjectModalC.prototype._handleDigitalChange = function (selectedOption) {
        if (!selectedOption || Array.isArray(selectedOption))
            return;
        var value = selectedOption.value;
        this._projectCreateModel.isDigital = value === '1';
    };
    CreateProjectModalC.prototype._handleCategoryChange = function (selectedOption) {
        if (!selectedOption || Array.isArray(selectedOption))
            return;
        var value = selectedOption.value;
        this._projectCreateModel.category = value;
    };
    CreateProjectModalC.prototype._handleTypeChange = function (selectedOption) {
        if (!selectedOption || Array.isArray(selectedOption))
            return;
        var value = selectedOption.value;
        this._projectCreateModel.type = value;
    };
    CreateProjectModalC.prototype._updateOrgUnit = function (orgUnitId) {
        return __awaiter(this, void 0, void 0, function () {
            var responsibleOrgUnitId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        responsibleOrgUnitId = parseInt(orgUnitId);
                        this._projectCreateModel.responsibleOrgUnitId = responsibleOrgUnitId;
                        return [4 /*yield*/, this.setIsProjectTypeSelectionAllowed(responsibleOrgUnitId)];
                    case 1:
                        _a.sent();
                        if (!this._isProjectTypeSelectionAllowed) {
                            this._projectCreateModel.type = ProjectTypeEnum.Gate;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateProjectModalC.prototype.setIsProjectTypeSelectionAllowed = function (orgUnitId) {
        return __awaiter(this, void 0, void 0, function () {
            var body;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.isProjectTypeSelectionAllowed({
                            orgUnitId: orgUnitId
                        })];
                    case 1:
                        body = (_a.sent()).body;
                        this._isProjectTypeSelectionAllowed = body;
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateProjectModalC.prototype._toggleModal = function () {
        this.props.onToggle();
    };
    CreateProjectModalC.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        var isOpen = this.props.isOpen;
        var isFormValid = !!(this._projectCreateModel.displayName &&
            this._projectCreateModel.responsibleOrgUnitId &&
            this._projectCreateModel.leaderPrincipalId &&
            this._projectCreateModel.plannedStartDate &&
            this._projectCreateModel.plannedEndDate &&
            this._projectCreateModel.securityLevel &&
            this._projectCreateModel.category &&
            this._projectCreateModel.type &&
            this._totalBudgetNumber);
        return (React.createElement(Modal, { isOpen: isOpen, headerTitle: "Create New Project", toggle: this._toggleModal, minWidth: '1000px', footerbuttons: this._getFooterButtons(isFormValid) },
            React.createElement("div", { className: styles.projectContainer },
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(Label, null, "Project Name"),
                        React.createElement(Input, { type: "text", onChange: function (e) {
                                return (_this._projectCreateModel.displayName = e.target.value);
                            } })),
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(Label, null, "Total Budget, KUSD"),
                        React.createElement(Input, { type: "text", value: this._totalBudgetNumber, onChange: this._handleTotalBudgetChange }))),
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(Label, null, "Lead Responsible Org Unit"),
                        React.createElement(OrgUnitPicker, { isForProject: true, isActiveOnly: true, isActiveOrWithProjectsOnly: false, onChangeHandler: function (ou) { return _this._updateOrgUnit(ou.value); } })),
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(Label, null, "Project Leader"),
                        React.createElement(PersonPicker, { onUsersSelect: this._handleUserSelect, isCreatable: true }))),
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(Label, null, "Planned Start"),
                        React.createElement(DatePicker, { onDateChanged: function (date) {
                                _this._projectCreateModel.plannedStartDate = date;
                            }, max: (_a = this._projectCreateModel) === null || _a === void 0 ? void 0 : _a.plannedEndDate, acceptCustomValue: false, type: "date" })),
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(Label, null, "Planned End"),
                        React.createElement(DatePicker, { onDateChanged: function (date) {
                                _this._projectCreateModel.plannedEndDate = date;
                            }, min: (_b = this._projectCreateModel) === null || _b === void 0 ? void 0 : _b.plannedStartDate, acceptCustomValue: false, type: "date" }))),
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(Label, null, "Security Level"),
                        React.createElement(Select, { options: this._securityLevels, onChange: this._handleSecurityLevelChange })),
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(Label, null, "Project Category"),
                        React.createElement(Select, { options: this._categories, onChange: this._handleCategoryChange }))),
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(Label, null, "IP Key"),
                        React.createElement(Select, { options: BooleanOptions, defaultValue: BooleanOptions.find(function (x) { return x.value === (_this._projectCreateModel.intellectualPropertyKeyProject ? '1' : '0'); }), onChange: this._handleIPKeyChange })),
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(Label, null, "Digital"),
                        React.createElement(Select, { options: BooleanOptions, defaultValue: BooleanOptions.find(function (x) { return x.value === (_this._projectCreateModel.isDigital ? '1' : '0'); }), onChange: this._handleDigitalChange }))),
                this._isProjectTypeSelectionAllowed &&
                    React.createElement("div", { className: styles.line },
                        React.createElement("div", { className: styles.halfField },
                            React.createElement(Label, null, "Project Type"),
                            React.createElement(Select, { defaultValue: (_c = this._types) === null || _c === void 0 ? void 0 : _c.find(function (x) { return x.value === _this._projectCreateModel.type; }), options: this._types, onChange: this._handleTypeChange }))))));
    };
    CreateProjectModalC.prototype._getFooterButtons = function (isFormValid) {
        return [
            {
                color: 'success',
                disabled: !isFormValid,
                onClick: this._handleSaveClick,
                title: 'Save'
            },
            {
                onClick: this._toggleModal,
                title: 'Close'
            }
        ];
    };
    __decorate([
        observable.deep
    ], CreateProjectModalC.prototype, "_projectCreateModel", void 0);
    __decorate([
        observable
    ], CreateProjectModalC.prototype, "_totalBudgetNumber", void 0);
    __decorate([
        observable
    ], CreateProjectModalC.prototype, "_securityLevels", void 0);
    __decorate([
        observable
    ], CreateProjectModalC.prototype, "_categories", void 0);
    __decorate([
        observable
    ], CreateProjectModalC.prototype, "_types", void 0);
    __decorate([
        observable
    ], CreateProjectModalC.prototype, "_savingInProgress", void 0);
    __decorate([
        observable
    ], CreateProjectModalC.prototype, "_isProjectTypeSelectionAllowed", void 0);
    __decorate([
        action.bound
    ], CreateProjectModalC.prototype, "_handleSaveClick", null);
    __decorate([
        action.bound
    ], CreateProjectModalC.prototype, "_handleUserSelect", null);
    __decorate([
        action.bound
    ], CreateProjectModalC.prototype, "_handleSecurityLevelChange", null);
    __decorate([
        action.bound
    ], CreateProjectModalC.prototype, "_handleIPKeyChange", null);
    __decorate([
        action.bound
    ], CreateProjectModalC.prototype, "_handleDigitalChange", null);
    __decorate([
        action.bound
    ], CreateProjectModalC.prototype, "_handleCategoryChange", null);
    __decorate([
        action.bound
    ], CreateProjectModalC.prototype, "_handleTypeChange", null);
    __decorate([
        action.bound
    ], CreateProjectModalC.prototype, "_updateOrgUnit", null);
    __decorate([
        action.bound
    ], CreateProjectModalC.prototype, "setIsProjectTypeSelectionAllowed", null);
    __decorate([
        action.bound
    ], CreateProjectModalC.prototype, "_toggleModal", null);
    __decorate([
        action.bound
    ], CreateProjectModalC.prototype, "_handleTotalBudgetChange", void 0);
    CreateProjectModalC = __decorate([
        observer
    ], CreateProjectModalC);
    return CreateProjectModalC;
}(React.Component));
export var CreateProjectModal = withRouter(CreateProjectModalC);
