var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observer } from 'mobx-react';
import { action, computed, observable, runInAction } from 'mobx';
import { FieldType, Filter } from '@app/Components/Filter/Filter';
import { Api } from '@app/Api';
import { setDefaultFilterProp } from '@app/Components/ProjectsOverview/helpers';
import { PassedGateYearCustomTitle } from '../PassedGateYearCustomTitle/PassedGateYearCustomTitle';
import { Events } from '@app/AppConstants';
export var ProjectsOverviewFilter = /** @class */ (function (_super) {
    __extends(ProjectsOverviewFilter, _super);
    function ProjectsOverviewFilter() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        _this = _super.apply(this, arguments) || this;
        _this._filterTimerId = null;
        _this._isLastPassedGateOnly = ((_a = _this.props.defaultFilters) === null || _a === void 0 ? void 0 : _a['isLastPassedGateOnly']) != undefined
            ? (_b = _this.props.defaultFilters) === null || _b === void 0 ? void 0 : _b['isLastPassedGateOnly']
            : true;
        _this._isIncludeSkipped = ((_c = _this.props.defaultFilters) === null || _c === void 0 ? void 0 : _c['isIncludeSkipped']) != undefined
            ? (_d = _this.props.defaultFilters) === null || _d === void 0 ? void 0 : _d['isIncludeSkipped']
            : true;
        _this._yearFilter = {
            name: 'Year',
            codeName: 'year',
            type: FieldType.SELECT,
            options: [],
            value: [],
            isMulti: true,
            onChange: function (value) {
                _this._yearFilter.value = value ? value : [];
                _this._onFilterChange();
            }
        };
        _this._projectNameFilter = {
            name: 'Project Name / Number',
            codeName: 'projectName',
            type: FieldType.TEXT,
            value: ((_e = _this.props.defaultFilters) === null || _e === void 0 ? void 0 : _e['projectName']) || '',
            onChange: function (value) {
                _this._projectNameFilter.value = value;
                _this._onFilterChange();
            }
        };
        _this._leaderFilter = {
            name: 'Leader',
            codeName: 'leader',
            type: FieldType.PERSON_PICKER,
            options: [],
            value: ((_f = _this.props.defaultFilters) === null || _f === void 0 ? void 0 : _f.leader) || [],
            isMulti: true,
            onChange: function (value) {
                _this._leaderFilter.value = value ? value : [];
                _this._onFilterChange();
            }
        };
        _this._leadResponsibleOrgUnitFilter = {
            name: 'Lead Responsible Org Unit',
            codeName: 'leadResponsibleOrgUnit',
            type: FieldType.SELECT,
            options: [],
            value: undefined,
            onChange: function (value) {
                _this._leadResponsibleOrgUnitFilter.value = value;
                _this._onFilterChange();
            }
        };
        _this._statusFilter = {
            name: 'Status',
            codeName: 'status',
            type: FieldType.SELECT,
            options: [],
            value: [],
            isMulti: true,
            onChange: function (value) {
                _this._statusFilter.value = value ? value : [];
                _this._onFilterChange();
            }
        };
        _this._categoryFilter = {
            name: 'Category',
            codeName: 'category',
            type: FieldType.SELECT,
            options: [],
            value: [],
            isMulti: true,
            onChange: function (value) {
                _this._categoryFilter.value = value ? value : [];
                _this._onFilterChange();
            }
        };
        _this._myFavouritesFilter = {
            name: 'My Favourites',
            codeName: 'myFavourites',
            type: FieldType.SELECT,
            options: [
                { value: '1', label: 'YES' },
                { value: '2', label: 'NO' }
            ],
            value: ((_g = _this.props.defaultFilters) === null || _g === void 0 ? void 0 : _g['myFavourites']) || undefined,
            onChange: function (value) {
                _this._myFavouritesFilter.value = value;
                _this._onFilterChange();
            }
        };
        _this._gateAssessorFilter = {
            name: 'Gate Assessor',
            codeName: 'gateAssessor',
            type: FieldType.PERSON_PICKER,
            options: [],
            value: ((_h = _this.props.defaultFilters) === null || _h === void 0 ? void 0 : _h.gateAssessor) || [],
            isMulti: true,
            onChange: function (value) {
                _this._gateAssessorFilter.value = value ? value : [];
                _this._onFilterChange();
            }
        };
        _this._byTeamMemberFilter = {
            name: 'By Team Member',
            codeName: 'byTeamMember',
            type: FieldType.PERSON_PICKER,
            options: [],
            value: ((_j = _this.props.defaultFilters) === null || _j === void 0 ? void 0 : _j.byTeamMember) || [],
            isMulti: true,
            onChange: function (value) {
                _this._byTeamMemberFilter.value = value ? value : [];
                _this._onFilterChange();
            }
        };
        _this._gateFilter = {
            name: 'Passed Gate',
            codeName: 'gate',
            type: FieldType.SELECT,
            options: [],
            value: [],
            isMulti: true,
            customTitle: (React.createElement(PassedGateYearCustomTitle, { isLastPassedGateOnlyDefaultChecked: _this._isLastPassedGateOnly, isIncludeSkippedDefaultChecked: _this._isIncludeSkipped, onLastPassedGateOnlyToggle: _this._onLastPassedGateOnlyToggle, onIncludeSkippedToggle: _this._onIncludeSkippedToggle })),
            onChange: function (value) {
                _this._gateFilter.value = value ? value : [];
                if (!value) {
                    _this._passedGateYearFilter.isDisable = true;
                    _this._passedGateYearFilter.value = '';
                }
                else {
                    _this._passedGateYearFilter.isDisable = false;
                }
                _this._onFilterChange();
            }
        };
        _this._passedGateYearFilter = {
            name: 'Passed Gate Year',
            codeName: 'passedGateYear',
            type: FieldType.SELECT,
            options: [],
            value: undefined,
            isMulti: false,
            onChange: function (value) {
                _this._passedGateYearFilter.value = value;
                _this._onFilterChange();
            }
        };
        _this._checklistByCodeFilter = {
            name: 'Checklist by Code',
            codeName: 'checklistByCode',
            type: FieldType.SELECT,
            options: [],
            value: [],
            isMulti: true,
            onChange: function (value) {
                _this._checklistByCodeFilter.value = value ? value : [];
                _this._onFilterChange();
            }
        };
        _this._isOverdueGateOnlyFilter = {
            name: 'Gate Decision Overdue',
            codeName: 'isOverdueGateOnly',
            type: FieldType.SWITCH,
            value: ((_k = _this.props.defaultFilters) === null || _k === void 0 ? void 0 : _k.isOverdueGateOnly) || false,
            onChange: function (value) {
                _this._isOverdueGateOnlyFilter.value = value;
                _this._onFilterChange();
            }
        };
        _this._filters = [
            _this._yearFilter,
            _this._projectNameFilter,
            _this._leaderFilter,
            _this._leadResponsibleOrgUnitFilter,
            _this._statusFilter,
            _this._categoryFilter,
            _this._myFavouritesFilter,
            _this._gateAssessorFilter,
            _this._byTeamMemberFilter,
            _this._gateFilter,
            _this._passedGateYearFilter,
            _this._checklistByCodeFilter,
            _this._isOverdueGateOnlyFilter
        ];
        return _this;
    }
    Object.defineProperty(ProjectsOverviewFilter.prototype, "_filterOptions", {
        get: function () {
            var _this = this;
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            var mGateIds = [], gateIds = [];
            (_a = this._gates) === null || _a === void 0 ? void 0 : _a.forEach(function (gate) {
                var _a;
                (_a = _this._gateFilter.value) === null || _a === void 0 ? void 0 : _a.forEach(function (opt) {
                    if (opt.label === gate.label) {
                        gate.isMilestone ? mGateIds.push(gate.number) : gateIds.push(gate.number);
                    }
                });
            });
            var years = (_b = this._yearFilter.value) === null || _b === void 0 ? void 0 : _b.map(function (it) { return +it.value; });
            var projectLeaderIds = (_c = this._leaderFilter.value) === null || _c === void 0 ? void 0 : _c.map(function (it) { return +it.value; });
            var orgUnitId = (_d = this._leadResponsibleOrgUnitFilter.value) === null || _d === void 0 ? void 0 : _d.value;
            var categories = (_e = this._categoryFilter.value) === null || _e === void 0 ? void 0 : _e.map(function (it) { return it.value; });
            var gateAssessorIds = (_g = (_f = this._gateAssessorFilter.value) === null || _f === void 0 ? void 0 : _f.map) === null || _g === void 0 ? void 0 : _g.call(_f, function (it) { return +it.value; });
            var teamMemberIds = (_h = this._byTeamMemberFilter.value) === null || _h === void 0 ? void 0 : _h.map(function (it) { return +it.value; });
            var checklistCodes = (_j = this._checklistByCodeFilter.value) === null || _j === void 0 ? void 0 : _j.map(function (it) { return it.value; });
            var passedGateYear = (_k = this._passedGateYearFilter.value) === null || _k === void 0 ? void 0 : _k.value;
            var isOverdueGateOnly = this._isOverdueGateOnlyFilter.value;
            return {
                years: (years === null || years === void 0 ? void 0 : years.length) ? years : undefined,
                projectDisplayNameAndNumber: this._projectNameFilter.value || undefined,
                projectLeaderIds: (projectLeaderIds === null || projectLeaderIds === void 0 ? void 0 : projectLeaderIds.length) ? projectLeaderIds : undefined,
                orgUnitId: orgUnitId ? +orgUnitId : undefined,
                statuses: (_l = this._statusFilter.value) === null || _l === void 0 ? void 0 : _l.map(function (it) { return it.label; }),
                categories: (categories === null || categories === void 0 ? void 0 : categories.length) ? categories : undefined,
                favoritesOnly: ((_m = this._myFavouritesFilter.value) === null || _m === void 0 ? void 0 : _m.label) === 'YES' || undefined,
                gateAssessorIds: (gateAssessorIds === null || gateAssessorIds === void 0 ? void 0 : gateAssessorIds.length) ? gateAssessorIds : undefined,
                teamMemberIds: (teamMemberIds === null || teamMemberIds === void 0 ? void 0 : teamMemberIds.length) ? teamMemberIds : undefined,
                passedGateNumbers: gateIds.length ? gateIds : undefined,
                milestoneNumbers: (mGateIds === null || mGateIds === void 0 ? void 0 : mGateIds.length) ? mGateIds : undefined,
                checklistCodes: checklistCodes.length ? checklistCodes : undefined,
                passedGateYear: passedGateYear ? +passedGateYear : undefined,
                isLastPassedGateOnly: this._isLastPassedGateOnly,
                isIncludeSkipped: this._isIncludeSkipped,
                isOverdueGateOnly: isOverdueGateOnly
            };
        },
        enumerable: false,
        configurable: true
    });
    ProjectsOverviewFilter.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var yearsResponse, gatesResponse, orgUnitsResponse, statusResponse, categoryResponse, checklistsResponse, passedGateYearResponse;
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            Api.secured.getProjectYearLookups(),
                            Api.secured.gateDisplayNameLookupModels(),
                            Api.secured.getOrgUnitsLookupInfo({ isActiveOrWithProjectsOnly: true }),
                            Api.secured.getProjectStatusEnum(),
                            Api.secured.getProjectCategoryEnum(),
                            Api.secured.getProjectMasterChecklistTemplateLookups(),
                            Api.secured.getProjectPassedGateYearLookups()
                        ])];
                    case 1:
                        yearsResponse = (_a = _b.sent(), _a[0]), gatesResponse = _a[1], orgUnitsResponse = _a[2], statusResponse = _a[3], categoryResponse = _a[4], checklistsResponse = _a[5], passedGateYearResponse = _a[6];
                        runInAction(function () {
                            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
                            _this._yearFilter.options = yearsResponse.map(function (item) { return (__assign(__assign({}, item), { value: "".concat(item.value) })); });
                            if ((_b = (_a = _this.props.defaultFilters) === null || _a === void 0 ? void 0 : _a.year) === null || _b === void 0 ? void 0 : _b.length) {
                                setDefaultFilterProp(_this._yearFilter, (_c = _this.props.defaultFilters) === null || _c === void 0 ? void 0 : _c.year);
                            }
                            _this._gateFilter.options = gatesResponse.map(function (item) { return ({ value: item.label, label: item.label }); });
                            if ((_e = (_d = _this.props.defaultFilters) === null || _d === void 0 ? void 0 : _d.gate) === null || _e === void 0 ? void 0 : _e.length) {
                                setDefaultFilterProp(_this._gateFilter, (_f = _this.props.defaultFilters) === null || _f === void 0 ? void 0 : _f.gate);
                            }
                            else {
                                _this._passedGateYearFilter.isDisable = true;
                            }
                            _this._gates = gatesResponse;
                            _this._leadResponsibleOrgUnitFilter.options = orgUnitsResponse.map(function (item) { return ({
                                value: "".concat(item.id),
                                label: item.codeName
                            }); });
                            if ((_g = _this.props.defaultFilters) === null || _g === void 0 ? void 0 : _g.leadResponsibleOrgUnit) {
                                if (_this._leadResponsibleOrgUnitFilter.options.some(function (option) { var _a, _b; return option.value === ((_b = (_a = _this.props.defaultFilters) === null || _a === void 0 ? void 0 : _a.leadResponsibleOrgUnit) === null || _b === void 0 ? void 0 : _b.value); })) {
                                    _this._leadResponsibleOrgUnitFilter.value = (_h = _this.props.defaultFilters) === null || _h === void 0 ? void 0 : _h.leadResponsibleOrgUnit;
                                }
                            }
                            _this._statusFilter.options = statusResponse;
                            if ((_k = (_j = _this.props.defaultFilters) === null || _j === void 0 ? void 0 : _j.status) === null || _k === void 0 ? void 0 : _k.length) {
                                setDefaultFilterProp(_this._statusFilter, (_l = _this.props.defaultFilters) === null || _l === void 0 ? void 0 : _l.status);
                            }
                            _this._categoryFilter.options = categoryResponse;
                            if ((_o = (_m = _this.props.defaultFilters) === null || _m === void 0 ? void 0 : _m.category) === null || _o === void 0 ? void 0 : _o.length) {
                                setDefaultFilterProp(_this._categoryFilter, (_p = _this.props.defaultFilters) === null || _p === void 0 ? void 0 : _p.category);
                            }
                            _this._checklistByCodeFilter.options = checklistsResponse.map(function (item) { return (__assign(__assign({}, item), { value: "".concat(item.value) })); });
                            if ((_r = (_q = _this.props.defaultFilters) === null || _q === void 0 ? void 0 : _q.checklistByCode) === null || _r === void 0 ? void 0 : _r.length) {
                                setDefaultFilterProp(_this._checklistByCodeFilter, (_s = _this.props.defaultFilters) === null || _s === void 0 ? void 0 : _s.checklistByCode);
                            }
                            _this._passedGateYearFilter.options = passedGateYearResponse.map(function (item) { return (__assign(__assign({}, item), { value: "".concat(item.value) })); });
                            _this._passedGateYearFilter.value = (_t = _this.props.defaultFilters) === null || _t === void 0 ? void 0 : _t['passedGateYear'];
                        });
                        this.props.onFilterProjects(this._filterOptions);
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectsOverviewFilter.prototype.componentWillUnmount = function () {
        this._clearFilterTimeout();
    };
    ProjectsOverviewFilter.prototype._onLastPassedGateOnlyToggle = function (checked) {
        this._isLastPassedGateOnly = checked;
        this._onFilterChange();
    };
    ProjectsOverviewFilter.prototype._onIncludeSkippedToggle = function (checked) {
        this._isIncludeSkipped = checked;
        this._onFilterChange();
    };
    ProjectsOverviewFilter.prototype._onFilterChange = function () {
        var _this = this;
        this._clearFilterTimeout();
        this._filterTimerId = window.setTimeout(function () {
            _this.props.onFilterProjects(_this._filterOptions);
        }, 500);
    };
    ProjectsOverviewFilter.prototype._clearFilters = function () {
        this._clearFilterTimeout();
        this._filters.map(function (f) {
            if (f.isMulti) {
                f.value = [];
            }
            else {
                var value = void 0;
                switch (f.type) {
                    case FieldType.SELECT: {
                        value = null;
                        break;
                    }
                    case FieldType.SWITCH: {
                        value = false;
                        break;
                    }
                    default: {
                        value = '';
                    }
                }
                f.value = value;
            }
        });
        this._isLastPassedGateOnly = true;
        this._isIncludeSkipped = true;
        this._passedGateYearFilter.isDisable = true;
        document.dispatchEvent(new Event(Events.setPassedGateYearTogglerTrue));
        document.dispatchEvent(new Event(Events.setIncludeSkippedTogglerTrue));
        this.props.onFilterClear();
    };
    ProjectsOverviewFilter.prototype._onSaveFilters = function () {
        var _a, _b;
        var filtersParams = {
            year: this._yearFilter.value,
            projectName: this._projectNameFilter.value,
            leader: (_b = (_a = this._leaderFilter.value) === null || _a === void 0 ? void 0 : _a.map) === null || _b === void 0 ? void 0 : _b.call(_a, function (leader) { return (__assign(__assign({}, leader), { label: leader.displayName })); }),
            leadResponsibleOrgUnit: this._leadResponsibleOrgUnitFilter.value,
            status: this._statusFilter.value,
            category: this._categoryFilter.value,
            myFavourites: this._myFavouritesFilter.value,
            gateAssessor: this._gateAssessorFilter.value,
            byTeamMember: this._byTeamMemberFilter.value,
            gate: this._gateFilter.value,
            passedGateYear: this._passedGateYearFilter.value,
            checklistByCode: this._checklistByCodeFilter.value,
            isLastPassedGateOnly: this._isLastPassedGateOnly,
            isIncludeSkipped: this._isIncludeSkipped,
            isOverdueGateOnly: this._isOverdueGateOnlyFilter.value
        };
        this.props.onFiltersSave(filtersParams);
    };
    ProjectsOverviewFilter.prototype._resetFilters = function () {
        this.props.onFilterReset();
        this._clearFilters();
    };
    ProjectsOverviewFilter.prototype._clearFilterTimeout = function () {
        if (this._filterTimerId)
            window.clearTimeout(this._filterTimerId);
    };
    ProjectsOverviewFilter.prototype.render = function () {
        return (React.createElement(Filter, { filters: this._filters, onFiltersSave: this._onSaveFilters, onFiltersClear: this._clearFilters, onFiltersReset: this._resetFilters }));
    };
    __decorate([
        observable
    ], ProjectsOverviewFilter.prototype, "_gates", void 0);
    __decorate([
        observable
    ], ProjectsOverviewFilter.prototype, "_yearFilter", void 0);
    __decorate([
        observable
    ], ProjectsOverviewFilter.prototype, "_projectNameFilter", void 0);
    __decorate([
        observable
    ], ProjectsOverviewFilter.prototype, "_leaderFilter", void 0);
    __decorate([
        observable
    ], ProjectsOverviewFilter.prototype, "_leadResponsibleOrgUnitFilter", void 0);
    __decorate([
        observable
    ], ProjectsOverviewFilter.prototype, "_statusFilter", void 0);
    __decorate([
        observable
    ], ProjectsOverviewFilter.prototype, "_categoryFilter", void 0);
    __decorate([
        observable
    ], ProjectsOverviewFilter.prototype, "_myFavouritesFilter", void 0);
    __decorate([
        observable
    ], ProjectsOverviewFilter.prototype, "_gateAssessorFilter", void 0);
    __decorate([
        observable
    ], ProjectsOverviewFilter.prototype, "_byTeamMemberFilter", void 0);
    __decorate([
        observable
    ], ProjectsOverviewFilter.prototype, "_gateFilter", void 0);
    __decorate([
        observable
    ], ProjectsOverviewFilter.prototype, "_passedGateYearFilter", void 0);
    __decorate([
        observable
    ], ProjectsOverviewFilter.prototype, "_checklistByCodeFilter", void 0);
    __decorate([
        observable
    ], ProjectsOverviewFilter.prototype, "_isOverdueGateOnlyFilter", void 0);
    __decorate([
        observable
    ], ProjectsOverviewFilter.prototype, "_filters", void 0);
    __decorate([
        computed
    ], ProjectsOverviewFilter.prototype, "_filterOptions", null);
    __decorate([
        action.bound
    ], ProjectsOverviewFilter.prototype, "_onLastPassedGateOnlyToggle", null);
    __decorate([
        action.bound
    ], ProjectsOverviewFilter.prototype, "_onIncludeSkippedToggle", null);
    __decorate([
        action.bound
    ], ProjectsOverviewFilter.prototype, "_onFilterChange", null);
    __decorate([
        action.bound
    ], ProjectsOverviewFilter.prototype, "_clearFilters", null);
    __decorate([
        action.bound
    ], ProjectsOverviewFilter.prototype, "_onSaveFilters", null);
    __decorate([
        action.bound
    ], ProjectsOverviewFilter.prototype, "_resetFilters", null);
    ProjectsOverviewFilter = __decorate([
        observer
    ], ProjectsOverviewFilter);
    return ProjectsOverviewFilter;
}(React.PureComponent));
