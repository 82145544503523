var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import styles from './Home.module.scss';
import { authStore } from '@app/Stores/AuthStore';
import { Widget } from '@app/Components/Widget/Widget';
import { Button } from '@app/Components/Button/Button';
import { MyTasks } from '@app/Pages/Home/MyTasks/MyTasks';
import { MyFavoriteProjects } from '@app/Pages/Home/MyFavoriteProjects/MyFavoriteProjects';
import { PageTitle } from '@app/Components/PageTitle/PageTitle';
import { appStore } from '@app/AppStore';
import { InfoNotifications } from '@app/Pages/Home/InfoNotifications/InfoNotifications';
import ErrorBoundary from '@app/Components/ErrorBoundary/ErrorBoundary';
import { AppData } from '@app/AppConstants';
import { MyFunctionChecklists } from '@app/Pages/Home/MyFunctionChecklists/MyFunctionChecklists';
import { ProjectsWidget } from './Projects/ProjectsWidget';
var Home = /** @class */ (function (_super) {
    __extends(Home, _super);
    function Home() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Home.prototype.render = function () {
        var _a, _b;
        var displayName = ((_a = appStore.currentPrincipal) === null || _a === void 0 ? void 0 : _a.displayName) || ((_b = authStore.currentUser) === null || _b === void 0 ? void 0 : _b.name);
        return (React.createElement(ErrorBoundary, null,
            React.createElement("div", null,
                React.createElement(PageTitle, { title: "".concat(displayName, "'s Dashboard") }),
                React.createElement("div", { className: styles.widgets },
                    React.createElement("div", { className: styles.column },
                        React.createElement("div", { className: styles.widget },
                            React.createElement(Widget, { title: "My Tasks" },
                                React.createElement(MyTasks, null))),
                        React.createElement("div", { className: styles.widget },
                            React.createElement(Widget, { title: "My favorite projects" },
                                React.createElement(MyFavoriteProjects, null))),
                        React.createElement("div", { className: styles.widget },
                            React.createElement(ProjectsWidget, null)),
                        React.createElement("div", { className: styles.widget },
                            React.createElement(Widget, { title: "My Function Checklists" },
                                React.createElement(MyFunctionChecklists, null)))),
                    React.createElement("div", { className: styles.column },
                        React.createElement("div", null,
                            React.createElement("a", { href: "/tasks?".concat(AppData.AssigneeQueryParameter, "=").concat(displayName) },
                                React.createElement(Button, { size: "sm", color: "primary" }, "Delegates & Project Tasks History"))),
                        React.createElement("div", { className: "".concat(styles.widget, " ").concat(styles.info) },
                            React.createElement(Widget, { title: "Information widget" },
                                React.createElement(InfoNotifications, null))))))));
    };
    return Home;
}(React.PureComponent));
export { Home };
