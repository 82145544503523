var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Widget } from '@app/Components/Widget/Widget';
import { TemplatesManagementTable } from './TemplatesManagementTable/TemplatesManagementTable';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { Api } from '@app/Api';
import styles from './Templates.module.scss';
import { TemplatesImport } from './TemplatesImport/TemplatesImport';
import { Button } from '@app/Components/Button/Button';
import PromiseQueue from '@app/Classes/PromiseQueueWithCompletion';
import { PageTitle } from '@app/Components/PageTitle/PageTitle';
import ErrorBoundary from '@app/Components/ErrorBoundary/ErrorBoundary';
import DownloadService from '@app/Services/DownloadService';
export var Templates = /** @class */ (function (_super) {
    __extends(Templates, _super);
    function Templates(props) {
        var _this = _super.call(this, props) || this;
        _this._templates = [];
        _this._isTemplatesLoading = false;
        _this._isImportOpen = false;
        _this._importErrors = [];
        _this._masterFileMode = false;
        _this._uploadQueue = new PromiseQueue();
        return _this;
    }
    Templates.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._getTemplates()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Templates.prototype._handleTemplateExport = function (projectTemplateId) {
        return __awaiter(this, void 0, void 0, function () {
            var getLogsResponse, data, fileName;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.downloadChecklist({ projectTemplateId: projectTemplateId })];
                    case 1:
                        getLogsResponse = _a.sent();
                        return [4 /*yield*/, getLogsResponse.blobBody];
                    case 2:
                        data = _a.sent();
                        fileName = "template".concat(projectTemplateId, ".zip");
                        DownloadService.downloadByData(data, fileName);
                        return [2 /*return*/];
                }
            });
        });
    };
    Templates.prototype._handleTemplateEnabled = function (projectTemplateId, isEnabled) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.changeProjectTemplate(projectTemplateId, { isEnabled: isEnabled })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._getTemplates()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Templates.prototype._handleTemplateRemove = function (projectTemplateId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.deleteTemplate({ projectTemplateId: projectTemplateId })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._getTemplates()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Templates.prototype._handleSaveAction = function (files) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._importErrors = [];
                        files.forEach(function (file) {
                            return _this._uploadQueue.add(file, function (file) { return __awaiter(_this, void 0, void 0, function () {
                                var res, e_1;
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _b.trys.push([0, 2, , 3]);
                                            return [4 /*yield*/, Api.secured.uploadChecklistTemplateAsync({
                                                    file: file,
                                                    masterFileMode: this._masterFileMode
                                                })];
                                        case 1:
                                            res = _b.sent();
                                            if ((_a = res.errorMessages) === null || _a === void 0 ? void 0 : _a.length)
                                                this._importErrors.push(res);
                                            return [3 /*break*/, 3];
                                        case 2:
                                            e_1 = _b.sent();
                                            console.log(e_1);
                                            return [3 /*break*/, 3];
                                        case 3: return [2 /*return*/];
                                    }
                                });
                            }); });
                        });
                        return [4 /*yield*/, this._uploadQueue.wait()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._getTemplates()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Templates.prototype._getTemplates = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this._isTemplatesLoading = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, , 3, 4]);
                        _a = this;
                        return [4 /*yield*/, Api.secured.getTemplates()];
                    case 2:
                        _a._templates = _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this._isTemplatesLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    Templates.prototype._handleCancelAction = function () {
        this._importErrors = [];
        this._isImportOpen = !this._isImportOpen;
    };
    Templates.prototype._handleModeChange = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._importErrors = [];
                this._masterFileMode = !this._masterFileMode;
                return [2 /*return*/];
            });
        });
    };
    Templates.prototype.render = function () {
        var _this = this;
        return (React.createElement(ErrorBoundary, null,
            React.createElement(PageTitle, { title: 'TEMPLATES' }),
            React.createElement(Widget, { title: "Project Templates" },
                React.createElement("div", { className: styles.table },
                    React.createElement(TemplatesManagementTable, { loading: this._isTemplatesLoading, templates: this._templates, onTemplateExport: this._handleTemplateExport, onTemplateEnabled: this._handleTemplateEnabled, onRefreshTemplates: this._getTemplates, onTemplateRemove: this._handleTemplateRemove })),
                React.createElement("div", { className: styles.buttonLine },
                    React.createElement(Button, { size: "sm", color: "primary", onClick: function () {
                            _this._isImportOpen = true;
                        } }, "Import")),
                React.createElement(TemplatesImport, { isOpen: this._isImportOpen, toggle: this._handleCancelAction, save: this._handleSaveAction, importErrors: this._importErrors, modeChange: this._handleModeChange, masterFileMode: this._masterFileMode }))));
    };
    __decorate([
        observable
    ], Templates.prototype, "_templates", void 0);
    __decorate([
        observable
    ], Templates.prototype, "_isTemplatesLoading", void 0);
    __decorate([
        observable
    ], Templates.prototype, "_isImportOpen", void 0);
    __decorate([
        observable
    ], Templates.prototype, "_importErrors", void 0);
    __decorate([
        observable
    ], Templates.prototype, "_masterFileMode", void 0);
    __decorate([
        action.bound
    ], Templates.prototype, "_handleTemplateExport", null);
    __decorate([
        action.bound
    ], Templates.prototype, "_handleTemplateEnabled", null);
    __decorate([
        action.bound
    ], Templates.prototype, "_handleTemplateRemove", null);
    __decorate([
        action.bound
    ], Templates.prototype, "_handleSaveAction", null);
    __decorate([
        action.bound
    ], Templates.prototype, "_getTemplates", null);
    __decorate([
        action.bound
    ], Templates.prototype, "_handleCancelAction", null);
    __decorate([
        action.bound
    ], Templates.prototype, "_handleModeChange", null);
    Templates = __decorate([
        observer
    ], Templates);
    return Templates;
}(React.PureComponent));
