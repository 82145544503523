var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import styles from './AssessmentGateHeader.module.scss';
import { Api } from '@app/Api';
import { Events } from '@app/AppConstants';
import { Button } from '@app/Components/Button/Button';
import { ConfirmButton } from '@app/Components/ConfirmButton/ConfirmButton';
import { ConfirmationDialog } from '@app/Widgets/ConfirmationDialog/ConfirmationDialog';
import { LoadingContext } from '@app/Context/LoadingContext';
export var AssesmentGateHeader = /** @class */ (function (_super) {
    __extends(AssesmentGateHeader, _super);
    function AssesmentGateHeader() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isConfirmationModalOpen = false;
        return _this;
    }
    AssesmentGateHeader.prototype._toggleConfirmationModalOpen = function () {
        this._isConfirmationModalOpen = !this._isConfirmationModalOpen;
    };
    AssesmentGateHeader.prototype._handleSaveOkClick = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._toggleConfirmationModalOpen();
                return [2 /*return*/];
            });
        });
    };
    AssesmentGateHeader.prototype._saveOkAssessorPart = function () {
        var _a, _b, _c, _d, _e, _f;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        _g.trys.push([0, , 3, 4]);
                        (_b = (_a = this.context).setLoading) === null || _b === void 0 ? void 0 : _b.call(_a, true);
                        return [4 /*yield*/, Api.secured.okAssessmentMasterCheckListItemAssessorParts({ gateId: this.props.currentGate.id })];
                    case 1:
                        _g.sent();
                        return [4 /*yield*/, ((_d = (_c = this.props).onRefreshChecklistStatusOverview) === null || _d === void 0 ? void 0 : _d.call(_c))];
                    case 2:
                        _g.sent();
                        document.dispatchEvent(new Event(Events.assessmentGateDeliversConfirm));
                        document.dispatchEvent(new Event(Events.assessmentGateFunctionSummariesConfirm));
                        document.dispatchEvent(new Event(Events.assessmentGateExecutiveQuestionsConfirm));
                        this._toggleConfirmationModalOpen();
                        return [3 /*break*/, 4];
                    case 3:
                        (_f = (_e = this.context).setLoading) === null || _f === void 0 ? void 0 : _f.call(_e, false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AssesmentGateHeader.prototype.render = function () {
        var _a;
        var _this = this;
        var currentGate = (_a = this.props, _a.currentGate), isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.tableHeader },
                React.createElement("div", { className: styles.recommendation },
                    currentGate.canMakeRecommendation && React.createElement("div", { className: styles.tableTitle }, "Recommendation"),
                    !currentGate.hasRecommendation && currentGate.canMakeRecommendation && (React.createElement(Button, { disabled: isProjectTerminatedOrCompleted, size: "sm", className: styles.recommendationButton, onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(currentGate === null || currentGate === void 0 ? void 0 : currentGate.id)) return [3 /*break*/, 2];
                                        return [4 /*yield*/, Api.secured.createRecommendation(currentGate.id)];
                                    case 1:
                                        _a.sent();
                                        _a.label = 2;
                                    case 2:
                                        if (currentGate) {
                                            currentGate.hasRecommendation = true;
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        }); } }, "Make Recommendation"))),
                currentGate.canPrincipalUpdateGateAssessmentAssessorPart && (React.createElement("div", { className: styles.confirmButtonWrapper },
                    React.createElement("div", { className: styles.confirmButton },
                        React.createElement(ConfirmButton, { id: 'tooltipAssessmentsGa' + currentGate.id, tooltipText: "Rate ALL Deliverables, Confirmation of Functional Readiness and Questions green and set comments to 'OK' as GA (missing data only)", disabled: isProjectTerminatedOrCompleted, onClick: function () { return _this._handleSaveOkClick(); } }))))),
            React.createElement(ConfirmationDialog, { isOpen: this._isConfirmationModalOpen, onToggle: this._toggleConfirmationModalOpen, headerTitle: "Rate ALL Deliverables, Confirmation of Functional Readiness and Questions green and set comments to 'OK'", onSave: this._saveOkAssessorPart, body: "Are you sure you want to provide Gate Assessor input as \u2018OK' comment and green Rating for all relevant items, where input is missing ('OK' comment will be set only for the cases of green or missing Rating)?" })));
    };
    AssesmentGateHeader.contextType = LoadingContext;
    __decorate([
        observable
    ], AssesmentGateHeader.prototype, "_isConfirmationModalOpen", void 0);
    __decorate([
        action.bound
    ], AssesmentGateHeader.prototype, "_toggleConfirmationModalOpen", null);
    __decorate([
        action.bound
    ], AssesmentGateHeader.prototype, "_handleSaveOkClick", null);
    __decorate([
        action.bound
    ], AssesmentGateHeader.prototype, "_saveOkAssessorPart", null);
    AssesmentGateHeader = __decorate([
        observer
    ], AssesmentGateHeader);
    return AssesmentGateHeader;
}(React.PureComponent));
