var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { Column, Sorting } from 'devextreme-react/data-grid';
import styles from './AppTasks.module.scss';
import { Api } from '@app/Api';
import { Widget } from '@app/Components/Widget/Widget';
import { Grid } from '@app/Components/Grid/Grid';
import { Button } from '@app/Components/Button/Button';
import { DateBox } from '@app/Widgets/DateBox/DateBox';
import { generateLink } from '../Home/UrlHelper';
import { AssignmentStateEnum } from '@app/AppConstants';
import { DATE_WITH_TIME_OPTIONS } from '@app/Helpers/DateOptions';
import { Col, Label, Row } from 'reactstrap';
import { ProjectPicker } from '@app/Components/Pickers/ProjectPicker/ProjectPicker';
import { PersonPicker } from '@app/Components/Pickers/PersonPicker/PersonPicker';
import { TaskStatePicker } from '@app/Components/Pickers/TaskStatePicker/TaskStatePicker';
export var AppTasks = /** @class */ (function (_super) {
    __extends(AppTasks, _super);
    function AppTasks(props) {
        var _this = _super.call(this, props) || this;
        _this._tasks = [];
        _this._projectId = null;
        _this._principal = null;
        _this._assignmentState = AssignmentStateEnum.Active;
        _this._loading = false;
        _this._expandedRows = [];
        _this._navigate = function (assignment) { return __awaiter(_this, void 0, void 0, function () {
            var link;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, generateLink(assignment)];
                    case 1:
                        link = _a.sent();
                        if (link) {
                            window.open(link, '_blank', 'noopener,noreferrer');
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        _this._handleRowExpanding = _this._handleRowExpanding.bind(_this);
        _this._handleRowCollapsing = _this._handleRowCollapsing.bind(_this);
        return _this;
    }
    AppTasks.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadTasks()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppTasks.prototype._loadTasks = function (messageType) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var projectId, principalId, assignmentState, tasks;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, , 2, 3]);
                        this._loading = true;
                        projectId = this._projectId || undefined;
                        principalId = ((_a = this._principal) === null || _a === void 0 ? void 0 : _a.id) || undefined;
                        assignmentState = this._assignmentState || undefined;
                        return [4 /*yield*/, Api.secured.getAppTasks({ projectId: projectId, messageType: messageType, assignmentState: assignmentState, principalId: principalId })];
                    case 1:
                        tasks = _b.sent();
                        this._setTasks(tasks, messageType);
                        return [3 /*break*/, 3];
                    case 2:
                        this._loading = false;
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AppTasks.prototype._setTasks = function (tasks, messageType) {
        if (messageType) {
            var filteredTasks = this._tasks.filter(function (task) { return task.messageType !== messageType; });
            this._tasks = __spreadArray(__spreadArray([], filteredTasks, true), tasks, true);
            return;
        }
        this._tasks = tasks;
    };
    AppTasks.prototype._setTaskState = function (id, messageType, isActive) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.updateTaskState({ id: id, isActive: isActive })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadTasks(messageType)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppTasks.prototype._handleRowExpanding = function (event) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var messageType, isExpanded;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        messageType = (_a = event.key) === null || _a === void 0 ? void 0 : _a[0];
                        isExpanded = !!this._expandedRows.find(function (row) { return row === messageType; });
                        if (isExpanded || !messageType)
                            return [2 /*return*/];
                        event.cancel = true;
                        return [4 /*yield*/, this._loadTasks(messageType)];
                    case 1:
                        _c.sent();
                        this._expandedRows.push(messageType);
                        return [4 /*yield*/, ((_b = event.component) === null || _b === void 0 ? void 0 : _b.expandRow([messageType]))];
                    case 2:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppTasks.prototype._handleRowCollapsing = function (event) {
        this._expandedRows = this._expandedRows.filter(function (row) { var _a; return row !== ((_a = event.key) === null || _a === void 0 ? void 0 : _a[0]); });
    };
    AppTasks.prototype._onFilterChange = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadTasks()];
                    case 1:
                        _a.sent();
                        if (this._expandedRows.length) {
                            this._expandedRows.forEach(function (row) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this._loadTasks(row)];
                                    case 1: return [2 /*return*/, _a.sent()];
                                }
                            }); }); });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AppTasks.prototype._handleProjectChange = function (projectId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._projectId = projectId ? parseInt(projectId) : null;
                        return [4 /*yield*/, this._onFilterChange()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppTasks.prototype._handlePrincipalChange = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._principal = value ? value[0] : null;
                        return [4 /*yield*/, this._onFilterChange()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppTasks.prototype._handleTaskStateChange = function (taskState) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._assignmentState = taskState || null;
                        return [4 /*yield*/, this._onFilterChange()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppTasks.prototype.render = function () {
        var _this = this;
        return (React.createElement(React.Fragment, null,
            React.createElement(Row, { className: styles.filters },
                React.createElement(Col, null,
                    React.createElement(Label, null, "PROJECT"),
                    React.createElement(ProjectPicker, { showDisplayName: true, onChangeHandler: function (x) { return _this._handleProjectChange(x === null || x === void 0 ? void 0 : x.value); }, isClearable: true })),
                React.createElement(Col, null,
                    React.createElement(Label, null, "PRINCIPAL"),
                    React.createElement(PersonPicker, { onUsersSelect: this._handlePrincipalChange, isClearable: true })),
                React.createElement(Col, null,
                    React.createElement(Label, null, "TASK STATE"),
                    React.createElement(TaskStatePicker, { defaultValue: AssignmentStateEnum.Active, onChangeHandler: function (x) { return _this._handleTaskStateChange(x === null || x === void 0 ? void 0 : x.value); }, isClearable: true }))),
            React.createElement(Widget, { title: "All Dashboard Tasks" },
                React.createElement(Grid, { dataSource: this._tasks, loading: this._loading, expandableRows: true, onRowExpanding: this._handleRowExpanding, onRowCollapsing: this._handleRowCollapsing },
                    React.createElement(Sorting, { mode: "multiple" }),
                    React.createElement(Column, { caption: "Message Type", dataField: "messageType", groupIndex: 0, autoExpandGroup: false }),
                    React.createElement(Column, { caption: "Message", dataField: "description", allowFiltering: false, minWidth: 250, cellRender: function (_a) {
                            var data = _a.data;
                            return (React.createElement("div", { className: styles.link, onClick: function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this._navigate(data)];
                                        case 1: return [2 /*return*/, _a.sent()];
                                    }
                                }); }); } }, data.description));
                        } }),
                    React.createElement(Column, { caption: "Project ID", dataField: "project.value", width: 100 }),
                    React.createElement(Column, { caption: "Project", dataField: "project.label", minWidth: 250 }),
                    React.createElement(Column, { caption: "Creation Date", dataField: "createdDate", width: 150, cellRender: function (_a) {
                            var data = _a.data;
                            return (React.createElement(DateBox, { date: data.createdDate }));
                        } }),
                    React.createElement(Column, { caption: "Due Date", dataField: "dueDate", width: 150, defaultSortOrder: "asc", cellRender: function (_a) {
                            var data = _a.data;
                            return (React.createElement(DateBox, { date: data.dueDate }));
                        } }),
                    React.createElement(Column, { caption: "Principals", dataField: "principals", minWidth: 250 }),
                    React.createElement(Column, { caption: "State", dataField: "state", defaultSelectedFilterOperation: "startswith", width: 100 }),
                    React.createElement(Column, { caption: "Email Last Send", dataField: "emailQueueLastRun", dataType: "date", width: 100, cellRender: function (_a) {
                            var data = _a.data;
                            var emailQueueLastRun = data.emailQueueLastRun;
                            return emailQueueLastRun
                                ? emailQueueLastRun.toLocaleDateString('en-GB', DATE_WITH_TIME_OPTIONS)
                                : '';
                        } }),
                    React.createElement(Column, { caption: "Email Last Failed Send", dataField: "emailQueueLastFailedRun", dataType: "date", width: 100, cellRender: function (_a) {
                            var data = _a.data;
                            var emailQueueLastFailedRun = data.emailQueueLastFailedRun;
                            return emailQueueLastFailedRun
                                ? emailQueueLastFailedRun.toLocaleDateString('en-GB', DATE_WITH_TIME_OPTIONS)
                                : '';
                        } }),
                    React.createElement(Column, { caption: "Email Error Message", dataField: "emailQueueLastErrorMessage", dataType: "string", width: 100, cellRender: function (_a) {
                            var data = _a.data;
                            return React.createElement("div", null, data.emailQueueLastErrorMessage);
                        } }),
                    React.createElement(Column, { caption: "Action", width: 100, cellRender: function (_a) {
                            var data = _a.data;
                            return data.state === AssignmentStateEnum.Active ? (React.createElement(Button, { size: "sm", onClick: function () { return _this._setTaskState(data.id, data.messageType, false); } }, "Deactivate")) : (React.createElement(Button, { size: "sm", color: "primary", onClick: function () { return _this._setTaskState(data.id, data.messageType, true); } }, "Activate"));
                        } })))));
    };
    __decorate([
        observable
    ], AppTasks.prototype, "_tasks", void 0);
    __decorate([
        observable
    ], AppTasks.prototype, "_projectId", void 0);
    __decorate([
        observable
    ], AppTasks.prototype, "_principal", void 0);
    __decorate([
        observable
    ], AppTasks.prototype, "_assignmentState", void 0);
    __decorate([
        observable
    ], AppTasks.prototype, "_loading", void 0);
    __decorate([
        action.bound
    ], AppTasks.prototype, "_loadTasks", null);
    __decorate([
        action.bound
    ], AppTasks.prototype, "_setTasks", null);
    __decorate([
        action.bound
    ], AppTasks.prototype, "_setTaskState", null);
    __decorate([
        action.bound
    ], AppTasks.prototype, "_handleProjectChange", null);
    __decorate([
        action.bound
    ], AppTasks.prototype, "_handlePrincipalChange", null);
    __decorate([
        action.bound
    ], AppTasks.prototype, "_handleTaskStateChange", null);
    AppTasks = __decorate([
        observer
    ], AppTasks);
    return AppTasks;
}(React.PureComponent));
