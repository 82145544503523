var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import styles from './comments-list.module.scss';
import HTMLReactParser from 'html-react-parser';
import { WithCopy } from '@app/Components/LinkWithCopy/LinkWithCopy';
import { DownloadButton, InactiveDownloadButton } from '@app/Components/DownloadButton/DownloadButton';
import { ProjectStateContext } from '../../../Pages/ProjectView/Components/ProjectStateContext';
var CommentsList = /** @class */ (function (_super) {
    __extends(CommentsList, _super);
    function CommentsList() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CommentsList.prototype.render = function () {
        var comments = this.props.comments;
        if (!comments)
            return null;
        var text = comments.map(function (c) { return "".concat(c.code ? c.code + ': ' : '').concat(c.comment || ''); }).join(' ');
        return (React.createElement(ProjectStateContext.Consumer, null, function (projectStateContext) {
            return (React.createElement(WithCopy, { text: text }, comments.map(function (comment, i) { return (React.createElement("div", { key: i, className: "".concat(styles.comment, " ").concat(comment.needAttention ? styles.red : '') },
                React.createElement("div", null,
                    comment.isDeliverable ? (comment.fileId ? (React.createElement(DownloadButton, { fileId: comment.fileId, className: styles.button, size: 15, hasRedDot: comment.hasRedDot && projectStateContext.showRedDotDeliverable })) : (React.createElement(InactiveDownloadButton, { className: styles.button, size: 15 }))) : null,
                    comment.code ? React.createElement("span", null,
                        comment.code,
                        ":") : null),
                React.createElement("div", { className: styles.commentText }, comment.text),
                React.createElement("div", null, HTMLReactParser(comment.comment || '')))); })));
        }));
    };
    return CommentsList;
}(React.PureComponent));
export { CommentsList };
