var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import styles from './comment-summary.module.scss';
import { observer } from 'mobx-react';
import { Link } from '@app/Components/Link/Link';
import { TooltipItem } from '@app/Components/Tooltip/Tooltip';
export var Comment = /** @class */ (function (_super) {
    __extends(Comment, _super);
    function Comment() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Comment.prototype.render = function () {
        var _a;
        var errorText = (_a = this.props, _a.errorText), codes = _a.codes, id = _a.id, link = _a.link;
        var cursorClass = codes && codes.length > 0 ? styles.pointerCursor : '';
        return (React.createElement("div", { className: styles.red },
            React.createElement("span", { className: "".concat(styles.redText, " ").concat(cursorClass), id: id }, errorText),
            codes && codes.length > 0 && (React.createElement(TooltipItem, { autohide: false, innerClassName: "tooltip-with-buttons", targetId: "".concat(id) },
                React.createElement("div", { className: styles.tooltip }, codes.map(function (item) {
                    return link ? (React.createElement(Link, { key: item.id, to: "".concat(link).concat(item.checkListId) }, item.code)) : (React.createElement("span", { key: item.id }, item.code));
                }))))));
    };
    Comment = __decorate([
        observer
    ], Comment);
    return Comment;
}(React.PureComponent));
