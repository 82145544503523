var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { Row, Col } from 'reactstrap';
import { observer } from 'mobx-react';
import Select from 'react-select';
import { action, computed, observable } from 'mobx';
import { AiFillDelete } from 'react-icons/ai';
import { ApprovalTaskTypeEnum } from '@app/AppConstants';
import { FunctionCell } from './FunctionCell';
export var FunctionRow = /** @class */ (function (_super) {
    __extends(FunctionRow, _super);
    function FunctionRow() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._selectedFunctionValue = null;
        _this._selectedWeekFromValue = null;
        _this._selectedWeekToValue = null;
        _this._selectedTypeValue = null;
        _this._originalRowInfo = null;
        return _this;
    }
    FunctionRow.prototype.componentDidMount = function () {
        this._refreshFunctionRanges();
    };
    FunctionRow.prototype.componentDidUpdate = function () {
        this._refreshFunctionRanges();
    };
    FunctionRow.prototype._refreshFunctionRanges = function () {
        var rowInfo = this.props.rowInfo;
        var currentFunction = rowInfo.currentFunction, weekFrom = rowInfo.weekFrom, weekTo = rowInfo.weekTo, type = rowInfo.type;
        var sameInfo = JSON.stringify(this._originalRowInfo) === JSON.stringify(rowInfo);
        if (sameInfo)
            return;
        this._originalRowInfo = rowInfo;
        var functionOption = currentFunction
            ? { value: '' + currentFunction.id, label: "".concat(currentFunction.name, " (").concat(currentFunction.code, ")") }
            : null;
        this._selectedFunctionValue = functionOption;
        var weekFromValue = weekFrom ? { value: '' + weekFrom, label: '' + weekFrom } : null;
        this._selectedWeekFromValue = weekFromValue;
        var weekToValue = weekTo ? { value: '' + weekTo, label: '' + weekTo } : null;
        this._selectedWeekToValue = weekToValue;
        var typeValue = type
            ? { value: '' + type, label: FunctionCell.renderApprovalType(type) }
            : null;
        this._selectedTypeValue = typeValue;
    };
    FunctionRow.prototype._handleFunctionChange = function (selectedValue) {
        this._selectedFunctionValue = selectedValue;
        var updatedRowInfo = Object.assign({}, this._originalRowInfo);
        var option = selectedValue;
        var currentFunction = null;
        if (option)
            currentFunction = this.props.functions.find(function (f) { return f.id === +option.value; }) || null;
        updatedRowInfo.currentFunction = currentFunction;
        this.props.onRowInfoChange(updatedRowInfo);
    };
    Object.defineProperty(FunctionRow.prototype, "functionOptions", {
        get: function () {
            var functions = this.props.functions;
            return functions.map(function (func) { return ({ value: func.id.toString(), label: "".concat(func.name, " (").concat(func.code, ")") }); });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FunctionRow.prototype, "weekOptions", {
        get: function () {
            var weekOptions = [-13, -12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0];
            return weekOptions.map(function (w) { return ({ value: '' + w, label: '' + w }); });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FunctionRow.prototype, "_typeOptions", {
        get: function () {
            var types = this.props.type === ApprovalTaskTypeEnum.Deliverable
                ? [
                    'ProvideDeliverableTask',
                    'DraftDeliverableTask',
                    'MoveDeliverableToInApprovalTask',
                    'ApprovalDeliverableTask'
                ]
                : ['MoveFunctionChecklistToInApprovalTask', 'ApprovalFunctionChecklistTask'];
            return types.map(function (t) { return ({ value: '' + t, label: FunctionCell.renderApprovalType(t) }); });
        },
        enumerable: false,
        configurable: true
    });
    FunctionRow.prototype._handleWeekChange = function (value, from) {
        var updatedRowInfo = Object.assign({}, this._originalRowInfo);
        var option = value;
        var updatedValue = option ? +option.value : null;
        if (from) {
            this._selectedWeekFromValue = value;
            updatedRowInfo.weekFrom = updatedValue;
        }
        else {
            this._selectedWeekToValue = value;
            updatedRowInfo.weekTo = updatedValue;
        }
        this.props.onRowInfoChange(updatedRowInfo);
    };
    FunctionRow.prototype._handleTypeChange = function (value) {
        var updatedRowInfo = Object.assign({}, this._originalRowInfo);
        var option = value;
        var updatedValue = option ? option.value : null;
        this._selectedTypeValue = value;
        updatedRowInfo.type = updatedValue;
        this.props.onRowInfoChange(updatedRowInfo);
    };
    FunctionRow.prototype.render = function () {
        var _a;
        var _this = this;
        var isCreation = (_a = this.props, _a.isCreation), onDelete = _a.onDelete;
        return (React.createElement(Row, { style: { margin: '10px 0' } },
            React.createElement(Col, null,
                React.createElement(Select, { placeholder: "Function", options: this.functionOptions, value: this._selectedFunctionValue, onChange: this._handleFunctionChange, isDisabled: !isCreation })),
            React.createElement(Col, null,
                React.createElement(Select, { placeholder: "Week from", options: this.weekOptions, value: this._selectedWeekFromValue, onChange: function (value) { return _this._handleWeekChange(value, true); } })),
            React.createElement(Col, null,
                React.createElement(Select, { placeholder: "Week to", options: this.weekOptions, value: this._selectedWeekToValue, onChange: function (value) { return _this._handleWeekChange(value); } })),
            React.createElement(Col, null,
                React.createElement(Select, { isDisabled: !isCreation, placeholder: "Type", options: this._typeOptions, value: this._selectedTypeValue, onChange: function (value) { return _this._handleTypeChange(value); } })),
            React.createElement(Col, null, !isCreation && (React.createElement(AiFillDelete, { size: "20", onClick: function () {
                    if (onDelete)
                        onDelete();
                } })))));
    };
    __decorate([
        observable
    ], FunctionRow.prototype, "_selectedFunctionValue", void 0);
    __decorate([
        observable
    ], FunctionRow.prototype, "_selectedWeekFromValue", void 0);
    __decorate([
        observable
    ], FunctionRow.prototype, "_selectedWeekToValue", void 0);
    __decorate([
        observable
    ], FunctionRow.prototype, "_selectedTypeValue", void 0);
    __decorate([
        observable
    ], FunctionRow.prototype, "_originalRowInfo", void 0);
    __decorate([
        action.bound
    ], FunctionRow.prototype, "_refreshFunctionRanges", null);
    __decorate([
        action.bound
    ], FunctionRow.prototype, "_handleFunctionChange", null);
    __decorate([
        computed
    ], FunctionRow.prototype, "functionOptions", null);
    __decorate([
        computed
    ], FunctionRow.prototype, "weekOptions", null);
    __decorate([
        computed
    ], FunctionRow.prototype, "_typeOptions", null);
    __decorate([
        action.bound
    ], FunctionRow.prototype, "_handleWeekChange", null);
    __decorate([
        action.bound
    ], FunctionRow.prototype, "_handleTypeChange", null);
    FunctionRow = __decorate([
        observer
    ], FunctionRow);
    return FunctionRow;
}(React.PureComponent));
