var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observable, action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { Api } from '@app/Api';
import { RequestCountersTable } from './RequestCountersTable';
import { DatePicker } from '../../../../Components/DatePicker';
import DateTimeService from '../../../../Services/DateTimeService';
import { CustomInput, FormGroup, Label } from 'reactstrap';
import styles from './RequestCounters.module.scss';
import { LineGraph } from '../../../../Components/Graphs/Line/LineGraph';
import { Accordion } from '../../../../Components/Accordion/Accordion';
import { AccordionTab } from '../../../../Components/Accordion/AccordionTab';
var ViewMode;
(function (ViewMode) {
    ViewMode["Table"] = "table";
    ViewMode["Graph"] = "graph";
})(ViewMode || (ViewMode = {}));
export var RequestCounters = /** @class */ (function (_super) {
    __extends(RequestCounters, _super);
    function RequestCounters(props) {
        var _this = _super.call(this, props) || this;
        _this._tableData = [];
        _this._graphData = [];
        _this._selectedViewMode = ViewMode.Table;
        _this._loadData = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this._selectedViewMode;
                        switch (_a) {
                            case ViewMode.Table: return [3 /*break*/, 1];
                            case ViewMode.Graph: return [3 /*break*/, 3];
                        }
                        return [3 /*break*/, 5];
                    case 1: return [4 /*yield*/, this._loadTableData()];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 6];
                    case 3: return [4 /*yield*/, this._loadGraphData()];
                    case 4:
                        _b.sent();
                        return [3 /*break*/, 6];
                    case 5: return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        _this._loadTableData = function () { return __awaiter(_this, void 0, void 0, function () {
            var res;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.getRequestStatistics({
                            dateTimeFrom: this._selectedDateFrom,
                            dateTimeTo: this._selectedDateTo
                        })];
                    case 1:
                        res = _a.sent();
                        runInAction(function () {
                            _this._tableData = res;
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        _this._loadGraphData = function () { return __awaiter(_this, void 0, void 0, function () {
            var res;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.getRequestStatisticsGraph({
                            dateTimeFrom: this._selectedDateFrom,
                            dateTimeTo: this._selectedDateTo,
                            xScaleUnit: 'Hour'
                        })];
                    case 1:
                        res = _a.sent();
                        runInAction(function () {
                            _this._graphData = res;
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        _this._onDateFromChangedHandle = function (date) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._selectedDateFrom = date;
                        return [4 /*yield*/, this._loadData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this._onDateToChangedHandle = function (date) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._selectedDateTo = date;
                        return [4 /*yield*/, this._loadData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this._onViewModeChangeHandler = function (viewMode) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._selectedViewMode = viewMode;
                        return [4 /*yield*/, this._loadData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var dateNow = DateTimeService.now();
        var startOfDay = DateTimeService.startOfDate(dateNow);
        _this._maxDateTime = DateTimeService.addTime(startOfDay, 24);
        _this._selectedDateFrom = startOfDay;
        _this._selectedDateTo = dateNow;
        return _this;
    }
    RequestCounters.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    RequestCounters.prototype.render = function () {
        var _this = this;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.line },
                React.createElement("div", { className: styles.picker },
                    React.createElement(Label, null, "Date from:"),
                    React.createElement(DatePicker, { defaultValue: this._selectedDateFrom, onDateChanged: this._onDateFromChangedHandle, max: this._maxDateTime, acceptCustomValue: false, type: "datetime", displayFormat: "dd MMM yyyy HH:mm", disabledDates: [] })),
                React.createElement("div", { className: styles.picker },
                    React.createElement(Label, null, "Date to:"),
                    React.createElement(DatePicker, { defaultValue: this._selectedDateTo, onDateChanged: this._onDateToChangedHandle, max: this._maxDateTime, acceptCustomValue: false, type: "datetime", displayFormat: "dd MMM yyyy HH:mm", disabledDates: [] })),
                React.createElement(FormGroup, { className: styles.radio },
                    React.createElement(CustomInput, { type: "radio", id: "rTable", name: "customRadio", label: "Table view", checked: this._selectedViewMode === ViewMode.Table, onChange: function () { return _this._onViewModeChangeHandler(ViewMode.Table); } }),
                    React.createElement(CustomInput, { type: "radio", id: "rGraph", name: "customRadio", label: "Graph view", checked: this._selectedViewMode === ViewMode.Graph, onChange: function () { return _this._onViewModeChangeHandler(ViewMode.Graph); } }))),
            this._selectedViewMode === ViewMode.Table && React.createElement(RequestCountersTable, { data: this._tableData }),
            this._selectedViewMode === ViewMode.Graph && (React.createElement(Accordion, null, this._graphData.map(function (x) {
                return (React.createElement(AccordionTab, { key: x.serverName, title: x.serverName, isOpenedByDefault: true },
                    React.createElement(LineGraph, { data: x.graphData, dateFrom: _this._selectedDateFrom, dateTo: _this._selectedDateTo, xAxisScale: 'Hour', yAxisTitle: "Number of requests", y1AxisTitle: "Average Response Time (ms) / Requests > 500 ms. (%)" })));
            })))));
    };
    __decorate([
        observable
    ], RequestCounters.prototype, "_maxDateTime", void 0);
    __decorate([
        observable
    ], RequestCounters.prototype, "_selectedDateFrom", void 0);
    __decorate([
        observable
    ], RequestCounters.prototype, "_selectedDateTo", void 0);
    __decorate([
        observable
    ], RequestCounters.prototype, "_tableData", void 0);
    __decorate([
        observable
    ], RequestCounters.prototype, "_graphData", void 0);
    __decorate([
        observable
    ], RequestCounters.prototype, "_selectedViewMode", void 0);
    __decorate([
        action
    ], RequestCounters.prototype, "_loadData", void 0);
    __decorate([
        action
    ], RequestCounters.prototype, "_loadTableData", void 0);
    __decorate([
        action
    ], RequestCounters.prototype, "_loadGraphData", void 0);
    __decorate([
        action
    ], RequestCounters.prototype, "_onDateFromChangedHandle", void 0);
    __decorate([
        action
    ], RequestCounters.prototype, "_onDateToChangedHandle", void 0);
    __decorate([
        action
    ], RequestCounters.prototype, "_onViewModeChangeHandler", void 0);
    RequestCounters = __decorate([
        observer
    ], RequestCounters);
    return RequestCounters;
}(React.PureComponent));
