var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { RoutedTabs } from 'react-router-tabs';
import styles from './TgTabs.module.scss';
var TgTabs = /** @class */ (function (_super) {
    __extends(TgTabs, _super);
    function TgTabs() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TgTabs.prototype.render = function () {
        var _a = this.props, startPathWith = _a.startPathWith, children = _a.children, customButtons = _a.customButtons;
        return (React.createElement(RoutedTabs, { startPathWith: startPathWith, tabClassName: styles.navTab, activeTabClassName: styles.active, className: styles.container },
            children,
            React.createElement("div", { style: { alignSelf: 'center', marginLeft: 'auto', display: 'flex' } }, customButtons === null || customButtons === void 0 ? void 0 : customButtons.map(function (b, i) { return (React.createElement("div", { key: i, className: styles.button }, b)); }))));
    };
    return TgTabs;
}(React.PureComponent));
export { TgTabs };
