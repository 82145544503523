var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import ErrorBoundary from '@app/Components/ErrorBoundary/ErrorBoundary';
import React from 'react';
import { AppConfiguration } from './Components/AppConfiguration/AppConfiguration';
import { OrgUnitConfiguration } from './Components/OrgUnitConfiguration/OrgUnitConfiguration';
var Configuration = /** @class */ (function (_super) {
    __extends(Configuration, _super);
    function Configuration() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Configuration.prototype.render = function () {
        return (React.createElement(ErrorBoundary, null,
            React.createElement(AppConfiguration, null),
            React.createElement("br", null),
            React.createElement(OrgUnitConfiguration, null)));
    };
    return Configuration;
}(React.PureComponent));
export { Configuration };
