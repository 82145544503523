var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import DateTimeService from '@app/Services/DateTimeService';
import { Select } from '../Select/Select';
import DeadlinePickerStore from './DeadlinePickerStore';
import { DatePicker } from '../DatePicker';
import styles from './DeadlinePicker.module.scss';
export var DeadlinePicker = /** @class */ (function (_super) {
    __extends(DeadlinePicker, _super);
    function DeadlinePicker(props) {
        var _this = _super.call(this, props) || this;
        _this._store = new DeadlinePickerStore();
        return _this;
    }
    DeadlinePicker.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._store.loadDeadlines()];
                    case 1:
                        _a.sent();
                        this.resetDefault();
                        return [2 /*return*/];
                }
            });
        });
    };
    DeadlinePicker.prototype.componentDidUpdate = function (prevProps) {
        var _a, _b;
        var prevVal = (_a = prevProps.value, _a.val), prevDate = _a.date;
        var val = (_b = this.props.value, _b.val), date = _b.date;
        if (prevVal !== val || prevDate !== date) {
            this.resetDefault();
        }
    };
    DeadlinePicker.prototype.resetDefault = function () {
        var _a;
        var val = (_a = this.props.value, _a.val), date = _a.date;
        this._store.setDateValue(date);
        var option = this._store.getOptionByValue(val);
        if (option)
            this._store.setSelectedOption(option);
    };
    DeadlinePicker.prototype._handleDateChange = function (value) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this._store.setDateValue(value);
                        return [4 /*yield*/, ((_b = (_a = this.props).onValueChanged) === null || _b === void 0 ? void 0 : _b.call(_a, this._store.value, this._store.dateValue))];
                    case 1:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DeadlinePicker.prototype._handleSelectChange = function (selectedOption) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var value;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        value = selectedOption.value;
                        if (value === 'ByDate') {
                            this._store.setDateValue(DateTimeService.addDays(DateTimeService.now(), 7));
                        }
                        else {
                            this._store.setDateValue(null);
                        }
                        this._store.setSelectedOption(selectedOption);
                        return [4 /*yield*/, ((_b = (_a = this.props).onValueChanged) === null || _b === void 0 ? void 0 : _b.call(_a, this._store.value, this._store.dateValue))];
                    case 1:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DeadlinePicker.prototype.render = function () {
        var _a, _b;
        var deadlines = (_a = this._store, _a.deadlines), value = _a.value, dateValue = _a.dateValue, showDatePicker = _a.showDatePicker;
        var disabled = (_b = this.props, _b.disabled), datePickerVisible = _b.datePickerVisible, deadlineTypePickerVisible = _b.deadlineTypePickerVisible, toggleIgnoreDetection = _b.toggleIgnoreDetection;
        return (React.createElement("div", { className: styles.wrapper, style: { flexDirection: toggleIgnoreDetection ? 'column' : 'row' } },
            deadlineTypePickerVisible && (React.createElement("div", { className: styles.select },
                React.createElement(Select, { styles: {
                        menuPortal: function (base) { return (__assign(__assign({}, base), { zIndex: 1050 })); },
                        input: function (base) { return (__assign(__assign({}, base), { lineHeight: '20px' })); }
                    }, options: deadlines, onMenuOpen: function () { return toggleIgnoreDetection && toggleIgnoreDetection(true); }, onMenuClose: function () { return toggleIgnoreDetection && toggleIgnoreDetection(false); }, value: value, onChange: this._handleSelectChange, menuPortalTarget: document.body, menuPosition: 'absolute', isDisabled: disabled }))),
            datePickerVisible && showDatePicker && (React.createElement("div", { className: styles.dateSelect, style: { margin: toggleIgnoreDetection ? '5px 0 0 0' : '0 0 0 5px' } },
                React.createElement(DatePicker, { value: dateValue || undefined, onOpened: function () { return toggleIgnoreDetection && toggleIgnoreDetection(true); }, onClosed: function () { return toggleIgnoreDetection && toggleIgnoreDetection(false); }, onDateChanged: this._handleDateChange, displayFormat: "dd MMM yyyy", type: "date", disabled: disabled })))));
    };
    __decorate([
        action.bound
    ], DeadlinePicker.prototype, "_handleDateChange", null);
    __decorate([
        action.bound
    ], DeadlinePicker.prototype, "_handleSelectChange", null);
    DeadlinePicker = __decorate([
        observer
    ], DeadlinePicker);
    return DeadlinePicker;
}(React.PureComponent));
