var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Button } from '@app/Components/Button/Button';
import { Grid } from '@app/Components/Grid/Grid';
import { Column } from 'devextreme-react/data-grid';
import { FaTrash } from 'react-icons/fa';
import { DateBox } from '@app/Widgets/DateBox/DateBox';
import styles from './DocumentsGrid.module.scss';
import { DownloadButton } from '@app/Components/DownloadButton/DownloadButton';
import { CopyToClipBoardButton } from '@app/Components/CopyToClipboardButton/CopyToClipboardButton';
import { ChecklistSections, ComponentUrls, ObjectType } from '@app/AppConstants';
import { ProjectStateContext } from '../../../Pages/ProjectView/Components/ProjectStateContext';
import { EditCell } from '@app/Components/Grid/EditCell/EditCell';
import columnSharedStyles from '@app/Components/Grid/SharedStyles/column-shared-styles.module.scss';
import { createUniqueId } from '@app/Helpers/UidHelper/UidHelper';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import { Api } from '@app/Api';
export var DocumentsGrid = /** @class */ (function (_super) {
    __extends(DocumentsGrid, _super);
    function DocumentsGrid() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DocumentsGrid.prototype._saveFileComment = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            var updateModel;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        updateModel = {
                            checkListItemId: rowData.checkListItemId,
                            fileInformationId: rowData.fileId,
                            comment: rowData.fileComment
                        };
                        return [4 /*yield*/, Api.secured.updateChecklistItemDeliverableFileComment({ body: updateModel })];
                    case 1:
                        _a.sent();
                        this.props.onFileCommentUpdate();
                        return [2 /*return*/];
                }
            });
        });
    };
    DocumentsGrid.prototype.render = function () {
        var _a;
        var _this = this;
        var documents = (_a = this.props, _a.documents), displayDocuments = _a.displayDocuments, canUpdateFileComment = _a.canUpdateFileComment, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted, onDocumentDelete = _a.onDocumentDelete;
        return (displayDocuments && (React.createElement(ProjectStateContext.Consumer, null, function (projectStateContext) {
            return (React.createElement(Grid, { dataSource: documents },
                React.createElement(Column, { caption: "Name", minWidth: 200, dataField: "name" }),
                React.createElement(Column, { caption: "Original File Name", minWidth: 200, dataField: "originalFileName" }),
                React.createElement(Column, { cssClass: columnSharedStyles.commentColumn, caption: "Comment", width: 300, dataField: "FileComment", cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(EditCell, { value: data.fileComment, onChange: function (value) { return (data.fileComment = value); }, onSave: function () { return _this._saveFileComment(data); }, objectUniqueId: createUniqueId(ObjectType.FileInformation, data.fileId), section: ChecklistSections.DeliverableFileComments, disabled: !canUpdateFileComment || isProjectTerminatedOrCompleted }));
                    } }),
                React.createElement(Column, { caption: "Uploaded Date", dataField: "uploadedDate", dataType: "date", width: 150, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(DateBox, { date: data.uploadedDate }));
                    } }),
                React.createElement(Column, { caption: "Uploaded By", dataField: "uploadedBy", width: 150 }),
                React.createElement(Column, { caption: "Status", dataField: "status", width: 150 }),
                React.createElement(Column, { caption: "Uploaded on Gate", dataField: "gateDisplayName", width: 100 }),
                React.createElement(Column, { caption: "Version", dataField: "version", width: 100 }),
                React.createElement(Column, { caption: "Action", width: 300, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("div", { className: styles.actions },
                            React.createElement(DownloadButton, { fileId: data.fileId, className: styles.action, hasRedDot: data.hasRedDot && projectStateContext.showRedDotDeliverable }),
                            data.canPrincipalDeleteDocument && data.status === 'Draft' && (React.createElement(Button, { className: styles.action, color: "danger", size: "xs", disabled: isProjectTerminatedOrCompleted, onClick: function () { return onDocumentDelete(data.version, data.checkListItemId); } },
                                React.createElement(FaTrash, null))),
                            React.createElement(CopyToClipBoardButton, { text: location.origin + ComponentUrls.DocumentUrl + data.fileId, copyText: "Copy file link to clipboard" })));
                    } })));
        })));
    };
    __decorate([
        action.bound
    ], DocumentsGrid.prototype, "_saveFileComment", null);
    DocumentsGrid = __decorate([
        observer
    ], DocumentsGrid);
    return DocumentsGrid;
}(React.PureComponent));
