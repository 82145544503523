var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import styles from './ApprovedChecklistViewDescription.module.scss';
import { Button } from '@app/Components/Button/Button';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { ChecklistApproversModal } from '@app/Pages/ProjectView/ProjectViewTabs/GateDeliverables/ChecklistsTable/ChecklistApproversModal';
import { ApproveDenyChecklistButtons } from '@app/Pages/ProjectView/ProjectViewTabs//GateDeliverables/ChecklistsTable/ApproveDenyChecklistButtons';
import { ValidationHint } from '@app/Components/ValidationHint/ValidationHint';
import { Api } from '@app/Api';
import { CommentModal } from '@app/Widgets/CommentModal/CommentModal';
import { CompletionType, PromiseCompletion } from '@app/Classes/PromiseCompletion';
import { Loader } from '@app/Components/Loader/Loader';
import { Events, Notifications } from '@app/AppConstants';
import { ConfirmButton } from '@app/Components/ConfirmButton/ConfirmButton';
import { CopyChecklistCommentsButton } from '@app/Components/CopyChecklistCommentsButton/CopyChecklistCommentsButton';
import { ConfirmationDialog } from '@app/Widgets/ConfirmationDialog/ConfirmationDialog';
import { LoadingContext } from '@app/Context/LoadingContext';
var Role;
(function (Role) {
    Role[Role["PJM"] = 0] = "PJM";
    Role[Role["GA"] = 1] = "GA";
})(Role || (Role = {}));
export var ApprovedChecklistViewDescription = /** @class */ (function (_super) {
    __extends(ApprovedChecklistViewDescription, _super);
    function ApprovedChecklistViewDescription() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isApproversModalOpen = false;
        _this._isUnapproveChecklistModalOpen = false;
        _this._isDenyCommentModalOpen = false;
        _this._checkListInfo = null;
        _this._denyItem = null;
        _this._denyApprovalId = undefined;
        _this.isLoading = new PromiseCompletion(CompletionType.Completed);
        _this._isConfirmationModalOpen = false;
        _this._isCopyCommentsConfirmationModalOpen = false;
        _this._checklistToCopyCommentsFrom = null;
        _this._currentCheckListId = null;
        _this._currentRole = null;
        _this.refreshChecklistInfo = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    ApprovedChecklistViewDescription.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistViewDescription.prototype._loadData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var checklistId, promise, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        checklistId = this.props.checklistId;
                        promise = Api.secured.getChecklistInfo({ id: checklistId });
                        this.isLoading.subscribe(promise);
                        _a = this;
                        return [4 /*yield*/, promise];
                    case 1:
                        _a._checkListInfo = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistViewDescription.prototype._showApprovers = function () {
        this._toggleApproversModal();
    };
    ApprovedChecklistViewDescription.prototype._toggleApproversModal = function () {
        this._isApproversModalOpen = !this._isApproversModalOpen;
    };
    ApprovedChecklistViewDescription.prototype._handleInApproveClick = function (checkListId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._handlePrepare(checkListId)];
                    case 1:
                        _a.sent();
                        this.props.onStateChanged();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistViewDescription.prototype._handlePrepare = function (checkListId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.prepareFunctionChecklist(checkListId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadData()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistViewDescription.prototype._handleUndoApproval = function (comment) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.undoChecklistApproval(this.props.checklistId, { comment: comment })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadData()];
                    case 2:
                        _a.sent();
                        this.props.onStateChanged();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistViewDescription.prototype._handleDeny = function (comment) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 3, 5]);
                        if (!(this._denyItem && this._denyApprovalId)) return [3 /*break*/, 2];
                        return [4 /*yield*/, Api.secured.denyFunctionChecklist(this._denyItem.id, {
                                approvalId: this._denyApprovalId,
                                comment: comment
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this._denyItem = null;
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.refreshChecklistInfo()];
                    case 4:
                        _a.sent();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistViewDescription.prototype._toggleUnapproveChecklistModalOpen = function () {
        this._isUnapproveChecklistModalOpen = !this._isUnapproveChecklistModalOpen;
    };
    ApprovedChecklistViewDescription.prototype._toggleDenyCommentModalOpen = function () {
        this._isDenyCommentModalOpen = !this._isDenyCommentModalOpen;
        if (!this._isDenyCommentModalOpen)
            this._denyItem = null;
    };
    ApprovedChecklistViewDescription.prototype._onApprovalChangedHandler = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._isApproversModalOpen = !this._isApproversModalOpen;
                        return [4 /*yield*/, this._loadData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistViewDescription.prototype._toggleConfirmationModalOpen = function () {
        this._isConfirmationModalOpen = !this._isConfirmationModalOpen;
    };
    ApprovedChecklistViewDescription.prototype._toggleCopyCommentsConfirmationModalOpen = function () {
        this._isCopyCommentsConfirmationModalOpen = !this._isCopyCommentsConfirmationModalOpen;
    };
    ApprovedChecklistViewDescription.prototype._onChecklistToCopyCommentsFromClick = function (checklist) {
        this._checklistToCopyCommentsFrom = checklist;
        this._isCopyCommentsConfirmationModalOpen = true;
    };
    ApprovedChecklistViewDescription.prototype._copyComments = function () {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (!this._checklistToCopyCommentsFrom) {
                            return [2 /*return*/];
                        }
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, , 3, 4]);
                        (_b = (_a = this.context).setLoading) === null || _b === void 0 ? void 0 : _b.call(_a, true);
                        return [4 /*yield*/, Api.secured.copyChecklistFunctionComments({
                                fromChecklistId: this._checklistToCopyCommentsFrom.id,
                                toChecklistId: this.props.checklistId
                            })];
                    case 2:
                        _e.sent();
                        document.dispatchEvent(new Event(Events.deliverablesConfirm));
                        document.dispatchEvent(new Event(Events.questionsConfirm));
                        this._toggleCopyCommentsConfirmationModalOpen();
                        return [3 /*break*/, 4];
                    case 3:
                        (_d = (_c = this.context).setLoading) === null || _d === void 0 ? void 0 : _d.call(_c, false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistViewDescription.prototype._handleSaveOkClick = function (checkListId, role) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._currentRole = role;
                this._currentCheckListId = checkListId;
                this._toggleConfirmationModalOpen();
                return [2 /*return*/];
            });
        });
    };
    ApprovedChecklistViewDescription.prototype._saveOkPMPart = function () {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (!this._currentCheckListId) {
                            return [2 /*return*/];
                        }
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, , 3, 4]);
                        (_b = (_a = this.context).setLoading) === null || _b === void 0 ? void 0 : _b.call(_a, true);
                        return [4 /*yield*/, Api.secured.okPrjManagerPartGatePreparationItems({ checkListId: this._currentCheckListId })];
                    case 2:
                        _e.sent();
                        document.dispatchEvent(new Event(Events.deliverablesConfirm));
                        document.dispatchEvent(new Event(Events.questionsConfirm));
                        this._toggleConfirmationModalOpen();
                        return [3 /*break*/, 4];
                    case 3:
                        (_d = (_c = this.context).setLoading) === null || _d === void 0 ? void 0 : _d.call(_c, false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistViewDescription.prototype._saveOkGAPart = function () {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (!this._currentCheckListId) {
                            return [2 /*return*/];
                        }
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, , 3, 4]);
                        (_b = (_a = this.context).setLoading) === null || _b === void 0 ? void 0 : _b.call(_a, true);
                        return [4 /*yield*/, Api.secured.okAssessorPartGatePreparationItems({ checkListId: this._currentCheckListId })];
                    case 2:
                        _e.sent();
                        document.dispatchEvent(new Event(Events.deliverablesConfirm));
                        document.dispatchEvent(new Event(Events.questionsConfirm));
                        this._toggleConfirmationModalOpen();
                        return [3 /*break*/, 4];
                    case 3:
                        (_d = (_c = this.context).setLoading) === null || _d === void 0 ? void 0 : _d.call(_c, false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistViewDescription.prototype.render = function () {
        var _a;
        var _this = this;
        var _checkListInfo = (_a = this, _a._checkListInfo), isLoading = _a.isLoading;
        var isProjectTerminatedOrCompleted = this.props.isProjectTerminatedOrCompleted;
        if (!_checkListInfo && isLoading.isPending) {
            return React.createElement(Loader, { style: { width: 20, height: 20 } });
        }
        return (_checkListInfo && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.line },
                React.createElement("div", { className: styles.description },
                    React.createElement("div", { className: styles.itemSmall },
                        React.createElement("div", { className: styles.title }, "Checklist Code"),
                        React.createElement("div", { className: styles.data }, _checkListInfo.code)),
                    React.createElement("div", { className: styles.itemSmall },
                        React.createElement("div", { className: styles.title }, "Checklist Description"),
                        React.createElement("div", { className: styles.data }, _checkListInfo.name)),
                    React.createElement("div", { className: styles.itemLarge },
                        React.createElement("div", { className: styles.title }, "Checklist Project"),
                        React.createElement("div", { className: styles.data },
                            _checkListInfo.projectCode,
                            "/ ",
                            _checkListInfo.projectName)),
                    React.createElement("div", { className: styles.itemExtraSmall },
                        React.createElement("div", { className: styles.title }, "Checklist Status"),
                        React.createElement("div", { className: styles.data }, _checkListInfo.status))),
                React.createElement("div", { className: styles.actionButtons },
                    !_checkListInfo.isMaster && (React.createElement(React.Fragment, null,
                        !this._isApproversModalOpen &&
                            !this.isLoading.isPending &&
                            _checkListInfo.canCheckListBeApproved &&
                            _checkListInfo.canPrincipalImmediateApproveChecklist && (React.createElement(ApproveDenyChecklistButtons, { checklistId: _checkListInfo.id, checklistApproverId: _checkListInfo.canPrincipalImmediateApproveChecklist
                                ? null
                                : _checkListInfo.approvalId || null, canApproveWithoutApprover: _checkListInfo.canPrincipalImmediateApproveChecklist, isApproveDisabled: this._isApproversModalOpen || this.isLoading.isPending, onDenyHandler: function () {
                                _this._toggleDenyCommentModalOpen();
                                _this._denyItem = _checkListInfo;
                                _this._denyApprovalId = _checkListInfo.approvalId;
                            }, onApproveHandler: function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.refreshChecklistInfo()];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); }, parentClassName: styles.actionButtons, disabled: isProjectTerminatedOrCompleted })),
                        _checkListInfo.canPrincipalUpdatePrjManagerPart && (React.createElement("div", { className: styles.confirmButton },
                            React.createElement(ConfirmButton, { id: 'tooltipPrj' + this.props.checklistId, tooltipText: "Rate ALL Deliverables and Questions green and set comments to 'OK' as PjM (missing data only)", onClick: function () { return _this._handleSaveOkClick(_checkListInfo.id, Role.PJM); }, disabled: isProjectTerminatedOrCompleted }))),
                        _checkListInfo.canPrincipalUpdateAssessorPart && (React.createElement("div", { className: styles.confirmButton },
                            React.createElement(ConfirmButton, { id: 'tooltipGa' + this.props.checklistId, tooltipText: "Rate ALL Deliverables and Questions green and set comments to 'OK' as GA (missing data only)", onClick: function () { return _this._handleSaveOkClick(_checkListInfo.id, Role.GA); }, disabled: isProjectTerminatedOrCompleted }))),
                        React.createElement(Button, { color: _checkListInfo.hasApprovalWarning ? 'danger' : 'success', size: "xs", className: styles.action, onClick: function () { return _this._showApprovers(); } }, "A"),
                        React.createElement(ChecklistApproversModal, { isOpen: this._isApproversModalOpen, toggle: this._toggleApproversModal, onApprovalChanged: this._onApprovalChangedHandler, checklist: {
                                id: _checkListInfo === null || _checkListInfo === void 0 ? void 0 : _checkListInfo.id,
                                uniqueId: _checkListInfo === null || _checkListInfo === void 0 ? void 0 : _checkListInfo.uniqueId,
                                status: _checkListInfo === null || _checkListInfo === void 0 ? void 0 : _checkListInfo.status,
                                canCheckListBeApproved: _checkListInfo === null || _checkListInfo === void 0 ? void 0 : _checkListInfo.canCheckListBeApproved
                            }, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted }),
                        React.createElement(CommentModal, { isOpen: this._isDenyCommentModalOpen, onToggle: this._toggleDenyCommentModalOpen, onSave: this._handleDeny }))),
                    _checkListInfo.relatedChecklistsFromSplittedGates &&
                        _checkListInfo.relatedChecklistsFromSplittedGates.length > 0 && (React.createElement(React.Fragment, null,
                        React.createElement(CopyChecklistCommentsButton, { checklists: _checkListInfo.relatedChecklistsFromSplittedGates, onChecklistClick: this._onChecklistToCopyCommentsFromClick }),
                        React.createElement(ConfirmationDialog, { isOpen: this._isCopyCommentsConfirmationModalOpen, onToggle: this._toggleCopyCommentsConfirmationModalOpen, headerTitle: "Confirmation", onSave: this._copyComments, body: 'All Function comments from the selected Checklist will be copied to the current Checklist. Attention: only non-empty comments will be copied for Questions and Deliverables in Draft state, existent comments will be overwritten. Do you want to continue?' }))),
                    _checkListInfo.canPrincipalSetInApprovalChecklist && (React.createElement(React.Fragment, null,
                        React.createElement("div", { style: { marginRight: 5 } },
                            React.createElement(ValidationHint, { id: 'del' + _checkListInfo.id, isValid: _checkListInfo.isValid, errors: [Notifications.ErrorForInvalidReadyForApprovalCheckListString] })),
                        React.createElement(Button, { className: styles.action, size: "sm", color: "success", onClick: function () { return _this._handleInApproveClick(_checkListInfo.id); }, disabled: !_checkListInfo.isValid || isProjectTerminatedOrCompleted }, "Ready for Approval"))),
                    _checkListInfo.canPrincipalSetToDraftChecklist && (React.createElement(React.Fragment, null,
                        React.createElement(Button, { className: styles.action, size: "sm", color: "success", onClick: this._toggleUnapproveChecklistModalOpen, disabled: isProjectTerminatedOrCompleted }, "Undo Approval"),
                        React.createElement(CommentModal, { isOpen: this._isUnapproveChecklistModalOpen, onToggle: this._toggleUnapproveChecklistModalOpen, onSave: this._handleUndoApproval }))))),
            React.createElement(ConfirmationDialog, { isOpen: this._isConfirmationModalOpen, onToggle: this._toggleConfirmationModalOpen, headerTitle: "Rate ALL Deliverables and Questions green and set comments to 'OK'", onSave: this._currentRole === Role.PJM ? this._saveOkPMPart : this._saveOkGAPart, body: this._currentRole === Role.PJM
                    ? "Are you sure you want to provide PjM input as 'OK' comment and green Rating for all completed by Function Deliverables and Questions, where input is missing ('OK' comment will be set only for the cases of green or missing Rating)?"
                    : "Are you sure you want to provide Gate Assessor input as 'OK' comment and green Rating for all completed by Function Deliverables and Questions, where input is missing ('OK' comment will be set only for the cases of green or missing Rating)?" }))));
    };
    ApprovedChecklistViewDescription.contextType = LoadingContext;
    __decorate([
        observable
    ], ApprovedChecklistViewDescription.prototype, "_isApproversModalOpen", void 0);
    __decorate([
        observable
    ], ApprovedChecklistViewDescription.prototype, "_isUnapproveChecklistModalOpen", void 0);
    __decorate([
        observable
    ], ApprovedChecklistViewDescription.prototype, "_isDenyCommentModalOpen", void 0);
    __decorate([
        observable
    ], ApprovedChecklistViewDescription.prototype, "_checkListInfo", void 0);
    __decorate([
        observable
    ], ApprovedChecklistViewDescription.prototype, "_denyItem", void 0);
    __decorate([
        observable
    ], ApprovedChecklistViewDescription.prototype, "_denyApprovalId", void 0);
    __decorate([
        observable
    ], ApprovedChecklistViewDescription.prototype, "isLoading", void 0);
    __decorate([
        observable
    ], ApprovedChecklistViewDescription.prototype, "_isConfirmationModalOpen", void 0);
    __decorate([
        observable
    ], ApprovedChecklistViewDescription.prototype, "_isCopyCommentsConfirmationModalOpen", void 0);
    __decorate([
        observable
    ], ApprovedChecklistViewDescription.prototype, "_checklistToCopyCommentsFrom", void 0);
    __decorate([
        observable
    ], ApprovedChecklistViewDescription.prototype, "_currentCheckListId", void 0);
    __decorate([
        observable
    ], ApprovedChecklistViewDescription.prototype, "_currentRole", void 0);
    __decorate([
        action.bound
    ], ApprovedChecklistViewDescription.prototype, "_loadData", null);
    __decorate([
        action.bound
    ], ApprovedChecklistViewDescription.prototype, "_showApprovers", null);
    __decorate([
        action.bound
    ], ApprovedChecklistViewDescription.prototype, "_toggleApproversModal", null);
    __decorate([
        action.bound
    ], ApprovedChecklistViewDescription.prototype, "_handleInApproveClick", null);
    __decorate([
        action.bound
    ], ApprovedChecklistViewDescription.prototype, "_handlePrepare", null);
    __decorate([
        action.bound
    ], ApprovedChecklistViewDescription.prototype, "_handleUndoApproval", null);
    __decorate([
        action.bound
    ], ApprovedChecklistViewDescription.prototype, "_handleDeny", null);
    __decorate([
        action.bound
    ], ApprovedChecklistViewDescription.prototype, "_toggleUnapproveChecklistModalOpen", null);
    __decorate([
        action.bound
    ], ApprovedChecklistViewDescription.prototype, "_toggleDenyCommentModalOpen", null);
    __decorate([
        action.bound
    ], ApprovedChecklistViewDescription.prototype, "_onApprovalChangedHandler", null);
    __decorate([
        action.bound
    ], ApprovedChecklistViewDescription.prototype, "_toggleConfirmationModalOpen", null);
    __decorate([
        action.bound
    ], ApprovedChecklistViewDescription.prototype, "_toggleCopyCommentsConfirmationModalOpen", null);
    __decorate([
        action.bound
    ], ApprovedChecklistViewDescription.prototype, "_onChecklistToCopyCommentsFromClick", null);
    __decorate([
        action.bound
    ], ApprovedChecklistViewDescription.prototype, "_copyComments", null);
    __decorate([
        action.bound
    ], ApprovedChecklistViewDescription.prototype, "_handleSaveOkClick", null);
    __decorate([
        action.bound
    ], ApprovedChecklistViewDescription.prototype, "_saveOkPMPart", null);
    __decorate([
        action.bound
    ], ApprovedChecklistViewDescription.prototype, "_saveOkGAPart", null);
    ApprovedChecklistViewDescription = __decorate([
        observer
    ], ApprovedChecklistViewDescription);
    return ApprovedChecklistViewDescription;
}(React.PureComponent));
