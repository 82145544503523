var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import DateTimeService from '@app/Services/DateTimeService';
import { DeadlinePicker } from '@app/Components/DeadlinePicker/DeadlinePicker';
import { ConfirmationCellWrapper } from '@app/Components/Grid/ConfirmationCellWrapper/ConfirmationCellWrapper';
import { ActionItemDeadLineEnum } from '@app/AppConstants';
export var DeadlineEditCell = /** @class */ (function (_super) {
    __extends(DeadlineEditCell, _super);
    function DeadlineEditCell() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._value = ActionItemDeadLineEnum.Asap;
        _this._originalValue = ActionItemDeadLineEnum.Asap;
        return _this;
    }
    DeadlineEditCell.prototype.componentDidMount = function () {
        var _a;
        var value = (_a = this.props, _a.value), dateValue = _a.dateValue;
        this._value = value || ActionItemDeadLineEnum.ByDate;
        this._originalValue = this._value;
        this._dateValue = dateValue;
        this._originaDatelValue = dateValue;
    };
    DeadlineEditCell.prototype.render = function () {
        var _a;
        var _this = this;
        var objectUniqueId = (_a = this.props, _a.objectUniqueId), disabled = _a.disabled, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
        var dateChanged = this._dateValue !== this._originaDatelValue;
        var typeChanged = this._value !== this._originalValue;
        var hasChanges = typeChanged || dateChanged;
        var correctByDateValue = true;
        if (this._value === ActionItemDeadLineEnum.ByDate && !this._dateValue)
            correctByDateValue = false;
        return (React.createElement(ConfirmationCellWrapper, { onSave: this._handleSave, onCancel: this._handleCancel, objectUniqueId: objectUniqueId, disabled: disabled, valueChanged: hasChanges && correctByDateValue, ignoreDetection: true, rowIndex: rowIndex, columnIndex: columnIndex }, function (isEditing, toggleIgnoreDetection) { return (React.createElement(DeadlinePicker, { value: { val: _this._value, date: _this._dateValue || null }, disabled: !isEditing, onValueChanged: _this._onDeadlineSelect, toggleIgnoreDetection: toggleIgnoreDetection, datePickerVisible: true, deadlineTypePickerVisible: isEditing || _this._value !== ActionItemDeadLineEnum.ByDate })); }));
    };
    DeadlineEditCell.prototype._onDeadlineSelect = function (option, date) {
        return __awaiter(this, void 0, void 0, function () {
            var prevValue;
            return __generator(this, function (_a) {
                prevValue = this._value;
                this._value = option.value;
                this._dateValue = date || undefined;
                if (prevValue !== option.value && option.value === 'ByDate') {
                    this._dateValue = DateTimeService.addDays(DateTimeService.now(), 7);
                }
                return [2 /*return*/];
            });
        });
    };
    DeadlineEditCell.prototype._handleSave = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._value && this.props.onDeadlineTypeChange(this._value, this._dateValue);
                        this._originalValue = this._value;
                        this._originaDatelValue = this._dateValue;
                        return [4 /*yield*/, this.props.onSave()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DeadlineEditCell.prototype._handleCancel = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._value = this._originalValue;
                this._dateValue = this._originaDatelValue;
                return [2 /*return*/];
            });
        });
    };
    __decorate([
        observable
    ], DeadlineEditCell.prototype, "_value", void 0);
    __decorate([
        observable
    ], DeadlineEditCell.prototype, "_originalValue", void 0);
    __decorate([
        observable
    ], DeadlineEditCell.prototype, "_dateValue", void 0);
    __decorate([
        observable
    ], DeadlineEditCell.prototype, "_originaDatelValue", void 0);
    __decorate([
        action.bound
    ], DeadlineEditCell.prototype, "_onDeadlineSelect", null);
    __decorate([
        action.bound
    ], DeadlineEditCell.prototype, "_handleSave", null);
    __decorate([
        action.bound
    ], DeadlineEditCell.prototype, "_handleCancel", null);
    DeadlineEditCell = __decorate([
        observer
    ], DeadlineEditCell);
    return DeadlineEditCell;
}(React.Component));
