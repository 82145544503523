/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as msRest from "@azure/ms-rest-js";
var packageName = "";
var packageVersion = "0.1.0";
var TeamGateApiUnsecuredContext = /** @class */ (function (_super) {
    __extends(TeamGateApiUnsecuredContext, _super);
    /**
     * Initializes a new instance of the TeamGateApiUnsecuredContext class.
     * @param [options] The parameter options
     */
    function TeamGateApiUnsecuredContext(options) {
        var _this = this;
        if (!options) {
            options = {};
        }
        if (!options.userAgent) {
            var defaultUserAgent = msRest.getDefaultUserAgentValue();
            options.userAgent = "".concat(packageName, "/").concat(packageVersion, " ").concat(defaultUserAgent);
        }
        _this = _super.call(this, undefined, options) || this;
        _this.baseUri = options.baseUri || _this.baseUri || "http://localhost";
        _this.requestContentType = "application/json; charset=utf-8";
        return _this;
    }
    return TeamGateApiUnsecuredContext;
}(msRest.ServiceClient));
export { TeamGateApiUnsecuredContext };
