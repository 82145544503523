var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { action, observable } from 'mobx';
import { AssessmentGateDeliversTable } from './AssesmentGateDeliversTable/AssessmentGateDeliversTable';
import { AssessmentGateFunctionSummariesTable } from './AssesmentGateFunctionSummariesTable/AssessmentGateFunctionSummariesTable';
import { AssesmentGateExecutiveQuestionsTable } from './AssesmentGateExecutiveQuestionsTable/AssesmentGateExecutiveQuestionsTable';
import styles from './AssessmentGateView.module.scss';
import { observer } from 'mobx-react';
import { DocumentView } from '@app/Widgets/DocumentView/DocumentView';
import ActionItemsTable from '../../Components/ActionItemsTable/ActionItemsTable';
import { GateProcessTypeEnum } from '@app/AppConstants';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { FunctionFilterContext } from '@app/Components/FunctonFilter/FunctionFilterContext';
import { FunctionFilter } from '@app/Components/FunctonFilter/FunctionFilter';
import { Api } from '@app/Api';
import { RatingFilterAssessment } from '@app/Components/RatingFilterAssessment/RatingFilterAssessment';
import { RatingFilterAssessmentContext } from '@app/Components/RatingFilterAssessment/RatingFilterAssessmentContext';
import { ProjectStateContext } from '@app/Pages/ProjectView/Components/ProjectStateContext';
import { Modal } from '@app/Components/Modal/Modal';
import { ApprovedChecklistView } from '@app/Pages/ProjectView/ProjectViewTabs/GatePreparations/ApprovedGateView/ApprovedChecklistView/ApprovedChecklistView';
var AssessmentGateViewC = /** @class */ (function (_super) {
    __extends(AssessmentGateViewC, _super);
    function AssessmentGateViewC() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isFilesChanged = false;
        _this._checklists = [];
        _this._loading = false;
        _this._documentViewSection = '';
        _this._assessmentGateDeliversRef = React.createRef();
        _this._assessmentGateFunctionSummariesTableRef = React.createRef();
        _this._assesmentGateExecutiveQuestionsTableRef = React.createRef();
        _this.refreshActionItems = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                document.dispatchEvent(new Event('refreshActionItems'));
                return [2 /*return*/];
            });
        }); };
        return _this;
    }
    AssessmentGateViewC.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadChecklists()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AssessmentGateViewC.prototype._handleDocumentClick = function (ciid, section) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._documentViewSection = section;
                this.props.history.push("".concat(this.props.match.url, "/upload/cl-").concat(ciid));
                return [2 /*return*/];
            });
        });
    };
    AssessmentGateViewC.prototype._loadChecklists = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, projectId, gateId, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this.props, projectId = _a.projectId, gateId = _a.gateId;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, , 3, 4]);
                        this._loading = true;
                        _b = this;
                        return [4 /*yield*/, Api.secured.getFunctionalOnlyChecklists({
                                projectId: projectId,
                                gateId: gateId,
                                hideNAItems: this.context.hideNAItems
                            })];
                    case 2:
                        _b._checklists = _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this._loading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AssessmentGateViewC.prototype._toggleChecklistsModal = function () {
        var _a, _b, _c, _d, _e, _f;
        return __awaiter(this, void 0, void 0, function () {
            var _g, history, match;
            return __generator(this, function (_h) {
                switch (_h.label) {
                    case 0:
                        _g = this.props, history = _g.history, match = _g.match;
                        return [4 /*yield*/, Promise.all([
                                (_b = (_a = this._assessmentGateDeliversRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.reloadData(),
                                (_d = (_c = this._assessmentGateFunctionSummariesTableRef) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 : _d.reloadData(),
                                (_f = (_e = this._assesmentGateExecutiveQuestionsTableRef) === null || _e === void 0 ? void 0 : _e.current) === null || _f === void 0 ? void 0 : _f.reloadData()
                            ])];
                    case 1:
                        _h.sent();
                        history.push(match.url);
                        return [2 /*return*/];
                }
            });
        });
    };
    AssessmentGateViewC.prototype._handleChecklistChanged = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadChecklists()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AssessmentGateViewC.prototype.render = function () {
        var _this = this;
        var _a = this.props, projectId = _a.projectId, gateId = _a.gateId, projectCodeName = _a.projectCodeName, gateName = _a.gateName, canCreateActionItems = _a.canCreateActionItems, onRefreshChecklistStatusOverview = _a.onRefreshChecklistStatusOverview, match = _a.match, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted;
        var openModalLink = "".concat(match.url, "/cl-");
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.wrapper },
                React.createElement("div", { className: styles.functionFilterWrapper },
                    React.createElement(FunctionFilter, { actionForLoading: function () { return Api.secured.getMasterCheckListItemFunctions({ projectId: projectId, gateId: gateId }); } }),
                    React.createElement(RatingFilterAssessment, null)),
                React.createElement(RatingFilterAssessmentContext.Provider, { value: RatingFilterAssessment.filterValue },
                    React.createElement(FunctionFilterContext.Provider, { value: FunctionFilter.filterValue },
                        React.createElement("div", { className: styles.table },
                            React.createElement(ActionItemsTable, { gateId: gateId, projectId: projectId, gateProcessType: GateProcessTypeEnum.GateAssessment, gateProcessTypeForCreation: GateProcessTypeEnum.GateAssessment, canAdd: canCreateActionItems, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })),
                        React.createElement(React.Fragment, null,
                            React.createElement("div", { className: styles.table },
                                React.createElement("div", { className: styles.tableTitle }, "Deliverables"),
                                React.createElement(AssessmentGateDeliversTable, { ref: this._assessmentGateDeliversRef, projectId: projectId, gateId: gateId, projectCodeName: projectCodeName, gateName: gateName, onDocumentIconClick: this._handleDocumentClick, projectState: this.props.projectState, projectType: this.props.projectType, onRefreshActionItems: this.refreshActionItems, onRefreshChecklistStatusOverview: onRefreshChecklistStatusOverview, isFilesChanged: this._isFilesChanged, hideNAItems: this.context.hideNAItems, showRedDotDeliverable: this.context.showRedDotDeliverable, openModalLink: openModalLink, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })),
                            React.createElement("div", { className: styles.table },
                                React.createElement("div", { className: styles.tableTitle }, "Confirmation of Functional Readiness"),
                                React.createElement(AssessmentGateFunctionSummariesTable, { ref: this._assessmentGateFunctionSummariesTableRef, projectId: projectId, gateId: gateId, projectState: this.props.projectState, onRefreshActionItems: this.refreshActionItems, onRefreshChecklistStatusOverview: onRefreshChecklistStatusOverview, hideNAItems: this.context.hideNAItems, openModalLink: openModalLink, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })),
                            React.createElement("div", { className: styles.table },
                                React.createElement("div", { className: styles.tableTitle }, "Questions / Expectations"),
                                React.createElement(AssesmentGateExecutiveQuestionsTable, { ref: this._assesmentGateExecutiveQuestionsTableRef, projectId: projectId, gateId: gateId, projectCodeName: projectCodeName, gateName: gateName, onRefreshActionItems: this.refreshActionItems, projectState: this.props.projectState, onRefreshChecklistStatusOverview: onRefreshChecklistStatusOverview, hideNAItems: this.context.hideNAItems, openModalLink: openModalLink, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })))))),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "".concat(this.props.match.url, "/upload/cl-:id") },
                    React.createElement(DocumentView, { backUrl: this.props.match.url, section: this._documentViewSection, onFileChange: function () { return (_this._isFilesChanged = !_this._isFilesChanged); }, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted }))),
            React.createElement(Switch, null, this._checklists.map(function (c) { return (React.createElement(Route, { key: c.id, path: "".concat(_this.props.match.url, "/cl-").concat(c.id) },
                !c.isEnabled && React.createElement(Redirect, { to: _this.props.match.url }),
                React.createElement(Modal, { isOpen: true, toggle: _this._toggleChecklistsModal, headerTitle: "Checklist Info", maxWidth: "95%" },
                    React.createElement(ApprovedChecklistView, { checklist: c, gateId: gateId || 0, gateCodeName: gateName || '', projectId: projectId, projectCodeName: projectCodeName, onChecklistChanged: _this._handleChecklistChanged, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })))); }))));
    };
    __decorate([
        observable
    ], AssessmentGateViewC.prototype, "_isFilesChanged", void 0);
    __decorate([
        observable
    ], AssessmentGateViewC.prototype, "_checklists", void 0);
    __decorate([
        observable
    ], AssessmentGateViewC.prototype, "_loading", void 0);
    __decorate([
        action.bound
    ], AssessmentGateViewC.prototype, "_handleDocumentClick", null);
    __decorate([
        action.bound
    ], AssessmentGateViewC.prototype, "_loadChecklists", null);
    __decorate([
        action.bound
    ], AssessmentGateViewC.prototype, "_toggleChecklistsModal", null);
    __decorate([
        action.bound
    ], AssessmentGateViewC.prototype, "_handleChecklistChanged", null);
    AssessmentGateViewC = __decorate([
        observer
    ], AssessmentGateViewC);
    return AssessmentGateViewC;
}(React.PureComponent));
AssessmentGateViewC.contextType = ProjectStateContext;
export var AssessmentGateView = withRouter(AssessmentGateViewC);
