var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { ImBook } from 'react-icons/im';
import { FiLogOut } from 'react-icons/fi';
import { ImContrast } from 'react-icons/im';
import { FaUserSecret, FaEyeSlash, FaEye } from 'react-icons/fa';
import { devInfoStore } from '@app/Stores/DevInfoStore';
import { appStore } from '@app/AppStore';
import { Api } from '@app/Api';
import { PersonPicker } from '../Pickers/PersonPicker/PersonPicker';
import { Modal } from '../Modal/Modal';
import styles from './Userbar.module.scss';
import { ColorBlindModeModal } from '@app/Widgets/ColorBlindModeModal/ColorBlindModeModal';
import { colorThemesStore } from '@app/Stores/ColorThemesStore';
export var Userbar = /** @class */ (function (_super) {
    __extends(Userbar, _super);
    function Userbar() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._showModal = false;
        _this._showColorBlindModeModal = false;
        _this._impersonatedPrincipal = null;
        return _this;
    }
    Userbar.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.helpLink().then(function (result) {
                            if (result.helpLink) {
                                _this._helpLink = result.helpLink;
                            }
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Userbar.prototype.render = function () {
        var _a;
        var user = this.props.user;
        var displayName = ((_a = appStore.impersonatedPrincipal) === null || _a === void 0 ? void 0 : _a.displayName) || user.name;
        return (React.createElement("div", { className: styles.userbar },
            React.createElement("div", { className: styles.name }, displayName),
            this._renderImpersonationInfo(),
            this._renderColorBlindButton(),
            React.createElement("div", { className: styles.button, onClick: this._onHelpButtonClick },
                React.createElement(ImBook, null)),
            React.createElement("div", { className: styles.button, onClick: user.logout },
                React.createElement(FiLogOut, null)),
            React.createElement(Modal, { isOpen: this._showModal, toggle: this._toggleModal, headerTitle: "Impersonation", footerbuttons: this._getFooterButtons() },
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Principal"),
                    React.createElement(PersonPicker, { onUsersSelect: this._onUserSelect }))),
            React.createElement(ColorBlindModeModal, { isOpen: this._showColorBlindModeModal, toggleModal: this._toggleColorBlindModeModal })));
    };
    Userbar.prototype._getFooterButtons = function () {
        return [
            {
                color: 'success',
                onClick: this._onEnterImpersonation,
                title: 'Impersonate'
            },
            {
                onClick: this._toggleModal,
                title: 'Close'
            }
        ];
    };
    Userbar.prototype._renderImpersonationInfo = function () {
        if (devInfoStore.isProduction)
            return null;
        if (!appStore.isUserHasAdminRole)
            return null;
        if (appStore.impersonatedPrincipal) {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.impersonation },
                    React.createElement("div", { onClick: this._toggleModal },
                        React.createElement(FaUserSecret, null)),
                    React.createElement("div", { onClick: this._logOutImpersonation },
                        React.createElement(FaEyeSlash, null)))));
        }
        return (React.createElement("div", { className: styles.button + ' ' + styles.impersonate, onClick: this._toggleModal },
            React.createElement(FaEye, null)));
    };
    Userbar.prototype._renderColorBlindButton = function () {
        return (React.createElement("div", { className: styles.button + ' ' + styles.colorBlind, onClick: this._toggleColorBlindModeModal, title: "Color Settings" },
            React.createElement(ImContrast, null),
            colorThemesStore.isBlindColorTheme && React.createElement("span", { className: styles.colorBlindIndicator })));
    };
    Userbar.prototype._toggleModal = function () {
        this._showModal = !this._showModal;
    };
    Userbar.prototype._toggleColorBlindModeModal = function () {
        this._showColorBlindModeModal = !this._showColorBlindModeModal;
    };
    Userbar.prototype._onUserSelect = function (users) {
        if (users) {
            var user = users[0];
            this._impersonatedPrincipal = {
                principalId: user.id,
                displayName: user.displayName.trim().length > 0 ? user.displayName : user.email,
                email: user.email,
                objectId: user.objectId
            };
        }
    };
    Userbar.prototype._onEnterImpersonation = function () {
        if (this._impersonatedPrincipal) {
            appStore.setValue('impersonatedPrincipal', this._impersonatedPrincipal);
            this._toggleModal();
            window.location.href = '/'; // TODO: clarify
        }
    };
    Userbar.prototype._logOutImpersonation = function () {
        localStorage.removeItem('impersonatedPrincipal');
        this._impersonatedPrincipal = null;
        window.location.href = '/'; // TODO: clarify
    };
    Userbar.prototype._onHelpButtonClick = function () {
        if (this._helpLink) {
            window.open(this._helpLink);
        }
    };
    __decorate([
        observable
    ], Userbar.prototype, "_showModal", void 0);
    __decorate([
        observable
    ], Userbar.prototype, "_showColorBlindModeModal", void 0);
    __decorate([
        observable
    ], Userbar.prototype, "_impersonatedPrincipal", void 0);
    __decorate([
        observable
    ], Userbar.prototype, "_helpLink", void 0);
    __decorate([
        action.bound
    ], Userbar.prototype, "_toggleModal", null);
    __decorate([
        action.bound
    ], Userbar.prototype, "_toggleColorBlindModeModal", null);
    __decorate([
        action.bound
    ], Userbar.prototype, "_onUserSelect", null);
    __decorate([
        action.bound
    ], Userbar.prototype, "_onEnterImpersonation", null);
    __decorate([
        action.bound
    ], Userbar.prototype, "_logOutImpersonation", null);
    __decorate([
        action.bound
    ], Userbar.prototype, "_onHelpButtonClick", null);
    Userbar = __decorate([
        observer
    ], Userbar);
    return Userbar;
}(React.Component));
