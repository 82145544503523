var EnumService = /** @class */ (function () {
    function EnumService() {
    }
    EnumService.getEnumKeyByEnumValue = function (myEnum, enumValue) {
        var keys = Object.keys(myEnum).filter(function (x) { return myEnum[x] == enumValue; });
        return keys.length > 0 ? keys[0] : null;
    };
    return EnumService;
}());
export default EnumService;
