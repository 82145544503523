var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { colorThemesStore } from '@app/Stores/ColorThemesStore';
import { ratingPickerStore } from './RatingPickerStore';
import { CheckListItemRatingEnum } from '@app/AppConstants';
import { Select } from '../Select/Select';
import styles from './rating-picker.module.scss';
export var RatingPicker = /** @class */ (function (_super) {
    __extends(RatingPicker, _super);
    function RatingPicker() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isMenuOpen = false;
        _this.pickerRef = React.createRef();
        _this._getBGColor = function (rating) {
            var colors = colorThemesStore.appColors;
            var lightGreen = colors.lightGreen, yellow = colors.yellow, red = colors.red, gray = colors.gray, white = colors.white;
            switch (rating) {
                case CheckListItemRatingEnum.Ok:
                    return lightGreen;
                case CheckListItemRatingEnum.OnGoing:
                    return yellow;
                case CheckListItemRatingEnum.NotOk:
                    return red;
                case CheckListItemRatingEnum.NotApplicable:
                    return gray;
                default:
                    return white;
            }
        };
        _this._getLetter = function (rating) {
            switch (rating) {
                case CheckListItemRatingEnum.Default:
                    return '';
                case CheckListItemRatingEnum.Ok:
                    return 'G';
                case CheckListItemRatingEnum.OnGoing:
                    return 'Y';
                case CheckListItemRatingEnum.NotOk:
                    return 'R';
                default:
                    return '-';
            }
        };
        _this._getLetterColor = function (rating, colors) {
            var darkGray = colors.darkGray, white = colors.white;
            switch (rating) {
                case CheckListItemRatingEnum.Ok:
                case CheckListItemRatingEnum.NotOk:
                    return white;
                default:
                    return darkGray;
            }
        };
        _this._renderCustomLabel = function (option, colorTheme) {
            var label = option.label, value = option.value;
            var isBlindColorTheme = colorTheme.isBlindColorTheme, appColors = colorTheme.appColors;
            return (React.createElement("div", { onMouseDown: function (e) { return e.nativeEvent.stopImmediatePropagation(); }, className: styles.customOption, key: value, style: { backgroundColor: _this._getBGColor(label) } }, isBlindColorTheme && (React.createElement("div", { className: styles.colorLetter, style: { color: _this._getLetterColor(label, appColors) } }, _this._getLetter(label)))));
        };
        return _this;
    }
    RatingPicker.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!ratingPickerStore.ratings.length && !ratingPickerStore.isLoading)) return [3 /*break*/, 2];
                        return [4 /*yield*/, ratingPickerStore.loadRatings()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (this.props.defaultOpenMenu) {
                            this.openMenu();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    RatingPicker.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.defaultOpenMenu && !prevProps.defaultOpenMenu) {
            this.openMenu();
        }
    };
    RatingPicker.prototype.handleNvEnter = function () {
        this.isMenuOpen = false;
    };
    RatingPicker.prototype.openMenu = function () {
        var _a;
        if (this.isMenuOpen)
            return;
        this.isMenuOpen = true;
        (_a = this.pickerRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    };
    RatingPicker.prototype.closeMenu = function () {
        var _a;
        if (!this.isMenuOpen)
            return;
        this.isMenuOpen = false;
        (_a = this.pickerRef.current) === null || _a === void 0 ? void 0 : _a.blur();
    };
    RatingPicker.prototype.render = function () {
        var _a;
        var _this = this;
        var disabled = (_a = this.props, _a.disabled), rating = _a.rating;
        var ratingOptions = ratingPickerStore.ratingOptions;
        var isBlindColorTheme = colorThemesStore.isBlindColorTheme, appColors = colorThemesStore.appColors;
        return (React.createElement(Select, { innerRef: this.pickerRef, styles: {
                option: function (base) { return (__assign(__assign({}, base), { margin: 5, padding: 0, width: 60, height: 20 })); },
                menuPortal: function (base) { return (__assign(__assign({}, base), { width: 70, zIndex: 1500 })); },
                valueContainer: function (base) { return (__assign(__assign({}, base), { padding: 0 })); },
                singleValue: function (base) { return (__assign(__assign({}, base), { maxWidth: '100%', height: 27, marginLeft: 0, display: 'flex' })); }
            }, options: ratingOptions, value: rating && { value: rating, label: rating }, onChange: this._handleChange, menuIsOpen: this.isMenuOpen, menuPortalTarget: document.body, isDisabled: disabled, formatOptionLabel: function (option) { return _this._renderCustomLabel(option, { isBlindColorTheme: isBlindColorTheme, appColors: appColors }); }, onFocus: this.openMenu, onBlur: this.closeMenu, menuPlacement: 'auto' }));
    };
    RatingPicker.prototype._handleChange = function (value) {
        var op = value;
        if (op.value && this.props.onChange) {
            this.props.onChange(op.value);
        }
        this.closeMenu();
    };
    __decorate([
        observable
    ], RatingPicker.prototype, "isMenuOpen", void 0);
    __decorate([
        action.bound
    ], RatingPicker.prototype, "handleNvEnter", null);
    __decorate([
        action.bound
    ], RatingPicker.prototype, "openMenu", null);
    __decorate([
        action.bound
    ], RatingPicker.prototype, "closeMenu", null);
    __decorate([
        action.bound
    ], RatingPicker.prototype, "_handleChange", null);
    __decorate([
        action.bound
    ], RatingPicker.prototype, "_renderCustomLabel", void 0);
    RatingPicker = __decorate([
        observer
    ], RatingPicker);
    return RatingPicker;
}(React.PureComponent));
