var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { TiArrowLoop } from 'react-icons/ti';
import { IoMdClose } from 'react-icons/io';
import { FaSave } from 'react-icons/fa';
import { Select } from '@app/Components/Select/Select';
import { PersonPicker } from '@app/Components/Pickers/PersonPicker/PersonPicker';
import { Input } from '../Input/Input';
import styles from './Filter.module.scss';
import { colorThemesStore } from '@app/Stores/ColorThemesStore';
import { SimpleToggler } from '../ShowNAItemsToggler/SimpleToggler';
import { Sizes } from '@app/AppConstants';
export var FieldType;
(function (FieldType) {
    FieldType[FieldType["SELECT"] = 0] = "SELECT";
    FieldType[FieldType["TEXT"] = 1] = "TEXT";
    FieldType[FieldType["PERSON_PICKER"] = 2] = "PERSON_PICKER";
    FieldType[FieldType["SWITCH"] = 3] = "SWITCH";
})(FieldType || (FieldType = {}));
export var Filter = /** @class */ (function (_super) {
    __extends(Filter, _super);
    function Filter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isOpen = true;
        return _this;
    }
    Filter.prototype._handleOpenState = function () {
        this._isOpen = !this._isOpen;
    };
    Filter.prototype._handleKeyPress = function (e) {
        var _a, _b;
        if (e.key === 'Enter')
            (_b = (_a = this.props).onFiltersSave) === null || _b === void 0 ? void 0 : _b.call(_a);
    };
    Filter.prototype.render = function () {
        var _a;
        var _this = this;
        var onFiltersSave = (_a = this.props, _a.onFiltersSave), onFiltersClear = _a.onFiltersClear, onFiltersReset = _a.onFiltersReset;
        var colors = colorThemesStore.appColors;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.head },
                React.createElement("div", { className: styles.headItem, onClick: function () { return onFiltersClear === null || onFiltersClear === void 0 ? void 0 : onFiltersClear(); } },
                    React.createElement(IoMdClose, { style: { color: colors.red, fontSize: 15 } }),
                    React.createElement("div", { className: styles.headItemText }, "Clear Filter")),
                React.createElement("div", { className: styles.headItem, onClick: function () { return onFiltersSave === null || onFiltersSave === void 0 ? void 0 : onFiltersSave(); } },
                    React.createElement(FaSave, { style: { color: colors.green, fontSize: 15 } }),
                    React.createElement("div", { className: styles.headItemText }, "Save Filter")),
                React.createElement("div", { className: styles.headItem, onClick: function () { return onFiltersReset === null || onFiltersReset === void 0 ? void 0 : onFiltersReset(); } },
                    React.createElement(TiArrowLoop, { style: { color: colors.red, fontSize: 15 } }),
                    React.createElement("div", { className: styles.headItemText }, "Reset Filter")),
                React.createElement("div", { className: "".concat(styles.burger, " ").concat(this._isOpen ? styles.opened : styles.closed), onClick: this._handleOpenState },
                    this._isOpen ? 'Close' : 'Open',
                    " Filter")),
            this._isOpen && (React.createElement("div", { className: styles.body },
                React.createElement("div", { className: styles.line }, this.props.filters.map(function (field) { return (React.createElement("div", { key: field.codeName, className: styles.item + (field.type === FieldType.SWITCH ? " ".concat(styles.switch) : '') },
                    field.type === FieldType.SWITCH && (React.createElement("div", { className: styles.toggler },
                        React.createElement(SimpleToggler, { onToggle: function (checked) { var _a; return (_a = field.onChange) === null || _a === void 0 ? void 0 : _a.call(field, checked); }, checked: field.value, iconSize: Sizes.IconSizeSmall }))),
                    field.customTitle || React.createElement("div", { className: styles.title }, field.name),
                    field.type === FieldType.SELECT && (React.createElement(Select, { options: field.options, onChange: function (option) { var _a; return (_a = field.onChange) === null || _a === void 0 ? void 0 : _a.call(field, option); }, value: field.value, isMulti: field.isMulti, isDisabled: field.isDisable, isClearable: true })),
                    field.type === FieldType.TEXT && (React.createElement(Input, { type: "text", bsSize: "sm", value: field.value, onChange: function (e) { var _a; return (_a = field.onChange) === null || _a === void 0 ? void 0 : _a.call(field, e.target.value); }, onKeyDown: _this._handleKeyPress, isDisabled: field.isDisable })),
                    field.type === FieldType.PERSON_PICKER && (React.createElement(PersonPicker, { onUsersSelect: function (val) { var _a; return (_a = field.onChange) === null || _a === void 0 ? void 0 : _a.call(field, val); }, defaultValue: field.value, isMulti: field.isMulti, isDisabled: field.isDisable, styles: {
                            menu: function (base) { return (__assign(__assign({}, base), { width: 'unset' })); },
                            menuPortal: function (base) { return (__assign(__assign({}, base), { zIndex: 100 })); },
                            multiValueLabel: function (base) { return (__assign(__assign({}, base), { div: {
                                    width: 'unset !important',
                                    div: {
                                        marginLeft: '12px',
                                        display: 'none'
                                    }
                                } })); }
                        } })))); }))))));
    };
    __decorate([
        observable
    ], Filter.prototype, "_isOpen", void 0);
    __decorate([
        action.bound
    ], Filter.prototype, "_handleOpenState", null);
    __decorate([
        action.bound
    ], Filter.prototype, "_handleKeyPress", null);
    Filter = __decorate([
        observer
    ], Filter);
    return Filter;
}(React.PureComponent));
