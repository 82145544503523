var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Api } from '@app/Api';
import { Button } from '@app/Components/Button/Button';
import { CompletionType, PromiseCompletion } from '@app/Classes/PromiseCompletion';
import { CheckListItemRatingEnum } from '@app/AppConstants';
import { ValidationHint } from '@app/Components/ValidationHint/ValidationHint';
import { InApprovalLinkedDeliverableWarning } from '@app/Components/InApprovalWarning/InApprovalLinkedDeliverableWarning';
import { InApprovalRedDotDeliverableWarning } from '@app/Components/InApprovalWarning/InApprovalRedDotDeliverableWarning';
export var ApproveButton = /** @class */ (function (_super) {
    __extends(ApproveButton, _super);
    function ApproveButton(props) {
        var _this = _super.call(this, props) || this;
        _this._isLoaded = new PromiseCompletion(CompletionType.Completed);
        _this._showInApprovalWarning = false;
        _this._showInApprovalRedDotWarning = false;
        _this._dismissWarning = false;
        _this._dismissRedDotWarning = false;
        _this._disabled = false;
        _this._disabled = _this.props.isDisabled;
        return _this;
    }
    ApproveButton.prototype._handlePrepare = function () {
        return __awaiter(this, void 0, void 0, function () {
            var deliverable, onPrepare, hasRedDot, promise, isSuccessful;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        deliverable = (_a = this.props, _a.deliverable), onPrepare = _a.onPrepare, hasRedDot = _a.hasRedDot;
                        if (!deliverable)
                            return [2 /*return*/];
                        if (hasRedDot && !this._dismissRedDotWarning) {
                            this._onToggleRedDotWarning();
                            return [2 /*return*/];
                        }
                        promise = Api.secured.prepareDeliverable(deliverable.id, { dismissWarning: this._dismissWarning });
                        this._isLoaded.subscribe(promise);
                        return [4 /*yield*/, promise];
                    case 1:
                        isSuccessful = (_b.sent()).body;
                        if (!isSuccessful) return [3 /*break*/, 3];
                        return [4 /*yield*/, onPrepare()];
                    case 2:
                        _b.sent();
                        this._dismissWarning = false;
                        this._showInApprovalWarning = false;
                        return [3 /*break*/, 4];
                    case 3:
                        this._showInApprovalWarning = true;
                        _b.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ApproveButton.prototype._onDismissWarning = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._dismissWarning = true;
                        return [4 /*yield*/, this._handlePrepare()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApproveButton.prototype._onDismissRedDotWarning = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._dismissRedDotWarning = true;
                        this._onToggleRedDotWarning();
                        return [4 /*yield*/, this._handlePrepare()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApproveButton.prototype._onToggleWarning = function () {
        this._showInApprovalWarning = !this._showInApprovalWarning;
    };
    ApproveButton.prototype._onToggleRedDotWarning = function () {
        this._showInApprovalRedDotWarning = !this._showInApprovalRedDotWarning;
    };
    ApproveButton.prototype.changeDisabled = function (isDisabled) {
        this._disabled = isDisabled;
    };
    Object.defineProperty(ApproveButton.prototype, "validationErrors", {
        get: function () {
            var _a;
            var errors = [];
            var rating = (_a = this.props, _a.rating), comment = _a.comment, hasDocument = _a.hasDocument, isApprovalDisabled = _a.isApprovalDisabled;
            if (!comment) {
                errors.push('Comment has to be filled');
            }
            if (rating === CheckListItemRatingEnum.NotApplicable) {
                return errors;
            }
            if (rating === CheckListItemRatingEnum.Default) {
                errors.push('Rating has to be set');
            }
            if (hasDocument === false && !isApprovalDisabled) {
                errors.push('File has to be uploaded');
            }
            return errors;
        },
        enumerable: false,
        configurable: true
    });
    ApproveButton.prototype.render = function () {
        var _a;
        var deliverable = (_a = this.props, _a.deliverable), showValidationError = _a.showValidationError, isApprovalDisabled = _a.isApprovalDisabled;
        if (!deliverable)
            return null;
        return (React.createElement(React.Fragment, null,
            showValidationError && this._disabled && (React.createElement(ValidationHint, { id: 'del' + deliverable.id, isValid: !this._disabled, errors: this.validationErrors })),
            !deliverable.doNotNeedApproval && !isApprovalDisabled && (React.createElement(Button, { size: "sm", color: "success", isLoading: this._isLoaded.isPending, onClick: this._handlePrepare, disabled: this._disabled }, "Ready for Approval")),
            this._showInApprovalWarning && (React.createElement(InApprovalLinkedDeliverableWarning, { onDismissWarning: this._onDismissWarning, onToggleWarning: this._onToggleWarning })),
            this._showInApprovalRedDotWarning && (React.createElement(InApprovalRedDotDeliverableWarning, { onDismissWarning: this._onDismissRedDotWarning, onToggleWarning: this._onToggleRedDotWarning }))));
    };
    __decorate([
        observable
    ], ApproveButton.prototype, "_isLoaded", void 0);
    __decorate([
        observable
    ], ApproveButton.prototype, "_showInApprovalWarning", void 0);
    __decorate([
        observable
    ], ApproveButton.prototype, "_showInApprovalRedDotWarning", void 0);
    __decorate([
        observable
    ], ApproveButton.prototype, "_dismissWarning", void 0);
    __decorate([
        observable
    ], ApproveButton.prototype, "_dismissRedDotWarning", void 0);
    __decorate([
        observable
    ], ApproveButton.prototype, "_disabled", void 0);
    __decorate([
        action.bound
    ], ApproveButton.prototype, "_handlePrepare", null);
    __decorate([
        action.bound
    ], ApproveButton.prototype, "_onDismissWarning", null);
    __decorate([
        action.bound
    ], ApproveButton.prototype, "_onDismissRedDotWarning", null);
    __decorate([
        action.bound
    ], ApproveButton.prototype, "_onToggleWarning", null);
    __decorate([
        action.bound
    ], ApproveButton.prototype, "_onToggleRedDotWarning", null);
    __decorate([
        action.bound
    ], ApproveButton.prototype, "changeDisabled", null);
    __decorate([
        computed
    ], ApproveButton.prototype, "validationErrors", null);
    ApproveButton = __decorate([
        observer
    ], ApproveButton);
    return ApproveButton;
}(React.Component));
