var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { withRouter, Switch, Route } from 'react-router-dom';
import styles from './ProjectDocuments.module.scss';
import { Api } from '@app/Api';
import { Button } from '@app/Components/Button/Button';
import ProjectDocumentsGrid from './ProjectDocumentsGrid/ProjectDocumentsGrid';
import ProjectDocumentViewDialog from './ProjectDocumentViewDialog/ProjectDocumentViewDialog';
import { DocumentCategoryDialog } from '../../DocumentCategoryManagement/DocumentCategoryDialog';
import { ProjectStatus } from '@app/AppConstants';
import { DocumentCategoryHint } from '@app/Components/DocumentCategoryHint/DocumentCategoryHint';
import { DeletedItemsFilter } from './Components/DeletedItemsFilter/DeletedItemsFilter';
var ProjectDocuments = /** @class */ (function (_super) {
    __extends(ProjectDocuments, _super);
    function ProjectDocuments() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._openCategoryManagementModal = false;
        _this._documents = undefined;
        _this._loadingDocuments = false;
        _this._project = _this.props.project;
        _this._gateOptions = [];
        _this._documentCategoryOptions = [];
        _this._showDeletedDocuments = false;
        _this._isProjectTerminatedOrCompleted = _this.props.project.status === ProjectStatus.Terminated || _this.props.project.status === ProjectStatus.Completed;
        return _this;
    }
    ProjectDocuments.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadDocuments()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadGates()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this._loadDocumentCategories()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocuments.prototype._categoryManagementHandler = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._openCategoryManagementModal = true;
                return [2 /*return*/];
            });
        });
    };
    ProjectDocuments.prototype._addDocumentClickHandler = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.props.history.push("".concat(this.props.match.url, "/upload/add"));
                return [2 /*return*/];
            });
        });
    };
    ProjectDocuments.prototype._onDocumentClickHandler = function (projectDocument) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.props.history.push("".concat(this.props.match.url, "/upload/pd-").concat(projectDocument.id));
                return [2 /*return*/];
            });
        });
    };
    ProjectDocuments.prototype._loadProject = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, Api.secured.getProject(this._project.codeName)];
                    case 1:
                        _a._project = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocuments.prototype._loadGates = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, Api.secured.getGatesListLookup({ projectId: this._project.id })];
                    case 1:
                        _a._gateOptions = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocuments.prototype._loadDocumentCategories = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, Api.secured.getDocumentCategoriesForProjectLookup({
                                projectId: this._project.id
                            })];
                    case 1:
                        _a._documentCategoryOptions = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocuments.prototype._loadDocuments = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, , 2, 3]);
                        this._loadingDocuments = true;
                        _a = this;
                        return [4 /*yield*/, Api.secured.getProjectDocuments({
                                projectId: this._project.id,
                                showDeletedDocuments: this._showDeletedDocuments
                            })];
                    case 1:
                        _a._documents = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this._loadingDocuments = false;
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocuments.prototype._reloadData = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all([this._loadDocuments(), this._loadProject(), this._loadDocumentCategories()])];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocuments.prototype._documentDeleteClickHandler = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.deleteProjectDocument({
                            id: id
                        })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadDocuments()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocuments.prototype._documentRestoreClickHandler = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.restoreProjectDocument({
                            id: id
                        })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadDocuments()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocuments.prototype._onFilterChangeHandler = function (checked) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._showDeletedDocuments = checked;
                        return [4 /*yield*/, this._loadDocuments()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocuments.prototype._saveFileComment = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            var updateModel;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!rowData.fileInformationId)
                            return [2 /*return*/];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        this._loadingDocuments = true;
                        updateModel = {
                            projectDocumentId: rowData.id,
                            fileInformationId: rowData.fileInformationId,
                            comment: rowData.comment
                        };
                        return [4 /*yield*/, Api.secured.updateProjectDocumentComment({ body: updateModel })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this._loadingDocuments = false;
                        return [7 /*endfinally*/];
                    case 4: return [4 /*yield*/, this._loadDocuments()];
                    case 5:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocuments.prototype._updateFileComment = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            var updateModel;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!rowData.fileInformationId)
                            return [2 /*return*/];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        this._loadingDocuments = true;
                        updateModel = {
                            projectDocumentId: rowData.id,
                            fileInformationId: rowData.fileInformationId,
                            fileName: rowData.name
                        };
                        return [4 /*yield*/, Api.secured.updateProjectDocumentFileName({ body: updateModel })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this._loadingDocuments = false;
                        return [7 /*endfinally*/];
                    case 4: return [4 /*yield*/, this._loadDocuments()];
                    case 5:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocuments.prototype._onFileCategoryUpdate = function (documentId, categoryId) {
        return __awaiter(this, void 0, void 0, function () {
            var documentCategoryId, updateModel;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        documentCategoryId = categoryId ? Number(categoryId) : undefined;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        this._loadingDocuments = true;
                        updateModel = {
                            id: documentId,
                            documentCategoryId: documentCategoryId
                        };
                        return [4 /*yield*/, Api.secured.updateProjectDocumentCategory({ body: updateModel })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this._loadingDocuments = false;
                        return [7 /*endfinally*/];
                    case 4: return [4 /*yield*/, this._loadDocuments()];
                    case 5:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocuments.prototype._onFileGateUpdate = function (documentId, gateId) {
        return __awaiter(this, void 0, void 0, function () {
            var gateIdNumber, updateModel;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        gateIdNumber = gateId ? Number(gateId) : undefined;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        this._loadingDocuments = true;
                        updateModel = {
                            id: documentId,
                            gateId: gateIdNumber
                        };
                        return [4 /*yield*/, Api.secured.updateProjectDocumentGate({ body: updateModel })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this._loadingDocuments = false;
                        return [7 /*endfinally*/];
                    case 4: return [4 /*yield*/, this._loadDocuments()];
                    case 5:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectDocuments.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d;
        var match = this.props.match;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.wrapper },
                React.createElement("div", { className: styles.table },
                    React.createElement("div", { className: styles.tableHeader },
                        React.createElement("div", { className: styles.buttonWrapper },
                            React.createElement("div", { className: styles.button },
                                ((_a = this._documents) === null || _a === void 0 ? void 0 : _a.canPrincipalCreateProjectDocument) && (React.createElement(Button, { onClick: function () { return _this._addDocumentClickHandler(); }, color: "primary", disabled: this._isProjectTerminatedOrCompleted }, "Add Document")),
                                ((_b = this._documents) === null || _b === void 0 ? void 0 : _b.canPrincipalManageProjectDocumentCategory) && (React.createElement("div", { className: styles.button },
                                    React.createElement(Button, { onClick: function () { return _this._categoryManagementHandler(); }, disabled: this._openCategoryManagementModal },
                                        "Category Management",
                                        React.createElement(DocumentCategoryHint, { id: "projectDocumentView", isValid: !((_c = this._documents) === null || _c === void 0 ? void 0 : _c.showDocumentCategoryWarning) }))))))))),
            React.createElement("div", { className: styles.deletedItemsFilter },
                React.createElement(DeletedItemsFilter, { isChecked: this._showDeletedDocuments, title: "Show Deleted Documents", onToggle: this._onFilterChangeHandler })),
            React.createElement(ProjectDocumentsGrid, { documentCategoryOptions: this._documentCategoryOptions, gateOptions: this._gateOptions, projectId: this._project.id, documents: (_d = this._documents) === null || _d === void 0 ? void 0 : _d.documents, loadingDocuments: this._loadingDocuments, onDocumentClick: this._onDocumentClickHandler, onFileCommentSave: this._saveFileComment, onFileNameUpdate: this._updateFileComment, onDelete: this._documentDeleteClickHandler, onRestore: this._documentRestoreClickHandler, onFileCategoryUpdate: this._onFileCategoryUpdate, onFileGateUpdate: this._onFileGateUpdate, isProjectTerminatedOrCompleted: this._isProjectTerminatedOrCompleted }),
            React.createElement(DocumentCategoryDialog, { isProjectTerminatedOrCompleted: this._isProjectTerminatedOrCompleted, project: this._project, isOpen: this._openCategoryManagementModal, onClose: function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this._openCategoryManagementModal = false;
                                return [4 /*yield*/, this._reloadData()];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); } }),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "".concat(match.url, "/upload/pd-:id") },
                    React.createElement(ProjectDocumentViewDialog, { backUrl: "".concat(match.url), onClose: this._reloadData, projectId: this._project.id, title: "".concat(this._project.codeName, " / ").concat(this._project.displayName), isProjectTerminatedOrCompleted: this._isProjectTerminatedOrCompleted, isCreate: false })),
                React.createElement(Route, { path: "".concat(match.url, "/upload/add") },
                    React.createElement(ProjectDocumentViewDialog, { backUrl: "".concat(match.url), onClose: this._reloadData, projectId: this._project.id, title: "".concat(this._project.codeName, " / ").concat(this._project.displayName), isProjectTerminatedOrCompleted: this._isProjectTerminatedOrCompleted, isCreate: true })))));
    };
    __decorate([
        observable
    ], ProjectDocuments.prototype, "_openCategoryManagementModal", void 0);
    __decorate([
        observable
    ], ProjectDocuments.prototype, "_documents", void 0);
    __decorate([
        observable
    ], ProjectDocuments.prototype, "_loadingDocuments", void 0);
    __decorate([
        observable
    ], ProjectDocuments.prototype, "_project", void 0);
    __decorate([
        observable
    ], ProjectDocuments.prototype, "_gateOptions", void 0);
    __decorate([
        observable
    ], ProjectDocuments.prototype, "_documentCategoryOptions", void 0);
    __decorate([
        observable
    ], ProjectDocuments.prototype, "_showDeletedDocuments", void 0);
    __decorate([
        action.bound
    ], ProjectDocuments.prototype, "_categoryManagementHandler", null);
    __decorate([
        action.bound
    ], ProjectDocuments.prototype, "_addDocumentClickHandler", null);
    __decorate([
        action.bound
    ], ProjectDocuments.prototype, "_onDocumentClickHandler", null);
    __decorate([
        action.bound
    ], ProjectDocuments.prototype, "_loadProject", null);
    __decorate([
        action.bound
    ], ProjectDocuments.prototype, "_loadGates", null);
    __decorate([
        action.bound
    ], ProjectDocuments.prototype, "_loadDocumentCategories", null);
    __decorate([
        action.bound
    ], ProjectDocuments.prototype, "_loadDocuments", null);
    __decorate([
        action.bound
    ], ProjectDocuments.prototype, "_reloadData", null);
    __decorate([
        action.bound
    ], ProjectDocuments.prototype, "_documentDeleteClickHandler", null);
    __decorate([
        action.bound
    ], ProjectDocuments.prototype, "_documentRestoreClickHandler", null);
    __decorate([
        action.bound
    ], ProjectDocuments.prototype, "_onFilterChangeHandler", null);
    __decorate([
        action.bound
    ], ProjectDocuments.prototype, "_saveFileComment", null);
    __decorate([
        action.bound
    ], ProjectDocuments.prototype, "_updateFileComment", null);
    __decorate([
        action.bound
    ], ProjectDocuments.prototype, "_onFileCategoryUpdate", null);
    __decorate([
        action.bound
    ], ProjectDocuments.prototype, "_onFileGateUpdate", null);
    ProjectDocuments = __decorate([
        observer
    ], ProjectDocuments);
    return ProjectDocuments;
}(React.PureComponent));
export default withRouter(ProjectDocuments);
