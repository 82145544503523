var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import styles from './confirm-button.module.scss';
import { Tooltip } from 'devextreme-react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Loader } from '@app/Components/Loader/Loader';
export var ConfirmButton = /** @class */ (function (_super) {
    __extends(ConfirmButton, _super);
    function ConfirmButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isTooltipVisible = false;
        return _this;
    }
    ConfirmButton.prototype.render = function () {
        var _a;
        var _this = this;
        var _b;
        var onClick = (_a = this.props, _a.onClick), id = _a.id, disabled = _a.disabled, isLoading = _a.isLoading;
        var tooltipText = (_b = this.props.tooltipText) !== null && _b !== void 0 ? _b : "Rate green and set comment to 'OK.'";
        return (React.createElement(React.Fragment, null,
            React.createElement("button", { className: !disabled ? styles.confirmButton : styles.disabledConfirmButton, id: id, onMouseEnter: function () { return (_this._isTooltipVisible = true); }, onMouseLeave: function () { return (_this._isTooltipVisible = false); } },
                React.createElement("span", { onClick: onClick, style: { color: 'green' } }, isLoading ? (React.createElement("div", { style: { display: 'flex', alignItems: 'center', justifyContent: 'center' } },
                    React.createElement(Loader, { style: { width: 20, height: 20 } }))) : ('OK'))),
            React.createElement(Tooltip, { visible: this._isTooltipVisible, target: "#".concat(id) },
                React.createElement("div", { className: styles.validationInfoWrapper }, tooltipText))));
    };
    __decorate([
        observable
    ], ConfirmButton.prototype, "_isTooltipVisible", void 0);
    ConfirmButton = __decorate([
        observer
    ], ConfirmButton);
    return ConfirmButton;
}(React.Component));
