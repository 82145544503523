var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observable, action, computed } from 'mobx';
import { observer } from 'mobx-react';
import { Checklists } from './Checklists/Checklists';
import { Gates } from './Gates/Gates';
import { withRouter, Route, Redirect, Switch } from 'react-router-dom';
import { TgTabs } from '@app/Components/TgTabs/TgTabs';
import { NavTab } from 'react-router-tabs';
import { TgTabsContent } from '@app/Components/TgTabs/TgTabsContent';
import { Link } from '@app/Components/Link/Link';
import { Button } from '@app/Components/Button/Button';
import { NotFound } from '../../../NotFound/NotFound';
import { InitFromTemplateModal } from './InitProjectFromTemplate/InitFromTemplateModal';
import { ProjectReaders } from './ProjectReaders/ProjectReaders';
import { ConfirmationRemoveDialog } from '@app/Pages/ProjectView/ProjectViewTabs/ProjectSetup/ConfirmationRemoveDialog/ConfirmationRemoveDialog';
import { TemplateInfo } from './TemplateInfo/TemplateInfo';
import { NotificationsConfiguration } from './NotificationsConfiguration/NotificationsConfiguration';
import { ProjectType } from '@app/AppConstants';
import { ProjectStatuses } from '@app/Components/ProjectsOverview/ProjectsOverview';
import styles from '@app/Pages/ProjectView/ProjectView.module.scss';
import { Api } from '@app/Api';
import { ConfirmationDialog } from '@app/Widgets/ConfirmationDialog/ConfirmationDialog';
var ProjectSetupC = /** @class */ (function (_super) {
    __extends(ProjectSetupC, _super);
    function ProjectSetupC() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._canAddGate = true;
        _this._isProjectRollbackModalOpen = false;
        _this._isProjectCompleteModalOpen = false;
        _this._isProjectTerminateModalOpen = false;
        return _this;
    }
    ProjectSetupC.prototype._onGatesNumberChanged = function (gatesNumber) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._canAddGate = !gatesNumber;
                        if (!!gatesNumber) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.props.onUpdateProjectChangeHandler()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(ProjectSetupC.prototype, "_addGateButtonVisible", {
        get: function () {
            var match = this.props.match;
            var isModalOpen = match.url.includes('/gates/add');
            return this._canAddGate && !isModalOpen;
        },
        enumerable: false,
        configurable: true
    });
    ProjectSetupC.prototype._changeProjectStatus = function (status) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var params;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        params = {
                            projectId: (_a = this.props) === null || _a === void 0 ? void 0 : _a.id,
                            status: status
                        };
                        return [4 /*yield*/, Api.secured.updateStatus(params)];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this.props.onUpdateProjectChangeHandler()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectSetupC.prototype._rollbackProjectStatus = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.rollbackStatus({ projectId: this.props.id })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.props.onUpdateProjectChangeHandler()];
                    case 2:
                        _a.sent();
                        this._toggleProjectRollbackModalOpen();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectSetupC.prototype._setProjectStatusCompleted = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._changeProjectStatus(ProjectStatuses.COMPLETED)];
                    case 1:
                        _a.sent();
                        this._toggleProjectCompleteModalOpen();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectSetupC.prototype._setProjectStatusTerminated = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._changeProjectStatus(ProjectStatuses.TERMINATED)];
                    case 1:
                        _a.sent();
                        this._toggleProjectTerminateModalOpen();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectSetupC.prototype._toggleProjectRollbackModalOpen = function () {
        this._isProjectRollbackModalOpen = !this._isProjectRollbackModalOpen;
    };
    ProjectSetupC.prototype._toggleProjectCompleteModalOpen = function () {
        this._isProjectCompleteModalOpen = !this._isProjectCompleteModalOpen;
    };
    ProjectSetupC.prototype._toggleProjectTerminateModalOpen = function () {
        this._isProjectTerminateModalOpen = !this._isProjectTerminateModalOpen;
    };
    ProjectSetupC.prototype._getCustomButtons = function () {
        var _this = this;
        var _a = this.props, match = _a.match, canPrincipalAddGate = _a.canPrincipalAddGate, canPrincipalInitFromTemplate = _a.canPrincipalInitFromTemplate, canPrincipalManipulateWithReader = _a.canPrincipalManipulateWithReader, canPrincipalRemoveProject = _a.canPrincipalRemoveProject, canPrincipalUpdateStatus = _a.canPrincipalUpdateStatus, canPrincipalRollbackStatus = _a.canPrincipalRollbackStatus, status = _a.status, projectType = _a.projectType, isEmpty = _a.isEmpty, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted;
        var res = [];
        var canAddGate = canPrincipalRemoveProject && this._canAddGate;
        var isMilestoneProject = projectType === ProjectType.Milestone;
        if (location.pathname.startsWith(match.url + '/gates') && !isEmpty)
            res.push(canPrincipalAddGate && this._addGateButtonVisible && (React.createElement(Link, { to: "".concat(match.url, "/gates/add") },
                React.createElement(Button, { disabled: isProjectTerminatedOrCompleted, size: "sm", color: "primary" }, "Add ".concat(isMilestoneProject ? 'Milestone' : 'Gate')))));
        if (location.pathname.startsWith(match.url + '/project-readers') &&
            !isEmpty &&
            canPrincipalManipulateWithReader)
            res.push(React.createElement(Link, { to: "".concat(match.url, "/project-readers/add") },
                React.createElement(Button, { disabled: isProjectTerminatedOrCompleted, size: "sm", color: "primary" }, "Add Project Readers")));
        if (isEmpty && canPrincipalInitFromTemplate)
            res.push(React.createElement(Link, { to: "".concat(match.url, "/init") },
                React.createElement(Button, { disabled: isProjectTerminatedOrCompleted, size: "sm", color: "success" }, "Choose Template")));
        if (!isEmpty && canPrincipalUpdateStatus && status === ProjectStatuses.APPROVED) {
            res.push(React.createElement(Button, { disabled: isProjectTerminatedOrCompleted, className: styles.button, size: "sm", color: "success", onClick: function () { return _this._toggleProjectCompleteModalOpen(); } }, "Complete"));
            res.push(React.createElement(Button, { className: styles.button, size: "sm", color: "ghost", onClick: function () { return _this._toggleProjectTerminateModalOpen(); } }, "Terminate"));
        }
        if (!isEmpty &&
            canPrincipalRollbackStatus &&
            (status === ProjectStatuses.COMPLETED || status === ProjectStatuses.TERMINATED)) {
            var rollbackButtonTitle = status === ProjectStatuses.COMPLETED ? 'Rollback Completion' : 'Rollback Termination';
            res.push(React.createElement(Button, { className: styles.button, size: "sm", color: "danger", onClick: function () { return _this._toggleProjectRollbackModalOpen(); } }, rollbackButtonTitle));
        }
        if (canPrincipalRemoveProject && canAddGate)
            res.push(React.createElement(Link, { to: "".concat(match.url, "/remove-project") },
                React.createElement(Button, { size: "sm" }, "Remove Project")));
        return res;
    };
    ProjectSetupC.prototype.render = function () {
        var _a = this.props, id = _a.id, projectCodeName = _a.projectCodeName, match = _a.match, onChooseTemplateHandler = _a.onChooseTemplateHandler, onUpdateProjectChangeHandler = _a.onUpdateProjectChangeHandler, isNotififcationsEnabled = _a.isNotififcationsEnabled, canPrincipalRedoGate = _a.canPrincipalRedoGate, projectType = _a.projectType, isNorrdProject = _a.isNorrdProject, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted;
        return (React.createElement(React.Fragment, null,
            React.createElement(TgTabs, { startPathWith: match.url, customButtons: this._getCustomButtons() },
                React.createElement(NavTab, { to: "/gates" }, projectType === ProjectType.Milestone ? 'Milestones' : 'Gates'),
                React.createElement(NavTab, { to: "/checklists" }, "Checklists"),
                React.createElement(NavTab, { to: "/project-readers" }, "Project Access"),
                React.createElement(NavTab, { to: "/info" }, "Info"),
                React.createElement(NavTab, { to: "/notifications" }, "Notifications")),
            React.createElement(TgTabsContent, null,
                React.createElement(Switch, null,
                    React.createElement(Route, { path: "".concat(match.url, "/gates") },
                        React.createElement(Gates, { projectId: id, projectCodeName: projectCodeName, onGatesNumberChanged: this._onGatesNumberChanged, canPrincipalRedoGate: canPrincipalRedoGate, onGateStatusChangeHandler: onUpdateProjectChangeHandler, projectType: projectType, isNorrdProject: isNorrdProject, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })),
                    React.createElement(Route, { path: "".concat(match.url, "/checklists") },
                        React.createElement(Checklists, { projectId: id })),
                    React.createElement(Route, { path: "".concat(match.url, "/project-readers") },
                        React.createElement(ProjectReaders, { projectId: id, editable: !isProjectTerminatedOrCompleted })),
                    React.createElement(Route, { path: "".concat(match.url, "/info") },
                        React.createElement(TemplateInfo, { projectId: id })),
                    React.createElement(Route, { path: "".concat(match.url, "/notifications") },
                        React.createElement(NotificationsConfiguration, { projectId: id, isNotififcationsEnabled: isNotififcationsEnabled, disabled: isProjectTerminatedOrCompleted })),
                    React.createElement(Route, { path: "".concat(match.url, "/init") },
                        React.createElement(InitFromTemplateModal, { backUrl: match.url, projectId: id, onChooseTemplateHandler: onChooseTemplateHandler })),
                    React.createElement(Route, { path: "".concat(match.url, "/remove-project") },
                        React.createElement(ConfirmationRemoveDialog, { backUrl: match.url, projectId: id })),
                    React.createElement(Route, { exact: true, path: "".concat(match.url) },
                        React.createElement(Redirect, { to: "".concat(match.url, "/gates") })),
                    React.createElement(Route, { path: "*", component: NotFound }))),
            React.createElement(ConfirmationDialog, { isOpen: this._isProjectRollbackModalOpen, onToggle: this._toggleProjectRollbackModalOpen, headerTitle: "Rollback Project", onSave: this._rollbackProjectStatus, body: 'Are you sure you want to roll back the Project to "Approved" state?' }),
            React.createElement(ConfirmationDialog, { isOpen: this._isProjectCompleteModalOpen, onToggle: this._toggleProjectCompleteModalOpen, headerTitle: "Set Project to Completed", okButtonTitle: "Set to Completed", onSave: this._setProjectStatusCompleted, body: "Do you really want to set project to Completed?" }),
            React.createElement(ConfirmationDialog, { isOpen: this._isProjectTerminateModalOpen, onToggle: this._toggleProjectTerminateModalOpen, headerTitle: "Set Project to Terminated", okButtonTitle: "Set to Terminated", onSave: this._setProjectStatusTerminated, body: "Do you really want to set project to Terminated?" })));
    };
    __decorate([
        observable
    ], ProjectSetupC.prototype, "_canAddGate", void 0);
    __decorate([
        observable
    ], ProjectSetupC.prototype, "_isProjectRollbackModalOpen", void 0);
    __decorate([
        observable
    ], ProjectSetupC.prototype, "_isProjectCompleteModalOpen", void 0);
    __decorate([
        observable
    ], ProjectSetupC.prototype, "_isProjectTerminateModalOpen", void 0);
    __decorate([
        action.bound
    ], ProjectSetupC.prototype, "_onGatesNumberChanged", null);
    __decorate([
        computed
    ], ProjectSetupC.prototype, "_addGateButtonVisible", null);
    __decorate([
        action.bound
    ], ProjectSetupC.prototype, "_changeProjectStatus", null);
    __decorate([
        action.bound
    ], ProjectSetupC.prototype, "_rollbackProjectStatus", null);
    __decorate([
        action.bound
    ], ProjectSetupC.prototype, "_setProjectStatusCompleted", null);
    __decorate([
        action.bound
    ], ProjectSetupC.prototype, "_setProjectStatusTerminated", null);
    __decorate([
        action.bound
    ], ProjectSetupC.prototype, "_toggleProjectRollbackModalOpen", null);
    __decorate([
        action.bound
    ], ProjectSetupC.prototype, "_toggleProjectCompleteModalOpen", null);
    __decorate([
        action.bound
    ], ProjectSetupC.prototype, "_toggleProjectTerminateModalOpen", null);
    ProjectSetupC = __decorate([
        observer
    ], ProjectSetupC);
    return ProjectSetupC;
}(React.PureComponent));
export var ProjectSetup = withRouter(ProjectSetupC);
