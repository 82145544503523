var EnumHelper = /** @class */ (function () {
    function EnumHelper() {
        var _a;
        this.StatisticsActionTypeLabels = (_a = {},
            _a['UserConnected'] = 'User is connected',
            _a['UserDisconnected'] = 'User is disconnected',
            _a['MessageSent'] = 'Message is sent',
            _a['EmailSent'] = 'Email is sent',
            _a['RequestSent'] = 'Request is sent',
            _a['JobStarted'] = 'Job is started',
            _a);
    }
    return EnumHelper;
}());
export { EnumHelper };
export var enumHelper = new EnumHelper();
