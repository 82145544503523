var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { CustomInput, FormGroup, Label, Row } from 'reactstrap';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { colorThemesStore } from '@app/Stores/ColorThemesStore';
import { PromiseCompletion } from '@app/Classes/PromiseCompletion';
import { Modal } from '@app/Components/Modal/Modal';
import { ColorPicker } from './ColorPicker/ColorPicker';
import styles from './ColorBlindModeModal.module.scss';
import { AiOutlineInfoCircle } from 'react-icons/ai';
export var ColorBlindModeModal = /** @class */ (function (_super) {
    __extends(ColorBlindModeModal, _super);
    function ColorBlindModeModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._colors = __assign({}, colorThemesStore.colors);
        _this._loader = new PromiseCompletion();
        return _this;
    }
    ColorBlindModeModal.prototype.componentDidUpdate = function (prevProps) {
        if (!prevProps.isOpen && this.props.isOpen) {
            var currentColors = __assign({}, colorThemesStore.colors);
            this._setColors(currentColors);
        }
    };
    ColorBlindModeModal.prototype._setColor = function (key, color) {
        this._colors[key] = color;
    };
    ColorBlindModeModal.prototype._setColors = function (colors) {
        this._colors = colors;
    };
    ColorBlindModeModal.prototype._saveColors = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this._loader.add(function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, colorThemesStore.saveColors(this._colors)];
                                case 1: return [2 /*return*/, _a.sent()];
                            }
                        }); }); })];
                    case 1:
                        _c.sent();
                        (_b = (_a = this.props).toggleModal) === null || _b === void 0 ? void 0 : _b.call(_a);
                        return [2 /*return*/];
                }
            });
        });
    };
    ColorBlindModeModal.prototype._resetColors = function () {
        this._setColors(__assign({}, colorThemesStore.defaultColors));
    };
    ColorBlindModeModal.prototype._switchMode = function (isCustom) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!isCustom) {
                    this._resetColors();
                }
                colorThemesStore.setColorTheme(isCustom);
                return [2 /*return*/];
            });
        });
    };
    ColorBlindModeModal.prototype._renderColorPickerRow = function (label, colorKey) {
        var _this = this;
        var isDisabled = !colorThemesStore.isBlindColorTheme;
        return (React.createElement(Row, { className: styles.colorPickerRow + (isDisabled ? " ".concat(styles.disabledRow) : '') },
            React.createElement(Label, { className: styles.colorLabel }, label),
            React.createElement(ColorPicker, { color: this._colors[colorKey], onChange: function (color) { return _this._setColor(colorKey, color); }, disabled: isDisabled })));
    };
    ColorBlindModeModal.prototype._closeModal = function () {
        colorThemesStore.resetChanges();
        this.props.toggleModal();
    };
    ColorBlindModeModal.prototype.render = function () {
        var _this = this;
        var isBlindColorTheme = colorThemesStore.isBlindColorTheme;
        var isOpen = this.props.isOpen;
        return (React.createElement(Modal, { isOpen: isOpen, toggle: this._closeModal, headerTitle: "Color Settings", footerbuttons: this._getFooterButtons() },
            this.props.isShowInfo && (React.createElement("div", { className: styles.info },
                React.createElement("div", { className: styles.icon },
                    React.createElement(AiOutlineInfoCircle, { size: 20 })),
                React.createElement("div", { className: styles.content }, 'Please, select your preferred color configuration and confirm your selection via ‘Save’ button. \n This configuration can be changed at any time using ‘Color Settings’ option in the toolbar of the application (right-upper corner of the page).'))),
            React.createElement(FormGroup, { className: styles.radio },
                React.createElement(CustomInput, { type: "radio", id: "enableColorConfig", name: "customRadio", label: "Use standard color configuration", checked: !isBlindColorTheme, onChange: function () { return _this._switchMode(false); } }),
                React.createElement(CustomInput, { type: "radio", id: "disableColorConfig", name: "customRadio", label: "Use custom color configuration", checked: isBlindColorTheme, onChange: function () { return _this._switchMode(true); } })),
            React.createElement("p", null, "Change the color configuration e.g. in case of color vision deficiency. Click on colors to change if activated."),
            React.createElement(Label, { className: styles.colorsDefinition }, "Colors definitions"),
            this._renderColorPickerRow('Red', 'red'),
            this._renderColorPickerRow('Green', 'green'),
            this._renderColorPickerRow('Orange', 'orange'),
            this._renderColorPickerRow('Yellow', 'yellow'),
            this._renderColorPickerRow('Gray', 'gray')));
    };
    ColorBlindModeModal.prototype._getFooterButtons = function () {
        var disabled = this._loader.isPending || !colorThemesStore.isBlindColorTheme;
        return [
            {
                color: 'primary',
                onClick: this._resetColors.bind(this),
                disabled: disabled,
                title: 'Reset Colors'
            },
            {
                color: 'success',
                onClick: this._saveColors.bind(this),
                disabled: this._loader.isPending,
                title: 'Save'
            },
            {
                onClick: this._closeModal.bind(this),
                title: 'Close'
            }
        ];
    };
    __decorate([
        observable
    ], ColorBlindModeModal.prototype, "_colors", void 0);
    __decorate([
        observable
    ], ColorBlindModeModal.prototype, "_loader", void 0);
    __decorate([
        action.bound
    ], ColorBlindModeModal.prototype, "_setColor", null);
    __decorate([
        action.bound
    ], ColorBlindModeModal.prototype, "_setColors", null);
    __decorate([
        action.bound
    ], ColorBlindModeModal.prototype, "_closeModal", null);
    ColorBlindModeModal = __decorate([
        observer
    ], ColorBlindModeModal);
    return ColorBlindModeModal;
}(React.PureComponent));
