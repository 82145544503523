var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import pako from 'pako';
import { Form, FormGroup, Input, Label, Badge } from 'reactstrap';
import DocumentTitle from 'react-document-title';
import { SignalREvents } from '@app/AppConstants';
import DateTimeService from '@app/Services/DateTimeService';
import { signalRService } from '@app/Services/SignalRService';
import { requestTimelineService } from '@app/Services/RequestTimelineService';
import { appStore } from '@app/AppStore';
import requestStyles from './RequestTimeline.module.scss';
import timelineStyles from './Timeline.module.scss';
/* eslint-disable @typescript-eslint/no-explicit-any */
var PAGE_TITLE = 'Request Timeline';
var defaultOptions = {
    html: false,
    classPrefix: 'sqlhl',
    colors: {
        keyword: '\x1b[35m',
        function: '\x1b[31m',
        number: '\x1b[32m',
        string: '\x1b[32m',
        special: '\x1b[33m',
        bracket: '\x1b[33m'
    }
};
var keywordsUpper = [
    'PRAGMA',
    'CREATE',
    'EXISTS',
    'INTEGER',
    'PRIMARY',
    'DECLARE',
    'letCHAR',
    'DATETIME',
    'NULL',
    'REFERENCES',
    'AND',
    'AS',
    'ASC',
    'INDEX_LIST',
    'BETWEEN',
    'BY',
    'CASE',
    'CURRENT_DATE',
    'CURRENT_TIME',
    'DELETE',
    'DESC',
    'DISTINCT',
    'EACH',
    'ELSE',
    'ELSEIF',
    'FALSE',
    'FOR',
    'FROM',
    'GROUP',
    'HAVING',
    'IF',
    'IN',
    'INSERT',
    'INTERVAL',
    'INTO',
    'IS',
    'JOIN',
    'KEY',
    'KEYS',
    'LEFT',
    'LIKE',
    'LIMIT',
    'MATCH',
    'NOT',
    'ON',
    'OPTION',
    'OR',
    'ORDER',
    'OUT',
    'OUTER',
    'REPLACE',
    'TINYINT',
    'RIGHT',
    'SELECT',
    'SET',
    'TABLE',
    'THEN',
    'TO',
    'TRUE',
    'UPDATE',
    'VALUES',
    'WHEN',
    'WHERE',
    'UNSIGNED',
    'CASCADE',
    'UNIQUE',
    'DEFAULT',
    'ENGINE',
    'TEXT',
    'auto_increment',
    'SHOW',
    'INDEX',
    'CROSS',
    'COALESCE',
    'END',
    'INNER',
    'TOP',
    'OFFSET',
    'ROWS',
    'FETCH',
    'NEXT',
    'ROWS',
    'ONLY',
    'CAST',
    'TOP',
    'EXEC'
];
var keywordsLower = keywordsUpper.map(function (value) { return value.toLowerCase(); });
var keywords = keywordsUpper.concat(keywordsLower);
var clearStyle = '\x1b[0m';
var Highlighter = /** @class */ (function () {
    function Highlighter(_options) {
        this.options = Object.assign({}, defaultOptions, _options);
        this.unicodePattern = "{0}$1".concat(clearStyle);
        this.htmlPattern = "<span class=\"{0}\">$1</span>";
    }
    Highlighter.prototype.highlight = function (text) {
        var newText = text;
        var rules = {
            special: /(=|%|\/|\*|-|,|;|:|\+|<|>)/g,
            function: {
                match: /(\w*?)\(/g,
                pattern: '{0}('
            },
            number: /(\d+)/g,
            string: /(['`].*?['`])/g,
            bracket: /([()])/g
        };
        for (var key in rules) {
            var rule = rules[key];
            var match = rule;
            var pattern = '{0}';
            if (typeof rule === 'function') {
                match = rule.match;
                pattern = rule.pattern;
            }
            var replacer_1 = void 0;
            var isTypeString = key === 'string';
            var classname = void 0;
            if (isTypeString) {
                classname = timelineStyles.sqlhlstring;
            }
            else {
                classname = "".concat(this.options.classPrefix).concat(key);
            }
            if (!this.options.html) {
                replacer_1 = this.unicodePattern.replace('{0}', this.options.colors[key]);
            }
            else {
                replacer_1 = this.htmlPattern.replace('{0}', classname);
            }
            newText = newText.replace(match, pattern.replace('{0}', replacer_1));
        }
        var replacer = !this.options.html
            ? this.unicodePattern.replace('{0}', this.options.colors.keyword)
            : this.htmlPattern.replace('{0}', "".concat(timelineStyles.sqlhlkeyword));
        // Keywords
        for (var i = 0; i < keywords.length; i++) {
            var regEx = new RegExp("\\b(".concat(keywords[i], ")\\b"), 'g');
            newText = newText.replace(regEx, replacer);
        }
        return newText;
    };
    return Highlighter;
}());
var RequestTimeline = /** @class */ (function (_super) {
    __extends(RequestTimeline, _super);
    function RequestTimeline(props) {
        var _this = _super.call(this, props) || this;
        _this._requestsData = null;
        _this._lastRawLength = 0;
        _this._checkTimer = 0;
        _this._showRequests = true;
        _this._requestFilter = '';
        _this._base64Timeline = localStorage.getItem('DevToolRequestTimeline') || undefined;
        _this._requestFilter = localStorage.getItem('DevToolRequestFilter') || '';
        _this.onUnload = _this.onUnload.bind(_this);
        return _this;
    }
    RequestTimeline.prototype.onUnload = function () {
        requestTimelineService.setValue('');
    };
    RequestTimeline.prototype.componentDidMount = function () {
        var _this = this;
        requestTimelineService.clear();
        window.addEventListener('beforeunload', this.onUnload);
        this._checkTimer = window.setInterval(function () { return _this._checkRequests(); }, 1000);
        if (appStore.isUserHasAdminRole) {
            signalRService.subscribe(SignalREvents.sendJobTimeline, this.onJobTimelineChangedHandler);
        }
    };
    RequestTimeline.prototype.componentWillUnmount = function () {
        this.onUnload();
        window.clearInterval(this._checkTimer);
        window.removeEventListener('beforeunload', this.onUnload);
        if (appStore.isUserHasAdminRole) {
            signalRService.unsubscribe(SignalREvents.sendJobTimeline, this.onJobTimelineChangedHandler);
        }
    };
    RequestTimeline.prototype._base64ToArrayBuffer = function (base64) {
        var binaryString = window.atob(base64);
        var len = binaryString.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    };
    RequestTimeline.prototype._checkRequests = function () {
        var rawRequests = requestTimelineService.readTimeline(this._lastRawLength);
        if (rawRequests === null)
            return;
        var requests = [];
        for (var i = 0; i < rawRequests.length; i++) {
            var request = this._parseRawData(rawRequests[i]);
            request.Raw = rawRequests[i];
            requests.push(request);
        }
        this._requestsData = requests;
    };
    RequestTimeline.prototype._parseRawData = function (rawBase64) {
        var raw = this._base64ToArrayBuffer(rawBase64);
        var jsonData = pako.inflate(raw, { to: 'string', raw: true });
        return JSON.parse(jsonData);
    };
    RequestTimeline.prototype.onJobTimelineChangedHandler = function (eventName, data) {
        requestTimelineService.addTimeline(data.rawTimeline);
    };
    RequestTimeline.prototype._renderData = function () {
        if (!this._base64Timeline) {
            return null;
        }
        try {
            var data = this._parseRawData(this._base64Timeline);
            var timeRanges = [10, 20, 50, 100, 200, 350, 500, 1000, 2000, 5000, 10000, 30000, 60000];
            var timeSplits = [10, 10, 10, 10, 10, 14, 10, 10, 20, 10, 10, 30, 20];
            var maxTime = 0;
            var splits = 0;
            for (var i = 0; i < timeRanges.length; i++) {
                maxTime = timeRanges[i];
                splits = timeSplits[i];
                if (timeRanges[i] * 0.7 > data.TotalTimeMs)
                    break;
            }
            var msLength = 100 * (1 / maxTime);
            var splitValue = maxTime / splits;
            var result = [];
            for (var i = 0; i < splits; i++) {
                var ms = splitValue * i;
                var style = {
                    left: (ms * msLength).toFixed(2).toString() + '%'
                };
                var headerName = ms > 1000 ? (ms / 1000).toFixed(1) + 's' : ms.toFixed(1);
                result.push(React.createElement("div", { key: 'header' + i, className: timelineStyles.timeBlock, style: style }, headerName));
            }
            result.push(this._renderEvents(data, msLength));
            return result;
        }
        catch (_error) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            var error = _error;
            return React.createElement("span", { className: timelineStyles.requestDecodeError }, error.toString());
        }
    };
    RequestTimeline.prototype._renderSelectedEvent = function () {
        var event = this._selectedEvent;
        if (!event)
            return null;
        if (event.Text)
            return event.Text;
        var highlighter = new Highlighter({
            html: true
        });
        var sqlParameters = highlighter.highlight(event.OperationParameters || '').replace(/(?:\r\n|\r|\n)/g, '<br>');
        var sqlText = highlighter.highlight(event.OperationName || '').replace(/(?:\r\n|\r|\n)/g, '<br>');
        return sqlParameters + '<br />' + sqlText;
    };
    RequestTimeline.prototype._renderEvents = function (data, msLength) {
        var _this = this;
        var styleRequestWidth = {
            width: (data.TotalTimeMs * msLength).toFixed(2).toString() + '%'
        };
        var events = [];
        var dateTimeStartUtc = DateTimeService.fromString(data.StartUtc);
        events.push(React.createElement("div", { key: 'request', className: timelineStyles.event },
            React.createElement("div", null,
                React.createElement("b", null, data.TimelineParameters),
                " ",
                data.TimelineName),
            React.createElement("div", { className: timelineStyles.time, style: styleRequestWidth }),
            React.createElement("b", null,
                data.TotalTimeMs.toFixed(1),
                "ms"),
            " ",
            DateTimeService.toUiClientShortDateTime(dateTimeStartUtc)));
        var repeatCounts = new Map();
        var repeatFullTime = new Map();
        for (var i = 0; i < data.Items.length; i++) {
            var event_1 = data.Items[i];
            var eventKey = event_1.Text || event_1.OperationName;
            if (!eventKey)
                continue;
            var fullTimeMs = repeatFullTime.get(eventKey) || 0;
            fullTimeMs += event_1.EndTimeMs - event_1.StartTimeMs;
            repeatFullTime.set(eventKey, fullTimeMs);
            var count = repeatCounts.get(eventKey) || 0;
            count++;
            repeatCounts.set(eventKey, count);
        }
        var _loop_1 = function (i) {
            var event_2 = data.Items[i];
            var styleStart = {
                marginLeft: (event_2.StartTimeMs * msLength).toFixed(2).toString() + '%'
            };
            var styleWidth = {
                width: ((event_2.EndTimeMs - event_2.StartTimeMs) * msLength).toFixed(2).toString() + '%',
                backgroundColor: event_2.CssColor ? event_2.CssColor : void 0
            };
            var eventKey = event_2.Text || event_2.OperationName;
            var count = eventKey ? repeatCounts.get(eventKey) || 0 : 0;
            var fullTimeMs = eventKey ? repeatFullTime.get(eventKey) || 0 : 0;
            var isEventSelected = this_1._selectedEvent &&
                (this_1._selectedEvent.OperationName || this_1._selectedEvent.Text) === (event_2.OperationName || event_2.Text);
            events.push(React.createElement("div", { key: 'event' + i, className: "".concat(timelineStyles.event) + (isEventSelected ? " ".concat(timelineStyles.selectedEvent) : ''), style: styleStart, onClick: function () { return (_this._selectedEvent = event_2); } },
                React.createElement("div", { className: timelineStyles.time, style: styleWidth }, event_2.IsException && React.createElement("i", { className: "fa fa-bug time-bug" })),
                React.createElement("b", null,
                    (event_2.EndTimeMs - event_2.StartTimeMs).toFixed(1),
                    "ms"),
                ' ',
                count > 1 ? (React.createElement(React.Fragment, null,
                    React.createElement(Badge, { color: "danger", pill: true }, count),
                    ' ',
                    React.createElement(Badge, { color: "info", pill: true },
                        fullTimeMs.toFixed(1),
                        "ms"))) : null,
                ' ',
                event_2.Text ? event_2.Text : event_2.OperationName));
        };
        var this_1 = this;
        for (var i = 0; i < data.Items.length; i++) {
            _loop_1(i);
        }
        return (React.createElement("div", { key: "events", className: timelineStyles.events }, events));
    };
    RequestTimeline.prototype._setNewTimelineRaw = function (rawData) {
        this._base64Timeline = rawData;
        localStorage.setItem('DevToolRequestTimeline', rawData);
    };
    RequestTimeline.prototype._toggleRequests = function () {
        this._showRequests = !this._showRequests;
    };
    RequestTimeline.prototype._renderRequests = function () {
        var _this = this;
        var requests = [];
        var filterGroups = [];
        var filterGroupParts = this._requestFilter.split(';');
        for (var j = 0; j < filterGroupParts.length; j++) {
            var filterParts = filterGroupParts[j].split(' ');
            var filters = [];
            for (var i = 0; i < filterParts.length; i++) {
                var value = filterParts[i].toLowerCase();
                var type = '+URL';
                if (value.startsWith('-')) {
                    type = '-URL';
                    value = value.substring(1);
                }
                if (value.startsWith('$')) {
                    type = '+SQL';
                    value = value.substring(1);
                }
                if (!value)
                    continue;
                filters.push({
                    value: value,
                    type: type
                });
            }
            filterGroups.push(filters);
        }
        if (this._requestsData) {
            var _loop_2 = function (i) {
                var request = this_2._requestsData[i];
                var matchFilters = !filterGroupParts.length;
                for (var k = 0; k < filterGroupParts.length; k++) {
                    var matchFilter = true;
                    var filters = filterGroups[k];
                    for (var j = 0; j < filters.length; j++) {
                        var filter = filters[j];
                        if (filter.type === '+URL') {
                            matchFilter =
                                matchFilter &&
                                    (request.TimelineParameters + ' ' + request.TimelineName)
                                        .toLowerCase()
                                        .includes(filter.value);
                        }
                        if (filter.type === '-URL') {
                            matchFilter =
                                matchFilter &&
                                    !(request.TimelineParameters + ' ' + request.TimelineName)
                                        .toLowerCase()
                                        .includes(filter.value);
                        }
                        if (filter.type === '+SQL') {
                            matchFilter =
                                matchFilter &&
                                    request.Items.map(function (x) { return x.OperationName || x.Text || ''; })
                                        .join(' ')
                                        .toLowerCase()
                                        .includes(filter.value);
                        }
                    }
                    if (matchFilter) {
                        matchFilters = true;
                    }
                }
                if (!matchFilters)
                    return "continue";
                var requestTimeCss = "".concat(requestStyles.requestTime, " badge");
                if (request.TotalTimeMs > 200) {
                    requestTimeCss += ' badge-danger';
                }
                else if (request.TotalTimeMs > 100) {
                    requestTimeCss += ' badge-warning';
                }
                else if (request.TotalTimeMs > 50) {
                    requestTimeCss += ' badge-info';
                }
                else {
                    requestTimeCss += ' badge-success';
                }
                var requestCss = "".concat(requestStyles.requestItem);
                if (request.Raw === this_2._base64Timeline) {
                    requestCss += " ".concat(requestStyles.selectedrRequestItem);
                }
                if (request.Items.find(function (x) { return !!x.IsException; })) {
                    requestCss += " ".concat(requestStyles.requestItemWithException);
                }
                requests.push(React.createElement("div", { key: request.StartUtc + request.TimelineParameters + request.TimelineName, className: requestCss, onClick: function () { return _this._setNewTimelineRaw(request.Raw || ''); } },
                    React.createElement("b", null, request.TimelineParameters),
                    " ",
                    request.TimelineName,
                    React.createElement("div", { className: requestTimeCss }, request.TotalTimeMs.toFixed(1) + 'ms')));
            };
            var this_2 = this;
            for (var i = 0; i < this._requestsData.length; i++) {
                _loop_2(i);
            }
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: requestStyles.requestsFilter },
                React.createElement("input", { className: "form-control", value: this._requestFilter, onChange: function (e) { return _this._setFilter(e.target.value); }, placeholder: "Filter" })),
            React.createElement("div", { className: requestStyles.requestList }, requests)));
    };
    RequestTimeline.prototype._clearRequests = function (e) {
        requestTimelineService.clear();
        this._requestsData = [];
        e.stopPropagation();
    };
    RequestTimeline.prototype._setFilter = function (value) {
        this._requestFilter = value;
        localStorage.setItem('DevToolRequestFilter', value);
    };
    RequestTimeline.prototype.render = function () {
        var _this = this;
        var selectedEventHtml = {
            __html: this._renderSelectedEvent() || ''
        };
        //https://codepen.io/MilanMilosev/pen/ONNQJM
        return (
        //<ReportErrorBoundary title={PAGE_TITLE}> TODO clarify if we need it
        React.createElement(React.Fragment, null,
            React.createElement(DocumentTitle, { title: PAGE_TITLE }),
            React.createElement("h2", { className: "clearfix" }, PAGE_TITLE),
            React.createElement(Form, null,
                React.createElement(FormGroup, null,
                    React.createElement(Label, { for: "exampleText", onClick: function () { return _this._toggleRequests(); } },
                        this._showRequests ? 'Requests ' : 'Request timeline base64 ',
                        this._showRequests && this._requestsData && this._requestsData.length > 0 ? (React.createElement(Badge, { color: "info" }, this._requestsData.length)) : null,
                        this._showRequests && this._requestsData && this._requestsData.length > 0 ? (React.createElement(Badge, { color: "danger", className: requestStyles.requestsClear, onClick: function (e) { return _this._clearRequests(e); } }, "Clear")) : null),
                    this._showRequests && this._renderRequests(),
                    !this._showRequests && (React.createElement(Input, { type: "textarea", name: "text", value: this._base64Timeline, onChange: function (event) { return _this._setNewTimelineRaw(event.target.value); } })))),
            React.createElement("div", { className: timelineStyles.timeline },
                React.createElement("div", { id: "life" }, this._renderData())),
            selectedEventHtml && (React.createElement("div", { className: timelineStyles.eventDetails, dangerouslySetInnerHTML: selectedEventHtml })))
        //</ReportErrorBoundary>
        );
    };
    __decorate([
        observable
    ], RequestTimeline.prototype, "_base64Timeline", void 0);
    __decorate([
        observable
    ], RequestTimeline.prototype, "_selectedEvent", void 0);
    __decorate([
        observable
    ], RequestTimeline.prototype, "_requestsData", void 0);
    __decorate([
        observable
    ], RequestTimeline.prototype, "_showRequests", void 0);
    __decorate([
        observable
    ], RequestTimeline.prototype, "_requestFilter", void 0);
    __decorate([
        action.bound
    ], RequestTimeline.prototype, "onJobTimelineChangedHandler", null);
    __decorate([
        action
    ], RequestTimeline.prototype, "_clearRequests", null);
    __decorate([
        action
    ], RequestTimeline.prototype, "_setFilter", null);
    RequestTimeline = __decorate([
        observer
    ], RequestTimeline);
    return RequestTimeline;
}(React.Component));
export default RequestTimeline;
