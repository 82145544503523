var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { observer } from 'mobx-react';
import { action, observable, reaction } from 'mobx';
import { Api } from '@app/Api';
import { components } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
var ReactSelectStyles = {
    control: function (base) { return (__assign(__assign({}, base), { minHeight: '5px' })); },
    dropdownIndicator: function (base) { return (__assign(__assign({}, base), { padding: '2px' })); },
    input: function (base) { return (__assign(__assign({}, base), { lineHeight: '10px' })); },
    valueContainer: function (base) { return (__assign(__assign({}, base), { padding: '0 8px' })); },
    menu: function (base) { return (__assign(__assign({}, base), { zIndex: 3 })); }
};
var DEFAUL_SIZE = 10;
export var PersonPicker = /** @class */ (function (_super) {
    __extends(PersonPicker, _super);
    function PersonPicker(props) {
        var _this = _super.call(this, props) || this;
        _this._store = new PersonPickerStore();
        return _this;
    }
    PersonPicker.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._store.updateProps(this.props);
                        return [4 /*yield*/, this._store.loadMoreDefaultOptions()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PersonPicker.prototype.componentDidUpdate = function (prevProps) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                if (prevProps.exceptIds !== this.props.exceptIds ||
                    ((_a = prevProps.exceptIds) === null || _a === void 0 ? void 0 : _a.length) !== ((_b = this.props.exceptIds) === null || _b === void 0 ? void 0 : _b.length) ||
                    prevProps.defaultValue !== this.props.defaultValue) {
                    this._store.updateProps(this.props);
                }
                return [2 /*return*/];
            });
        });
    };
    PersonPicker.prototype.render = function () {
        var _a, _b;
        var _this = this;
        var promiseOptions = (_a = this._store, _a.promiseOptions), onInputChange = _a.onInputChange, userOnChange = _a.userOnChange, selectedPersons = _a.selectedPersons, key = _a.key;
        var className = (_b = this.props, _b.className), isMulti = _b.isMulti, styles = _b.styles, placeholder = _b.placeholder, needPortal = _b.needPortal, isDisabled = _b.isDisabled, isClearable = _b.isClearable, isCreatable = _b.isCreatable, onMenuOpen = _b.onMenuOpen, onMenuClose = _b.onMenuClose;
        var cls = ['react-select'];
        className && cls.push(className);
        return (React.createElement(AsyncCreatableSelect, { key: key, className: cls.join(' '), classNamePrefix: "react-select", loadOptions: promiseOptions, defaultOptions: this._store.defaultOpts, onMenuScrollToBottom: function () { return _this._store.loadMoreDefaultOptions(); }, isLoading: this._store.loading, isMulti: isMulti, value: selectedPersons, menuPortalTarget: needPortal ? document.body : null, menuPosition: needPortal ? 'absolute' : 'fixed', components: { Option: this._Option, Menu: this._Menu }, formatOptionLabel: this.renderCustomLabel, onChange: userOnChange, styles: __assign(__assign({}, ReactSelectStyles), (styles || {})), onInputChange: onInputChange, placeholder: placeholder || 'Select Person', isDisabled: isDisabled, createOptionPosition: 'last', allowCreateWhileLoading: false, onMenuOpen: onMenuOpen, onMenuClose: onMenuClose, isSearchable: true, isCreatable: isCreatable, isClearable: isClearable, isOptionDisabled: function (o) {
                var createOption = o;
                if (createOption.__isNew__) {
                    return !_this.props.isCreatable;
                }
                return false;
            } }));
    };
    PersonPicker.prototype._Option = function (props) {
        return (React.createElement("div", { onMouseDown: function (e) { return e.nativeEvent.stopImmediatePropagation(); } },
            React.createElement(components.Option, __assign({}, props))));
    };
    PersonPicker.prototype._Menu = function (props) {
        return (React.createElement(components.Menu, __assign({}, props),
            React.createElement("div", { onMouseDown: function (e) { return e.nativeEvent.stopImmediatePropagation(); } }, props.children)));
    };
    PersonPicker.prototype.renderCustomLabel = function (option) {
        var createOption = option;
        if (createOption.__isNew__) {
            return React.createElement("div", null, createOption.label);
        }
        return (React.createElement("div", { key: option.id, style: { display: 'flex', width: '400px', color: option.isActive === false ? 'red' : '' } },
            React.createElement("div", { style: { display: 'block', width: '200px', marginLeft: 0 } }, "".concat(option.displayName ? option.displayName : option.email).concat(option.isActive === false ? ' (Inactive)' : '')),
            option.subDepartment && React.createElement("div", { style: { width: '100px' } }, option.subDepartment),
            option.country && React.createElement("div", { style: { width: '100px' } }, option.country)));
    };
    PersonPicker = __decorate([
        observer
    ], PersonPicker);
    return PersonPicker;
}(React.Component));
var PersonPickerStore = /** @class */ (function () {
    function PersonPickerStore() {
        var _this = this;
        this.key = 0;
        this.defaultOpts = [];
        this.loading = false;
        this.size = DEFAUL_SIZE;
        this.isCreatable = false;
        this.selectedPersons = null;
        this.onInputChange = function (newValue) {
            if (newValue !== _this.inputValue) {
                _this.inputValue = newValue;
                _this.page = 0;
                _this.defaultOpts = [];
            }
        };
        this.page = 0;
        this.inputValue = '';
        this.isMulti = false;
        this.exceptIds = [];
        this.isCreatable = false;
        reaction(function () { return _this.inputValue; }, function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.inputValue) return [3 /*break*/, 2];
                        this.clearDefaultOptions();
                        return [4 /*yield*/, this.loadMoreDefaultOptions()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); });
    }
    PersonPickerStore.prototype.updateProps = function (props) {
        if (props.size)
            this.size = props.size;
        this.isMulti = !!props.isMulti;
        this.onUsersSelect = props.onUsersSelect;
        this.clearAfterSelect = props.clearAfterSelect;
        this.exceptIds = props.exceptIds;
        this.isCreatable = !!props.isCreatable;
        this.projectId = props.projectId;
        this.selectedPersons = props.defaultValue;
    };
    PersonPickerStore.prototype.loadPrincipals = function (inputValue) {
        return __awaiter(this, void 0, void 0, function () {
            var params;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = this._getRequestParams(inputValue, this.exceptIds, this.projectId);
                        return [4 /*yield*/, Api.secured.searchPrincipals(params)];
                    case 1: return [2 /*return*/, (_a.sent())];
                }
            });
        });
    };
    PersonPickerStore.prototype.loadMoreDefaultOptions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        return [4 /*yield*/, this.loadPrincipals(this.inputValue)];
                    case 1:
                        res = _a.sent();
                        this.page++;
                        this.defaultOpts = __spreadArray(__spreadArray([], this.defaultOpts, true), res, true);
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    PersonPickerStore.prototype.clearDefaultOptions = function () {
        this.loading = true;
        this.page = 0;
        this.defaultOpts = [];
        this.loading = false;
    };
    PersonPickerStore.prototype.promiseOptions = function (inputValue, cb) {
        var _this = this;
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.loadPrincipals(inputValue).then(function (res) {
            _this.page++;
            cb(res);
        });
    };
    PersonPickerStore.prototype._getRequestParams = function (inputValue, exceptIds, projectId) {
        this.onInputChange(inputValue);
        var params = {};
        params.size = this.size;
        params.page = this.page;
        if (inputValue)
            params.term = inputValue;
        if (exceptIds)
            params.exceptPrincipalIds = exceptIds;
        if (projectId)
            params.projectId = projectId;
        return params;
    };
    PersonPickerStore.prototype.userOnChange = function (value, actionMeta) {
        return __awaiter(this, void 0, void 0, function () {
            var createOption, user, e_1, selectedPrincipals, v;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(actionMeta.action === 'create-option')) return [3 /*break*/, 5];
                        if (!this.isCreatable)
                            return [2 /*return*/];
                        createOption = value;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, Api.secured.createPrincipal({ email: createOption.value })];
                    case 2:
                        user = _a.sent();
                        this.selectedPersons = [user];
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        this.selectedPersons = null;
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        this.selectedPersons = value;
                        _a.label = 6;
                    case 6:
                        selectedPrincipals = null;
                        if (this.selectedPersons) {
                            if (Array.isArray(this.selectedPersons)) {
                                selectedPrincipals = this.selectedPersons;
                            }
                            else {
                                v = this.selectedPersons;
                                selectedPrincipals = [v];
                            }
                        }
                        if (this.onUsersSelect) {
                            this.onUsersSelect(selectedPrincipals);
                        }
                        if (this.clearAfterSelect) {
                            this.selectedPersons = null;
                            this.key++;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        observable
    ], PersonPickerStore.prototype, "key", void 0);
    __decorate([
        observable
    ], PersonPickerStore.prototype, "defaultOpts", void 0);
    __decorate([
        observable
    ], PersonPickerStore.prototype, "loading", void 0);
    __decorate([
        observable
    ], PersonPickerStore.prototype, "inputValue", void 0);
    __decorate([
        observable
    ], PersonPickerStore.prototype, "selectedPersons", void 0);
    __decorate([
        action.bound
    ], PersonPickerStore.prototype, "promiseOptions", null);
    __decorate([
        action.bound
    ], PersonPickerStore.prototype, "userOnChange", null);
    return PersonPickerStore;
}());
