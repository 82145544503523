var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { ManualTaskStateEnum, StatusEnum } from '@app/AppConstants';
import styles from './ManualTaskActionButtons.module.scss';
import { Button } from '@app/Components/Button/Button';
import { FaTrash } from 'react-icons/fa';
import { Api } from '@app/Api';
import { ConfirmationDialog } from '@app/Widgets/ConfirmationDialog/ConfirmationDialog';
import { CommentModal } from '@app/Widgets/CommentModal/CommentModal';
export var ManualTaskActionButtons = /** @class */ (function (_super) {
    __extends(ManualTaskActionButtons, _super);
    function ManualTaskActionButtons() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._actionLoading = false;
        _this._isManualTaskDeleteModalOpen = false;
        _this._isManualTaskDenyCommentModalOpen = false;
        _this._currentManualTaskId = null;
        return _this;
    }
    ManualTaskActionButtons.prototype._toggleManualTaskDeleteModalOpen = function () {
        this._isManualTaskDeleteModalOpen = !this._isManualTaskDeleteModalOpen;
    };
    ManualTaskActionButtons.prototype._toggleManualTaskDenyCommentModalOpen = function () {
        this._isManualTaskDenyCommentModalOpen = !this._isManualTaskDenyCommentModalOpen;
    };
    ManualTaskActionButtons.prototype._handleManualTaskDeleteClick = function (manualTaskId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._currentManualTaskId = manualTaskId;
                this._toggleManualTaskDeleteModalOpen();
                return [2 /*return*/];
            });
        });
    };
    ManualTaskActionButtons.prototype._handleManualTaskDelete = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!this._currentManualTaskId)
                            return [2 /*return*/];
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, , 3, 4]);
                        this._actionLoading = true;
                        return [4 /*yield*/, Api.secured.deleteManualTask({ manualTaskId: this._currentManualTaskId })];
                    case 2:
                        _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this._actionLoading = false;
                        return [7 /*endfinally*/];
                    case 4:
                        this._currentManualTaskId = null;
                        this._toggleManualTaskDeleteModalOpen();
                        return [4 /*yield*/, ((_b = (_a = this.props).onToggle) === null || _b === void 0 ? void 0 : _b.call(_a))];
                    case 5:
                        _c.sent();
                        return [4 /*yield*/, this.props.updateTaskStatus()];
                    case 6:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ManualTaskActionButtons.prototype._handleManualTaskApprove = function (manualTaskId) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, , 2, 3]);
                        this._actionLoading = true;
                        return [4 /*yield*/, Api.secured.approveManualTask({ manualTaskId: manualTaskId })];
                    case 1:
                        _c.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this._actionLoading = false;
                        return [7 /*endfinally*/];
                    case 3: return [4 /*yield*/, ((_b = (_a = this.props).onToggle) === null || _b === void 0 ? void 0 : _b.call(_a))];
                    case 4:
                        _c.sent();
                        return [4 /*yield*/, this.props.updateTaskStatus()];
                    case 5:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ManualTaskActionButtons.prototype._handleManualTaskDenyClick = function (manualTaskId) {
        this._currentManualTaskId = manualTaskId;
        this._toggleManualTaskDenyCommentModalOpen();
    };
    ManualTaskActionButtons.prototype._handleManualTaskDeny = function (comment) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!this._currentManualTaskId)
                            return [2 /*return*/];
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, , 3, 4]);
                        this._actionLoading = true;
                        return [4 /*yield*/, Api.secured.denyManualTask({ manualTaskId: this._currentManualTaskId, comment: comment })];
                    case 2:
                        _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this._actionLoading = false;
                        return [7 /*endfinally*/];
                    case 4:
                        this._currentManualTaskId = null;
                        return [4 /*yield*/, ((_b = (_a = this.props).onToggle) === null || _b === void 0 ? void 0 : _b.call(_a))];
                    case 5:
                        _c.sent();
                        return [4 /*yield*/, this.props.updateTaskStatus()];
                    case 6:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ManualTaskActionButtons.prototype._handleManualTaskCantDo = function (manualTaskId) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, , 2, 3]);
                        this._actionLoading = true;
                        return [4 /*yield*/, Api.secured.completeManualTask({ manualTaskId: manualTaskId, state: ManualTaskStateEnum.CantDo })];
                    case 1:
                        _c.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this._actionLoading = false;
                        return [7 /*endfinally*/];
                    case 3: return [4 /*yield*/, ((_b = (_a = this.props).onToggle) === null || _b === void 0 ? void 0 : _b.call(_a))];
                    case 4:
                        _c.sent();
                        return [4 /*yield*/, this.props.updateTaskStatus()];
                    case 5:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ManualTaskActionButtons.prototype._handleManualTaskDone = function (manualTaskId) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, , 2, 3]);
                        this._actionLoading = true;
                        return [4 /*yield*/, Api.secured.completeManualTask({ manualTaskId: manualTaskId, state: ManualTaskStateEnum.Done })];
                    case 1:
                        _c.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this._actionLoading = false;
                        return [7 /*endfinally*/];
                    case 3: return [4 /*yield*/, ((_b = (_a = this.props).onToggle) === null || _b === void 0 ? void 0 : _b.call(_a))];
                    case 4:
                        _c.sent();
                        return [4 /*yield*/, this.props.updateTaskStatus()];
                    case 5:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ManualTaskActionButtons.prototype.render = function () {
        var _this = this;
        var task = this.props.task;
        var canUpdateWorkerPart = task.state !== ManualTaskStateEnum.ClosedBySystem &&
            task.status === StatusEnum.Draft &&
            task.canPrincipalUpdateWorkerPart;
        var canApprove = task.state !== ManualTaskStateEnum.ClosedBySystem &&
            task.status === StatusEnum.InApproval &&
            task.canPrincipalApproveManualTask;
        return (React.createElement(React.Fragment, null,
            task.canPrincipalUpdateMainPart && (React.createElement("div", null,
                React.createElement(Button, { className: styles.actionBtn, color: "danger", size: "xs", onClick: function () { return _this._handleManualTaskDeleteClick(task.id); }, isLoading: this._actionLoading },
                    React.createElement(FaTrash, null)))),
            canApprove && (React.createElement("div", null,
                React.createElement(Button, { className: styles.actionBtn, size: "sm", color: "success", onClick: function () { return _this._handleManualTaskApprove(task.id); }, isLoading: this._actionLoading }, "Approve"))),
            canApprove && (React.createElement("div", null,
                React.createElement(Button, { className: styles.actionBtn, size: "sm", onClick: function () { return _this._handleManualTaskDenyClick(task.id); }, isLoading: this._actionLoading }, "Deny"))),
            canUpdateWorkerPart && (React.createElement("div", null,
                React.createElement(Button, { className: styles.actionBtn, size: "sm", color: "success", onClick: function () { return _this._handleManualTaskCantDo(task.id); }, isLoading: this._actionLoading }, "Can't do"))),
            canUpdateWorkerPart && (React.createElement("div", null,
                React.createElement(Button, { className: styles.actionBtn, size: "sm", color: "success", onClick: function () { return _this._handleManualTaskDone(task.id); }, isLoading: this._actionLoading }, "Done"))),
            React.createElement(ConfirmationDialog, { isOpen: this._isManualTaskDeleteModalOpen, onToggle: this._toggleManualTaskDeleteModalOpen, headerTitle: "Remove Task", onSave: this._handleManualTaskDelete, body: "Are you sure you want to delete the task? The task can not be restored after deletion." }),
            React.createElement(CommentModal, { isOpen: this._isManualTaskDenyCommentModalOpen, onToggle: this._toggleManualTaskDenyCommentModalOpen, onSave: this._handleManualTaskDeny })));
    };
    __decorate([
        observable
    ], ManualTaskActionButtons.prototype, "_actionLoading", void 0);
    __decorate([
        observable
    ], ManualTaskActionButtons.prototype, "_isManualTaskDeleteModalOpen", void 0);
    __decorate([
        observable
    ], ManualTaskActionButtons.prototype, "_isManualTaskDenyCommentModalOpen", void 0);
    __decorate([
        observable
    ], ManualTaskActionButtons.prototype, "_currentManualTaskId", void 0);
    __decorate([
        action.bound
    ], ManualTaskActionButtons.prototype, "_toggleManualTaskDeleteModalOpen", null);
    __decorate([
        action.bound
    ], ManualTaskActionButtons.prototype, "_toggleManualTaskDenyCommentModalOpen", null);
    __decorate([
        action.bound
    ], ManualTaskActionButtons.prototype, "_handleManualTaskDeleteClick", null);
    __decorate([
        action.bound
    ], ManualTaskActionButtons.prototype, "_handleManualTaskDelete", null);
    __decorate([
        action.bound
    ], ManualTaskActionButtons.prototype, "_handleManualTaskApprove", null);
    __decorate([
        action.bound
    ], ManualTaskActionButtons.prototype, "_handleManualTaskDenyClick", null);
    __decorate([
        action.bound
    ], ManualTaskActionButtons.prototype, "_handleManualTaskDeny", null);
    __decorate([
        action.bound
    ], ManualTaskActionButtons.prototype, "_handleManualTaskCantDo", null);
    __decorate([
        action.bound
    ], ManualTaskActionButtons.prototype, "_handleManualTaskDone", null);
    ManualTaskActionButtons = __decorate([
        observer
    ], ManualTaskActionButtons);
    return ManualTaskActionButtons;
}(React.Component));
