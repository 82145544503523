var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Label } from 'reactstrap';
import { observable, action, computed } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import styles from './RedoGate.module.scss';
import { Api } from '@app/Api';
import { DATE_OPTIONS } from '@app/Helpers/DateOptions';
import { Input } from '@app/Components/Input/Input';
import { Loader } from '@app/Components/Loader/Loader';
import { DatePicker } from '@app/Components/DatePicker';
import { Modal } from '@app/Components/Modal/Modal';
import { HtmlEditor } from '@app/Components/HtmlEditor/HtmlEditor';
var RedoGateC = /** @class */ (function (_super) {
    __extends(RedoGateC, _super);
    function RedoGateC() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isSaving = false;
        return _this;
    }
    Object.defineProperty(RedoGateC.prototype, "_hasNorrdGate", {
        get: function () {
            var _a, _b;
            return ((_a = this._data) === null || _a === void 0 ? void 0 : _a.norrdCompleted) !== undefined && ((_b = this._data) === null || _b === void 0 ? void 0 : _b.norrdCompleted) !== null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RedoGateC.prototype, "_calculatedStartDate", {
        get: function () {
            if (this._data && this._data.forecastOrActualDate) {
                var calculated = new Date(this._data.forecastOrActualDate.getTime());
                calculated.setDate(calculated.getDate() - this._data.lengthInWeeks * 7);
                return calculated;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RedoGateC.prototype, "_minCompletionTargetDate", {
        get: function () {
            var _a, _b, _c, _d;
            if ((_a = this._data) === null || _a === void 0 ? void 0 : _a.minimumAllowedStartDate) {
                var minDate = new Date((_b = this._data) === null || _b === void 0 ? void 0 : _b.minimumAllowedStartDate);
                minDate.setDate(((_c = this._data) === null || _c === void 0 ? void 0 : _c.minimumAllowedStartDate.getDate()) + ((_d = this._data) === null || _d === void 0 ? void 0 : _d.lengthInWeeks) * 7);
                return minDate;
            }
            else {
                return undefined;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RedoGateC.prototype, "_maxDuration", {
        get: function () {
            var _a, _b;
            if (((_a = this._data) === null || _a === void 0 ? void 0 : _a.minimumAllowedStartDate) && ((_b = this._data) === null || _b === void 0 ? void 0 : _b.forecastOrActualDate)) {
                var diffDays = this._dateDiffInDays(this._data.minimumAllowedStartDate, this._data.forecastOrActualDate);
                return diffDays / 7;
            }
            else {
                return undefined;
            }
        },
        enumerable: false,
        configurable: true
    });
    RedoGateC.prototype._dateDiffInDays = function (a, b) {
        var _MS_PER_DAY = 1000 * 60 * 60 * 24;
        var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    };
    RedoGateC.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._initData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    RedoGateC.prototype._initData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, projectId, redoGateId, previousGateId, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this.props, projectId = _a.projectId, redoGateId = _a.redoGateId, previousGateId = _a.previousGateId;
                        _b = this;
                        return [4 /*yield*/, Api.secured.getGateInitData({
                                projectId: projectId,
                                redoGateId: redoGateId
                            })];
                    case 1:
                        _b._data = _c.sent();
                        this._data.previousGateId = previousGateId;
                        if (this._minCompletionTargetDate && !this._data.forecastOrActualDate && !this._hasNorrdGate) {
                            this._data.forecastOrActualDate = this._minCompletionTargetDate;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    RedoGateC.prototype._onToggle = function () {
        this.props.onToggle();
    };
    RedoGateC.prototype._onSave = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var hasOneMoreGateForRedo, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this._isSaving = true;
                        if (!this._data) return [3 /*break*/, 4];
                        return [4 /*yield*/, ((_b = (_a = this.props).onGateRedo) === null || _b === void 0 ? void 0 : _b.call(_a, this._data))];
                    case 1:
                        hasOneMoreGateForRedo = _d.sent();
                        this._isSaving = false;
                        this._onToggle();
                        _c = hasOneMoreGateForRedo;
                        if (!_c) return [3 /*break*/, 3];
                        return [4 /*yield*/, this._initData()];
                    case 2:
                        _c = (_d.sent());
                        _d.label = 3;
                    case 3:
                        _c;
                        _d.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    RedoGateC.prototype._handleGateDescriptionChange = function (e) {
        var _a;
        var parts = (_a = e.value) === null || _a === void 0 ? void 0 : _a.split('<p>');
        if (!parts)
            return (this._data.gateDescription = '');
        var isEmpty = true;
        for (var i = 0; i < parts.length; i++) {
            var p = parts[i];
            if (p.indexOf('</p>') !== -1) {
                p = p.replace('</p>', '');
            }
            if (p.length > 1) {
                // special chars in the end
                isEmpty = false;
                break;
            }
        }
        this._data.gateDescription = isEmpty ? '' : e.value;
    };
    RedoGateC.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d;
        var isMilestoneProject = this.props.isMilestoneProject;
        var isSaveDisabled = !(((_a = this._data) === null || _a === void 0 ? void 0 : _a.lengthInWeeks) &&
            ((_b = this._data) === null || _b === void 0 ? void 0 : _b.gateDescription) &&
            ((_c = this._data) === null || _c === void 0 ? void 0 : _c.name) &&
            ((_d = this._data) === null || _d === void 0 ? void 0 : _d.forecastOrActualDate));
        return (React.createElement("div", { className: styles.container },
            React.createElement(Modal, { isOpen: true, headerTitle: isMilestoneProject ? 'Redo Milestone' : 'Redo Gate', toggle: this._onToggle, maxWidth: "95%", footerbuttons: this._getFooterButtons(isSaveDisabled) }, this._data ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(Label, null, "Name"),
                        React.createElement(Input, { placeholder: "Name", type: "text", value: this._data.name, disabled: true, onChange: function (e) { return (_this._data.name = e.target.value); } }))),
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.field },
                        React.createElement(Label, null, isMilestoneProject ? 'Milestone Description' : 'Gate Description'),
                        React.createElement(HtmlEditor, { value: this._data.gateDescription, height: 180, onValueChanged: this._handleGateDescriptionChange }))),
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.field },
                        React.createElement(Label, null, isMilestoneProject ? 'Milestone Definition' : 'Gate Definition'),
                        React.createElement(Input, { placeholder: isMilestoneProject ? 'Milestone Definition' : 'Gate Definition', type: "textarea", rows: 4, value: this._data.gateDefinition, onChange: function (e) {
                                return (_this._data.gateDefinition = e.target.value);
                            } }))),
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.thirdField },
                        React.createElement(Label, null, "Forecast Or Actual Date"),
                        React.createElement(DatePicker, { disabled: !this._data.isForecastOrActualDateEditable, onDateChanged: function (date) {
                                _this._data.forecastOrActualDate = date;
                                _this.forceUpdate();
                            }, className: styles.datePicker, value: this._data.forecastOrActualDate, displayFormat: "dd MMM yyyy", acceptCustomValue: false, type: "date" })),
                    React.createElement("div", { className: styles.thirdField },
                        React.createElement(Label, null, "Duration In Weeks "),
                        React.createElement(Input, { placeholder: "Duration in weeks", type: "number", min: 1, max: this._maxDuration, value: this._data.lengthInWeeks, onChange: function (e) {
                                return (_this._data.lengthInWeeks = parseInt(e.target.value, 10));
                            } })),
                    this._calculatedStartDate && (React.createElement("div", { className: styles.thirdField },
                        React.createElement(Label, null, "Calculated Start Date"),
                        React.createElement(Input, { placeholder: "Date", type: "text", value: this._calculatedStartDate.toLocaleDateString('en-GB', DATE_OPTIONS), disabled: true })))),
                this._hasNorrdGate && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles.line },
                        React.createElement("div", { className: styles.field },
                            React.createElement(Label, null, "NORRD Assessors"),
                            React.createElement(Input, { type: "text", value: this._data.norrdGateAssessors, disabled: true, onChange: function (e) {
                                    return (_this._data.norrdGateAssessors =
                                        e.target.value);
                                } }))),
                    React.createElement("div", { className: styles.line },
                        React.createElement(Label, null,
                            "NORRD Gate Status:",
                            this._data.norrdCompleted ? 'COMPLETED' : 'NOT COMPLETED')))))) : (React.createElement(Loader, { style: { width: 100, height: 100 } })))));
    };
    RedoGateC.prototype._getFooterButtons = function (isSaveDisabled) {
        return [
            {
                color: 'success',
                onClick: this._onSave,
                disabled: isSaveDisabled,
                isLoading: this._isSaving,
                title: 'Redo'
            },
            {
                onClick: this._onToggle,
                title: 'Close'
            }
        ];
    };
    __decorate([
        observable
    ], RedoGateC.prototype, "_data", void 0);
    __decorate([
        observable
    ], RedoGateC.prototype, "_isSaving", void 0);
    __decorate([
        computed
    ], RedoGateC.prototype, "_hasNorrdGate", null);
    __decorate([
        computed
    ], RedoGateC.prototype, "_calculatedStartDate", null);
    __decorate([
        computed
    ], RedoGateC.prototype, "_minCompletionTargetDate", null);
    __decorate([
        computed
    ], RedoGateC.prototype, "_maxDuration", null);
    __decorate([
        action.bound
    ], RedoGateC.prototype, "_initData", null);
    __decorate([
        action.bound
    ], RedoGateC.prototype, "_onToggle", null);
    __decorate([
        action.bound
    ], RedoGateC.prototype, "_onSave", null);
    __decorate([
        action.bound
    ], RedoGateC.prototype, "_handleGateDescriptionChange", null);
    RedoGateC = __decorate([
        observer
    ], RedoGateC);
    return RedoGateC;
}(React.PureComponent));
export var RedoGate = withRouter(RedoGateC);
