var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { SaveCancel } from '@app/Components/SaveCancel/SaveCancel';
var SaveCancelContainer = /** @class */ (function (_super) {
    __extends(SaveCancelContainer, _super);
    function SaveCancelContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SaveCancelContainer.prototype.render = function () {
        var _a = this.props, isActive = _a.isActive, onCancel = _a.onCancel, onSave = _a.onSave;
        return (React.createElement("div", null,
            this.props.children,
            isActive && React.createElement(SaveCancel, { onSave: onSave, onCancel: onCancel })));
    };
    return SaveCancelContainer;
}(React.PureComponent));
export { SaveCancelContainer };
