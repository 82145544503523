var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import styles from './ChecklistView.module.scss';
import { observer } from 'mobx-react';
import { ChecklistViewDescription } from './ChecklistViewDescription/ChecklistViewDescription';
import { CheckListItemsSimpleTable } from '@app/Widgets/ChecklistView/CheckListItemsSimpleTable';
export var ChecklistView = /** @class */ (function (_super) {
    __extends(ChecklistView, _super);
    function ChecklistView() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChecklistView.prototype.render = function () {
        var _a;
        var checklist = (_a = this.props, _a.checklist), gateId = _a.gateId;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.description },
                React.createElement(ChecklistViewDescription, { checklist: checklist })),
            React.createElement("div", { className: styles.table },
                React.createElement("div", { className: styles.tableTitle }, "Deliverables"),
                React.createElement(CheckListItemsSimpleTable, { checklistId: checklist.id, isDeliverables: true, isApplicableForTemplate: !gateId })),
            React.createElement("div", { className: styles.table },
                React.createElement("div", { className: styles.tableTitle }, "Questions"),
                React.createElement(CheckListItemsSimpleTable, { checklistId: checklist.id, isDeliverables: false, isApplicableForTemplate: !gateId }))));
    };
    ChecklistView = __decorate([
        observer
    ], ChecklistView);
    return ChecklistView;
}(React.PureComponent));
