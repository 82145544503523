var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { ModalFooter } from 'reactstrap';
import { Button } from '@app/Components/Button/Button';
import { DialogActionType } from '@app/Stores/ModalStore';
import ModalBodyWithBoundary from '../ModalBodyWithBoundary';
var ErrorDialog = /** @class */ (function (_super) {
    __extends(ErrorDialog, _super);
    function ErrorDialog() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onClose = function () {
            _this.props.getModalInstance().close(DialogActionType.cancel);
        };
        return _this;
    }
    ErrorDialog.prototype.render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(ModalBodyWithBoundary, null,
                this.getMessage(),
                this.getRequestMessage()),
            React.createElement(ModalFooter, null,
                React.createElement(Button, { onClick: this.onClose }, "Ok"))));
    };
    ErrorDialog.prototype.getMessage = function () {
        var errorText = this.props.errorText;
        if (!errorText)
            return '';
        if (Array.isArray(errorText)) {
            return (React.createElement(React.Fragment, null, errorText.map(function (mess, index) { return (React.createElement("p", { key: index }, mess)); })));
        }
        return React.createElement("p", null, errorText);
    };
    ErrorDialog.prototype.getRequestMessage = function () {
        var error = this.props.error;
        if (!error || !error.response)
            return '';
        var errorData = error.response.data;
        return (React.createElement(React.Fragment, null,
            errorData && (React.createElement("p", null, Array.isArray(errorData)
                ? errorData.join(' ')
                : typeof errorData === 'string'
                    ? errorData
                    : errorData.error)),
            React.createElement("div", null,
                React.createElement("small", null,
                    "Code: ",
                    error.response.status)),
            React.createElement("div", null,
                React.createElement("small", null,
                    "URL: ",
                    error.config && error.config.url))));
    };
    return ErrorDialog;
}(React.PureComponent));
export { ErrorDialog };
