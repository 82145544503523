var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import styles from './CodeCell.module.scss';
import { HistoryButton } from '@app/Components/HistoryButton.tsx/HistoryButton';
var CodeCell = /** @class */ (function (_super) {
    __extends(CodeCell, _super);
    function CodeCell() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CodeCell.prototype.render = function () {
        var _a = this.props, code = _a.code, to = _a.to, onHistoryClick = _a.onHistoryClick;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.mainBlock },
                React.createElement("span", null, code),
                this.props.children),
            React.createElement(HistoryButton, { onClick: onHistoryClick, to: to })));
    };
    return CodeCell;
}(React.PureComponent));
export { CodeCell };
