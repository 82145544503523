var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { FunctionPickerStore } from './FunctionPickerStore';
import { observer } from 'mobx-react';
import { Select } from '@app/Components/Select/Select';
import { computed } from 'mobx';
export var FunctionPicker = /** @class */ (function (_super) {
    __extends(FunctionPicker, _super);
    function FunctionPicker(props) {
        var _this = _super.call(this, props) || this;
        _this._handleSelectChange = function (value) {
            var _a, _b;
            var funct = null;
            if (value) {
                var data_1 = value;
                var list = _this.props.externalFunctions ? _this.props.externalFunctions : _this._store.list;
                funct = (list === null || list === void 0 ? void 0 : list.find(function (f) { return f.id === parseInt(data_1.value); })) || null;
            }
            (_b = (_a = _this.props).onFunctionSelect) === null || _b === void 0 ? void 0 : _b.call(_a, funct);
        };
        _this._store = new FunctionPickerStore();
        return _this;
    }
    FunctionPicker.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var externalFunctions, actionForLoading;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        externalFunctions = (_a = this.props, _a.externalFunctions), actionForLoading = _a.actionForLoading;
                        if (!!externalFunctions) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._store.loadFunctions(actionForLoading)];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(FunctionPicker.prototype, "_pickerOptions", {
        get: function () {
            var externalFunctions = this.props.externalFunctions;
            var list = this._store.list;
            var functions = externalFunctions ? externalFunctions : list;
            if (!functions)
                return [];
            return functions
                .map(function (f) { return ({ label: "".concat(f.name, " (").concat(f.code, ")") || '', value: f.id.toString() }); })
                .sort(function (a, b) { return (a.label < b.label ? -1 : 1); });
        },
        enumerable: false,
        configurable: true
    });
    FunctionPicker.prototype.render = function () {
        return (React.createElement(Select, __assign({}, this.props, { options: this._pickerOptions, onChange: this._handleSelectChange, value: this.props.overrideValue ||
                (this.props.defaultFunction
                    ? {
                        label: "".concat(this.props.defaultFunction.name, " (").concat(this.props.defaultFunction.code, ")"),
                        value: this.props.defaultFunction.id.toString()
                    }
                    : undefined) })));
    };
    __decorate([
        computed
    ], FunctionPicker.prototype, "_pickerOptions", null);
    FunctionPicker = __decorate([
        observer
    ], FunctionPicker);
    return FunctionPicker;
}(React.Component));
