var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import styles from './ProjectViewTitle.module.scss';
import { FavouriteToggler } from '@app/Components/FavoriteToggler/FavouriteToggler';
import { SimpleToggler } from '../../../Components/ShowNAItemsToggler/SimpleToggler';
var ProjectViewTitle = /** @class */ (function (_super) {
    __extends(ProjectViewTitle, _super);
    function ProjectViewTitle() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProjectViewTitle.prototype.render = function () {
        var _a = this.props, isFavourite = _a.isFavourite, isShowRedDotDeliverableEnabled = _a.isShowRedDotDeliverableEnabled, isHideNAItemsEnabled = _a.isHideNAItemsEnabled, onFavouriteToggle = _a.onFavouriteToggle, onHideNAItemsToggle = _a.onHideNAItemsToggle, onShowRedDotDeliverableToggle = _a.onShowRedDotDeliverableToggle, status = _a.status;
        return (React.createElement("div", { className: styles.block },
            React.createElement("div", { className: styles.blockTitle },
                React.createElement("div", { className: styles.blockTitleText }, this.props.children),
                React.createElement("div", { className: styles.blockTitleLine }),
                status && React.createElement("span", { className: styles.status }, "Status: ".concat(status))),
            React.createElement("div", { className: styles.blockToggler, title: "Show Red Dot Deliverables" },
                React.createElement(SimpleToggler, { onToggle: function (checked) { return onShowRedDotDeliverableToggle(checked); }, checked: isShowRedDotDeliverableEnabled })),
            React.createElement("div", { className: styles.blockToggler, title: "Hide all NA items" },
                React.createElement(SimpleToggler, { onToggle: function (checked) { return onHideNAItemsToggle(checked); }, checked: isHideNAItemsEnabled })),
            React.createElement("div", { className: styles.blockToggler, title: "Mark as favourite" },
                React.createElement(FavouriteToggler, { onToggle: function (checked) { return onFavouriteToggle(checked); }, checked: isFavourite }))));
    };
    return ProjectViewTitle;
}(React.PureComponent));
export { ProjectViewTitle };
