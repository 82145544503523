var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { NavTab } from 'react-router-tabs';
import { TgTabs } from '@app/Components/TgTabs/TgTabs';
import { TgTabsContent } from '@app/Components/TgTabs/TgTabsContent';
import { NotFound } from '../NotFound/NotFound';
import { RolesOnObject } from './SecurityTabs/RolesOnObject/RolesOnObject';
import { RolesOfUserOnObject } from './SecurityTabs/RolesOfUserOnObject/RolesOfUserOnObject';
import { UsersInRole } from './SecurityTabs/UsersInRole/UsersInRole';
var Security = /** @class */ (function (_super) {
    __extends(Security, _super);
    function Security() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Security.prototype.render = function () {
        var match = this.props.match;
        return (React.createElement(React.Fragment, null,
            React.createElement(TgTabs, { startPathWith: match.url },
                React.createElement(NavTab, { to: "/rolesonobject" }, "Roles on Object"),
                React.createElement(NavTab, { to: "/userrolesonobject" }, "Roles of User on Object"),
                React.createElement(NavTab, { to: "/userinrolesonobject" }, "Users in Role / on Object")),
            React.createElement(TgTabsContent, null,
                React.createElement(Switch, null,
                    React.createElement(Route, { path: "".concat(match.url, "/rolesonobject") },
                        React.createElement(RolesOnObject, null)),
                    React.createElement(Route, { path: "".concat(match.url, "/userrolesonobject") },
                        React.createElement(RolesOfUserOnObject, null)),
                    React.createElement(Route, { path: "".concat(match.url, "/userinrolesonobject") },
                        React.createElement(UsersInRole, null)),
                    React.createElement(Route, { exact: true, path: "".concat(match.url) },
                        React.createElement(Redirect, { to: "".concat(match.url, "/rolesonobject") })),
                    React.createElement(Route, { path: "*", component: NotFound })))));
    };
    return Security;
}(React.PureComponent));
export { Security };
