var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
var CopyToClipBoardBlock = /** @class */ (function (_super) {
    __extends(CopyToClipBoardBlock, _super);
    function CopyToClipBoardBlock() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CopyToClipBoardBlock.prototype.render = function () {
        var _a = this.props, text = _a.text, children = _a.children, onCopyHandler = _a.onCopyHandler, onMouseEnterHandler = _a.onMouseEnterHandler, onMouseLeaveHandler = _a.onMouseLeaveHandler;
        return (React.createElement("div", { onClick: function () {
                var mark = document.createElement('div');
                mark.style.whiteSpace = 'pre';
                mark.style.position = 'fixed';
                mark.style.top = '0';
                mark.style.clip = 'rect(0, 0, 0, 0)';
                document.body.appendChild(mark);
                mark.innerHTML = text;
                var selection = window.getSelection();
                if (selection) {
                    selection.removeAllRanges();
                    var range = document.createRange();
                    range.selectNode(mark);
                    selection.addRange(range);
                    var listener = function (e) {
                        var _a, _b;
                        (_a = e.clipboardData) === null || _a === void 0 ? void 0 : _a.setData('text/html', text);
                        (_b = e.clipboardData) === null || _b === void 0 ? void 0 : _b.setData('text/plain', mark.innerText);
                        e.preventDefault();
                        onCopyHandler === null || onCopyHandler === void 0 ? void 0 : onCopyHandler();
                    };
                    document.addEventListener('copy', listener);
                    document.execCommand('copy');
                    document.removeEventListener('copy', listener);
                    selection.removeAllRanges();
                }
                document.body.removeChild(mark);
            }, onMouseEnter: onMouseEnterHandler, onMouseLeave: onMouseLeaveHandler }, children));
    };
    return CopyToClipBoardBlock;
}(React.Component));
export { CopyToClipBoardBlock };
