var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import styles from './ApprovedChecklistView.module.scss';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { ApprovedChecklistViewDescription } from './ApprovedChecklistViewDescription/ApprovedChecklistViewDescription';
import { ApprovedChecklistQuestionsTable } from './ApprovedChecklistQuestionsTable/ApprovedChecklistQuestionsTable';
import { ApprovedChecklistDeliversTable } from './ApprovedChecklistDeliversTable/ApprovedChecklistDeliversTable';
import { DocumentView } from '@app/Widgets/DocumentView/DocumentView';
import { withRouter, Switch, Route } from 'react-router-dom';
import { ApprovedChecklistStatusOverview } from './ApprovedChecklistStatusOverview/ApprovedChecklistStatusOverview';
import { ProjectStateContext } from '@app/Pages/ProjectView/Components/ProjectStateContext';
import { ChecklistSections } from '@app/AppConstants';
var ApprovedChecklistViewC = /** @class */ (function (_super) {
    __extends(ApprovedChecklistViewC, _super);
    function ApprovedChecklistViewC() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isFilesChanged = false;
        _this._documentViewSection = ChecklistSections.Function;
        _this._checklistStatusOverview = React.createRef();
        _this._checklistInfo = React.createRef();
        _this._checklistDeliversTable = React.createRef();
        _this._checklistQuestionsTable = React.createRef();
        _this.refreshChecklistStatusOverview = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, ((_a = this._checklistStatusOverview.current) === null || _a === void 0 ? void 0 : _a.refreshChecklistStatusOverview())];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.refreshChecklistInfo = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, ((_a = this._checklistInfo.current) === null || _a === void 0 ? void 0 : _a.refreshChecklistInfo())];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this._handleRefresh = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            (_a = this._checklistInfo.current) === null || _a === void 0 ? void 0 : _a.refreshChecklistInfo(),
                            (_b = this._checklistDeliversTable.current) === null || _b === void 0 ? void 0 : _b.reload()
                        ])];
                    case 1:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    ApprovedChecklistViewC.prototype._handleDocumentClick = function (ciid, section) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._documentViewSection = section;
                this.props.history.push("".concat(this.props.match.url, "/upload/cl-").concat(ciid));
                return [2 /*return*/];
            });
        });
    };
    ApprovedChecklistViewC.prototype._handleCheckListStateChanged = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, ((_b = (_a = this.props).onChecklistChanged) === null || _b === void 0 ? void 0 : _b.call(_a))];
                    case 1:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistViewC.prototype.render = function () {
        var _this = this;
        var _a = this.props, checklist = _a.checklist, projectId = _a.projectId, projectCodeName = _a.projectCodeName, gateId = _a.gateId, gateCodeName = _a.gateCodeName, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement("div", { className: styles.description },
                    React.createElement(ApprovedChecklistViewDescription, { ref: this._checklistInfo, checklistId: checklist.id, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted, onStateChanged: this._handleCheckListStateChanged })),
                React.createElement(ApprovedChecklistStatusOverview, { ref: this._checklistStatusOverview, checklistId: checklist.id }),
                React.createElement(ProjectStateContext.Consumer, null, function (projectState) { return (React.createElement("div", { className: styles.table },
                    React.createElement("div", { className: styles.tableTitle }, "Deliverables"),
                    React.createElement(ApprovedChecklistDeliversTable, { ref: _this._checklistDeliversTable, projectId: projectId, projectCodeName: projectCodeName, gateId: gateId, gateCodeName: gateCodeName, onDocumentIconClick: _this._handleDocumentClick, checklist: _this.props.checklist, isFilesChanged: _this._isFilesChanged, onRefreshChecklistStatusOverview: _this.refreshChecklistStatusOverview, onRefreshCheckListInfo: _this.refreshChecklistInfo, showRedDotDeliverable: projectState.showRedDotDeliverable, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted }))); }),
                React.createElement("div", { className: styles.table },
                    React.createElement("div", { className: styles.tableTitle }, "Questions"),
                    React.createElement(ApprovedChecklistQuestionsTable, { ref: this._checklistQuestionsTable, projectId: projectId, projectCodeName: projectCodeName, gateId: gateId, gateCodeName: gateCodeName, checklist: this.props.checklist, onRefreshChecklistStatusOverview: this.refreshChecklistStatusOverview, onRefreshCheckListInfo: this.refreshChecklistInfo, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted }))),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "".concat(this.props.match.url, "/upload/cl-:id") },
                    React.createElement(DocumentView, { backUrl: this.props.match.url, section: this._documentViewSection, onFileChange: function () { return (_this._isFilesChanged = !_this._isFilesChanged); }, onClose: this._handleRefresh, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })))));
    };
    __decorate([
        observable
    ], ApprovedChecklistViewC.prototype, "_isFilesChanged", void 0);
    __decorate([
        action.bound
    ], ApprovedChecklistViewC.prototype, "_handleDocumentClick", null);
    __decorate([
        action.bound
    ], ApprovedChecklistViewC.prototype, "_handleCheckListStateChanged", null);
    ApprovedChecklistViewC = __decorate([
        observer
    ], ApprovedChecklistViewC);
    return ApprovedChecklistViewC;
}(React.PureComponent));
export var ApprovedChecklistView = withRouter(ApprovedChecklistViewC);
