var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styles from './AssessmentGateDeliversTable.module.scss';
import columnSharedStyles from '@app/Components/Grid/SharedStyles/column-shared-styles.module.scss';
import { RatingPicker } from '@app/Components/RatingPicker/RatingPicker';
import { observer } from 'mobx-react';
import { Grid } from '@app/Components/Grid/Grid';
import { Column } from 'devextreme-react/data-grid';
import { ApprovalApplicationEnum, ChecklistItemType, ChecklistSections, Events, GateProcessTypeEnum, ObjectType, ProjectState, SignalREvents } from '@app/AppConstants';
import { action, observable } from 'mobx';
import { Api } from '@app/Api';
import { FunctionFilterContext } from '@app/Components/FunctonFilter/FunctionFilterContext';
import { filterDeliverableTableRowsByFunction } from '@app/Components/FunctonFilter/FunctionFilter';
import { EditCell } from '@app/Components/Grid/EditCell/EditCell';
import { CommentsSummary } from '@app/Components/Grid/CommentsSummary/CommentsSummary';
import { CommentsList } from '@app/Components/Grid/CommentsList/CommentsList';
import { RatingCell } from '@app/Components/Grid/RatingCell/RatingCell';
import { createUniqueId } from '@app/Helpers/UidHelper/UidHelper';
import HTMLReactParser from 'html-react-parser';
import { signalRService } from '@app/Services/SignalRService';
import { DocumentButton } from '@app/Components/DocumentButton/DocumentButton';
import { ChangeLogDialog } from '@app/Components/Dialogs/ChangeLogDialog';
import { AddActionItemWrapper } from '@app/Components/AddActionItemWrapper/AddActionItemWrapper';
import { ConfirmButton } from '@app/Components/ConfirmButton/ConfirmButton';
import { CodeCell } from '@app/Components/Grid/CodeCell/CodeCell';
import { FunctionList } from '@app/Components/Grid/FunctionList/FunctionList';
import { ApproveButton } from '@app/Pages/ProjectView/ProjectViewTabs/GatePreparations/ApprovedGateView/ApprovedChecklistView/ApprovedChecklistDeliversTable/ApproveButton/ApproveButton';
import { ApproveDenyDeliverableButtons } from '@app/Pages/ProjectView/ProjectViewTabs/GateDeliverables/DeliversTable/ApproveDenyDeliverableButtons';
import { Button } from '@app/Components/Button/Button';
import { ApproversModal } from '@app/Pages/ProjectView/ProjectViewTabs/GateDeliverables/DeliversTable/ApproversModal';
import { CommentModal } from '@app/Widgets/CommentModal/CommentModal';
import { RatingFilterAssessmentContext } from '@app/Components/RatingFilterAssessment/RatingFilterAssessmentContext';
import { filterItemsTableRowsByRoleAndRating } from '@app/Components/RatingFilterAssessment/RatingFilterAssessment';
import { DocumentStatusIcon } from '@app/Components/DocumentStatusIcon/DocumentStatusIcon';
import { DocumentStatusModal } from '@app/Components/DocumentStatusModal/DocumentStatusModal';
import { ExternalProviderButton } from '../../../GatePreparations/ApprovedGateView/ApprovedChecklistView/ExternalProviderButton/ExternalProviderButton';
export var AssessmentGateDeliversTable = /** @class */ (function (_super) {
    __extends(AssessmentGateDeliversTable, _super);
    function AssessmentGateDeliversTable() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._delivers = [];
        _this._currentChecklistitem = null;
        _this._denyItem = null;
        _this._currentDeliverable = null;
        _this._denyApprovalId = undefined;
        _this._unapprovedDeliverable = null;
        _this._currentMasterDeliverable = null;
        _this._isApproversModalOpen = false;
        _this._isCommentModalOpen = false;
        _this._isUnapproveDeliverableModalOpen = false;
        _this._isDocumentStatusModalOpen = false;
        _this._loading = false;
        _this._gridRef = React.createRef();
        return _this;
    }
    AssessmentGateDeliversTable.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadDelivers()];
                    case 1:
                        _a.sent();
                        signalRService.subscribe(SignalREvents.recommendationChanged, this._loadDelivers);
                        document.addEventListener(Events.assessmentGateDeliversConfirm, this._loadDelivers);
                        return [2 /*return*/];
                }
            });
        });
    };
    AssessmentGateDeliversTable.prototype.componentWillUnmount = function () {
        signalRService.unsubscribe(SignalREvents.recommendationChanged, this._loadDelivers);
        document.removeEventListener(Events.assessmentGateDeliversConfirm, this._loadDelivers);
    };
    AssessmentGateDeliversTable.prototype.componentDidUpdate = function (prevProps) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(prevProps.gateId !== this.props.gateId ||
                            prevProps.isFilesChanged !== this.props.isFilesChanged ||
                            prevProps.hideNAItems !== this.props.hideNAItems)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this._loadDelivers()];
                    case 1: return [4 /*yield*/, _a.sent()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AssessmentGateDeliversTable.prototype.reloadData = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadDelivers()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AssessmentGateDeliversTable.prototype._loadDelivers = function () {
        return __awaiter(this, void 0, void 0, function () {
            var projectId, gateId, projectState, hideNAItems, _a;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        projectId = (_b = this.props, _b.projectId), gateId = _b.gateId, projectState = _b.projectState, hideNAItems = _b.hideNAItems;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, , 3, 4]);
                        this._loading = true;
                        _a = this;
                        return [4 /*yield*/, Api.secured.getGateAssessmentKeyDeliverables({
                                projectId: projectId,
                                gateId: gateId,
                                projectState: projectState,
                                hideNAItems: hideNAItems
                            })];
                    case 2:
                        _a._delivers = _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this._loading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AssessmentGateDeliversTable.prototype._handleDeny = function (comment) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 3, 5]);
                        this._loading = true;
                        if (!(this._denyItem && this._denyApprovalId)) return [3 /*break*/, 2];
                        return [4 /*yield*/, Api.secured.denyDeliverable(this._denyItem.id, {
                                approvalId: this._denyApprovalId,
                                comment: comment
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this._denyItem = null;
                        return [3 /*break*/, 5];
                    case 3:
                        this._loading = false;
                        return [4 /*yield*/, this._refreshDeliverables()];
                    case 4:
                        _a.sent();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    AssessmentGateDeliversTable.prototype._refreshDeliverables = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadDelivers()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.props.onRefreshChecklistStatusOverview()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AssessmentGateDeliversTable.prototype._denyDeliverable = function (deliverable) {
        if (deliverable) {
            this._toggleCommentModalOpen();
            this._denyItem = deliverable;
            this._denyApprovalId = deliverable.approvalId;
        }
    };
    AssessmentGateDeliversTable.prototype._handleUndoApprovalClick = function (deliverable) {
        this._toggleUnapproveDeliverableModalOpen();
        this._unapprovedDeliverable = deliverable;
    };
    AssessmentGateDeliversTable.prototype._showApprovers = function (deliverable) {
        if (deliverable) {
            this._currentDeliverable = deliverable;
            this._toggleApproversModal();
        }
    };
    AssessmentGateDeliversTable.prototype._savePMDeliverable = function (rowData, rowIndex, isRepaint) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var item_1, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 5, , 8]);
                        return [4 /*yield*/, Api.secured.updateProjectManagerDeliverable({ body: rowData })];
                    case 1:
                        _b.sent();
                        if (!isRepaint) return [3 /*break*/, 3];
                        return [4 /*yield*/, Api.secured.getGateAssessmentKeyDeliverable({ checklistItemId: rowData.id })];
                    case 2:
                        item_1 = _b.sent();
                        (_a = Object.keys(rowData)) === null || _a === void 0 ? void 0 : _a.forEach(function (key) {
                            rowData[key] = item_1[key];
                        });
                        if (this._gridRef.current) {
                            this._gridRef.current.repaintRows([rowIndex]);
                        }
                        _b.label = 3;
                    case 3: return [4 /*yield*/, this.props.onRefreshChecklistStatusOverview()];
                    case 4:
                        _b.sent();
                        return [3 /*break*/, 8];
                    case 5:
                        error_1 = _b.sent();
                        if (!error_1) return [3 /*break*/, 7];
                        console.error(error_1);
                        return [4 /*yield*/, this._loadDelivers()];
                    case 6:
                        _b.sent();
                        _b.label = 7;
                    case 7: return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    AssessmentGateDeliversTable.prototype._saveAssessorDeliverable = function (rowData, rowIndex, isRepaint) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var item_2, error_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 5, , 8]);
                        return [4 /*yield*/, Api.secured.updateAssessorDeliverable({ body: rowData })];
                    case 1:
                        _b.sent();
                        if (!isRepaint) return [3 /*break*/, 3];
                        return [4 /*yield*/, Api.secured.getGateAssessmentKeyDeliverable({ checklistItemId: rowData.id })];
                    case 2:
                        item_2 = _b.sent();
                        (_a = Object.keys(rowData)) === null || _a === void 0 ? void 0 : _a.forEach(function (key) {
                            rowData[key] = item_2[key];
                        });
                        if (this._gridRef.current) {
                            this._gridRef.current.repaintRows([rowIndex]);
                        }
                        _b.label = 3;
                    case 3: return [4 /*yield*/, this.props.onRefreshChecklistStatusOverview()];
                    case 4:
                        _b.sent();
                        return [3 /*break*/, 8];
                    case 5:
                        error_2 = _b.sent();
                        if (!error_2) return [3 /*break*/, 7];
                        console.error(error_2);
                        return [4 /*yield*/, this._loadDelivers()];
                    case 6:
                        _b.sent();
                        _b.label = 7;
                    case 7: return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    AssessmentGateDeliversTable.prototype._saveOkAssessorPart = function (checkListItemId, rowData, rowIndex) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var projectState, row;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        projectState = this.props.projectState;
                        return [4 /*yield*/, Api.secured.okDeliverableAssessorPart({ checkListItemId: checkListItemId, projectState: projectState })];
                    case 1:
                        row = _b.sent();
                        (_a = Object.keys(rowData)) === null || _a === void 0 ? void 0 : _a.forEach(function (key) {
                            rowData[key] = row[key];
                        });
                        if (this._gridRef.current) {
                            this._gridRef.current.repaintRows([rowIndex]);
                        }
                        return [4 /*yield*/, this.props.onRefreshChecklistStatusOverview()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AssessmentGateDeliversTable.prototype._handleUndoApproval = function (comment) {
        return __awaiter(this, void 0, void 0, function () {
            var id, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this._unapprovedDeliverable)
                            return [2 /*return*/];
                        id = this._unapprovedDeliverable.id;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, , 7, 8]);
                        this._loading = true;
                        _a = this._unapprovedDeliverable.checkListItemType;
                        switch (_a) {
                            case ChecklistItemType.MasterDeliverableWithoutFunctionDeliverable: return [3 /*break*/, 2];
                            case ChecklistItemType.MasterDeliverable: return [3 /*break*/, 4];
                        }
                        return [3 /*break*/, 6];
                    case 2: return [4 /*yield*/, Api.secured.undoDeliverableApprovalWithoutInput(id, { comment: comment })];
                    case 3:
                        _b.sent();
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, Api.secured.undoMasterDeliverable(id, { comment: comment })];
                    case 5:
                        _b.sent();
                        return [3 /*break*/, 6];
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        this._loading = false;
                        return [7 /*endfinally*/];
                    case 8: return [4 /*yield*/, this._loadDelivers()];
                    case 9: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    AssessmentGateDeliversTable.prototype._onApprovalChangedHandler = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._toggleApproversModal();
                        return [4 /*yield*/, this._refreshDeliverables()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AssessmentGateDeliversTable.prototype._toggleLogModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._currentChecklistitem = null;
                return [2 /*return*/];
            });
        });
    };
    AssessmentGateDeliversTable.prototype._toggleCommentModalOpen = function () {
        this._isCommentModalOpen = !this._isCommentModalOpen;
        if (!this._isCommentModalOpen)
            this._denyItem = null;
    };
    AssessmentGateDeliversTable.prototype._toggleUnapproveDeliverableModalOpen = function () {
        this._isUnapproveDeliverableModalOpen = !this._isUnapproveDeliverableModalOpen;
        if (!this._isUnapproveDeliverableModalOpen)
            this._unapprovedDeliverable = null;
    };
    AssessmentGateDeliversTable.prototype._toggleApproversModal = function () {
        this._isApproversModalOpen = !this._isApproversModalOpen;
    };
    AssessmentGateDeliversTable.prototype._toggleDocumentStatusModalOpen = function () {
        this._isDocumentStatusModalOpen = !this._isDocumentStatusModalOpen;
        if (!this._isDocumentStatusModalOpen)
            this._currentMasterDeliverable = null;
    };
    AssessmentGateDeliversTable.prototype._showChildDeliverables = function (deliverable) {
        var projectState = this.props.projectState;
        if (projectState === ProjectState.Full &&
            deliverable.checkListItemType === ChecklistItemType.MasterDeliverable) {
            this._currentMasterDeliverable = deliverable;
            this._toggleDocumentStatusModalOpen();
        }
    };
    AssessmentGateDeliversTable.prototype.render = function () {
        var _a, _b;
        var _this = this;
        var onDocumentIconClick = (_a = this.props, _a.onDocumentIconClick), projectState = _a.projectState, projectType = _a.projectType, projectId = _a.projectId, gateId = _a.gateId, onRefreshActionItems = _a.onRefreshActionItems, showRedDotDeliverable = _a.showRedDotDeliverable, hideNAItems = _a.hideNAItems, isFilesChanged = _a.isFilesChanged, openModalLink = _a.openModalLink, projectCodeName = _a.projectCodeName, gateName = _a.gateName, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted;
        var _delivers = (_b = this, _b._delivers), _loading = _b._loading;
        return (React.createElement(React.Fragment, null,
            React.createElement(RatingFilterAssessmentContext.Consumer, null, function (ratingFilterValue) {
                return (React.createElement(FunctionFilterContext.Consumer, null, function (functionFilterValue) {
                    var rows = filterItemsTableRowsByRoleAndRating(_delivers, ratingFilterValue);
                    rows = filterDeliverableTableRowsByFunction(rows, functionFilterValue);
                    var count = rows.length;
                    return (React.createElement(Grid, { ref: _this._gridRef, dataSource: __spreadArray([], rows, true), expandableRows: true, rowsCount: count, loading: _loading },
                        React.createElement(Column, { caption: "ID", dataField: "code", width: 150, cssClass: columnSharedStyles.colWithHistory, cellRender: function (_a) {
                                var data = _a.data;
                                return (React.createElement(React.Fragment, null,
                                    React.createElement(CodeCell, { code: data.code, onHistoryClick: function () { return (_this._currentChecklistitem = data); } }, data.canPrincipalUpdateProjectManagerPart &&
                                        data.masterCheckListItemOnly && (React.createElement(ExternalProviderButton, { projectName: projectCodeName, projectId: projectId, gateId: gateId, gateCodeName: gateName, checkListItemId: data.id, taskId: data.taskId || null, taskStatus: data.taskStatus, onRefresh: _this._loadDelivers, disable: isProjectTerminatedOrCompleted }))),
                                    data.masterDeliverable &&
                                        data.masterDeliverable.stateAtGateAssessment &&
                                        data.masterDeliverable.currentStatus && (React.createElement(DocumentStatusIcon, { targetStatus: data.masterDeliverable.stateAtGateAssessment, isFileUploaded: data.masterDeliverable.hasFile, isCommented: data.masterDeliverable.hasComment, isRated: data.masterDeliverable.hasRating, currentStatus: data.masterDeliverable.currentStatus, deliverableId: data.id, isExternalManagement: data.masterDeliverable.approvalApplication !==
                                            ApprovalApplicationEnum.TeamGate, isNotApplicable: data.masterDeliverable.isApprovalDisabled ||
                                            data.masterDeliverable
                                                .isNotApplicableAfterApproval, rating: data.masterDeliverable.rating, onClick: function () {
                                            return data.masterDeliverable &&
                                                _this._showChildDeliverables(data.masterDeliverable);
                                        } }))));
                            } }),
                        React.createElement(Column, { caption: "Functions", width: 90, cellRender: function (_a) {
                                var data = _a.data;
                                return (React.createElement(FunctionList, { functions: data.functions, rowId: '' + data.id }));
                            } }),
                        React.createElement(Column, { caption: "Key Deliverables", dataField: "keyDeliverables", width: 350, cellRender: function (_a) {
                                var data = _a.data;
                                return React.createElement("div", { style: { whiteSpace: 'pre-line' } }, HTMLReactParser(data.keyDeliverables));
                            } }),
                        React.createElement(Column, { caption: "Details", dataField: "guideline", minWidth: 300, cellRender: function (_a) {
                                var data = _a.data;
                                return React.createElement("div", { style: { whiteSpace: 'pre-line' } }, HTMLReactParser(data.guideline));
                            } }),
                        projectState !== ProjectState.HasOnlyMasterCheckLists && (React.createElement(Column, { caption: "Function Comments", width: 200, cellRender: function (_a) {
                                var data = _a.data;
                                return (React.createElement("div", null,
                                    data.masterCheckListItemOnly ? null : (React.createElement(CommentsSummary, { id: 'f' + data.id, green: data.greenRatingCount, yellow: data.yellowRatingCount, red: data.redRatingCount, notApplicable: data.notApplicableRatingCount, notRated: data.notRatingCount, notRatedCodes: data.notRatingCheckListItems, notCommented: data.notCommentedCount, notCommentedCodes: data.notCommentedCheckListItems, link: openModalLink, isVisible: true })),
                                    data.functionalComments && (React.createElement(CommentsList, { comments: data.functionalComments }))));
                            } })),
                        projectState !== ProjectState.HasOnlyMasterCheckLists && (React.createElement(Column, { headerCellRender: function () { return React.createElement("div", { title: "Function Rating" }, "Rating"); }, width: 60, cellRender: function (_a) {
                                var data = _a.data;
                                return (React.createElement("div", { className: styles.frating },
                                    React.createElement(RatingPicker, { rating: data.functionalRating, disabled: true })));
                            } })),
                        React.createElement(Column, { caption: "Project Manager Comments", dataField: "projectManagerOriginalComment", width: 300, cssClass: columnSharedStyles.commentColumn, cellRender: function (_a) {
                                var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                                return (React.createElement(EditCell, { value: data.projectManagerOriginalComment, onChange: function (value) {
                                        data.projectManagerOriginalComment = value;
                                        if (_this._gridRef.current) {
                                            _this._gridRef.current.repaintRows([rowIndex]);
                                        }
                                    }, rowIndex: rowIndex, columnIndex: columnIndex, onSave: function () { return _this._savePMDeliverable(data, rowIndex); }, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), withCopy: true, disabled: !data.canPrincipalUpdateProjectManagerPart ||
                                        isProjectTerminatedOrCompleted, section: ChecklistSections.ProjectManager },
                                    React.createElement(CommentsSummary, { id: 'p' + data.id, green: data.projectManagerGreenRatingCount, yellow: data.projectManagerYellowRatingCount, red: data.projectManagerRedRatingCount, notApplicable: data.projectManagerNotApplicableRatingCount, notRated: data.projectManagerNotRatingCount, notRatedCodes: data.projectManagerNotRatingCheckListItems, notCommented: data.projectManagerNotCommentedCount, notCommentedCodes: data.projectManagerNotCommentedCheckListItems, link: openModalLink, isVisible: !(projectState ===
                                            ProjectState.HasOnlyMasterCheckLists ||
                                            data.masterCheckListItemOnly) }),
                                    React.createElement(CommentsList, { comments: data.projectManagerComments })));
                            } }),
                        React.createElement(Column, { headerCellRender: function () { return (React.createElement("div", { title: "Project Manager Rating" }, "Rating")); }, width: 110, cssClass: columnSharedStyles.commentColumn, cellRender: function (_a) {
                                var _b, _c;
                                var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                                return (React.createElement(AddActionItemWrapper, { projectId: projectId, gateId: gateId, gateProcessType: GateProcessTypeEnum.GateAssessment, disabled: !data.canPrincipalAddActionItem ||
                                        _this._loading ||
                                        isProjectTerminatedOrCompleted, data: data, isForProjectManager: true, onActionItemSave: onRefreshActionItems },
                                    React.createElement(React.Fragment, null,
                                        React.createElement(RatingCell, { rowIndex: rowIndex, columnIndex: columnIndex, onSave: function () {
                                                return _this._savePMDeliverable(data, rowIndex, true);
                                            }, onChange: function (value) { return __awaiter(_this, void 0, void 0, function () {
                                                return __generator(this, function (_a) {
                                                    data.projectManagerOriginalRating = value;
                                                    data.projectManagerRating = value;
                                                    return [2 /*return*/];
                                                });
                                            }); }, rating: data.projectManagerRating, text: data.projectManagerOriginalComment, textTitle: "Comment", hasDocuments: data.isDocumentUploadVisible &&
                                                data.canDocumentBeUploadByPjm
                                                ? data.hasDocuments
                                                : undefined, showValidationError: data.canPrincipalUpdateProjectManagerPart &&
                                                ((projectState !==
                                                    ProjectState.HasOnlyMasterCheckLists &&
                                                    data.masterCheckListItemOnly) ||
                                                    projectState ===
                                                        ProjectState.HasOnlyMasterCheckLists), objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), disabled: !data.canPrincipalUpdateProjectManagerPart ||
                                                _this._loading ||
                                                isProjectTerminatedOrCompleted, section: ChecklistSections.ProjectManager, controlId: "pmRating".concat(data.id) }),
                                        data.masterDeliverable &&
                                            !data.masterDeliverable.isApprovalDisabled && (React.createElement("div", { className: styles.statusButton },
                                            data.masterDeliverable
                                                .isReadyForApprovalVisible && (React.createElement(ApproveButton, { deliverable: data.masterDeliverable, rating: data.projectManagerRating, comment: data.projectManagerOriginalComment, isDisabled: !data.masterDeliverable
                                                    .canPrincipalSetInApprovalDeliverable ||
                                                    isProjectTerminatedOrCompleted, onPrepare: _this._refreshDeliverables, hasRedDot: ((_b = data.masterDeliverable) === null || _b === void 0 ? void 0 : _b.hasRedDot) &&
                                                    showRedDotDeliverable })),
                                            !_this._isApproversModalOpen &&
                                                !_this._loading &&
                                                data.masterDeliverable
                                                    .isApprovalActionVisible && (React.createElement(ApproveDenyDeliverableButtons, { disabled: isProjectTerminatedOrCompleted, deliverable: data.masterDeliverable, deliverableApproverId: data.masterDeliverable
                                                    .canPrincipalImmediateApproveDeliverable
                                                    ? null
                                                    : data.masterDeliverable
                                                        .approvalId || null, canApproveWithoutApprover: data.masterDeliverable
                                                    .canPrincipalImmediateApproveDeliverable, isApproveDisabled: _this._isApproversModalOpen ||
                                                    _this._loading, onDenyHandler: function () {
                                                    return _this._denyDeliverable(data.masterDeliverable);
                                                }, onApprovedHandler: _this._refreshDeliverables, parentClassName: styles.approveDenyBlock, childClassName: styles.approveDenyButton })),
                                            data.masterDeliverable
                                                .canPrincipalSetToDraftDeliverable && (React.createElement(Button, { className: styles.action, size: "sm", color: "success", onClick: function () {
                                                    return _this._handleUndoApprovalClick(data.masterDeliverable);
                                                }, disabled: _this._loading ||
                                                    isProjectTerminatedOrCompleted }, "Undo Approval")))),
                                        data.isDocumentUploadVisible &&
                                            data.canDocumentBeUploadByPjm && (React.createElement("div", { className: styles.commentActions },
                                            React.createElement("div", { className: "".concat(styles.commentAction, " ").concat(styles.documentIconWrapper) },
                                                onDocumentIconClick && (React.createElement(DocumentButton, { hasDocument: data.hasDocuments, onDocumentIconClick: function () { return __awaiter(_this, void 0, void 0, function () {
                                                        return __generator(this, function (_a) {
                                                            switch (_a.label) {
                                                                case 0: return [4 /*yield*/, (onDocumentIconClick === null || onDocumentIconClick === void 0 ? void 0 : onDocumentIconClick(data.id, ChecklistSections.ProjectManager))];
                                                                case 1: return [2 /*return*/, _a.sent()];
                                                            }
                                                        });
                                                    }); }, hasRedDot: ((_c = data.masterDeliverable) === null || _c === void 0 ? void 0 : _c.hasRedDot) &&
                                                        showRedDotDeliverable })),
                                                data.masterDeliverable &&
                                                    data.masterDeliverable
                                                        .isApprovalButtonVisible && (React.createElement(Button, { color: "success", size: "xs", className: styles.approversButton, onClick: function () {
                                                        return _this._showApprovers(data.masterDeliverable);
                                                    } }, "A"))))))));
                            } }),
                        React.createElement(Column, { caption: "Gate Assessor Comment", dataField: "assessorOriginalComment", width: 300, cssClass: styles.gateAssessorCommentCell, cellRender: function (_a) {
                                var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                                return (React.createElement(EditCell, { rowIndex: rowIndex, columnIndex: columnIndex, value: data.assessorOriginalComment, onChange: function (value) {
                                        data.assessorOriginalComment = value;
                                        if (_this._gridRef.current) {
                                            _this._gridRef.current.repaintRows([rowIndex]);
                                        }
                                    }, onSave: function () { return _this._saveAssessorDeliverable(data, rowIndex); }, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), withCopy: true, disabled: !data.canPrincipalUpdateGateAssessorPart ||
                                        isProjectTerminatedOrCompleted, section: ChecklistSections.Assessor },
                                    React.createElement(CommentsSummary, { id: 'a' + data.id, green: data.assessorGreenRatingCount, yellow: data.assessorYellowRatingCount, red: data.assessorRedRatingCount, notApplicable: data.assessorNotApplicableRatingCount, notRated: data.assessorNotRatingCount, notRatedCodes: data.assessorNotRatingCheckListItems, notCommented: data.assessorNotCommentedCount, notCommentedCodes: data.assessorNotCommentedCheckListItems, link: openModalLink, isVisible: !(projectState ===
                                            ProjectState.HasOnlyMasterCheckLists ||
                                            data.masterCheckListItemOnly) }),
                                    React.createElement(CommentsList, { comments: data.assessorComments })));
                            } }),
                        React.createElement(Column, { headerCellRender: function () { return React.createElement("div", { title: "Gate Assessor Rating" }, "Rating"); }, width: 110, cssClass: styles.assessorRating, cellRender: function (_a) {
                                var _b;
                                var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                                return (React.createElement(AddActionItemWrapper, { projectId: projectId, gateId: gateId, gateProcessType: GateProcessTypeEnum.GateAssessment, disabled: !data.canPrincipalAddActionItem ||
                                        isProjectTerminatedOrCompleted, data: data, onActionItemSave: onRefreshActionItems },
                                    React.createElement(React.Fragment, null,
                                        React.createElement(RatingCell, { rowIndex: rowIndex, columnIndex: columnIndex, onSave: function () {
                                                return _this._saveAssessorDeliverable(data, rowIndex, true);
                                            }, onChange: function (value) {
                                                data.assessorOriginalRating = value;
                                                data.assessorRating = value;
                                            }, rating: data.assessorRating, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), disabled: !data.canPrincipalUpdateGateAssessorPart ||
                                                _this._loading ||
                                                isProjectTerminatedOrCompleted, section: ChecklistSections.Assessor, controlId: "gaRating".concat(data.id), confirmButton: data.canPrincipalUpdateGateAssessorPart ? (React.createElement("div", { className: styles.confirmButton },
                                                React.createElement(ConfirmButton, { disabled: isProjectTerminatedOrCompleted, id: 'tooltip' + data.id, 
                                                    // isLoading={this.context.loading}
                                                    onClick: function () {
                                                        return _this._saveOkAssessorPart(data.id, data, rowIndex);
                                                    } }))) : (undefined) }),
                                        data.isDocumentUploadVisible && (React.createElement("div", { className: styles.commentActions },
                                            React.createElement("div", { className: "".concat(styles.commentAction, " ").concat(styles.documentIconWrapper) }, onDocumentIconClick && (React.createElement(DocumentButton, { hasDocument: data.hasDocuments, onDocumentIconClick: function () { return __awaiter(_this, void 0, void 0, function () {
                                                    return __generator(this, function (_a) {
                                                        switch (_a.label) {
                                                            case 0: return [4 /*yield*/, (onDocumentIconClick === null || onDocumentIconClick === void 0 ? void 0 : onDocumentIconClick(data.id, ChecklistSections.Assessor))];
                                                            case 1: return [2 /*return*/, _a.sent()];
                                                        }
                                                    });
                                                }); }, hasRedDot: ((_b = data.masterDeliverable) === null || _b === void 0 ? void 0 : _b.hasRedDot) &&
                                                    showRedDotDeliverable }))))))));
                            } })));
                }));
            }),
            this._currentChecklistitem && (React.createElement(ChangeLogDialog, { onToggle: this._toggleLogModal, uniqueId: createUniqueId(ObjectType.ChecklistItem, this._currentChecklistitem.id) })),
            this._currentMasterDeliverable && (React.createElement(DocumentStatusModal, { isOpen: this._isDocumentStatusModalOpen, toggle: this._toggleDocumentStatusModalOpen, checklistItemId: this._currentMasterDeliverable.checkListItemId, checklistItemName: this._currentMasterDeliverable.checkListItemCode, projectId: projectId, gateId: gateId, projectState: projectState, projectType: projectType, hideNAItems: hideNAItems, showRedDotDeliverable: showRedDotDeliverable, onDocumentIconClick: onDocumentIconClick, isFilesChanged: isFilesChanged, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })),
            React.createElement(CommentModal, { isOpen: this._isUnapproveDeliverableModalOpen, onToggle: this._toggleUnapproveDeliverableModalOpen, onSave: this._handleUndoApproval }),
            React.createElement(CommentModal, { isOpen: this._isCommentModalOpen, onToggle: this._toggleCommentModalOpen, onSave: this._handleDeny }),
            React.createElement(ApproversModal, { isOpen: this._isApproversModalOpen, toggle: this._toggleApproversModal, onApprovalChanged: this._onApprovalChangedHandler, deliverable: this._currentDeliverable, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })));
    };
    __decorate([
        observable
    ], AssessmentGateDeliversTable.prototype, "_delivers", void 0);
    __decorate([
        observable
    ], AssessmentGateDeliversTable.prototype, "_currentChecklistitem", void 0);
    __decorate([
        observable
    ], AssessmentGateDeliversTable.prototype, "_denyItem", void 0);
    __decorate([
        observable
    ], AssessmentGateDeliversTable.prototype, "_currentDeliverable", void 0);
    __decorate([
        observable
    ], AssessmentGateDeliversTable.prototype, "_denyApprovalId", void 0);
    __decorate([
        observable
    ], AssessmentGateDeliversTable.prototype, "_unapprovedDeliverable", void 0);
    __decorate([
        observable
    ], AssessmentGateDeliversTable.prototype, "_currentMasterDeliverable", void 0);
    __decorate([
        observable
    ], AssessmentGateDeliversTable.prototype, "_isApproversModalOpen", void 0);
    __decorate([
        observable
    ], AssessmentGateDeliversTable.prototype, "_isCommentModalOpen", void 0);
    __decorate([
        observable
    ], AssessmentGateDeliversTable.prototype, "_isUnapproveDeliverableModalOpen", void 0);
    __decorate([
        observable
    ], AssessmentGateDeliversTable.prototype, "_isDocumentStatusModalOpen", void 0);
    __decorate([
        observable
    ], AssessmentGateDeliversTable.prototype, "_loading", void 0);
    __decorate([
        observable.ref
    ], AssessmentGateDeliversTable.prototype, "_gridRef", void 0);
    __decorate([
        action.bound
    ], AssessmentGateDeliversTable.prototype, "reloadData", null);
    __decorate([
        action.bound
    ], AssessmentGateDeliversTable.prototype, "_loadDelivers", null);
    __decorate([
        action.bound
    ], AssessmentGateDeliversTable.prototype, "_handleDeny", null);
    __decorate([
        action.bound
    ], AssessmentGateDeliversTable.prototype, "_refreshDeliverables", null);
    __decorate([
        action.bound
    ], AssessmentGateDeliversTable.prototype, "_denyDeliverable", null);
    __decorate([
        action.bound
    ], AssessmentGateDeliversTable.prototype, "_handleUndoApprovalClick", null);
    __decorate([
        action.bound
    ], AssessmentGateDeliversTable.prototype, "_showApprovers", null);
    __decorate([
        action.bound
    ], AssessmentGateDeliversTable.prototype, "_savePMDeliverable", null);
    __decorate([
        action.bound
    ], AssessmentGateDeliversTable.prototype, "_saveAssessorDeliverable", null);
    __decorate([
        action.bound
    ], AssessmentGateDeliversTable.prototype, "_saveOkAssessorPart", null);
    __decorate([
        action.bound
    ], AssessmentGateDeliversTable.prototype, "_handleUndoApproval", null);
    __decorate([
        action.bound
    ], AssessmentGateDeliversTable.prototype, "_onApprovalChangedHandler", null);
    __decorate([
        action.bound
    ], AssessmentGateDeliversTable.prototype, "_toggleLogModal", null);
    __decorate([
        action.bound
    ], AssessmentGateDeliversTable.prototype, "_toggleCommentModalOpen", null);
    __decorate([
        action.bound
    ], AssessmentGateDeliversTable.prototype, "_toggleUnapproveDeliverableModalOpen", null);
    __decorate([
        action.bound
    ], AssessmentGateDeliversTable.prototype, "_toggleApproversModal", null);
    __decorate([
        action.bound
    ], AssessmentGateDeliversTable.prototype, "_toggleDocumentStatusModalOpen", null);
    __decorate([
        action.bound
    ], AssessmentGateDeliversTable.prototype, "_showChildDeliverables", null);
    AssessmentGateDeliversTable = __decorate([
        observer
    ], AssessmentGateDeliversTable);
    return AssessmentGateDeliversTable;
}(React.Component));
