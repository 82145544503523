var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { Column, Sorting, FilterRow, Scrolling } from 'devextreme-react/data-grid';
import { Grid } from '@app/Components/Grid/Grid';
import { Link } from '@app/Components/Link/Link';
import { Status } from '@app/AppConstants';
var MyProjects = /** @class */ (function (_super) {
    __extends(MyProjects, _super);
    function MyProjects() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MyProjects.prototype._renderGates = function (data) {
        var gates = [];
        for (var i = 0; i < data.gateLinks.length; i++) {
            var gate = data.gateLinks[i];
            gates.push(gate.setupStatus == Status.Approved ? (React.createElement(Link, { key: "gate-link-".concat(gate.id), to: "/projects/".concat(data.projectCode, "/preparations/g-").concat(gate.id) }, "".concat(gate.displayName))) : (gate.displayName));
            if (i < data.gateLinks.length - 1) {
                gates.push(', ');
            }
        }
        return gates;
    };
    MyProjects.prototype.render = function () {
        var _this = this;
        return (React.createElement(Grid, { dataSource: this.props.projects, loading: this.props.isLoading, grouping: {
                contextMenuEnabled: true
            }, groupPanel: {
                visible: true,
                emptyPanelText: "MY PROJECTS"
            }, summary: {
                totalItems: [
                    {
                        column: 'Project',
                        summaryType: 'count',
                        customizeText: function (data) {
                            return "Total Projects: ".concat(data.value);
                        }
                    }
                ]
            }, style: { maxHeight: '300px' } },
            React.createElement(Scrolling, { mode: "virtual" }),
            React.createElement(Sorting, { mode: "multiple" }),
            React.createElement(FilterRow, { visible: true }),
            React.createElement(Column, { caption: "Project", dataField: "projectFullDisplayName", cellRender: function (_a) {
                    var data = _a.data;
                    return (React.createElement(Link, { to: "/projects/".concat(data.projectCode) }, data.projectFullDisplayName));
                } }),
            React.createElement(Column, { caption: "Gates", dataField: "gates", width: 180, cellRender: function (_a) {
                    var data = _a.data;
                    return _this._renderGates(data);
                } }),
            React.createElement(Column, { caption: "Project Management", dataField: "projectManagementDisplayNames", width: 180 }),
            React.createElement(Column, { caption: "Gate Assessors", dataField: "gateAssessorDisplayNames", width: 180 }),
            React.createElement(Column, { caption: "My Functions", dataField: "functionCodes", width: 105 })));
    };
    return MyProjects;
}(React.PureComponent));
export { MyProjects };
