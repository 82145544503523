var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observable, action, computed } from 'mobx';
import { observer } from 'mobx-react';
import styles from './UpdateTaskModal.module.scss';
import { Api } from '@app/Api';
import { Modal } from '@app/Components/Modal/Modal';
import { TaskInfo, TaskInfoFormModel } from '@app/Widgets/ManualTaskModals/TaskInfo';
import { Loader } from '@app/Components/Loader/Loader';
import { withRouter } from 'react-router-dom';
import { ManualTaskStatusEnum, ManualTaskStateEnum, StatusEnum } from '@app/AppConstants';
import { ManualTaskActionButtons } from '@app/Components/ManualTaskActionButtons/ManualTaskActionButtons';
var UpdateTaskModalComponent = /** @class */ (function (_super) {
    __extends(UpdateTaskModalComponent, _super);
    function UpdateTaskModalComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._task = null;
        _this._loading = false;
        _this._actionLoading = false;
        _this._formData = new TaskInfoFormModel();
        _this._isManualTaskDeleteModalOpen = false;
        _this._isManualTaskDenyCommentModalOpen = false;
        _this._currentManualTaskId = null;
        _this.id = null;
        return _this;
    }
    UpdateTaskModalComponent.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.id = parseInt(this.props.match.params.id);
                        if (!this.id) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.updateFullTask()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    UpdateTaskModalComponent.prototype.loadTask = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.id)
                            return [2 /*return*/];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        this._loading = true;
                        return [4 /*yield*/, Api.secured.getManualTask(this.id)];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3:
                        this._loading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    UpdateTaskModalComponent.prototype.updateFullTask = function () {
        return __awaiter(this, void 0, void 0, function () {
            var task;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadTask()];
                    case 1:
                        task = _a.sent();
                        if (task) {
                            this.updateFormFromTask(task);
                            this._task = task;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    UpdateTaskModalComponent.prototype.updateTaskStatus = function () {
        return __awaiter(this, void 0, void 0, function () {
            var task;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadTask()];
                    case 1:
                        task = _a.sent();
                        if (task && this._task) {
                            this._task.status = task.status;
                        }
                        if (!this.props.afterSave) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.props.afterSave()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UpdateTaskModalComponent.prototype.updateFormFromTask = function (task) {
        var _a, _b;
        this._formData.gateId = (_a = task.gate) === null || _a === void 0 ? void 0 : _a.value;
        if (task.gate) {
            this._formData.gate = {
                value: task.gate.value,
                label: task.gate.label || ''
            };
        }
        this._formData.projectId = task.project.value;
        this._formData.checkListId = (_b = task.checkListItem) === null || _b === void 0 ? void 0 : _b.value;
        this._formData.description = task.description || '';
        this._formData.title = task.title || '';
        this._formData.answer = task.answer || '';
        this._formData.deadLine = task.dueDate;
        this._formData.approver = task.approver;
        this._formData.assignee = task.assignee;
    };
    UpdateTaskModalComponent.prototype.onFormChange = function (form) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._formData = form;
                        return [4 /*yield*/, this._onTaskSave()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    UpdateTaskModalComponent.prototype._onTaskSave = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var _c, projectId, gateId, approver, assignee, deadLine, description, answer, _d, id, canPrincipalUpdateMainPart, canPrincipalUpdateWorkerPart, checkListItem, model;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _c = this._formData, projectId = _c.projectId, gateId = _c.gateId, approver = _c.approver, assignee = _c.assignee, deadLine = _c.deadLine, description = _c.description, answer = _c.answer;
                        if (!(this._task && deadLine && projectId && approver && assignee)) return [3 /*break*/, 6];
                        _d = this._task, id = _d.id, canPrincipalUpdateMainPart = _d.canPrincipalUpdateMainPart, canPrincipalUpdateWorkerPart = _d.canPrincipalUpdateWorkerPart, checkListItem = _d.checkListItem;
                        model = {
                            id: id,
                            checklistItemId: checkListItem === null || checkListItem === void 0 ? void 0 : checkListItem.value,
                            gateId: Number(gateId),
                            dueDate: deadLine,
                            approverPrincipalId: approver.id,
                            assignedToPrincipalId: assignee.id,
                            description: description,
                            comment: answer
                        };
                        if (!canPrincipalUpdateMainPart) return [3 /*break*/, 2];
                        return [4 /*yield*/, Api.secured.updateManualTaskMainPart({ body: model })];
                    case 1:
                        _e.sent();
                        _e.label = 2;
                    case 2:
                        if (!canPrincipalUpdateWorkerPart) return [3 /*break*/, 4];
                        return [4 /*yield*/, Api.secured.updateManualTaskWorkerPart({ body: model })];
                    case 3:
                        _e.sent();
                        _e.label = 4;
                    case 4: return [4 /*yield*/, this.updateFullTask()];
                    case 5:
                        _e.sent();
                        return [2 /*return*/, (_b = (_a = this.props).afterSave) === null || _b === void 0 ? void 0 : _b.call(_a)];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    UpdateTaskModalComponent.prototype._toggleModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, history, backUrl, onToggle;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, history = _a.history, backUrl = _a.backUrl, onToggle = _a.onToggle;
                        history.push(backUrl);
                        if (!onToggle) return [3 /*break*/, 2];
                        return [4 /*yield*/, onToggle()];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(UpdateTaskModalComponent.prototype, "modalWidth", {
        get: function () {
            if (!this._task || this._task.type === ManualTaskStatusEnum.ProjectManagement)
                return '800px';
            else if (this._task && this._task.type === ManualTaskStatusEnum.DelegateDeliverable)
                return '1300px';
            else
                return '1165px';
        },
        enumerable: false,
        configurable: true
    });
    UpdateTaskModalComponent.prototype.render = function () {
        var task = this._task;
        return (React.createElement(Modal, { isOpen: true, headerTitle: "Task Info", maxWidth: this.modalWidth, footerbuttons: this._getFooterButtons(), toggle: this._toggleModal },
            this._loading && !task ? React.createElement(Loader, { style: { width: 100, height: 100 } }) : null,
            task ? (React.createElement(React.Fragment, null,
                task.state !== ManualTaskStateEnum.Deleted && (React.createElement("div", { className: styles.buttons },
                    React.createElement(ManualTaskActionButtons, { task: task, updateTaskStatus: this.updateTaskStatus, onToggle: this._toggleModal }))),
                React.createElement(TaskInfo, { task: task, projectName: "".concat(task.projectCodeName, " - ").concat(task.project.label) || '', form: this._formData, isReadOnly: false, config: {
                        isGateDisabled: true,
                        isDeadLineDisabled: !task.canPrincipalUpdateMainPart ||
                            task.state === ManualTaskStateEnum.ClosedBySystem,
                        isApproverDisabled: !task.canPrincipalUpdateMainPart ||
                            task.state === ManualTaskStateEnum.ClosedBySystem,
                        isAssigneeDisabled: !task.canPrincipalUpdateMainPart ||
                            task.state === ManualTaskStateEnum.ClosedBySystem,
                        isDescriptionDisabled: !task.canPrincipalUpdateMainPart ||
                            task.state === ManualTaskStateEnum.ClosedBySystem,
                        isAnswerHidden: task.state === ManualTaskStateEnum.ClosedBySystem,
                        isAnswerDisabled: !task.canPrincipalUpdateWorkerPart ||
                            task.state === ManualTaskStateEnum.ClosedBySystem,
                        isAttachmentsDisabled: !task.canPrincipalUpdateWorkerPart ||
                            task.status !== StatusEnum.Draft ||
                            task.state === ManualTaskStateEnum.ClosedBySystem,
                        isTitleDisabled: true
                    }, onChange: this.onFormChange, onFileChange: this.updateFullTask }))) : null));
    };
    UpdateTaskModalComponent.prototype._getFooterButtons = function () {
        return [
            {
                onClick: this._toggleModal,
                title: 'Close'
            }
        ];
    };
    __decorate([
        observable
    ], UpdateTaskModalComponent.prototype, "_task", void 0);
    __decorate([
        observable
    ], UpdateTaskModalComponent.prototype, "_loading", void 0);
    __decorate([
        observable
    ], UpdateTaskModalComponent.prototype, "_actionLoading", void 0);
    __decorate([
        observable
    ], UpdateTaskModalComponent.prototype, "_formData", void 0);
    __decorate([
        observable
    ], UpdateTaskModalComponent.prototype, "_isManualTaskDeleteModalOpen", void 0);
    __decorate([
        observable
    ], UpdateTaskModalComponent.prototype, "_isManualTaskDenyCommentModalOpen", void 0);
    __decorate([
        observable
    ], UpdateTaskModalComponent.prototype, "_currentManualTaskId", void 0);
    __decorate([
        action.bound
    ], UpdateTaskModalComponent.prototype, "updateFullTask", null);
    __decorate([
        action.bound
    ], UpdateTaskModalComponent.prototype, "updateTaskStatus", null);
    __decorate([
        action.bound
    ], UpdateTaskModalComponent.prototype, "updateFormFromTask", null);
    __decorate([
        action.bound
    ], UpdateTaskModalComponent.prototype, "onFormChange", null);
    __decorate([
        action.bound
    ], UpdateTaskModalComponent.prototype, "_onTaskSave", null);
    __decorate([
        action.bound
    ], UpdateTaskModalComponent.prototype, "_toggleModal", null);
    __decorate([
        computed
    ], UpdateTaskModalComponent.prototype, "modalWidth", null);
    UpdateTaskModalComponent = __decorate([
        observer
    ], UpdateTaskModalComponent);
    return UpdateTaskModalComponent;
}(React.PureComponent));
export var UpdateTaskModal = withRouter(UpdateTaskModalComponent);
