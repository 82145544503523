var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import styles from './GateTabsDates.module.scss';
import { DateBox } from '@app/Widgets/DateBox/DateBox';
var GateTabsDates = /** @class */ (function (_super) {
    __extends(GateTabsDates, _super);
    function GateTabsDates() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GateTabsDates.prototype.render = function () {
        var _a = this.props, meetingDate = _a.meetingDate, forecastOrActualDate = _a.forecastOrActualDate, isMilestoneProject = _a.isMilestoneProject;
        return (React.createElement("div", { className: styles.gateTabsDates },
            isMilestoneProject ? 'Milestone Meeting Date:' : 'Gate Meeting Date:',
            " ",
            React.createElement(DateBox, { date: meetingDate }),
            " / Forecast Or Actual Date: ",
            React.createElement(DateBox, { date: forecastOrActualDate })));
    };
    return GateTabsDates;
}(React.PureComponent));
export { GateTabsDates };
