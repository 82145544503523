var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import ReactSelect, { components } from 'react-select';
var ReactSelectStyles = function (hasError) { return ({
    control: function (base) { return (__assign(__assign(__assign({}, base), { minHeight: '5px' }), (hasError ? { border: '2px solid red' } : { border: base.border }))); },
    dropdownIndicator: function (base) { return (__assign(__assign({}, base), { padding: '2px' })); },
    input: function (base) { return (__assign(__assign({}, base), { lineHeight: '10px' })); },
    valueContainer: function (base) { return (__assign(__assign({}, base), { padding: '0 8px' })); },
    clearIndicator: function (base) { return (__assign(__assign({}, base), { padding: 0 })); },
    multiValue: function (base) { return (__assign(__assign({}, base), { margin: '0 2px 0 0' })); },
    menu: function (base) { return (__assign(__assign({}, base), { zIndex: 3 })); }
}); };
/* eslint-disable @typescript-eslint/no-explicit-any */
function Option(_a) {
    var innerProps = _a.innerProps, props = __rest(_a, ["innerProps"]);
    var onClick = function (e) {
        var _a;
        e.nativeEvent.stopImmediatePropagation();
        (_a = innerProps === null || innerProps === void 0 ? void 0 : innerProps.onClick) === null || _a === void 0 ? void 0 : _a.call(innerProps, e);
    };
    props.innerProps = __assign(__assign({}, innerProps), { onClick: onClick });
    return React.createElement(components.Option, __assign({}, props));
}
var defaultComponents = {
    Option: Option
};
var Select = /** @class */ (function (_super) {
    __extends(Select, _super);
    function Select() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Select.prototype.render = function () {
        var isInvalid = this.props.isInvalid;
        return (React.createElement(ReactSelect, __assign({}, this.props, { ref: this.props.innerRef, styles: __assign(__assign({}, ReactSelectStyles(!!isInvalid)), (this.props.styles || {})), components: defaultComponents })));
    };
    return Select;
}(React.PureComponent));
export { Select };
