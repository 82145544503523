var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Api } from '@app/Api';
import { observer } from 'mobx-react';
import styles from './HealthBoard.module.scss';
import { withRouter } from 'react-router-dom';
import DateTimeService from '../../Services/DateTimeService';
import { DateTime } from '../../AppConstants';
import { PageTitle } from '@app/Components/PageTitle/PageTitle';
import { Button } from '@app/Components/Button/Button';
import { appStore } from '@app/AppStore';
import { observable, action } from 'mobx';
import { devInfoStore } from '@app/Stores/DevInfoStore';
import { ConfirmationDialog } from '@app/Widgets/ConfirmationDialog/ConfirmationDialog';
import { Label } from 'reactstrap';
import { DatePicker } from '@app/Components/DatePicker';
import ErrorBoundary from '@app/Components/ErrorBoundary/ErrorBoundary';
import DownloadService from '@app/Services/DownloadService';
var HealthBoardC = /** @class */ (function (_super) {
    __extends(HealthBoardC, _super);
    function HealthBoardC() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isRemoveProjectsModalOpen = false;
        _this._isRemoveTemplatesModalOpen = false;
        _this.onDownloadLogs = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.downloadLogs(false)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onDownloadErrorsOnlyLogs = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.downloadLogs(true)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    HealthBoardC.prototype.render = function () {
        var _this = this;
        var hasPrincipalAdminAccess = appStore.appSecurityModel.hasPrincipalAdminAccess;
        var isPrincipalImpersonated = !!appStore.impersonatedPrincipal;
        return (React.createElement(ErrorBoundary, null,
            React.createElement("div", { className: styles.container },
                React.createElement(PageTitle, { title: 'HEALTH BOARD' }),
                React.createElement("div", null,
                    React.createElement(Label, null, "Download logs: from"),
                    React.createElement(DatePicker, { onDateChanged: function (date) { return (_this._dateFrom = date); }, max: this._dateTo, disabledDates: [], acceptCustomValue: false, type: "date" }),
                    React.createElement(Label, null, "Download logs: to"),
                    React.createElement(DatePicker, { onDateChanged: function (date) { return (_this._dateTo = date); }, min: this._dateFrom, disabledDates: [], acceptCustomValue: false, type: "date" })),
                React.createElement("div", null,
                    React.createElement(Button, { size: "sm", color: "primary", className: styles.item, onClick: this.onDownloadLogs }, "Download Logs")),
                React.createElement("div", null,
                    React.createElement(Button, { size: "sm", color: "primary", className: styles.item, onClick: this.onDownloadErrorsOnlyLogs }, "Download Logs (errors only)")),
                !devInfoStore.isProduction && hasPrincipalAdminAccess && !isPrincipalImpersonated && (React.createElement("div", null,
                    React.createElement(Button, { size: "sm", color: "primary", className: styles.buttonRemoveProjects, onClick: this._toggleRemoveProjectsModalOpen }, "Delete TeamGate Projects"),
                    React.createElement(Button, { size: "sm", color: "primary", className: styles.buttonRemoveTemplates, onClick: this._toggleRemoveTemplatesModalOpen }, "Delete TeamGate Templates")))),
            React.createElement(ConfirmationDialog, { isOpen: this._isRemoveProjectsModalOpen, onToggle: this._toggleRemoveProjectsModalOpen, headerTitle: "Remove Projects", onSave: this._handleDeleteProjects, body: "Are you sure you want to delete all Projects ? This information cannot be recovered after deletion." }),
            React.createElement(ConfirmationDialog, { isOpen: this._isRemoveTemplatesModalOpen, onToggle: this._toggleRemoveTemplatesModalOpen, headerTitle: "Remove Templates", onSave: this._handleDeleteTemplates, body: "Are you sure you want to delete all Templates? This information cannot be recovered after deletion." })));
    };
    HealthBoardC.prototype.downloadLogs = function (isErrorsOnly) {
        return __awaiter(this, void 0, void 0, function () {
            var getLogsResponse, data, dateString, fileName;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.downloadFullLogs({
                            isErrorsOnly: isErrorsOnly,
                            dateFrom: this._dateFrom,
                            dateTo: this._dateTo
                        })];
                    case 1:
                        getLogsResponse = _a.sent();
                        return [4 /*yield*/, getLogsResponse.blobBody];
                    case 2:
                        data = _a.sent();
                        dateString = DateTimeService.toCustomUiFormat(DateTimeService.now(), DateTime.jsonDateFormat);
                        fileName = isErrorsOnly
                            ? "TeamGate_Logs (errors only) ".concat(dateString, ".zip")
                            : "TeamGate_Logs ".concat(dateString, ".zip");
                        DownloadService.downloadByData(data, fileName);
                        return [2 /*return*/];
                }
            });
        });
    };
    HealthBoardC.prototype._handleDeleteProjects = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.removeAllProjects()];
                    case 1:
                        _a.sent();
                        this._toggleRemoveProjectsModalOpen();
                        return [2 /*return*/];
                }
            });
        });
    };
    HealthBoardC.prototype._handleDeleteTemplates = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.removeAllTemplates()];
                    case 1:
                        _a.sent();
                        this._toggleRemoveTemplatesModalOpen();
                        return [2 /*return*/];
                }
            });
        });
    };
    HealthBoardC.prototype._toggleRemoveProjectsModalOpen = function () {
        this._isRemoveProjectsModalOpen = !this._isRemoveProjectsModalOpen;
    };
    HealthBoardC.prototype._toggleRemoveTemplatesModalOpen = function () {
        this._isRemoveTemplatesModalOpen = !this._isRemoveTemplatesModalOpen;
    };
    __decorate([
        observable
    ], HealthBoardC.prototype, "_isRemoveProjectsModalOpen", void 0);
    __decorate([
        observable
    ], HealthBoardC.prototype, "_isRemoveTemplatesModalOpen", void 0);
    __decorate([
        observable
    ], HealthBoardC.prototype, "_dateFrom", void 0);
    __decorate([
        observable
    ], HealthBoardC.prototype, "_dateTo", void 0);
    __decorate([
        action.bound
    ], HealthBoardC.prototype, "_handleDeleteProjects", null);
    __decorate([
        action.bound
    ], HealthBoardC.prototype, "_handleDeleteTemplates", null);
    __decorate([
        action.bound
    ], HealthBoardC.prototype, "_toggleRemoveProjectsModalOpen", null);
    __decorate([
        action.bound
    ], HealthBoardC.prototype, "_toggleRemoveTemplatesModalOpen", null);
    HealthBoardC = __decorate([
        observer
    ], HealthBoardC);
    return HealthBoardC;
}(React.PureComponent));
export var HealthBoard = withRouter(HealthBoardC);
