var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { Widget } from '@app/Components/Widget/Widget';
import styles from './NorrdProjectsPage.module.scss';
import { NorrdProjects } from '@app/Widgets/NorrdProjects/NorrdProjects';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ErrorBoundary from '@app/Components/ErrorBoundary/ErrorBoundary';
export var NorrdProjectsPage = /** @class */ (function (_super) {
    __extends(NorrdProjectsPage, _super);
    function NorrdProjectsPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NorrdProjectsPage.prototype.render = function () {
        var _this = this;
        var _a;
        return (React.createElement(ErrorBoundary, null,
            React.createElement("div", { className: styles.widgets },
                React.createElement("div", { className: styles.widgetWrapper },
                    React.createElement("div", { className: styles.widget },
                        React.createElement(Widget, { title: React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
                                React.createElement("div", null, "NORRD Projects"),
                                React.createElement("div", null,
                                    "Count: ", (_a = this._norrdProjectsRef) === null || _a === void 0 ? void 0 :
                                    _a.projectsLength)) },
                            React.createElement(NorrdProjects, { ref: function (r) { return r && (_this._norrdProjectsRef = r); } })))))));
    };
    __decorate([
        observable
    ], NorrdProjectsPage.prototype, "_norrdProjectsRef", void 0);
    NorrdProjectsPage = __decorate([
        observer
    ], NorrdProjectsPage);
    return NorrdProjectsPage;
}(React.PureComponent));
