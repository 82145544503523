var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import styles from './ApprovedChecklistItemTables.module.scss';
import { observer } from 'mobx-react';
import { Column } from 'devextreme-react/data-grid';
import HTMLReactParser from 'html-react-parser';
import columnSharedStyles from '@app/Components/Grid/SharedStyles/column-shared-styles.module.scss';
import { EditCell } from '@app/Components/Grid/EditCell/EditCell';
import { createUniqueId } from '@app/Helpers/UidHelper/UidHelper';
import { ChecklistSections, ObjectType } from '@app/AppConstants';
import { action, observable } from 'mobx';
import { Api } from '@app/Api';
import { Grid } from '@app/Components/Grid/Grid';
import { CodeCell } from '@app/Components/Grid/CodeCell/CodeCell';
import { ChangeLogDialog } from '@app/Components/Dialogs/ChangeLogDialog';
import { Route, Switch, withRouter } from 'react-router-dom';
import { DocumentView } from '@app/Widgets/DocumentView/DocumentView';
import { RatingCell } from '@app/Components/Grid/RatingCell/RatingCell';
import { DocumentButton } from '@app/Components/DocumentButton/DocumentButton';
var MilestoneChecklistItemMasterDeliverableTablePartC = /** @class */ (function (_super) {
    __extends(MilestoneChecklistItemMasterDeliverableTablePartC, _super);
    function MilestoneChecklistItemMasterDeliverableTablePartC() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._loadingDelivers = false;
        _this._currentChecklistitem = null;
        _this._documentViewSection = ChecklistSections.ProjectManager;
        _this._delivers = [];
        return _this;
    }
    MilestoneChecklistItemMasterDeliverableTablePartC.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadDelivers()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MilestoneChecklistItemMasterDeliverableTablePartC.prototype._loadDelivers = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 2, 3]);
                        this._loadingDelivers = true;
                        return [4 /*yield*/, Api.secured.getMilestoneDeliverable({
                                checklistItemId: this.props.checkListItemId
                            })];
                    case 1:
                        result = _a.sent();
                        this._delivers = [result];
                        return [3 /*break*/, 3];
                    case 2:
                        this._loadingDelivers = false;
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MilestoneChecklistItemMasterDeliverableTablePartC.prototype._toggleLogModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._currentChecklistitem = null;
                return [2 /*return*/];
            });
        });
    };
    MilestoneChecklistItemMasterDeliverableTablePartC.prototype._savePMDeliverable = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.updateMilestoneDeliverableRelatedManualTaskWorkerPart({
                            manualTaskId: this.props.manualTaskId,
                            body: rowData
                        })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadDelivers()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MilestoneChecklistItemMasterDeliverableTablePartC.prototype.onFileChange = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadDelivers()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MilestoneChecklistItemMasterDeliverableTablePartC.prototype._handleDocumentClick = function (ciid, section) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._documentViewSection = section;
                this.props.history.push("".concat(this.props.match.url, "/upload/cl-").concat(ciid));
                return [2 /*return*/];
            });
        });
    };
    MilestoneChecklistItemMasterDeliverableTablePartC.prototype.render = function () {
        var _this = this;
        var _a = this.props, match = _a.match, canPrincipalUpdateWorkerPart = _a.canPrincipalUpdateWorkerPart, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted;
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid, { expandableRows: true, dataSource: __spreadArray([], this._delivers, true), rowsCount: this._delivers.length, loading: this._loadingDelivers },
                React.createElement(Column, { caption: "ID", dataField: "code", width: 100, allowEditing: false, cssClass: columnSharedStyles.colWithHistory, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(CodeCell, { code: data.code, onHistoryClick: function () { return (_this._currentChecklistitem = data); } }));
                    } }),
                React.createElement(Column, { caption: "Key Deliverables", dataField: "keyDeliverables", width: 250, allowEditing: false, cellRender: function (_a) {
                        var data = _a.data;
                        return React.createElement("div", { style: { whiteSpace: 'pre-line' } }, HTMLReactParser(data.keyDeliverables));
                    } }),
                React.createElement(Column, { caption: "Details", dataField: "guideline", minWidth: 300, allowEditing: false, cellRender: function (_a) {
                        var data = _a.data;
                        return React.createElement("div", { style: { whiteSpace: 'pre-line' } }, HTMLReactParser(data.guideline));
                    } }),
                React.createElement(Column, { caption: "Project Manager comment", width: 300, dataField: "projectManagerComment", cssClass: columnSharedStyles.commentColumn, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(EditCell, { value: data.projectManagerOriginalComment, onChange: function (value) { return (data.projectManagerOriginalComment = value); }, onSave: function () { return _this._savePMDeliverable(data); }, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), withCopy: true, disabled: !canPrincipalUpdateWorkerPart || !data.isGateEditable, section: ChecklistSections.ProjectManager }));
                    } }),
                React.createElement(Column, { headerCellRender: function () { return React.createElement("div", { title: "Project Manager Rating" }, "Rating"); }, width: 110, cellRender: function (_a) {
                        var _b;
                        var data = _a.data;
                        return (React.createElement(React.Fragment, null,
                            React.createElement(RatingCell, { onSave: function () { return _this._savePMDeliverable(data); }, onChange: function (value) { return (data.projectManagerRating = value); }, rating: data.projectManagerRating, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), disabled: !canPrincipalUpdateWorkerPart || !data.isGateEditable, section: ChecklistSections.ProjectManager, controlId: "pmRating".concat(data.id) }),
                            React.createElement("div", { className: styles.commentActions },
                                React.createElement("div", { className: "".concat(styles.commentAction, " ").concat(styles.documentIconWrapper) },
                                    React.createElement(DocumentButton, { hasDocument: data.hasDocuments, onDocumentIconClick: function () { return __awaiter(_this, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0: return [4 /*yield*/, this._handleDocumentClick(data.id, ChecklistSections.ProjectManager)];
                                                    case 1: return [2 /*return*/, _a.sent()];
                                                }
                                            });
                                        }); }, hasRedDot: (_b = data.masterDeliverable) === null || _b === void 0 ? void 0 : _b.hasRedDot, className: styles.documentIconButton })))));
                    } })),
            this._currentChecklistitem && (React.createElement(ChangeLogDialog, { onToggle: this._toggleLogModal, uniqueId: createUniqueId(ObjectType.ChecklistItem, this._currentChecklistitem.id) })),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "".concat(match.url, "/upload/cl-:id") },
                    React.createElement(DocumentView, { backUrl: match.url, section: this._documentViewSection, onFileChange: this.onFileChange, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })))));
    };
    __decorate([
        observable
    ], MilestoneChecklistItemMasterDeliverableTablePartC.prototype, "_loadingDelivers", void 0);
    __decorate([
        observable
    ], MilestoneChecklistItemMasterDeliverableTablePartC.prototype, "_currentChecklistitem", void 0);
    __decorate([
        observable.shallow
    ], MilestoneChecklistItemMasterDeliverableTablePartC.prototype, "_delivers", void 0);
    __decorate([
        action.bound
    ], MilestoneChecklistItemMasterDeliverableTablePartC.prototype, "_loadDelivers", null);
    __decorate([
        action.bound
    ], MilestoneChecklistItemMasterDeliverableTablePartC.prototype, "_toggleLogModal", null);
    __decorate([
        action.bound
    ], MilestoneChecklistItemMasterDeliverableTablePartC.prototype, "_savePMDeliverable", null);
    __decorate([
        action.bound
    ], MilestoneChecklistItemMasterDeliverableTablePartC.prototype, "onFileChange", null);
    __decorate([
        action.bound
    ], MilestoneChecklistItemMasterDeliverableTablePartC.prototype, "_handleDocumentClick", null);
    MilestoneChecklistItemMasterDeliverableTablePartC = __decorate([
        observer
    ], MilestoneChecklistItemMasterDeliverableTablePartC);
    return MilestoneChecklistItemMasterDeliverableTablePartC;
}(React.Component));
export var MilestoneChecklistItemMasterDeliverableTablePart = withRouter(MilestoneChecklistItemMasterDeliverableTablePartC);
