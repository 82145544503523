var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FaRegCopy } from 'react-icons/fa';
import styles from './LinkWithCopy.module.scss';
import { Tooltip } from 'devextreme-react';
import nextId from 'react-id-generator';
import { CopyToClipBoardBlock } from '@app/Components/CopyToClipboardBlock/CopyToClipBoardBlock';
export var WithCopy = /** @class */ (function (_super) {
    __extends(WithCopy, _super);
    function WithCopy() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._copied = false;
        _this._isTooltipVisible = false;
        _this._id = nextId();
        return _this;
    }
    WithCopy.prototype.render = function () {
        var _a;
        var _this = this;
        var children = (_a = this.props, _a.children), text = _a.text, renderChild = _a.renderChild, copyText = _a.copyText;
        return (React.createElement("div", { className: styles.container },
            text && (React.createElement("div", { className: "".concat(styles.iconWrapper, " copyIcon") },
                React.createElement(CopyToClipBoardBlock, { text: text, onCopyHandler: function () { return (_this._copied = true); }, onMouseEnterHandler: function () { return (_this._isTooltipVisible = true); }, onMouseLeaveHandler: function () { return (_this._isTooltipVisible = false); } },
                    React.createElement(FaRegCopy, { style: { color: '#555' }, id: 'copy' + this._id })))),
            renderChild ? (React.createElement("div", { className: styles.text }, renderChild(children))) : (React.createElement("div", { className: styles.text }, children)),
            text && (React.createElement(Tooltip, { position: "right", visible: this._isTooltipVisible, target: '#copy' + this._id }, this._copied ? 'Copied' : copyText !== null && copyText !== void 0 ? copyText : 'Copy to clipboard'))));
    };
    __decorate([
        observable
    ], WithCopy.prototype, "_copied", void 0);
    __decorate([
        observable
    ], WithCopy.prototype, "_isTooltipVisible", void 0);
    WithCopy = __decorate([
        observer
    ], WithCopy);
    return WithCopy;
}(React.PureComponent));
